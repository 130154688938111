"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AbstractRouterType = void 0;
var AbstractRouterType;

(function (AbstractRouterType) {
  AbstractRouterType[AbstractRouterType["Action"] = 0] = "Action";
  AbstractRouterType[AbstractRouterType["Function"] = 1] = "Function";
})(AbstractRouterType = exports.AbstractRouterType || (exports.AbstractRouterType = {}));