"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  metrics: null,
  loading: true,
  objectProductTypesMetrics: null,
  objectProductMetrics: [],
  brandMetrics: null
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_METRICS, function (state, _ref) {
  var metrics = _ref.metrics;
  return Object.assign(Object.assign({}, state), {
    metrics: metrics
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_METRICS_LOADING, function (state, _ref2) {
  var loading = _ref2.loading;
  return Object.assign(Object.assign({}, state), {
    loading: loading
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_OBJECT_PRODUCT_TYPES_METRICS, function (state, _ref3) {
  var objectProductTypesMetrics = _ref3.objectProductTypesMetrics;
  return Object.assign(Object.assign({}, state), {
    objectProductTypesMetrics: objectProductTypesMetrics
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_OBJECT_PRODUCT_METRICS, function (state, _ref4) {
  var objectProductMetrics = _ref4.objectProductMetrics;
  return Object.assign(Object.assign({}, state), {
    objectProductMetrics: objectProductMetrics
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_BRAND_METRICS, function (state, _ref5) {
  var brandMetrics = _ref5.brandMetrics;
  return Object.assign(Object.assign({}, state), {
    brandMetrics: brandMetrics
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);