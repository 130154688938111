"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNotifications = exports.thunks = exports.reducer = void 0;

var crud_1 = require("./crud");

var notification_1 = require("./notification");

var KEY = 'PRODUCT_MATERIAL_TAG';
exports.reducer = (0, crud_1.default)(KEY);
exports.thunks = new crud_1.ThunkHandler({
  url: '/product-material-tags',
  key: KEY,
  noPagination: true
});

function createNotifications(dispatch, message) {
  dispatch((0, notification_1.addLabels)(KEY, {
    fetchValues: {
      error: message.errorRetrievingMaterialTags
    },
    fetchValue: {
      error: message.errorRetrievingAMaterialTag
    },
    create: {
      success: message.addedMaterialTag,
      error: message.errorCreatingMaterialTag
    },
    patch: {
      success: message.updatedMaterialTag,
      error: message.errorRegisteringMaterialTag
    },
    delete: {
      success: message.removedMaterialTag,
      error: message.errorDeletingMaterialTag
    }
  }));
}

exports.createNotifications = createNotifications;