import PageHeaderArchitectContent from "../../components/PageHeaderArchitectContent/PageHeaderArchitectContent";
import {IRootState} from "../../redux";
import {ArchitectWithBrandDTO} from "@addsome/dtos";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {collections} from '@addsome/redux-store'
import {connect} from "react-redux";
import React, { useEffect, useState } from 'react'
import {CollectionDtoWithProducts} from "@addsome/dtos/dist/dtos/collection";
import Section from "../../components/Section/Section";
import Routes from "../../utils/routes";
import productToCardData from "../../utils/productToCardData";
import {FormattedMessage, useIntl} from "react-intl";
import styles from "./CollectionProducts.module.scss";
import {Icon, Input} from "antd";
import {Modal} from "@addsome/ui-kit";
import edit from "../../assets/images/edit.png";
import Button from "antd/lib/button";

interface PayloadI {
  name: string;
}

const CollectionProducts = ({router, fetchOneCollection, removeSelectedProductFromCollection, updateSelectedCollection}) => {

  //TODO find a more clean solution to initilize the collection
  const [collection, setCollection] = useState<CollectionDtoWithProducts | {products:[], id: null, name: string, createdAt: string}>({products: [], id: null, name: "", createdAt: ""});
  const id = router.location.pathname.split("/")[2];
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [collectionName, setCollectionName] = useState<string|null>();
  const [editCollectionName, setEditCollectionName] = useState(false);

  const fetchAndSetCollection = () => {
    fetchOneCollection(id).then((res: any) => {
      setCollection(res);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (collection.id == null) {
      fetchAndSetCollection();
    }
    setCollectionName(collection.name);
  }, [collection]);

  const handleOpenDeleteModal = (e) => {
    e.preventDefault();
    setIsDeleteModalVisible(true);
    setProductIdToDelete(e.currentTarget.getAttribute('data-id'));
  }

  const handleDelete = () => {
    removeSelectedProductFromCollection(
      id,
      productIdToDelete,
      intl.formatMessage({ id: 'architect.collections.products.delete.success' }),
      intl.formatMessage({ id: 'architect.collections.products.delete.error' })).then(() => {
      fetchAndSetCollection();
    })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsDeleteModalVisible(false);
      });
  }

  const handleEnableButton = () => {
    setEditCollectionName(!editCollectionName);
  }

  const handleEditCollectionName = (name: string) => {
    updateSelectedCollection(
      id,
      {name: name},
      intl.formatMessage({ id: 'architect.collections.edit.success' }),
      intl.formatMessage({ id: 'architect.collections.edit.error' })).then(() => {
      fetchAndSetCollection();
      handleEnableButton();
    });
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleEditCollectionName(e.target.value);
    }
  };

  return (
    <>
      <PageHeaderArchitectContent/>
      <div className={styles.section}>
        {collection.products.length == 0 && !isLoading &&
          <h2 className={styles.noProducts}>No Products Available</h2>
        }
        {collection.products.length > 0 && !isLoading &&
          <div className={styles.details}>
            <div className={styles.editName}>
              <Input
                className={styles.inputStyle}
                defaultValue={collectionName || ""}
                disabled={!editCollectionName}
                onBlur={(e) => handleEditCollectionName(e.target.value)}
                onKeyUp={handleKeyPress}
              />
              <Button className={styles.editButton}>
                <img src={edit} style={{height: "15px"}} onClick={handleEnableButton}/>
              </Button>
            </div>
            <p style={{paddingRight:"3vh"}}>{collection.products.length} Saved Products</p>
            <p>Created on {collection.createdAt.toString().split("T")[0]}</p>
          </div>
        }
        <Section
          noPaddingTop
          title={""}
          buttonLabel={intl.formatMessage({ id: 'global.displayall' })}
          buttonRoute={Routes.Products}
          loading={isLoading}
          smallProductGrid={true}
          cardsData={collection.products.map(product => productToCardData(product))}
          productTopIcons={<Icon type={"delete"} style={{color:"white"}} onClick={(e) => handleOpenDeleteModal(e)}/>}
          enrichTopIcons={true}
          href={true}
        />
      </div>
      <Modal
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        onOk={handleDelete}
        okText={<FormattedMessage id="global.yes" />}
        cancelText={<FormattedMessage id="global.no" />}
        title={<FormattedMessage id="architect.collections.products.delete.modal.header" />}
        bodyStyle={{ display: 'none' }}
      />
    </>
  )

};

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user as ArchitectWithBrandDTO,
  router: state.router
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchOneCollection: (id: string) => dispatch(collections.fetchCollection(id)),
  removeSelectedProductFromCollection: (id: string, productId: string, success: string, error: string) => dispatch(collections.removeProductFromCollection(id, productId, success, error)),
  updateSelectedCollection: (id: string, payload: PayloadI, success: string, error: string) => dispatch(collections.updateCollection(id, payload, success, error))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionProducts)