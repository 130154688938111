import { IntlShape } from 'react-intl'

export enum FileType {
  image = 'image',
  vectorial = 'vectorial',
  model3D = 'model3D',
  model3DSettings = 'model3DSettings',
  technical = 'technical',
  archive = 'archive',
  text = 'text',
  document = 'document',
  unused = 'unused'
}

export interface IExtensionsTypes {
  [FileType.image]: string[]
  [FileType.vectorial]: string[]
  [FileType.model3D]: string[]
  [FileType.model3DSettings]: string[]
  [FileType.technical]: string[]
  [FileType.archive]: string[]
  [FileType.text]: string[]
  [FileType.document]: string[]
  [FileType.unused]: string[]
}

export const extensionTypes: IExtensionsTypes = {
  [FileType.image]: [
    'bmp',
    'gif',
    'hdr',
    'ico',
    'jpeg',
    'jpg',
    'png',
    'psd',
    'targa',
    'tga',
    'tif',
    'tiff',
    'webp'
  ],
  [FileType.vectorial]: ['ai', 'eps', 'svg', 'svg+xml'],
  [FileType.model3D]: [
    '3dm',
    '3dmf',
    '3ds',
    'blen',
    'blend',
    'c4d',
    'dae',
    'dwg',
    '_dxf',
    'dxf',
    'fbx',
    'gsm',
    'igs',
    'lwo',
    'ma',
    'max',
    'mb',
    'obj',
    'rfa',
    'rvt',
    'sat',
    'skp',
    'sldprt',
    'step',
    'stl',
    'stp',
    'vwx',
    'wrl',
    'x_t'
  ],
  [FileType.model3DSettings]: ['ctb', 'lp', 'mtl', 'p2m'],
  [FileType.technical]: ['ies', 'ldt'],
  [FileType.archive]: ['7z', 'gz', 'iso', 'rar', 'tar', 'zip', 'x-zip-compressed'],
  [FileType.text]: ['log', 'rtf', 'txt', 'plain'],
  [FileType.document]: ['csv', 'doc', 'pdf'],
  [FileType.unused]: ['bak', 'cshtml', 'dwl', 'md5', 'url']
}

const types = Object.keys(extensionTypes) as FileType[]
export function getFileTypeByName(filename: string | null): FileType {
  if (!filename) return FileType.unused

  const extension = (filename.split('.').pop() || '').toLowerCase()
  return getFileType(extension)
}

export function getFileType(extension: string): FileType {
  return (
    types.find(type => {
      return extensionTypes[type].find(ext => ext === extension)
    }) || FileType.unused
  )
}

export const getFormattedFileType = (type: FileType, intl: IntlShape) =>
  intl.formatMessage({
    id: `filetype.${type}`
  })
