import {
  AccountType,
  BrandLightDTO,
  BrandUserWithBrandsDTO,
  ProductLightExtendedDTO
} from '@addsome/dtos';
import {
  brand as brandActions,
  IPaginationRequest,
  metrics as metricsActions,
  product as productActions
} from '@addsome/redux-store';
import { CategoryColor, Heading, Size } from '@addsome/ui-kit';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import bg from '../../assets/images/home-other-bg.png';
import MetricsBars from '../../components/MetricsBars/MetricsBars';
import PageCards from '../../components/PageCards/PageCards';
import PageHeader from '../../components/PageHeaders/PageHeader';
import { IRootState } from '../../redux';
import { updatePageTitle } from '../../services/title';
import HomePageCategories from './HomePageCategories/HomePageCategories';
import styles from './HomePageOther.module.scss';

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & WrappedComponentProps & {
    accountType: AccountType;
  };

const NB_RECENT = 8;

const HomePageOther: React.FC<IProps> = ({
  fetchMetrics,
  metrics,
  brandMetrics,
  user,
  accountType,
  fetchMultipleBrandsMetrics,
  fetchBrands,
  brands,
  fetchProducts,
  products,
  intl
}) => {
  const [globalMetrics, setGlobalMetrics] = useState<any[]>([]);
  const [userBrands, setUserBrands] = useState<BrandLightDTO[]>([]);
  const [object3DProducts, setObject3DProducts] = useState<ProductLightExtendedDTO[]>([]);
  const [recentProducts, setRecentProducts] = useState<ProductLightExtendedDTO[]>([])

  useEffect(() => {
    if (accountType) {
      if (accountType !== AccountType.BrandUser) {
        fetchBrands({
          order: { createdAt: 'DESC' },
          filters: { enabled: ['true'] },
          take: NB_RECENT
        });
      }

      if (accountType !== AccountType.BrandUser || userBrands.length > 0) {
        fetchProducts({
          order: { createdAt: 'DESC' },
          take: NB_RECENT,
          filters: {
            have3DObject: ['true'],
            enabled: ['true'],
            stateIds: ['500'],
            ...(userBrands.length > 0 ? { brandIds: userBrands.map(b => b.id) } : {})
          }
        }).then(results => {
          setObject3DProducts(results);
        });
      }

      fetchProducts({
        order: { createdAt: 'DESC' },
        take: NB_RECENT,
        filters: {
          enabled: ['true'],
          ...(userBrands.length > 0 ? { brandIds: userBrands.map(b => b.id) } : {})
        }
      }).then(results => {
        setRecentProducts(results);
      });
    }
  }, [accountType, fetchBrands, fetchProducts, intl, userBrands]);

  // TODO custom hook for clarity ?
  useEffect(() => {
    if (!metrics && !brandMetrics) return;
    const isBrandUser = accountType === AccountType.BrandUser;
    if ((isBrandUser && !brandMetrics) || (!isBrandUser && !metrics)) return;

    const metricsArray: any[] = [
      ...(!isBrandUser
        ? [
          {
            entityName: <FormattedMessage id="homepage.metrics.brands.title" />,
            nbEntities:
              metrics.brandDefaultTypeCount.furniture +
              metrics.brandDefaultTypeCount.lighting +
              metrics.brandDefaultTypeCount.accessories +
              metrics.brandDefaultTypeCount.unset,
            diagramDatas: [
              {
                label: intl.formatMessage({ id: 'global.furniture' }),
                value: metrics.brandDefaultTypeCount.furniture,
                color: CategoryColor.LIGHT_GREY
              },
              {
                label: intl.formatMessage({ id: 'global.lighting' }),
                value: metrics.brandDefaultTypeCount.lighting,
                color: CategoryColor.CYAN
              },
              {
                label: intl.formatMessage({ id: 'global.accessories' }),
                value: metrics.brandDefaultTypeCount.accessories,
                color: CategoryColor.DARK_BLUE
              }
            ],
            recents: brands.slice(0, 8).map(brand => ({
              img: (brand.logo && brand.logo.url) || '',
              label: brand.name,
              link: `/brands/${brand.id}`
            })),
            link: '/brands'
          }
        ]
        : []),
      {
        entityName: <FormattedMessage id="homepage.metrics.products.title" />,
        nbEntities: isBrandUser
          ? brandMetrics.furniture.objectProductCount +
          brandMetrics.lighting.objectProductCount +
          brandMetrics.accessories.objectProductCount
          : metrics.objectProductCount.furniture +
          metrics.objectProductCount.lighting +
          metrics.objectProductCount.accessories,
        diagramDatas: [
          {
            label: intl.formatMessage({ id: 'global.furniture' }),
            value: isBrandUser
              ? brandMetrics.furniture.objectProductCount
              : metrics.objectProductCount.furniture,
            color: CategoryColor.LIGHT_GREY
          },
          {
            label: intl.formatMessage({ id: 'global.lighting' }),
            value: isBrandUser
              ? brandMetrics.lighting.objectProductCount
              : metrics.objectProductCount.lighting,
            color: CategoryColor.CYAN
          },
          {
            label: intl.formatMessage({ id: 'global.accessories' }),
            value: isBrandUser
              ? brandMetrics.accessories.objectProductCount
              : metrics.objectProductCount.accessories,
            color: CategoryColor.DARK_BLUE
          }
        ],
        recents: recentProducts.slice(0, 8).map(product => ({
          img: (product.thumbnail && product.thumbnail.media && product.thumbnail.media.url) || '',
          label: product.name,
          link: `/products/${product.id}`
        })),
        link: '/products'
      },
      {
        entityName: <FormattedMessage id="homepage.metrics.files.title" />,
        nbEntities: isBrandUser
          ? brandMetrics.furniture.objectProductWithBundleCount +
          brandMetrics.lighting.objectProductWithBundleCount +
          brandMetrics.accessories.objectProductWithBundleCount
          : metrics.objectProductWithBundleCount.furniture +
          metrics.objectProductWithBundleCount.lighting +
          metrics.objectProductWithBundleCount.accessories,
        diagramDatas: [
          {
            label: intl.formatMessage({ id: 'global.furniture' }),
            value: isBrandUser
              ? brandMetrics.furniture.objectProductWithBundleCount
              : metrics.objectProductWithBundleCount.furniture,
            color: CategoryColor.LIGHT_GREY
          },
          {
            label: intl.formatMessage({ id: 'global.lighting' }),
            value: isBrandUser
              ? brandMetrics.lighting.objectProductWithBundleCount
              : metrics.objectProductWithBundleCount.lighting,
            color: CategoryColor.CYAN
          },
          {
            label: intl.formatMessage({ id: 'global.accessories' }),
            value: isBrandUser
              ? brandMetrics.accessories.objectProductWithBundleCount
              : metrics.objectProductWithBundleCount.accessories,
            color: CategoryColor.DARK_BLUE
          }
        ],
        recents: object3DProducts.slice(0, 8).map(product => ({
          img: (product.thumbnail && product.thumbnail.media && product.thumbnail.media.url) || '',
          label: product.name,
          link: `/products/${product.id}`
        }))
      }
    ];
    setGlobalMetrics(metricsArray);
  }, [metrics, accountType, brandMetrics, brands, products, object3DProducts, recentProducts, intl]);

  useEffect(() => {
    if (user) {
      if (accountType === AccountType.BrandUser) {
        const brandUser = user as BrandUserWithBrandsDTO;
        if (brandUser.brands && brandUser.brands.length > 0) {
          setUserBrands(brandUser.brands);
          fetchMultipleBrandsMetrics(brandUser.brands.map(brand => brand.id));
        }
      } else {
        fetchMetrics();
      }
    }
  }, [user, accountType, fetchMultipleBrandsMetrics, fetchMetrics]);

  updatePageTitle();
  return (
    <>
      <PageHeader
        title={
          <Heading level={1} strong centerText>
            {accountType === AccountType.Architect ? (
              user && <span>{`${user.account.firstName} ${user.account.lastName}`}</span>
            ) : accountType === AccountType.BrandUser && userBrands.length === 1 ? (
              userBrands[0].name
            ) : (
              <FormattedMessage id="homepage.title" description="Title on app home page" />
            )}
          </Heading>
        }
        size={Size.MEDIUM}
        hasCards
        backgroundSrc={
          accountType === AccountType.BrandUser &&
            userBrands.length === 1 &&
            userBrands[0].cover &&
            userBrands[0].cover.url
            ? userBrands[0].cover.url
            : bg
        }
      />
      <div className={styles.container}>
        <PageCards metricsArray={globalMetrics} />
        <HomePageCategories brandsMetrics={userBrands.length > 0 ? brandMetrics : undefined} />
        {[AccountType.AddsomeUser, AccountType.BrandUser].includes(accountType) ? (
          <MetricsBars
            brandIds={userBrands.length > 0 ? userBrands.map(brand => brand.id) : undefined}
          />
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user,
  metrics: state.metricsState.metrics,
  brandMetrics: state.metricsState.brandMetrics,
  brands: state.brandState.values,
  products: state.productState.values
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchMetrics: () => dispatch(metricsActions.fetchMetrics()),
  fetchMultipleBrandsMetrics: (ids: string[]) =>
    dispatch(metricsActions.fetchMultipleBrandsMetrics(ids)),
  fetchBrands: (request: IPaginationRequest = {}) =>
    dispatch(brandActions.thunks.fetchValues(request)),
  fetchProducts: (request: IPaginationRequest = {}) =>
    dispatch(productActions.thunks.fetchValues(request))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HomePageOther));
