import React, { FC } from 'react'
import { Modal, Heading, Theme, Button } from '@addsome/ui-kit'
import { useIntl } from 'react-intl'
import styles from './InviteNotificationHandler.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { IRootState } from '../../redux'
import { setDisplayBrandInviteNotification } from '@addsome/redux-store/dist/store/brand-invitation'

const InviteNotificationHandler: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const display = useSelector((state: IRootState) => state.brandInvitationState.displayNotification)
  const notificationState = useSelector(
    (state: IRootState) => state.brandInvitationState.notificationState
  )
  const brandName = useSelector(
    (state: IRootState) => state.brandInvitationState.brand && state.brandInvitationState.brand.name
  )

  return (
    <Modal
      visible={display}
      footer={null}
      centered
      onCancel={() => dispatch(setDisplayBrandInviteNotification(false))}
    >
      <Heading level={3} fill centerText>
        {intl.formatMessage(
          { id: `invite.modal.title.${notificationState}` },
          {
            brandName
          }
        )}
      </Heading>
      <p className={styles.desc}>
        {intl.formatMessage(
          { id: `invite.modal.desc.${notificationState}` },
          {
            brandName
          }
        )}
      </p>
      <Button
        theme={Theme.PRIMARY}
        block
        onClick={() => dispatch(setDisplayBrandInviteNotification(false))}
      >
        {intl.formatMessage({ id: 'invite.modal.close' })}
      </Button>
    </Modal>
  )
}

export default InviteNotificationHandler
