"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.setObjectProductData3D = void 0;

var model_1 = require("./model");

var setObjectProductData3D = function setObjectProductData3D(objectProductData3D) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_DATA3D,
    objectProductData3D: objectProductData3D
  };
};

exports.setObjectProductData3D = setObjectProductData3D;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_DATA3D_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;