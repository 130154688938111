import React, { useCallback, useState } from 'react';
import { InputField, InputURL, Option, Select, TagsList, TextArea } from '@addsome/ui-kit';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Form } from 'antd';
import { AddsomeUserCreationDTO, AddsomeUserUpdateDTO } from '@addsome/dtos';
import { FormikProps } from 'formik';
import styles from './AdminInformationFieldGroup.module.scss';
import { findAccountWithSameEmail } from '../../../services/architectAccount'

type IProps = WrappedComponentProps & {
  formik: FormikProps<AddsomeUserUpdateDTO | AddsomeUserCreationDTO>;
  addsomeUser: AddsomeUserUpdateDTO | AddsomeUserCreationDTO;
};

const AdminInformationFieldGroup: React.FC<IProps> = ({ formik, addsomeUser, intl }) => {
  const [isEmailAlreadyUsed, setEmailAlreadyUsed] = useState(false)

  const checkEmailValidation = (e, isOnSubmit = false, values = {}) => {
    if (!e) {
      setEmailAlreadyUsed(false)
      return;
    }

    findAccountWithSameEmail(e).then((emailExists) => {
      if (emailExists) {
        setEmailAlreadyUsed(true)
      } else {
        setEmailAlreadyUsed(false)
      }
    })
  }

  return (
    <div className={styles.adminInformationFieldsGroup}>
      <Form.Item
        label={<FormattedMessage id="adminpage.panel.name" />}
        colon={false}
        required={true}
      >
        <InputField
          name="firstName"
          type={'text'}
          required
          onChange={formik.handleChange}
          defaultValue={addsomeUser.firstName}
          error={formik.errors.firstName}
        />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="adminpage.panel.alternativeNames" />}
        colon={false}
        required={true}
      >
        <InputField
          name="lastName"
          type={'text'}
          required
          onChange={formik.handleChange}
          defaultValue={addsomeUser.lastName}
          error={formik.errors.lastName}
        />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="adminpage.panel.email" />}
        colon={false}
        required={true}
      >
        <InputField
          name="email"
          type={'email'}
          required
          error={isEmailAlreadyUsed ? intl.formatMessage({ id: 'register.emailAlreadyUsed' }) : (formik.touched.email && formik.errors.email ? formik.errors.email : '')}
          onChange={formik.handleChange}
          onBlur={(value) => { checkEmailValidation(value.target.value); formik.handleBlur(value); }}
        />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="adminpage.panel.password" />}
        colon={false}
        required={true}
      >
        <InputField
          name="password"
          type={'password'}
          required
          onChange={formik.handleChange}
          defaultValue={addsomeUser.password}
          error={formik.errors.password}
        />
      </Form.Item>
    </div>
  )
};

export default injectIntl(AdminInformationFieldGroup);