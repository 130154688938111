import {ProductPaginationResponse} from "@addsome/dtos";
import React, {useEffect, useState} from 'react';
import PageHeaderArchitectContent from "../../components/PageHeaderArchitectContent/PageHeaderArchitectContent";
import Routes from "../../utils/routes";
import productToCardData from "../../utils/productToCardData";
import Section from "../../components/Section/Section";
import {useIntl} from "react-intl";
import {ThunkDispatch} from "redux-thunk";
import {IRootState} from "../../redux";
import {AnyAction} from "redux";
import {
  architectProductHistory,
  IPaginationRequest,
} from "@addsome/redux-store";
import {connect} from "react-redux";
import useLoggedUser from "../../hooks/useUser";

// We need to take enough data to fit on wider screens
const TAKE = 10

const RecentlyViewedProducts = ({fetchProductsHistory}) => {
  const [lastProducts, setLastProducts] = useState<ProductPaginationResponse>({
    data: [],
    total: 0
  });
  const [lastProductsLoading, setLastProductsLoading] = useState(true);
  const intl = useIntl();
  const { user } = useLoggedUser();

  useEffect(() => {
    if (!user) return;

    fetchProductsHistory(user.account.id, {
      take: TAKE
    }).then(res => {
      setLastProducts(res);
      setLastProductsLoading(false);
    })
  }, [user, fetchProductsHistory]);

  return (
    <>
      <PageHeaderArchitectContent />

      <Section
        noPaddingTop
        title={""}
        buttonLabel={intl.formatMessage({ id: 'global.displayall' })}
        buttonRoute={Routes.Products}
        cardsData={lastProducts.data.map(product => productToCardData(product))}
        loading={lastProductsLoading}
        smallProductGrid={true}
        useAnchorForUrl={false}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProductsHistory: (architectId: string, request: IPaginationRequest = {}) =>
    dispatch(architectProductHistory.fetchArchitectHistory(architectId, request))
})

export default connect(
  undefined,
  mapDispatchToProps
)(RecentlyViewedProducts)

