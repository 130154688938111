import { ProductPictureType } from '@addsome/dtos/dist'
import AntLayout from 'antd/lib/layout'
import {
  IPaginationRequest,
  objectProduct,
  objectProductVariations,
  product,
  product as productActions,
  productViewer
} from '@addsome/redux-store'
import {
  FilterType,
  ICardData,
  InfiniteGridWithSearch,
  Searchbar
} from '@addsome/ui-kit'
import FiltersContainerCatalog from './FiltersContainerCatalog'
import classNames from 'classnames'
import { parse } from 'querystring'
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { getResizedPreview } from '../../utils/cloudImage'
import formatPrice from '../../utils/formatPrice'
import { ScrollDirectionContext } from '../../utils/ScrollDirectionContext'
import styles from './Catalog.module.scss'
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel';
import Header from "../../components/Header/Header";
import classnames from "classnames";
import RegisterViewModal from '../RegisterPage/RegisterViewModal'
import { Helmet } from "react-helmet";
import Routes from '../../utils/routes'

const FILTERS = [
  FilterType.BRAND,
  FilterType.EMPTY,
  FilterType.MATERIAL,
  FilterType.OBJECT,
  FilterType.ORIGIN,
  FilterType.PRICE,
  FilterType.ROOM,
  FilterType.STYLE,
  FilterType.HAVE3D,
  FilterType.DIMENSIONS
]

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const PublicCatalog: React.FC<IProps> = ({
  fetchProducts,
  products,
  loader,
  totalProducts,
  showProductModal,
  router,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchValueBuffer, setSearchValueBuffer] = useState('')
  const scrollDirection = useContext(ScrollDirectionContext)
  const [initialFetch, setInitialFetch] = useState(true)
  const [initialLoader, setInitialLoader] = useState(true)
  const [filtersForAPI, setfiltersForAPI] = useState(undefined)
  const [productSchema, setProductSchema] = useState({})
  const intl = useIntl()


  useEffect(() => {
    window.scrollTo(0, 0)
    Mixpanel.track(MixpanelEvents.PublicCatalogViewed)
  }, [])

  //open product popup
  useEffect(() => {
    if (products[0] && filtersForAPI && filtersForAPI.productIds && filtersForAPI.productIds.length === 1) {
      showProductModal(filtersForAPI.productIds[0], products[0].objectProductId)
      document.title = `${products[0].name} - ${intl.formatMessage({ id: 'productpage.panel.seeProduct' })}`
      setProductSchema({
        "@context": "https://schema.org",
        "@type": "Product",
        "brand": `${products[0].brand ? products[0].brand.name : ''}`,
        "name": `${products[0].name}`,
        "category": `${products[0].collection}`,
        "image": `${products[0].thumbnail ? products[0].thumbnail.media.url : ''}`,
        "description": `${products[0].description}`,
        "aggregateRating": {
          "ratingCount": 489,
          "reviewCount": 312,
          "ratingValue": '95%'
        }
      });
    }
  }, [filtersForAPI, products])

  const fetchItems = useCallback(
    (
      filters: { [key: string]: string[] },
      renew: boolean,
      skip: number,
      take: 20,
      order: { [key: string]: 'ASC' | 'DESC' } = { promoted: 'DESC', createdAt: 'DESC' }
    ) => {
      if (searchValue) {
        filters.query = [searchValue]
      }

      return fetchProducts({ skip, filters, order }, !renew);
    }, [fetchProducts, searchValue]
  )

  useEffect(() => {
    if (router.location.pathname.includes(`${Routes.Catalog}/`) && !router.location.query.productIds) {
      window.location.href = `${Routes.Catalog}`
    }

    if (router.location.search) {
      const querystring = parse(router.location.search.substring(1))

      if (querystring.query) {
        const value = querystring.query.toString()
        setSearchValue(value)
        setSearchValueBuffer(value)
      }
    }
    // updateUrl(stringify(filtersForAPI, { arrayFormat: 'comma' }));
    if (router.location.search == '') {
      setfiltersForAPI({})
    }
  }, [router.location.search])

  const cardsData = useMemo<ICardData[]>(() => {
    if (initialFetch) {
      setInitialFetch(false)
      return []
    } else {
      setInitialLoader(false)
      return products.map(
        (p): ICardData => {
          const image =
            p.thumbnail && p.thumbnail.media.url
              ? getResizedPreview(385, 256, p.thumbnail.media.url)
              : undefined

          return {
            id: p.id,
            title: p.brand ? p.brand.name : '',
            image,
            desc: p.name,
            slug: p.slug,
            side: formatPrice(p.suggestedPrice),
            objectProductId: p.objectProductId,
            imgFit: (p.thumbnail && p.thumbnail.type !== ProductPictureType.Ambiance) || undefined,
            imgBgColor: (p.thumbnail && p.thumbnail.hexaColor) || undefined
          }
        })
    }
  }, [products])

  const onFiltersChange = (f) => {
    setfiltersForAPI(f)
  }

  return (
    <>
      <AntLayout
        className={classnames(styles.layout, {
          [styles.whiteBackground]: 1
        })}
      >
        <PublicHeader darkTheme={false} />
        <div className={styles.container}>
          <div
            className={classNames(styles.filtersContainer, {
              [styles.scrollToTop]: scrollDirection === 'up' || scrollDirection == null,
              [styles.scrollToBottom]: scrollDirection === 'down'
            })}
          >
            <div className={styles.searchBarContainer}>
              <Searchbar
                value={searchValueBuffer}
                onChange={e => setSearchValueBuffer(e.target.value)}
                onBlur={e => setSearchValue(e.target.value)}
                onSearch={v => setSearchValue(v)}
                placeholder={intl.formatMessage({ id: 'catalog.filters.search' })}
              />
            </div>
            {(products.length != 0 || router.location.search != '') &&
              <FiltersContainerCatalog
                onFiltersChange={(f) => onFiltersChange(f)}
                searchValue={searchValue}
                reinitLabel={intl.formatMessage({ id: 'catalog.filters.reinit' })}
                buttonsContainerClassName={styles.filtersBar}
                tagsContainerClassName={styles.filtersBar}
              />
            }
          </div>
          <InfiniteGridWithSearch
            filters={filtersForAPI}
            fetchItems={fetchItems}
            cardsData={cardsData}
            totalItems={totalProducts}
            className={styles.grid}
            searchValue={searchValue}
            loading={loader || initialLoader}
            inner
            loadingMessage={intl.formatMessage({ id: 'catalog.search.message' })}
            onCardClick={(cardData: ICardData) =>
              showProductModal(cardData.id, cardData.objectProductId)
            }
          />
        </div>
      </AntLayout>
      <RegisterViewModal />
      {!filtersForAPI &&
        <Helmet>
          <meta name="description" content={`${intl.formatMessage({ id: 'catalog.filters.search' })}`} />
        </Helmet>
      }
      {products[0] && filtersForAPI && filtersForAPI.productIds && filtersForAPI.productIds.length === 1 &&
        <Helmet>
          <meta name="description" content={`${products[0].description}`} />
          <meta property="og:title" content={`${products[0].name}`} />
          <meta property="og:description" content={`${products[0].description}`}/>
          <meta property="og:image" content={(products[0].thumbnail && products[0].thumbnail.media && products[0].thumbnail.media.url) ? products[0].thumbnail.media.url : ""} />
          <meta property="og:url" content={`${window.location.origin}/catalog/${products[0].slug}?productIds=${products[0].id}`} />
          <script type="application/ld+json">
            {JSON.stringify(productSchema)}
          </script>
        </Helmet>
      }
      {filtersForAPI && filtersForAPI.brandIds && filtersForAPI.brandIds.length >= 1 &&
        <Helmet>
          <meta name="description" content={`${intl.formatMessage({ id: 'catalog.filters.search.seo.brands' })}`} />
        </Helmet>
      }
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  products: state.productState.values,
  totalProducts: state.productState.total,
  user: state.userState.user,
  router: state.router,
  loader: state.productState.loading,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProducts: (request: IPaginationRequest = {}, concatenate: boolean) =>
    dispatch(productActions.thunks.fetchValues(request, concatenate, undefined, undefined, true)),
  showProductModal: async (productId: string, objectProductId?: string | null) => {
    dispatch(product.thunks.fetchValue(productId))
    dispatch(productViewer.setDisplayViewer(true))

    // Only fecth again if the product changed
    if (!!objectProductId) {
      await dispatch(objectProduct.thunks.fetchValue(objectProductId))
      await dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId))
    }
    dispatch(productViewer.setProductId(productId))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(PublicCatalog))
