import React, { FC } from 'react'
import classnames from 'classnames'
import { Modal, Heading, Theme, Button } from '@addsome/ui-kit'
import { setShouldShowArchitectNotEnabledDownloadPopup } from '@addsome/redux-store/dist/store/auth'
import { ArchitectDTO } from '@addsome/dtos/dist'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { IRootState } from '../../redux'
import styles from './ArchitectNotEnabledPopup.module.scss'

const ArchitectNotEnabledPopup: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: IRootState) => state.userState.user) as ArchitectDTO
  const isVisible = useSelector(
    (state: IRootState) => state.authState.shouldShowArchitectNotEnabledDownloadPopup
  )

  if (!user) {
    return <></>;
  }

  return (
    <Modal
      visible={isVisible}
      footer={null}
      centered
      onCancel={() => dispatch(setShouldShowArchitectNotEnabledDownloadPopup(false))}
    >
      <Heading level={3} fill centerText>
        <FormattedMessage
          id="architectnotenabledpopup.title"
          values={{
            name: (
              <span className={styles.name}>
                {user.account.firstName} {user.account.lastName}
              </span>
            )
          }}
        />
      </Heading>
      <div className={classnames(styles.description)}>
        <FormattedMessage
          id="architectnotenabledpopup.desc"
          values={{
            p: (...chunks: string[]) => <p>{chunks}</p>
          }}
        />
      </div>

      <Button
        theme={Theme.PRIMARY}
        block
        onClick={() => {
          dispatch(setShouldShowArchitectNotEnabledDownloadPopup(false))
          window.open('mailto:hello@addsome.io')
        }}
      >
        <FormattedMessage id="architectnotenabledpopup.button" />
      </Button>
    </Modal>
  )
}

export default ArchitectNotEnabledPopup
