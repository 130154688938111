import { FileDTO, MediaDTO, ProductLightRelationshipsExtendedDTO, ProductPictureCreationDTO } from '@addsome/dtos';
import notification from 'antd/lib/notification';
import React from 'react';
import { convertPictureForCreation } from '../../services/convertions';
import { extensionTypes, FileType } from '../../services/filesTypes';
import { deleteMedia, mediaUploadUrl, setThumbnail } from '../../services/media';
import { setUserLog } from '../../services/userLog';
import Media from '../Media/Media';
import UploadMedia, { UploadType } from '../UploadMedia/UploadMedia';
import style from './MediaGallery.module.scss';
import { WrappedComponentProps, injectIntl } from 'react-intl'

type IProps = WrappedComponentProps & {
  pictures: FileDTO[];
  product?: ProductLightRelationshipsExtendedDTO;
  onChange: (pictures: ProductPictureCreationDTO[]) => void;
  readonly?: boolean;
  onClick?: (picture: FileDTO) => void;
  token: string;
  user: any;
  setImageAsThumbnail?: (pictureId: string, mediaId: string) => void;
  getAllProducts?: (productId: string) => void;
  thumbnailPictureId?: string;
}

class MediaGallery extends React.Component<IProps> {
  productThumbnailId = this.props.product && this.props.product.thumbnail && this.props.product.thumbnail.id ? this.props.product.thumbnail.id : "";
  productId = this.props.product ? this.props.product.id : "";

  public render() {

    return (
      <div className={style.gallery}>
        {this.props.pictures.map((picture, index) => (
          <Media
            key={picture.id}
            src={picture.media.url || ''}
            enabled={picture.enabled}
            name={`${picture.name || `picture ${index}`}`}
            media={picture.media}
            picture={picture}
            onChange={this.updateMediaEnabled}
            onDelete={this.deleteMedia}
            readonly={this.props.readonly}
            thumbnailPictureId={this.props.thumbnailPictureId || this.productThumbnailId}
            onClick={() => this.props.onClick && this.props.onClick(picture)}
            setImageAsThumbnailById={(pictureId) => {
              this.props.setImageAsThumbnail && this.props.setImageAsThumbnail(pictureId, picture.media.id);
              this.updateThumbnail(this.productId, picture.id);
            }}
          />
        ))}
        <UploadMedia
          action={mediaUploadUrl}
          afterUpload={this.addMedia}
          type={UploadType.SQUARE}
          accept={extensionTypes[FileType.image].map(ext => `.${ext}`).join(',')}
          token={this.props.token}
        />
      </div>
    );
  }

  private updateMediaEnabled = async (mediaId: string, enabled: boolean) => {
    await this.props.onChange(
      this.props.pictures.map(picture =>
        picture.media.id === mediaId
          ? convertPictureForCreation({ ...picture, enabled })
          : convertPictureForCreation(picture)
      )
    );
  };
  private updateThumbnail = async (productId: string, pictureId: string) => {

    try {
      await Promise.all([
        setThumbnail(productId, pictureId),
      ]);
      if (this.props.getAllProducts)
        await this.props.getAllProducts(productId);
      notification.success({
        message: this.props.intl.formatMessage({ id: 'productspage.images.setAsThumbnail' }),
        className: style.notificationContainer
      });
    } catch (e) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'global.error' }),
        description: this.props.intl.formatMessage({ id: 'productspage.images.errorThumbnail' }),
        className: style.notificationContainer
      });
    }
  };

  private deleteMedia = async (mediaId: string) => {
    try {
      const picture = this.props.pictures.find(x => x.media.id == mediaId)
      await Promise.all([
        setUserLog(this.props.user!.account.id, this.props.product ? this.props.product.id : '', `media deleted: ${picture!.media.name}`, mediaId),
        deleteMedia(mediaId),
        this.props.onChange(
          this.props.pictures.filter(p => p.media.id !== mediaId).map(convertPictureForCreation)
        )
      ]);
      notification.success({
        message: this.props.intl.formatMessage({ id: 'productspage.images.deletedImage' }),
        className: style.notificationContainer
      });
    } catch (e) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'global.error' }),
        description: this.props.intl.formatMessage({ id: 'productspage.images.errorDeletingImage' }),
        className: style.notificationContainer
      });
    }
  };

  private addMedia = async (media: MediaDTO) => {

    setUserLog(this.props.user!.account.id, this.props.product ? this.props.product.id : '', 'media uploaded', media.id)

    let list = JSON.parse(localStorage.pictures);
    await this.props.onChange([
      ...this.props.pictures.map(convertPictureForCreation),
      ...list
      // {
      //   enabled: true,
      //   mediaId: media.id
      // }
    ]);
    notification.success({
      message: this.props.intl.formatMessage({ id: 'productspage.images.addedImage' }),
      className: style.notificationContainer
    });
  };
}

export default injectIntl(MediaGallery);
