"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateActions = exports.generateActionsTypes = void 0;

function generateActionsTypes(key) {
  return {
    SET_VALUES: "SET_VALUES_".concat(key),
    SET_VALUE: "SET_VALUE_".concat(key),
    UNSET_VALUE: "UNSET_VALUE_".concat(key),
    UPDATE_VALUE: "UPDATE_VALUE_".concat(key),
    REMOVE_VALUE: "REMOVE_VALUE_".concat(key),
    SET_LOADING: "SET_LOADING_".concat(key),
    ADD_VALUES: "ADD_VALUES_".concat(key)
  };
}

exports.generateActionsTypes = generateActionsTypes;

function generateActions(actionsTypes) {
  return {
    setValues: function setValues(values, total) {
      return {
        type: actionsTypes.SET_VALUES,
        values: values,
        total: total
      };
    },
    setValue: function setValue(value) {
      return {
        type: actionsTypes.SET_VALUE,
        value: value
      };
    },
    unsetValue: function unsetValue() {
      return {
        type: actionsTypes.UNSET_VALUE
      };
    },
    updateValue: function updateValue(value) {
      return {
        type: actionsTypes.UPDATE_VALUE,
        value: value
      };
    },
    removeValue: function removeValue(id) {
      return {
        type: actionsTypes.REMOVE_VALUE,
        id: id
      };
    },
    setLoading: function setLoading(loading) {
      return {
        type: actionsTypes.SET_LOADING,
        loading: loading
      };
    },
    addValues: function addValues(values, total) {
      return {
        type: actionsTypes.ADD_VALUES,
        values: values,
        total: total
      };
    }
  };
}

exports.generateActions = generateActions;