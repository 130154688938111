import { ArchitectSubscriptionType } from '@addsome/dtos/dist';
import { apiRequest } from '@addsome/redux-store';
import { getSubscription } from './subscription'

const PAYMENT_PATH = '/stripe-payment';

export async function configPayment() {
  try {
    const resp = await apiRequest('GET', `${PAYMENT_PATH}/config`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function hasActiveSubscription(email) {
  try {
    const resp = await apiRequest('GET', `${PAYMENT_PATH}/activeSubscriptions/${email}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function hasCanceledSubscriptions(email) {
  try {
    const resp = await apiRequest('GET', `${PAYMENT_PATH}/canceledSubscriptions/${email}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function payInvoice(email) {
  try {
    const resp = await apiRequest('POST', `${PAYMENT_PATH}/payInvoice/${email}`);
    return resp
  }
  catch (e) {
    console.log(e);
    return e;
  }
}


export async function createPaymentIntent(amount, email, subscriptionType, architect) {
  try {
    const resp = await apiRequest('POST', `${PAYMENT_PATH}/create-payment-intent/${amount}/${email}/${subscriptionType}`, architect);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function createPaymentIntentTrial(amount, email, architect) {
  try {
    const resp = await apiRequest('POST', `${PAYMENT_PATH}/create-payment-intent-trial/${amount}/${email}/${ArchitectSubscriptionType.Standard25}`, architect);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function cancelMonthlySubscription(email) {
  try {
    const resp = await apiRequest('POST', `${PAYMENT_PATH}/cancel-subscription/${email}`, {});
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function cancelLastMonthlySubscription(email) {
  try {
    const resp = await apiRequest('POST', `${PAYMENT_PATH}/cancel-subscription/last/${email}`, {});
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function getInvoices(email) {
  try {
    const resp = await apiRequest('GET', `${PAYMENT_PATH}/invoices/${email}`, {});
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function setTrialSubscription(id) {
  try {
    const trialSubscription = await getSubscription(ArchitectSubscriptionType.Standard10)
    //set trial because he unsubscribed
    await apiRequest('PATCH', `architects/${id}`, { id, subscription: trialSubscription, subscriptionStartDate: new Date(), downloads: 0 });
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function setTrialSubscriptionWithRemainingDownloads(id, remainingDownloads) {
  try {
    const trialSubscription = await getSubscription(ArchitectSubscriptionType.Standard10)
    //set trial because he unsubscribed
    await apiRequest('PATCH', `architects/${id}`, { id, subscription: trialSubscription, subscriptionStartDate: new Date(), downloads: 0, subscriptionRemainingDownloads: remainingDownloads });
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function setTrialSubscriptionNoRemainingDownloads(id) {
  try {
    const trialSubscription = await getSubscription(ArchitectSubscriptionType.Standard10)
    //set trial because he unsubscribed
    await apiRequest('PATCH', `architects/${id}`, { id, subscription: trialSubscription, subscriptionStartDate: new Date(), downloads: 0, subscriptionRemainingDownloads: 0 });
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function setNoSubscriptionNoRemainingDownloads(id) {
  try {
    await apiRequest('PATCH', `architects/${id}`, { id, subscription: null, subscriptionStartDate: new Date(), downloads: 0, subscriptionRemainingDownloads: 0 });
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function setNoSubscriptionWithRemainingDownloads(id) {
  try {
    const architect = await apiRequest('GET', `architects/${id}`, {});
    const remaining = parseInt(architect.subscription.quota) - parseInt(architect.downloads) + parseInt(architect.subscriptionRemainingDownloads)
    //set trial because he unsubscribed
    await apiRequest('PATCH', `architects/${id}`, { id, subscription: null, subscriptionStartDate: new Date(), downloads: 0, subscriptionRemainingDownloads: remaining });
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function checkMonthlySubscriptionPayment(email, id, subscriptionSlug) {
  try {
    const resp = await apiRequest<number>('GET', `${PAYMENT_PATH}/check-payment-subscription/${email}`, {});
    if (resp) {
      const architect = await apiRequest('GET', `architects/${id}`, {});

      //check if the subscription start date was not already modified
      if (new Date(architect.subscriptionStartDate) == new Date(resp * 1000)) {
        return;
      }

      const remaining = parseInt(architect.subscription.quota) - parseInt(architect.downloads) + parseInt(architect.subscriptionRemainingDownloads)
      //set new start date every month he pays successfully
      if (subscriptionSlug != ArchitectSubscriptionType.Standard10) {
        await apiRequest('PATCH', `architects/${id}`, { id, subscriptionStartDate: new Date(resp * 1000), downloads: 0, subscriptionRemainingDownloads: remaining });
      } else {
        const lightSubscription = await getSubscription(ArchitectSubscriptionType.Standard25)
        await apiRequest('PATCH', `architects/${id}`, { id, subscription: lightSubscription, subscriptionStartDate: new Date(resp * 1000), downloads: 0, subscriptionRemainingDownloads: remaining });
      }
    } else {

      if (subscriptionSlug != ArchitectSubscriptionType.Standard10) {
        const architect = await apiRequest('GET', `architects/${id}`, {});

        if (!architect.subscription) {
          return;
        }

        const remaining = parseInt(architect.subscription.quota) - parseInt(architect.downloads) + parseInt(architect.subscriptionRemainingDownloads)
        await apiRequest('PATCH', `architects/${id}`, { id, subscription: null, subscriptionStartDate: new Date(), downloads: 0, subscriptionRemainingDownloads: remaining });
      } else {
        await apiRequest('PATCH', `architects/${id}`, { id, subscription: null, subscriptionStartDate: new Date(), downloads: 0, subscriptionRemainingDownloads: 0 });
      }
    }
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

