"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  notificationType: null,
  message: null,
  key: null,
  labels: {
    default: {
      fetchValues: {
        success: 'Données récupérées',
        error: 'Une erreur est survenue à la récupération des données'
      },
      fetchValue: {
        success: 'Donnée récupérée',
        error: 'Une erreur est survenue à la récupération de la donnée'
      },
      create: {
        success: 'Valeur ajoutée',
        error: 'Une erreur est survenue à la création'
      },
      patch: {
        success: 'Donnée mise à jour',
        error: "Une erreur est survenue \xE0 l'\xE9dition"
      },
      delete: {
        success: 'Donnée supprimée',
        error: 'Une erreur est survenue à la suppression'
      }
    }
  }
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionTypes.SET_NOTIFICATION, function (state, _ref) {
  var notificationType = _ref.notificationType,
      message = _ref.message,
      key = _ref.key;
  return Object.assign(Object.assign({}, state), {
    notificationType: notificationType,
    message: message,
    key: key
  });
}), _defineProperty(_actionHandlers, model_1.ActionTypes.UNSET_NOTIFICATION, function (state) {
  return Object.assign(Object.assign({}, state), {
    notificationType: null,
    message: null,
    key: null
  });
}), _defineProperty(_actionHandlers, model_1.ActionTypes.ADD_LABELS, function (state, _ref2) {
  var key = _ref2.key,
      labels = _ref2.labels;
  return Object.assign(Object.assign({}, state), {
    labels: Object.assign(Object.assign({}, state.labels), _defineProperty({}, key, labels))
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);