import { notification as storeNotification } from '@addsome/redux-store'
import { setProductStates } from '@addsome/redux-store/dist/store/product-states'
import notification from 'antd/lib/notification'
import get from 'lodash.get'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { IRootState } from '../../redux'
import styles from './NotificationHandler.module.scss'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const NotificationHandler: React.FunctionComponent<IProps> = ({
  message,
  notificationKey,
  labels,
  notificationType,
  unsetNotification
}) => {

  const [activeNotification, setActiveNotification] = useState([])

  React.useEffect(() => {
    if (notificationType) {
      setActiveNotification([...activeNotification, { key: `${notificationKey}.${message}`, time: Date.now() }])
      notification[notificationType]({
        key: `${notificationKey}.${message}`,
        description: get(labels, `${notificationKey}.${message}`) || get(labels, `default.${message}`),
        message: activeNotification.length > 0
          && activeNotification.filter(x => x.key == `${notificationKey}.${message}` && (Date.now() - x.time) < 12000).length != 0
          && `x${activeNotification.filter(x => x.key == `${notificationKey}.${message}` && (Date.now() - x.time) < 12000).length + 1}`,
        onClose: () => {
          setActiveNotification(activeNotification.filter(x => x.key == `${notificationKey}.${message}` && (Date.now() - x.time) < 12000));
          unsetNotification()
        },
        className: styles.container,
      })
    }
  }, [labels, message, notificationKey, notificationType, unsetNotification])
  return null
}

const mapStateToProps = (state: IRootState) => ({
  notificationType: state.notificationState.notificationType,
  message: state.notificationState.message,
  notificationKey: state.notificationState.key,
  labels: state.notificationState.labels
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  unsetNotification: () => dispatch(storeNotification.unsetNotification())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationHandler)
