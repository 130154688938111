import {
  AccountType,
  AddsomeUserDTO,
  BrandCreationDTO,
  BrandDefaultType,
  BrandExtendedDTO,
  BrandUpdateDTO
} from '@addsome/dtos'
import { brand as brandActions, IPaginationRequest } from '@addsome/redux-store'
import { PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import { parse, stringify } from 'querystring'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import ListTop from '../../ListTop/ListTop'
import { BrandInformationStep } from '../BrandCreation'
import BrandsTable from '../BrandsTable/BrandsTable'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    pageSize: number
    readonly?: boolean
    filters?: { [key: string]: string[] }
  }

const BrandsList: React.FC<IProps> = ({
  fetchBrands,
  pageSize,
  filters,
  brands,
  loading,
  readonly,
  total,
  patchBrand,
  deleteBrand,
  createBrand,
  queryParams,
  updateQuery,
  urlSearch,
  connectedUser,
  redirect,
  accountType
}) => {
  const [currentPage, setCurrentPage] = useState(Math.max(1, parseInt(queryParams.page, 10)) || 1)
  const [request, setRequest] = useState<IPaginationRequest>({
    skip: (currentPage - 1) * pageSize,
    order: queryParams.order ? JSON.parse(queryParams.order) : { createdAt: -1 },
    take: pageSize,
    filters: { ...filters, query: [queryParams.search], ...JSON.parse(queryParams.filters || '{}') }
  })
  const [prevSearch, setPrevSearch] = useState(urlSearch)
  const intl = useIntl()

  const canDelete = accountType === AccountType.AddsomeUser && (connectedUser as AddsomeUserDTO).perform_deletions

  useEffect(() => {
    fetchBrands(request)
  }, [fetchBrands, request])

  // Update data when user use previous
  useEffect(() => {
    if (prevSearch !== urlSearch) {
      const newCurrentPage = Math.max(1, parseInt(queryParams.page, 10)) || 1
      const newRequest: IPaginationRequest = {
        skip: (newCurrentPage - 1) * pageSize,
        order: queryParams.order ? JSON.parse(queryParams.order) : { createdAt: -1 },
        take: pageSize,
        filters: {
          ...filters,
          query: [queryParams.search],
          ...JSON.parse(queryParams.filters || '{}')
        }
      }
      setCurrentPage(newCurrentPage)
      setRequest(newRequest)
      fetchBrands(newRequest)
      setPrevSearch(urlSearch)
    }
  }, [
    fetchBrands,
    filters,
    pageSize,
    prevSearch,
    queryParams.filters,
    queryParams.order,
    queryParams.page,
    queryParams.search,
    urlSearch
  ])

  const memoizedHandleTableChange = useCallback(
    (
      pagination: PaginationConfig,
      filter: Record<keyof BrandExtendedDTO, string[]>,
      sorter: SorterResult<BrandExtendedDTO>
    ) => {
      const newRequest: IPaginationRequest = {
        ...request,
        skip: ((pagination.current || 1) - 1) * pageSize,
        order: sorter.column
          ? { [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1 }
          : { createdAt: -1 },
        filters: { ...request.filters, ...filter, ...filters }
      }
      setRequest(newRequest)
      setCurrentPage(pagination.current ? pagination.current : currentPage)
      fetchBrands(newRequest)

      const newParams = {
        ...queryParams,
        page: pagination.current || currentPage,
        order: JSON.stringify(newRequest.order),
        filters: JSON.stringify(filter)
      }
      setPrevSearch('?' + stringify(newParams))
      updateQuery(stringify(newParams))
    },
    [currentPage, fetchBrands, filters, pageSize, queryParams, request, updateQuery]
  )

  const memoizedHandleSearch = useCallback(
    (searchPattern: string) => {
      const newRequest: IPaginationRequest = {
        ...request,
        skip: 0,
        take: pageSize,
        filters: { ...request.filters, ...filters, query: [searchPattern] }
      }
      setRequest(newRequest)
      setCurrentPage(1)
      fetchBrands(newRequest)

      const newParams = { ...queryParams, search: searchPattern, page: 1 }
      setPrevSearch('?' + stringify(newParams))
      updateQuery(stringify(newParams))
    },
    [fetchBrands, filters, pageSize, queryParams, request, updateQuery]
  )

  const handleCreateBrand = useCallback(
    async (payload: BrandCreationDTO) => {
      const brand = await createBrand(payload, request)
      redirect(`/brands/${brand.id}`)
    },
    [createBrand, redirect, request]
  )
  const editBrand = async (brandId: string, brand: BrandExtendedDTO) => {
    const logoId = brand.logo && brand.logo.id && brand.logo.id
    await patchBrand(brandId, { ...brand, logo: logoId })
  }

  return (
    <>
      <ListTop
        searchLabel={intl.formatMessage({ id: 'brandspage.search' })}
        onSearch={memoizedHandleSearch}
        searchInitValue={queryParams.search}
        creation={
          accountType === AccountType.AddsomeUser
            ? {
              active: !readonly,
              initialValue: {
                name: '',
                alternativeNames: [],
                defaultType: BrandDefaultType.Furniture
              },
              steps: [
                {
                  title: <FormattedMessage id="addbrand.information" />,
                  component: BrandInformationStep
                }
              ],
              title: <FormattedMessage id="brandspage.add" />,
              onCreate: payload => handleCreateBrand(payload)
            }
            : undefined
        }
      />
      <BrandsTable
        brands={brands}
        loading={loading}
        candDelete={canDelete}
        total={total}
        currentPage={currentPage}
        pageSize={pageSize}
        onTableChange={memoizedHandleTableChange}
        onDelete={(id: string) => deleteBrand(id, request)}
        editBrandStatus={(id: string, brand: BrandExtendedDTO) => editBrand(id, brand)}
        readonly={readonly}
        sortBy={JSON.parse(queryParams.order || '{}')}
        filters={JSON.parse(queryParams.filters || '{}')}
      />
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  brands: state.brandState.values,
  total: state.brandState.total,
  loading: state.brandState.loading,
  accountType: state.authState.type,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string },
  urlSearch: state.router.location.search,
  connectedUser: state.userState.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createBrand: (payload: BrandCreationDTO, request: IPaginationRequest) =>
    dispatch(brandActions.thunks.createValue(payload, request)),
  fetchBrands: (request: IPaginationRequest = {}) =>
    dispatch(brandActions.thunks.fetchValues(request)),
  deleteBrand: (id: string, request: IPaginationRequest) =>
    dispatch(brandActions.thunks.deleteValue(id, request)),
  patchBrand: (id: string, brand: BrandUpdateDTO) =>
    dispatch(brandActions.thunks.patchValue(id, brand)),
  updateQuery: (params: string) => dispatch(push({ search: params })),
  redirect: (location: string) => dispatch(push(location))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandsList)
