"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setShouldShowArchitectNotLinkedToBrandDownloadPopup = exports.setShouldShowArchitectNotEnabledDownloadPopup = exports.setShouldShowArchitectFirstConnectionPopup = exports.hasLoggedIn = exports.resetAuth = exports.unsetError = exports.setError = exports.setAuth = exports.setLoading = exports.resetToken = exports.setToken = void 0;

var api_1 = require("../../api");

var model_1 = require("./model");

var setToken = function setToken(token) {
  (0, api_1.setAxiosToken)(token);
  return {
    type: model_1.ActionsTypes.SET_TOKEN,
    token: token
  };
};

exports.setToken = setToken;

var resetToken = function resetToken() {
  (0, api_1.resetAxiosToken)();
  return {
    type: model_1.ActionsTypes.RESET_TOKEN
  };
};

exports.resetToken = resetToken;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_AUTH_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;

var setAuth = function setAuth(auth) {
  (0, api_1.setAxiosToken)(auth.token);
  return {
    type: model_1.ActionsTypes.SET_AUTH,
    auth: auth
  };
};

exports.setAuth = setAuth;

var setError = function setError(error) {
  return {
    type: model_1.ActionsTypes.SET_ERROR,
    error: error
  };
};

exports.setError = setError;

var unsetError = function unsetError() {
  return {
    type: model_1.ActionsTypes.UNSET_ERROR
  };
};

exports.unsetError = unsetError;

var resetAuth = function resetAuth() {
  return {
    type: model_1.ActionsTypes.RESET_AUTH
  };
};

exports.resetAuth = resetAuth;

var hasLoggedIn = function hasLoggedIn() {
  return {
    type: model_1.ActionsTypes.HAS_LOGGED_IN
  };
};

exports.hasLoggedIn = hasLoggedIn;

var setShouldShowArchitectFirstConnectionPopup = function setShouldShowArchitectFirstConnectionPopup(shouldShowArchitectFirstConnectionPopup) {
  return {
    type: model_1.ActionsTypes.SHOULD_SHOW_ARCHITECT_FIRST_CONNECTION_POPUP,
    shouldShowArchitectFirstConnectionPopup: shouldShowArchitectFirstConnectionPopup
  };
};

exports.setShouldShowArchitectFirstConnectionPopup = setShouldShowArchitectFirstConnectionPopup;

var setShouldShowArchitectNotEnabledDownloadPopup = function setShouldShowArchitectNotEnabledDownloadPopup(shouldShowArchitectNotEnabledDownloadPopup) {
  return {
    type: model_1.ActionsTypes.SHOULD_SHOW_ARCHITECT_NOT_ENABLED_DOWNLOAD_POPUP,
    shouldShowArchitectNotEnabledDownloadPopup: shouldShowArchitectNotEnabledDownloadPopup
  };
};

exports.setShouldShowArchitectNotEnabledDownloadPopup = setShouldShowArchitectNotEnabledDownloadPopup;

var setShouldShowArchitectNotLinkedToBrandDownloadPopup = function setShouldShowArchitectNotLinkedToBrandDownloadPopup(shouldShowArchitectNotLinkedToBrandDownloadPopup, brand) {
  return {
    type: model_1.ActionsTypes.SHOULD_SHOW_ARCHITECT_NOT_LINKED_TO_BRAND_DOWNLOAD_POPUP,
    shouldShowArchitectNotLinkedToBrandDownloadPopup: shouldShowArchitectNotLinkedToBrandDownloadPopup,
    brand: brand
  };
};

exports.setShouldShowArchitectNotLinkedToBrandDownloadPopup = setShouldShowArchitectNotLinkedToBrandDownloadPopup;