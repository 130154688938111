import { ProductProblemCreationDTO, ProductProblemDTO } from '@addsome/dtos';
import { IPaginationRequest, productProblem } from '@addsome/redux-store';
import { Heading } from '@addsome/ui-kit';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import { push } from 'connected-react-router';
import { parse, stringify } from 'querystring';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from '../../../redux';
import CreationModalWrapper from '../../CreationModalWrapper/CreationModalWrapper';
import ProductProblemsTable from '../ProductProblemsTable/ProductProblemsTable';
import ProductProblemCreationForm from './ProductProblemCreationForm';
import styles from './ProductProblems.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

// Mock
class ProductProblemUpdateDTO extends ProductProblemDTO { }

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & WrappedComponentProps & {
    productId: string;
    readonly?: boolean;
  };

interface IState {
  displayCreationModal: boolean;
}

class ProductProblems extends React.Component<IProps, IState> {
  public state: IState = {
    displayCreationModal: false
  };

  public componentDidMount() {
    this.fetchProblems();
  }

  public render() {
    const { queryParams } = this.props;
    return (
      this.props.problems && (
        <div className={styles.productProblems}>
          <div className={styles.add}>
            <CreationModalWrapper
              display={this.state.displayCreationModal}
              onUpdateDisplay={this.setCreationModalDisplay}
              title={this.props.intl.formatMessage({ id: 'productspage.problems.addAProblem' })}
            >
              <ProductProblemCreationForm
                productId={this.props.productId}
                accountId={this.props.connectedUser!.account.id}
                onSubmit={this.handleCreate}
              />
            </CreationModalWrapper>
          </div>
          <Heading level={3} as="h2">
            <FormattedMessage id='productspage.problems' />
          </Heading>
          <ProductProblemsTable
            problems={this.props.problems}
            loading={this.props.loading}
            onDelete={this.handleDelete}
            onEdit={this.handleEdit}
            onTableChange={this.handleTableChange}
            sortBy={JSON.parse(queryParams.order || '{}')}
            filters={JSON.parse(queryParams.filters || '{}')}
            intl={this.props.intl}
          />
        </div>
      )
    );
  }

  private fetchProblems = () => {
    setTimeout(async () => {
      await this.props.fetchProductProblems({
        filters: { productIds: [this.props.productId], deleted: false }
      });
    }, 0);
  };

  private handleCreate = async (problem: ProductProblemCreationDTO) => {
    this.setCreationModalDisplay(false);
    problem.productId = this.props.productId;
    await this.props.createProductProblem(problem);
    this.fetchProblems();
  };

  private handleEdit = async (problem: ProductProblemUpdateDTO) => {
    await this.props.patchProductProblem(problem.id, problem);
    this.fetchProblems();
  };

  private handleDelete = async (id: string) => {
    await this.props.deleteProductProblem(id);
    this.fetchProblems();
  };

  private setCreationModalDisplay = (display: boolean) => {
    this.setState({
      displayCreationModal: display
    });
  };

  private handleTableChange = (
    pagination: PaginationConfig,
    filter: Record<keyof ProductProblemDTO, string[]>,
    sorter: SorterResult<ProductProblemDTO>
  ) => {
    const newParams = {
      ...this.props.queryParams,
      order: JSON.stringify(
        sorter.column ? { [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1 } : {}
      ),
      filters: JSON.stringify(filter)
    };
    this.props.updateQuery(stringify(newParams));
  };
}

const mapStateToProps = (state: IRootState) => ({
  problems: state.productProblemState.values,
  loading: state.productProblemState.loading,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string; },
  connectedUser: state.userState.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProductProblems: (request: IPaginationRequest) =>
    dispatch(productProblem.thunks.fetchValues(request)),
  createProductProblem: (problem: ProductProblemCreationDTO) =>
    dispatch(productProblem.thunks.createValue(problem)),
  patchProductProblem: (id: string, problem: ProductProblemUpdateDTO) =>
    dispatch(productProblem.thunks.patchValue(id, problem)),
  deleteProductProblem: (id: string) => dispatch(productProblem.thunks.deleteValue(id)),
  updateQuery: (params: string) => dispatch(push({ search: params }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProductProblems));
