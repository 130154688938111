import { AccountType } from '@addsome/dtos/dist'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../redux'
import CommunityPage from './CommunityPage'
import CommunityPageAdmin from './CommunityAdmin/CommunityPageAdmin'
import Layout from '../../templates/Layout/Layout'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { auth } from '@addsome/redux-store'
import { Loading } from "@addsome/ui-kit";
import PublicHeader from '../../components/PublicHeader/PublicHeader'
import { RouteComponentProps } from 'react-router-dom';
import ProductViewerModal from '../../components/Player/ProductViewModal/ProductViewerModal'
import Footer from '../../components/Footer/Footer'
interface IMatchParams {
  slug: string
}

const CommunityRouter: React.FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IMatchParams>> = ({ match, accountType, getAuth }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!accountType) {
      const fetchAuth = async () => {
        await getAuth();
        setLoading(false);
      };
      fetchAuth();
    } else {
      setLoading(false);
    }
  }, [accountType, getAuth]);

  if (loading) {
    return <Loading></Loading>;
  }
  else {
    switch (accountType) {
    case AccountType.BrandUser:
      return <Layout><CommunityPage slug={match.params.slug} /></Layout>
    case AccountType.AddsomeUser:
      return <Layout><CommunityPageAdmin /></Layout>
    case AccountType.Architect:
      return <Layout><CommunityPage slug={match.params.slug} /></Layout>
    default:
      return <>
        <PublicHeader darkTheme={false} />
        <ProductViewerModal />
        <CommunityPage slug={match.params.slug} />
        <Footer></Footer>
      </>
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  getAuth: () => dispatch(auth.getAuth()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CommunityRouter)
