import { useState } from 'react'

export default function useSearch<T extends { name: string }>(): [
  T[],
  (query: string, source: T[]) => void
  ] {
  const [suggestedItems, setSuggestedItems] = useState<T[]>([])

  const searchItem = (query: string, source: T[]) =>
    setSuggestedItems(
      source.filter(item => item.name.toLowerCase().includes(query.toString().toLowerCase()))
    )

  return [suggestedItems, searchItem]
}
