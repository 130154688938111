import {ObjectProductVariationDTO, ProductRelationshipsExtendedDTO} from '@addsome/dtos';
import {Button, Popconfirm, Theme} from '@addsome/ui-kit';
import React from 'react';
// import styles from './ProductPreview.module.scss';


type IProps = {
    product: ProductRelationshipsExtendedDTO;
};

class ProductPreviewFile extends React.Component<IProps> {
  public render() {
    return (
      <div>
        {
          this.props.product.objectProductModelBundle !== undefined &&
                    this.props.product.objectProductModelBundle !== null &&
                    this.props.product.objectProductModelBundle.file !== undefined &&
                    this.props.product.objectProductModelBundle.file !== null && (
            <p>{this.props.product.objectProductModelBundle.file.id}</p>
          )}
      </div>
    );
  }
}


export default ProductPreviewFile;
