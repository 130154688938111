import React, { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { timeConvert } from '../../../../utils/utils'
import styles from './TimeEstimate.module.scss'

interface IProps {
  estimate: number
}

const TimeEstimate: FC<IProps> = ({ estimate }) => {
  const intl = useIntl()

  const text = useMemo(() => {
    if (estimate === Infinity) {
      return intl.formatMessage({ id: 'drivepopup.estimate.infinity' })
    } else if (estimate < 1) {
      return intl.formatMessage({ id: 'drivepopup.estimate.lessthanaminute' })
    } else if (estimate >= 1 && estimate < 60) {
      return intl.formatMessage({ id: 'drivepopup.estimate.minutes' }, { minutes: estimate })
    } else if (estimate >= 60) {
      const time = timeConvert(estimate)
      return intl.formatMessage(
        { id: 'drivepopup.estimate.morethananhour' },
        { hours: time.hours, minutes: time.minutes }
      )
    }
  }, [estimate, intl])

  return <span className={styles.container}>{text}</span>
}

export default TimeEstimate
