import { AccountType, ArchitectSubscriptionType, BrandUserRole, BrandUserWithBrandsDTO } from '@addsome/dtos/dist'
import { ArchitectType } from '@addsome/dtos/dist/enum/architectType'
import { Button, InputField, Option, Select, Size, TextArea, Theme, Popconfirm, Loading } from '@addsome/ui-kit'
import { Menu } from 'antd'
import Form from 'antd/lib/form'
import { Field, FieldProps, Formik, FormikErrors } from 'formik'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Route, RouteComponentProps } from 'react-router-dom'
import * as yup from 'yup'
import notification from 'antd/lib/notification'
import UploadMedia, { UploadType } from '../../components/UploadMedia/UploadMedia'
import { IRootState } from '../../redux'
import { mediaUploadUrl } from '../../services/media'
import BrandUserProfilePageLayout, { Columns } from '../../templates/ProfilePageLayout/BrandUserProfilePageLayout'
import useBrandUser from '../../templates/ProfilePageLayout/useBrandUser'
import usePush from '../../hooks/usePush'
import useLoggedUser from '../../hooks/useUser'
import Routes from '../../utils/routes'
import styles from './BrandUserProfileEditPage.module.scss'
import { Validator } from 'class-validator';

interface IMatchParams {
  id: string
}

type IProps = RouteComponentProps<IMatchParams>

const ProfileFormField: React.FC<{ fieldId: string; placeholder?: string; password?: boolean }> = ({
  fieldId,
  placeholder,
  password = false
}) => {
  const intl = useIntl()

  return (
    <Form.Item label={intl.formatMessage({ id: `profileEdit.form.${fieldId}` })} colon={false}>
      <Field
        name={fieldId}
        placeholder={placeholder}
        render={({ field, form }: FieldProps) => (
          <InputField
            {...field}
            type={password ? 'password' : 'text'}
            error={(form.errors[field.name] as string) || ''}
            placeholder={intl.formatMessage({
              id: `profileEdit.form.${fieldId}`
            })}
          />
        )}
      />
    </Form.Item>
  )
}

const BrandUserProfileEditPage: React.FC<IProps> = ({ match }) => {
  const intl = useIntl()
  const { brandUser, patchBrandUser, loading, profilePicture } = useBrandUser(match.params.id)
  const { user, accountType, setUser } = useLoggedUser()
  const { push } = usePush()
  const formikRef = useRef<Formik>(null)
  const { token, pathname } = useSelector((state: IRootState) => ({
    token: state.authState.token,
    pathname: state.router.location.pathname
  }))

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        lastName: yup
          .string()
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        firstName: yup
          .string()
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        email: yup
          .string()
          .email(intl.formatMessage({ id: 'validation.email.notValid' }))
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        phone: yup
          .string()
          .test('len', intl.formatMessage({ id: 'register.address.phone.error' }), val => {
            const validator = new Validator();
            return val == null || validator.isPhoneNumber(val, 'ZZ');
          })
          .length(12),
      }),
    [intl]
  )

  useEffect(() => {
    if (!user || !accountType) return
    if (
      (accountType === AccountType.Architect && user.id !== match.params.id) ||
      accountType === AccountType.BrandUser
    ) {
      push(`${Routes.Architects}/${match.params.id}`)
    }
  }, [match.params.id, user, accountType, push])

  return (
    brandUser && (
      <BrandUserProfilePageLayout
        showEmail
        canToggleUse={accountType === AccountType.AddsomeUser}
        brandUser={brandUser}
        subHeader={
          <Menu
            className={styles.subHeader}
            theme="light"
            mode="horizontal"
            selectedKeys={[
              `/${pathname
                .split('/')
                .slice(3)
                .join('/')}`
            ]}
          >
            <Menu.Item
              key="/edit"
              className={styles.item}
              onClick={() => push(`${Routes.BrandUsers}/${match.params.id}/edit`)}
            >
              {intl.formatMessage({ id: 'profileEdit.navBar.profile' })}
            </Menu.Item>
          </Menu>
        }
      >
        <Route
          path={`${Routes.BrandUsers}/:id/edit`}
          exact
          component={() => (
            <Columns
              leftSectionTitle={intl.formatMessage({ id: 'profile.title.photo' })}
              rightSectionTitle={intl.formatMessage({ id: 'profile.title.informations' })}
              leftSectionContent={
                <>
                  <div className={styles.profilePicture}>
                    <img src={profilePicture} />
                  </div>

                  <UploadMedia
                    type={UploadType.GHOST}
                    action={mediaUploadUrl}
                    disabled={loading}
                    afterUpload={media =>
                      patchBrandUser({
                        account: { profilePictureId: media.id }
                      }).then(u => {
                        // if current user is the currently modified user,
                        // set its new profile picture
                        console.log('match.params.id === user!.id', match.params.id === user!.id)
                        if (match.params.id === user!.id) {
                          setUser(u as BrandUserWithBrandsDTO)
                        }
                      })
                    }
                    token={token}
                    className={styles.uploadButton}
                  />
                  <Button
                    uppercase
                    disabled={loading}
                    block
                    className={styles.uploadButton}
                    theme={Theme.PRIMARY}
                    title={intl.formatMessage({ id: 'global.save' })}
                    onClick={() => {
                      if (formikRef && formikRef.current) {
                        formikRef.current!.executeSubmit()
                      }
                    }}
                  >
                    {intl.formatMessage({ id: 'global.save' })}
                  </Button>
                </>
              }
              rightSectionContent={
                <Formik
                  ref={formikRef}
                  validationSchema={validationSchema}
                  initialValues={{
                    lastName: brandUser!.account.lastName || '',
                    firstName: brandUser!.account.firstName || '',
                    email: brandUser!.account.email || '',
                    phone: brandUser!.account.phone || '',
                    role: brandUser!.role,
                    // brandIds: brandUser!.brandIds
                  }}
                  onSubmit={({
                    lastName,
                    firstName,
                    email,
                    phone,
                    role,
                  }) => {
                    patchBrandUser({
                      account: { lastName, firstName, email, phone: phone || undefined },
                      role: role,
                    }).then(bu =>
                      push('/users?tab=brandusers')
                    )
                  }}
                  render={({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <ProfileFormField fieldId="firstName" />

                      <ProfileFormField fieldId="lastName" />

                      <ProfileFormField fieldId="email" />

                      <ProfileFormField fieldId="phone" placeholder="+33" />

                      <Form.Item
                        label={intl.formatMessage({ id: `register.brandUser.role` })}
                        colon={false}
                      >
                        <Field
                          name="role"
                          render={({
                            field: { name, value },
                            form: { setFieldValue }
                          }: FieldProps) => (
                            <Select
                              value={value}
                              onChange={newType => setFieldValue(name, newType)}
                              placeholder={intl.formatMessage({ id: `register.brandUser.role` })}
                            >
                              {Object.keys(BrandUserRole).map(role => (
                                <Option key={role} value={BrandUserRole[role]}>
                                  {role}
                                </Option>
                              ))}
                            </Select>
                          )}
                        />
                      </Form.Item>
                    </Form>
                  )}
                />
              }
            />
          )}
        />
      </BrandUserProfilePageLayout>
    )
  )
}

export default memo(BrandUserProfileEditPage)
