import { AutoComplete, TagsList } from '@addsome/ui-kit'
import { SelectValue } from 'antd/lib/select'
import React from 'react'

interface IProps<T extends { id: string }> {
  ids: string[]
  source: T[]
  getLabel: (t: T) => string
  onSearch: (v: SelectValue) => void
  fields: T[]
  onChange: (ids: string[]) => void
}

export default class TagsAutoComplete<T extends { id: string }> extends React.Component<IProps<T>> {
  public render() {
    const { ids, source, getLabel, onChange, onSearch, fields } = this.props
    return (
      <div>
        <TagsList
          values={ids.map(id => {
            const item = source.find(tag => tag.id === id)
            return item ? getLabel(item) : 'undefined'
          })}
          onClose={index => {
            const updatedIds = [...ids]
            updatedIds.splice(index, 1)
            onChange(updatedIds)
          }}
        />
        <AutoComplete
          onSearch={onSearch}
          fields={fields.map(tag => ({
            label: getLabel(tag),
            key: tag.id
          }))}
          onSelect={(v: SelectValue) => {
            const newId = v.toString()
            if (!ids.includes(newId)) onChange([...ids, newId])
          }}
        />
      </div>
    )
  }
}
