"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var brand_1 = require("./brand");

var brandUser_1 = require("./brandUser");

var BrandProjectDTO = function BrandProjectDTO() {
  _classCallCheck(this, BrandProjectDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], BrandProjectDTO.prototype, "id", void 0);

__decorate([class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return brand_1.BrandDTO;
}), __metadata("design:type", brand_1.BrandDTO)], BrandProjectDTO.prototype, "brand", void 0);

__decorate([class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return brandUser_1.BrandUserDTO;
}), __metadata("design:type", brandUser_1.BrandUserDTO)], BrandProjectDTO.prototype, "brandUser", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], BrandProjectDTO.prototype, "createdAt", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], BrandProjectDTO.prototype, "updatedAt", void 0);

exports.BrandProjectDTO = BrandProjectDTO;

var BrandProjectCreationDTO = function BrandProjectCreationDTO() {
  _classCallCheck(this, BrandProjectCreationDTO);
};

__decorate([class_validator_1.IsDefined(), class_validator_1.IsUUID(), __metadata("design:type", String)], BrandProjectCreationDTO.prototype, "brandId", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], BrandProjectCreationDTO.prototype, "brandUserId", void 0);

exports.BrandProjectCreationDTO = BrandProjectCreationDTO;

var BrandProjectUpdateDTO = function BrandProjectUpdateDTO() {
  _classCallCheck(this, BrandProjectUpdateDTO);
};

exports.BrandProjectUpdateDTO = BrandProjectUpdateDTO;