import {AccountType, ProductLightExtendedDTO} from '@addsome/dtos'
import {architectProductHistory, IPaginationRequest} from '@addsome/redux-store'
import { Size } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import { FormattedMessage, injectIntl, useIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import PageHeader from '../../../components/PageHeaders/PageHeader'
import ProductsTable from '../../../components/Products/ProductsTable/ProductsTable'
import { IRootState } from '../../../redux'
import { updatePageTitle } from '../../../services/title'
import styles from './DownloadedProductsPage.module.scss'
import useLoggedUser from "../../../hooks/useUser";
import PageHeaderArchitectContent from "../../../components/PageHeaderArchitectContent/PageHeaderArchitectContent";
import {parse, stringify} from "querystring";
import usePush from "../../../hooks/usePush";
import {PaginationConfig, SorterResult} from "antd/lib/table";

type IProps = ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  ReturnType<typeof mapStateToProps>

const DownloadsProductsPage: FC<IProps> = ({
  fetchProductsDownloadHistory,
  accountType,
  accountId,
  queryParams,
  urlSearch,
  updateQuery,
  architectId
}) => {
  const intl = useIntl()
  const [products, setProducts] = useState<ProductLightExtendedDTO[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const { updateUrl, router } = usePush();
  const [searchTerm, setSearchTerm] = useState("");

  const pageSize = 20;
  const [currentPage, setCurrentPage] = useState(Math.max(1, parseInt(queryParams.page, 10)) || 1);
  const [request, setRequest] = useState<IPaginationRequest>({
    skip: (currentPage - 1) * pageSize,
    take: pageSize,
    order: queryParams.order ? JSON.parse(queryParams.order) : { downloadedAt: -1 },
    filters: {
      name: [queryParams.search],
      ...JSON.parse(queryParams.filters || '{}')
    }
  });
  const [prevSearch, setPrevSearch] = useState(urlSearch);

  useEffect(() => {
    setLoading(true)
    fetchProductsDownloadHistory(architectId, undefined).then(res => {
      setProducts(res.data)
      setTotal(res.total)
      setLoading(false)
    })
    updatePageTitle(intl.formatMessage({ id: 'downloadspage.title' }))
  }, [architectId, accountType, intl])

  const memoizedHandleSearch = (searchPattern: string) => {
    const newRequest: IPaginationRequest = {
      ...request,
      skip: 0,
      take: pageSize,
      filters: {...request.filters, name: [searchPattern]}
    };
    setRequest(newRequest);
    setCurrentPage(1);
    setLoading(true)
    fetchProductsDownloadHistory(architectId, newRequest).then(res => {
      setProducts(res.data)
      setTotal(res.total)
      setLoading(false)
    });

    const newParams = {...queryParams, search: searchPattern, page: 1};
    setPrevSearch('?' + stringify(newParams));
    updateQuery(stringify(newParams));
  }

  const memoizedHandleTableChange = (
    pagination: PaginationConfig,
    filter: Record<keyof ProductLightExtendedDTO, string[]>,
    sorter: SorterResult<ProductLightExtendedDTO>
  ) => {
    setProducts([])
    const newRequest: IPaginationRequest = {
      take: pageSize,
      skip: ((pagination.current || 1) - 1) * pageSize,
      order: sorter.column
        ? { [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1 }
        : {},
      filters: { ...request.filters, ...filter }
    };
    setRequest(newRequest);
    setLoading(true);
    fetchProductsDownloadHistory(architectId, newRequest).then(res => {
      setProducts(res.data);
      setLoading(false);
    });
    if (pagination.current) setCurrentPage(pagination.current);

    const newParams = {
      ...queryParams,
      page: pagination.current || currentPage,
      order: JSON.stringify(newRequest.order),
      filters: JSON.stringify(filter)
    };
    setPrevSearch('?' + stringify(newParams));
    updateQuery(stringify(newParams));
  };


  const memoizedProductsTable = useMemo(() => (
    <ProductsTable
      products={products}
      loading={loading}
      total={total}
      currentPage={currentPage}
      pageSize={20}
      onTableChange={memoizedHandleTableChange}
      readonly={true}
      handleSearch={memoizedHandleSearch}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      sortBy={request.order}
    />
  ), [products, loading, total, currentPage, memoizedHandleTableChange, memoizedHandleSearch, searchTerm, setSearchTerm]);

  return (
    <>
      <PageHeaderArchitectContent />
      <div className={styles.container}>
        {memoizedProductsTable}
      </div>
    </>
  );

}

const mapStateToProps = (state: IRootState) => ({
  accountId: state.authState.accountId,
  accountType: state.authState.type,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string; },
  urlSearch: state.router.location.search,
  architectId: state.authState.typeId,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProductsDownloadHistory: (architectId: string, request: IPaginationRequest|undefined) =>
    dispatch(architectProductHistory.fetchArchitectProductDownloads(architectId, request)),
  push: (to: string) => dispatch(push(to)),
  updateQuery: (params: string) => dispatch(push({ search: params })),
})

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DownloadsProductsPage)
)
