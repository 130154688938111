import {
  ProductRelationshipsExtendedDTO,
  ProductUpdateDTO,
  ProductPictureType
} from '@addsome/dtos'
import { product as productActions, productViewer } from '@addsome/redux-store'
import { Button, Card, Theme } from '@addsome/ui-kit'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import thumbnailPlaceholder from '../../../assets/images/donnees-2D.svg'
import { IRootState } from '../../../redux'
import Price from '../../Price'
import styles from './ProductVisuals.module.scss'
import { getCloudImageUrl } from '../../../utils/cloudImage'
import {FormattedMessage} from 'react-intl'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    product: ProductRelationshipsExtendedDTO
  }

const ProductVisuals: React.FC<IProps> = ({ product, setDisplayViewer }) => {
  return (
    <div className={styles.visuals}>
      <Card
        image={
          product.thumbnail && product.thumbnail.media.url
            ? getCloudImageUrl(product.thumbnail.media.url, 750)
            : thumbnailPlaceholder
        }
        imgFit={
          (product.thumbnail &&
            product.thumbnail.media &&
            product.thumbnail.type !== ProductPictureType.Ambiance) ||
          undefined
        }
        imgBgColor={
          (product.thumbnail && product.thumbnail.media && product.thumbnail.hexaColor) || undefined
        }
        desc={product.brand && product.brand.name}
        side={
          product.suggestedPrice && (
            <div className={styles.price}>
              <Price amount={product.suggestedPrice} /> HT
            </div>
          )
        }
        ratio={[7, 5]}
        inner
      />

      {/* Quickfix: we check if haveObjectProductModelBundle is strictly different from false because
      when we update the product we first get a ProductExtendedDTO and not a ProductRelationshipsExtendedDTO
      so haveObjectProductModelBundle is undefined, this fix the button disappearing and appearing again */}
      {product.haveObjectProductModelBundle !== false && (
        <Button
          theme={Theme.PRIMARY}
          block
          size="large"
          uppercase
          onClick={() => {
            setDisplayViewer(true)
          }}
        >
          <FormattedMessage id='global.3dpreview' />
        </Button>
      )}
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  token: state.authState.token
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  patchProduct: (id: string, payload: ProductUpdateDTO) =>
    dispatch(productActions.thunks.patchValue(id, payload)),
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewer.setDisplayViewer(displayViewer))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductVisuals)
