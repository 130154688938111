import { ProductRelationshipsExtendedDTO, AddsomeUserDTO, ArchitectDTO, BrandUserWithBrandsDTO, ObjectProductUpdateDTO, ObjectProductDimensionUpdateDTO, ObjectProductDimensionDTO } from '@addsome/dtos'
import { Button, Container, Theme, Tabs } from '@addsome/ui-kit'
import { Form, Icon } from 'antd'
import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux';
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import ObjectProductFieldsGroup from '../ObjectProductFieldsGroup.tsx/ObjectProductFieldsGroup'
import styles from './ObjectProductForm.module.scss'
import { FormattedMessage } from 'react-intl'
import { Loading } from '@addsome/ui-kit'
import { setUserLog } from '../../../services/userLog';

interface IProps {
  objectProductDimensions: ObjectProductDimensionDTO[],
  objectProduct: ObjectProductUpdateDTO,
  product: ProductRelationshipsExtendedDTO,
  user: AddsomeUserDTO | ArchitectDTO | BrandUserWithBrandsDTO | null,
  loading: boolean,
  canEdit?: boolean,
  onSubmit: (objectProductDimension: ObjectProductDimensionDTO) => Promise<void> | void
  addDimension: () => Promise<void> | void,
  removeDimension: (objectProductDimensionId: string) => Promise<void> | void,
  updateDimension: (objectProductDimensionId: string, objectProductDimension: ObjectProductDimensionUpdateDTO) => Promise<void> | void,
}

interface IState {
  activeKey: string;
  dimensions: ObjectProductDimensionDTO[];
}

const ObjectProductFormTest: React.FC<IProps> = ({
  objectProductDimensions,
  product,
  user,
  loading,
  canEdit,
  addDimension,
  removeDimension,
  updateDimension,
}) => {

  const [activeKey, setActiveKey] = useState('dimension1')
  const [dimensions, setDimensions] = useState<ObjectProductDimensionDTO[]>([])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!dimensions.length && objectProductDimensions && objectProductDimensions.length) {
      const copyDimensions = objectProductDimensions.map((dim) => convertObjectProductDimensionForFront(dim))
      setDimensions(copyDimensions)
    }
    if (dimensions.length && dimensions.length < objectProductDimensions.length) {
      const lastElementIdx = objectProductDimensions.length - 1
      let copyDimensions = [...dimensions]
      copyDimensions[dimensions.length] = objectProductDimensions[lastElementIdx]
      setDimensions(copyDimensions)
      changeTab(`dimension${objectProductDimensions.length}`)
    }
  }, [objectProductDimensions])

  const validationSchema = yup.object().shape({
    width: yup
      .number()
      .typeError('')
      .positive()
      .nullable(),
    height: yup
      .number()
      .typeError('')
      .positive()
      .nullable(),
    depth: yup
      .number()
      .typeError('')
      .positive()
      .nullable(),
    seatHeight: yup
      .number()
      .typeError('')
      .positive()
      .nullable(),
    diameter: yup
      .number()
      .typeError('')
      .positive()
      .nullable(),
    weight: yup
      .number()
      .typeError('')
      .positive()
      .nullable()
  })

  const convertObjectProductDimensionForFront = (opd) => {
    return {
      ...opd,
      width: opd.width && opd.width / 10,
      height: opd.height && opd.height / 10,
      depth: opd.depth && opd.depth / 10,
      seatHeight: opd.seatHeight && opd.seatHeight / 10,
      diameter: opd.diameter && opd.diameter / 10,
      weight: opd.weight && opd.weight / 1000
    }
  }

  const convertObjectProductDimensionForApi = (opd) => {
    return {
      ...opd,
      width: opd.width && Math.round(opd.width * 10),
      height: opd.height && Math.round(opd.height * 10),
      depth: opd.depth && Math.round(opd.depth * 10),
      seatHeight: opd.seatHeight && Math.round(opd.seatHeight * 10),
      diameter: opd.diameter && Math.round(opd.diameter * 10),
      weight: opd.weight && Math.round(opd.weight * 1000)
    }
  }

  const changeTab = (tab: string) => {
    setActiveKey(tab);
  };

  const saveChanges = () => {
    if (dimensions) {
      setUserLog(user!.account.id, product.id, 'dimension updated')
      dimensions.forEach((dimension) => {
        updateDimension(dimension.id, convertObjectProductDimensionForApi(dimension))
      })
    }
  }

  const removeArrayDimension = async (id) => {
    await removeDimension(id)
    let dimensionsCopy = dimensions.filter((d, idx) => d.id !== id)
    setDimensions(dimensionsCopy)

    changeTab('dimension1')
  }

  const addArrayDimension = async () => {
    await addDimension()
  }

  const updateArray = (e, values) => {
    values[e.target.name] = parseFloat(e.target.value)
    if (dimensions) {
      const changedIdx = dimensions.findIndex(x => x.id === values.id)
      let dimensionsCopy = [...dimensions]
      dimensionsCopy[changedIdx] = values

      setDimensions(dimensionsCopy)
    }
  }

  return (
    <div className={styles.objectProductForm}>
      <Container>
        <Tabs activeKey={activeKey} onChange={changeTab} className={styles.antTabsBar}>
          {dimensions.map((dim, idx) => (
            <Tabs.TabPane
              tab={(
                <span>
                  Dimension{idx + 1}&nbsp;
                  {objectProductDimensions.length > 1 && <Icon
                    type="close"
                    onClick={() => removeArrayDimension(dim.id)}
                  />}
                </span>
              )}
              key={`dimension${idx + 1}`}
            >
              {loading ? (
                <div className={styles.productLoading}>
                  <Loading />
                </div>
              ) : (
                <Formik
                  initialValues={dim}
                  key={dim.id}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={(values: ObjectProductDimensionDTO, { setSubmitting }) => {
                    console.log(values)
                  }}
                >
                  {formik => (
                    <Form
                      id="myForm"
                      layout="horizontal"
                      onSubmit={formik.handleSubmit}
                      onChange={(e) => updateArray(e, formik.values)}
                      noValidate
                    >
                      <ObjectProductFieldsGroup objectProduct={formik.values} formik={formik} />
                    </Form>
                  )}
                </Formik>
              )}
            </Tabs.TabPane>
          ))}
        </Tabs>
        <Button
          title={""}
          theme={Theme.PRIMARY}
          shape="circle"
          aria-label="Add"
          icon="plus"
          className={styles.add}
          onClick={async () => addArrayDimension()}
        />
      </Container>
      <Button
        theme={Theme.PRIMARY}
        block
        size="large"
        uppercase
        onClick={() => saveChanges()}
      >
        <FormattedMessage id='productpage.panel.saveChanges' />
      </Button>
    </div>
  )
}

export default connect(
  null,
  null,
)(ObjectProductFormTest)
