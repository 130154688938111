import { SkeletonCard } from '@addsome/ui-kit'
import styles from './SkeletonBrandCardsList.module.scss'
import classnames from 'classnames'
import React from 'react'

export const SkeletonBrandCardsList: React.FC<{
  className?: string
  nbItems: number
}> = ({ className, nbItems }) => (
  <div className={classnames(styles.container, className)}>
    {[...Array(nbItems)].map((_, i) => (
      <SkeletonCard key={i} hideInfos className={styles.card} />
    ))}
  </div>
)

export default SkeletonBrandCardsList
