import {
  ProductLightRelationshipsExtendedDTO,
  ProductPictureCreationDTO,
  ProductUpdateDTO
} from '@addsome/dtos'
import { product as productActions, productViewer } from '@addsome/redux-store'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import MediaGallery from '../../MediaGallery/MediaGallery'
import styles from './ProductMedia.module.scss'
import useLoggedUser from '../../../hooks/useUser'
import { setUserLog } from '../../../services/userLog';

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    product: ProductLightRelationshipsExtendedDTO
    readonly?: boolean
  }

const ProductMedia: React.FC<IProps> = ({
  product,
  readonly,
  candDelete,
  patchProduct,
  setDisplayViewer,
  setSelectedPictureId,
  setThumbnailPictureId,
  thumbnailPictureId,
  token,
  fetchProduct
}) => {
  const handlePicturesChange = async (pictures: ProductPictureCreationDTO[]) => {
    return patchProduct(product.id, { pictures });
  };

  const { user } = useLoggedUser();

  return (
    <div className={styles.productMedia}>
      <MediaGallery
        pictures={product.pictures || []}
        product={product}
        onChange={handlePicturesChange}
        thumbnailPictureId={thumbnailPictureId}
        readonly={readonly}
        user={user}
        onClick={picture => {
          setSelectedPictureId(picture.id);
          setDisplayViewer(true);
        }}
        setImageAsThumbnail={(pictureId, mediaId) => {
          setThumbnailPictureId(pictureId);
          setUserLog(user!.account.id, product.id, 'thumbnail changed', mediaId)
        }}
        getAllProducts={productId => {
          fetchProduct(productId);
        }}
        token={token}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  token: state.authState.token,
  thumbnailPictureId: state.productViewerState.thumbnailPictureId
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProduct: (id: string) => dispatch(productActions.thunks.fetchValue(id)),
  patchProduct: (id: string, payload: ProductUpdateDTO) =>
    dispatch(productActions.thunks.patchValue(id, payload)),
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewer.setDisplayViewer(displayViewer)),
  setSelectedPictureId: (pictureId: string) =>
    dispatch(productViewer.setSelectedPictureId(pictureId)),
  setThumbnailPictureId: (pictureId: string) =>
    dispatch(productViewer.setThumbnailByPictureId(pictureId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductMedia);
