"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationMessages = exports.NotificationType = exports.ActionTypes = void 0;
var ActionTypes;

(function (ActionTypes) {
  ActionTypes["SET_NOTIFICATION"] = "SET_NOTIFICATION";
  ActionTypes["UNSET_NOTIFICATION"] = "UNSET_NOTIFICATION";
  ActionTypes["ADD_LABELS"] = "ADD_LABELS";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));

var NotificationType;

(function (NotificationType) {
  NotificationType["SUCCESS"] = "success";
  NotificationType["INFO"] = "info";
  NotificationType["WARNING"] = "warning";
  NotificationType["ERROR"] = "error";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));

var NotificationMessages;

(function (NotificationMessages) {
  NotificationMessages["FETCH_VALUES_SUCCESS"] = "fetchValues.success";
  NotificationMessages["FETCH_VALUES_ERROR"] = "fetchValues.error";
  NotificationMessages["FETCH_VALUE_SUCCESS"] = "fetchValue.success";
  NotificationMessages["FETCH_VALUE_ERROR"] = "fetchValue.error";
  NotificationMessages["CREATE_SUCCESS"] = "create.success";
  NotificationMessages["CREATE_ERROR"] = "create.error";
  NotificationMessages["PATCH_SUCCESS"] = "patch.success";
  NotificationMessages["PATCH_ERROR"] = "patch.error";
  NotificationMessages["DELETE_SUCCESS"] = "delete.success";
  NotificationMessages["DELETE_ERROR"] = "delete.error";
})(NotificationMessages = exports.NotificationMessages || (exports.NotificationMessages = {}));