import { apiRequest } from '@addsome/redux-store'

export default function searchSuggested<T>(id: string, query: string): Promise<T[]> {
  return apiRequest<T[]>(
    'GET',
    `${id}/suggest`,
    {},
    {
      query
    }
  )
}
