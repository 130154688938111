"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.unsetArchitectClient = exports.setArchitectClient = exports.setArchitectClients = void 0;

var model_1 = require("./model");

var setArchitectClients = function setArchitectClients(clients, total) {
  return {
    type: model_1.ActionsTypes.SET_ARCHITECT_CLIENTS,
    clients: clients,
    total: total
  };
};

exports.setArchitectClients = setArchitectClients;

var setArchitectClient = function setArchitectClient(client) {
  return {
    type: model_1.ActionsTypes.SET_ARCHITECT_CLIENT,
    client: client
  };
};

exports.setArchitectClient = setArchitectClient;

var unsetArchitectClient = function unsetArchitectClient() {
  return {
    type: model_1.ActionsTypes.UNSET_ARCHITECT_CLIENT
  };
};

exports.unsetArchitectClient = unsetArchitectClient;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_ARCHITECT_CLIENT_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;