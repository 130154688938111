import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { getResizedPreview } from '../../utils/cloudImage';
import { Icon, Input } from "antd";
import styles from './CollectionMenu.module.scss'
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import heartFullWhite from '../../assets/images/heartFullWhite.png'
import heartFullBlack from '../../assets/images/heartFullBlack.png'
import heartEmptyWhite from '../../assets/images/heartEmptyWhite.png'
import heartEmptyBlack from '../../assets/images/heartEmptyBlack.png'
import arrowUp from '../../assets/images/arrowUp.png'
import arrowDown from '../../assets/images/arrowDown.png'
import addSquare from '../../assets/images/addSquare.png'
import { useIntl } from "react-intl";
import { dividerClasses } from '@mui/material';

export default function CollectionMenu({ collections, createNewCollection, productId, addProductToCollection, handleRemoveFromFavorites }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const [collectionName, setCollectionName] = useState<string>();
  const [selectedCollection, setSelectedCollection] = useState({ name: "", id: null });
  const intl = useIntl();

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    const productId = event.currentTarget.getAttribute('data-productid');
    setAnchorEl(document.getElementById(`lock-button-${productId}`));
  };

  const isProductInCollection = (collection) => {
    if (collection && collection.products) {
      return collection.products.some((product) => product.id === productId);
    }
    return false;
  }

  useEffect(() => {
    if (collections.length > 0) {
      setSelectedCollection(collections[0]);
    }
  }, [collections]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    collection: any
  ) => {
    setSelectedIndex(index);
    setSelectedCollection(collection);
    setAnchorEl(null);
    if (isProductInCollection(collection)) {
      handleRemoveFromFavorites(productId, collection.id);
    } else {
      addProductToCollection(productId, collection.id);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getThumbnail = (collection) => {
    if (collection.products.length > 0 && collection.products[0].thumbnail && collection.products[0].thumbnail.media) {
      return getResizedPreview(40, 40, collection.products[0].thumbnail.media.url)
    } else {
      return "";
    }
  }

  const handleCreateNewCollection = (e) => {
    createNewCollection(collectionName, productId);
    setCollectionName("");
  }

  useEffect(() => {
    const productCard = document.getElementById(productId);
    if (productCard) {
      const menuHeader = document.getElementById(`nav-${productId}`);
      productCard.addEventListener("mouseover", (event) => {
        if (menuHeader) {
          menuHeader.style.visibility = "inherit";
        }
      });
      productCard.addEventListener("mouseleave", (event) => {
        const lockMenu = document.getElementById(`lock-menu-${productId}`);
        if (menuHeader && !lockMenu) {
          menuHeader.style.visibility = "hidden";
        }
      });
    }
  })

  return (
    <div id={`container-${productId}`}>
      <List
        id={`nav-${productId}`}
        component="nav"
        className={`${styles.list} ${isProductInCollection(selectedCollection) ? styles.listItemAddedToFav : styles.listItemNotAddedToFav}`}
        onClick={(e) => e.preventDefault()}
      >
        <ListItem
          id={`lock-button-${productId}`}
          aria-haspopup="listbox"
          aria-controls={`lock-menu-${productId}`}
          aria-expanded={open ? 'true' : undefined}
          style={{ color: "white", paddingBottom: 5 }}
        >
          <ListItemText>
            <Typography variant="body2" style={{ color: 'white', display: "flex", justifyContent: "space-between" }}>
              <span>
                <span data-productid={productId} onClick={(event) => { handleClickListItem(event); event.preventDefault() }}>{selectedCollection && selectedCollection.name ? selectedCollection.name : ''}</span>
                {open ? <span style={{ marginLeft: 5 }}><Icon type=" " /> <img src={arrowUp} /></span> :
                  <img src={arrowDown} style={{ marginLeft: 8 }} data-productid={productId} onClick={(event) => handleClickListItem(event)} />}
              </span>
              {isProductInCollection(selectedCollection) ?
                <img src={heartFullWhite} className={styles.heart} onClick={(e) => handleRemoveFromFavorites(productId, selectedCollection.id)} /> :
                <img src={heartEmptyWhite} className={styles.heart} onClick={(e) => addProductToCollection(productId, selectedCollection.id)} />}
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Menu
        style={{ maxHeight: 290, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        className={styles.menu}
        id={`lock-menu-${productId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        <div style={{ maxHeight: "200px", overflowX: 'hidden', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          {collections.map((collection, index) => (
            <MenuItem
              className={styles.menuItem}
              key={`${collection.id}-${productId}`}
              value={collection.id}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index, collection)}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span style={{ border: 1, color: "grey" }}>
                {getThumbnail(collection) ? <img style={{ width: 40, height: 40, marginRight: 15 }} src={getThumbnail(collection)} /> :
                  <img style={{ width: 40, height: 40, marginRight: 15 }} />
                }
                <span>{collection.name}</span>
              </span>
              {isProductInCollection(collection) ? (
                <img src={heartFullBlack} className={styles.heart} />
              ) : (
                <img src={heartEmptyBlack} className={styles.heart} />
              )}
            </MenuItem>
          ))}
        </div>

        <MenuItem
          style={{ display: "flex", justifyContent: "space-between", position: "sticky", bottom: 0 }}
          id={`test-${productId}`}
          className={styles.menuItem}>
          <Input
            className={styles.inputStyle}
            defaultValue={collectionName || ""}
            placeholder={intl.formatMessage({ id: "architect.collection.add.new.collection.placeholder" })}
            style={{ backgroundColor: "#292C330D", border: "none", outline: "none" }}
            onBlur={(e) => setCollectionName(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <img src={addSquare} style={{ fontSize: 20, marginLeft: 10 }} onClick={(e) => handleCreateNewCollection(e)} />
        </MenuItem>
      </Menu>
    </div>
  );
}
