"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var BrandMarket;

(function (BrandMarket) {
  BrandMarket["GeneralPublic"] = "generalPublic";
  BrandMarket["Professional"] = "professional";
})(BrandMarket = exports.BrandMarket || (exports.BrandMarket = {}));