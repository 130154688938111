"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNotifications = exports.thunks = exports.reducer = void 0;

var crud_1 = require("./crud");

var notification_1 = require("./notification");

var KEY = 'STYLE_TAG';
exports.reducer = (0, crud_1.default)(KEY);
exports.thunks = new crud_1.ThunkHandler({
  url: '/style-tags',
  key: KEY
});

function createNotifications(dispatch, message) {
  dispatch((0, notification_1.addLabels)(KEY, {
    fetchValues: {
      error: message.errorRetrievingStyleTags
    },
    fetchValue: {
      error: message.errorRetrievingAStyleTag
    },
    create: {
      success: message.addedStyleTag,
      error: message.errorCreatingStyleTag
    },
    patch: {
      success: message.updatedStyleTag,
      error: message.errorRegisteringStyleTag
    },
    delete: {
      success: message.removedStyleTag,
      error: message.errorDeletingStyleTag
    }
  }));
}

exports.createNotifications = createNotifications;