import { drive } from '@addsome/redux-store'
import { UploadStatus } from '@addsome/redux-store/dist/store/drive'
import { InvisibleButton, Modal } from '@addsome/ui-kit'
import Icon from 'antd/lib/icon'
import classNames from 'classnames'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../../redux'
import styles from './DriveUploadPopup.module.scss'
import UploadItem from './UploadItem'
import TimeEstimate from './TimeEstimate'

function handleUnload(e: BeforeUnloadEvent) {
  e.returnValue = 'Cancel upload'
}

const DriveUploadPopup: FC = () => {
  const files = useSelector((state: IRootState) => state.driveState.queuedFiles)
  const uploadSpeed = useSelector((state: IRootState) => state.driveState.uploadSpeed)
  const bytesUploaded = useSelector((state: IRootState) => state.driveState.bytesUploaded)
  const dispatch = useDispatch()
  const [isReduced, setIsReduced] = useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const intl = useIntl()

  const estimate = useMemo(() => {
    const size =
      files.reduce((acc: number, queueFile) => {
        if (!queueFile.file) return acc
        return acc + queueFile.file.size
      }, 0) - bytesUploaded
    return Math.round(size / uploadSpeed / 60)
  }, [files, uploadSpeed, bytesUploaded])

  const showPopup = useMemo(() => {
    return files.length > 0
  }, [files])

  useEffect(() => {
    if (
      files.length > 0 &&
      files[0].brandSlug &&
      files[0].file &&
      files[0].status === UploadStatus.Waiting
    ) {
      dispatch(drive.uploadFile(files[0].brandSlug, files[0].file, files[0].path))
    }
  }, [dispatch, files])

  useEffect(() => {
    if (showPopup) {
      window.addEventListener('beforeunload', handleUnload)
    } else {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [showPopup])

  return (
    <div
      className={classNames(styles.container, {
        [styles.hidden]: !showPopup,
        [styles.reduced]: isReduced
      })}
    >
      <div className={styles.header}>
        <span className={styles.title}>
          {files.length > 1
            ? intl.formatMessage({ id: 'drivepopup.importplural' })
            : intl.formatMessage({ id: 'drivepopup.import' })}
        </span>
        <div className={styles.actions}>
          <InvisibleButton onClick={() => setIsReduced(!isReduced)}>
            <Icon type="up" rotate={isReduced ? 180 : 0} className={styles.icon} />
          </InvisibleButton>
          <InvisibleButton onClick={() => setIsConfirmationVisible(true)}>
            <Icon type="close" />
          </InvisibleButton>
        </div>
      </div>
      <div className={styles.estimate}>
        <TimeEstimate estimate={estimate} />
      </div>
      <div className={styles.list}>
        {files.map(file => (
          <UploadItem key={file.name} file={file} />
        ))}
      </div>
      <Modal
        visible={isConfirmationVisible}
        onCancel={() => setIsConfirmationVisible(false)}
        onOk={() => {
          dispatch(drive.cancelFileUpload())
          setIsConfirmationVisible(false)
        }}
        okText={<FormattedMessage id="global.yes" />}
        cancelText={<FormattedMessage id="global.no" />}
        title={<FormattedMessage id="drivepopup.confirm" />}
        bodyStyle={{ display: 'none' }}
      />
    </div>
  )
}

export default DriveUploadPopup
