"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ArchitectType;

(function (ArchitectType) {
  ArchitectType["Architect"] = "architect";
  ArchitectType["InteriorArchitect"] = "interior-architect";
  ArchitectType["Designer"] = "designer";
  ArchitectType["Promoter"] = "promoter";
  ArchitectType["Hotelier"] = "hotelier";
})(ArchitectType = exports.ArchitectType || (exports.ArchitectType = {}));