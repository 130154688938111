import settings from '../settings'

export const getCloudImageUrl = (url: string, height: number) =>
  // settings.media.cloudimageToken
  //   ? `https://${settings.media.cloudimageToken}.cloudimg.io/height/${height}/x/${url}`
  //   : url
  url

export const getResizedPreview = (width: number, height: number, url: string) => {
  const max = width > height ? width : height

  return max > 2000 ? getCloudImageUrl(url, 2000) : getCloudImageUrl(url, max)
}
