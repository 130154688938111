import { Cell, DatePicker, InputField, InputNumber, Option, Select } from '@addsome/ui-kit'
import { Checkbox } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import get from 'lodash.get'
import moment from 'moment'
import React from 'react'
import { EditableContext } from './EditableTable'
import style from './EditableTable.module.scss'

interface IProps<T> {
  editing: boolean
  dataIndex: string
  editIndex?: string
  editValue?: string
  title: string
  inputType: string
  selectOptions?: ISelectOptions<any>
  record: T
}

export interface ISelectOptions<T> {
  values: T[]
  display: keyof T
  model: keyof T
}

export default class EditableCell<T> extends React.Component<IProps<T>, {}> {
  public render() {
    const { editing, dataIndex, title, record, editIndex, editValue, ...restProps } = this.props
    return (
      <EditableContext.Consumer>
        {({ form }) =>
          form && (
            <Cell {...restProps}>
              {editing ? (
                <FormItem className={style.formItem}>
                  {/* Generate an input in form */}
                  {form.getFieldDecorator(editIndex || dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: title
                      }
                    ],
                    initialValue: this.formatValue(get(record, editValue || dataIndex)),
                    valuePropName: this.getValuePropName()
                  })(this.getInput())}
                </FormItem>
              ) : (
                // When not editing, display value
                restProps.children
              )}
            </Cell>
          )
        }
      </EditableContext.Consumer>
    )
  }

  /**
   * Return an input corresponding to the cell's data type
   */
  private getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />
    } else if (this.props.inputType === 'date') {
      return <DatePicker />
    } else if (this.props.inputType === 'boolean') {
      return <Checkbox />
    } else if (this.props.inputType === 'select' && this.props.selectOptions) {
      const { selectOptions } = this.props
      return (
        <Select>
          {selectOptions.values.map(v => (
            <Option key={v[selectOptions.model]} value={v[selectOptions.model]}>
              {v[selectOptions.display]}
            </Option>
          ))}
        </Select>
      )
    }
    return <InputField />
  }

  /** Depending of the input type, returns on which props insert the value (default is "value") */
  private getValuePropName = () => {
    if (this.props.inputType === 'boolean') {
      return 'checked'
    }
    return 'value'
  }

  /** Format data for some inputs */
  private formatValue = (value: any) => {
    if (this.props.inputType === 'date') {
      return value ? moment(value) : undefined
    }
    return value
  }
}
