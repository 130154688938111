import { ArchitectCreationDTO } from '@addsome/dtos'
import { ArchitectType } from '@addsome/dtos/dist/enum/architectType';
import { Button, InputField, InvisibleButton, Theme, Select, Option, Size } from '@addsome/ui-kit'
import { Form, Icon } from 'antd'
import { Formik } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import * as yup from 'yup'
import styles from './RegisterInitForm.module.scss'
import Routes from '../../../utils/routes'
import { Link } from 'react-router-dom'
import { findAccountWithSameEmail } from '../../../services/architectAccount'
import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel';
import googlePng from "../../../assets/images/google.svg";
import facebookLogo from "../../../assets/images/facebookLogo.jpeg";

type IProps = WrappedComponentProps & {
  initialValues: ArchitectCreationDTO
  onSubmit: (architect: ArchitectCreationDTO) => void
  googleButtonWrapper: any,
  handleFacebook: any
}

const RegisterInitForm: React.FC<IProps> = ({ initialValues, onSubmit, intl, googleButtonWrapper, handleFacebook }) => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup
          .string()
          .required()
          .email(),
        password: yup
          .string()
          .required()
          .min(5),
        type: yup.string(),
      }),
    []
  )

  useEffect(() => {
    Mixpanel.track(MixpanelEvents.RegisterStep1)
  }, [])

  const [isEmailAlreadyUsed, setEmailAlreadyUsed] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  let checkEmailValidation = (e, isOnSubmit = false, values = {}) => {
    if (!e) {
      setEmailAlreadyUsed(false)
      return;
    }

    findAccountWithSameEmail(e).then((emailExists) => {
      if (emailExists) {
        setEmailAlreadyUsed(true)
      } else {
        setEmailAlreadyUsed(false)
        if (isOnSubmit) {
          onSubmit(values)
        }
      }
    })
  }

  const formRef = useRef(null);

  useEffect(() => {
    formRef.current.setValues({
      ...initialValues,
      email: initialValues.email,
      firstName: initialValues.firstName,
      lastName: initialValues.lastName,
      password: initialValues.password,
      type: initialValues.type,
    });
  }, [formRef, initialValues]);
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        checkEmailValidation(values.email, true, values);
      }}
      enableReinitialize
      ref={formRef}
    >
      {formik => (
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          layout="horizontal"
          className={styles.registerInitForm}
        >
          <Form.Item
            label={intl.formatMessage({ id: 'register.firstname' })}
            required
            colon={false}
          >
            <InputField
              required
              name="firstName"
              defaultValue={initialValues.firstName}
              value={formik.values.firstName}
              error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size="small"
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'register.lastname' })} colon={false}>
            <InputField
              required
              name="lastName"
              value={formik.values.lastName}
              defaultValue={initialValues.lastName}
              error={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'register.email' })} required colon={false}>
            <InputField
              name="email"
              defaultValue={initialValues.email}
              error={isEmailAlreadyUsed ? intl.formatMessage({ id: 'register.emailAlreadyUsed' }) : (formik.touched.email && formik.errors.email ? formik.errors.email : '')}
              required
              value={formik.values.email}
              onChange={(e) => { formik.handleChange(e); setEmailAlreadyUsed(false); }}
              onBlur={(value) => { checkEmailValidation(value.target.value); formik.handleBlur(value); }}
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'register.password' })} required colon={false}>
            <InputField
              required
              name="password"
              defaultValue={initialValues.password}
              type={showPassword ? 'text' : 'password'}
              error={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              addonAfter={
                <InvisibleButton
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label="Afficher / Masquer le mot de passe"
                  type="button"
                >
                  <Icon type={showPassword ? 'eye' : 'eye-invisible'} />
                </InvisibleButton>
              }
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'register.architect.type' })}>
            <InputField>
              <Select
                defaultValue={initialValues.type}
                onChange={value => formik.setFieldValue('type', value)}
              >
                <Option value={ArchitectType.Architect}>
                  {intl.formatMessage({ id: 'register.architect.type.architect' })}
                </Option>
                <Option value={ArchitectType.InteriorArchitect}>
                  {intl.formatMessage({ id: 'register.architect.type.interiorArchitect' })}
                </Option>
                <Option value={ArchitectType.Designer}>
                  {intl.formatMessage({ id: 'register.architect.type.designer' })}
                </Option>
                <Option value={ArchitectType.Promoter}>
                  {intl.formatMessage({ id: 'register.architect.type.promoter' })}
                </Option>
                <Option value={ArchitectType.Hotelier}>
                  {intl.formatMessage({ id: 'register.architect.type.hotelier' })}
                </Option>
              </Select>
            </InputField>
          </Form.Item>
          <p className={styles.legend}>
            <FormattedMessage id="register.required" />
          </p>
          <Button
            className={styles.submit}
            theme={Theme.PRIMARY}
            htmlType="submit"
            block
            size="large"
            uppercase
            disabled={Object.values(formik.errors).length > 0 || isEmailAlreadyUsed}
          >
            <FormattedMessage id="register.submit" />
          </Button>

          <Button
            id="google-button"
            theme={Theme.GHOST}
            className={styles.submit}
            uppercase
            size={Size.LARGE}
            style={{ display: 'flex', alignItems: 'center', width: "100%", marginTop: 20 }}
            onClick={() => { googleButtonWrapper.click(); }}
          >
            <img style={{ width: '15px', height: '15px', marginRight: '8px' }} src={googlePng} />
            <span style={{ flex: 1, textAlign: 'center' }}>Sign up with Google</span>
          </Button>

          <Button
            id="facebook-button"
            theme={Theme.GHOST}
            className={styles.submit}
            uppercase
            size={Size.LARGE}
            style={{ display: 'flex', alignItems: 'center', width: "100%", marginTop: 20 }}
            onClick={() => handleFacebook()}
          >
            <img style={{ width: '15px', height: '15px', marginRight: '8px' }} src={facebookLogo} />
            <span style={{ flex: 1, textAlign: 'center' }}>Sign up with Facebook</span>
          </Button>

          <div className={styles.login}>
            <FormattedMessage id="register.haveAccount" />{' '}
            <Link to={Routes.Login}>
              <FormattedMessage id="register.login" />
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default injectIntl(RegisterInitForm)
