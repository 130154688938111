import {
  FileCreationDTO,
  FileDTO,
  ProductCreationDTO,
  ProductPictureCreationDTO
} from '@addsome/dtos'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../redux'
import { getMedia } from '../../../services/media'
import MediaGallery from '../../MediaGallery/MediaGallery'
import { IStepFormProps } from '../../MultiStepModal/MultiStepModal'
import MultiStepNavigation from '../../MultiStepModal/MultiStepNavigation/MultiStepNavigation'
import styles from './ProductCreation.module.scss'

const PicturesStep: React.FC<
  ReturnType<typeof mapStateToProps> & IStepFormProps<ProductCreationDTO>
> = ({ onPrevious, onNext, value, first, last, token }) => {
  const [pictures, setPictures] = useState<ProductPictureCreationDTO[]>([...(value.pictures || [])])
  // Pictures needs to be FileCreationDTO, while displayedPictures must be (fake) FileDTO
  const [displayedPictures, setDisplayedPictures] = useState<FileDTO[]>([])

  const updateDisplayedPictures = useCallback(
    async (updatedPictures: FileCreationDTO[]) => {
      // Delete
      const files = displayedPictures.filter(picture =>
        updatedPictures.find(updatedPicture => updatedPicture.mediaId === picture.media.id)
      )
      // Add new
      await updatedPictures.reduce(async (promise, picture) => {
        await promise
        const existing = files.find(file => file.media.id === picture.mediaId)
        if (existing) {
          existing.enabled = picture.enabled !== undefined ? picture.enabled : true
        } else {
          const media = await getMedia(picture.mediaId)
          files.push({
            id: picture.mediaId,
            name: picture.name || '',
            enabled: picture.enabled !== undefined ? picture.enabled : true,
            media,
            extension: ''
          })
        }
      }, Promise.resolve())
      setDisplayedPictures(files)
    },
    [displayedPictures]
  )

  // Fetch product pictures
  useEffect(() => {
    if (value.pictures && value.pictures.length > 0) {
      updateDisplayedPictures(value.pictures)
    }
  }, [updateDisplayedPictures, value])

  const handlePicturesChange = useCallback(
    (updatedPictures: ProductPictureCreationDTO[]) => {
      setPictures(updatedPictures)
      updateDisplayedPictures(updatedPictures)
    },
    [updateDisplayedPictures]
  )

  return (
    <>
      <div className={`${styles.formBody} ${styles.pictures}`}>
        <MediaGallery pictures={displayedPictures} onChange={handlePicturesChange} token={token} />
      </div>
      <MultiStepNavigation
        showPrevious={!first}
        finish={last}
        onPrevious={onPrevious}
        onNext={() => onNext({ ...value, pictures })}
      />
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  token: state.authState.token
})

export default connect(mapStateToProps)(PicturesStep)
