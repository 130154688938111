import { Button, Size, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import React from 'react'
import {FormattedMessage} from 'react-intl'

interface IProps {
  loading: boolean
  children?: React.ReactNode
  theme?: Theme
  circle?: boolean
  size?: Size
}

const UploadButton = (props: IProps) => (
  <Button
    disabled={props.loading}
    block
    uppercase
    size={props.size || Size.LARGE}
    theme={props.theme}
    shape={props.circle ? 'circle' : undefined}
  >
    {!(props.loading && props.circle) && (props.children || <span><FormattedMessage id='import.upload' /></span>)}
    {props.loading && <Icon type="loading" />}
  </Button>
)

export default UploadButton
