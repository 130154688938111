"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_PRODUCTS_SUGGESTS"] = "SET_PRODUCTS_SUGGESTS";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));