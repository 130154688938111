import { Searchbar } from '@addsome/ui-kit'
import { FormikValues } from 'formik'
import React from 'react'
import { IFieldsGroupOptions } from '../DTOForm/interfaces'
import MultiStepModal, { StepFormComponent } from '../MultiStepModal/MultiStepModal'
import styles from './ListTop.module.scss'

interface IState {
  searchPattern: string
}

interface IProps<T extends FormikValues> {
  searchLabel: string
  onSearch: (searchPattern: string) => void
  searchInitValue?: string
  fieldsGroupOptions?: IFieldsGroupOptions<T>
  creation?: {
    active: boolean
    initialValue: T
    steps: Array<{
      title: React.ReactNode
      component: StepFormComponent<T>
      props?: { [key: string]: any }
    }>
    title: React.ReactNode
    onCreate: (data: T) => void
  }
}

export default class ListTop<T> extends React.Component<IProps<T>, IState> {
  public state: IState = {
    searchPattern: ''
  }

  public componentDidMount() {
    this.setState({
      searchPattern: this.props.searchInitValue || ''
    })
  }

  public render() {
    const { creation } = this.props
    return (
      <div className={styles.listTop}>
        <Searchbar
          className={styles.search}
          placeholder={this.props.searchLabel}
          value={this.state.searchPattern}
          onChange={event => this.setSearchPattern(event.currentTarget.value)}
          onSearch={() => this.props.onSearch(this.state.searchPattern)}
        />
        {creation && creation.active && (
          <MultiStepModal
            initialValue={creation.initialValue}
            steps={creation.steps}
            onSubmit={creation.onCreate}
            title={creation.title}
          />
        )}
      </div>
    )
  }

  private setSearchPattern = (searchPattern: string) => {
    this.setState({ searchPattern })
  }
}
