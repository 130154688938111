import { apiRequest, product } from '@addsome/redux-store';

const ARCHITECT_PRODUCT_DOWNLOAD_PATH = '/architect-product-download';

export async function hasDownloadedProduct(architectId, productId) {
  try {
    const hasDownloadedProduct = await apiRequest('GET', `${ARCHITECT_PRODUCT_DOWNLOAD_PATH}/alreadyUsed/${architectId}/${productId}`);
    return hasDownloadedProduct;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function createDownloadedProduct(architectId, productId) {
  try {
    await apiRequest('POST', `${ARCHITECT_PRODUCT_DOWNLOAD_PATH}`, { architectId, productId });
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function getTotalDownloads(architectId) {
  try {
    return await apiRequest('GET', `${ARCHITECT_PRODUCT_DOWNLOAD_PATH}/total-downloads/${architectId}/`);
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

