"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_transformer_1 = require("class-transformer");

exports.TransformToBoolean = function () {
  return class_transformer_1.Transform(function (value) {
    if (['0', 'false'].includes(value)) {
      return false;
    } else if (['1', 'true'].includes(value)) {
      return true;
    }

    return value;
  });
};

exports.TransformToArray = function () {
  var separator = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ',';
  return class_transformer_1.Transform(function (value) {
    if (typeof value === 'string') {
      return value.split(separator);
    }

    return value;
  });
};

exports.TransformToNumber = function () {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'int';
  return class_transformer_1.Transform(function (value) {
    if (typeof value === 'string') {
      var valueNumber = type === 'int' ? Number.parseInt(value, 10) : Number.parseFloat(value);
      return isNaN(valueNumber) ? value : valueNumber;
    }

    return value;
  });
};