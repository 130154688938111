"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNotifications = exports.thunks = exports.reducer = void 0;

var crud_1 = require("./crud");

var notification_1 = require("./notification");

var KEY = 'COLOR_TAG';
exports.reducer = (0, crud_1.default)(KEY);
exports.thunks = new crud_1.ThunkHandler({
  url: '/color-tags',
  key: KEY,
  noPagination: true
});

function createNotifications(dispatch, message) {
  dispatch((0, notification_1.addLabels)(KEY, {
    fetchValues: {
      error: message.errorRetrievingColorTags
    },
    fetchValue: {
      error: message.errorRetrievingTheColorTag
    },
    create: {
      success: message.addedColorTag,
      error: message.errorCreatingColorTag
    },
    patch: {
      success: message.updatedColorTag,
      error: message.errorRegisteringColorTag
    },
    delete: {
      success: message.removedColorTag,
      error: message.errorDeletingColorTag
    }
  }));
}

exports.createNotifications = createNotifications;