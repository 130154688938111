"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) {
    if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cameraTemplate = exports.quote = exports.tagsSelectors = exports.brandTag = exports.roomTag = exports.colorTag = exports.styleTag = exports.productMaterialTag = exports.objectProductCategories = exports.album = exports.user = exports.surfaceProduct = exports.settings = exports.scene = exports.render = exports.projectSceneTemplate = exports.project = exports.productVariations = exports.productSuggests = exports.productViewer = exports.productStates = exports.productProblemType = exports.productProblem = exports.product = exports.packshot = exports.objectProductModelBundle = exports.objectProductVariationMaterials = exports.objectProductDimensions = exports.objectProductVariations = exports.objectProductData3D = exports.objectProduct = exports.notification = exports.metrics = exports.drive = exports.designer = exports.communityScene = exports.crud = exports.brandUserProject = exports.brandUser = exports.brandsSuggests = exports.brands = exports.brand = exports.addsomeUser = exports.auth = exports.architectProjectSceneTemplate = exports.architectProject = exports.architectClient = exports.architectProductHistory = exports.architectBrands = exports.architect = void 0;
exports.collections = exports.brandInvitation = exports.productPrices = exports.brandProjectSceneTemplate = void 0;

var settings_1 = require("./settings");

exports.settings = settings_1.default;

var album = require("./store/album");

exports.album = album;

var architect = require("./store/architect");

exports.architect = architect;

var architectBrands = require("./store/architect-brands");

exports.architectBrands = architectBrands;

var architectProductHistory = require("./store/architect-product-history");

exports.architectProductHistory = architectProductHistory;

var architectClient = require("./store/architect-clients");

exports.architectClient = architectClient;

var architectProjectSceneTemplate = require("./store/architect-project-scene-template");

exports.architectProjectSceneTemplate = architectProjectSceneTemplate;

var architectProject = require("./store/architect-projects");

exports.architectProject = architectProject;

var auth = require("./store/auth");

exports.auth = auth;

var addsomeUser = require("./store/addsome-users");

exports.addsomeUser = addsomeUser;

var brand = require("./store/brand");

exports.brand = brand;

var brandProjectSceneTemplate = require("./store/brand-project-scene-template");

exports.brandProjectSceneTemplate = brandProjectSceneTemplate;

var brandTag = require("./store/brand-tags");

exports.brandTag = brandTag;

var brandUser = require("./store/brand-user");

exports.brandUser = brandUser;

var brandUserProject = require("./store/brand-user-projects");

exports.brandUserProject = brandUserProject;

var brands = require("./store/brands");

exports.brands = brands;

var brandsSuggests = require("./store/brands-suggests");

exports.brandsSuggests = brandsSuggests;

var cameraTemplate = require("./store/camera-templates");

exports.cameraTemplate = cameraTemplate;

var colorTag = require("./store/color-tags");

exports.colorTag = colorTag;

var crud = require("./store/crud");

exports.crud = crud;

var designer = require("./store/designer");

exports.designer = designer;

var drive = require("./store/drive");

exports.drive = drive;

var metrics = require("./store/metrics");

exports.metrics = metrics;

var notification = require("./store/notification");

exports.notification = notification;

var objectProduct = require("./store/object-product");

exports.objectProduct = objectProduct;

var objectProductCategories = require("./store/object-product-categories");

exports.objectProductCategories = objectProductCategories;

var objectProductData3D = require("./store/object-product-data3d");

exports.objectProductData3D = objectProductData3D;

var objectProductVariations = require("./store/object-product-variations");

exports.objectProductVariations = objectProductVariations;

var objectProductDimensions = require("./store/object-product-dimensions");

exports.objectProductDimensions = objectProductDimensions;

var objectProductVariationMaterials = require("./store/object-product-variation-material");

exports.objectProductVariationMaterials = objectProductVariationMaterials;

var objectProductModelBundle = require("./store/object-product-model-bundle");

exports.objectProductModelBundle = objectProductModelBundle;

var packshot = require("./store/packshot");

exports.packshot = packshot;

var projectSceneTemplate = require("./store/project-scene-template");

exports.projectSceneTemplate = projectSceneTemplate;

var product = require("./store/product");

exports.product = product;

var productMaterialTag = require("./store/product-material-tags");

exports.productMaterialTag = productMaterialTag;

var productPrices = require("./store/product-prices");

exports.productPrices = productPrices;

var productProblem = require("./store/product-problem");

exports.productProblem = productProblem;

var productProblemType = require("./store/product-problem-type");

exports.productProblemType = productProblemType;

var productStates = require("./store/product-states");

exports.productStates = productStates;

var productSuggests = require("./store/product-suggests");

exports.productSuggests = productSuggests;

var productVariations = require("./store/product-variations");

exports.productVariations = productVariations;

var productViewer = require("./store/product-viewer");

exports.productViewer = productViewer;

var project = require("./store/project");

exports.project = project;

var scene = require("./store/project-scene");

exports.scene = scene;

var communityScene = require("./store/scene");

exports.communityScene = communityScene;

var quote = require("./store/quote");

exports.quote = quote;

var render = require("./store/render");

exports.render = render;

var roomTag = require("./store/room-tags");

exports.roomTag = roomTag;

var styleTag = require("./store/style-tags");

exports.styleTag = styleTag;

var surfaceProduct = require("./store/surface-product");

exports.surfaceProduct = surfaceProduct;

var tagsSelectors = require("./store/tags-selectors");

exports.tagsSelectors = tagsSelectors;

var user = require("./store/user");

exports.user = user;

var brandInvitation = require("./store/brand-invitation");

exports.brandInvitation = brandInvitation;

var collections = require("./store/collections");

exports.collections = collections;

__exportStar(require("./api"), exports);

__exportStar(require("./store"), exports);