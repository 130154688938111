import { Button, Popconfirm, Theme } from '@addsome/ui-kit';
import React from 'react';
import styles from './ProductVariations.module.scss';
import { ObjectProductVariationDTO, ObjectProductVariationMaterialDTO } from '@addsome/dtos/dist';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import EditRowForMaterial from './EditRowForMaterial';

type IProps = WrappedComponentProps & {
  variationPresetNameAndGroupId: number;
  selectedColor: string;
  materialName: string;
  materialNameError: boolean;
  displayEdit: boolean;
  material: ObjectProductVariationMaterialDTO;
  variation: ObjectProductVariationDTO;
  closeEditAddMaterialForm: () => void;
  changeName: (name: string) => void;
  changePresetVariation: (presetVariationId: number) => void;
  changeColorHexa: (colorCode: string) => void;
  editMaterial: () => void;
  deleteMaterial: (materialId) => void;
  setAddEditMaterialError: () => void;
  materialsForProduct: string[];

};
class MaterialTableRow extends React.Component<IProps> {
  public render() {
    return (
      <div className={`${styles.materialSection} ${styles.alignTable} `} >
        {!this.props.displayEdit &&
          <p> {this.props.material.materialName}</p>

        }
        {this.props.displayEdit &&

          <EditRowForMaterial
            material={this.props.material}
            materialsForProduct={this.props.materialsForProduct}
            materialName={this.props.materialName}
            variation={this.props.variation}
            selectedColor={this.props.selectedColor}
            variationPresetNameAndGroupId={this.props.variationPresetNameAndGroupId}
            changeName={(name) => {
              this.props.changeName(name);
            }}
            changePresetVariation={(presetVariationId) => {
              this.props.changePresetVariation(presetVariationId);
            }}
            changeColorHexa={(materialColorHexa) => {
              this.props.changeColorHexa(materialColorHexa);
            }}
            closeEditAddMaterialForm={() => {
              this.props.closeEditAddMaterialForm();
            }}
            setAddEditMaterialError={() => {
              this.setState({
                displayAddEditMaterialError: true,
              });
            }}
            materialNameError={this.props.materialNameError}
            addMaterial={false} />

        }
        <div className={`${styles.flexRight} `} style={{ alignItems: "center" }}>
          <span>
            <Popconfirm
              title={this.props.intl.formatMessage({ id: 'productsvariations.confirmDeleteMaterial' })}
              onConfirm={() => { this.props.deleteMaterial(this.props.material.id); }}
              okText={this.props.intl.formatMessage({ id: 'productsvariations.okText' })}
              cancelText={this.props.intl.formatMessage({ id: 'productsvariations.cancelText' })}
              placement="left"
            >
              <Button
                theme={Theme.GHOST}
                shape="circle"
                icon="delete"
                title={this.props.intl.formatMessage({ id: 'productsvariations.deleteMaterial' })}
                aria-label={`Supprimer ${this.props.variation.name}`}
              />
            </Popconfirm>
          </span>
        </div>
      </div>
    );
  }
}


export default injectIntl(MaterialTableRow);
