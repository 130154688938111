import { ObjectProductVariationDTO, ProductPictureDTO, ProductRelationshipsExtendedDTO } from '@addsome/dtos';
import { Button, Container, InvisibleButton, Theme } from '@addsome/ui-kit';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import playerLogo from '../../../assets/images/donnees-3D.svg';
import ProductViewer from '../../Player/ProductViewer/ProductViewer';
import styles from './ProductGallery.module.scss';
import { useHorizontalScroll } from '../../../hooks/scroll';
import { getCloudImageUrl } from '../../../utils/cloudImage';
import { FormattedMessage } from 'react-intl';

interface IProps {
  product: ProductRelationshipsExtendedDTO;
  objectProductVariations: ObjectProductVariationDTO[];
  variationIndex: number;
  nrOfVariationChanges: number;
  selectedPictureId?: string;
  onSelectPicture?: (picture: ProductPictureDTO) => void;
  isInCatalog: boolean;
  isShowing3d: boolean;
  displayViewer: boolean;
  setIsShowing3d: (isShowing3d: boolean) => void;
  download3dWithTexturesFormat: string | null
  onResetAuth: () => void
  onFinishDownloading: () => void
}

const ProductGallery: React.FC<IProps> = ({
  product,
  variationIndex,
  nrOfVariationChanges,
  objectProductVariations,
  selectedPictureId,
  onSelectPicture,
  isInCatalog,
  isShowing3d,
  setIsShowing3d,
  displayViewer,
  download3dWithTexturesFormat,
  onResetAuth,
  onFinishDownloading
}) => {
  const [currentPicture, setCurrentPicture] = useState<ProductPictureDTO>();
  const [pictures, setPictures] = useState<ProductPictureDTO[]>([]);
  const { scrollContainerRef, handleWheel } = useHorizontalScroll<HTMLUListElement>();

  useEffect(() => {
    if (product.pictures) {
      setPictures(
        product.pictures.slice().sort((a: ProductPictureDTO, b: ProductPictureDTO) => {
          if (!a.media || !a.media.width || !a.media.height) return 1;
          if (!b.media || !b.media.width || !b.media.height) return -1;

          return b.media.width * b.media.height - a.media.width * a.media.height;
        })
      );
    }
    if (isInCatalog) {
      setIsShowing3d(false);
      const picture = !product.thumbnail
        ? product.pictures[0]
        : product.pictures.find(picture => {
          return picture.id === product.thumbnail!.id;
        });
      setCurrentPicture(picture);
    } else {
      setIsShowing3d(product.haveObjectProductModelBundle);
    }
  }, [isInCatalog, product, setIsShowing3d]);

  useEffect(() => {
    const selected = pictures.find(p => p.id === selectedPictureId);
    if (selected) {
      setCurrentPicture(selected);
      setIsShowing3d(false);
    }

  }, [selectedPictureId, pictures, setIsShowing3d]);


  return (
    <Container className={styles.productGallery}>
      <div className={styles.display} id="modalCompId" data-nosnippet>
        {!isShowing3d && currentPicture && (
          <img src={getCloudImageUrl(currentPicture.media.url || '', 1000)} />
        )}
        <div className={classNames(styles.viewer, { [styles.hide]: !isShowing3d })}>
          <ProductViewer
            isOnView={displayViewer}
            productId={product.id}
            variationIndex={variationIndex}
            nrOfVariationChanges={nrOfVariationChanges}
            product={product}
            objectProductVariations={objectProductVariations}
            download3dWithTexturesFormat={download3dWithTexturesFormat}
            onResetAuth={() => onResetAuth()}
            onFinishDownloading={onFinishDownloading}
          />
        </div>
      </div>
      <ul className={styles.select} ref={scrollContainerRef} onWheel={handleWheel}>
        {product.haveObjectProductModelBundle && (
          <li>
            <Button
              theme={Theme.GHOST}
              disabled={isShowing3d}
              onClick={() => setIsShowing3d(true)}
              className={classNames(styles.button, { [styles.selected]: isShowing3d })}
            >
              <img src={playerLogo} alt="3D" className={styles.logo3D} aria-hidden />
              <span><FormattedMessage id='global.3dview' /></span>
            </Button>
          </li>
        )}
        {pictures.map((picture, index) => (
          <li key={picture.id}>
            <InvisibleButton
              onClick={() => {
                if (onSelectPicture) {
                  onSelectPicture(picture);
                } else {
                  setCurrentPicture(picture);
                  setIsShowing3d(false);
                }
              }}
              className={classNames(styles.button, {
                [styles.selected]: !isShowing3d && currentPicture === picture
              })}
            >
              <img src={`${getCloudImageUrl(picture.media.url || '', 84)}#${product.thumbnail.id === picture.id ? product.name : ''}`}
                alt={product.thumbnail.id === picture.id ? `${product.name}` : `${product.name} picture no.${index + 1}`} />
            </InvisibleButton>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default ProductGallery;
