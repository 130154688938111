"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.setObjectProductModelBundle = void 0;

var model_1 = require("./model");

var setObjectProductModelBundle = function setObjectProductModelBundle(objectProductModelBundle) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_MODEL_BUNDLE,
    objectProductModelBundle: objectProductModelBundle
  };
};

exports.setObjectProductModelBundle = setObjectProductModelBundle;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_MODEL_BUNDLE_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;