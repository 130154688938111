import { RouteComponentProps } from 'react-router-dom'
import React, { FC, useEffect, useState } from 'react'
import { getArchitectByAccountId } from '../../services/architectAccount';
import { ArchitectSubscriptionType } from '@addsome/dtos/dist';
import { ArchitectSubscriptionDTO } from '@addsome/dtos'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { architect as architectActions } from '@addsome/redux-store'
import { getSubscription, setPayment } from '../../services/subscription';
import { cancelLastMonthlySubscription, payInvoice } from '../../services/payment'
import { sendUpgradeSubscriptionEmail } from '../../services/architectAccount'
import ProfilePageLayout from '../../templates/ProfilePageLayout/ProfilePageLayout'
import { push } from 'connected-react-router'
import { Icon } from 'antd'
import styles from './UpgradeSubscription.module.scss'
import { useIntl, FormattedMessage } from 'react-intl';
import { auth } from '@addsome/redux-store'
import { Button } from '@addsome/ui-kit'

interface IMatchParams {
  accountId: string,
  email: string,
  subscriptionType: string,
}

type IProps = RouteComponentProps<IMatchParams> &
  ReturnType<typeof mapDispatchToProps>

const UpgradePaymentComplete: FC<IProps> = ({ match, updateSubscriptionOnArchitectAccount, updateRemainingDownloadsOnArchitectAccount, pushRouter }) => {
  const intl = useIntl()

  const userAccountId = match.params.accountId
  const userEmail = match.params.email
  const subscriptionType = match.params.subscriptionType

  const [architect, setArchitect] = useState(null)

  const getSubscriptionObject = async () => {
    const subscription = await getSubscription(subscriptionType as ArchitectSubscriptionType)
    return subscription
  }

  useEffect(() => {
    getArchitectByAccountId(userAccountId).then(async (architect) => {
      setArchitect(architect)
      if (subscriptionType.includes('pack')) {
        let remainingDownloads = parseInt(architect.subscriptionRemainingDownloads) + parseInt(subscriptionType.match(/\d+/)[0])
        const validPaymentIntent = await payInvoice(userEmail)
        if (validPaymentIntent) {
          updateRemainingDownloadsOnArchitectAccount(architect.id, remainingDownloads)
        }
      }
      else if (architect.subscription && architect.subscription.slug != subscriptionType) {
        let remainingDownloads = parseInt(architect.subscriptionRemainingDownloads) + parseInt(architect.subscription.quota) - parseInt(architect.downloads);
        await cancelLastMonthlySubscription(userEmail)
        const subscription = await getSubscriptionObject()
        updateSubscriptionOnArchitectAccount(architect.id, subscription, remainingDownloads)
        sendUpgradeSubscriptionEmail(architect.id, subscriptionType)
        setPayment({ accountId: architect.account.id, subscriptionType: subscriptionType, paymentStatus: 'payment complete' })
      } else if (!architect.subscription) {
        let remainingDownloads = parseInt(architect.subscriptionRemainingDownloads) - parseInt(architect.downloads);
        const subscription = await getSubscriptionObject()
        updateSubscriptionOnArchitectAccount(architect.id, subscription, remainingDownloads)
        sendUpgradeSubscriptionEmail(architect.id, subscriptionType)
        setPayment({ accountId: architect.account.id, subscriptionType: subscriptionType, paymentStatus: 'payment complete' })
      } else {
        pushRouter(`/architects/${architect.id}/subscription`)
      }
    }).catch((e) => {
      pushRouter('/')
    })
  }, [])

  return (architect &&
    <ProfilePageLayout architect={architect}>
      <div className={styles.iconContainer}>
        <Icon className={styles.checkIcon} type="check-circle"></Icon>
        {subscriptionType.includes('pack') ?
          <span className={styles.subtitle}>{intl.formatMessage({ id: 'profile.plan.purchase' })}</span>
          : <span className={styles.subtitle}>{intl.formatMessage({ id: 'profile.subscription.upgradeSuccessfully' })}</span>
        }
        <Button
          uppercase
          className={styles.upgradeButton}
          shape="round"
          onClick={() => pushRouter('/catalog')}
        >
          <FormattedMessage id="profile.seeCatalog" />
        </Button>
      </div>
    </ProfilePageLayout>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  updateSubscriptionOnArchitectAccount: (id: string, subscription: ArchitectSubscriptionDTO, remainingDownloads: number) => {
    dispatch(
      architectActions.thunks.patchValue(id,
        {
          subscription: subscription,
          subscriptionStartDate: new Date(),
          downloads: 0,
          subscriptionRemainingDownloads: remainingDownloads <= 0 ? 0 : remainingDownloads,
        }
      )
    ).then(() => dispatch(auth.getAuth()))
  },
  updateRemainingDownloadsOnArchitectAccount: (id: string, remainingDownloads: number) => {
    dispatch(
      architectActions.thunks.patchValue(id,
        {
          subscriptionRemainingDownloads: remainingDownloads <= 0 ? 0 : remainingDownloads,
        }
      )
    ).then(() => dispatch(auth.getAuth()))
  }
})

export default connect(
  null,
  mapDispatchToProps
)(UpgradePaymentComplete)