import { BrandExtendedDTO } from '@addsome/dtos/dist'
import { Button, Card, Heading, ICardData, Theme } from '@addsome/ui-kit'
import classnames from 'classnames'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { getResizedPreview } from '../../utils/cloudImage'
import Routes from '../../utils/routes'
import ProductsGrid from '../ProductsGrid/ProductsGrid'
import styles from './Section.module.scss'
import usePush from '../../hooks/usePush'
import SkeletonProductCardsList from '../SkeletonCardsList/SkeletonProductCardsList/SkeletonProductCardsList'
import SkeletonBrandCardsList from '../SkeletonCardsList/SkeletonBrandCardsList/SkeletonBrandCardsList'

interface IProps {
  title: string
  buttonLabel: string
  buttonRoute: Routes
  cardsData?: ICardData[]
  brandsData?: BrandExtendedDTO[]
  green?: boolean
  noPaddingTop?: boolean
  loading?: boolean
  oneLine?: boolean
  mobileHidden?: boolean
  smallProductGrid?: boolean
  isCollection?: boolean
  productTopIcons?: React.ReactNode,
  enrichTopIcons?: boolean,
  onProductClick?: (id) => void
  href?: boolean
}

interface ISectionTemplateProps {
  title: string
  sectionTitleRightComponent: React.ReactNode
  green?: boolean
  noPaddingTop?: boolean
  loading?: boolean
  isProductCard?: boolean
  oneLine?: boolean
  mobileHidden?: boolean
  smallProductGrid?: boolean
}

export const SectionTemplate: React.FC<ISectionTemplateProps> = ({
  title,
  // sectionTitleRightComponent,
  green = false,
  children,
  noPaddingTop = false,
  loading = false,
  isProductCard,
  oneLine = false,
  mobileHidden = false,
  smallProductGrid = false,
}) => {
  const content = (
    <div
      className={classnames(styles.section, {
        [styles.sectionGreen]: green,
        [styles.noPaddingTop]: noPaddingTop,
        [styles.mobileHidden]: mobileHidden
      })}
    >
      <div className={styles.sectionTitle}>
        <Heading level={2} as="h2" strong>
          {title}
        </Heading>

        {
          //sectionTitleRightComponent
        }
      </div>

      {loading ? (
        isProductCard ? (
          <SkeletonProductCardsList
            nbItems={20}
            className={classnames(smallProductGrid ? styles.smallProductGrid : styles.productGrid, {
              [styles.oneLine]: oneLine,
              [styles.twoLines]: !oneLine
            })}
          />
        ) : (
          <SkeletonBrandCardsList nbItems={20} />
        )
      ) : (
        children
      )}
    </div>
  )

  return green ? <div className={styles.containerSectionGreen}>{content}</div> : content
}

const Section: React.FC<IProps> = ({
  title,
  buttonLabel,
  buttonRoute,
  cardsData,
  brandsData,
  green = false,
  noPaddingTop = false,
  loading = false,
  oneLine = false,
  mobileHidden = false,
  smallProductGrid = false,
  isCollection = false,
  enrichTopIcons = false,
  productTopIcons,
  onProductClick,
  href=true
}) => {
  const { push } = usePush()

  return (
    <SectionTemplate
      loading={loading}
      noPaddingTop={noPaddingTop}
      title={title}
      green={green}
      isProductCard={!!cardsData}
      oneLine={oneLine}
      mobileHidden={mobileHidden}
      smallProductGrid={smallProductGrid}
      sectionTitleRightComponent={
        <Button
          theme={green ? Theme.WHITEGHOST : Theme.GHOST}
          uppercase
          onClick={() => push(buttonRoute)}
        >
          {buttonLabel}
        </Button>
      }
    >
      {cardsData && (
        <ProductsGrid
          cardsData={cardsData}
          className={classnames(smallProductGrid ? styles.smallProductGrid : styles.productGrid, {
            [styles.oneLine]: oneLine,
            [styles.twoLines]: !oneLine
          })}
          cardTheme={green ? Theme.WHITE : Theme.DEFAULT}
          isCollection={isCollection}
          topIcons={productTopIcons}
          enrichTopIcons={enrichTopIcons}
          onProductClick={onProductClick}
          href={href}
        />
      )}

      {brandsData && (
        <div className={styles.brandGrid}>
          {brandsData.map(b => (
            <Link key={b.id} to={`${Routes.Catalog}?brandIds=${b.id}`}>
              <Card
                key={b.id}
                className={styles.card}
                title=""
                footer=""
                side=""
                image={b.logo && b.logo.url && getResizedPreview(124, 124, b.logo.url)}
                ratio={[1, 1]}
              />
            </Link>
          ))}
        </div>
      )}
    </SectionTemplate>
  )
}

export default memo(Section)
