"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_QUOTE"] = "SET_QUOTE";
  ActionsTypes["UNSET_QUOTE"] = "UNSET_QUOTE";
  ActionsTypes["SET_QUOTE_LOADING"] = "SET_QUOTE_LOADING";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));