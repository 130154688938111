import { AccountType, ArchitectWithBrandDTO, BrandExtendedDTO, BrandUpdateDTO } from '@addsome/dtos';
import { Button, Integration, Popconfirm, Size, Switch, Table, Tag, TagType, Theme, Thumbnail } from '@addsome/ui-kit';
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table';
import { push } from 'connected-react-router';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import logoPlaceholder from '../../../assets/images/donnees-2D.svg';
import { IRootState } from '../../../redux';
import styles from './BrandsTable.module.scss';
import Icon from 'antd/lib/icon';
import { activateBrand } from '@addsome/redux-store/dist/store/brand-invitation';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { setUserLog } from '../../../services/userLog';
import { multiply3Dmetrics } from '../../../utils/multiply3Dmetrics';
import { getCloudImageUrl } from '../../../utils/cloudImage';
import { user } from '@addsome/redux-store/dist';

type IProps = WrappedComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    brands: BrandExtendedDTO[];
    total: number;
    currentPage: number;
    pageSize: number;
    loading: boolean;
    candDelete?: boolean;
    onTableChange: (
      pagination: PaginationConfig,
      filter: Record<keyof BrandExtendedDTO, string[]>,
      sorter: SorterResult<BrandExtendedDTO>
    ) => void;
    onDelete: (id: string) => void;
    editBrandStatus: (id: string, brand: BrandExtendedDTO) => void;
    readonly?: boolean;
    sortBy?: { [key: string]: number; };
    filters?: { [key: string]: string[]; };
  };

const AdminBlackListDataIndexes = ['brandInvitationToken'];
const NonAdminBlacklistDataIndexes = [
  'productsCountWithProblem',
  'updatedAt',
  'brandInvitationToken',
  'promoted'
];
const ArchitectBlacklistDataIndexes = ['productsCountWithProblem', 'updatedAt', 'enabled', 'promoted'];
const isMobile = window.innerWidth <= 600;


class BrandsTable extends React.Component<IProps> {
  private updateEnabled = (enabled: boolean, brand: BrandExtendedDTO) => {
    this.props.editBrandStatus(brand.id, {
      ...brand, enabled: enabled, address: {
        city: "",
        country: "",
        street: "",
        zipcode: ""
      }
    });
  };

  private updatePromoted = (promoted: boolean, brand: BrandExtendedDTO) => {
    console.log(promoted)
    this.props.editBrandStatus(brand.id, {
      ...brand, promoted: promoted, address: {
        city: "",
        country: "",
        region: "",
        street: "",
        zipcode: ""
      }
    });
  };

  private columns: Array<ColumnProps<BrandExtendedDTO>> = [
    {
      title: '',
      dataIndex: 'logo.url',
      key: 'logo',
      render: (value, record) => (
        <Thumbnail
          src={getCloudImageUrl(value, 54) || logoPlaceholder}
          alt={`Logo ${record.name}`}
          size={Size.SMALL}
        />
      ),
      width: '5%'
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.brand' }),
      dataIndex: 'name',
      render: (value, record) => <Link to={`/brands/${record.id}?tab=info`}>{value}</Link>,
      sorter: true
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.products' }),
      dataIndex: 'productsCount'
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.3dfiles' }),
      dataIndex: 'productsCountWith3DFile',
      render: value => value
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.problems' }),
      dataIndex: 'productsCountWithProblem'
    },

    {
      title: this.props.intl.formatMessage({ id: 'brandstable.updatedAt' }),
      dataIndex: 'updatedAt',
      render: value => <FormattedDate value={value} />,
      sorter: true
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.status' }),
      dataIndex: 'enabled',
      render: (value, record) => {
        if (this.props.accountType !== AccountType.Architect) {

          return (
            <div className={styles.enableCell}>

              <Tag type={value ? TagType.ACTIVE : TagType.INACTIVE}>
                <FormattedMessage id={`brands.${value ? 'active' : 'inactive'}`} />
              </Tag>
              <div className={styles.richRight}>
                {this.props.children}
                <Switch
                  onChange={() => {
                    this.updateEnabled(!record.enabled, record);
                  }}
                  checked={record.enabled}
                  integration={Integration.LIGHT}
                  className={styles.enabled}
                  disabled={this.props.readonly}
                >
                </Switch>
              </div >
            </div>
          );
        }
      },
      filters: [{ text: 'Active', value: 'true' }, { text: 'Inactive', value: 'false' }],
      filterMultiple: false
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.promoted' }),
      dataIndex: 'promoted',
      render: (value, record) => {
        if (this.props.accountType !== AccountType.Architect) {
          return (
            <div className={styles.enableCell}>
              <div className={styles.richRight}>
                {this.props.children}
                <Switch
                  onChange={() => {
                    this.updatePromoted(!record.promoted, record);
                  }}
                  checked={record.promoted}
                  integration={Integration.GREEN}
                  className={styles.enabled}
                  disabled={this.props.readonly}
                >
                </Switch>
              </div >
            </div>
          );
        }
      },
      filters: [{ text: 'Promoted', value: 'true' }, { text: 'Not promoted', value: 'false' }],
      filterMultiple: false
    },
    {
      dataIndex: 'id',
      render: (value, record) => {
        if (this.props.accountType !== AccountType.Architect) return null;


        const userBrands = (this.props.user as ArchitectWithBrandDTO).brands;
        let hasBrand;
        if (userBrands && userBrands.length > 0)
          hasBrand = userBrands.find(b => b.id === record.id);
        if (hasBrand)
          return (
            <div className={styles.check}>
              <Icon type="check" />
            </div>

          );
        else
          return (
            <Button uppercase theme={Theme.GHOST} onClick={() => this.props.activateBrand(value)}>
              <FormattedMessage id="brandstable.activate" />
            </Button>
          );

      }
    }
  ];

  private mobileColumns: Array<ColumnProps<BrandExtendedDTO>> = [
    {
      title: '',
      dataIndex: 'logo.url',
      key: 'logo',
      render: (value, record) => (
        <Thumbnail
          src={getCloudImageUrl(value, 54) || logoPlaceholder}
          alt={`Logo ${record.name}`}
          size={Size.SMALL}
        />
      ),
      width: '5%'
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.brand' }),
      dataIndex: 'name',
      render: (value, record) => <Link to={`/brands/${record.id}?tab=info`}>{value}</Link>,
      sorter: true,
      align: 'left'
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.products' }),
      dataIndex: 'productsCount',
      align: 'left'
    },
    {
      title: this.props.intl.formatMessage({ id: 'brandstable.status' }),
      dataIndex: 'enabled',
      render: value => (
        <Tag type={value ? TagType.ACTIVE : TagType.INACTIVE}>
          <FormattedMessage id={`brands.${value ? 'active' : 'inactive'}`} />
        </Tag>
      ),
      filters: [{ text: 'Active', value: 'true' }, { text: 'Inactive', value: 'false' }],
      filterMultiple: false,
      align: 'left'
    }
  ];

  public render() {
    const pagination: PaginationConfig = {
      total: this.props.total,
      pageSize: this.props.pageSize,
      current: this.props.currentPage,
      showTotal: total =>
        `${total} ${this.props.intl.formatMessage({ id: 'global.pagination.results' })}`
    };
    const tableColumns = [
      ...(isMobile ? this.mobileColumns : this.columns),
      ...(!this.props.readonly && this.props.candDelete
        ? ([
          {
            title: '',
            render: (record: BrandExtendedDTO) => (
              <Popconfirm
                title={this.props.intl.formatMessage({ id: 'global.confirmdelete' })}
                onConfirm={() => {
                  this.props.onDelete(record.id)
                  setUserLog(this.props.user!.account.id, null, `brand ${record.name} deleted`)
                }}
                okText={this.props.intl.formatMessage({ id: 'global.yes' })}
                cancelText={this.props.intl.formatMessage({ id: 'global.no' })}
                placement="left"
              >
                <Button
                  theme={Theme.GHOST}
                  shape="circle"
                  icon="delete"
                  title={this.props.intl.formatMessage({ id: 'global.delete' })}
                  aria-label={`Supprimer ${record.name}`}
                />
              </Popconfirm>
            ),
            width: '5%'
          }
        ] as Array<ColumnProps<BrandExtendedDTO>>)

        : [])
    ];

    // Init sort and filters
    if (this.props.sortBy || this.props.filters) {
      const sortBy = this.props.sortBy;
      const filters = this.props.filters;
      tableColumns.filter(column => (isMobile ? column.dataIndex === 'name' : column));
      tableColumns.forEach(column => {
        if (sortBy) {
          const orderValue = column.key
            ? sortBy[column.key]
            : (column.dataIndex && sortBy[column.dataIndex]) || 0;
          if (orderValue) {
            column.defaultSortOrder = orderValue > 0 ? 'ascend' : 'descend';
          }
        }

        if (filters) {
          column.filteredValue = column.key
            ? filters[column.key]
            : (column.dataIndex && filters[column.dataIndex]) || undefined;
        }
      });
    }
    return (
      <div className={styles.container}>
        <Table
          columns={
            this.props.accountType === AccountType.Architect
              ? tableColumns.filter(
                col =>
                  !ArchitectBlacklistDataIndexes.find(dataIndex => dataIndex === col.dataIndex)
              )
              : this.props.accountType === AccountType.AddsomeUser
                ? tableColumns.filter(
                  col => !AdminBlackListDataIndexes.find(dataIndex => dataIndex === col.dataIndex)
                )
                : tableColumns.filter(
                  col =>
                    !NonAdminBlacklistDataIndexes.find(dataIndex => dataIndex === col.dataIndex)
                )
          }
          dataSource={this.props.brands}
          rowKey="id"
          pagination={this.props.total > this.props.pageSize ? pagination : false}
          loading={this.props.loading}
          onChange={this.props.onTableChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  user: state.userState.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  push: (location: string) => dispatch(push(location)),
  activateBrand: (token: string) => dispatch(activateBrand(token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BrandsTable));
