"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ErrorsCode;

(function (ErrorsCode) {
  ErrorsCode["Order_Invalid_Json"] = "Order_Invalid_Json";
  ErrorsCode["Bad_Request"] = "Bad_Request";
  ErrorsCode["Token_Expired"] = "Token_Expired";
  ErrorsCode["Token_Invalid"] = "Token_Invalid";
  ErrorsCode["Unauthorized"] = "Unauthorized";
  ErrorsCode["User_Not_Found"] = "User_Not_Found";
  ErrorsCode["Account_Type_Invalid"] = "Account_Type_Invalid";
  ErrorsCode["Email_Already_Exists"] = "Email_Already_Exists";
  ErrorsCode["Wrong_Credentials"] = "Wrong_Credentials";
  ErrorsCode["Not_Found"] = "Not_Found";
  ErrorsCode["Internal_Server_Error"] = "Internal_Server_Error";
  ErrorsCode["Media_Not_Found"] = "Media_Not_Found";
  ErrorsCode["Brand_Already_Exists"] = "Brand_Already_Exists";
  ErrorsCode["Brand_Not_Found"] = "Brand_Not_Found";
  ErrorsCode["Eshop_Not_Found"] = "Eshop_Not_Found";
  ErrorsCode["Eshop_Already_Exists"] = "Eshop_Already_Exists";
  ErrorsCode["Product_Not_Found"] = "Product_Not_Found";
  ErrorsCode["Project_Invalid"] = "Project_Invalid";
  ErrorsCode["Architect_Project_Not_Found"] = "Architect_Project_Not_Found";
  ErrorsCode["Project_Not_Found"] = "Project_Not_Found";
  ErrorsCode["Architect_Client_Not_Found"] = "Architect_Client_Not_Found";
  ErrorsCode["Invalid_File"] = "Invalid_File";
  ErrorsCode["Designer_Not_Found"] = "Designer_Not_Found";
  ErrorsCode["Architect_Not_Found"] = "Architect_Not_Found";
  ErrorsCode["Designer_Name_Already_Exists"] = "Designer_Name_Already_Exists";
  ErrorsCode["AddsomeUser_Not_Found"] = "AddsomeUser_Not_Found";
  ErrorsCode["Global_Material_Bundle_Not_Found"] = "Global_Material_Bundle_Not_Found";
  ErrorsCode["Global_Material_Bundle_Already_Exist"] = "Global_Material_Bundle_Already_Exist";
  ErrorsCode["Tag_Not_Found"] = "Tag_Not_Found";
  ErrorsCode["Brand_User_Not_Found"] = "Brand_User_Not_Found";
  ErrorsCode["Product_Problem_Not_Found"] = "Product_Problem_Not_Found";
  ErrorsCode["Product_Problem_Type_Not_Found"] = "Product_Problem_Type_Not_Found";
  ErrorsCode["Brand_Material_Bundle_Already_Exist"] = "Brand_Material_Bundle_Already_Exist";
  ErrorsCode["Brand_Material_Bundle_Not_Found"] = "Brand_Material_Bundle_Not_Found";
  ErrorsCode["Style_Tag_Not_Found"] = "Style_Tag_Not_Found";
  ErrorsCode["Style_Tag_Already_Exist"] = "Style_Tag_Already_Exist";
  ErrorsCode["Project_Scene_Not_Found"] = "Project_Scene_Not_Found";
  ErrorsCode["Color_Tag_Not_Found"] = "Color_Tag_Not_Found";
  ErrorsCode["Color_Tag_Cant_Link_Self"] = "Color_Tag_Cant_Link_Self";
  ErrorsCode["Color_Tag_Slug_Already_Exists"] = "Color_Tag_Slug_Already_Exists";
  ErrorsCode["Room_Tag_Not_Found"] = "Room_Tag_Not_Found";
  ErrorsCode["Room_Tag_Cant_Link_Self"] = "Room_Tag_Cant_Link_Self";
  ErrorsCode["Room_Tag_Slug_Already_Exists"] = "Room_Tag_Slug_Already_Exists";
  ErrorsCode["Product_Material_Tag_Not_Found"] = "Product_Material_Tag_Not_Found";
  ErrorsCode["Product_Material_Tag_Cant_Link_Self"] = "Product_Material_Tag_Cant_Link_Self";
  ErrorsCode["Product_Material_Tag_Slug_Already_Exists"] = "Product_Material_Tag_Slug_Already_Exists";
  ErrorsCode["Object_Product_Not_Found"] = "Object_Product_Not_Found";
  ErrorsCode["Object_Product_Data_3D_Product_Not_Found"] = "Object_Product_Data_3D_Product_Not_Found";
  ErrorsCode["Object_Product_FBX_Processing_Not_Found"] = "Object_Product_FBX_Processing_Not_Found";
  ErrorsCode["Object_Product_FBX_Processing_Already_Exist"] = "Object_Product_FBX_Processing_Already_Exist";
  ErrorsCode["Object_Product_Category_Not_Found"] = "Object_Product_Category_Not_Found";
  ErrorsCode["Object_Product_Dimension_Not_Found"] = "Object_Product_Dimension_Not_Found";
  ErrorsCode["Object_Product_Category_Cant_Link_Self"] = "Object_Product_Category_Cant_Link_Self";
  ErrorsCode["Object_Product_Category_Slug_Already_Exists"] = "Object_Product_Category_Slug_Already_Exists";
  ErrorsCode["Object_Product_Model_Bundle_Already_Exist"] = "Object_Product_Model_Bundle_Already_Exist";
  ErrorsCode["Object_Product_Model_Bundle_Not_Found"] = "Object_Product_Model_Bundle_Not_Found";
  ErrorsCode["Object_Product_Variation_Not_Found"] = "Object_Product_Variation_Not_Found";
  ErrorsCode["Object_Product_Variation_Material_Not_Found"] = "Object_Product_Variation_Material_Not_Found";
  ErrorsCode["Wrong_Computer_Vision_Type"] = "Wrong_Computer_Vision_Type";
  ErrorsCode["Computer_Vision_Request_Not_Found"] = "Computer_Vision_Request_Not_Found";
  ErrorsCode["Object_Detection_Not_Found"] = "Object_Detection_Not_Found";
  ErrorsCode["Object_Detection_Or_File_Required"] = "Object_Detection_Or_File_Required";
  ErrorsCode["Computer_Vision_Request_Failed"] = "Computer_Vision_Request_Failed";
  ErrorsCode["Brand_Contact_Not_Found"] = "Brand_Contact_Not_Found";
  ErrorsCode["Product_Problem_Type_Already_Exist"] = "Product_Problem_Type_Already_Exist";
  ErrorsCode["Product_Eshop_Not_Found"] = "Product_Eshop_Not_Found";
  ErrorsCode["Project_Scene_Version_Not_Found"] = "Project_Scene_Version_Not_Found";
  ErrorsCode["Project_Scene_Template_Not_Found"] = "Project_Scene_Template_Not_Found";
  ErrorsCode["Brand_Project_Scene_Template_Not_Found"] = "Brand_Project_Scene_Template_Not_Found";
  ErrorsCode["Architect_Project_Scene_Template_Not_Found"] = "Architect_Project_Scene_Template_Not_Found";
  ErrorsCode["Camera_Template_Not_Found"] = "Camera_Template_Not_Found";
  ErrorsCode["Render_Not_Found"] = "Render_Not_Found";
  ErrorsCode["Project_Packshot_Not_Found"] = "Project_Packshot_Not_Found";
  ErrorsCode["Project_Scene_Version_Render_Not_Found"] = "Project_Scene_Version_Render_Not_Found";
  ErrorsCode["Project_Packshot_Request_Not_Found"] = "Project_Packshot_Request_Not_Found";
  ErrorsCode["Product_State_Not_Found"] = "Product_State_Not_Found";
  ErrorsCode["Product_Source_File_Not_Found"] = "Product_Source_File_Not_Found";
  ErrorsCode["Product_Document_Not_Found"] = "Product_Document_Not_Found";
  ErrorsCode["Project_Scene_Version_Quote_Empty"] = "Project_Scene_Version_Quote_Empty";
  ErrorsCode["Brand_Drive_Folder_Already_Exist"] = "Brand_Drive_Folder_Already_Exist";
  ErrorsCode["Product_Picture_Not_Found"] = "Product_Picture_Not_Found";
  ErrorsCode["Brand_File_Not_Found"] = "Brand_File_Not_Found";
  ErrorsCode["Brand_Invitation_Not_Found"] = "Brand_Invitation_Not_Found";
  ErrorsCode["Brand_Invitation_Already_Exist"] = "Brand_Invitation_Already_Exist";
  ErrorsCode["Brand_Invitation_Already_Accepted"] = "Brand_Invitation_Already_Accepted";
  ErrorsCode["Account_Need_Password"] = "Account_Need_Password";
  ErrorsCode["Brand_Invitation_Invalid_File"] = "Brand_Invitation_Invalid_File";
  ErrorsCode["Brand_Invitation_File_Required"] = "Brand_Invitation_File_Required";
  ErrorsCode["Brand_Drive_File_Not_At_Root"] = "Brand_Drive_File_Not_At_Root";
  ErrorsCode["Brand_Drive_Folder_Doesnt_Exist"] = "Brand_Drive_Folder_Doesnt_Exist";
  ErrorsCode["Account_Already_Have_Brand_Access"] = "Account_Already_Have_Brand_Access";
  ErrorsCode["Account_Not_Validated"] = "Account_Not_Validated";
  ErrorsCode["Email_Already_Validated"] = "Email_Already_Validated";
  ErrorsCode["Subscription_Not_Found"] = "Subscription_Not_Found";
  ErrorsCode["Quota_Max_Reached"] = "Quota_Max_Reached";
  ErrorsCode["Quota_Reached"] = "Quota_Reached";
  ErrorsCode["Quota_Max_Exceeded"] = "Quota_Max_Exceeded";
})(ErrorsCode = exports.ErrorsCode || (exports.ErrorsCode = {}));