"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.addProjectSceneTemplates = exports.setProjectSceneTemplates = void 0;

var model_1 = require("./model");

var setProjectSceneTemplates = function setProjectSceneTemplates(templates, total) {
  return {
    type: model_1.ActionsTypes.SET_PROJECT_SCENE_TEMPLATES,
    templates: templates,
    total: total
  };
};

exports.setProjectSceneTemplates = setProjectSceneTemplates;

var addProjectSceneTemplates = function addProjectSceneTemplates(templates, total) {
  return {
    type: model_1.ActionsTypes.ADD_PROJECT_SCENE_TEMPLATES,
    templates: templates,
    total: total
  };
};

exports.addProjectSceneTemplates = addProjectSceneTemplates;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_PROJECT_SCENE_TEMPLATE_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;