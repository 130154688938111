import { Button, Popconfirm, Theme } from '@addsome/ui-kit'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import folder from '../../../assets/images/folder.svg'
import styles from './FolderItem.module.scss'

interface IProps {
  label: string
  path: string
  onDelete: (path: string) => void
  onClick: (path: string) => void
}

const FolderItem: FC<IProps> = ({ label, onDelete, path, onClick }) => {
  const intl = useIntl()
  return (
    <div className={styles.container} onClick={() => onClick(path)}>
      <div className={styles.icon}>
        <img src={folder} />
      </div>
      <div className={styles.label}>{label}</div>
      <div className={styles.actions}>
        <Popconfirm
          title={intl.formatMessage({ id: 'global.confirmdelete' })}
          onConfirm={e => {
            if (e) e.stopPropagation()
            onDelete(path)
          }}
          onCancel={e => e && e.stopPropagation()}
          okText={intl.formatMessage({ id: 'global.yes' })}
          cancelText={intl.formatMessage({ id: 'global.no' })}
          placement="left"
        >
          <Button
            theme={Theme.GHOST}
            shape="circle"
            icon="delete"
            title={intl.formatMessage({ id : 'global.delete'})}
            aria-label={`Supprimer ${label}`}
            onClick={e => e.stopPropagation()}
          />
        </Popconfirm>
      </div>
    </div>
  )
}

export default FolderItem
