import { Button, Loading, Theme } from '@addsome/ui-kit';
import { Form } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import styles from './RegisterSubscriptionFormModal.module.scss';
import { IRegisterStepProps } from '../register-steps';
import { getSubscriptions} from '../../../services/subscription'
import { ArchitectSubscriptionDTO } from '@addsome/dtos/dist';
import { IRootState } from '../../../redux';
import { parse } from 'querystring';
import { connect } from 'react-redux';
import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel';

type IProps = WrappedComponentProps & IRegisterStepProps & ReturnType<typeof mapStateToProps>;

const RegisterSubscriptionFormModal: React.FC<IProps> = ({ initialValues, onBack, onSubmit, intl, queryParams }) => {
  const [loadRegistration, setLoadRegistration] = useState(false);
  const [freeTrialSubscription, setFreeTrialSubscription] = useState<ArchitectSubscriptionDTO | undefined>();
  const [unlimitedSubscription, setUnlimitedSubscription] = useState<ArchitectSubscriptionDTO | undefined>();
  
  useEffect(() => {
    Mixpanel.track(MixpanelEvents.RegisterStep2)
  }, [])
  
  useEffect(() => {
    getSubscriptions().then((s) => {
      setFreeTrialSubscription(s.find(s => s.price === 0));
      setUnlimitedSubscription(s.find(s => s.slug === 'Unlimited'));
    })
  }, [])

  return (
    <div className={styles.registerAddressFormModal}>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {formik => {
          return (
            <Form className={styles.form} onSubmit={formik.handleSubmit} noValidate>
              <div className={styles.selectPlanRegion}>
                {freeTrialSubscription ?
                  <>
                    <div className={styles.planName}>
                      <h1><FormattedMessage id="register.architect.free" /></h1>
                    </div>
                    <p style={{display: "flex", justifyContent: "center", height: "75px"}}>
                      <FormattedMessage id="register.architect.freetrial.description" />
                    </p>
                    <div style={{height: "75px"}}>
                      <span style={{display: "flex", justifyContent: "center", color: "black", fontWeight: "400"}}>
                        <FormattedMessage id="register.architect.download.per.month" />
                      </span>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <span style={{backgroundColor: "black", borderRadius: "50%", color:"white", width:"38px", height:"36px", display:"flex", alignItems:"center", justifyContent: "center"}}>
                          {freeTrialSubscription.quota}
                        </span>
                      </div>
                    </div>
                    
                    <div className={styles.priceGroup}>
                      <h1 style={{display: "flex", justifyContent: "center", paddingTop: "15px", paddingBottom: "15px", textTransform:'uppercase'}}>
                        <FormattedMessage id="register.architect.free" />
                      </h1>
                    </div>
                    <span style={{display: "flex", justifyContent: "center"}}>
                      <FormattedMessage id="register.architect.freetrial.priceDescription" />
                    </span>
                    <div className={styles.submitBtnGroup}>
                      <Button
                        theme={Theme.PRIMARY}
                        htmlType="submit"
                        size="large"
                        uppercase
                        disabled={Object.values(formik.errors).length > 0}
                        loading={loadRegistration}
                        onClick={() => { formik.setFieldValue('subscriptionType', freeTrialSubscription.slug); setLoadRegistration(true); }}
                      >
                        <FormattedMessage id="register.architect.subscription.selectFreeTrial" />
                      </Button>
                    </div>
                  </>
                  :
                  <div><Loading /></div>
                }
              </div>
              <div className={styles.selectPlanRegion}>
                {unlimitedSubscription ?
                  <>
                    <div className={styles.planName}>
                      <h1>
                        <FormattedMessage id="register.architect.unlimited.title" />
                      </h1>
                    </div>
                    <p style={{display: "flex", justifyContent: "center", height: "75px"}}>
                      <FormattedMessage id="register.architect.unlimited.description" />
                    </p>
                    <div style={{display: "flex", justifyContent: "center", height: "75px"}}>
                      <span style={{
                        backgroundColor: "black",
                        textAlign: "center",
                        color: "white",
                        padding: "5px 15px",
                        border: "2px solid black",
                        borderRadius: "25px",
                        display: "inline-block",
                        fontWeight: "400",
                        fontSize: "14px",
                        height: "35px",
                        width: "220px"
                      }}>
                        <FormattedMessage id="register.architect.unlimited.downloads" />
                      </span>
                      
                    </div>
                    <div className={styles.priceGroup}>
                      <h1 style={{display: "flex", justifyContent: "center", paddingTop: "15px", paddingBottom: "15px"}}>
                        {unlimitedSubscription.price} € / <FormattedMessage id="register.architect.month" />
                      </h1>
                    </div>
                    <span style={{display: "flex", justifyContent: "center"}}>
                      <FormattedMessage id="register.architect.unlimited.priceDescription" />
                    </span>
                    <div className={styles.submitBtnGroup}>
                      <Button
                        theme={Theme.PRIMARY}
                        htmlType="submit"
                        size="large"
                        uppercase
                        disabled={Object.values(formik.errors).length > 0}
                        loading={loadRegistration}
                        onClick={() => { formik.setFieldValue('subscriptionType', unlimitedSubscription.slug); setLoadRegistration(true);  }}
                      >
                        <FormattedMessage id="register.architect.subscription.selectPlan" />
                      </Button>
                    </div>
                  </> : <Loading></Loading>
                }
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string },
});

export default connect(
  mapStateToProps,
  undefined
)(injectIntl(RegisterSubscriptionFormModal));
