"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _toConsumableArray = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/toConsumableArray");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  scenes: [],
  scene: null,
  loading: false,
  total: 0
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_SCENES, function (state, _ref) {
  var scenes = _ref.scenes,
      total = _ref.total;
  return Object.assign(Object.assign({}, state), {
    scenes: scenes,
    total: total
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.ADD_SCENES, function (state, _ref2) {
  var scenes = _ref2.scenes,
      total = _ref2.total;
  return Object.assign(Object.assign({}, state), {
    scenes: [].concat(_toConsumableArray(state.scenes), _toConsumableArray(scenes)),
    total: total
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_SCENE_LOADING, function (state, _ref3) {
  var loading = _ref3.loading;
  return Object.assign(Object.assign({}, state), {
    loading: loading
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UPDATE_SCENE, function (state, _ref4) {
  var scene = _ref4.scene;
  return Object.assign(Object.assign({}, state), {
    scene: scene
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_SCENE, function (state, _ref5) {
  var scene = _ref5.scene;
  return Object.assign(Object.assign({}, state), {
    scene: scene
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UNSET_SCENE, function (state) {
  return Object.assign(Object.assign({}, state), {
    scene: null
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.REMOVE_SCENE, function (state, _ref6) {
  var id = _ref6.id;
  return Object.assign(Object.assign({}, state), {
    scenes: state.scenes.filter(function (scene) {
      return scene.id !== id;
    })
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);