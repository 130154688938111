import React from 'react'
import { useIntl, WrappedComponentProps, injectIntl } from 'react-intl';
import styles from './NotFound.module.scss'
import bg from '../../assets/images/home-other-bg.png';


const NotFound: React.FC<WrappedComponentProps> = ({ intl }) => {
  return (
    <div className={styles.notFoundContainer}>
      <img src={bg}></img>
      <span>404</span>
      <h1 className={styles.notFound}>{intl.formatMessage({ id: 'page.notFound' })}</h1>
    </div>
  )
}

export default injectIntl(NotFound)
