import {
  ObjectProductMetricsDTO,
  ObjectProductMetricsGroupBy,
  ObjectProductType
} from '@addsome/dtos';
import { metrics as metricsActions } from '@addsome/redux-store';
import { ThunkDispatch } from '@addsome/redux-store/node_modules/redux-thunk';
import {
  BarChart,
  CategoryColor,
  Container,
  DateRangePicker,
  Heading,
  IBarChartData,
  Option,
  Select
} from '@addsome/ui-kit';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IRootState } from '../../redux';
import { getMonthsBetween } from '../../utils/utils';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import styles from './MetricsBars.module.scss';

const getGroupedValues = (
  objectProductMetrics: ObjectProductMetricsDTO[],
  objectProductType: ObjectProductType,
  groupBy: ObjectProductMetricsGroupBy,
  startDate: Date,
  endDate: Date
): number[] => {
  // Years
  if (groupBy === ObjectProductMetricsGroupBy.Year) {
    const values: number[] = [];
    for (let year = moment(startDate).year(); year <= moment(endDate).year(); year++) {
      const foundMetric = objectProductMetrics.find(
        metric => metric.year === year && metric.type === objectProductType
      );
      values.push(foundMetric ? foundMetric.count : 0);
    }
    return values;
  }

  // Months
  else if (groupBy === ObjectProductMetricsGroupBy.Month) {
    const months = getMonthsBetween(startDate, endDate);
    return months.map(month => {
      const foundMetric = objectProductMetrics.find(
        metric =>
          metric.month === month.month &&
          metric.year === month.year &&
          metric.type === objectProductType
      );
      if (foundMetric) {
        return foundMetric.count;
      }
      return 0;
    });
  }

  // Days
  else {
    const values: number[] = [];
    for (let dt = moment(startDate); dt <= moment(endDate); dt = dt.add(1, 'day')) {
      const foundMetric = objectProductMetrics.find(metric => {
        const day = moment(metric.day);
        return day.isSame(dt, 'day') && metric.type === objectProductType;
      });
      values.push(foundMetric ? foundMetric.count : 0);
    }
    return values;
  }
};

const getTimeLabels = (
  startDate: Date,
  endDate: Date,
  groupBy: ObjectProductMetricsGroupBy
): string[] => {
  // Years
  if (groupBy === ObjectProductMetricsGroupBy.Year) {
    const labels: string[] = [];
    for (const dt = new Date(startDate); dt <= endDate; dt.setFullYear(dt.getFullYear() + 1)) {
      labels.push(`${dt.getFullYear()}`);
    }
    return labels;

    // Months
  } else if (groupBy === ObjectProductMetricsGroupBy.Month) {
    const months = getMonthsBetween(startDate, endDate);
    return months.map(month => month.label);

    // Days
  } else {
    const labels: string[] = [];
    for (let dt = moment(startDate); dt <= moment(endDate); dt = dt.add(1, 'day')) {
      labels.push(dt.format('DD-MM-YY'));
    }
    return labels;
  }
};

type IProps = WrappedComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    brandIds?: string[];
    noHeading?: boolean;
  };

const MetricsBars: React.FC<IProps> = ({
  fetchObjectProductMetrics,
  objectProductMetrics,
  brandIds,
  noHeading = false,
  intl
}) => {
  const [startDate, setStartDate] = useState<Date>(
    moment(moment.now())
      .subtract(1, 'year')
      .toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment(moment.now()).toDate());
  const [barChartDatas, setBarChartDatas] = useState<IBarChartData[]>([]);
  const [groupBy, setGroupBy] = useState(ObjectProductMetricsGroupBy.Month);

  useEffect(() => {
    fetchObjectProductMetrics(startDate, endDate, groupBy, brandIds);
  }, [brandIds, endDate, fetchObjectProductMetrics, groupBy, startDate]);

  useEffect(() => {
    const datas: IBarChartData[] = [
      {
        label: intl.formatMessage({ id: 'global.furniture' }),
        values: getGroupedValues(
          objectProductMetrics,
          ObjectProductType.Furniture,
          groupBy,
          startDate,
          endDate
        ),
        color: CategoryColor.LIGHT_GREY
      },
      {
        label: intl.formatMessage({ id: 'global.accessories' }),
        values: getGroupedValues(
          objectProductMetrics,
          ObjectProductType.Accessories,
          groupBy,
          startDate,
          endDate
        ),
        color: CategoryColor.DARK_BLUE
      },
      {
        label: intl.formatMessage({ id: 'global.lighting' }),
        values: getGroupedValues(
          objectProductMetrics,
          ObjectProductType.Lighting,
          groupBy,
          startDate,
          endDate
        ),
        color: CategoryColor.CYAN
      }
    ];
    setBarChartDatas(datas);
  }, [endDate, groupBy, intl, objectProductMetrics, startDate]);

  return (
    <div className={styles.container}>
      {!noHeading && (
        <Heading level={3} strong as="h2">
          <FormattedMessage id="homepage.section.title.addedProducts" />
        </Heading>
      )}

      <Container className={styles.chartContainer}>
        <BarChart
          datas={barChartDatas}
          xLabels={getTimeLabels(startDate, endDate, groupBy)}
          containerClassName={styles.chart}
        />
        <div className={styles.inputsContainer}>
          <label htmlFor="filterBy" className={styles.title}>
            <FormattedMessage id="homepage.metrics.filterBy" />
          </label>
          <Select
            id="filterBy"
            defaultValue={ObjectProductMetricsGroupBy.Month}
            onChange={newValue => {
              setGroupBy(newValue as ObjectProductMetricsGroupBy);
              fetchObjectProductMetrics(
                startDate,
                endDate,
                newValue as ObjectProductMetricsGroupBy,
                brandIds
              );
            }}
            className={styles.select}
          >
            <Option value={ObjectProductMetricsGroupBy.Day}>
              <FormattedMessage id="homepage.metrics.days" />
            </Option>
            <Option value={ObjectProductMetricsGroupBy.Month}>
              <FormattedMessage id="homepage.metrics.months" />
            </Option>
            <Option value={ObjectProductMetricsGroupBy.Year}>
              <FormattedMessage id="homepage.metrics.years" />
            </Option>
          </Select>
          <DateRangePicker
            placeholder={['Date début', 'Date fin']}
            defaultValue={[moment(moment.now()).subtract(1, 'year'), moment(moment.now())]}
            onChange={value => {
              setStartDate(moment(value[0]).toDate());
              setEndDate(moment(value[1]).toDate());
              fetchObjectProductMetrics(
                moment(value[0]).toDate(),
                moment(value[1]).toDate(),
                groupBy,
                brandIds
              );
            }}
            ranges={{
              'Cette année': [moment(moment.now()).subtract(1, 'year'), moment(moment.now())],
              'Année précédente': [
                moment(moment.now()).subtract(2, 'year'),
                moment(moment.now()).subtract(1, 'year')
              ]
            }}
            className={styles.datePicker}
          />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  objectProductMetrics: state.metricsState.objectProductMetrics
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchObjectProductMetrics: (
    startDate: Date,
    endDate: Date,
    groupBy: ObjectProductMetricsGroupBy,
    brandIds?: string[]
  ) => dispatch(metricsActions.fetchObjectProductMetrics(startDate, endDate, groupBy, brandIds))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MetricsBars));
