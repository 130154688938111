import { Heading, Size } from '@addsome/ui-kit'
import React, { useEffect } from 'react'
import { FormattedMessage, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import bg from '../../assets/images/home-bg.png'
import PageHeader from '../../components/PageHeaders/PageHeader'
import Clients from '../../components/Clients/Clients'
import { IRootState } from '../../redux'
import { updatePageTitle } from '../../services/title'
import { AccountType, BrandUserWithBrandsDTO } from '@addsome/dtos/dist'
import usePush from '../../hooks/usePush'

type IProps = ReturnType<typeof mapStateToProps> & WrappedComponentProps

const ClientPage: React.FC<IProps> = ({ accountType, user }) => {
  const intl = useIntl()
  const { push } = usePush()

  const background =
    user.brands.length !== 0 && user.brands[0].cover && user.brands[0].cover.url
      ? user.brands[0].cover.url
      : bg

  useEffect(() => {
    if (accountType !== AccountType.BrandUser) {
      // @TODO: Redirect to 404 but we don't have a 404
      push('/')
    }
  }, [accountType, push])

  updatePageTitle(intl.formatMessage({ id: 'clientpage.title' }))

  return (
    <>
      <PageHeader
        title={
          <Heading level={1} strong centerText>
            <FormattedMessage id="clientpage.title" />
          </Heading>
        }
        backgroundSrc={background}
        size={Size.LARGE}
      />
      <Clients />
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user as BrandUserWithBrandsDTO,
  accountType: state.authState.type
})

export default connect(mapStateToProps)(ClientPage)
