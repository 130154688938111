"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_SCENES"] = "SET_SCENES";
  ActionsTypes["SET_SCENE"] = "SET_SCENE";
  ActionsTypes["UNSET_SCENE"] = "UNSET_SCENE";
  ActionsTypes["UPDATE_SCENE"] = "UPDATE_SCENE";
  ActionsTypes["REMOVE_SCENE"] = "REMOVE_SCENE";
  ActionsTypes["SET_SCENE_LOADING"] = "SET_SCENE_LOADING";
  ActionsTypes["ADD_SCENES"] = "ADD_SCENES";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));