"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addUserProjects = exports.setLoading = exports.removeUserProject = exports.unsetUserProject = exports.setUserProject = exports.setUserProjects = void 0;

var model_1 = require("./model");

var setUserProjects = function setUserProjects(projects, total) {
  return {
    type: model_1.ActionsTypes.SET_USER_PROJECTS,
    projects: projects,
    total: total
  };
};

exports.setUserProjects = setUserProjects;

var setUserProject = function setUserProject(project) {
  return {
    type: model_1.ActionsTypes.SET_USER_PROJECT,
    project: project
  };
};

exports.setUserProject = setUserProject;

var unsetUserProject = function unsetUserProject() {
  return {
    type: model_1.ActionsTypes.UNSET_USER_PROJECT
  };
};

exports.unsetUserProject = unsetUserProject;

var removeUserProject = function removeUserProject(id) {
  return {
    type: model_1.ActionsTypes.REMOVE_USER_PROJECT,
    id: id
  };
};

exports.removeUserProject = removeUserProject;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_USER_PROJECT_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;

var addUserProjects = function addUserProjects(projects, total) {
  return {
    type: model_1.ActionsTypes.ADD_USER_PROJECTS,
    projects: projects,
    total: total
  };
};

exports.addUserProjects = addUserProjects;