import { AddsomeUserDTO } from '@addsome/dtos'
import { Button, Popconfirm, Table, Theme, Switch } from '@addsome/ui-kit'
import { addsomeUser as addsomeUserActions } from '@addsome/redux-store'
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import style from './AdminsTable.module.scss'
import moment from 'moment'

type IProps = ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps & {
    addsomeUsers: AddsomeUserDTO[]
    total: number
    currentPage: number
    pageSize: number
    loading: boolean
    onTableChange: (
      pagination: PaginationConfig,
      filter: Record<keyof AddsomeUserDTO, string[]>,
      sorter: SorterResult<AddsomeUserDTO>
    ) => void
    onDelete: (id: string) => void
    sortBy?: { [key: string]: number }
    filters?: { [key: string]: string[] }
  }
const isMobile = window.innerWidth <= 600

class AdminsTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<AddsomeUserDTO>> = [
    {
      title: <FormattedMessage id="architecttable.name" />,
      dataIndex: 'account.firstName',
      key: 'firstName',
      render: (value, record) => (
        <Link to={`/addsome-users/${record.id}/edit`}>
          {value} {record.account.lastName}
        </Link>
      ),
      sorter: true
    },
    {
      title: 'Mail',
      dataIndex: 'account.email',
      key: 'email'
    },
    {
      title: <FormattedMessage id="architecttable.update" />,
      dataIndex: 'account.createdAt',
      key: 'createdAt',
      sorter: true,
      render: (value: Date) => moment(value).format('DD/MM/YYYY')
    },
    {
      title: 'Email',
      dataIndex: 'account.emailValidated',
      key: 'emailValidated',
      render: (_, record) => (
        <Switch
          checked={record.account.emailValidated}
          onChange={() => this.props.toggleAddsomeUserEmail(record.id, record.account.emailValidated)}
        />
      )
    },
    {
      title: <FormattedMessage id="architecttable.account" />,
      dataIndex: 'account.enabled',
      key: 'account.enabled',
      render: (value, record) => (
        <Switch
          checked={record.account.enabled}
          onChange={() => this.props.toggleAddsomeUserAccount(record.id, value)}
        />
      )
    },
    {
      title: "Perform deletions",
      dataIndex: 'perform_deletions',
      key: 'perform_deletions',
      render: (value, record) => (
        <Switch
          checked={record.perform_deletions}
          onChange={() => this.props.toggleAddsomeUserDeletions(record.id, value)}
        />
      )
    },
    {
      title: "Data entry",
      dataIndex: 'data_entry',
      key: 'data_entry',
      render: (value, record) => (
        <Switch
          checked={record.data_entry}
          onChange={() => this.props.toggleAddsomeUserDataEntry(record.id, value)}
        />
      )
    },
    {
      title: '',
      key: 'enabled',
      render: (v, record) =>
        !record.deletedAt ? (
          <Popconfirm
            title={this.props.intl.formatMessage({ id: 'global.confirmdelete' })}
            onConfirm={() => this.props.onDelete(record.id)}
            okText={this.props.intl.formatMessage({ id: 'global.yes' })}
            cancelText={this.props.intl.formatMessage({ id: 'global.no' })}
            placement="left"
          >
            <span className={style.actions}>
              <Button
                theme={Theme.PRIMARY}
                shape="circle"
                aria-label="Éditer"
                icon="edit"
                onClick={() => this.props.redirect(`/addsome-users/${record.id}/edit`)}
              >
              </Button>
              <Button
                theme={Theme.GHOST}
                shape="circle"
                icon="delete"
                title={this.props.intl.formatMessage({ id: 'global.delete' })}
                aria-label={`Supprimer ${record.account.lastName}`}
              />
            </span>
          </Popconfirm>
        ) : (
          <FormattedMessage id='userspage.deleted' />
        ),
      width: '5%',
      dataIndex: 'account.enabled',
      filters: [
        { text: this.props.intl.formatMessage({ id: 'user.enable' }), value: 'true' },
        { text: 'Pending', value: 'false' }
      ],
      filterMultiple: false
    }
  ]

  public edit(key: string) {
    this.setState({ editingKey: key })
  }

  private mobileColumns: Array<ColumnProps<AddsomeUserDTO>> = [
    {
      title: <FormattedMessage id="architecttable.name" />,
      dataIndex: 'account.firstName',
      key: 'firstName',
      render: (value, record) => (
        <Link to={`/addsome-users/${record.id}/edit`}>
          {value} {record.account.lastName}
        </Link>
      ),
      sorter: true
    },
    {
      title: 'Mail',
      dataIndex: 'account.email',
      key: 'email'
    },
    {
      title: <FormattedMessage id="architecttable.account" />,
      dataIndex: 'account.enabled',
      key: 'account.enabled',
      render: (value, record) => (
        <Switch
          checked={record.account.enabled}
          onChange={() => this.props.toggleAddsomeUserAccount(record.id, value)}
        />
      )
    },
    {
      title: '',
      key: 'enabled',
      render: (v, record) =>
        !record.deletedAt ? (
          <Popconfirm
            title={this.props.intl.formatMessage({ id: 'global.confirmdelete' })}
            onConfirm={() => this.props.onDelete(record.id)}
            okText={this.props.intl.formatMessage({ id: 'global.yes' })}
            cancelText={this.props.intl.formatMessage({ id: 'global.no' })}
            placement="left"
          >
            <Button
              theme={Theme.GHOST}
              shape="circle"
              icon="delete"
              title={this.props.intl.formatMessage({ id: 'global.delete' })}
              aria-label={`Supprimer ${record.account.lastName}`}
            />
          </Popconfirm>
        ) : (
          <FormattedMessage id='userspage.deleted' />
        ),
      width: '5%',
      dataIndex: 'account.enabled',
      filters: [
        { text: this.props.intl.formatMessage({ id: 'user.enable' }), value: 'true' },
        { text: 'Pending', value: 'false' }
      ],
      filterMultiple: false
    }
  ]

  public render() {
    const columns = [...(isMobile ? this.mobileColumns : this.columns)]

    const pagination: PaginationConfig = {
      total: this.props.total,
      pageSize: this.props.pageSize,
      current: this.props.currentPage,
      showTotal: total =>
        `${total} ${this.props.intl.formatMessage({ id: 'architecttable.results' })}`
    }

    // Init sort and filters
    if (this.props.sortBy || this.props.filters) {
      const sortBy = this.props.sortBy
      const filters = this.props.filters

      columns.forEach(column => {
        if (sortBy) {
          const orderValue = column.key
            ? sortBy[column.key]
            : (column.dataIndex && sortBy[column.dataIndex]) || 0

          if (orderValue) {
            column.defaultSortOrder = orderValue > 0 ? 'ascend' : 'descend'
          }
        }

        if (filters) {
          column.filteredValue = column.key
            ? filters[column.key]
            : (column.dataIndex && filters[column.dataIndex]) || undefined
          if (column.key === 'account.enabled') column.filteredValue = filters['enabled']
        }
      })
    }

    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.addsomeUsers}
          rowKey="id"
          pagination={pagination}
          loading={this.props.loading}
          onChange={this.props.onTableChange}
          rowClassName={this.getRowClassName}
        />
      </div>
    )
  }

  private getRowClassName = (record: AddsomeUserDTO) => {
    return record.deletedAt ? style.deleted : ''
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  push: (location: string) => dispatch(push(location)),
  toggleAddsomeUserAccount: (id: string, isEnabled: boolean) => {
    dispatch(
      addsomeUserActions.thunks.patchValue(id, {
        account: {
          enabled: !isEnabled
        }
      })
    )
  },
  toggleAddsomeUserEmail: (id: string, isEnabled: boolean) => {
    dispatch(
      addsomeUserActions.thunks.patchValue(id, {
        account: {
          emailValidated: !isEnabled
        }
      })
    )
  },
  toggleAddsomeUserDeletions: (id: string, isEnabled: boolean) => {
    dispatch(
      addsomeUserActions.thunks.patchValue(id, {
        perform_deletions: !isEnabled
      })
    )
  },
  toggleAddsomeUserDataEntry: (id: string, isEnabled: boolean) => {
    dispatch(
      addsomeUserActions.thunks.patchValue(id, {
        data_entry: !isEnabled
      })
    )
  },
  redirect: (location: string) => dispatch(push(location)),
})

export default connect(
  undefined,
  mapDispatchToProps
)(injectIntl(AdminsTable))
