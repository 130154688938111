"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var storage_1 = require("class-transformer/storage");

var class_validator_jsonschema_1 = require("class-validator-jsonschema");

exports.metadatas = class_validator_1.getFromContainer(class_validator_1.MetadataStorage).validationMetadatas;

if (process.env.NODE_ENV === 'production') {
  var classes = [];
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = exports.metadatas[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var metadata = _step.value;
      var classType = metadata && metadata.target;
      if (!classType) continue;

      if (classes.indexOf(classType) === -1) {
        classes.push(classType);
        Object.defineProperty(classType, 'name', {
          writable: true
        });
        classType.name = (classes.length - 1).toString();
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
}

exports.definitions = class_validator_jsonschema_1.validationMetadatasToSchemas(exports.metadatas, {
  skipMissingProperties: false,
  classTransformerMetadataStorage: storage_1.defaultMetadataStorage
});