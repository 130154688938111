import { AccountType, BrandMetricsDTO, BrandsMetricsDTO, BrandUpdateDTO } from '@addsome/dtos'
import {
  brand as brandActions,
  IPaginationRequest,
  metrics,
  product as productActions
} from '@addsome/redux-store'
import { CategoryColor, Diagram, Heading, Integration, Size, Tabs } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import get from 'lodash.get'
import { parse, stringify } from 'querystring'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import BrandInformation from '../../../components/Brands/BrandInformation/BrandInformation'
import BrandProducts from '../../../components/Brands/BrandProducts/BrandProducts'
import PageHeader from '../../../components/PageHeaders/PageHeader'
import { IRootState } from '../../../redux'
import { updatePageTitle } from '../../../services/title'
import styles from './BrandPage.module.scss'
import { multiply3Dmetrics } from '../../../utils/multiply3Dmetrics'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IMatchParams> &
  WrappedComponentProps

interface IState {
  productsCurrentPage: number
  searchPattern: string
  activeKey: string
}

class BrandPage extends React.Component<IProps, IState> {
  public state: IState = {
    productsCurrentPage: 0,
    searchPattern: '',
    activeKey: (this.props.queryParams.tab as string) || 'products'
  }

  private productsPageSize = 10

  public async componentDidMount() {
    await this.props.fetchBrand(this.props.match.params.id)
    updatePageTitle((this.props.brand || ({} as any)).name || 'Brand')
    this.props.fetchBrandMetric(this.props.match.params.id)
  }

  public componentWillUnmount() {
    this.props.unsetBrand()
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.queryParams !== this.props.queryParams) {
      if (this.props.queryParams.hasOwnProperty('tab'))
        this.setState({ activeKey: this.props.queryParams.tab as string })
    }
  }

  public render() {
    const { brand, intl, brandMetrics, accountType } = this.props
    const readOnly = this.props.accountType !== AccountType.AddsomeUser
    const headerProps = {
      title: (
        <Heading level={1} strong>
          {(brand && brand.name) || ''}
        </Heading>
      ),
      ...(brand
        ? {
          richParams: {
            info:
              accountType !== AccountType.AddsomeUser
                ? brand.websiteUrl && (
                  <a href={brand.websiteUrl} target="blank">
                    {brand.websiteUrl}
                  </a>
                )
                : `${intl.formatMessage({ id: 'brandpage.panel.addedOn' })} ${intl.formatDate(brand.createdAt)}`,
            enabled: brand.enabled,
            icon: get(brand, 'logo.url', ''),
            namespace: 'brands',
            onChangeEnabled: this.updateEnabled,
            readonly: readOnly
          },
          backgroundSrc: brand.cover && brand.cover.url ? brand.cover.url : undefined
        }
        : {})
    }
    return (
      <>
        <div className={styles.brandHeader}>
          <PageHeader {...headerProps} size={Size.MEDIUM}>
            {brandMetrics && (
              <div className={styles.metrics}>
                <Diagram
                  integration={Integration.DARK}
                  label={intl.formatNumber(
                    this.countTotalMetrics(brandMetrics, 'objectProductWithBundleCount')
                  )}
                  subLabel={intl.formatMessage({ id: 'brandpage.withBundle' })}
                  datas={[
                    {
                      label: intl.formatMessage({ id: 'global.furniture' }),
                      value:
                        brandMetrics.furniture && brandMetrics.furniture.objectProductWithBundleCount,
                      color: CategoryColor.LIGHT_GREY
                    },
                    {
                      label: intl.formatMessage({ id: 'global.lighting' }),
                      value:
                        brandMetrics.lighting && brandMetrics.lighting.objectProductWithBundleCount,
                      color: CategoryColor.CYAN
                    },
                    {
                      label: intl.formatMessage({ id: 'global.materials' }),
                      value:
                        brandMetrics.materials && brandMetrics.materials.objectProductWithBundleCount,
                      color: CategoryColor.BLUE
                    },
                    {
                      label: intl.formatMessage({ id: 'global.accessories' }),
                      value:
                        brandMetrics.accessories &&
                        brandMetrics.accessories.objectProductWithBundleCount,
                      color: CategoryColor.DARK_BLUE
                    }
                  ]}
                />
                <Diagram
                  integration={Integration.DARK}
                  label={intl.formatNumber(
                    this.countTotalMetrics(brandMetrics, 'objectProductCount')
                  )}
                  subLabel={intl.formatMessage({ id: 'global.products' })}
                  datas={[
                    {
                      label: intl.formatMessage({ id: 'global.furniture' }),
                      value: brandMetrics.furniture && brandMetrics.furniture.objectProductCount,
                      color: CategoryColor.LIGHT_GREY
                    },
                    {
                      label: intl.formatMessage({ id: 'global.lighting' }),
                      value: brandMetrics.lighting && brandMetrics.lighting.objectProductCount,
                      color: CategoryColor.CYAN
                    },
                    {
                      label: intl.formatMessage({ id: 'global.materials' }),
                      value: brandMetrics.materials && brandMetrics.materials.objectProductCount,
                      color: CategoryColor.BLUE
                    },
                    {
                      label: intl.formatMessage({ id: 'global.accessories' }),
                      value: brandMetrics.accessories && brandMetrics.accessories.objectProductCount,
                      color: CategoryColor.DARK_BLUE
                    }
                  ]}
                />
              </div>
            )}
          </PageHeader>
        </div>
        {brand && (
          <>
            {accountType !== AccountType.Architect ? (
              <>
                {/* query param can be either string or string[] */}
                <Tabs activeKey={this.state.activeKey} onChange={this.changeTab}>
                  <Tabs.TabPane tab={intl.formatMessage({ id: 'global.products' })} key="products">
                    <BrandProducts productsPageSize={this.productsPageSize} readonly={readOnly} />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={intl.formatMessage({ id: 'brandpage.tabs.informations' })}
                    key="info"
                  >
                    <BrandInformation brand={brand} readonly={readOnly} />
                  </Tabs.TabPane>
                </Tabs>
              </>
            ) : (
              <div className={styles.container}>
                <BrandInformation brand={brand} readonly={readOnly} />
              </div>
            )}
          </>
        )}
      </>
    )
  }

  private changeTab = (tab: string) => {
    const params = { ...this.props.queryParams, tab }
    this.props.updateQuery(stringify(params))
    this.setState({ activeKey: tab })
  }

  private updateEnabled = (enabled: boolean) => {
    if (this.props.brand) {
      this.props.patchBrand(this.props.brand.id, { enabled })
    }
  }

  private countTotalMetrics = (brandsMetrics: BrandsMetricsDTO, property: keyof BrandMetricsDTO) =>
    Object.values(brandsMetrics).reduce((total, metric) => total + metric[property], 0)
}

const mapStateToProps = (state: IRootState) => ({
  brand: state.brandState.value,
  productLoading: state.productState.loading,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')),
  accountType: state.authState.type,
  brandMetrics: state.metricsState.brandMetrics
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchBrand: (id: string) => dispatch(brandActions.thunks.fetchValue(id)),
  patchBrand: (id: string, payload: BrandUpdateDTO) =>
    dispatch(brandActions.thunks.patchValue(id, payload)),
  fetchProducts: (request: IPaginationRequest = {}) =>
    dispatch(productActions.thunks.fetchValues(request)),
  updateQuery: (params: string) => dispatch(push({ search: params })),
  unsetBrand: () => dispatch(brandActions.thunks.unsetValue()),
  fetchBrandMetric: (id: string) => dispatch(metrics.fetchBrandMetrics(id)),
  push: (to: string) => dispatch(push(to))
})

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BrandPage)
)
