import React from 'react'
import { LoginLayout } from '@addsome/ui-kit'
import logo from '../../assets/images/logo-white.png'
import bg from '../../assets/images/register-login-bg.jpg'
import Footer from '../../components/Footer/Footer'
import { useSelector } from 'react-redux'
import { IRootState } from '../../redux'
import PublicHeader from "../PublicHeader/PublicHeader";

interface IProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
  className?: string
  children: React.ReactNode
  displayImage?: boolean
}

const ConnectionLayout: React.FC<IProps> = ({ title, subtitle, className, children, displayImage = true }) => {
  const brand = useSelector((state: IRootState) => state.brandInvitationState.brand)
  const img = bg

  return (
      <>
        <PublicHeader darkTheme={false}></PublicHeader>
        <LoginLayout
          logo={logo}
          backgroundImage={img}
          pageTitle="Addsome Drive"
          title={title}
          subtitle={subtitle}
          footer={<Footer isLogin />}
          className={className}
          displayImage={displayImage}
        >
          {children}
        </LoginLayout>
      </>
  )
}

export default ConnectionLayout
