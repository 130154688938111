import { ArchitectExtendedBrandDTO } from '@addsome/dtos/dist'
import { Button, Theme } from '@addsome/ui-kit'
import React, { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import ArchitectUserCard from '../ArchitectUserCard/ArchitectUserCard'
import { SectionTemplate } from '../Section/Section'
import SkeletonUserCardsList from '../SkeletonCardsList/SkeletonUserCardsList/SkeletonUserCardsList'
import styles from './MostActiveUsersSection.module.scss'

interface IProps {
  users: ArchitectExtendedBrandDTO[]
  loading: boolean
}

const takeOptions = [10, 30, 50]
const isMobile = window.innerWidth <= 576

const MostActiveUsersSection: React.FC<IProps> = ({ users, loading }) => {
  const intl = useIntl()
  const [take, setTake] = useState(takeOptions[1])
  return (
    <SectionTemplate
      green
      title={intl.formatMessage({ id: 'homepage.section.title.mostActiveUsersSection' })}
      sectionTitleRightComponent={
        <div className={styles.buttonContainer}>
          {takeOptions.map(option => (
            <Button
              theme={option === take ? Theme.WHITE : Theme.WHITEGHOST}
              key={option}
              onClick={() => setTake(option)}
            >
              Top {option}
            </Button>
          ))}
        </div>
      }
    >
      {!loading ? (
        <div className={styles.userGrid}>
          {users.slice(0, take).map(user => (
            <ArchitectUserCard key={user.id} user={user} />
          ))}
        </div>
      ) : isMobile ? (
        <SkeletonUserCardsList nbItems={3} />
      ) : (
        <SkeletonUserCardsList nbItems={take} />
      )}
    </SectionTemplate>
  )
}

export default memo(MostActiveUsersSection)
