"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_TOKEN"] = "SET_TOKEN";
  ActionsTypes["RESET_TOKEN"] = "RESET_TOKEN";
  ActionsTypes["SET_AUTH_LOADING"] = "SET_AUTH_LOADING";
  ActionsTypes["SET_AUTH"] = "SET_AUTH";
  ActionsTypes["SET_ERROR"] = "SET_ERROR";
  ActionsTypes["UNSET_ERROR"] = "UNSET_ERROR";
  ActionsTypes["RESET_AUTH"] = "RESET_AUTH";
  ActionsTypes["HAS_LOGGED_IN"] = "HAS_LOGGED_IN";
  ActionsTypes["SHOULD_SHOW_ARCHITECT_FIRST_CONNECTION_POPUP"] = "SHOULD_SHOW_ARCHITECT_FIRST_CONNECTION_POPUP";
  ActionsTypes["SHOULD_SHOW_ARCHITECT_NOT_ENABLED_DOWNLOAD_POPUP"] = "SHOULD_SHOW_ARCHITECT_NOT_ENABLED_DOWNLOAD_POPUP";
  ActionsTypes["SHOULD_SHOW_ARCHITECT_NOT_LINKED_TO_BRAND_DOWNLOAD_POPUP"] = "SHOULD_SHOW_ARCHITECT_NOT_LINKED_TO_BRAND_DOWNLOAD_POPUP";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));