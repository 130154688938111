import { Button, Theme } from '@addsome/ui-kit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './MultiStepNavigation.module.scss'

interface IProps {
  onNext: (() => void) | 'submit'
  onPrevious: () => void
  nextDisabled?: boolean
  showPrevious?: boolean
  finish?: boolean
}

const MultiStepNavigation: React.FC<IProps> = ({
  onNext,
  onPrevious,
  nextDisabled = false,
  showPrevious = false,
  finish = false
}) => {
  return (
    <div className={styles.multiStepNavigation}>
      {showPrevious && (
        <Button onClick={onPrevious} uppercase block htmlType="button">
          <FormattedMessage id="global.steps.previous" />
        </Button>
      )}
      <Button
        {...(onNext !== 'submit' && { onClick: onNext })}
        theme={Theme.PRIMARY}
        disabled={nextDisabled}
        uppercase
        block
        htmlType={onNext === 'submit' ? 'submit' : 'button'}
      >
        <FormattedMessage id={`global.steps.${finish ? 'finish' : 'next'}`} />
      </Button>
    </div>
  )
}

export default MultiStepNavigation
