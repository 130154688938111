"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Platform;

(function (Platform) {
  Platform["Ios"] = "ios";
  Platform["Webgl"] = "webgl";
  Platform["Win64"] = "win64";
})(Platform = exports.Platform || (exports.Platform = {}));