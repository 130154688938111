import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Loader, OrbitControls, useGLTF, PerspectiveCamera, Environment, useProgress } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import styles from './ProductViewer.module.scss';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import * as THREE from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useAsset } from 'use-asset';
import * as crypto from 'crypto-js';
import { Button, Modal, Theme, Heading } from '@addsome/ui-kit'
import useLoggedUser from '../../../hooks/useUser'
import { ErrorsCode } from '@addsome/dtos/dist';
import usePush from '../../../hooks/usePush'
import Routes from '../../../utils/routes';
import { axiosInstance } from '@addsome/redux-store'
import cloneDeep from 'lodash.clonedeep';

const ObjectRender = ({ productComponents, reference, stopLoader, files, onTextureLoad, nodes, groupName }) => {
  const { progress } = useProgress()

  // if groupName is set, it means there are no variations and we need to use the glb as it is (textures included)
  if (!groupName) {
    var [normalMap0, diffuseMap0, roughnessMap0, alphaMap0, normalMap1, diffuseMap1, roughnessMap1, alphaMap1, normalMap2, diffuseMap2, roughnessMap2, alphaMap2,
      normalMap3, diffuseMap3, roughnessMap3, alphaMap3, normalMap4, diffuseMap4, roughnessMap4, alphaMap4, normalMap5, diffuseMap5, roughnessMap5, alphaMap5] = useLoader(TextureLoader, files);
    normalMap0.wrapS = THREE.RepeatWrapping;
    normalMap0.wrapT = THREE.RepeatWrapping;
    diffuseMap0.wrapS = THREE.RepeatWrapping;
    diffuseMap0.wrapT = THREE.RepeatWrapping;
    roughnessMap0.wrapS = THREE.RepeatWrapping;
    roughnessMap0.wrapT = THREE.RepeatWrapping;
    alphaMap0.wrapT = THREE.RepeatWrapping;
    alphaMap0.wrapS = THREE.RepeatWrapping;

    productComponents[0].normalMap = normalMap0;
    productComponents[0].map = diffuseMap0
    productComponents[0].diffuseMap = diffuseMap0;
    productComponents[0].roughnessMap = roughnessMap0;
    productComponents[0].alphaMap = alphaMap0;

    if (1 in productComponents && normalMap1) {
      normalMap1.wrapS = THREE.RepeatWrapping;
      normalMap1.wrapT = THREE.RepeatWrapping;
      diffuseMap1.wrapS = THREE.RepeatWrapping;
      diffuseMap1.wrapT = THREE.RepeatWrapping;

      productComponents[1].normalMap = normalMap1;
      productComponents[1].map = diffuseMap1
      productComponents[1].diffuseMap = diffuseMap1;
    }

    if (1 in productComponents && roughnessMap1) {
      roughnessMap1.wrapS = THREE.RepeatWrapping;
      roughnessMap1.wrapT = THREE.RepeatWrapping;
      productComponents[1].roughnessMap = roughnessMap1;
    }

    if (1 in productComponents && alphaMap1) {
      alphaMap1.wrapS = THREE.RepeatWrapping;
      alphaMap1.wrapT = THREE.RepeatWrapping;
      productComponents[1].alphaMap = alphaMap1;
    }

    if (2 in productComponents && normalMap2) {
      normalMap2.wrapS = THREE.RepeatWrapping;
      normalMap2.wrapT = THREE.RepeatWrapping;
      diffuseMap2.wrapS = THREE.RepeatWrapping;
      diffuseMap2.wrapT = THREE.RepeatWrapping;

      productComponents[2].normalMap = normalMap2;
      productComponents[2].map = diffuseMap2
      productComponents[2].diffuseMap = diffuseMap2;
    }

    if (2 in productComponents && roughnessMap2) {
      roughnessMap2.wrapS = THREE.RepeatWrapping;
      roughnessMap2.wrapT = THREE.RepeatWrapping;
      productComponents[2].roughnessMap = roughnessMap2;
    }

    if (2 in productComponents && alphaMap2) {
      alphaMap2.wrapS = THREE.RepeatWrapping;
      alphaMap2.wrapT = THREE.RepeatWrapping;
      productComponents[2].alphaMap = alphaMap2;
    }

    if (3 in productComponents && normalMap3) {
      normalMap3.wrapS = THREE.RepeatWrapping;
      normalMap3.wrapT = THREE.RepeatWrapping;
      diffuseMap3.wrapS = THREE.RepeatWrapping;
      diffuseMap3.wrapT = THREE.RepeatWrapping;

      productComponents[3].normalMap = normalMap3;
      productComponents[3].map = diffuseMap3
      productComponents[3].diffuseMap = diffuseMap3;
    }

    if (3 in productComponents && roughnessMap3) {
      roughnessMap3.wrapS = THREE.RepeatWrapping;
      roughnessMap3.wrapT = THREE.RepeatWrapping;
      productComponents[3].roughnessMap = roughnessMap3;
    }

    if (3 in productComponents && alphaMap3) {
      alphaMap3.wrapS = THREE.RepeatWrapping;
      alphaMap3.wrapT = THREE.RepeatWrapping;
      productComponents[3].alphaMap = alphaMap3;
    }

    if (4 in productComponents && normalMap4) {
      normalMap4.wrapS = THREE.RepeatWrapping;
      normalMap4.wrapT = THREE.RepeatWrapping;
      diffuseMap4.wrapS = THREE.RepeatWrapping;
      diffuseMap4.wrapT = THREE.RepeatWrapping;

      productComponents[4].normalMap = normalMap4;
      productComponents[4].map = diffuseMap4;
      productComponents[4].diffuseMap = diffuseMap4;
    }

    if (4 in productComponents && roughnessMap4) {
      roughnessMap4.wrapS = THREE.RepeatWrapping;
      roughnessMap4.wrapT = THREE.RepeatWrapping;
      productComponents[4].roughnessMap = roughnessMap4;
    }

    if (4 in productComponents && normalMap4) {
      alphaMap4.wrapS = THREE.RepeatWrapping;
      alphaMap4.wrapT = THREE.RepeatWrapping;
      productComponents[4].alphaMap = alphaMap4;
    }

    if (5 in productComponents && normalMap5) {
      normalMap5.wrapS = THREE.RepeatWrapping;
      normalMap5.wrapT = THREE.RepeatWrapping;
      diffuseMap5.wrapS = THREE.RepeatWrapping;
      diffuseMap5.wrapT = THREE.RepeatWrapping;

      productComponents[5].normalMap = normalMap5;
      productComponents[5].map = diffuseMap5;
      productComponents[5].diffuseMap = diffuseMap5;
    }

    if (5 in productComponents && roughnessMap5) {
      roughnessMap5.wrapS = THREE.RepeatWrapping;
      roughnessMap5.wrapT = THREE.RepeatWrapping;
      productComponents[5].roughnessMap = roughnessMap5;
    }

    if (5 in productComponents && alphaMap5) {
      alphaMap5.wrapS = THREE.RepeatWrapping;
      alphaMap5.wrapT = THREE.RepeatWrapping;
      productComponents[5].alphaMap = alphaMap5;
    }
  }

  useEffect(() => {
    if (progress === 100) {
      stopLoader();
    }
  }, [progress])

  useEffect(() => {
    return () => {
      if (!groupName) {
        if (normalMap0) {
          normalMap0.dispose();
          diffuseMap0.dispose();
          normalMap0 = null;
          diffuseMap0 = null;
        }

        if (roughnessMap0) {
          roughnessMap0.dispose();
          roughnessMap0 = null;
        }

        if (alphaMap0) {
          alphaMap0.dispose();
          alphaMap0 = null;
        }

        if (normalMap1) {
          normalMap1.dispose();
          diffuseMap1.dispose();
          normalMap1 = null;
          diffuseMap1 = null;
        }

        if (roughnessMap1) {
          roughnessMap1.dispose();
          roughnessMap1 = null;
        }

        if (alphaMap1) {
          alphaMap1.dispose();
          alphaMap1 = null;
        }

        if (normalMap2) {
          normalMap2.dispose();
          diffuseMap2.dispose();
          normalMap2 = null;
        }

        if (roughnessMap2) {
          roughnessMap2.dispose();
          roughnessMap2 = null;
        }

        if (alphaMap2) {
          alphaMap2.dispose();
          alphaMap2 = null;
        }

        if (normalMap3) {
          normalMap3.dispose();
          diffuseMap3.dispose();
          normalMap3 = null;
          diffuseMap3 = null;
        }

        if (roughnessMap3) {
          roughnessMap3.dispose();
          roughnessMap3 = null;
        }

        if (alphaMap3) {
          alphaMap3.dispose();
          alphaMap3 = null;
        }

        if (normalMap4) {
          normalMap4.dispose();
          diffuseMap4.dispose();
          normalMap4 = null;
          diffuseMap4 = null;
        }

        if (roughnessMap4) {
          roughnessMap4.dispose();
          roughnessMap4 = null;
        }

        if (alphaMap4) {
          alphaMap4.dispose();
          alphaMap4 = null;
        }

        if (normalMap5) {
          normalMap5.dispose();
          diffuseMap5.dispose();
          normalMap5 = null;
          diffuseMap5 = null;
        }

        if (roughnessMap5) {
          roughnessMap5.dispose();
          roughnessMap5 = null;
        }

        if (alphaMap5) {
          alphaMap5.dispose();
          alphaMap5 = null;
        }
      }
    };
  }, [groupName]);

  useEffect(() => () => useAsset.clear(), [])

  useEffect(() => {
    onTextureLoad(productComponents)
  }, [productComponents])

  return (
    groupName ? (
      <group ref={reference} dispose={null}>
        <primitive object={nodes[groupName]} position={[0, -0.5, 0]} />
        <mesh position={[0, -0.5, 0]} rotation={[-Math.PI / 2, 0, 0]} receiveShadow castShadow dispose={null}>
          <planeBufferGeometry attach="geometry" args={[10, 10, 10]} />
          <meshStandardMaterial attach="material" color={"#FFFFFF"} roughness={1} metalness={0} />
        </mesh>
      </group>
    ) : (
      <group
        ref={reference}
        dispose={null}
      >
        {productComponents.map((component) => (
          <mesh
            visible
            key={component.key}
            geometry={component.geometry}
            material={component.material}
            material-color={component.color}
            castShadow
            receiveShadow
            position={[0, -0.5, 0]}
            dispose={null}
          >
            <meshStandardMaterial
              map={component.diffuseMap}
              normalMap={component.normalMap}
              normalScale={new THREE.Vector2(component.normalMapStrength, component.normalMapStrength)}
              roughnessMap={component.roughnessMap}
              alphaMap={component.alphaMap}
              roughness={component.roughness}
              opacity={component.opacity}
              metalness={component.metalness}
              emissiveIntensity={component.emissiveIntensity}
              transparent={component.transparent}
              color={component.color}
              side={THREE.DoubleSide}
            />
          </mesh>
        ))}
        <mesh position={[0, -0.5, 0]} rotation={[-Math.PI / 2, 0, 0]} receiveShadow castShadow dispose={null}>
          <planeBufferGeometry attach="geometry" args={[10, 10, 10]} />
          <meshStandardMaterial attach="material" color={"#FFFFFF"} roughness={1} metalness={0} />
        </mesh>
      </group>
    )
  );
};

const CanvasComponent = ({
  glbFile,
  objFile,
  objectProductVariations,
  variationIndex,
  isOnView,
  productId,
  download3dWithTexturesFormat,
  onResetAuth,
  onFinishDownloading
}) => {
  const secretKey = 'c6e45b8e7a1f65a76f8e9f6c4a5e3b6d';
  const { user, accountType } = useLoggedUser()
  const [productComponentsFinal, setProductComponentsFinal] = useState([]);
  const [filesFinal, setFilesFinal] = useState([]);
  const [loader, setLoader] = useState(true);
  const mediaURLEncrypted = glbFile && glbFile.media && glbFile.media.url;
  // if comes as url then we don't need to decrypt it
  const mediaURlDecrypted = mediaURLEncrypted.startsWith("https") ? mediaURLEncrypted : mediaURLEncrypted && crypto.AES.decrypt(mediaURLEncrypted, secretKey).toString(crypto.enc.Utf8);
  const { nodes, materials } = useGLTF(mediaURlDecrypted, "https://www.gstatic.com/draco/versioned/decoders/1.5.6/");
  const reference = useRef<{ position: { x: number, y: number, z: number; }, rotation: { x: number, y: number, z: number; }; }>();
  const [readyToRender, setReadyToRender] = useState(false);
  const [filesChecked, setFilesChecked] = useState(false);
  const noTextureFile = 'https://storage.googleapis.com/addsome-prod/textures/default/no_texture.jpg';
  let dbMaterials = objectProductVariations[variationIndex] && objectProductVariations[variationIndex].materials;
  const [finalTextures, setFinalTextures] = useState([])
  const [showQuotaPopup, setShowQuotaPopup] = useState(false)
  const [showExceededQuotaPopup, setShowExceededQuotaPopup] = useState(false)
  const [groupName, setGroupName] = useState('')
  const { push } = usePush()

  const createProductComponent = useCallback(
    (dbMaterials) => {
      let files: string[] = [];
      let productComponents: any[] = [];
      Object.keys(nodes).forEach(key => {
        const node = nodes[key];

        if (node.type === 'Mesh') {
          let color = "";
          let textureNormalMap = "";
          let textureDiffuseMap = "";
          let textureRoughnessMap = "";
          let textureAlphaMap = ""
          let type = "";
          let roughness = 1;
          let opacity = 1;
          let emissiveIntensity = 0;
          let metalness = 0;
          let transparent = false;
          let normalMapStrength = null;

          if (dbMaterials) {
            Object.keys(dbMaterials).forEach(key => {
              const dbMaterial = dbMaterials[key];

              if (dbMaterial.materialName === node.name) {
                color = dbMaterial._DiffuseColor;
                type = dbMaterial.presetGroup;

                if (dbMaterial._NormalMap !== null) {
                  textureNormalMap = dbMaterial._NormalMap;

                  if (textureNormalMap.includes('.png')) {
                    textureNormalMap = textureNormalMap.replace('.png', '');
                  }
                  if (textureNormalMap.includes('.jpg')) {
                    textureNormalMap = textureNormalMap.replace('.jpg', '');
                  }
                }

                if (dbMaterial._NormalMapStrength != '' && dbMaterial._NormalMapStrength != null) {
                  normalMapStrength = dbMaterial._NormalMapStrength;
                }

                if (dbMaterial._DiffuseMap !== null) {
                  textureDiffuseMap = dbMaterial._DiffuseMap;

                  if (textureDiffuseMap.includes('.png')) {
                    textureDiffuseMap = textureDiffuseMap.replace('.png', '');
                  }
                  if (textureDiffuseMap.includes('.jpg')) {
                    textureDiffuseMap = textureDiffuseMap.replace('.jpg', '');
                  }
                }

                if (dbMaterial._SmoothnessMap !== null) {
                  textureRoughnessMap = dbMaterial._SmoothnessMap;
                  if (textureRoughnessMap.includes('.png')) {
                    textureRoughnessMap = textureRoughnessMap.replace('.png', '');
                  }
                  if (textureRoughnessMap.includes('.jpg')) {
                    textureRoughnessMap = textureRoughnessMap.replace('.jpg', '');
                  }
                }

                if (dbMaterial._DetailNormalMap !== null) {
                  textureAlphaMap = dbMaterial._DetailNormalMap;

                  if (textureAlphaMap.includes('.png')) {
                    textureAlphaMap = textureAlphaMap.replace('.png', '');
                  }
                  if (textureNormalMap.includes('.jpg')) {
                    textureAlphaMap = textureAlphaMap.replace('.jpg', '');
                  }

                  if (dbMaterial._Opacity != '' && dbMaterial._Opacity != null) {
                    opacity = dbMaterial._Opacity;
                    if (opacity == 2) {
                      opacity = 1;
                      transparent = true;
                    }
                  }
                }

                if (dbMaterial._Smoothness != '' && dbMaterial._Smoothness != null) {
                  roughness = 1 - dbMaterial._Smoothness;
                }

                if (dbMaterial._Opacity != '' && dbMaterial._Opacity != null) {
                  opacity = dbMaterial._Opacity;
                  if (opacity < 1) {
                    transparent = true;
                  }
                }

                if (dbMaterial._EmissionStrength != '' && dbMaterial._EmissionStrength != null) {
                  emissiveIntensity = dbMaterial._EmissionStrength;
                }

                if (dbMaterial._Metalness != '' && dbMaterial._Metalness != null) {
                  metalness = dbMaterial._Metalness;
                }

                if (type == 'Plastiques') {
                  type = 'plastic';
                }
                if (type == 'Cuirs') {
                  type = 'leather';
                }
                if (type == 'Bois') {
                  type = 'wood';
                }
              }
            });
          }

          productComponents.push({
            "color": color,
            "textureNormalMap": textureNormalMap,
            "textureDiffuseMap": textureDiffuseMap,
            "textureRoughnessMap": textureRoughnessMap,
            "normalMapStrength": normalMapStrength,
            "textureAlphaMap": textureAlphaMap,
            "roughness": roughness,
            "opacity": opacity,
            "metalness": metalness,
            "emissiveIntensity": emissiveIntensity,
            "transparent": transparent,
            "type": type,
            "material": node.material,
            "geometry": node.geometry,
            "key": node.uuid
          });
        }
      });

      for (const productComponent of productComponents) {
        if (productComponent.textureNormalMap == "") {
          files.push(noTextureFile);
        }
        if (productComponent.textureNormalMap != "") {
          files.push('https://storage.googleapis.com/addsome-prod/textures/' + productComponent.type + '/' + productComponent.textureNormalMap + '.png');
        }
        if (productComponent.textureDiffuseMap == "") {
          files.push(noTextureFile);
        }
        if (productComponent.textureDiffuseMap != "") {
          files.push('https://storage.googleapis.com/addsome-prod/textures/' + productComponent.type + '/' + productComponent.textureDiffuseMap + '.png');
        }
        if (productComponent.textureRoughnessMap == "") {
          files.push(noTextureFile);
        }
        if (productComponent.textureRoughnessMap != "") {
          files.push('https://storage.googleapis.com/addsome-prod/textures/' + productComponent.type + '/' + productComponent.textureRoughnessMap + '.png');
        }
        if (productComponent.textureAlphaMap != "") {
          files.push('https://storage.googleapis.com/addsome-prod/textures/' + productComponent.type + '/' + productComponent.textureAlphaMap + '.png');
        }

        if (productComponent.textureAlphaMap == "") {
          files.push(noTextureFile);
        }
      }

      return [productComponents, files];
    }, [glbFile, variationIndex]);

  useEffect(() => {
    if (readyToRender) {
      const productComponentsInfo = createProductComponent(dbMaterials);
      const productComponentsFinalLocal: any[] = productComponentsInfo[0];
      let filesLocal: string[] = productComponentsInfo[1];
      setProductComponentsFinal(productComponentsFinalLocal);

      parseFiles(filesLocal, noTextureFile).then(response => {
        filesLocal = response;
        setFilesChecked(true);
        setFilesFinal(filesLocal);
      });
    }
  }, [createProductComponent, glbFile, variationIndex, readyToRender]);

  useEffect(() => {
    if (!dbMaterials) {
      const foundGroup = Object.entries(nodes).find(([key, value]) => value.type === 'Group');
      if (foundGroup) {
        setGroupName(foundGroup[0]);
      }
    } else {
      setGroupName('');
    }
  }, [dbMaterials, nodes]);

  useEffect(() => {
    if (isOnView === false) {
      setReadyToRender(false);
      setFilesChecked(false);
    } else {
      setTimeout(() => {
        setReadyToRender(true);
        setLoader(true)
      }, 300);
    }
  }, [isOnView]);

  useEffect(() => {
    return () => {
      Object.keys(nodes).forEach(key => {
        const node = nodes[key];
        if (node.type === 'Mesh') {
          node.geometry.dispose();
          if (node.material) {
            if (Array.isArray(node.material)) {
              node.material.forEach((material) => material.dispose());
            } else {
              node.material.dispose();
            }
          }
          node.removeFromParent()
        }
      })
      useLoader.clear(TextureLoader, mediaURlDecrypted)
      THREE.Cache.clear()
    }
  }, []);

  useEffect(() => {
    if (download3dWithTexturesFormat) {
      download3dVisualization(download3dWithTexturesFormat)
    }
  }, [download3dWithTexturesFormat])

  const getObjDimensions = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      const loader = new OBJLoader();
      let minXGeneral, maxXGeneral, minYGeneral, maxYGeneral, minZGeneral, maxZGeneral;

      loader.load(objFile.media.url, (object) => {
        for (let i = 0; i < object.children.length; i++) {
          const mesh = object.children[i];

          if (!mesh.geometry) {
            continue;
          }

          const vertices = mesh.geometry.attributes.position.array
          let minX = Infinity, minY = Infinity, minZ = Infinity;
          let maxX = -Infinity, maxY = -Infinity, maxZ = -Infinity;

          for (let j = 0; j < vertices.length; j += 3) {
            const x = vertices[j];
            const y = vertices[j + 1];
            const z = vertices[j + 2];

            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            minZ = Math.min(minZ, z);

            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
            maxZ = Math.max(maxZ, z);
          }

          if (i === 0) {
            minXGeneral = minX;
            maxXGeneral = maxX;
            minYGeneral = minY;
            maxYGeneral = maxY;
            minZGeneral = minZ;
            maxZGeneral = maxZ;
          } else {
            minXGeneral = Math.min(minXGeneral, minX)
            maxXGeneral = Math.max(maxXGeneral, maxX)
            minYGeneral = Math.min(minYGeneral, minY)
            maxYGeneral = Math.max(maxYGeneral, maxY)
            minZGeneral = Math.min(minZGeneral, minZ)
            maxZGeneral = Math.max(maxZGeneral, maxZ)
          }
        }

        if (typeof maxXGeneral === 'undefined' || typeof minXGeneral === 'undefined') {
          resolve(0);
        }

        resolve(maxXGeneral - minXGeneral);
      }, undefined, (error) => {
        console.error('An error occurred while loading the OBJ file:', error);
        reject(error);
      });
    });
  }

  const getGlbDimensions = (children): number => {
    let minXGeneral, maxXGeneral, minYGeneral, maxYGeneral, minZGeneral, maxZGeneral;

    for (let i = 0; i < children.length; i++) {
      const mesh = children[i];

      if (!mesh.geometry) {
        continue;
      }

      const vertices = mesh.geometry.attributes.position.array
      let minX = Infinity, minY = Infinity, minZ = Infinity;
      let maxX = -Infinity, maxY = -Infinity, maxZ = -Infinity;

      for (let j = 0; j < vertices.length; j += 3) {
        const x = vertices[j];
        const y = vertices[j + 1];
        const z = vertices[j + 2];

        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        minZ = Math.min(minZ, z);

        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
        maxZ = Math.max(maxZ, z);
      }

      if (i === 0) {
        minXGeneral = minX;
        maxXGeneral = maxX;
        minYGeneral = minY;
        maxYGeneral = maxY;
        minZGeneral = minZ;
        maxZGeneral = maxZ;
      } else {
        minXGeneral = Math.min(minXGeneral, minX)
        maxXGeneral = Math.max(maxXGeneral, maxX)
        minYGeneral = Math.min(minYGeneral, minY)
        maxYGeneral = Math.max(maxYGeneral, maxY)
        minZGeneral = Math.min(minZGeneral, minZ)
        maxZGeneral = Math.max(maxZGeneral, maxZ)
      }
    }

    if (!maxXGeneral || !minXGeneral) {
      return 0;
    }

    return (maxXGeneral - minXGeneral);
  }

  const download3dVisualization = async (type: string) => {
    try {
      let scale = 1;
      const nodesReplica = cloneDeep(nodes);
      nodesReplica.Scene.material = finalTextures;

      if (nodesReplica.Scene) {
        let children = nodesReplica.Scene.children;

        if (children[0] && children[0].children && children[0].type == 'Object3D') {
          children = children[0].children;
        }

        if (objFile) {
          const xObj = await getObjDimensions();
          const xGlb = getGlbDimensions(children);

          if (xObj !== 0 && xGlb !== 0 && !isNaN(xObj) && !isNaN(xGlb)) {
            scale = xObj / xGlb;
          }
        }

        for (let i = 0; i < children.length; i++) {

          //set material to null and recreate with new to avoid the situation where materials has same name and id
          children[i].material = null
          children[i].material = new THREE.MeshStandardMaterial()

          children[i].material.name = finalTextures[i].type;

          children[i].material.map = finalTextures[i].map;

          children[i].geometry = finalTextures[i].geometry

          children[i].material.normalMap = finalTextures[i].normalMap

          children[i].material.roughnessMap = finalTextures[i].roughnessMap

          children[i].material.alphaMap - finalTextures[i].alphaMap

          children[i].material.normalScale = new THREE.Vector2(finalTextures[i].normalMapStrength, finalTextures[i].normalMapStrength)

          children[i].material.side = THREE.DoubleSide

          children[i].material.metalness = finalTextures[i].metalness

          children[i].material.opacity = finalTextures[i].opacity

          children[i].material.roughness = finalTextures[i].roughness

          children[i].material.emissiveIntensity = finalTextures[i].emissiveIntensity

          children[i].material.transparent = finalTextures[i].transparent

          children[i].castShadow = true

          children[i].receiveShadow = true

          children[i].color = finalTextures[i].color
          children[i].material.color = new THREE.Color(finalTextures[i].color)

          children[i].scale.set(scale, scale, scale)

        }
        const exporter = new GLTFExporter();

        // Parse the input and generate the glTF output
        exporter.parse(
          nodesReplica.Scene,
          async function (gltf) {

            if (gltf instanceof ArrayBuffer) {
              if (['fbx', 'obj', 'dae'].includes(type)) {
                const formData = new FormData();
                const blob = new Blob([gltf], { type: 'model/gltf-binary' }); // Assuming it's a binary glTF
                formData.append('file', blob, glbFile.name);
                formData.append('extension', type);

                let convertedBlobResponse;
                try {
                  convertedBlobResponse = await axiosInstance.post(`${process.env.REACT_APP_PYTHON_SCRIPTS_URL}/convertFile`, formData, {
                    headers: {
                      'Authorization': `Bearer ${process.env.REACT_APP_PYTHON_SCRIPTS_TOKEN}`,
                      'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                  });
                } catch (error) {
                  console.error('Error when converting file:', error);
                  onFinishDownloading();

                  return;
                }

                let downloadedFileFormat = 'zip';
                if (type == 'fbx') {
                  downloadedFileFormat = 'fbx';
                }

                const link = document.createElement('a');
                link.href = URL.createObjectURL(convertedBlobResponse.data);
                link.download = `${glbFile.name.replace('.glb', '')}.${downloadedFileFormat}`;
                link.click();
              } else {
                const link = document.createElement('a');

                link.href = URL.createObjectURL(new Blob([gltf], { type: 'application/octet-stream' }));
                link.download = `${glbFile.name.replace('.glb', '')}.glb`;
                link.click();
              }
            } else {
              const output = JSON.stringify(gltf, null, 2);

              const link = document.createElement('a');
              link.href = URL.createObjectURL(new Blob([output], { type: 'application/octet-stream' }));
              link.download = `${glbFile.name.replace('.glb', '')}.gltf`;
              link.click();

            }

            onFinishDownloading();
          },
          //binary-> export glb if ArrayBuffer
          {
            binary: true,
            maxTextureSize: 4096,
            includeCustomExtensions: true,
            trs: true,
          }
        );
      }
    } catch (e) {
      if (e.data) {
        const errorMessage = JSON.parse(await e.data.text()).message;

        if (errorMessage === ErrorsCode.Quota_Max_Exceeded) {
          setShowQuotaPopup(true)
          setShowExceededQuotaPopup(true)
        }

        if (
          errorMessage === ErrorsCode.Quota_Reached ||
          errorMessage === ErrorsCode.Quota_Max_Reached
        ) {
          setShowQuotaPopup(true)
        }
      }
    }
  }

  return (
    <>
      {loader &&
        <div className={`${styles.loaderGLB}`}>
          <Icon type="loading" />
          <p>
            <FormattedMessage id="viewer.loading" />
          </p>
        </div>
      }
      {readyToRender && filesChecked && productComponentsFinal && productComponentsFinal.length > 0 && Array.isArray(productComponentsFinal) && glbFile &&
        <>
          <Suspense fallback={null}>
            <Canvas style={loader ? { visibility: "hidden" } : { visibility: "inherit" }} id={"idCanvas"} adjustCamera={true} pixelRatio={[8]} camera={{ fov: 70, near: 0.1, far: 1000, position: [0, 0, 3] }} shadows={true} dpr={[1, 2]} contactShadow={true} shadowMap>
              <color attach="background" args={['#E5E5E4']} />
              <ambientLight intensity={0.3} />
              {/* <spotLight intensity={0.5} angle={1} penumbra={1} position={[10, 15, 10]} castShadow shadow-mapSize={[10240, 10240]} shadow-bias={-0.0001} /> */}
              <directionalLight
                intensity={0.5}
                castShadow
                shadow-bias={-0.0001}
                position={[10, 15, 10]}
                shadow-mapSize-height={512}
                shadow-mapSize-width={512}
              />
              <Environment files={"Sky.hdr"} />
              <ObjectRender
                productComponents={productComponentsFinal}
                reference={reference}
                stopLoader={() => { setLoader(false); }}
                files={filesFinal}
                onTextureLoad={(textures) => setFinalTextures(textures)}
                nodes={nodes}
                groupName={groupName}
              />
              <OrbitControls enableZoom={true} enablePan={false} minPolarAngle={0} maxPolarAngle={Math.PI / 2.25} maxDistance={5} minDistance={1} />
              <PerspectiveCamera makeDefault position={[0, 0, 2]} />
            </Canvas>
          </Suspense>
          <Modal
            visible={showQuotaPopup}
            footer={null}
            centered
            onCancel={() => setShowQuotaPopup(false)}
          >
            <Heading level={3} fill centerText>
              <FormattedMessage
                id="architectnotlinkedpopup.title"
                values={{
                  name: (
                    <span className={styles.name}>
                      {user ? `${user!.account.firstName} ${user!.account.lastName}` : ''}
                    </span>
                  )
                }}
              />
            </Heading>
            <div className={styles.alignCenter}>
              {showExceededQuotaPopup ?
                <FormattedMessage id="productpage.files.modal.exceeded.text"
                  values={{
                    p: (...chunks: string[]) => <p>{chunks}</p>
                  }}
                />
                : <FormattedMessage id="productpage.files.modal.text" />
              }
            </div>
            {!showExceededQuotaPopup &&
              <Button
                theme={Theme.PRIMARY}
                onClick={() => {
                  setShowQuotaPopup(false);
                  window.location.href = `${Routes.Architects}/${user!.id}/edit/subscription`;
                }}
                block>
                <FormattedMessage
                  id={
                    'productpage.files.modal.buttonQuotaReached'
                  }
                />
              </Button>
            }
          </Modal>
        </>
      }
      {/* {productComponentsFinal && productComponentsFinal.length > 0 && Array.isArray(productComponentsFinal) && glbFile &&
        <Canvas id={"idCanvas"} adjustCamera={true} pixelRatio={[8]} camera={{ fov: 70, near: 0.1, far: 1000, position: [0, 0, 3] }} shadows={true} dpr={[1, 2]} contactShadow={true} shadowMap>
          <color attach="background" args={['#EBEBEA']} />
          <ambientLight intensity={1} />
          <spotLight intensity={0.5} angle={1} penumbra={1} position={[10, 15, 10]} castShadow shadow-mapSize={[10240, 10240]} />
          <Suspense fallback={<Loader />}>
            <ObjectRender
              productComponents={productComponentsFinal}
              reference={reference}
              stopLoader={() => { setLoader(false); }}
            />
          </Suspense>
          <OrbitControls enableZoom={true} enablePan={false} minPolarAngle={0} maxPolarAngle={Math.PI / 2.25} maxDistance={10} minDistance={1.5} />
          <PerspectiveCamera makeDefault position={[0, 0, 2]} />
        </Canvas>
      } */}
    </>
  );
};

const parseFiles = async (files, noTextureFile) => {
  for (let i = 0; i < files.length; i++) {
    await fetch(files[i]).then((response) => {
      if (!response.ok) {
        files[i] = noTextureFile;
      }
    });
  }

  return files;
}

export default function ProductRender3d({
  glbFile,
  objFile,
  objectProductVariations,
  variationIndex,
  isOnView,
  productId,
  download3dWithTexturesFormat,
  onResetAuth,
  onFinishDownloading
}) {
  return (
    <div className={styles.canvasStyle} id={"canvasId"}>
      <Suspense fallback={<Loader />}>
        {glbFile && glbFile.media.url &&
          <CanvasComponent
            glbFile={glbFile}
            objFile={objFile}
            isOnView={isOnView}
            objectProductVariations={objectProductVariations}
            variationIndex={variationIndex}
            productId={productId}
            download3dWithTexturesFormat={download3dWithTexturesFormat}
            onResetAuth={() => onResetAuth()}
            onFinishDownloading={onFinishDownloading}
          />
        }
      </Suspense>
    </div>
  );
}