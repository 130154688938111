import { IDiagramData } from '@addsome/ui-kit'
import React from 'react'
import MetricCard from '../MetricCard/MetricCard'
import styles from './PageCards.module.scss'

export interface IRecentItem {
  img?: string
  label: string
  link: string,
  id?: string
}

export interface IGlobalMetrics {
  entityName: string
  nbEntities: number
  diagramDatas: IDiagramData[]
  recents: IRecentItem[]
  placeholder?: string
  link?: string
  bigButton?: {
    link: string
    label: string
  },
  onThumbnailClick?: (id) => void
}

interface IProps {
  metricsArray: IGlobalMetrics[],
  onThumbnailClick?: (id) => void
}

const PageCards: React.FC<IProps> = ({ metricsArray, onThumbnailClick }) => (
  <div className={styles.container}>
    {metricsArray.map(metrics => (
      <MetricCard
        key={metrics.entityName}
        entityName={metrics.entityName}
        nbEntities={metrics.nbEntities}
        diagramDatas={metrics.diagramDatas}
        recents={metrics.recents}
        link={metrics.link}
        placeholder={metrics.placeholder}
        bigButton={metrics.bigButton}
        onThumbnailClick={metrics.onThumbnailClick}
      />
    ))}
  </div>
)

export default PageCards
