"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_COLLECTIONS"] = "SET_COLLECTIONS";
  ActionsTypes["UPDATE_COLLECTION"] = "UPDATE_COLLECTION";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));