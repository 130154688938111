import { ObjectProductVariationCreationDTO, ProductDTO, ColorTagDTO, ObjectProductVariationMaterialCreationDTO, ObjectProductVariationUpdateDTO, ObjectProductVariationMaterialDTO, ObjectProductVariationMaterialUpdateDTO, ObjectProductVariationDTO, ObjectProductData3DUpdateDTO, ProductLightExtendedDTO } from '@addsome/dtos';
import { objectProductVariations, colorTag, objectProductVariationMaterials, objectProductData3D, product as productActions, productVariations, IPaginationRequest } from '@addsome/redux-store';
import { AutoComplete, Button, Heading, InputField, Option, Popconfirm, Select, Theme, Loading } from '@addsome/ui-kit';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from '../../../redux';
import styles from './ProductVariations.module.scss';
import presetVariations from './presetVariations';
import EditRowForMaterial from './EditRowForMaterial';
import AddVariation from './AddVariation';
import { IObjectProductVariationMaterialState } from '@addsome/redux-store/dist/store/object-product-variation-material';
import VariationsTableRow from './VariationTableRow';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import AddMaterialsForProduct from './AddMaterialsForProduct';
import MaterialTableRow from './MaterialTableRow';
import { SelectValue } from 'antd/lib/select';
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";


type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & WrappedComponentProps & {
    product: ProductDTO;
    readonly?: boolean;
  };


const optionsForCloseFunction = {
  "addVariation": "addVariation",
  "editVariation": "editVariation",
  "addMaterial": "addMaterial",
  "editMaterial": "editMaterial",

};
interface IState {
  displayEditSectionForVariantId: string;
  displayEditSectionForMaterialId: string;
  displayAddVariationForm: boolean;
  displayAddEditVariationError: boolean;
  displayLoaderClone: boolean;
  displayEditVariationId: string;
  variationName: string;
  selectedColor: string;
  productForCloneVariationId: string;
  formValuesMaterial: any[];
  formValues: { materialName: string, presetVariationId: string, presetVariationsValue: string, materialColor: string, colorTag: { id: string, name: string; }; };
  materialsByVariant: ObjectProductVariationMaterialDTO[] | IObjectProductVariationMaterialState | null;
  displayAddMaterialForm: boolean;
  displayAddEditMaterialError: boolean;
  disableInput: boolean;
  displayCloneInput: boolean;
  productKey: string;
  materialLabelName: string;
  addLabelError: boolean;
  materialsForProduct: string[];
  colorTagsLabels: string[];
  colorTagsIds: string[];
  productForDuplicate: ProductLightExtendedDTO | undefined;
  allVariations: ObjectProductVariationDTO[];
  allProductsForCloneInput: ProductLightExtendedDTO[];
  dragElementIdx: number;
  toDragElementIdx: number;
  dragElement: boolean;
  autocompleteValue: string;
  newMaterials: any[];
}
const sortList = (list) => {
  list.sort((a, b) => {

    if (a.materialName > b.materialName) {
      return 1;
    }
    if (a.materialName < b.materialName) {
      return -1;
    }
    return 0;
  });
  return (list);
};

const sortVariations = (list) => {
  list.sort((a, b) => {
    if (a.listIndex === null) {
      return 1;
    }

    if (b.listIndex === null) {
      return -1;
    }

    if (a.listIndex === b.listIndex) {
      return 0;
    }

    return a.listIndex < b.listIndex ? -1 : 1;
  });
  return (list);
};
class ProductVariations extends React.Component<IProps, IState> {
  public state: IState = {
    displayEditSectionForVariantId: "",
    displayAddVariationForm: false,
    displayAddEditVariationError: false,
    displayCloneInput: false,
    displayLoaderClone: false,
    disableInput: false,
    displayEditVariationId: "",
    displayEditSectionForMaterialId: "",
    selectedColor: "",
    variationName: "",
    productForCloneVariationId: "",
    materialsByVariant: null,
    formValuesMaterial: [],
    formValues: {
      materialName: "",
      presetVariationId: "",
      presetVariationsValue: "",
      materialColor: "",
      colorTag: { id: "", name: "" }
    },
    displayAddMaterialForm: false,
    displayAddEditMaterialError: false,
    materialLabelName: "",
    productKey: "",
    addLabelError: false,
    materialsForProduct: [],
    colorTagsLabels: [],
    colorTagsIds: [],
    allVariations: [],
    productForDuplicate: undefined,
    allProductsForCloneInput: [],
    dragElementIdx: 0,
    toDragElementIdx: 1,
    dragElement: false,
    autocompleteValue: '',
    newMaterials: []
  };
  timer!: ReturnType<typeof setTimeout> | null;

  public async componentDidMount() {
    if (this.props.product && this.props.product.objectProduct && this.props.product.objectProduct.data3D) {
      const objectLocal = this.props.product.objectProduct.data3D.materialMapping || {};
      const key = Object.keys(objectLocal);
      if (key.length > 0) {
        const localKey = key[0];
        const copyList = [...objectLocal[localKey]];
        const sortedList = copyList.sort();
        this.setState({ productKey: key[0], materialsForProduct: sortedList });
      }
    }

    if (this.props.product && this.props.product.objectProduct) {
      const variations = await this.props.fetchObjectProductVariations(this.props.product.objectProduct.id);
      this.setState({ allVariations: sortVariations(variations) });

      const file = this.props.product.sourceFiles.find(f => f.extension === 'glb')
      if (file) {
        useGLTF(file.media.url, "https://www.gstatic.com/draco/versioned/decoders/1.5.6/")
      }
    }
  }
  getLabel = (elem) => {
    if (elem.details)
      return elem.name + " | " + elem.details;
    return elem.name;

  };

  public render() {

    const updateMaterials = async (variation) => {
      for (const [i, m] of variation.materials.entries()) {
        const presetObject = presetVariations.find((elem) => elem.id === this.state.formValuesMaterial[m.id].presetVariationId.toString());
        const objectToBeSent: ObjectProductVariationMaterialUpdateDTO = {
          ...presetObject,
          materialName: this.state.formValuesMaterial[m.id].materialName,
          variationId: variation.id,
          _DiffuseColor: this.state.formValuesMaterial[m.id].materialColor ? this.state.formValuesMaterial[m.id].materialColor : "#000000",
          _DiffuseHueOffset: null,
          _CoordinatesOffset: null,
          _CoordinatesRotation: null,
          _DetailNormalMapStrength: null,
          _DiffuseMultValue: null,
          _FresnelStrength: null,
          _DiffuseSaturation: null,
          _EmissionSaturation: null,
          _DiffuseTintOffset: null,
          _DiffuseTemperatureOffset: null,
          _InvertDiffuse: null,
          _SmoothnessContrast: null,
          _SpecularContrast: null,
          _SpecularSaturation: null,
          _SpecularMapStrength: null,
          _SpecularStrength: null,
          _Tiling: null,
          _TilingCoef: null,
          _TriPlanarBlend: null,
          _TriPlanarTiling: null,
          _UseTriPlanar: null,
          _UseRGBMasks: null,
          _DiffuseMapStrength: null,
          _DiffuseAddValue: null,
          _DiffuseContrast: null,
          _DetailCoordinatesOffset: null,
          _DetailTilingCoef: { "x": 0, "z": 0, "y": 0 },
        };
        await this.props.updateObjectProductVariationMaterial(m.id, objectToBeSent,
          this.props.intl.formatMessage({ id: 'productpage.dataUpdated' }), this.props.intl.formatMessage({ id: 'productpage.errorEditing' }));

        if (i == variation.materials.length - 1 && this.props.product && this.props.product.objectProduct) {
          const variations = await this.props.fetchObjectProductVariations(this.props.product.objectProduct.id);
          this.setState({
            allVariations: sortVariations(variations),
            displayEditSectionForVariantId: '',
            displayEditVariationId: "",
            displayEditSectionForMaterialId: "",
            displayAddMaterialForm: false,
            variationName: "",
            newMaterials: [],
            colorTagsIds: [],
            colorTagsLabels: []
          });
        }
      }
    };

    const addMaterials = async (variation) => {
      if (this.state.newMaterials.length) {
        this.state.newMaterials.forEach(async (newMaterial, idx) => {
          const presetObject = presetVariations.find((elem) => elem.id === newMaterial.presetVariationId.toString());
          const objectToBeSent: ObjectProductVariationMaterialCreationDTO = {
            ...presetObject,
            variationId: this.state.displayEditSectionForVariantId,
            _DiffuseColor: newMaterial.materialColor ? newMaterial.materialColor : "#000000",
            _DiffuseHueOffset: null,
            _CoordinatesOffset: null,
            _CoordinatesRotation: null,
            _DetailNormalMapStrength: null,
            _DiffuseMultValue: null,
            _FresnelStrength: null,
            _DiffuseSaturation: null,
            _EmissionSaturation: null,
            _DiffuseTintOffset: null,
            _DiffuseTemperatureOffset: null,
            _InvertDiffuse: null,
            _SmoothnessContrast: null,
            _SpecularContrast: null,
            _SpecularSaturation: null,
            _SpecularMapStrength: null,
            _SpecularStrength: null,
            _Tiling: null,
            _TilingCoef: null,
            _TriPlanarBlend: null,
            _TriPlanarTiling: null,
            _UseTriPlanar: null,
            _UseRGBMasks: null,
            _DiffuseMapStrength: null,
            _DiffuseAddValue: null,
            _DiffuseContrast: null,
            _DetailCoordinatesOffset: null,
            _DetailTilingCoef: { "x": 0, "z": 0, "y": 0 },
            materialName: newMaterial.materialName ? newMaterial.materialName : this.state.materialsForProduct[0]
          };
          await this.props.createObjectProductVariationMaterial(objectToBeSent, this.props.intl.formatMessage({ id: 'productpage.valueAdded' }), this.props.intl.formatMessage({ id: 'productpage.errorCreating' }));
          if (idx == this.state.newMaterials.length - 1) {
            updateMaterials(variation)
            this.setState({
              newMaterials: []
            })
          }
        })
      } else {
        updateMaterials(variation)
      }
    };

    const addMaterial = async (materialName: string | null = null, variationId: string | null = null) => {
      const presetObject = presetVariations.find((elem) => elem.id === this.state.formValues.presetVariationId.toString());
      const objectToBeSent: ObjectProductVariationMaterialCreationDTO = {
        ...presetObject,
        variationId: variationId ? variationId : this.state.displayEditSectionForVariantId,
        _DiffuseColor: this.state.formValues.materialColor ? this.state.formValues.materialColor : "#000000",
        _DiffuseHueOffset: null,
        _CoordinatesOffset: null,
        _CoordinatesRotation: null,
        _DetailNormalMapStrength: null,
        _DiffuseMultValue: null,
        _FresnelStrength: null,
        _DiffuseSaturation: null,
        _EmissionSaturation: null,
        _DiffuseTintOffset: null,
        _DiffuseTemperatureOffset: null,
        _InvertDiffuse: null,
        _SmoothnessContrast: null,
        _SpecularContrast: null,
        _SpecularSaturation: null,
        _SpecularMapStrength: null,
        _SpecularStrength: null,
        _Tiling: null,
        _TilingCoef: null,
        _TriPlanarBlend: null,
        _TriPlanarTiling: null,
        _UseTriPlanar: null,
        _UseRGBMasks: null,
        _DiffuseMapStrength: null,
        _DiffuseAddValue: null,
        _DiffuseContrast: null,
        _DetailCoordinatesOffset: null,
        _DetailTilingCoef: { "x": 0, "z": 0, "y": 0 },
        materialName: materialName ? materialName : this.state.formValues.materialName ? this.state.formValues.materialName : this.state.materialsForProduct[0]
      };

      await this.props.createObjectProductVariationMaterial(objectToBeSent, this.props.intl.formatMessage({ id: 'productpage.valueAdded' }), this.props.intl.formatMessage({ id: 'productpage.errorCreating' }));
      closeOthers("");

      if (!materialName) {
        await this.props.fetchObjectProductVariationMaterial(this.state.displayEditSectionForVariantId);
        const listCopy = [...this.props.objectProductVariationMaterials];
        const sortedList = sortList(listCopy);
        this.setState({
          materialsByVariant: sortedList
        });
      }

    }

    const addEditVariation = async (tagsArray?: string[]) => {

      const objectToBeSent: ObjectProductVariationCreationDTO = {
        objectProductId: this.props.product.objectProduct && this.props.product.objectProduct.id || "",
        name: this.state.variationName,
        uiColors: ["#EEEEEE"],
        colorTagIds: tagsArray
      };

      if (this.state.displayEditVariationId !== "") {
        await this.props.updateObjectProductVariation(this.state.displayEditVariationId, objectToBeSent,
          this.props.intl.formatMessage({ id: 'productpage.dataUpdated' }), this.props.intl.formatMessage({ id: 'productpage.errorEditing' }));
      }
      else {
        const response = await this.props.createObjectProductVariation(objectToBeSent, this.props.intl.formatMessage({ id: 'productpage.valueAdded' }),
          this.props.intl.formatMessage({ id: 'productpage.errorCreating' }));
        const variationId = response.id;
        if (this.state.materialsForProduct && this.state.materialsForProduct.length > 0) {
          for (const materialLocal of this.state.materialsForProduct) {
            await addMaterial(materialLocal, variationId);
          }
        }

        await this.props.fetchObjectProductVariationMaterial(this.state.displayEditSectionForVariantId);
        const listCopy = [...this.props.objectProductVariationMaterials];
        const sortedList = sortList(listCopy);

        this.setState({
          materialsByVariant: sortedList,
          displayAddVariationForm: false,
          displayEditVariationId: "",
          colorTagsLabels: [],
          colorTagsIds: []
        });

        closeOthers("");

        if (this.props.product.objectProduct) {
          const variations = await this.props.fetchObjectProductVariations(this.props.product.objectProduct.id);
          this.setState({ allVariations: sortVariations(variations) });
        }
      }

    };
    const cloneVariation = async (variation) => {

      const newVariationName = variation.name + "_copy";
      const objectToBeSent: ObjectProductVariationCreationDTO = {
        objectProductId: this.props.product.objectProduct && this.props.product.objectProduct.id || "",
        name: newVariationName,
        uiColors: ["#EEEEEE"],
        colorTagIds: variation.colorTags.map(s => s.id)
      };
      const response = await this.props.createObjectProductVariation(objectToBeSent, this.props.intl.formatMessage({ id: 'productpage.valueAdded' }),
        this.props.intl.formatMessage({ id: 'productpage.errorCreating' }));
      const variationId = response.id;
      if (variation.materials && variation.materials.length > 0) {
        for (const materialLocal of variation.materials) {
          await this.props.createObjectProductVariationMaterial({ ...materialLocal, variationId: variationId }, this.props.intl.formatMessage({ id: 'productpage.valueAdded' }), this.props.intl.formatMessage({ id: 'productpage.errorCreating' }));
        }
      }
      if (this.props.product.objectProduct) {
        const variations = await this.props.fetchObjectProductVariations(this.props.product.objectProduct.id);

        await this.props.fetchObjectProductVariationMaterial(this.state.displayEditSectionForVariantId);
        const listCopy = [...this.props.objectProductVariationMaterials];
        const sortedList = sortList(listCopy);

        this.setState({
          allVariations: sortVariations(variations),
          materialsByVariant: sortedList
        });
      }
    };

    const addColorLabel = (label: string, id: string) => {
      const copyColors = [...this.state.colorTagsLabels];
      const copyIds = [...this.state.colorTagsIds];
      //verifications to prevend dublicate colors
      let isCopy = false;
      copyColors.map((copy) => {
        if (copy === label)
          isCopy = true;
      });
      if (!isCopy) {
        copyColors.push(label);
        copyIds.push(id);
        this.setState({ colorTagsLabels: copyColors });
        this.setState({ colorTagsIds: copyIds });
      }
    };

    const onCloseColorLabel = (index: number) => {
      const copyColors = [...this.state.colorTagsLabels];
      const copyIds = [...this.state.colorTagsIds];
      copyColors.splice(index, 1);
      copyIds.splice(index, 1);
      this.setState({ colorTagsLabels: copyColors });
      this.setState({ colorTagsIds: copyIds });
    };

    const getColorNamesForVariation = (colorTags: ColorTagDTO[]) => {
      const colorLabels: string[] = [];
      colorTags.map((colorTag) => {
        colorLabels.push(colorTag.name);
      });
      this.setState({ colorTagsLabels: colorLabels });
      return colorLabels;
    };

    const getColorIdsForVariation = (colorTags: ColorTagDTO[]) => {
      const colorIds: string[] = [];
      colorTags.map((colorTag) => {
        colorIds.push(colorTag.id);
      });
      this.setState({ colorTagsIds: colorIds });
      return colorIds;
    };

    const setMaterialsForVariationId = async (variationId: string) => {
      await this.props.fetchObjectProductVariationMaterial(variationId);
      const listCopy = [...this.props.objectProductVariationMaterials];
      const sortedList = sortList(listCopy);
      const list = [...this.state.formValuesMaterial]
      sortedList.forEach((material, i) => {
        list[material.id] = {
          materialName: material.materialName,
          presetVariationsValue: "",
          materialColor: material._DiffuseColor || "",
          colorTag: { id: "", name: "" },
          presetVariationId: findPresetVariations(material)
        }

        if (i == sortedList.length - 1) {
          this.setState({
            formValuesMaterial: list,
            displayEditSectionForVariantId: variationId,
            materialsByVariant: sortedList
          })
        }
      })
    };

    const deleteMaterial = async (materialId: string) => {
      await this.props.deleteObjectProductVariationMaterial(materialId, this.props.intl.formatMessage({ id: 'productpage.dataDeleted' }), this.props.intl.formatMessage({ id: 'productpage.errorDeleting' }));
      await this.props.fetchObjectProductVariationMaterial(this.state.displayEditSectionForVariantId);
      const listCopy = [...this.props.objectProductVariationMaterials];
      const sortedList = sortList(listCopy);

      this.setState({
        materialsByVariant: sortedList
      });
      if (this.props.product && this.props.product.objectProduct) {
        const variations = await this.props.fetchObjectProductVariations(this.props.product.objectProduct.id);
        this.setState({ allVariations: sortVariations(variations) });

      }
    };

    const deleteVariation = async (variationId: string) => {
      this.setState({ displayEditSectionForVariantId: "" });
      await this.props.deleteObjectProductVariation(variationId, this.props.intl.formatMessage({ id: 'productpage.dataDeleted' }), this.props.intl.formatMessage({ id: 'productpage.errorDeleting' }));
      if (this.props.product.objectProduct) {
        const variations = await this.props.fetchObjectProductVariations(this.props.product.objectProduct.id);
        this.setState({ allVariations: sortVariations(variations) });

      }
    };

    const closeOthers = (remainsOpen: string | null) => {
      if (remainsOpen === optionsForCloseFunction.addVariation)
        this.setState({
          displayEditVariationId: "",
          displayEditSectionForMaterialId: "",
          displayAddMaterialForm: false,
          variationName: "",
          colorTagsIds: [],
          colorTagsLabels: []

        });
      else if (remainsOpen === optionsForCloseFunction.editVariation)
        this.setState({
          displayEditSectionForMaterialId: "",
          displayAddMaterialForm: false,
          displayAddVariationForm: false,

        });
      else if (remainsOpen === optionsForCloseFunction.addMaterial)
        this.setState({
          displayEditVariationId: "",
          displayEditSectionForMaterialId: "",
          displayAddVariationForm: false,

        }); else if (remainsOpen === optionsForCloseFunction.editMaterial)
        this.setState({
          displayEditVariationId: "",
          displayAddVariationForm: false,
          displayAddMaterialForm: false,

        });
      else
        this.setState({
          displayEditVariationId: "",
          displayAddVariationForm: false,
          displayEditSectionForMaterialId: "",
          displayAddMaterialForm: false,

        });

    };

    const deleteLabel = (id: number) => {
      const copyList = [...this.state.materialsForProduct];

      copyList.splice(id, 1);
      this.setState({ materialsForProduct: copyList });
      updateLabelList(copyList);
    };


    const addLabel = () => {
      const copyList = [...this.state.materialsForProduct];
      copyList.push(this.state.materialLabelName);
      this.setState({ materialsForProduct: copyList });
      updateLabelList(copyList);
    };


    const updateLabelList = (copyList: string[]) => {
      let productKey;
      let materialsListForRequest: any[] = [];
      for (let material in copyList)
        if (parseInt(material) < 10)
          materialsListForRequest.push({ "legible_name": copyList[material], "technical_name": `Material_0${material}` });
        else
          materialsListForRequest.push({ "legible_name": copyList[material], "technical_name": `Material_${material}` });

      productKey = this.state.productKey;
      const materialMappingObject = { [productKey]: copyList };
      const objectData3d: ObjectProductData3DUpdateDTO = { materialMapping: materialMappingObject, materialList: materialsListForRequest, anchor: "" };
      if (this.props.product.objectProduct && this.props.product.objectProduct.data3D) {
        this.props.updateObjectProductData3D(this.props.product.objectProduct.data3D.id, objectData3d,
          this.props.intl.formatMessage({ id: 'productpage.dataUpdated' }), this.props.intl.formatMessage({ id: 'productpage.errorEditing' }));
      }
      if (this.state.materialLabelName !== "")
        this.setState({ addLabelError: false, materialLabelName: "" });
    };

    const generateMeshId = () => {
      let meshId = '';
      let characters = 'abcdef0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < 32; i++) {
        meshId += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      const materialMappingObject = { ['mesh1_' + meshId]: [] };
      const objectData3d: ObjectProductData3DUpdateDTO = { materialMapping: materialMappingObject, materialList: [], anchor: "" };
      if (this.props.product.objectProduct && this.props.product.objectProduct.data3D) {
        this.props.updateObjectProductData3D(this.props.product.objectProduct.data3D.id, objectData3d,
          this.props.intl.formatMessage({ id: 'global.succes' }), this.props.intl.formatMessage({ id: 'global.error' }));
        this.setState({ productKey: 'mesh1_' + meshId });
      }
    };

    const generateMaterialNodes = () => {
      const file = this.props.product.sourceFiles.find(f => f.extension === 'glb')
      if (file) {
        const { nodes, materials } = useGLTF(file.media.url, "https://www.gstatic.com/draco/versioned/decoders/1.5.6/")
        let copyList = []
        for (let node in nodes) {
          if (node != 'Scene') {
            copyList.push(node)
          }
        }
        this.setState({ materialsForProduct: copyList });
        updateLabelList(copyList);
      }
    }

    const cloneVariationsForProduct = async () => {
      const objectProductId = this.state.productForDuplicate ? this.state.productForDuplicate.objectProductId : "";
      if (!this.state.productForDuplicate) {
        this.setState({ allProductsForCloneInput: [], displayLoaderClone: false, disableInput: false, displayCloneInput: false, productForCloneVariationId: "", productForDuplicate: undefined });
        return;
      }
      const variations = await this.props.fetchObjectProductVariations(objectProductId || "");
      const newVariationsListCurrentProduct = [];
      let listOfMaterials = [];
      let setOnceMaterials = true;
      let variationId = 0;

      //clone Variation
      for (const [i, variation] of variations.entries()) {
        const currentProductObjectId = this.props.product.objectProduct && this.props.product.objectProduct.id || "";
        const variationCreateResponse = await this.props.createObjectProductVariation({ ...variation, objectProductId: currentProductObjectId }, this.props.intl.formatMessage({ id: 'productpage.valueAdded' }));
        variationId = variationCreateResponse.id;
        newVariationsListCurrentProduct.push(variationCreateResponse);
        if (variationId) {
          for (const material of variation.materials) {
            if (setOnceMaterials) {
              listOfMaterials.push(material.materialName);
            }
            await this.props.createObjectProductVariationMaterial({ ...material, variationId: variationId }, this.props.intl.formatMessage({ id: 'productpage.valueAdded' }), this.props.intl.formatMessage({ id: 'productpage.errorCreating' }));
          }
          setOnceMaterials = false;
        }

        await this.props.fetchObjectProductVariationMaterial(variationId.toString());
        const listMaterials = [...this.props.objectProductVariationMaterials];
        const listIndex = newVariationsListCurrentProduct.findIndex(x => x.id == variationId)
        newVariationsListCurrentProduct[listIndex].materials = listMaterials

        if (i == variations.length - 1) {
          setMaterialsForVariationId(variationId.toString())
        }
      };

      updateLabelList(listOfMaterials);
      this.setState({ materialsForProduct: listOfMaterials });
      const allVariationsLocal = [...this.state.allVariations].concat(newVariationsListCurrentProduct);
      this.setState({ allProductsForCloneInput: [], displayLoaderClone: false, disableInput: false, displayCloneInput: false, allVariations: sortVariations(allVariationsLocal), productForCloneVariationId: "", productForDuplicate: undefined, autocompleteValue: '' });

    };

    const inputChanged = async e => {
      clearTimeout(this.timer);
      this.setState({ displayLoaderClone: true, autocompleteValue: e });
      this.timer = setTimeout(async () => {
        if (e.length > 2) {
          await this.props.fetchProductVariations(e);
          if (this.props.productVariations.length > 0) {
            const index = this.props.productVariations.findIndex((elem) => elem.id === this.props.product.id);
            const cloneList = [...this.props.productVariations];
            if (index >= 0)
              cloneList.splice(index, 1);
            this.setState({ allProductsForCloneInput: cloneList, displayLoaderClone: false });
          }
          else {
            this.setState({ allProductsForCloneInput: [], displayLoaderClone: false, disableInput: false, productForCloneVariationId: "", productForDuplicate: undefined });
          }
        }
        if (e.length <= 2) {
          this.setState({ allProductsForCloneInput: [], displayLoaderClone: false, disableInput: false, productForCloneVariationId: "", productForDuplicate: undefined });
        }
      }, 1000);

    };

    const setDraggedElement = (finalIdx) => {
      this.setState({
        toDragElementIdx: finalIdx
      });
    };

    const startDragElement = (idx) => {
      this.setState({
        dragElementIdx: idx,
        dragElement: true
      });
    };

    const placeDraggedElement = () => {
      if (this.state.dragElementIdx == this.state.toDragElementIdx) {
        return;
      }
      const currentElement = this.state.allVariations[this.state.dragElementIdx];
      const newArr = [...this.state.allVariations].filter(x => x.id != currentElement.id);
      newArr.splice(this.state.toDragElementIdx, 0, currentElement);

      this.setState({
        allVariations: newArr
      });

      newArr.forEach(async (element, index) => {
        await this.props.updateObjectProductVariation(element.id, { listIndex: index });

        if (index == (newArr.length - 1)) {
          this.setState({
            dragElement: false
          });
        }
      });
    };

    const findPresetVariations = (material) => {
      const presetVariation = presetVariations.find((presetElem) => (presetElem.presetVariation === material.presetVariation && presetElem.presetName === material.presetName && presetElem.presetGroup === material.presetGroup));
      return presetVariation ? presetVariation.id : 0;
    }

    return (
      <div className={styles.productProblems} >
        <div className={styles.variationsThumbnail}>
          {this.props.product && this.props.product.thumbnail &&
            <img id="variationThumbnail" src={this.props.product.thumbnail.media.url || undefined}></img>
          }
        </div>
        {
          this.state.productKey ?
            <p style={{ marginLeft: "1vw" }}>
              <FormattedMessage id="productpage.variations.meshIdentifier" />: {this.state.productKey}
            </p> :
            <button onClick={() => generateMeshId()} style={{ marginLeft: "1vw" }}>
              {this.props.intl.formatMessage({ id: 'productpage.variations.generateId' })}
            </button>
        }
        {
          this.props.product.sourceFiles.find(f => f.extension === 'glb') &&
          <button onClick={() => generateMaterialNodes()} style={{ marginLeft: "1vw" }}>
            {this.props.intl.formatMessage({ id: 'productpage.variations.getNodesFromGlb' })}
          </button>
        }
        < hr className={`${styles.hrStyle}`} />
        < AddMaterialsForProduct
          deleteLabel={deleteLabel}
          addLabel={addLabel}
          changeMaterialLabelName={(labelName) => this.setState({ materialLabelName: labelName })}
          materials={this.state.materialsForProduct}
          materialLabelName={this.state.materialLabelName}
          addLabelError={this.state.addLabelError}
          setAddLabelError={() => {
            this.setState({ addLabelError: true });
          }}

        />
        < hr className={`${styles.hrStyle}`} />
        < div className={` ${styles.alignMaterialsInputAndText}`}>
          <Heading level={3} as="h2" className={`${styles.titleFormat}`}>
            <p className={styles.titleFormat}><FormattedMessage id="productspage.variations" /> </p>
          </Heading>
          <span className={styles.spaceButtons}>
            {this.state.displayLoaderClone &&
              <div className={styles.loader}></div>}
            <span>
              <AutoComplete
                onSelect={(value: SelectValue) => {
                  const entireProduct = this.state.allProductsForCloneInput.find(elem => elem.id === value.toString());
                  this.setState({ displayLoaderClone: false, productForCloneVariationId: value.toString(), productForDuplicate: entireProduct });
                }}
                onBlur={() => {
                  this.setState({ allProductsForCloneInput: [], displayLoaderClone: false, disableInput: false, displayCloneInput: false, productForCloneVariationId: "", productForDuplicate: undefined, autocompleteValue: '' });
                }}
                onChange={(e) => { inputChanged(e); }}
                autoFocus
                placeholder={this.props.intl.formatMessage({ id: 'productsvariations.searchProductToClone' })}
                fields={this.state.allProductsForCloneInput.map(c => ({ key: c.id, label: this.getLabel(c) }))}
                filterOption={(inputValue, option) => {
                  return (option.props.children as string)
                    .toLocaleLowerCase()
                    .includes(inputValue.toLocaleLowerCase());
                }}
                value={this.state.autocompleteValue}
                disabled={this.state.disableInput}
                style={{
                  height: "3.2rem",
                  visibility: this.state.displayCloneInput ? 'visible' : 'hidden'
                }}
              />
            </span>
            {this.state.displayCloneInput ?
              <Button
                theme={Theme.PRIMARY}
                title={this.props.intl.formatMessage({ id: 'global.save' })}
                shape="circle"
                aria-label="Save"
                icon="check"
                className={`${styles.rightSpaceAddButton}`}
                disabled={this.state.disableInput}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  this.setState({ displayLoaderClone: true, disableInput: true });
                  cloneVariationsForProduct();
                }
                }
              />
              :
              <Button
                theme={Theme.PRIMARY}
                title={this.props.intl.formatMessage({ id: 'productsvariations.cloneProduct' })}
                shape="circle"
                aria-label="Clone"
                icon="copy"
                className={`${styles.rightSpaceAddButton}`}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  this.setState({ displayCloneInput: true });
                }
                }
              />

            }

            <Button
              theme={Theme.PRIMARY}
              title={this.props.intl.formatMessage({ id: 'productsvariations.addVariation' })}
              shape="circle"
              aria-label="Add"
              icon="plus"
              className={`${styles.rightSpaceAddButton}`}
              onClick={() => {
                this.setState({ displayAddVariationForm: true });
                closeOthers(optionsForCloseFunction.addVariation);
              }
              }
            />
          </span>

        </div >
        {
          this.state.displayAddVariationForm &&

          <AddVariation
            changeVariationName={(variationName) => {
              this.setState({
                variationName: variationName
              });
            }}
            addEditVariation={(tagsArray?: string[]) => {
              addEditVariation(tagsArray);
            }}

            closeAddVariation={() => {
              this.setState({
                displayAddVariationForm: false,
                displayAddEditVariationError: false,
                displayEditVariationId: "",
                colorTagsIds: [],
                colorTagsLabels: []
              });
            }}
            setAddEditVariationError={() => {
              this.setState({
                displayAddEditVariationError: true
              });
            }}
            addColorLabel={(label: string, id: string) => {
              addColorLabel(label, id);
            }}
            onCloseColorLabel={(index: number) => {
              onCloseColorLabel(index);
            }}
            variationName={this.state.variationName}
            variationNameError={this.state.displayAddEditVariationError}
            colorTagsRequest={this.props.colorTags}
            colorTagsLabels={this.state.colorTagsLabels}
            colorTagsIds={this.state.colorTagsIds}
            displayAddVariation={this.state.displayAddVariationForm}
            addVariation
          />
        }

        < br />
        <div>
          <div>{this.state.dragElement}</div>
          {(this.props.objectProductVariationMaterialsLoading || this.props.objectProductVariationsLoading)
            && !this.state.dragElement ?
            (<Loading />) :
            (
              Array.isArray(this.state.allVariations) &&
              this.state.allVariations.map((variation, i) => (
                <div
                  key={i}
                  draggable={true}
                  onDragEnter={() => setDraggedElement(i)}
                  onDragStart={() => startDragElement(i)}
                  onDragEnd={placeDraggedElement}
                >
                  <VariationsTableRow
                    variation={variation}
                    displayEditSectionForVariantId={this.state.displayEditSectionForVariantId}
                    closeOthers={(keepOpen: string) => closeOthers(keepOpen)}
                    setMaterialsForVariationId={(variationId: string) =>
                      setMaterialsForVariationId(variationId)}
                    deleteVariation={(variationId: string) =>
                      deleteVariation(variationId)}
                    closeExpand={() => {
                      this.setState({ displayEditSectionForVariantId: "" });
                      closeOthers(optionsForCloseFunction.addVariation);
                    }}
                    readonly={this.props.readonly}
                    optionsForCloseFunction={optionsForCloseFunction}
                    editVariation={(variation: ObjectProductVariationDTO) => {
                      const colorTagsLabels = getColorNamesForVariation(variation.colorTags);
                      const colorTagsIds = getColorIdsForVariation(variation.colorTags);
                      //open materials list
                      setMaterialsForVariationId(variation.id)
                      this.setState({
                        displayEditVariationId: variation.id,
                        variationName: variation.name,
                        colorTagsLabels: colorTagsLabels,
                        colorTagsIds: colorTagsIds
                      });
                    }}
                    displayEditVariation={this.state.displayEditVariationId === variation.id}
                    changeVariationName={(variationName) => {
                      this.setState({
                        variationName: variationName,
                      });
                    }}
                    cloneVariation={(variationId: string) => {
                      cloneVariation(variationId);
                    }}

                    closeAddVariation={() => {
                      this.setState({
                        displayAddVariationForm: false,
                        displayEditVariationId: "",
                        colorTagsIds: [],
                        colorTagsLabels: []
                      });
                    }}

                    setAddEditVariationError={() => {
                      this.setState({
                        displayAddEditVariationError: true,
                        variationName: variation.name,
                      });
                    }}
                    addColorLabel={(label: string, id: string) => {
                      addColorLabel(label, id);
                    }}
                    onCloseColorLabel={(index: number) => {
                      onCloseColorLabel(index);
                    }}
                    variationName={this.state.variationName}
                    displayAddEditVariationError={this.state.displayAddEditVariationError}
                    colorTags={this.props.colorTags}
                    colorTagsLabels={this.state.colorTagsLabels}
                  />
                  <hr style={{ margin: "0" }} />
                  {this.state.displayEditSectionForVariantId === variation.id &&
                    <div>
                      <div className={`${styles.materialSection} ${styles.alignTable} ${styles.flexColumn}`}>
                        {Array.isArray(this.state.materialsByVariant) && Array(this.state.materialsByVariant).length > 0
                          ?
                          <div className={styles.fullWidth}>
                            {this.state.materialsByVariant.map((material, i) => (
                              <div key={i} className={styles.fullWidth}>
                                <MaterialTableRow
                                  material={material}
                                  materialsForProduct={this.state.materialsForProduct}
                                  variation={variation}
                                  editMaterial={() => {
                                    this.setState(prevState => ({
                                      displayEditSectionForMaterialId: material.id,
                                      displayEditVariationId: "",
                                      displayAddMaterialForm: false,
                                      formValues: {
                                        ...prevState.formValues,
                                        materialName: material.materialName,
                                        presetVariationsValue: "",
                                        materialColor: material._DiffuseColor || "",
                                        colorTag: { id: "", name: "" },
                                        presetVariationId: finalValue.toString()
                                      }
                                    }));
                                  }}
                                  deleteMaterial={(materialId) => { deleteMaterial(materialId); }}
                                  displayEdit={this.state.displayEditVariationId === variation.id}
                                  materialName={this.state.formValues.materialName}
                                  selectedColor={material.id ? this.state.formValuesMaterial[material.id].materialColor : ''}
                                  variationPresetNameAndGroupId={findPresetVariations(material)}
                                  changeName={(name) => {
                                    var stateCopy = Object.assign({}, this.state)
                                    stateCopy.formValuesMaterial[material.id].materialName = name
                                    this.setState(stateCopy)
                                  }}
                                  changePresetVariation={(presetVariationId) => {

                                    var stateCopy = Object.assign({}, this.state)
                                    stateCopy.formValuesMaterial[material.id].presetVariationId = presetVariationId
                                    this.setState(stateCopy)
                                  }
                                  }
                                  changeColorHexa={(materialColorHexa) => {
                                    var stateCopy = Object.assign({}, this.state)
                                    stateCopy.formValuesMaterial[material.id].materialColor = materialColorHexa
                                    this.setState(stateCopy)
                                  }}
                                  closeEditAddMaterialForm={() => {
                                    this.setState({
                                      displayEditSectionForMaterialId: "",
                                      displayAddMaterialForm: false,
                                    });
                                  }}
                                  setAddEditMaterialError={() => {
                                    this.setState({
                                      displayAddEditMaterialError: true,
                                    });
                                  }}
                                  materialNameError={this.state.displayAddEditMaterialError}
                                />
                                <hr style={{ margin: "0" }} />
                              </div>
                            ))}


                          </div>
                          :
                          <p>No materials available</p>
                        }
                        {this.state.displayAddMaterialForm && this.state.newMaterials.map((m, i) => (
                          <div className={`${styles.materialSection} ${styles.alignTable} `} key={i}>
                            <EditRowForMaterial material={null} variation={variation}
                              draggable={true}
                              onDragStart={(event) => { event.preventDefault(); event.stopPropagation(); }}
                              selectedColor={this.state.newMaterials[i].materialColor}
                              materialName={this.state.newMaterials[i].materialName}
                              addMaterial
                              materialsForProduct={this.state.materialsForProduct}
                              variationPresetNameAndGroupId={this.state.newMaterials[i].presetVariationId}
                              changeName={(name) => {
                                var stateCopy = Object.assign({}, this.state)
                                stateCopy.newMaterials[i].materialName = name
                                this.setState(stateCopy)
                              }}
                              changePresetVariation={(presetVariationId) => {
                                var stateCopy = Object.assign({}, this.state)
                                stateCopy.newMaterials[i].presetVariationId = presetVariationId
                                this.setState(stateCopy)
                              }}
                              changeColorHexa={(color) => {
                                var stateCopy = Object.assign({}, this.state)
                                stateCopy.newMaterials[i].materialColor = color
                                this.setState(stateCopy)
                              }}
                              setAddEditMaterialError={() => {
                                this.setState({
                                  displayAddEditMaterialError: true,
                                });
                              }}
                              materialNameError={this.state.displayAddEditMaterialError}
                            />
                            <div className={`${styles.flexRight} `} style={{ alignItems: "center" }}>
                              <span>
                                <Button
                                  theme={Theme.GHOST}
                                  shape="circle"
                                  icon="delete"
                                  title={this.props.intl.formatMessage({ id: 'productsvariations.deleteMaterial' })}
                                  onClick={() => {
                                    var stateCopy = Object.assign({}, this.state)
                                    stateCopy.newMaterials.splice(i, 1)
                                    this.setState(stateCopy)
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        ))
                        }
                        {this.state.displayEditVariationId &&
                          <span className={`${styles.flexCenter} ${styles.fullWidth} ${styles.spaceAddButton}`}>
                            <Button
                              title={this.props.intl.formatMessage({ id: 'productsvariations.addMaterial' })}
                              theme={Theme.PRIMARY}
                              shape="circle"
                              aria-label="Add"
                              icon="plus"
                              onClick={() => {
                                var stateCopy = Object.assign({}, this.state)

                                stateCopy.newMaterials.push({
                                  materialName: "",
                                  presetVariationsValue: "",
                                  materialColor: "",
                                  colorTag: { id: "", name: "" },
                                  presetVariationId: 0
                                })
                                stateCopy.displayAddMaterialForm = true
                                this.setState(stateCopy)
                              }}
                            />
                            <Button
                              theme={Theme.PRIMARY}
                              shape="circle"
                              aria-label="Éditer"
                              icon="check"
                              className={`${styles.ml}`}
                              onClick={async () => {
                                await addEditVariation(this.state.colorTagsIds);
                                addMaterials(variation)
                              }}
                            />
                          </span>
                        }
                      </div>

                    </div>
                  }
                </div>
              ))
            )
          }
        </div>
      </div >
    );
  }
  ;
}

const mapStateToProps = (state: IRootState) => ({
  objectProductVariations: state.objectProductVariationsState,
  objectProductVariationsLoading: state.objectProductVariationsState.loading,
  objectProductVariationMaterials: state.objectProductVariationMaterialState.objectProductVariationMaterials,
  objectProductVariationMaterialsLoading: state.objectProductVariationMaterialState.loading,
  colorTags: state.colorTagState.values,
  products: state.productState.values,
  productVariations: state.productVariationsState.products
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchObjectProductVariations: (objectProductId: string) =>
    dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId)),
  getColorTags: () =>
    dispatch(colorTag.thunks.fetchValues()),
  deleteObjectProductVariation: (variationId: string, succes?: string, error?: string) =>
    dispatch(objectProductVariations.deleteObjectProductVariation(variationId, succes, error)),
  createObjectProductVariation: (variation: ObjectProductVariationCreationDTO, succes?: string, error?: string) =>
    dispatch(objectProductVariations.createObjectProductVariation(variation, succes, error)),
  updateObjectProductVariation: (variationId: string, variation: ObjectProductVariationUpdateDTO, succes?: string, error?: string) =>
    dispatch(objectProductVariations.updateObjectProductVariation(variationId, variation, succes, error)),
  fetchObjectProductVariationMaterial: (objectProductId: string) =>
    dispatch(objectProductVariationMaterials.fetchObjectProductVariationMaterials(objectProductId)),
  createObjectProductVariationMaterial: (material: ObjectProductVariationMaterialCreationDTO,
    succes?: string, error?: string) =>
    dispatch(objectProductVariationMaterials.createObjectProductVariationMaterial(material, succes, error)),
  updateObjectProductVariationMaterial: (materialId: string, material: ObjectProductVariationMaterialUpdateDTO,
    succes?: string, error?: string) =>
    dispatch(objectProductVariationMaterials.updateObjectProductVariationMaterial(materialId, material, succes, error)),
  deleteObjectProductVariationMaterial: (materialId: string, succes?: string, error?: string) =>
    dispatch(objectProductVariationMaterials.deleteObjectProductVariationMaterial(materialId, succes, error)),
  updateObjectProductData3D: (id: string, data3D: ObjectProductData3DUpdateDTO, succes?: string, error?: string) =>
    dispatch(objectProductData3D.updateObjectProductData3D(id, data3D, succes, error)),
  fetchProducts: (request: IPaginationRequest = {}) =>
    dispatch(productActions.thunks.fetchValues(request)),
  fetchProductVariations: (input: string) =>
    dispatch(productVariations.fetchProducts(input))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProductVariations));
