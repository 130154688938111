import { BrandUpdateDTO } from '@addsome/dtos'
import { Button, Container, Theme } from '@addsome/ui-kit'
import { Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import BrandInformationFieldsGroup from '../BrandInformationFieldsGroup'
import styles from './BrandInformationForm.module.scss'
import {WrappedComponentProps, FormattedMessage, injectIntl} from 'react-intl'

type IProps = WrappedComponentProps & FormComponentProps &{
  brand: BrandUpdateDTO
  onSubmit: (brand: BrandUpdateDTO) => Promise<void> | void
}

class BrandInformationForm extends React.Component<IProps> {
  private validationSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().nullable(),
    phone: yup
      .string()
      .matches(/^\+?([0-9]* ?)*$/, 'Veuillez entrer un numéro de téléphone valide')
      .nullable(),
    websiteUrl: yup
      .string()
      .url()
      .nullable(),
    madeIn: yup.string().nullable()
  })

  public render() {
    const { brand } = this.props
    return (
      <Formik
        initialValues={brand}
        validationSchema={this.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!values) return
          values.phone = values.phone || null
          values.websiteUrl = values.websiteUrl || null
          const wait = this.props.onSubmit(values) || Promise.resolve()
          // If we get a promise, wait for it
          wait.finally(() => setSubmitting(false))
        }}
      >
        {formik => (
          <Form
            layout="horizontal"
            onSubmit={formik.handleSubmit}
            className={styles.brandInformationForm}
          >
            <Container>
              <BrandInformationFieldsGroup brand={brand} formik={formik} />
            </Container>

            <Button
              theme={Theme.PRIMARY}
              htmlType="submit"
              block
              size="large"
              uppercase
              disabled={Object.values(formik.errors).length > 0}
            >
              <FormattedMessage id = 'productpage.panel.saveChanges' />
            </Button>
          </Form>
        )}
      </Formik>
    )
  }
}

export default injectIntl(Form.create<IProps>({ name: 'BrandInfo' })(BrandInformationForm))
