import { AccountType, BrandsMetricsDTO, ObjectProductType } from '@addsome/dtos'
import { metrics as metricsActions } from '@addsome/redux-store'
import { CategoryColor, Heading, Table } from '@addsome/ui-kit'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect, useMemo, useState } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import accessoires from '../../../assets/images/accessoires.svg'
import luminaires from '../../../assets/images/luminaires.svg'
import mobilier from '../../../assets/images/mobilier.svg'
import { IRootState } from '../../../redux'
import styles from './HomePageCategories.module.scss'

export interface ICategoriesMetrics {
  icon: {
    src: string
    color: CategoryColor
  }
  name: string
  objectProductType: ObjectProductType
  brands: number
  products: number
  files: number
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps & {
    brandsMetrics?: BrandsMetricsDTO
  }

const HomePageCategories: React.FC<IProps> = ({
  intl,
  fetchObjectProductTypesMetrics,
  objectProductTypesMetrics,
  brandsMetrics,
  accountType
}) => {
  const [categoriesMetrics, setCategoriesMetrics] = useState<ICategoriesMetrics[]>([])
  const [width, setWidth] = useState<number>(window.innerWidth)
  const isMobile = width <= 600

  useEffect(() => {
    fetchObjectProductTypesMetrics()
  }, [fetchObjectProductTypesMetrics])

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    if (!objectProductTypesMetrics || (accountType === AccountType.BrandUser && !brandsMetrics)) {
      return
    }

    setCategoriesMetrics([
      {
        icon: {
          src: mobilier,
          color: CategoryColor.LIGHT_GREY
        },
        name: intl.formatMessage({ id: 'global.furniture' }),
        objectProductType: ObjectProductType.Furniture,
        brands: objectProductTypesMetrics.furniture.brandDefaultTypeCount,
        products: (brandsMetrics || objectProductTypesMetrics).furniture.objectProductCount,
        files: (brandsMetrics || objectProductTypesMetrics).furniture.objectProductWithBundleCount
      },
      {
        icon: {
          src: luminaires,
          color: CategoryColor.CYAN
        },
        name: intl.formatMessage({ id: 'global.lighting' }),
        objectProductType: ObjectProductType.Lighting,
        brands: objectProductTypesMetrics.lighting.brandDefaultTypeCount,
        products: (brandsMetrics || objectProductTypesMetrics).lighting.objectProductCount,
        files: (brandsMetrics || objectProductTypesMetrics).lighting.objectProductWithBundleCount
      },
      {
        icon: {
          src: accessoires,
          color: CategoryColor.DARK_BLUE
        },
        name: intl.formatMessage({ id: 'global.accessories' }),
        objectProductType: ObjectProductType.Accessories,
        brands: objectProductTypesMetrics.accessories.brandDefaultTypeCount,
        products: (brandsMetrics || objectProductTypesMetrics).accessories.objectProductCount,
        files: (brandsMetrics || objectProductTypesMetrics).accessories.objectProductWithBundleCount
      }
    ])
  }, [objectProductTypesMetrics, brandsMetrics, accountType, intl])

  const columns: Array<ColumnProps<ICategoriesMetrics>> = useMemo(
    () => [
      {
        dataIndex: 'icon',
        render: (value: { src: string; color: CategoryColor }) => <img src={value.src} />,
        width: '1%'
      },
      {
        dataIndex: 'name',
        render: (value: string, record: ICategoriesMetrics) => (
          <Link
            to={{
              pathname: '/products',
              search: `?filters={"objectProductTypes":["${record.objectProductType}"]}`
            }}
          >
            <strong>{value}</strong>
          </Link>
        ),
        width: '20%',
        hidden: isMobile
      },
      ...(brandsMetrics
        ? []
        : [
          {
            dataIndex: 'brands',
            render: (value: number) => (
              <>
                <strong>{intl.formatNumber(value)}</strong>&nbsp;&nbsp;
                <span>{intl.formatMessage({ id: 'homepage.metrics.brands.title' })}</span>
              </>
            ),
            width: '20%'
          }
        ]),
      {
        dataIndex: 'products',
        render: (value: number) => (
          <>
            <strong>{intl.formatNumber(value)}</strong>&nbsp;&nbsp;
            <span>{intl.formatMessage({ id: 'homepage.metrics.products.title' })}</span>
          </>
        ),
        width: '20%'
      },
      {
        dataIndex: 'files',
        width: '20%',
        render: (value: number) => (
          <>
            <strong>{intl.formatNumber(value)}</strong>&nbsp;&nbsp;
            <span>{intl.formatMessage({ id: 'homepage.metrics.files.title' })}</span>
          </>
        )
      }
    ],
    [brandsMetrics, intl, isMobile]
  ).filter(column => !column.hidden)

  return (
    <div className={styles.container}>
      <Heading level={3} as="h2" strong>
        {intl.formatMessage({ id: 'homepage.section.title.categories' })}
      </Heading>
      <Table
        rowClassName={record => {
          switch (record.icon.color) {
          case CategoryColor.YELLOW:
            return `${styles.row} ${styles.yellow}`
          case CategoryColor.BLUE:
            return `${styles.row} ${styles.blue}`
          case CategoryColor.LIGHT_GREY:
            return `${styles.row} ${styles.ligthGrey}`
          case CategoryColor.PRIMARY:
            return `${styles.row} ${styles.primary}`
          case CategoryColor.CYAN:
            return `${styles.row} ${styles.cyan}`
          case CategoryColor.DARK_BLUE:
            return `${styles.row} ${styles.darkBlue}`
          default:
            return styles.row
          }
        }}
        dataSource={categoriesMetrics}
        columns={columns}
        pagination={false}
        rowKey="name"
        showHeader={false}
      />
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  objectProductTypesMetrics: state.metricsState.objectProductTypesMetrics
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchObjectProductTypesMetrics: () => dispatch(metricsActions.fetchObjectProductTypesMetrics())
})

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomePageCategories)
)
