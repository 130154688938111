import React, { FC } from 'react'
import styles from './UploadItem.module.scss'
import { BrandDriveFileWithStatus } from '@addsome/redux-store/dist/store/drive'
import Progress from 'antd/lib/progress'
import { PRIMARY } from '../../../../utils/colors'

interface IProps {
  file: BrandDriveFileWithStatus
}

const UploadItem: FC<IProps> = ({ file }) => (
  <div className={styles.container}>
    <div className={styles.label}>{file.displayName}</div>
    <Progress
      type="circle"
      percent={file.progress}
      showInfo={false}
      width={24}
      strokeWidth={8}
      status="active"
      strokeColor={PRIMARY}
    />
  </div>
)

export default UploadItem
