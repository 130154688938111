"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setBytesUploaded = exports.setUploadSpeed = exports.updateQueueFileStatus = exports.emptyQueue = exports.updateFileProgress = exports.removeWaitingFile = exports.addFile = exports.addQueueFile = exports.removeFileOrFolder = exports.addFolder = exports.setBrandId = exports.setLoading = exports.setDrive = void 0;

var model_1 = require("./model");

var setDrive = function setDrive(drive) {
  return {
    type: model_1.ActionsTypes.SET_DRIVE,
    drive: drive
  };
};

exports.setDrive = setDrive;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_DRIVE_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;

var setBrandId = function setBrandId(brandId) {
  return {
    type: model_1.ActionsTypes.SET_BRAND_ID,
    brandId: brandId
  };
};

exports.setBrandId = setBrandId;

var addFolder = function addFolder(path) {
  return {
    type: model_1.ActionsTypes.ADD_FOLDER,
    path: path
  };
};

exports.addFolder = addFolder;

var removeFileOrFolder = function removeFileOrFolder(path) {
  return {
    type: model_1.ActionsTypes.REMOVE_FILE_OR_FOLDER,
    path: path
  };
};

exports.removeFileOrFolder = removeFileOrFolder;

var addQueueFile = function addQueueFile(file) {
  return {
    type: model_1.ActionsTypes.ADD_QUEUE_FILE,
    file: file
  };
};

exports.addQueueFile = addQueueFile;

var addFile = function addFile(file) {
  return {
    type: model_1.ActionsTypes.ADD_FILE,
    file: file
  };
};

exports.addFile = addFile;

var removeWaitingFile = function removeWaitingFile(path) {
  return {
    type: model_1.ActionsTypes.REMOVE_WAITING_FILE,
    path: path
  };
};

exports.removeWaitingFile = removeWaitingFile;

var updateFileProgress = function updateFileProgress(path, progress) {
  return {
    type: model_1.ActionsTypes.UPDATE_FILE_PROGRESS,
    path: path,
    progress: progress
  };
};

exports.updateFileProgress = updateFileProgress;

var emptyQueue = function emptyQueue() {
  return {
    type: model_1.ActionsTypes.EMPTY_QUEUE
  };
};

exports.emptyQueue = emptyQueue;

var updateQueueFileStatus = function updateQueueFileStatus(path, status) {
  return {
    type: model_1.ActionsTypes.UPDATE_QUEUE_FILE_STATUS,
    path: path,
    status: status
  };
};

exports.updateQueueFileStatus = updateQueueFileStatus;

var setUploadSpeed = function setUploadSpeed(speed) {
  return {
    type: model_1.ActionsTypes.SET_UPLOAD_SPEED,
    speed: speed
  };
};

exports.setUploadSpeed = setUploadSpeed;

var setBytesUploaded = function setBytesUploaded(bytes) {
  return {
    type: model_1.ActionsTypes.SET_BYTES_UPLOADED,
    bytes: bytes
  };
};

exports.setBytesUploaded = setBytesUploaded;