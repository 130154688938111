"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var BrandDefaultType;

(function (BrandDefaultType) {
  BrandDefaultType["Furniture"] = "furniture";
  BrandDefaultType["Lighting"] = "lighting";
  BrandDefaultType["Accessories"] = "accessories";
})(BrandDefaultType = exports.BrandDefaultType || (exports.BrandDefaultType = {}));