"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.setPromotedScenes = exports.setMostPopularScenes = exports.setAllScenes = exports.setScenes = void 0;

var model_1 = require("./model");

var setScenes = function setScenes(scenes) {
  return {
    type: model_1.ActionsTypes.SET_SCENES,
    scenes: scenes
  };
};

exports.setScenes = setScenes;

var setAllScenes = function setAllScenes(allScenes) {
  return {
    type: model_1.ActionsTypes.SET_ALL_SCENES,
    allScenes: allScenes
  };
};

exports.setAllScenes = setAllScenes;

var setMostPopularScenes = function setMostPopularScenes(mostPopularScenes) {
  return {
    type: model_1.ActionsTypes.SET_MOST_POPULAR_SCENES,
    mostPopularScenes: mostPopularScenes
  };
};

exports.setMostPopularScenes = setMostPopularScenes;

var setPromotedScenes = function setPromotedScenes(promotedScenes) {
  return {
    type: model_1.ActionsTypes.SET_PROMOTED_SCENES,
    promotedScenes: promotedScenes
  };
};

exports.setPromotedScenes = setPromotedScenes;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_SCENES_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;