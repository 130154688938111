import {
  AbstractRouterType,
  architect,
  addsomeUser,
  auth,
  axiosInstance,
  brand,
  brandTag,
  brandUser,
  collections,
  communityScene,
  colorTag,
  designer,
  drive,
  IAbstractRouter,
  ISharedRootState,
  metrics,
  notification,
  objectProduct,
  objectProductCategories,
  objectProductVariations,
  product,
  productMaterialTag,
  productPrices,
  productProblem,
  productProblemType,
  productStates,
  productSuggests,
  productVariations,
  productViewer,
  project,
  roomTag,
  scene,
  setApiBaseUrl,
  styleTag,
  surfaceProduct,
  user,
  brandInvitation,
  objectProductVariationMaterials,
  objectProductDimensions
} from '@addsome/redux-store';
import { AxiosError } from 'axios';
import { connectRouter, push, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { parse } from 'querystring';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import settings from '../settings';
import { createIntl } from 'react-intl'
import english from '../../../backoffice/src/translations/en.json'
import french from '../../../backoffice/src/translations/fr.json'
import { AddsomeUserAndAccountOrderEnum } from '@addsome/dtos/dist';

export type IRootState = ISharedRootState & {
  router: RouterState;
};

export const abstractRouter: IAbstractRouter = {
  type: AbstractRouterType.Action,
  push
};

const reducers = (history: History) =>
  // Keep the reducers in alphabetical order
  // because the order here will be the same as the one in the redux devtools
  combineReducers({
    architectState: architect.reducer,
    authState: auth.reducer,
    addsomeUserState: addsomeUser.reducer,
    brandInvitationState: brandInvitation.reducer,
    brandState: brand.reducer,
    brandTagState: brandTag.reducer,
    brandUserState: brandUser.reducer,
    colorTagState: colorTag.reducer,
    collectionsState: collections.reducer,
    communitySceneState: communityScene.reducer,
    designerState: designer.reducer,
    driveState: drive.reducer,
    metricsState: metrics.reducer,
    notificationState: notification.reducer,
    objectProductCategoriesState: objectProductCategories.reducer,
    objectProductState: objectProduct.reducer,
    objectProductVariationsState: objectProductVariations.reducer,
    objectProductDimensionsState: objectProductDimensions.reducer,
    objectProductVariationMaterialState: objectProductVariationMaterials.reducer,
    productMaterialTagState: productMaterialTag.reducer,
    productPricesState: productPrices.reducer,
    productProblemState: productProblem.reducer,
    productProblemTypeState: productProblemType.reducer,
    productState: product.reducer,
    productStatesState: productStates.reducer,
    productSuggestsState: productSuggests.reducer,
    productVariationsState: productVariations.reducer,
    productViewerState: productViewer.reducer,
    projectState: project.reducer,
    roomTagState: roomTag.reducer,
    router: connectRouter(history),
    sceneState: scene.reducer,
    styleTagState: styleTag.reducer,
    surfaceProductState: surfaceProduct.reducer,
    userState: user.reducer
  })

export const browserHistory = createBrowserHistory()

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

setApiBaseUrl(settings.api.baseUrl)

// Axios interceptor
const errorInterceptor = (error: AxiosError) => {
  if (
    error.response &&
    error.response.status === 401 &&
    !['/login', '/reset-password', '/catalog'].includes(browserHistory.location.pathname)
  ) {
    const query = parse(browserHistory.location.search)
    browserHistory.push('/login' + query.from ? browserHistory.location.search : '')
  }
  return Promise.reject(error.response)
}
axiosInstance.interceptors.response.use(undefined, errorInterceptor)

let locale = localStorage.getItem('lang') || 'fr'
let messages
if (locale === 'en')
  messages = english
if (locale === 'fr')
  messages = french

const intl = createIntl({ locale, messages })

const store = createStore(
  reducers(browserHistory),
  composeEnhancers(applyMiddleware(routerMiddleware(browserHistory), thunk))
)
// Initialize notifications
brand.createNotifications(store.dispatch,
  {
    errorRetrievingBrands: intl.formatMessage({ id: 'brandpage.panel.errorRetrievingBrands' }),
    errorRetrievingABrand: intl.formatMessage({ id: 'brandpage.panel.errorRetrievingABrand' }),
    brandAdded: intl.formatMessage({ id: 'brandpage.panel.brandAdded' }),
    errorCreatingBrand: intl.formatMessage({ id: 'brandpage.panel.errorCreatingBrand' }),
    updatedBrand: intl.formatMessage({ id: 'brandpage.panel.updatedBrand' }),
    errorRegisteringBrand: intl.formatMessage({ id: 'brandpage.panel.errorRegisteringBrand' }),
    brandRemoved: intl.formatMessage({ id: 'brandpage.panel.brandRemoved' }),
    errorDeletingBrand: intl.formatMessage({ id: 'brandpage.panel.errorDeletingBrand' })
  })
architect.createNotifications(store.dispatch, {
  errorRetrievingArchitects: intl.formatMessage({ id: 'userspage.architect.errorRetrievingArchitects' }),
  errorRetrievingAnArchitect: intl.formatMessage({ id: 'userspage.architect.errorRetrievingAnArchitect' }),
  updatedArchitect: intl.formatMessage({ id: 'userspage.architect.updatedArchitect' }),
  errorRegisteringArchitect: intl.formatMessage({ id: 'userspage.architect.errorRegisteringArchitect' }),
  architectRemoved: intl.formatMessage({ id: 'userspage.architect.architectRemoved' }),
  errorDeletingArchitect: intl.formatMessage({ id: 'userspage.architect.errorDeletingArchitect' })
})
brandUser.createNotifications(store.dispatch, {
  errorRetrievingBrandUsers: intl.formatMessage({ id: 'userspage.branduser.errorRetrievingBrandUsers' }),
  errorRetrievingABrandUser: intl.formatMessage({ id: 'userspage.branduser.errorRetrievingABrandUser' }),
  addBrandUser: intl.formatMessage({ id: 'userspage.branduser.addBrandUser' }),
  errorCreatingBrandUser: intl.formatMessage({ id: 'userspage.branduser.errorCreatingBrandUser' }),
  userUpdated: intl.formatMessage({ id: 'userspage.branduser.userUpdated' }),
  errorRegisteringBrandUser: intl.formatMessage({ id: 'userspage.branduser.errorRegisteringBrandUser' }),
  userDeleted: intl.formatMessage({ id: 'userspage.branduser.userDeleted' }),
  errorDeletingBrandUser: intl.formatMessage({ id: 'userspage.branduser.errorDeletingBrandUser' })
})
addsomeUser.createNotifications(store.dispatch, {
  errorRetrievingAddsomeUsers: intl.formatMessage({ id: 'userspage.addsomeUser.errorRetrievingAddsomeUsers' }),
  errorRetrievingAnAddsomeUser: intl.formatMessage({ id: 'userspage.addsomeUser.errorRetrievingAnAddsomeUser' }),
  updatedAddsomeUser: intl.formatMessage({ id: 'userspage.addsomeUser.addsomeUserUpdated' }),
  errorDeletingAddsomeUser: intl.formatMessage({ id: 'userspage.addsomeUser.errorDeletingAddsomeUser' }),
  errorRegisteringAddsomeUser: intl.formatMessage({ id: 'userspage.addsomeUser.errorRegisteringAddsomeUser' }),
  addsomeUserRemoved: intl.formatMessage({ id: 'userspage.addsomeUser.addsomeUserRemoved' })
})
product.createNotifications(store.dispatch, {
  errorRetrievingProducts: intl.formatMessage({ id: 'productpage.panel.errorRetrievingProducts' }),
  errorRetrievingAProduct: intl.formatMessage({ id: 'productpage.panel.errorRetrievingAProduct' }),
  productAdded: intl.formatMessage({ id: 'productpage.panel.productAdded' }),
  errorCreatingProduct: intl.formatMessage({ id: 'productpage.panel.errorCreatingProduct' }),
  updatedProduct: intl.formatMessage({ id: 'productpage.panel.updatedProduct' }),
  errorRegisteringProduct: intl.formatMessage({ id: 'productpage.panel.errorRegisteringProduct' }),
  productRemoved: intl.formatMessage({ id: 'productpage.panel.productRemoved' }),
  errorDeletingProduct: intl.formatMessage({ id: 'productpage.panel.errorDeletingProduct' })
})
productProblem.createNotifications(store.dispatch, {
  errorRetrievingProblems: intl.formatMessage({ id: 'productspage.problems.errorRetrievingProblems' }),
  errorRetrievingAProblem: intl.formatMessage({ id: 'productspage.problems.errorRetrievingAProblem' }),
  addProblem: intl.formatMessage({ id: 'productspage.problems.addProblem' }),
  errorCreatingProblem: intl.formatMessage({ id: 'productspage.problems.errorCreatingProblem' }),
  updateProblem: intl.formatMessage({ id: 'productspage.problems.updateProblem' }),
  errorRegisteringProblem: intl.formatMessage({ id: 'productspage.problems.errorRegisteringProblem' }),
  removedProblem: intl.formatMessage({ id: 'productspage.problems.removedProblem' }),
  errorDeletingProblem: intl.formatMessage({ id: 'productspage.problems.errorDeletingProblem' })
})
productProblemType.createNotifications(store.dispatch, {
  errorRetrievingProblemsTypes: intl.formatMessage({ id: 'producttable.problemType.errorRetrievingProblemsTypes' }),
  errorRetrievingAProblemTypes: intl.formatMessage({ id: 'producttable.problemType.errorRetrievingAProblemTypes' }),
  errorCreatingProblemTypes: intl.formatMessage({ id: 'producttable.problemType.errorCreatingProblemTypes' }),
  errorRegisteringProblemTypes: intl.formatMessage({ id: 'producttable.problemType.errorRegisteringProblemTypes' }),
  errorDeletingProblemTypes: intl.formatMessage({ id: 'producttable.problemType.errorDeletingProblemTypes' })
})
project.createNotifications(store.dispatch, {
  errorRetrievingProjects: intl.formatMessage({ id: 'project.errorRetrievingProjects' }),
  errorRetrievingAProject: intl.formatMessage({ id: 'project.errorRetrievingAProject' }),
  addedProject: intl.formatMessage({ id: 'project.addedProject' }),
  errorCreatingProject: intl.formatMessage({ id: 'project.errorCreatingProject' }),
  updatedProject: intl.formatMessage({ id: 'project.updatedProject' }),
  errorRegisteringProject: intl.formatMessage({ id: 'project.errorRegisteringProject' }),
  removedProject: intl.formatMessage({ id: 'project.removedProject' }),
  errorDeletingProject: intl.formatMessage({ id: 'project.errorDeletingProject' })
})
objectProduct.createNotifications(store.dispatch, {
  errorRetrievingDimentions: intl.formatMessage({ id: 'objectProduct.errorRetrievingDimentions' }),
  errorRetrievingADimension: intl.formatMessage({ id: 'objectProduct.errorRetrievingADimension' }),
  errorCreatingDimension: intl.formatMessage({ id: 'objectProduct.errorCreatingDimension' }),
  errorRegisteringDimension: intl.formatMessage({ id: 'objectProduct.errorRegisteringDimension' }),
  errorDeletingDimension: intl.formatMessage({ id: 'objectProduct.errorDeletingDimension' })
})
surfaceProduct.createNotifications(store.dispatch, {
  errorRetrievingDimentions: intl.formatMessage({ id: 'objectProduct.errorRetrievingDimentions' }),
  errorRetrievingADimension: intl.formatMessage({ id: 'objectProduct.errorRetrievingADimension' }),
  errorCreatingDimension: intl.formatMessage({ id: 'objectProduct.errorCreatingDimension' }),
  errorRegisteringDimension: intl.formatMessage({ id: 'objectProduct.errorRegisteringDimension' }),
  errorDeletingDimension: intl.formatMessage({ id: 'objectProduct.errorDeletingDimension' })
})
designer.createNotifications(store.dispatch, {
  errorRetrievingDesigners: intl.formatMessage({ id: 'designer.errorRetrievingDesigners' }),
  errorRetrievingADesigner: intl.formatMessage({ id: 'designer.errorRetrievingADesigner' }),
  addedDesigner: intl.formatMessage({ id: 'designer.addedDesigner' }),
  errorCreatingDesigner: intl.formatMessage({ id: 'designer.errorCreatingDesigner' }),
  updatesDesigner: intl.formatMessage({ id: 'designer.updatesDesigner' }),
  errorRegisteringDesigner: intl.formatMessage({ id: 'designer.errorRegisteringDesigner' }),
  removedDesigner: intl.formatMessage({ id: 'designer.removedDesigner' }),
  errorDeletingDesigner: intl.formatMessage({ id: 'designer.errorDeletingDesigner' })
})
objectProductCategories.createNotifications(store.dispatch, {
  errorRetrievingCategories: intl.formatMessage({ id: 'objectProductCategories.errorRetrievingCategories' }),
  errorRetrievingCategory: intl.formatMessage({ id: 'objectProductCategories.errorRetrievingCategory' }),
  addedProductCategory: intl.formatMessage({ id: 'objectProductCategories.addedProductCategory' }),
  errorCreatingCategory: intl.formatMessage({ id: 'objectProductCategories.errorCreatingCategory' }),
  updatedProductCategory: intl.formatMessage({ id: 'objectProductCategories.updatedProductCategory' }),
  errorRegisteringCategory: intl.formatMessage({ id: 'objectProductCategories.errorRegisteringCategory' }),
  removedProductCategory: intl.formatMessage({ id: 'objectProductCategories.removedProductCategory' }),
  errorDeletingCategory: intl.formatMessage({ id: 'objectProductCategories.errorDeletingCategory' })
})
productMaterialTag.createNotifications(store.dispatch, {
  errorRetrievingMaterialTags: intl.formatMessage({ id: 'productMaterialTags.errorRetrievingMaterialTags' }),
  errorRetrievingAMaterialTag: intl.formatMessage({ id: 'productMaterialTags.errorRetrievingAMaterialTag' }),
  addedMaterialTag: intl.formatMessage({ id: 'productMaterialTags.addedMaterialTag' }),
  errorCreatingMaterialTag: intl.formatMessage({ id: 'productMaterialTags.errorCreatingMaterialTag' }),
  updatedMaterialTag: intl.formatMessage({ id: 'productMaterialTags.updatedMaterialTag' }),
  errorRegisteringMaterialTag: intl.formatMessage({ id: 'productMaterialTags.errorRegisteringMaterialTag' }),
  removedMaterialTag: intl.formatMessage({ id: 'productMaterialTags.removedMaterialTag' }),
  errorDeletingMaterialTag: intl.formatMessage({ id: 'productMaterialTags.errorDeletingMaterialTag' })
})
styleTag.createNotifications(store.dispatch, {
  errorRetrievingStyleTags: intl.formatMessage({ id: 'styletags.errorRetrievingStyleTags' }),
  errorRetrievingAStyleTag: intl.formatMessage({ id: 'styletags.errorRetrievingAStyleTag' }),
  addedStyleTag: intl.formatMessage({ id: 'styletags.addedStyleTag' }),
  errorCreatingStyleTag: intl.formatMessage({ id: 'styletags.errorCreatingStyleTag' }),
  updatedStyleTag: intl.formatMessage({ id: 'styletags.updatedStyleTag' }),
  errorRegisteringStyleTag: intl.formatMessage({ id: 'styletags.errorRegisteringStyleTag' }),
  removedStyleTag: intl.formatMessage({ id: 'styletags.removedStyleTag' }),
  errorDeletingStyleTag: intl.formatMessage({ id: 'styletags.errorDeletingStyleTag' })
})
colorTag.createNotifications(store.dispatch, {
  errorRetrievingColorTags: intl.formatMessage({ id: 'colortags.errorRetrievingColorTags' }),
  errorRetrievingTheColorTag: intl.formatMessage({ id: 'colortags.errorRetrievingTheColorTag' }),
  addedColorTag: intl.formatMessage({ id: 'colortags.addedColorTag' }),
  errorCreatingColorTag: intl.formatMessage({ id: 'colortags.errorCreatingColorTag' }),
  updatedColorTag: intl.formatMessage({ id: 'colortags.updatedColorTag' }),
  errorRegisteringColorTag: intl.formatMessage({ id: 'colortags.errorRegisteringColorTag' }),
  removedColorTag: intl.formatMessage({ id: 'colortags.removedColorTag' }),
  errorDeletingColorTag: intl.formatMessage({ id: 'colortags.errorDeletingColorTag' })
})
roomTag.createNotifications(store.dispatch, {
  errorRetrievingRoomTags: intl.formatMessage({ id: 'roomtags.errorRetrievingRoomTags' }),
  errorRetrievingTheRoomTag: intl.formatMessage({ id: 'roomtags.errorRetrievingTheRoomTag' }),
  addRoomTag: intl.formatMessage({ id: 'roomtags.addRoomTag' }),
  errorCreatingRoomTag: intl.formatMessage({ id: 'roomtags.updatedRoomTag' }),
  updatedRoomTag: intl.formatMessage({ id: 'roomtags.updatedRoomTag' }),
  errorRegisteringRoomTag: intl.formatMessage({ id: 'roomtags.errorRegisteringRoomTag' }),
  removedRoomTag: intl.formatMessage({ id: 'roomtags.removedRoomTag' }),
  errorDeletingRoomTag: intl.formatMessage({ id: 'roomtags.errorDeletingRoomTag' })
})
drive.createNotifications(store.dispatch, {
  errorRetrievingTheFolder: intl.formatMessage({ id: 'drive.errorRetrievingTheFolder' }),
  addedFolder: intl.formatMessage({ id: 'drive.addedFolder' }),
  folderAlreadyExisting: intl.formatMessage({ id: 'drive.folderAlreadyExisting' }),
  deletedItem: intl.formatMessage({ id: 'drive.deletedItem' }),
  errorDeletingItem: intl.formatMessage({ id: 'drive.errorDeletingItem' }),
  addedFile: intl.formatMessage({ id: 'drive.addedFile' }),
  fileAlreadyExisting: intl.formatMessage({ id: 'drive.fileAlreadyExisting' })
})

export default store
