"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.setObjectProductVariationMaterials = void 0;

var model_1 = require("./model");

var setObjectProductVariationMaterials = function setObjectProductVariationMaterials(objectProductVariationMaterials) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_VARIATION_MATERIALS,
    objectProductVariationMaterials: objectProductVariationMaterials
  };
};

exports.setObjectProductVariationMaterials = setObjectProductVariationMaterials;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_VARIATION_MATERIALS_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;