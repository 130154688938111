import React, { FC } from 'react'
import classnames from 'classnames'
import { Modal, Heading, Theme, Button } from '@addsome/ui-kit'
import { setShouldShowArchitectNotLinkedToBrandDownloadPopup } from '@addsome/redux-store/dist/store/auth'
import { ArchitectDTO } from '@addsome/dtos/dist'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { IRootState } from '../../redux'
import styles from './ArchitectNotLinkedPopup.module.scss'
import { activateBrand } from '@addsome/redux-store/dist/store/brand-invitation'
import { product } from '@addsome/redux-store/dist'

const ArchitectNotLinkedPopup: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: IRootState) => state.userState.user) as ArchitectDTO
  const isVisible = useSelector(
    (state: IRootState) => state.authState.shouldShowArchitectNotLinkedToBrandDownloadPopup
  )
  const notLinkedBrand = useSelector((state: IRootState) => state.authState.notLinkedBrand)
  const currentProduct = useSelector((state: IRootState) => state.productState.value)

  if (!user) {
    return <></>;
  }

  return (
    <Modal
      visible={isVisible}
      footer={null}
      centered
      onCancel={() => dispatch(setShouldShowArchitectNotLinkedToBrandDownloadPopup(false))}
    >
      <Heading level={3} fill centerText>
        <FormattedMessage
          id="architectnotlinkedpopup.title"
          values={{
            name: (
              <span className={styles.name}>
                {user.account.firstName} {user.account.lastName}
              </span>
            )
          }}
        />
      </Heading>
      <div className={classnames(styles.description)}>
        <FormattedMessage
          id="architectnotlinkedpopup.desc"
          values={{
            p: (...chunks: string[]) => <p>{chunks}</p>,
            a: (...chunks: string[]) => <a href="/brands?tab=allBrands">{chunks}</a>
          }}
        />
      </div>

      <Button
        theme={Theme.PRIMARY}
        block
        onClick={async () => {
          dispatch(activateBrand(notLinkedBrand.id))
          dispatch(setShouldShowArchitectNotLinkedToBrandDownloadPopup(false))

          // Refreshing product if needed to refresh download links
          dispatch(product.thunks.fetchValue(currentProduct!.id))
        }}
      >
        <FormattedMessage
          id="architectnotlinkedpopup.button"
          values={{ brandName: notLinkedBrand != null ? notLinkedBrand.name : 'Brand' }}
        />
      </Button>
    </Modal>
  )
}

export default ArchitectNotLinkedPopup
