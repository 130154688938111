"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.setObjectProductVariations = void 0;

var model_1 = require("./model");

var setObjectProductVariations = function setObjectProductVariations(objectProductVariations) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_VARIATIONS,
    objectProductVariations: objectProductVariations
  };
};

exports.setObjectProductVariations = setObjectProductVariations;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_VARIATIONS_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;