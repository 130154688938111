import { Button, Heading, Modal, Steps, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import React from 'react'
import styles from './MultiStepModal.module.scss'

export interface IStepFormProps<T> {
  value: T
  onNext: (values: T) => void
  onPrevious: () => void
  first: boolean
  last: boolean
}

export type StepFormComponent<T> =
  | React.FC<IStepFormProps<T>>
  | (new (props: IStepFormProps<T>) => React.Component<IStepFormProps<T>>)

interface IProps<T> {
  initialValue: T
  steps: Array<{
    title: React.ReactNode
    component: StepFormComponent<T>
    props?: { [key: string]: any }
  }>
  title: React.ReactNode
  onSubmit: (value: T) => void
}

interface IState<T> {
  visible: boolean
  currentStep: number
  value?: T
}

class MultiStepModal<T> extends React.Component<IProps<T>, IState<T>> {
  public state: IState<T> = {
    visible: false,
    currentStep: 0
  }

  public render() {
    const { steps, initialValue, title } = this.props
    const { currentStep, value, visible } = this.state
    const StepComponent = steps[currentStep].component

    const header = (
      <>
        <Heading level={3} as="h2" centerText>
          {title}
        </Heading>
        <div className={styles.steps}>
          <Steps current={currentStep} progressDot>
            {steps.map((step, index) => (
              <Steps.Step title={step.title} key={index} />
            ))}
          </Steps>
        </div>
      </>
    )

    return (
      <>
        <Button theme={Theme.BLACK} shape="circle" size="large" onClick={this.displayModal}>
          <Icon type="plus" aria-label={title} />
        </Button>
        <Modal
          visible={visible}
          title={header}
          footer={null}
          width={700}
          maskClosable={false}
          centered
          onCancel={() => {
            this.setState({ currentStep: 0, value: { ...initialValue }, visible: false })
          }}
          className={styles.multiStepModal}
        >
          {visible && StepComponent && (
            <StepComponent
              value={value || { ...initialValue }}
              onNext={this.next}
              onPrevious={this.previous}
              first={currentStep === 0}
              last={currentStep + 1 === steps.length}
              {...steps[currentStep].props}
            />
          )}
        </Modal>
      </>
    )
  }

  private displayModal = () => {
    this.setState({
      visible: true
    })
  }

  private next = (updatedValue: T) => {
    const { value, currentStep } = this.state
    const { initialValue, steps, onSubmit } = this.props
    const newValue = { ...value, ...updatedValue }
    this.setState({
      value: newValue
    })

    if (currentStep + 1 < steps.length) {
      this.setState({
        currentStep: currentStep + 1
      })
    } else {
      onSubmit(newValue)
      this.setState({ currentStep: 0, value: { ...initialValue }, visible: false })
    }
  }

  private previous = () => {
    this.setState({ currentStep: Math.max(this.state.currentStep - 1, 0) })
  }
}

export default MultiStepModal
