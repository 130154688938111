import { ColorTagDTO, ObjectProductVariationDTO } from '@addsome/dtos';
import { Button, Popconfirm, Theme } from '@addsome/ui-kit';
import React from 'react';
import styles from './ProductVariations.module.scss';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import AddVariation from './AddVariation';


type IProps = WrappedComponentProps & {
  variation: ObjectProductVariationDTO;
  displayEditSectionForVariantId: string;
  displayAddVariation: boolean;
  optionsForCloseFunction: { addVariation: string; editVariation: string; addMaterial: string; editMaterial: string; };
  closeOthers: (keepOpen: string) => void;
  closeExpand: () => void;
  setMaterialsForVariationId: (variationId: string) => void;
  deleteVariation: (variationId: string) => void;
  cloneVariation: (variationId: string) => void;
  editVariation?: (variation: ObjectProductVariationDTO) => void;
  changeVariationName: (variationName: string) => void;
  closeAddVariation: () => void;
  setAddEditVariationError: () => void;
  displayAddEditVariationError: boolean;
  addColorLabel: (label: string, id: string) => void;
  onCloseColorLabel: (index: number) => void;
  variationName: string;
  colorTags: ColorTagDTO[];
  colorTagsLabels: string[];
  readonly?: boolean;
};


class VariationsTableRow extends React.Component<IProps> {


  public render() {

    return (
      <div className={`${styles.backgroundTable} ${styles.alignTable} ${this.props.displayEditVariation && styles.flexStart} `}>
        {!this.props.displayEditVariation && <p>{this.props.variation.name}</p>}
        {this.props.displayEditVariation &&
          <div className={`${styles.fullWidth}`}>
            <AddVariation
              changeVariationName={(variationName) => {
                this.props.changeVariationName(variationName);
              }}
              closeAddVariation={() => {
                this.props.closeAddVariation();
              }}

              setAddEditVariationError={() => {
                this.props.setAddEditVariationError();
              }}
              addColorLabel={(label: string, id: string) => {
                this.props.addColorLabel(label, id);
              }}
              onCloseColorLabel={(index: number) => {
                this.props.onCloseColorLabel(index);
              }}
              variationName={this.props.variationName}
              variationNameError={this.props.displayAddEditVariationError}
              colorTagsRequest={this.props.colorTags}
              colorTagsLabels={this.props.colorTagsLabels}
              addVariation={false}
            />
          </div>

        }

        <div
          draggable={true}
          onDragStart={(event) => { event.preventDefault(); event.stopPropagation(); }}
          className={styles.flexRight}
        >
          <span>
            <Button
              theme={Theme.PRIMARY}
              shape="circle"
              aria-label="Éditer"
              title={this.props.intl.formatMessage({ id: 'productsvariations.expandForMaterials' })}
              icon={this.props.variation.id === this.props.displayEditSectionForVariantId ? "up" : "down"}
              onClick={() => {
                if (this.props.variation.id === this.props.displayEditSectionForVariantId) {

                  this.props.closeExpand();
                }

                else {
                  this.props.setMaterialsForVariationId(this.props.variation.id);
                  this.props.closeExpand();
                }
              }
              }
            />
          </span>
          <span>
            <Button
              theme={Theme.PRIMARY}
              shape="circle"
              aria-label="Éditer"
              icon="edit"
              title={this.props.intl.formatMessage({ id: 'productsvariations.editVariation' })}
              onClick={() => {

                this.props.editVariation && this.props.editVariation(this.props.variation);
                this.props.closeOthers(this.props.optionsForCloseFunction.editVariation);
              }
              }
            />
          </span>
          <span>
            <Button
              theme={Theme.PRIMARY}
              shape="circle"
              aria-label="Clone"
              icon="copy"
              title={this.props.intl.formatMessage({ id: 'productsvariations.cloneVariation' })}
              onClick={() => {

                this.props.cloneVariation(this.props.variation);
              }
              }
            />
          </span>
          <span>
            <Popconfirm
              title={this.props.intl.formatMessage({ id: 'productsvariations.confirmDeleteVariation' })}
              onConfirm={() => {
                this.props.deleteVariation(this.props.variation.id);
              }}
              okText={this.props.intl.formatMessage({ id: 'productsvariations.okText' })}
              cancelText={this.props.intl.formatMessage({ id: 'productsvariations.cancelText' })}
              placement="left"
            >
              <Button
                theme={Theme.GHOST}
                shape="circle"
                icon="delete"
                title={this.props.intl.formatMessage({ id: 'productsvariations.deteleVariation' })}
                aria-label={`Supprimer ${this.props.variation.name}`}
              />
            </Popconfirm>
          </span>
        </div>
        {/* <hr /> */}
      </div >
    );
  }
}


export default injectIntl(VariationsTableRow);
