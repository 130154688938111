import { apiRequest } from '@addsome/redux-store';

const ARCHITECT_PATH = '/architects';
const ACCOUNT_PATH = '/account';

export async function getArchitectByAccountId(id) {
  try {
    const resp = await apiRequest('GET', `${ARCHITECT_PATH}/account/${id}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}
export async function findAccountWithSameEmail(email) {
  try {
    const resp = await apiRequest('GET', `${ARCHITECT_PATH}/account/email/${email}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function sendNewAccountEmail(architectId, subscriptionType) {
  try {
    const resp = await apiRequest('GET', `${ARCHITECT_PATH}/newAccount/${architectId}/${subscriptionType}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function sendAccountValidatedEmail(architectId, query = null) {
  try {
    const resp = query ?
      await apiRequest('GET', `${ARCHITECT_PATH}/validateAccount/${architectId}${query}`) :
      await apiRequest('GET', `${ARCHITECT_PATH}/validateAccount/${architectId}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function sendAccountValidatedWithoutSubscriptionEmail(architectId) {
  try {
    const resp = await apiRequest('GET', `${ARCHITECT_PATH}/noSubscriptionValidatedAccount/${architectId}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function sendNewUserEmail(architectId) {
  try {
    const resp = await apiRequest('GET', `${ARCHITECT_PATH}/newUser/${architectId}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function sendUpgradeSubscriptionEmail(architectId, subscriptionType) {
  try {
    const resp = await apiRequest('GET', `${ARCHITECT_PATH}/upgradeSubscription/${architectId}/${subscriptionType}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}


export async function sendCancelSubscriptionEmail(architectId) {
  try {
    const resp = await apiRequest('GET', `${ARCHITECT_PATH}/cancelSubscription/${architectId}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function validateVatCode(countryCode, vatCode) {
  try {
    const resp = await apiRequest('GET', `${ARCHITECT_PATH}/validate/${countryCode}/${vatCode}`);
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function checkPassword(email, password) {
  try {
    console.log(email, password)
    const resp = await apiRequest('POST', `${ACCOUNT_PATH}/check-password`, { email: email, password: password });
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}

export async function reportProblemEmail(product, email, problemType, comment) {
  try {
    const resp = await apiRequest('POST', `${ARCHITECT_PATH}/reportProblemEmail`, {
      productId: product.id,
      productName: product.name,
      email: email,
      problemType: problemType,
      comment: comment
    });
    return resp;
  }
  catch (e) {
    console.log(e);
    return e;
  }
}