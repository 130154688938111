import React, { useEffect, useState } from 'react'
import StartComponent from './StartComponent/StartComponent'
import { MediaDTO, SceneDTO } from '@addsome/dtos'
import UploadComponent from './UploadComponent/UploadComponent'
import ProductsComponent from './ProductsComponent/ProductsComponent';
import styles from './CreateScene.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { AnyAction } from 'redux'
import {
  communityScene,
} from '@addsome/redux-store'
import { injectIntl, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import StatusComponent from './StatusComponent/StatusComponent'
import { deleteMedia } from '../../../services/media'

interface PayloadInterface {
  name: string;
  medias: { id: string }[];
  products: { id: string }[];
}

const CreateScene = ({defaultGallery, createScene}) => {
  const [step, setStep] = useState(1);
  const [firstGalleryMedia, setFirstGalleryMedia] = useState<SceneDTO|null>(null);
  const [images, setImages] = useState<MediaDTO[]>([]);
  const [payload, setPayload] = useState<PayloadInterface>({
    name: '',
    medias: [],
    products: [],
  });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [sceneName, setSceneName] = useState('');
  const [createdSceneMessage, setCreatedSceneMessage] = useState('');
  const [image, setImage] = useState(null);
  const intl = useIntl();
  
  const handleAddNewImage = (image: MediaDTO) => {
    setImages([...images, image]);
  }
  
  const handleRemoveImage = (image: MediaDTO) => {
    const newImages = images.filter(i => i.id !== image.id);
    deleteMedia(image.id);
    setImages(newImages);
  }
  
  const toggleProductSelection = (productId) => {
    const isAlreadySelected = selectedProducts.includes(productId);
    if (isAlreadySelected) {
      setSelectedProducts(selectedProducts.filter(id => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };
  
  const handleCreateScene = () => {
    createScene(payload).then((res) => {
      if (res) {
        setCreatedSceneMessage(intl.formatMessage({ id: 'community.create.success' }));
        if (res.medias.length > 0) {
          setImage(res.medias[0].url);
        }
      } else {
        setCreatedSceneMessage(intl.formatMessage({ id: 'community.create.error' }))
      }
      setStep(4);
    });
  }
  
  useEffect(() => {
    setPayload({
      ...payload,
      medias: images.map(i => ({ id: i.id })),
      products: selectedProducts.map(p => ({ id: p })),
      name: sceneName,
    });
  }, [images, selectedProducts, sceneName]);
  
  useEffect(() => {
    if (defaultGallery.length > 0) {
      setFirstGalleryMedia(defaultGallery[0]);
    }
  }, [defaultGallery])
  
  return (
    <div className={styles.container}>
      {step === 1 && (
        <StartComponent setStep={setStep} firstGalleryMedia={firstGalleryMedia} />
      )}
      {step === 2 && (
        <UploadComponent
          handleAddNewImage={handleAddNewImage}
          handleRemoveImage={handleRemoveImage}
          images={images}
          setStep={setStep}
        />
      )}
      {step === 3 && (
        <ProductsComponent
          selectedProducts={selectedProducts}
          toggleProductSelection={toggleProductSelection}
          thumbnail={images[0]}
          setStep={setStep}
          handleCreateScene={handleCreateScene}
          setSceneName={setSceneName}
          sceneName={sceneName}
        />
      )}
      {step === 4 && (
        <StatusComponent
          message={createdSceneMessage}
          image={image}
        />
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createScene: (payload: PayloadInterface) => dispatch(communityScene.createScene(payload)),
})

export default injectIntl(
  connect(
    undefined,
    mapDispatchToProps
  )(CreateScene)
)