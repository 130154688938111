"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var objectProductType_1 = require("../enum/objectProductType");

var class_transformer_1 = require("class-transformer");

var objectProductData3D_1 = require("./objectProductData3D");

var ObjectProductDTO = function ObjectProductDTO() {
  _classCallCheck(this, ObjectProductDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsEnum(objectProductType_1.ObjectProductType), __metadata("design:type", String)], ObjectProductDTO.prototype, "type", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDTO.prototype, "width", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDTO.prototype, "height", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDTO.prototype, "depth", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDTO.prototype, "seatHeight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDTO.prototype, "diameter", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDTO.prototype, "weight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsUUID(undefined, {
  each: true
}), __metadata("design:type", Array)], ObjectProductDTO.prototype, "categoryIds", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return objectProductData3D_1.ObjectProductData3DDTO;
}), __metadata("design:type", objectProductData3D_1.ObjectProductData3DDTO)], ObjectProductDTO.prototype, "data3D", void 0);

exports.ObjectProductDTO = ObjectProductDTO;

var ObjectProductCreationDTO = function ObjectProductCreationDTO() {
  _classCallCheck(this, ObjectProductCreationDTO);
};

__decorate([class_validator_1.IsEnum(objectProductType_1.ObjectProductType), __metadata("design:type", String)], ObjectProductCreationDTO.prototype, "type", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductCreationDTO.prototype, "width", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductCreationDTO.prototype, "height", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductCreationDTO.prototype, "depth", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductCreationDTO.prototype, "seatHeight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductCreationDTO.prototype, "diameter", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductCreationDTO.prototype, "weight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsArray(), class_validator_1.IsUUID(undefined, {
  each: true
}), __metadata("design:type", Array)], ObjectProductCreationDTO.prototype, "categoryIds", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return objectProductData3D_1.ObjectProductData3DUpdateDTO;
}), __metadata("design:type", objectProductData3D_1.ObjectProductData3DUpdateDTO)], ObjectProductCreationDTO.prototype, "data3D", void 0);

exports.ObjectProductCreationDTO = ObjectProductCreationDTO;

var ObjectProductUpdateDTO = function ObjectProductUpdateDTO() {
  _classCallCheck(this, ObjectProductUpdateDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsEnum(objectProductType_1.ObjectProductType), __metadata("design:type", String)], ObjectProductUpdateDTO.prototype, "type", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductUpdateDTO.prototype, "width", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductUpdateDTO.prototype, "height", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductUpdateDTO.prototype, "depth", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductUpdateDTO.prototype, "seatHeight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductUpdateDTO.prototype, "diameter", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductUpdateDTO.prototype, "weight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsArray(), class_validator_1.IsUUID(undefined, {
  each: true
}), __metadata("design:type", Array)], ObjectProductUpdateDTO.prototype, "categoryIds", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return objectProductData3D_1.ObjectProductData3DUpdateDTO;
}), __metadata("design:type", objectProductData3D_1.ObjectProductData3DUpdateDTO)], ObjectProductUpdateDTO.prototype, "data3D", void 0);

exports.ObjectProductUpdateDTO = ObjectProductUpdateDTO;