"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var AccountType;

(function (AccountType) {
  AccountType["Architect"] = "architect";
  AccountType["AddsomeUser"] = "addsomeUser";
  AccountType["BrandUser"] = "brandUser";
})(AccountType = exports.AccountType || (exports.AccountType = {}));

var Apps;

(function (Apps) {
  Apps["Backoffice"] = "backoffice";
  Apps["Studio"] = "studio";
  Apps["Webapp"] = "webapp";
})(Apps = exports.Apps || (exports.Apps = {}));

var AuthDTO = function AuthDTO() {
  _classCallCheck(this, AuthDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], AuthDTO.prototype, "accountId", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], AuthDTO.prototype, "token", void 0);

__decorate([class_validator_1.IsEnum(AccountType), __metadata("design:type", String)], AuthDTO.prototype, "type", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], AuthDTO.prototype, "typeId", void 0);

__decorate([class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], AuthDTO.prototype, "enabled", void 0);

exports.AuthDTO = AuthDTO;

var LoginDTO = function LoginDTO() {
  _classCallCheck(this, LoginDTO);
};

__decorate([class_validator_1.IsEmail(), __metadata("design:type", String)], LoginDTO.prototype, "email", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], LoginDTO.prototype, "password", void 0);

__decorate([class_validator_1.IsEnum(Apps), class_validator_1.IsOptional(), __metadata("design:type", String)], LoginDTO.prototype, "source", void 0);

exports.LoginDTO = LoginDTO;

var PasswordResetQueryDTO = function PasswordResetQueryDTO() {
  _classCallCheck(this, PasswordResetQueryDTO);
};

__decorate([class_validator_1.IsEmail(), __metadata("design:type", String)], PasswordResetQueryDTO.prototype, "email", void 0);

__decorate([class_validator_1.IsEnum(Apps), __metadata("design:type", String)], PasswordResetQueryDTO.prototype, "for", void 0);

exports.PasswordResetQueryDTO = PasswordResetQueryDTO;

var PasswordResetDTO = function PasswordResetDTO() {
  _classCallCheck(this, PasswordResetDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], PasswordResetDTO.prototype, "newPassword", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], PasswordResetDTO.prototype, "token", void 0);

exports.PasswordResetDTO = PasswordResetDTO;

var ValidateEmailQueryDTO = function ValidateEmailQueryDTO() {
  _classCallCheck(this, ValidateEmailQueryDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ValidateEmailQueryDTO.prototype, "token", void 0);

__decorate([class_validator_1.IsEnum(Apps), __metadata("design:type", String)], ValidateEmailQueryDTO.prototype, "source", void 0);

__decorate([class_validator_1.IsString(), class_validator_1.IsOptional(), __metadata("design:type", String)], ValidateEmailQueryDTO.prototype, "brandInvitationToken", void 0);

exports.ValidateEmailQueryDTO = ValidateEmailQueryDTO;

var ValidateEmailDTO = function ValidateEmailDTO() {
  _classCallCheck(this, ValidateEmailDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ValidateEmailDTO.prototype, "email", void 0);

exports.ValidateEmailDTO = ValidateEmailDTO;