"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setInvitationBrand = exports.unsetGuestInviteToken = exports.setGuestInviteToken = exports.setBrandInvitationNotificationState = exports.setDisplayBrandInviteNotification = exports.setBrandInvitation = void 0;

var model_1 = require("./model");

var setBrandInvitation = function setBrandInvitation(invitation) {
  return {
    type: model_1.ActionsTypes.SET_BRAND_INVITATION,
    invitation: invitation
  };
};

exports.setBrandInvitation = setBrandInvitation;

var setDisplayBrandInviteNotification = function setDisplayBrandInviteNotification(display) {
  return {
    type: model_1.ActionsTypes.SET_DISPLAY_BRAND_INVITATION_NOTIFICATION,
    display: display
  };
};

exports.setDisplayBrandInviteNotification = setDisplayBrandInviteNotification;

var setBrandInvitationNotificationState = function setBrandInvitationNotificationState(notificationState) {
  return {
    type: model_1.ActionsTypes.SET_BRAND_INVITATION_NOTIFICATION_STATE,
    notificationState: notificationState
  };
};

exports.setBrandInvitationNotificationState = setBrandInvitationNotificationState;

var setGuestInviteToken = function setGuestInviteToken(token) {
  return {
    type: model_1.ActionsTypes.SET_GUESTS_INVITATION_TOKEN,
    token: token
  };
};

exports.setGuestInviteToken = setGuestInviteToken;

var unsetGuestInviteToken = function unsetGuestInviteToken() {
  return {
    type: model_1.ActionsTypes.UNSET_GUESTS_INVITATION_TOKEN
  };
};

exports.unsetGuestInviteToken = unsetGuestInviteToken;

var setInvitationBrand = function setInvitationBrand(brand) {
  return {
    type: model_1.ActionsTypes.SET_INVITATION_BRAND,
    brand: brand
  };
};

exports.setInvitationBrand = setInvitationBrand;