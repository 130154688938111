import React, { useEffect } from 'react'
import { Theme, FilterType } from '@addsome/ui-kit'
import { IFilter, IFilterButton } from '@addsome/ui-kit'
import useFilters from './useFilters'
import { FiltersContainer } from '@addsome/ui-kit'

const FILTERS = [
  FilterType.BRAND,
  FilterType.COLOR,
  FilterType.EMPTY,
  FilterType.MATERIAL,
  FilterType.OBJECT,
  FilterType.ORIGIN,
  FilterType.PRICE,
  FilterType.ROOM,
  FilterType.STYLE,
  FilterType.HAVE3D,
  FilterType.DIMENSIONS
]

interface IProps {
  reinitLabel: string | React.ReactElement
  className?: string
  buttonsContainerClassName?: string
  tagsContainerClassName?: string
  buttonTheme?: Theme,
  searchValue?: string
  onFiltersChange: (filters) => void,
  shouldResetFilters?: boolean
}

const FiltersContainerCatalog: React.FC<IProps> = ({
  reinitLabel,
  className,
  buttonsContainerClassName,
  tagsContainerClassName,
  buttonTheme = Theme.BLACK,
  searchValue,
  onFiltersChange
}) => {
  const {
    filterButtons,
    filters,
    visibleButtonsIds,
    filtersForAPI,
    onResetFilters,
    onCloseFilter,
    onUpdatePopoverVisible,
    loading,
    closeFilter
  } = useFilters(FILTERS, searchValue)

  useEffect(() => {
    if (!(filtersForAPI == undefined || Object.keys(filtersForAPI).length == 0)) {
      onFiltersChange(filtersForAPI)
    }
  }, [filtersForAPI])

  return (
    <FiltersContainer
      loading={loading}
      filterButtons={filterButtons}
      filters={filters}
      visibleButtonsIds={visibleButtonsIds}
      onCloseFilter={onCloseFilter}
      onResetFilters={onResetFilters}
      onUpdatePopoverVisible={onUpdatePopoverVisible}
      reinitLabel={reinitLabel}
      buttonsContainerClassName={buttonsContainerClassName}
      tagsContainerClassName={tagsContainerClassName}
      closeFilter={closeFilter}
    />
  )
}

export default FiltersContainerCatalog
