import React, { useState } from 'react'
import styles from './UploadComponent.module.scss'
import UploadMedia, { UploadType } from '../../../../components/UploadMedia/UploadMedia';
import { mediaUploadUrlWithQuery } from '../../../../services/media'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../../redux'
import { getCloudImageUrl } from '../../../../utils/cloudImage'
import { Button, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
const UploadComponent = ({handleAddNewImage, handleRemoveImage, images, setStep}) => {
  
  const intl = useIntl();
  const [showMinimumUploadMessage, setShowMinimumUploadMessage] = useState(false);
  
  const afterUpload = (response, originalName) => {
    handleAddNewImage(response);
  }
  
  const { token} = useSelector((state: IRootState) => ({
    token: state.authState.token,
  }))
  
  const handleNext =  () => {
    if (images.length > 0) {
      setStep(3);
    } else {
      setShowMinimumUploadMessage(true);
      setTimeout(() => setShowMinimumUploadMessage(false), 3000);
    }
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.content}>
          <h3>
            {intl.formatMessage({ id: 'community.upload.title' })}
          </h3>
          <span>
            {intl.formatMessage({ id: 'community.upload.description' })}
          </span>
          {showMinimumUploadMessage && (
            <span className={styles.minimumUploadMessage}>
              {intl.formatMessage({ id: 'community.upload.error' })}
            </span>
          )}
        </div>
        <div>
          <Button className={styles.uploadButton} onClick={() => {handleNext()}}  theme={Theme.BLACK}>
            {intl.formatMessage({ id: 'community.products.next' })}  <Icon type="arrow-right" />
          </Button>
        </div>
      </div>
      <div className={styles.uploadContainer}>
        {images.map((picture, index) => (
          <div key={picture.id} className={styles.imageContainer}>
            <div
              className={styles.croppedImage}
              style={{ backgroundImage: `url(${getCloudImageUrl(picture.url, 200)})` }}
              alt={""}
            />
            <Icon className={styles.deleteButton} type="delete" onClick={() => {handleRemoveImage(picture)}} />
          </div>
        ))}
        {images.length < 6 && (
          <UploadMedia
            type={UploadType.SQUARE}
            action={mediaUploadUrlWithQuery('?type=community')}
            afterUpload={(response, originalName) => { afterUpload(response, originalName) }}
            token={token}
            className={styles.uploadComponent}
            title={intl.formatMessage({ id: 'community.upload.button.title' })}
            accept={".png,.jpg"}
          >
            <span> {intl.formatMessage({ id: 'community.upload.button.description' })}</span>
          </UploadMedia>
        )}
      </div>
    </div>
  );
}

export default UploadComponent;