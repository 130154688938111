"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var BrandDriveFolderCreationDTO = function BrandDriveFolderCreationDTO() {
  _classCallCheck(this, BrandDriveFolderCreationDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], BrandDriveFolderCreationDTO.prototype, "path", void 0);

exports.BrandDriveFolderCreationDTO = BrandDriveFolderCreationDTO;

var BrandDriveFile = function BrandDriveFile() {
  _classCallCheck(this, BrandDriveFile);
};

exports.BrandDriveFile = BrandDriveFile;

var BrandDriveMoveDTO = function BrandDriveMoveDTO() {
  _classCallCheck(this, BrandDriveMoveDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], BrandDriveMoveDTO.prototype, "dest", void 0);

exports.BrandDriveMoveDTO = BrandDriveMoveDTO;

var BrandDriveDTO = function BrandDriveDTO() {
  _classCallCheck(this, BrandDriveDTO);
};

__decorate([class_validator_1.IsString({
  each: true
}), __metadata("design:type", Array)], BrandDriveDTO.prototype, "folders", void 0);

__decorate([class_transformer_1.Type(function () {
  return BrandDriveFile;
}), class_validator_1.ValidateNested({
  each: true
}), __metadata("design:type", Array)], BrandDriveDTO.prototype, "files", void 0);

exports.BrandDriveDTO = BrandDriveDTO;