import { ArchitectDTO } from '@addsome/dtos'
import { auth } from '@addsome/redux-store'
import * as Sentry from '@sentry/browser'
import { Icon } from 'antd'
import { stringify } from 'querystring'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../redux'
import { IRedirection } from '../utils/interfaces'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import style from './PrivateRoute.module.scss'
import AppSocket from '../utils/socket'
import { AccountType, ArchitectSubscriptionType } from '@addsome/dtos/dist'
import { checkMonthlySubscriptionPayment, setTrialSubscription } from '../services/payment'
import NotFound from '../pages/NotFoundPage/NotFound'
import PublicHeader from '../components/PublicHeader/PublicHeader'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps

/**
 * Route that redirect to login page if user is not connected
 */
const PrivateRoute: React.FC<IProps> = ({
  token,
  user,
  accountType,
  getAuth,
  location,
  hasLoggedIn,
  setShouldShowNotEnabledPopup,
  ...rest
}) => {
  const [userIdentified, setUserIdentified] = useState<string>()

  useEffect(() => {
    if (!accountType) getAuth()
  }, [accountType, getAuth])

  const checkMonthSubscriptionOverdue = async (user) => {
    let date = new Date((user as ArchitectDTO).subscriptionStartDate!.toString())
    let oneMonthDiffDate = new Date(date)
    if (user.subscription.slug == ArchitectSubscriptionType.Standard10) {
      oneMonthDiffDate.setDate(date.getDate() + 30)
      if (new Date().getTime() > oneMonthDiffDate.getTime()) {
        await checkMonthlySubscriptionPayment(user.account.email, user.id, (user as ArchitectDTO).subscription!.slug)
        getAuth()
      }
    } else {
      oneMonthDiffDate.setMonth(date.getMonth() + 1)
      if (new Date().getTime() > oneMonthDiffDate.getTime()) {
        await checkMonthlySubscriptionPayment(user.account.email, user.id, (user as ArchitectDTO).subscription!.slug)
        getAuth()
      }
    }
  }

  useEffect(() => {
    if (token && accountType && user) {
      if (accountType === AccountType.Architect &&
        (user as ArchitectDTO).subscriptionStartDate &&
        (user as ArchitectDTO).subscription) {
        checkMonthSubscriptionOverdue(user)
      }
    }
  }, [token, accountType, user])

  if (token && accountType && user) {
    if (!userIdentified || user.id !== userIdentified) {
      Sentry.configureScope(scope => {
        scope.setUser({
          email: user.account.email,
          id: user.id,
          accountId: user.account.id,
          accountType
        })
      })

      AppSocket.login(token)

      // Mixpanel.identify(user.account.email)
      // Mixpanel.people.set({
      //   $email: user.account.email,
      //   $firstName: user.account.firstName,
      //   $lastName: user.account.lastName,
      //   type: accountType
      // })

      // Mixpanel event after loggin
      // if (hasLoggedIn) {
      //   Mixpanel.track(MixpanelEvents.UserLoggedIn)
      // }

      // Mixpanel.track(MixpanelEvents.UserConnected)

      if (hasLoggedIn && accountType === AccountType.Architect && !user.account.enabled) {
        setShouldShowNotEnabledPopup(true)
      }
      setUserIdentified(user.id)
    }

    return <Route {...rest} />
  }
  else if (!token) {
    AppSocket.logout()

    const redirection: IRedirection = {
      from: location.pathname,
      searchFrom: location.search
    }
    return (
      <>
        <PublicHeader darkTheme={false} />
        <NotFound />
      </>
    )
  } else {
    return (
      <div className={style.loading}>
        <Icon type="loading" />
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user,
  token: state.authState.token,
  hasLoggedIn: state.authState.hasLoggedIn,
  accountType: state.authState.type,
  location: state.router.location
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  getAuth: () => dispatch(auth.getAuth()),
  setShouldShowNotEnabledPopup: (shouldShowNotEnabledPopup: boolean) =>
    dispatch(auth.setShouldShowArchitectFirstConnectionPopup(shouldShowNotEnabledPopup))
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
