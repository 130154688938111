"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  products: []
};

var actionHandlers = _defineProperty({}, model_1.ActionsTypes.SET_PRODUCTS_VARIATIONS, function (state, _ref) {
  var products = _ref.products;
  return Object.assign(Object.assign({}, state), {
    products: products
  });
});

exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);