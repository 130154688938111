"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNotifications = exports.thunks = exports.reducer = void 0;

var crud_1 = require("./crud");

var notification_1 = require("./notification");

var KEY = 'PROJECT';
exports.reducer = (0, crud_1.default)(KEY);
exports.thunks = new crud_1.ThunkHandler({
  url: '/projects',
  key: KEY
});

function createNotifications(dispatch, message) {
  dispatch((0, notification_1.addLabels)(KEY, {
    fetchValues: {
      error: message.errorRetrievingProjects
    },
    fetchValue: {
      error: message.errorRetrievingAProject
    },
    create: {
      success: message.addedProject,
      error: message.errorCreatingProject
    },
    patch: {
      success: message.updatedProject,
      error: message.errorRegisteringProject
    },
    delete: {
      success: message.removedProject,
      error: message.errorDeletingProject
    }
  }));
}

exports.createNotifications = createNotifications;