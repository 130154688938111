"use strict";

var _toConsumableArray = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getColorTagFromId = exports.getMaterialTagFromId = exports.getRoomTagFromId = exports.getStyleTagFromId = exports.getObjectProductCategoryFromId = exports.getBrandSuggestionFromId = void 0;

var getBrandSuggestionFromId = function getBrandSuggestionFromId(brandId, state) {
  if (!state.brandTagState.brandLexique) return undefined;
  var lexique = Object.assign({}, state.brandTagState.brandLexique);
  var brands = Object.keys(lexique).reduce(function (acc, key) {
    return [].concat(_toConsumableArray(acc), _toConsumableArray(lexique[key]));
  }, []);
  return brands.find(function (brand) {
    return brand.id === brandId;
  });
};

exports.getBrandSuggestionFromId = getBrandSuggestionFromId;

var getObjectProductCategoryFromId = function getObjectProductCategoryFromId(objectProductCategoryId, state) {
  return state.objectProductCategoriesState.values.reduce(function (acc, objectProductCategory) {
    return [].concat(_toConsumableArray(acc), _toConsumableArray(objectProductCategory.childs));
  }, []).find(function (objectProductCategory) {
    return objectProductCategory.id === objectProductCategoryId;
  });
};

exports.getObjectProductCategoryFromId = getObjectProductCategoryFromId;

var getStyleTagFromId = function getStyleTagFromId(styleTagId, state) {
  return state.styleTagState.values.find(function (styleTag) {
    return styleTag.id === styleTagId;
  });
};

exports.getStyleTagFromId = getStyleTagFromId;

var getRoomTagFromId = function getRoomTagFromId(roomTagId, state) {
  return state.roomTagState.values.find(function (roomTag) {
    return roomTag.id === roomTagId;
  });
};

exports.getRoomTagFromId = getRoomTagFromId;

var getMaterialTagFromId = function getMaterialTagFromId(materialTagId, state) {
  return state.productMaterialTagState.values.reduce(function (acc, material) {
    return [].concat(_toConsumableArray(acc), _toConsumableArray(material.childs));
  }, []).find(function (m) {
    return m.id === materialTagId;
  });
};

exports.getMaterialTagFromId = getMaterialTagFromId;

var getColorTagFromId = function getColorTagFromId(colorTagId, state) {
  return state.colorTagState.values.find(function (c) {
    return c.id === colorTagId;
  });
};

exports.getColorTagFromId = getColorTagFromId;