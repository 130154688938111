import {IRootState} from "../../redux";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {push} from "connected-react-router";
import { auth } from '@addsome/redux-store'
import {connect} from "react-redux";
import React, {useEffect} from "react";
import Catalog from "./Catalog";
import {AccountType} from "@addsome/dtos";
import Layout from '../../templates/Layout/Layout'
import PublicCatalog from "./PublicCatalog";
import ProductViewerModal from "../../components/Player/ProductViewModal/ProductViewerModal";
import {Loading} from "@addsome/ui-kit";

export const CatalogRouter = ({accountType, getAuth, token}) => {
  useEffect(() => {
    if (!accountType) {
      getAuth()
    }
  }, [])

  if (accountType == AccountType.Architect || accountType == AccountType.AddsomeUser) {
    return (
      <Layout>
        <Catalog/>
      </Layout>
    )
  }

  if (!token) {
    return (
      <>
        <PublicCatalog></PublicCatalog>
        <ProductViewerModal />
      </>
    )
  }

  return () => <Loading></Loading>
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  token: state.authState.token,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  redirect: (location: string) => dispatch(push(location)),
  getAuth: () => dispatch(auth.getAuth()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogRouter)