import React from 'react'
import Icon from 'antd/lib/icon'
import { ProductLightExtendedDTO, ProductPictureType } from '@addsome/dtos/dist'
import { ICardData } from '@addsome/ui-kit/dist'
import { getResizedPreview } from './cloudImage'
import formatPrice from './formatPrice'

export enum Sides {
  'suggestedPrice',
  'view',
  'download'
}
interface IOptions {
  side?: Sides
}

const defaultOptions = {
  side: Sides.suggestedPrice
}

const productToCardData = (
  product: ProductLightExtendedDTO,
  options: IOptions = defaultOptions
): ICardData => {
  let side
  if (options.side === Sides.suggestedPrice) {
    side = formatPrice(product.suggestedPrice)
  } else if (options.side === Sides.view) {
    side = (
      <span>
        <Icon type="eye" /> {product.view}
      </span>
    )
  } else if (options.side === Sides.download) {
    side = (
      <span>
        <Icon type="download" /> {product.download}
      </span>
    )
  }

  return {
    id: product.id,
    title: product.brand ? product.brand.name : '',
    desc: `${product.name} ${product.details || ''}`,
    side,
    image:
      product.thumbnail &&
      product.thumbnail.media &&
      product.thumbnail.media.url &&
      getResizedPreview(385, 256, product.thumbnail.media.url),
    imgFit:
      (product.thumbnail &&
        product.thumbnail.media &&
        product.thumbnail.type !== ProductPictureType.Ambiance) ||
      undefined,
    imgBgColor:
      (product.thumbnail && product.thumbnail.media && product.thumbnail.hexaColor) || undefined
  }
}

export default productToCardData
