"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCustomCamera = exports.setProductSettingsCameraIds = exports.setProductSettingsVariationIds = exports.setLoading = exports.addPackshots = exports.setPackshots = exports.reset = exports.unsetRenderSettings = exports.removeRenderSettings = exports.setRenderSettings = exports.setIsAdd = exports.setProductsSettings = exports.setSelectedProducts = void 0;

var model_1 = require("./model");

var setSelectedProducts = function setSelectedProducts(selectedProducts) {
  return {
    type: model_1.ActionsTypes.SET_SELECTED_PRODUCTS,
    selectedProducts: selectedProducts
  };
};

exports.setSelectedProducts = setSelectedProducts;

var setProductsSettings = function setProductsSettings(productsSettings) {
  return {
    type: model_1.ActionsTypes.SET_PRODUCTS_SETTINGS,
    productsSettings: productsSettings
  };
};

exports.setProductsSettings = setProductsSettings;

var setIsAdd = function setIsAdd(isAdd) {
  return {
    type: model_1.ActionsTypes.SET_IS_ADD,
    isAdd: isAdd
  };
};

exports.setIsAdd = setIsAdd;

var setRenderSettings = function setRenderSettings(renderSettings) {
  return {
    type: model_1.ActionsTypes.SET_RENDER_SETTINGS,
    renderSettings: renderSettings
  };
};

exports.setRenderSettings = setRenderSettings;

var removeRenderSettings = function removeRenderSettings(id) {
  return {
    type: model_1.ActionsTypes.REMOVE_RENDER_SETTINGS,
    id: id
  };
};

exports.removeRenderSettings = removeRenderSettings;

var unsetRenderSettings = function unsetRenderSettings() {
  return {
    type: model_1.ActionsTypes.UNSET_RENDER_SETTINGS
  };
};

exports.unsetRenderSettings = unsetRenderSettings;

var reset = function reset() {
  return {
    type: model_1.ActionsTypes.RESET
  };
};

exports.reset = reset;

var setPackshots = function setPackshots(packshots, total) {
  return {
    type: model_1.ActionsTypes.SET_PACKSHOTS,
    packshots: packshots,
    total: total
  };
};

exports.setPackshots = setPackshots;

var addPackshots = function addPackshots(packshots, total) {
  return {
    type: model_1.ActionsTypes.ADD_PACKSHOTS,
    packshots: packshots,
    total: total
  };
};

exports.addPackshots = addPackshots;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_PACKSHOT_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;

var setProductSettingsVariationIds = function setProductSettingsVariationIds(productId, variationIds) {
  return {
    type: model_1.ActionsTypes.SET_PRODUCTS_SETTINGS_VARIATION_IDS,
    productId: productId,
    variationIds: variationIds
  };
};

exports.setProductSettingsVariationIds = setProductSettingsVariationIds;

var setProductSettingsCameraIds = function setProductSettingsCameraIds(productId, cameraIds) {
  return {
    type: model_1.ActionsTypes.SET_PRODUCTS_SETTINGS_CAMERA_IDS,
    productId: productId,
    cameraIds: cameraIds
  };
};

exports.setProductSettingsCameraIds = setProductSettingsCameraIds;

var setCustomCamera = function setCustomCamera(camera) {
  return {
    type: model_1.ActionsTypes.SET_CUSTOM_CAMERA,
    camera: camera
  };
};

exports.setCustomCamera = setCustomCamera;