"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var BrandUserRole;

(function (BrandUserRole) {
  BrandUserRole["Admin"] = "admin";
  BrandUserRole["Normal"] = "normal";
})(BrandUserRole = exports.BrandUserRole || (exports.BrandUserRole = {}));