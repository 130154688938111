"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var product_1 = require("./product");

var vector3_1 = require("./vector3");

var materialMapping_1 = require("./materialMapping");

var materialList_1 = require("./materialList");

var ObjectProductData3DDTO = function ObjectProductData3DDTO() {
  _classCallCheck(this, ObjectProductData3DDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductData3DDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DDTO.prototype, "canBeLoadedInStudio", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DDTO.prototype, "studioVersion", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsDate(), __metadata("design:type", Date)], ObjectProductData3DDTO.prototype, "studioUpdatedAt", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsJSON(), __metadata("design:type", Object)], ObjectProductData3DDTO.prototype, "bounds", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DDTO.prototype, "meshCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DDTO.prototype, "polyCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DDTO.prototype, "sourcePolyCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DDTO.prototype, "triangleCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DDTO.prototype, "vertexCount", void 0);

__decorate([class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DDTO.prototype, "generatedFromImage", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DDTO.prototype, "anchor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DDTO.prototype, "correctionAnchor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductData3DDTO.prototype, "correctionPosition", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductData3DDTO.prototype, "correctionRotation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return product_1.ProductCorrectionScale;
}), __metadata("design:type", product_1.ProductCorrectionScale)], ObjectProductData3DDTO.prototype, "correctionScale", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DDTO.prototype, "isCorrectionApplied", void 0);

__decorate([class_validator_1.IsOptional(), class_transformer_1.Type(function () {
  return materialMapping_1.MaterialMapping;
}), __metadata("design:type", materialMapping_1.MaterialMapping)], ObjectProductData3DDTO.prototype, "materialMapping", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsArray(), class_transformer_1.Type(function () {
  return materialList_1.MaterialList;
}), __metadata("design:type", Array)], ObjectProductData3DDTO.prototype, "materialList", void 0);

exports.ObjectProductData3DDTO = ObjectProductData3DDTO;

var ObjectProductData3DCreationDTO = function ObjectProductData3DCreationDTO() {
  _classCallCheck(this, ObjectProductData3DCreationDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DCreationDTO.prototype, "canBeLoadedInStudio", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DCreationDTO.prototype, "studioVersion", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsDate(), class_transformer_1.Type(function () {
  return Date;
}), __metadata("design:type", Date)], ObjectProductData3DCreationDTO.prototype, "studioUpdatedAt", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsJSON(), __metadata("design:type", Object)], ObjectProductData3DCreationDTO.prototype, "bounds", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DCreationDTO.prototype, "meshCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DCreationDTO.prototype, "polyCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DCreationDTO.prototype, "sourcePolyCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DCreationDTO.prototype, "triangleCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DCreationDTO.prototype, "vertexCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DCreationDTO.prototype, "generatedFromImage", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DCreationDTO.prototype, "anchor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DCreationDTO.prototype, "correctionAnchor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductData3DCreationDTO.prototype, "correctionPosition", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductData3DCreationDTO.prototype, "correctionRotation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return product_1.ProductCorrectionScale;
}), __metadata("design:type", product_1.ProductCorrectionScale)], ObjectProductData3DCreationDTO.prototype, "correctionScale", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DCreationDTO.prototype, "isCorrectionApplied", void 0);

__decorate([class_validator_1.IsOptional(), class_transformer_1.Type(function () {
  return materialMapping_1.MaterialMapping;
}), __metadata("design:type", materialMapping_1.MaterialMapping)], ObjectProductData3DCreationDTO.prototype, "materialMapping", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsArray(), class_transformer_1.Type(function () {
  return materialList_1.MaterialList;
}), __metadata("design:type", Array)], ObjectProductData3DCreationDTO.prototype, "materialList", void 0);

exports.ObjectProductData3DCreationDTO = ObjectProductData3DCreationDTO;

var ObjectProductData3DUpdateDTO = function ObjectProductData3DUpdateDTO() {
  _classCallCheck(this, ObjectProductData3DUpdateDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DUpdateDTO.prototype, "canBeLoadedInStudio", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DUpdateDTO.prototype, "studioVersion", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsDate(), class_transformer_1.Type(function () {
  return Date;
}), __metadata("design:type", Date)], ObjectProductData3DUpdateDTO.prototype, "studioUpdatedAt", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsJSON(), __metadata("design:type", Object)], ObjectProductData3DUpdateDTO.prototype, "bounds", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DUpdateDTO.prototype, "meshCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DUpdateDTO.prototype, "polyCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DUpdateDTO.prototype, "sourcePolyCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DUpdateDTO.prototype, "triangleCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductData3DUpdateDTO.prototype, "vertexCount", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DUpdateDTO.prototype, "generatedFromImage", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DUpdateDTO.prototype, "anchor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductData3DUpdateDTO.prototype, "correctionAnchor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductData3DUpdateDTO.prototype, "correctionPosition", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductData3DUpdateDTO.prototype, "correctionRotation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return product_1.ProductCorrectionScale;
}), __metadata("design:type", product_1.ProductCorrectionScale)], ObjectProductData3DUpdateDTO.prototype, "correctionScale", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductData3DUpdateDTO.prototype, "isCorrectionApplied", void 0);

__decorate([class_validator_1.IsOptional(), class_transformer_1.Type(function () {
  return materialMapping_1.MaterialMapping;
}), __metadata("design:type", materialMapping_1.MaterialMapping)], ObjectProductData3DUpdateDTO.prototype, "materialMapping", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsArray(), class_transformer_1.Type(function () {
  return materialList_1.MaterialList;
}), __metadata("design:type", Array)], ObjectProductData3DUpdateDTO.prototype, "materialList", void 0);

exports.ObjectProductData3DUpdateDTO = ObjectProductData3DUpdateDTO;