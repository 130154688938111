import { ObjectProductCreationDTO, ObjectProductDimensionDTO, ObjectProductUpdateDTO, ProductUpdateDTO } from '@addsome/dtos'
import { InputField, InputNumber } from '@addsome/ui-kit'
import { Form } from 'antd'
import { FormikProps } from 'formik'
import get from 'lodash.get'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface IProps {
  objectProduct: ObjectProductCreationDTO | ObjectProductUpdateDTO
  formik: FormikProps<ProductUpdateDTO | ObjectProductCreationDTO | ObjectProductUpdateDTO | ObjectProductDimensionDTO>
  prefix?: string
}

const ObjectProductFieldsGroup: React.FC<IProps> = ({ objectProduct, formik, prefix = '' }) => {
  return (
    <>
      <Form.Item label={<FormattedMessage id="productpage.panel.width" />}>
        <InputField error={get(formik.errors, `${prefix}width`)}>
          <InputNumber
            name={`${prefix}width`}
            defaultValue={objectProduct.width || undefined}
            onChange={value => formik.setFieldValue(`${prefix}width`, value)}
            precision={1}
          />
        </InputField>
      </Form.Item>
      <Form.Item label={<FormattedMessage id="productpage.panel.height" />}>
        <InputField error={get(formik.errors, `${prefix}height`)}>
          <InputNumber
            name={`${prefix}height`}
            defaultValue={objectProduct.height || undefined}
            onChange={value => formik.setFieldValue(`${prefix}height`, value)}
            precision={1}
          />
        </InputField>
      </Form.Item>
      <Form.Item label={<FormattedMessage id="productpage.panel.depth" />}>
        <InputField error={get(formik.errors, `${prefix}depth`)}>
          <InputNumber
            name={`${prefix}depth`}
            defaultValue={objectProduct.depth || undefined}
            onChange={value => formik.setFieldValue(`${prefix}depth`, value)}
            precision={1}
          />
        </InputField>
      </Form.Item>
      <Form.Item label={<FormattedMessage id="productpage.panel.seatHeight"/>}>
        <InputField error={get(formik.errors, `${prefix}seatHeight`)}>
          <InputNumber
            name={`${prefix}seatHeight`}
            defaultValue={objectProduct.seatHeight || undefined}
            onChange={value => formik.setFieldValue(`${prefix}seatHeight`, value)}
            precision={1}
          />
        </InputField>
      </Form.Item>
      <Form.Item label={<FormattedMessage id="productpage.panel.diameter" />}>
        <InputField error={get(formik.errors, `${prefix}diameter`)}>
          <InputNumber
            name={`${prefix}diameter`}
            defaultValue={objectProduct.diameter || undefined}
            onChange={value => formik.setFieldValue(`${prefix}diameter`, value)}
            precision={1}
          />
        </InputField>
      </Form.Item>
      <Form.Item label={<FormattedMessage id="productpage.panel.weight" />}>
        <InputField error={get(formik.errors, `${prefix}weight`)}>
          <InputNumber
            name={`${prefix}weight`}
            defaultValue={objectProduct.weight || undefined}
            onChange={value => formik.setFieldValue(`${prefix}weight`, value)}
            precision={3}
          />
        </InputField>
      </Form.Item>
    </>
  )
}

export default ObjectProductFieldsGroup
