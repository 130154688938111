import { ProductProblemDTO } from '@addsome/dtos'
import { productProblemType } from '@addsome/redux-store/dist'
import { PaginationConfig, SorterResult } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import EditableTable, { IColumnPropsEditable } from '../../EditableTable/EditableTable'
import { FormattedMessage, WrappedComponentProps } from 'react-intl'
import { Form } from 'antd'

class ProductProblemUpdateDTO extends ProductProblemDTO { }

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & WrappedComponentProps & {
    problems: ProductProblemDTO[]
    loading: boolean
    onDelete: (id: string) => void
    onEdit: (problem: ProductProblemUpdateDTO) => void
    onTableChange?: (
      pagination: PaginationConfig,
      filter: Record<keyof ProductProblemDTO, string[]>,
      sorter: SorterResult<ProductProblemDTO>
    ) => void
    sortBy?: { [key: string]: number }
    filters?: { [key: string]: string[] }
  }

class ProductProblemsTable extends React.Component<IProps> {
  private columns: Array<IColumnPropsEditable<ProductProblemDTO>> = []

  public async componentDidMount() {
    await this.props.fetchProductProblemTypes()
    this.columns = [
      {
        title: <FormattedMessage id='producttable.problemType' />,
        dataIndex: this.props.intl.locale == 'fr' ? 'type.nameFr' : 'type.name',
        editIndex: 'typeId',
        editValue: 'type.id',
        sorter: true,
        editable: true,
        inputType: 'select',
        selectOptions: {
          values: this.props.productProblemTypes,
          display: this.props.intl.locale == 'fr' ? 'nameFr' : 'name',
          model: 'id'
        }
      },
      {
        title: <FormattedMessage id='producttable.comment' />,
        dataIndex: 'comment',
        sorter: (a, b) => (a.comment > b.comment ? 1 : -1),
        editable: true
      },
      {
        title: <FormattedMessage id='producttable.account' />,
        dataIndex: 'account.email',
        editable: false
      },
      {
        title: <FormattedMessage id='producttable.creationDate' />,
        dataIndex: 'createdAt',
        render: (value: Date) => moment(value).format('DD/MM/YYYY'),
        sorter: (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
        editable: false
      },
      {
        title: <FormattedMessage id='producttable.resolved' />,
        dataIndex: 'resolved',
        render: value => (value ? 'Oui' : 'Non'),
        filters: [{ text: 'Oui', value: 'true' }, { text: 'Non', value: 'false' }],
        filterMultiple: false,
        onFilter: (value, record) => (value === 'true' ? record.resolved : !record.resolved),
        editable: true,
        inputType: 'boolean'
      }
    ]
  }

  public render() {
    const columns = [...this.columns]

    // Init sort and filters
    if (this.props.sortBy || this.props.filters) {
      const sortBy = this.props.sortBy
      const filters = this.props.filters
      columns.forEach(column => {
        if (sortBy) {
          const orderValue = column.key
            ? sortBy[column.key]
            : (column.dataIndex && sortBy[column.dataIndex]) || 0
          if (orderValue) {
            column.defaultSortOrder = orderValue > 0 ? 'ascend' : 'descend'
          }
        }

        if (filters) {
          column.filteredValue = column.key
            ? filters[column.key]
            : (column.dataIndex && filters[column.dataIndex]) || undefined
        }
      })
    }

    return (
      <EditableTable
        columns={columns}
        dataSource={this.props.problems}
        loading={this.props.loading}
        rowKey="id"
        onEdit={this.props.onEdit}
        onDelete={problem => this.props.onDelete(problem.id)}
        onChange={this.props.onTableChange}
        intl={this.props.intl}
      />
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  productProblemTypes: state.productProblemTypeState.values
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProductProblemTypes: () => dispatch(productProblemType.thunks.fetchValues())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductProblemsTable)
