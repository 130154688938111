"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_PROJECT_SCENE_TEMPLATES"] = "SET_PROJECT_SCENE_TEMPLATES";
  ActionsTypes["SET_PROJECT_SCENE_TEMPLATE_LOADING"] = "SET_PROJECT_SCENE_TEMPLATE_LOADING";
  ActionsTypes["ADD_PROJECT_SCENE_TEMPLATES"] = "ADD_PROJECT_SCENE_TEMPLATES";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));