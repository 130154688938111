"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPrices = void 0;

var model_1 = require("./model");

var setPrices = function setPrices(prices) {
  return {
    type: model_1.ActionsTypes.SET_PRICES,
    prices: prices
  };
};

exports.setPrices = setPrices;