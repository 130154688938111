"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

function IsSiret(validationOptions) {
  return function (object, propertyName) {
    class_validator_1.registerDecorator({
      name: 'isSiret',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate: function validate(value, args) {
          return true;
        },
        defaultMessage: function defaultMessage() {
          return '($value) should be a valid siret';
        }
      }
    });
  };
}

exports.IsSiret = IsSiret;