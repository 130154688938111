"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  objectProductVariations: [],
  loading: true
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_OBJECT_PRODUCT_VARIATIONS, function (state, _ref) {
  var objectProductVariations = _ref.objectProductVariations;
  return Object.assign(Object.assign({}, state), {
    objectProductVariations: objectProductVariations
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_OBJECT_PRODUCT_VARIATIONS_LOADING, function (state, _ref2) {
  var loading = _ref2.loading;
  return Object.assign(Object.assign({}, state), {
    loading: loading
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);