import { ObjectProductCreationDTO, ObjectProductDTO, ObjectProductUpdateDTO } from '@addsome/dtos'

export function convertObjectProductForFront<
  T extends ObjectProductDTO | ObjectProductUpdateDTO | ObjectProductCreationDTO
>(objectProduct: T): T {
  return {
    ...objectProduct,
    width: objectProduct.width && objectProduct.width / 10,
    height: objectProduct.height && objectProduct.height / 10,
    depth: objectProduct.depth && objectProduct.depth / 10,
    seatHeight: objectProduct.seatHeight && objectProduct.seatHeight / 10,
    diameter: objectProduct.diameter && objectProduct.diameter / 10,
    weight: objectProduct.weight && objectProduct.weight / 1000
  }
}

export function convertObjectProductForAPI<
  T extends ObjectProductCreationDTO | ObjectProductUpdateDTO
>(objectProduct: T): T {
  return {
    ...objectProduct,
    width: objectProduct.width && Math.round(objectProduct.width * 10),
    height: objectProduct.height && Math.round(objectProduct.height * 10),
    depth: objectProduct.depth && Math.round(objectProduct.depth * 10),
    seatHeight: objectProduct.seatHeight && Math.round(objectProduct.seatHeight * 10),
    diameter: objectProduct.diameter && Math.round(objectProduct.diameter * 10),
    weight: objectProduct.weight && Math.round(objectProduct.weight * 1000)
  }
}
