import { BrandDTO, AccountType } from '@addsome/dtos'
import { Container } from '@addsome/ui-kit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getCountryFromCode } from '../../../services/mapbox'
import styles from './BrandInformationPanel.module.scss'
import { IRootState } from '../../../redux'
import { connect } from 'react-redux'

type IProps = ReturnType<typeof mapStateToProps> & {
  brand: BrandDTO
}

interface IState {
  countryName: string
}

class BrandInformationPanel extends React.Component<IProps, IState> {
  public state: IState = {
    countryName: ''
  }

  public async componentDidMount() {
    if (this.props.brand.madeIn) {
      const country = await getCountryFromCode(this.props.brand.madeIn)
      this.setState({ countryName: country.label })
    }
  }

  public render() {
    const { brand, accountType } = this.props
    return (
      <div className={styles.brandInformationPanel}>
        <Container className={styles.data}>
          <div className={styles.title}>
            <strong>{brand.name}</strong>
          </div>
          <dl>
            {accountType === AccountType.AddsomeUser && (
              <div>
                <dt>
                  <FormattedMessage id="brandpage.panel.alternativeNames" />
                </dt>
                <dd>
                  {brand.alternativeNames.length > 0 ? (
                    brand.alternativeNames.join(', ')
                  ) : (
                    <FormattedMessage id="global.nodata" />
                  )}
                </dd>
              </div>
            )}

            <dt>
              <FormattedMessage id="brandpage.panel.description" />
            </dt>
            <dd>{brand.description || <FormattedMessage id="global.nodata" />}</dd>

            <dt>
              <FormattedMessage id="brandpage.panel.websiteUrl" />
            </dt>
            <dd>
              {brand.websiteUrl ? (
                <a href={brand.websiteUrl} target="_blank">
                  {brand.websiteUrl}
                </a>
              ) : (
                <FormattedMessage id="global.nodata" />
              )}
            </dd>

            <dt>
              <FormattedMessage id="brandpage.panel.madeIn" />
            </dt>
            <dd>{this.state.countryName || <FormattedMessage id="global.nodata" />}</dd>
          </dl>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type
})

export default connect(mapStateToProps)(BrandInformationPanel)
