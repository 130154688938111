import styles from "./PageHeaderArchitectContent.module.scss";
import {Button, FilterButton, FilterList} from "@addsome/ui-kit";
import {Link} from "react-router-dom";
import Routes from "../../utils/routes";
import React, {ReactElement, useEffect, useState} from "react";
import useLoggedUser from "../../hooks/useUser";
import {useIntl} from "react-intl";
import usePush from "../../hooks/usePush";
import favorites from "../../assets/images/favorite.png";
import {Icon} from "antd";
import {getTotalDownloads} from "../../services/productDownload";

const PageHeaderArchitectContent = () => {
  const { user, profilePicture } = useLoggedUser();
  const intl = useIntl();
  const { push, router } = usePush();
  const storedTotalDownloads = sessionStorage.getItem('totalDownloads');
  const [totalDownloads, setTotalDownloads] = useState(storedTotalDownloads ? parseInt(storedTotalDownloads) : 0);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const filters: string[] = ['Last Added', 'First Added'];
  const filterContent: ReactElement = (
    <div>
      {filters.map((filter: string) => (
        <p key={filter} onClick={() => handleFilterClick(filter)}>{filter}</p>
      ))}
    </div>
  );


  const handleVisibleChange = (id: number, visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
    setPopoverVisible(false); // close the popover after a filter is selected
  };
  async function getDownloads() {
    try {
      const downloads =  await getTotalDownloads(user!.id);
      setTotalDownloads(downloads.totalDownloads);
      sessionStorage.setItem('totalDownloads', totalDownloads.toString());
    } catch (err) {
      return 0;
    }
  }

  useEffect(() => {
    getDownloads();
  }, [totalDownloads])

  return (
    <div className={styles.header}>
      <img src={profilePicture} className={styles.profilePicture} />
      {user && user.account && (
        <>
          <h2>{user.account.firstName} {user.account.lastName}</h2>
          <p>{user.account.email}</p>
        </>
      )}
      <Button className={styles.editButton} onClick={() => push(`${Routes.Architects}/${user.id}`)}>{intl.formatMessage({ id: 'architect.header.content.edit.button' })}</Button>
      <div style={{display: "flex", justifyContent: "center", width: "100%"}}>

        {/*<div style={{position:"absolute", left:"8vw"}}>*/}
        {/*  <FilterButton*/}
        {/*    id={1}*/}
        {/*    icon={<Icon type="filter" />}*/}
        {/*    title={selectedFilter || ''}*/}
        {/*    content={filterContent}*/}
        {/*    popoverVisible={popoverVisible}*/}
        {/*    onUpdatePopoverVisible={handleVisibleChange}*/}
        {/*  />*/}
        {/*</div>*/}

        <div className={styles.linkContainer}>
          <Link
            to={Routes.MySpace}
            className={`${styles.tabLinks} ${router.location.pathname === Routes.MySpace || router.location.pathname.includes("collections")  ? styles.activeLink : ""}`}
          >
            <img style={{paddingRight: "5px", objectFit: "cover", paddingBottom:1}} src={favorites} />
            <span>{intl.formatMessage({ id: 'architect.myspace.collections' })}</span>
          </Link>
          <Link
            to={Routes.RecentlyViewedProducts}
            className={`${styles.tabLinks} ${router.location.pathname === Routes.RecentlyViewedProducts ? styles.activeLink : ""}`}
          >
            <span>{intl.formatMessage({ id: 'architect.myspace.recently.viewed' })}</span>
          </Link>
          <Link
            to={Routes.Downloads}
            className={`${styles.tabLinks} ${router.location.pathname === Routes.Downloads ? styles.activeLink : ""}`}
          >
            <span>{intl.formatMessage({ id: 'architect.myspace.downloads' })}</span>
            <span className={styles.downloadsNumber}>{totalDownloads}</span>
          </Link>
        </div>
        {
          router.location.pathname.includes("collections") && (
            <Link className={styles.backButton} to={Routes.MySpace} style={{position:"absolute", right: "9.5vw"}}>
              <span><Icon type="arrow-left"/></span>
              <span>{intl.formatMessage({ id: 'architect.myspace.backButton' })}</span>
            </Link>
          )
        }
      </div>
    </div>
  );
}

export default PageHeaderArchitectContent;
