import { MediaDTO, ProductThumbnailDTO} from '@addsome/dtos';
import { apiRequest } from '@addsome/redux-store';
import settings from '../settings';

const MEDIAS_PATH = '/medias';
const PRODUCTS_PATH = '/products';

export const mediaUploadUrl = `${settings.api.baseUrl}${MEDIAS_PATH}`;

export const mediaUploadUrlWithQuery = (query: string) => `${settings.api.baseUrl}${MEDIAS_PATH}${query}`;

export function getMedia(id: string): Promise<MediaDTO> {
  return apiRequest<MediaDTO>('GET', `${MEDIAS_PATH}/${id}`);
}

export function setThumbnail(productId: string, pictureId: string): Promise<ProductThumbnailDTO> {
  return apiRequest<ProductThumbnailDTO>('POST', `${PRODUCTS_PATH}/updateThumbnail`, { productId: productId, pictureId: pictureId });
}

export function deleteMedia(id: string): Promise<void> {
  return apiRequest('DELETE', `${MEDIAS_PATH}/${id}`);
}

export function deleteMultipleMedia(ids: string[]): Promise<void> {
  return apiRequest('DELETE', `${MEDIAS_PATH}/multiple?ids=${ids}`);
}
