"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.addBrandProjectSceneTemplates = exports.setBrandProjectSceneTemplates = void 0;

var model_1 = require("./model");

var setBrandProjectSceneTemplates = function setBrandProjectSceneTemplates(templates, total) {
  return {
    type: model_1.ActionsTypes.SET_BRAND_PROJECT_SCENE_TEMPLATES,
    templates: templates,
    total: total
  };
};

exports.setBrandProjectSceneTemplates = setBrandProjectSceneTemplates;

var addBrandProjectSceneTemplates = function addBrandProjectSceneTemplates(templates, total) {
  return {
    type: model_1.ActionsTypes.ADD_BRAND_PROJECT_SCENE_TEMPLATES,
    templates: templates,
    total: total
  };
};

exports.addBrandProjectSceneTemplates = addBrandProjectSceneTemplates;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_BRAND_PROJECT_SCENE_TEMPLATE_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;