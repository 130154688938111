import AntLayout from 'antd/lib/layout'
import classnames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import logoWhite from '../../assets/images/logo-white.png'
import { IRootState } from '../../redux'
import Routes from '../../utils/routes'
import { ScrollDirectionContext } from '../../utils/ScrollDirectionContext'
import { useIntl } from 'react-intl'
import styles from './PublicHeader.module.scss'
import NavBar from "../NavBar/NavBar";
import { Button, Size, Theme } from "@addsome/ui-kit";
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { user } from '@addsome/redux-store/dist';

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    darkTheme: boolean
  }

const PublicHeader: React.FC<IProps> = ({ darkTheme, user, router, setDisplayRegisterUser }) => {
  const scrollDirection = useContext(ScrollDirectionContext)
  const intl = useIntl()
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 800);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AntLayout.Header
      className={classnames(styles.header, {
        [styles.darkTheme]: darkTheme,
        [styles.sticky]:
          router.location.pathname.includes(Routes.Catalog) &&
          (scrollDirection === 'up' || scrollDirection == null)
      })}
    >
      <div className={styles.content}>
        <Link to="/" className={styles.logo}>
          <img alt="Addsome" src={logoWhite} />
        </Link>
        <div className={styles.navBar}>
          <NavBar darkTheme={darkTheme} />
        </div>

        <div className={styles.buttons} style={{ display: "flex" }}>
          <Button
            theme={Theme.WHITEGHOST}
            className={styles.submit}
            uppercase
            size={Size.LARGE}
            onClick={() => { window.location.href = Routes.Login }}
            style={{ marginRight: 10, height: 35 }}
          >
            <span>{intl.formatMessage({ id: 'public.header.login' })}</span>
          </Button>
          <Button
            theme={Theme.WHITE}
            className={styles.submit}
            uppercase
            size={Size.LARGE}
            onClick={() => {
              setDisplayRegisterUser(true)
            }}
            style={{ height: 35 }}
          >
            <span>{isSmallScreen ? 'Get started' : intl.formatMessage({ id: 'public.header.getStarted' })}</span>
          </Button>
        </div>
      </div>
    </AntLayout.Header>
  )
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  user: state.userState.user,
  router: state.router
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setDisplayRegisterUser: (displayRegister: boolean) =>
    dispatch(user.setRegisterUser(displayRegister)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicHeader)
