export const downloadUrl = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadBlob = (blob: BlobPart, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  downloadUrl(url, filename)
}
