import { BrandCreationDTO, BrandUpdateDTO, BrandDefaultType } from '@addsome/dtos';
import { InputField, InputURL, Option, Select, TagsList, TextArea } from '@addsome/ui-kit';
import { Form } from 'antd';
import { FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import CountryAutoCompleteField from '../../CountryAutocompleteField/CountryAutocompleteField';
import styles from './BrandInformationFieldsGroup.module.scss';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

type IProps = WrappedComponentProps & {
  formik: FormikProps<BrandUpdateDTO | BrandCreationDTO>;
  brand: BrandUpdateDTO | BrandCreationDTO;
};

const BrandInformationFieldsGroup: React.FC<IProps> = ({ formik, brand, intl }) => {
  const addAlternativeName = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const target = event.target as HTMLInputElement;
      formik.setFieldValue('alternativeNames', [
        ...(formik.values.alternativeNames || []),
        target.value
      ]);
    },
    [formik]
  );

  const removeAlternativeName = useCallback(
    (index: number) => {
      if (formik.values.alternativeNames) {
        const alternativeNames = [...formik.values.alternativeNames];
        alternativeNames.splice(index, 1);
        formik.setFieldValue('alternativeNames', alternativeNames);
      }
    },
    [formik]
  );
  
  const addCountry = (value: string) => {
    if (value !== '') {
      formik.setFieldValue('countries', [
        ...(formik.values.countries || []),
        value
      ]);
    }
  }
  
  const removeCountry = useCallback(
    (index: number) => {
      if (formik.values.countries) {
        const countries = [...formik.values.countries];
        countries.splice(index, 1);
        formik.setFieldValue('countries', countries);
      }
    },
    [formik]
  );
  

  const getList = () => {
    let brandTypeList: string[] = [];
    Object.keys(BrandDefaultType).forEach(((brandType) => {
      brandTypeList.push(brandType);
    }
    ));
    return brandTypeList;
  };

  const [brandsTypes] = useState(getList());

  return (
    <div className={styles.brandInformationFieldsGroup}>
      <Form.Item
        label={<FormattedMessage id="brandpage.panel.name" />}
        colon={false}
        required={true}
      >
        <InputField
          name="name"
          type="text"
          required
          onChange={formik.handleChange}
          defaultValue={brand.name}
          error={formik.errors.name}
        />
      </Form.Item>

      <Form.Item label={<FormattedMessage id="brandpage.panel.alternativeNames" />} colon={false}>
        {formik.values.alternativeNames && (
          <TagsList values={formik.values.alternativeNames} onClose={removeAlternativeName} />
        )}
        <InputField
          name="alternativeNames"
          type="text"
          onPressEnter={addAlternativeName}
          placeholder={intl.formatMessage({ id: 'brandspage.addName' })}
        />
      </Form.Item>

      <Form.Item label={<FormattedMessage id="brandpage.panel.description" />} colon={false}>
        <InputField error={formik.errors.description}>
          <TextArea
            name="description"
            onChange={formik.handleChange}
            defaultValue={brand.description || ''}
          />
        </InputField>
      </Form.Item>

      <Form.Item label={<FormattedMessage id="brandpage.panel.websiteUrl" />} colon={false}>
        <InputField error={formik.errors.websiteUrl}>
          <InputURL
            name="websiteUrl"
            onChange={formik.handleChange}
            defaultValue={brand.websiteUrl || ''}
          />
        </InputField>
      </Form.Item>
      
      <Form.Item label={<FormattedMessage id="brandpage.panel.countries" />} colon={false}>
        {formik.values.countries && (
          <TagsList values={formik.values.countries} onClose={removeCountry} />
        )}
        <CountryAutoCompleteField
          name="countriesInput"
          defaultValue={''}
          onChange={(v: string) => {
            addCountry(v);
          }}
        />
      </Form.Item>

      <Form.Item label={<FormattedMessage id="brandpage.panel.madeIn" />} colon={false}>
        <CountryAutoCompleteField
          name="madeIn"
          error={formik.errors.madeIn}
          defaultValue={brand.madeIn || ''}
          onChange={(v: string) => {
            formik.setFieldValue('madeIn', v || null);
          }}
          onSetInvalid={(invalid: boolean) => {
            if (invalid)
              formik.setFieldError(
                'madeIn',
                intl.formatMessage({ id: 'brandpage.panel.selectCountry' })
              );
          }}
        />
      </Form.Item>
      <Form.Item label={<FormattedMessage id="brandpage.panel.brandType" />} colon={false}>
        <InputField
        >
          <Select
            onChange={(v: string) => {
              formik.setFieldValue('defaultType', v || null);
            }}
            defaultValue={brand.defaultType || ""}
            placeholder={intl.formatMessage({ id: 'brandpage.panel.selectBrandType' })}
          >
            {brandsTypes.length > 0 && brandsTypes.map(((brandType, index) => (
              <Option key={index} value={BrandDefaultType[brandType]}>
                {brandType}
              </Option>
            )))}
          </Select>
        </InputField>

      </Form.Item >
    </div >
  );
};

export default injectIntl(BrandInformationFieldsGroup);
