"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$languageEnum;

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Language;

(function (Language) {
  Language["French"] = "French";
  Language["English"] = "English";
})(Language = exports.Language || (exports.Language = {}));

exports.languageEnumToStandard = (_exports$languageEnum = {}, _defineProperty(_exports$languageEnum, Language.French, 'fr'), _defineProperty(_exports$languageEnum, Language.English, 'en'), _exports$languageEnum);
exports.standardToLanguageEnum = {
  fr: Language.French,
  en: Language.English
};