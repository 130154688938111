import { BrandExtendedDTO, BrandUserCreationDTO, BrandUserRole, BrandUserUpdateDTO, BrandLightDTO } from '@addsome/dtos';
import { InputField, InvisibleButton, Option, Select, TagsList, AutoComplete } from '@addsome/ui-kit';
import { Form, Icon } from 'antd';
import { FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { findAccountWithSameEmail } from '../../../services/architectAccount'
import searchSuggested from '../../../services/searchSuggested'
import CountryAutoCompleteField from '../../CountryAutocompleteField/CountryAutocompleteField';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { SelectValue } from 'antd/lib/select'

type IProps = WrappedComponentProps & {
  formik: FormikProps<BrandUserUpdateDTO | BrandUserCreationDTO>;
  brandUser: BrandUserUpdateDTO | BrandUserCreationDTO;
};

const BrandUserInformationFieldsGroup: React.FC<IProps> = ({ formik, brandUser, intl }) => {
  const [isEmailAlreadyUsed, setEmailAlreadyUsed] = useState(false)

  const getList = () => {
    let brandUserRoleList: string[] = [];
    Object.keys(BrandUserRole).forEach(((brandUserRole) => {
      brandUserRoleList.push(brandUserRole);
    }
    ));
    return brandUserRoleList;
  };

  const checkEmailValidation = (e, isOnSubmit = false, values = {}) => {
    if (!e) {
      setEmailAlreadyUsed(false)
      return;
    }

    findAccountWithSameEmail(e).then((emailExists) => {
      if (emailExists) {
        setEmailAlreadyUsed(true)
      } else {
        setEmailAlreadyUsed(false)
      }
    })
  }

  const [brandUsersRoles] = useState(getList());
  const [showPassword, setShowPassword] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState<BrandLightDTO[]>([])
  const [suggestedBrands, setSuggestedBrands] = useState<BrandLightDTO[]>([])


  const searchBrands = async (value: SelectValue) => {
    const brands = await searchSuggested<BrandLightDTO>('brands', value.toString())
    setSuggestedBrands(brands)
  }
  const removeBrand = (brandId) => {
    setSelectedBrands(selectedBrands.filter(d => d.id !== brandId))
  }
  const addBrand = (brandId) => {
    const newBrand = suggestedBrands.find(brand => brand.id === brandId)
    if (newBrand) {
      setSelectedBrands([...selectedBrands, newBrand])
    }
  }

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'register.firstname' })}
        required
        colon={false}
      >
        <InputField
          required
          name="firstName"
          defaultValue={brandUser.firstName}
          error={formik.errors.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'register.lastname' })}
        required
        colon={false}>
        <InputField
          required
          name="lastName"
          defaultValue={brandUser.lastName}
          error={formik.errors.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.email' })} required colon={false}>
        <InputField
          name="email"
          defaultValue={brandUser.email}
          error={isEmailAlreadyUsed ? intl.formatMessage({ id: 'register.emailAlreadyUsed' }) : (formik.touched.email && formik.errors.email ? formik.errors.email : '')}
          required
          onChange={formik.handleChange}
          onBlur={(value) => { checkEmailValidation(value.target.value); formik.handleBlur(value); }}
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.password' })} required colon={false}>
        <InputField
          required
          name="password"
          defaultValue={brandUser.password}
          type={showPassword ? 'text' : 'password'}
          error={formik.errors.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          addonAfter={
            <InvisibleButton
              onClick={() => setShowPassword(!showPassword)}
              aria-label="Afficher / Masquer le mot de passe"
              type="button"
            >
              <Icon type={showPassword ? 'eye' : 'eye-invisible'} />
            </InvisibleButton>
          }
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.address.phone' })}>
        <InputField
          error={formik.errors.phone}
          name="phone"
          defaultValue={brandUser.phone}
          placeholder="+33"
          maxLength={16}
          onChange={event => formik.setFieldValue('phone', event.target.value || undefined)}
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.brandUser.role' })}>
        <InputField>
          <Select
            defaultValue={brandUser.role}
            onChange={value => formik.setFieldValue('type', value)}
          >
            {brandUsersRoles.length > 0 && brandUsersRoles.map(((buRole, index) => (
              <Option key={index} value={BrandUserRole[buRole]}>
                {buRole}
              </Option>
            )))}
          </Select>
        </InputField>
      </Form.Item>
      <Form.Item required label={<FormattedMessage id="register.brandUser.brands" />}>
        <TagsList
          values={selectedBrands.map(d => d.name)}
          onClose={index => {
            const closedBrand = selectedBrands[index]
            formik.setFieldValue(
              'brandIds',
              (formik.values.brandIds || []).filter(id => id !== closedBrand.id)
            )
            removeBrand(closedBrand.id)
          }}
        />
        <AutoComplete
          onSearch={searchBrands}
          fields={suggestedBrands.map(d => ({
            label: d.name,
            key: d.id
          }))}
          placeholder={intl.formatMessage({ id: 'productpage.panel.addBrand' })}
          onSelect={(v: SelectValue) => {
            const brandId = v.toString()
            formik.setFieldValue('brandIds', [...(formik.values.brandIds || []), brandId])
            addBrand(brandId)
          }}
        />
      </Form.Item>
    </>
  );
};

export default injectIntl(BrandUserInformationFieldsGroup);
