"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setRegisterUser = exports.resetUser = exports.setLoading = exports.setUser = void 0;

var model_1 = require("./model");

var setUser = function setUser(user) {
  return {
    type: model_1.ActionsTypes.SET_USER,
    user: user
  };
};

exports.setUser = setUser;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_USER_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;

var resetUser = function resetUser() {
  return {
    type: model_1.ActionsTypes.RESET_USER
  };
};

exports.resetUser = resetUser;

var setRegisterUser = function setRegisterUser(displayRegisterUser) {
  return {
    type: model_1.ActionsTypes.SET_DISPLAY_REGISTER_USER,
    displayRegisterUser: displayRegisterUser
  };
};

exports.setRegisterUser = setRegisterUser;