"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var render_1 = require("./render");

var ProjectPackshotRequestStatus;

(function (ProjectPackshotRequestStatus) {
  ProjectPackshotRequestStatus["Pending"] = "pending";
  ProjectPackshotRequestStatus["Processing"] = "processing";
  ProjectPackshotRequestStatus["Done"] = "done";
  ProjectPackshotRequestStatus["Fail"] = "fail";
})(ProjectPackshotRequestStatus = exports.ProjectPackshotRequestStatus || (exports.ProjectPackshotRequestStatus = {}));

var ProjectPackshotRequestDTO = function ProjectPackshotRequestDTO() {
  _classCallCheck(this, ProjectPackshotRequestDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ProjectPackshotRequestDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsDefined(), class_validator_1.Allow(), __metadata("design:type", Object)], ProjectPackshotRequestDTO.prototype, "baseContent", void 0);

__decorate([class_validator_1.IsDefined(), class_validator_1.Allow(), __metadata("design:type", Object)], ProjectPackshotRequestDTO.prototype, "camera", void 0);

__decorate([class_validator_1.IsDefined(), class_validator_1.Allow(), __metadata("design:type", Object)], ProjectPackshotRequestDTO.prototype, "renderSettings", void 0);

__decorate([class_validator_1.IsEnum(ProjectPackshotRequestStatus), __metadata("design:type", String)], ProjectPackshotRequestDTO.prototype, "status", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ProjectPackshotRequestDTO.prototype, "productId", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ProjectPackshotRequestDTO.prototype, "objectProductVariationId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return render_1.RenderDTO;
}), __metadata("design:type", render_1.RenderDTO)], ProjectPackshotRequestDTO.prototype, "render", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ProjectPackshotRequestDTO.prototype, "createdBy", void 0);

exports.ProjectPackshotRequestDTO = ProjectPackshotRequestDTO;

var ProjectPackshotRequestCreationDTO = function ProjectPackshotRequestCreationDTO() {
  _classCallCheck(this, ProjectPackshotRequestCreationDTO);
};

__decorate([class_validator_1.IsDefined(), class_validator_1.Allow(), __metadata("design:type", Object)], ProjectPackshotRequestCreationDTO.prototype, "baseContent", void 0);

__decorate([class_validator_1.IsDefined(), class_validator_1.Allow(), __metadata("design:type", Object)], ProjectPackshotRequestCreationDTO.prototype, "camera", void 0);

__decorate([class_validator_1.IsDefined(), class_validator_1.Allow(), __metadata("design:type", Object)], ProjectPackshotRequestCreationDTO.prototype, "renderSettings", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ProjectPackshotRequestCreationDTO.prototype, "productId", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ProjectPackshotRequestCreationDTO.prototype, "objectProductVariationId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsUUID(), __metadata("design:type", String)], ProjectPackshotRequestCreationDTO.prototype, "createdById", void 0);

exports.ProjectPackshotRequestCreationDTO = ProjectPackshotRequestCreationDTO;

var ProjectPacksthotRequestUpdateDTO = function ProjectPacksthotRequestUpdateDTO() {
  _classCallCheck(this, ProjectPacksthotRequestUpdateDTO);
};

__decorate([class_validator_1.IsOptional(), class_transformer_1.Type(function () {
  return render_1.RenderCreationDTO;
}), class_validator_1.ValidateNested(), __metadata("design:type", render_1.RenderCreationDTO)], ProjectPacksthotRequestUpdateDTO.prototype, "render", void 0);

__decorate([class_validator_1.IsEnum(ProjectPackshotRequestStatus), __metadata("design:type", String)], ProjectPacksthotRequestUpdateDTO.prototype, "status", void 0);

exports.ProjectPacksthotRequestUpdateDTO = ProjectPacksthotRequestUpdateDTO;