import React, { useEffect, useState } from 'react'
import { ArchitectType } from '@addsome/dtos/dist/enum/architectType';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ArchitectCreationDTO } from '@addsome/dtos'
import { IRootState } from '../../redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Modal } from '@addsome/ui-kit';
import { user } from '@addsome/redux-store/dist';
import RegisterPage from '../RegisterPage/RegisterPage'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps;

const modalStyle: React.CSSProperties = {
  padding: "0",
};

const RegisterViewModal: React.FC<IProps> = ({ displayRegisterUser, setDisplayRegisterUser }) => {

  return (
    <Modal
      visible={displayRegisterUser}
      footer={null}
      width="75%"
      centered
      onCancel={() => {
        setDisplayRegisterUser(false)
      }}
      bodyStyle={modalStyle}
    >
      <RegisterPage />
    </Modal>
  )
}

const mapStateToProps = (state: IRootState) => ({
  displayRegisterUser: state.userState.displayRegisterUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setDisplayRegisterUser: (displayRegister: boolean) =>
    dispatch(user.setRegisterUser(displayRegister)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RegisterViewModal));