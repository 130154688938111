"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ObjectProductType;

(function (ObjectProductType) {
  ObjectProductType["Furniture"] = "furniture";
  ObjectProductType["Lighting"] = "lighting";
  ObjectProductType["Accessories"] = "accessories";
})(ObjectProductType = exports.ObjectProductType || (exports.ObjectProductType = {}));