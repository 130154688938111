import { Button, Popconfirm, Table, Theme } from '@addsome/ui-kit'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import React from 'react'
import EditableCell, { ISelectOptions } from './EditableCell'
import EditableRow from './EditableRow'
import style from './EditableTable.module.scss'
import { FormattedMessage, WrappedComponentProps } from 'react-intl'

// Context used to share forms between rows and cells
interface IEditableContextValue {
  form?: WrappedFormUtils
}
export const EditableContext = React.createContext<IEditableContextValue>({})

// Editable column
export interface IColumnPropsEditable<T> extends ColumnProps<T> {
  editable?: boolean
  inputType?: 'string' | 'number' | 'date' | 'boolean' | 'select'
  editIndex?: string
  editValue?: string
  selectOptions?: ISelectOptions<any>
}

interface IState {
  editingKey: string
}

type IProps<T, E> = WrappedComponentProps & {
  columns: Array<IColumnPropsEditable<T>>
  rowSelection?: any
  dataSource: T[]
  loading?: boolean
  rowKey: string
  // If undefined, disable editing
  onEdit?: (record: E) => void
  readonly?: boolean
  // If undefined, disable delete action
  onDelete?: (record: T) => void
  onChange?: (
    pagination: PaginationConfig,
    filter: Record<keyof T, string[]>,
    sorter: SorterResult<T>
  ) => void
  showHeader?: boolean
}

export default class EditableTable<T, E> extends React.Component<IProps<T, E>, IState> {
  public state: IState = {
    editingKey: ''
  }

  public render() {
    // Create a rich set of columns
    const columns: Array<IColumnPropsEditable<T>> = [
      ...this.props.columns.map(column => ({
        ...column,
        // Transfer data as props to the cell component
        onCell: (record: T) => {
          const props = {
            record,
            inputType: column.inputType,
            dataIndex: column.dataIndex,
            editIndex: column.editIndex,
            editValue: column.editValue,
            selectOptions: column.selectOptions,
            title: column.title,
            editing: column.editable && this.isEditing(record)
          }

          // setting these props with undefined value will cause react to log errors in the console
          if (!column.inputType) delete props.inputType
          if (!column.selectOptions) delete props.selectOptions
          return props
        }
      }))
    ]

    // Add an action column if needed
    if (this.props.onEdit || this.props.onDelete) {
      columns.push({
        title: '',
        render: (record: T) => {
          const editable = this.isEditing(record)
          return (
            <div>
              {editable ? (
                // Save and cancel buttons
                <span className={style.actions}>
                  <EditableContext.Consumer>
                    {({ form }) =>
                      form && (
                        <Button
                          theme={Theme.PRIMARY}
                          onClick={() => this.save(form, (record as any)[this.props.rowKey])}
                          uppercase
                        >
                          <FormattedMessage id='edittable.toRegister' />
                        </Button>
                      )
                    }
                  </EditableContext.Consumer>
                  <Popconfirm
                    title={<FormattedMessage id='edittable.dischardChanges' />}
                    okText={<FormattedMessage id='edittable.okText' />}
                    cancelText={<FormattedMessage id='edittable.cancelText' />}
                    onConfirm={() => this.cancel()}
                  >
                    <Button theme={Theme.GHOST} uppercase>
                      <FormattedMessage id='edittable.toCancel' />
                    </Button>
                  </Popconfirm>
                </span>
              ) : (
                // Edit and Delete buttons
                <span className={style.actions}>
                  {this.props.onEdit && (
                    <Button
                      theme={Theme.PRIMARY}
                      shape="circle"
                      aria-label="Éditer"
                      icon="edit"
                      onClick={() => this.edit((record as any)[this.props.rowKey])}
                    />
                  )}

                  {this.props.onDelete && !this.props.readonly && (
                    <Popconfirm
                      title={<FormattedMessage id="global.confirmdelete" />}
                      onConfirm={() => this.props.onDelete && this.props.onDelete(record)}
                      okText={<FormattedMessage id="global.yes" />}
                      cancelText={<FormattedMessage id="global.no" />}
                      placement="left"
                    >
                      <Button
                        theme={Theme.GHOST}
                        shape="circle"
                        icon="delete"
                        title={this.props.intl.formatMessage({ id: 'global.delete' })}
                        aria-label="Supprimer"
                      />
                    </Popconfirm>
                  )}
                </span>
              )}
            </div>
          )
        }
      })
    }

    // Rows and cells that our table will use
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    }

    const { showHeader = true } = this.props

    return (
      <Table
        components={components}
        dataSource={this.props.dataSource}
        loading={this.props.loading || false}
        rowKey={this.props.rowKey}
        columns={columns}
        rowSelection={this.props.rowSelection}
        pagination={false}
        onChange={this.props.onChange}
        showHeader={showHeader}
        childrenColumnName={this.props.intl.formatMessage({ id: 'global.nodata' })}
      />
    )
  }

  public edit(key: string) {
    this.setState({ editingKey: key })
  }

  /** Say if a line is being edited */
  public isEditing = (record: T) => (record as any)[this.props.rowKey] === this.state.editingKey

  public cancel = () => {
    this.setState({ editingKey: '' })
  }

  public save(form: WrappedFormUtils, key: string) {
    form.validateFields((error: any, data: E) => {
      if (error) {
        return
      }
      const row = this.props.dataSource.find(item => (item as any)[this.props.rowKey] === key)
      if (this.props.onEdit != null) this.props.onEdit({ ...row, ...data })
      this.setState({ editingKey: '' })
    })
  }
}
