"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setProducts = void 0;

var model_1 = require("./model");

var setProducts = function setProducts(products) {
  return {
    type: model_1.ActionsTypes.SET_PRODUCTS_VARIATIONS,
    products: products
  };
};

exports.setProducts = setProducts;