import { ArchitectExtendedBrandDTO } from '@addsome/dtos/dist'
import { Icon } from 'antd'
import React, { memo } from 'react'
import UserThumbnail from '../UserThumbnail/UserThumbnail'
import styles from './ArchitectUserCard.module.scss'
import { useDispatch } from 'react-redux'
import Routes from '../../utils/routes'
import { push } from 'connected-react-router'

interface IProps {
  user: ArchitectExtendedBrandDTO
}

const ArchitectUserCard: React.FC<IProps> = ({ user }) => {
  const dispatch = useDispatch()

  return (
    <div key={user.id} className={styles.userThumbnail}>
      <UserThumbnail
        user={user}
        onClick={() => dispatch(push(`${Routes.Architects}/${user.id}`))}
      />

      <div className={styles.name}>
        {user.account.firstName && user.account.lastName
          ? `${user.account.firstName} ${user.account.lastName[0].toUpperCase()}.`
          : '?'}
      </div>

      <div className={styles.stats}>
        <div>
          <Icon type="eye" />
          <span>{user.view}</span>
        </div>
        <div>
          <Icon type="download" />
          <span>{user.download}</span>
        </div>
      </div>
    </div>
  )
}

export default memo(ArchitectUserCard)
