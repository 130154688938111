"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addLabels = exports.unsetNotification = exports.setNotification = void 0;

var model_1 = require("./model");

var setNotification = function setNotification(notificationType, message) {
  var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'default';
  return {
    type: model_1.ActionTypes.SET_NOTIFICATION,
    notificationType: notificationType,
    message: message,
    key: key
  };
};

exports.setNotification = setNotification;

var unsetNotification = function unsetNotification() {
  return {
    type: model_1.ActionTypes.UNSET_NOTIFICATION
  };
};

exports.unsetNotification = unsetNotification;

var addLabels = function addLabels(key, labels) {
  return {
    type: model_1.ActionTypes.ADD_LABELS,
    key: key,
    labels: labels
  };
};

exports.addLabels = addLabels;