import React, { FC, useEffect, useState } from 'react'
import styles from './SubscriptionCards.module.scss'
import { getSubscriptions } from '../../services/subscription'
import { ArchitectSubscriptionDTO } from '@addsome/dtos/dist'
import { useIntl, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Loading, Theme } from '@addsome/ui-kit'
import { loadStripe, PaymentIntentResult, Stripe } from '@stripe/stripe-js';
import { configPayment, createPaymentIntent, createPaymentIntentTrial, hasCanceledSubscriptions } from '../../services/payment';
import useLoggedUser from '../../hooks/useUser'
import usePush from '../../hooks/usePush'
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { getPacks } from '../../services/subscription'
import { IRootState } from '../../redux';
import { parse } from 'querystring'
import { connect } from 'react-redux'
import { ArchitectSubscriptionType, ArchitectPackDTO } from '@addsome/dtos'
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel'
import { freeTrialFeatures, monthlyPaymentFeatures, packFeaturesList } from "../../utils/subscriptionAndPackFeatures";
import Routes from '../../utils/routes'

type IProps = WrappedComponentProps &
{
  currentSubscription?: ArchitectSubscriptionDTO
  queryParams: any
}

const SubscriptionCards: React.FC<IProps> = ({ currentSubscription, queryParams }) => {
  const { user, accountType } = useLoggedUser()
  const [subscriptions, setSubscriptions] = useState<ArchitectSubscriptionDTO[]>([])
  const [subscription, setSubscription] = useState<ArchitectSubscriptionDTO | undefined>(undefined)
  const [packs, setPacks] = useState<ArchitectPackDTO[] | undefined>(undefined)
  const [pack, setPack] = useState<ArchitectPackDTO | undefined>(undefined)
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [displayPaymentForm, setDisplayPaymentForm] = useState(false);
  const [upgradeLoading, setUpgradeLoading] = useState(false)
  const [stripeError, setStripeError] = useState('')
  const [subscriptionType, setSubscriptionType] = useState(null)
  const [packType, setPackType] = useState(null)
  const [trialUsed, setTrialUsed] = useState(false);
  const euCountries = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU',
    'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'XI']
  const [freeTrialSubscription, setFreeTrialSubscription] = useState<ArchitectSubscriptionDTO | undefined>();
  const [unlimitedSubscription, setUnlimitedSubscription] = useState<ArchitectSubscriptionDTO | undefined>();

  const intl = useIntl();
  const { push } = usePush()

  useEffect(() => {
    configPayment().then(async (result) => {
      const { publishableKey } = await result;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    getSubscriptions().then((s) => {
      setFreeTrialSubscription(s.find(s => s.price === 0));
      setUnlimitedSubscription(s.find(s => s.slug === 'Unlimited'));
      setSubscriptions(s)
      if (currentSubscription) {
        setSubscription(currentSubscription)
      } else {
        setSubscription(s.sort((a, b) => a.quota > b.quota ? 1 : -1)[0])
      }
      if (queryParams && queryParams.subscription) {
        let subscription = s.find(sb => sb.slug == queryParams.subscription)
        if (subscription && subscription.slug != ArchitectSubscriptionType.Standard10) {
          upgradeUserSubscription(subscription)
        } else {
          upgradeUserTrialSubscription(subscription)
        }
      }
    })

    getPacks().then((p) => {
      setPacks(p)
      setPack(p.sort((a, b) => a.downloads > b.downloads ? 1 : -1)[0])
    })
  }, [])

  useEffect(() => {
    if (!user) {
      return;
    }

    hasCanceledSubscriptions(user.account.email).then((canceledSubscriptions) => {
      if (canceledSubscriptions.length) {
        canceledSubscriptions.forEach(cancelSubscription => {
          if (cancelSubscription.trial_start && cancelSubscription.default_payment_method) {
            setTrialUsed(true)
          }
        });
      }
    });
  }, [user])

  const getFeaturesForSubscriptions = () => {
    if (subscription && subscription.slug == 'Standard10') {
      return freeTrialFeatures(intl);
    }
    return monthlyPaymentFeatures(intl);
  }

  const upgradeUserSubscription = (subscription) => {
    Mixpanel.track(MixpanelEvents.UserClickSelectPlan)
    setUpgradeLoading(true)
    setSubscriptionType(subscription.slug)
    setPackType(null)
    createPaymentIntent(subscription.price, user!.account.email, subscription.slug, { email: user!.account.email, password: '', firstName: user!.account.firstName, lastName: user!.account.lastName }).then(async (result) => {
      if (result.error) {
        setUpgradeLoading(false)
        setStripeError(result.error.message)
        return;
      }
      var { clientSecret } = await result;
      setClientSecret(clientSecret);
      setDisplayPaymentForm(true)
    });
  }

  const upgradeUserTrialSubscription = (subscription) => {
    Mixpanel.track(MixpanelEvents.UserClickSelectPlan)
    setUpgradeLoading(true)
    setSubscriptionType(subscription.slug)
    setPackType(null)
    window.location.href = `${Routes.UpgradePaymentComplete}/${user!.account.id}/${user!.account.email}/${subscription.slug}`
    // const standard25 = subscriptions.find(s => s.slug === 'Standard25')
    // createPaymentIntentTrial(standard25.price, user!.account.email, { email: user!.account.email, password: '', firstName: user!.account.firstName, lastName: user!.account.lastName }).then(async (result) => {
    //   if (result.error) {
    //     setUpgradeLoading(false)
    //     setStripeError(result.error.message)
    //     return;
    //   }
    //   var { clientSecret } = await result;
    //   setClientSecret(clientSecret);
    //   setDisplayPaymentForm(true)
    // });
  }

  const buyPackage = (pack) => {
    Mixpanel.track(MixpanelEvents.UserClickSelectPack)
    setUpgradeLoading(true)
    setPackType(pack.downloads)
    setSubscriptionType(null)
    createPaymentIntent(pack.price, user!.account.email, 'Pack' + pack.downloads, { email: user!.account.email, password: '', firstName: user!.account.firstName, lastName: user!.account.lastName }).then(async (result) => {
      if (result.error) {
        setUpgradeLoading(false)
        setStripeError(result.error.message)
        return;
      }
      const { clientSecret } = await result;
      setClientSecret(clientSecret);
      setDisplayPaymentForm(true)
    });
  }

  return (
    <div className={styles.cardWrapper}>
      {displayPaymentForm &&
        <Elements key={clientSecret} stripe={stripePromise} options={{ clientSecret }} >
          <CheckoutForm user={user} account={user.account} onStripeCancel={() => setUpgradeLoading(false)} upgrade={subscriptionType ? subscriptionType : 'pack' + packType} />
        </Elements>
      }
      <div className={styles.packCard}>
        {freeTrialSubscription ?
          <>
            <div className={styles.planName}>
              <h1><FormattedMessage id="register.architect.free" /></h1>
            </div>
            <p style={{display: "flex", justifyContent: "center"}}>
              <FormattedMessage id="register.architect.freetrial.description" />
            </p>
            <div style={{height: "50px"}}>
              <span style={{display: "flex", justifyContent: "center", color: "black", fontWeight: "500"}}>
                <FormattedMessage id="register.architect.download.per.month" />
              </span>
              <div style={{display: "flex", justifyContent: "center"}}>
                <span style={{backgroundColor: "black", borderRadius: "50%", color:"white", width:"38px", height:"36px", display:"flex", alignItems:"center", justifyContent: "center"}}>
                  {freeTrialSubscription.quota}
                </span>
              </div>
            </div>
            
            <div className={styles.priceGroup}>
              <h1 style={{display: "flex", justifyContent: "center", paddingTop: "15px", paddingBottom: "15px", textTransform:'uppercase', fontWeight: 600}}>
                <FormattedMessage id="register.architect.free" />
              </h1>
            </div>
            <span style={{display: "flex", justifyContent: "center"}}>
              <FormattedMessage id="register.architect.freetrial.priceDescription" />
            </span>
            <div className={styles.submitBtnGroup}>
              <Button
                theme={Theme.PRIMARY}
                htmlType="submit"
                size="large"
                uppercase
                disabled={true}
                loading={upgradeLoading}
              >
                <FormattedMessage id="register.architect.subscription.selectFreeTrial" />
              </Button>
            </div>
          </>
          : <div><Loading /></div>
        }
      </div>
      <div className={styles.standardCard}>
        {unlimitedSubscription ?
          <>
            <div className={styles.planName}>
              <h1>
                <FormattedMessage id="register.architect.unlimited.title" />
              </h1>
            </div>
            <p style={{display: "flex", justifyContent: "center"}}>
              <FormattedMessage id="register.architect.unlimited.description" />
            </p>
            <div style={{display: "flex", justifyContent: "center", height: "50px"}}>
              <span style={{
                backgroundColor: "black",
                color: "white",
                padding: "5px 15px",
                border: "2px solid black",
                borderRadius: "25px",
                display: "inline-block",
                fontWeight: "600",
                fontSize: "14px",
                height: "35px",
                width: "220px"
              }}>
                <FormattedMessage id="register.architect.unlimited.downloads" />
              </span>
            </div>
            <div className={styles.priceGroup}>
              <h1 style={{display: "flex", justifyContent: "center", paddingTop: "15px", paddingBottom: "15px", fontWeight: 600}}>
                {unlimitedSubscription.price} € / <FormattedMessage id="register.architect.month" />
              </h1>
            </div>
            <span style={{display: "flex", justifyContent: "center"}}>
              <FormattedMessage id="register.architect.unlimited.priceDescription" />
            </span>
            <div className={styles.submitBtnGroup}>
              <Button
                theme={Theme.PRIMARY}
                htmlType="submit"
                size="large"
                uppercase
                disabled={(currentSubscription && currentSubscription.id == unlimitedSubscription.id) || (currentSubscription && currentSubscription.quota > unlimitedSubscription.quota)}
                onClick={() => upgradeUserSubscription(unlimitedSubscription)}
                loading={upgradeLoading}
              >
                <FormattedMessage id="register.architect.subscription.selectPlan" />
              </Button>
            </div>
          </>
          : <div><Loading /></div>
        }
      </div>

    </div>
  )
}

export default injectIntl(SubscriptionCards);