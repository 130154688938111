"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_RENDERS"] = "SET_RENDERS";
  ActionsTypes["SET_RENDER"] = "SET_RENDER";
  ActionsTypes["UNSET_RENDER"] = "UNSET_RENDER";
  ActionsTypes["UPDATE_RENDER"] = "UPDATE_RENDER";
  ActionsTypes["REMOVE_RENDER"] = "REMOVE_RENDER";
  ActionsTypes["SET_RENDER_LOADING"] = "SET_RENDER_LOADING";
  ActionsTypes["ADD_RENDERS"] = "ADD_RENDERS";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));