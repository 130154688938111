import { AccountType } from '@addsome/dtos/dist'
import { Button, InputField, Loading, Option, Select, Size, TextArea, Theme } from '@addsome/ui-kit'
import { Menu } from 'antd'
import Form from 'antd/lib/form'
import { Field, FieldProps, Formik, FormikErrors } from 'formik'
import React, { memo, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Route, RouteComponentProps } from 'react-router-dom'
import * as yup from 'yup'
import UploadMedia, { UploadType } from '../../components/UploadMedia/UploadMedia'
import { IRootState } from '../../redux'
import { mediaUploadUrl } from '../../services/media'
import AddsomeUserProfilePageLayout, { Columns } from '../../templates/ProfilePageLayout/AddsomeUserProfilePageLayout'
import useAddsomeUser from '../../templates/ProfilePageLayout/useAddsomeUser'
import usePush from '../../hooks/usePush'
import useLoggedUser from '../../hooks/useUser'
import Routes from '../../utils/routes'
import styles from './AddsomeUserProfileEditPage.module.scss'

interface IMatchParams {
  id: string
}

type IProps = RouteComponentProps<IMatchParams>

const ProfileFormField: React.FC<{ fieldId: string; placeholder?: string; password?: boolean; noLabel?: boolean; }> = ({
  fieldId,
  placeholder,
  password = false,
  noLabel = false,
}) => {
  const intl = useIntl()

  return (
    <Form.Item label={noLabel ? '' : intl.formatMessage({ id: `profileEdit.form.${fieldId}` })} colon={false}>
      <Field
        name={fieldId}
        placeholder={placeholder}
        render={({ field, form }: FieldProps) => (
          <InputField
            {...field}
            type={password ? 'password' : 'text'}
            error={(form.errors[field.name] as string) || ''}
            placeholder={intl.formatMessage({
              id: `profileEdit.form.${fieldId}`
            })}
          />
        )}
      />
    </Form.Item>
  )
}

const AddsomeUserProfileEditPage: React.FC<IProps> = ({ match }) => {
  const intl = useIntl()
  const { addsomeUser, patchAddsomeUser, profilePicture, loading } = useAddsomeUser(match.params.id)
  const { user, accountType, setUser } = useLoggedUser()
  const { push } = usePush()
  const formikRef = useRef<Formik>(null)
  const { token, pathname } = useSelector((state: IRootState) => ({
    token: state.authState.token,
    pathname: state.router.location.pathname
  }))

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        lastName: yup
          .string()
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        firstName: yup
          .string()
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        email: yup
          .string()
          .email(intl.formatMessage({ id: 'validation.email.notValid' }))
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        phone: yup
          .string()
          .trim()
          .matches(/^\+33[0-9]*$/, intl.formatMessage({ id: 'validation.phone.invalid' }))
          .length(12),
      }),
    [intl]
  )

  const passwordValidationSchema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .required()
          .min(5)
      }),
    [intl]
  )

  return (
    addsomeUser && (
      <AddsomeUserProfilePageLayout
        showEmail
        canToggleUse={accountType === AccountType.AddsomeUser}
        addsomeUser={addsomeUser}
        subHeader={
          <Menu
            className={styles.subHeader}
            theme="light"
            mode="horizontal"
            selectedKeys={[
              `/${pathname
                .split('/')
                .slice(3)
                .join('/')}`
            ]}
          >
            <Menu.Item
              key="/edit"
              className={styles.item}
              onClick={() => push(`${Routes.AddsomeUsers}/${match.params.id}/edit`)}
            >
              {intl.formatMessage({ id: 'profileEdit.navBar.profile' })}
            </Menu.Item>

            {accountType === AccountType.AddsomeUser && (
              <Menu.Item
                key="/edit/password"
                className={styles.item}
                onClick={() => push(`${Routes.AddsomeUsers}/${match.params.id}/edit/password`)}
              >
                {intl.formatMessage({ id: 'profileEdit.navBar.password' })}
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Route
          path={`${Routes.AddsomeUsers}/:id/edit/password`}
          exact
          component={() => (
            <div className={styles.passwordEditContainer}>
              <div className={styles.column}>
                <h3>{intl.formatMessage({ id: 'profileEdit.navBar.password' })}</h3>
                <div className={styles.block}>
                  {loading ? <Loading /> :
                    <Formik
                      initialValues={{
                        newPassword: '',
                        confirmation: ''
                      }}
                      validate={({ newPassword, confirmation }) => {
                        const errors: FormikErrors<any> = {}

                        if (confirmation.length >= 5 && (newPassword !== confirmation)) {
                          errors.confirmation = intl.formatMessage({
                            id: 'validation.password.mismatch'
                          })
                        }
                        if (newPassword.length < 5) {
                          errors.newPassword = intl.formatMessage({
                            id: 'validation.password.length'
                          })
                        }
                        return errors
                      }}
                      onSubmit={values => {
                        patchAddsomeUser({ account: { password: values.newPassword } })
                      }
                      }
                      validateOnChange={false}
                      render={({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                          <ProfileFormField fieldId="newPassword" password />

                          <ProfileFormField fieldId="confirmation" password noLabel />

                          <Button
                            htmlType="submit"
                            theme={Theme.PRIMARY}
                            size={Size.LARGE}
                            className={styles.buttonChangePassword}
                          >
                            {intl.formatMessage({ id: 'profileEdit.form.button.changePassword' })}
                          </Button>
                        </Form>
                      )}
                    />
                  }
                </div>
              </div>
            </div>
          )}
        />

        <Route
          path={`${Routes.AddsomeUsers}/:id/edit`}
          exact
          component={() => (
            <Columns
              leftSectionTitle={intl.formatMessage({ id: 'profile.title.photo' })}
              rightSectionTitle={intl.formatMessage({ id: 'profile.title.informations' })}
              leftSectionContent={
                <>
                  <div className={styles.profilePicture}>
                    <img src={profilePicture} />
                  </div>

                  <UploadMedia
                    type={UploadType.GHOST}
                    action={mediaUploadUrl}
                    disabled={loading}
                    afterUpload={media =>
                      patchAddsomeUser({
                        account: { profilePictureId: media.id }
                      }).then(a => {
                        // if current user is the currently modified user,
                        // set its new profile picture
                        if (match.params.id === user!.id) {
                          setUser(a)
                        }
                      })
                    }
                    token={token}
                    className={styles.uploadButton}
                  />
                  <Button
                    uppercase
                    disabled={loading}
                    className={styles.downloadButton}
                    theme={Theme.PRIMARY}
                    title={intl.formatMessage({ id: 'global.save' })}
                    onClick={() => {
                      if (formikRef && formikRef.current) {
                        formikRef.current!.submitForm()
                      }
                    }}
                  >
                    {intl.formatMessage({ id: 'global.save' })}
                  </Button>
                </>
              }
              rightSectionContent={
                loading ? <Loading /> : (
                  <Formik
                    ref={formikRef}
                    validationSchema={validationSchema}
                    initialValues={{
                      lastName: addsomeUser.account.lastName || '',
                      firstName: addsomeUser.account.firstName || '',
                      email: addsomeUser.account.email || '',
                      phone: addsomeUser.account.phone || '',
                    }}
                    onSubmit={({
                      lastName,
                      firstName,
                      email,
                      phone,
                    }) => {
                      patchAddsomeUser({
                        account: { lastName, firstName, email, phone: phone || undefined }
                      }).then(au =>
                        push('/users?tab=admins')
                      )
                    }}
                    render={({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <ProfileFormField fieldId="lastName" />

                        <ProfileFormField fieldId="firstName" />

                        <ProfileFormField fieldId="email" />

                        <ProfileFormField fieldId="phone" placeholder="+33" />
                      </Form>
                    )}
                  />
                )}
            />
          )}
        />
      </AddsomeUserProfilePageLayout>
    )
  )
}

export default memo(AddsomeUserProfileEditPage)
