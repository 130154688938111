import {
  AccountType,
  AddsomeUserDTO,
  BrandDefaultType,
  ProductLightExtendedDTO,
  ProductStateDTO,
  ProductUpdateDTO
} from '@addsome/dtos';
import {
  objectProduct,
  objectProductVariations,
  product,
  productStates,
  productViewer,
  product as productActions,
} from '@addsome/redux-store';
import { Button, Integration, Popconfirm, Size, Switch, Table, Tag, TagType, Theme, Thumbnail } from '@addsome/ui-kit';
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table';
import {push, RouterState} from 'connected-react-router';
import React from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import thumbnailPlaceholder from '../../../assets/images/donnees-2D.svg';
import { IRootState } from '../../../redux';
import style from './ProductsTable.module.scss';
import getProductName from '../../../utils/getProductName';
import { getCloudImageUrl } from '../../../utils/cloudImage';
import { setUserLog } from '../../../services/userLog';
import {Searchbar} from "@addsome/ui-kit";
import Routes from "../../../utils/routes";
import styles from "../../ListTop/ListTop.module.scss";
import {Icon} from "antd";

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  WrappedComponentProps & {
    products: ProductLightExtendedDTO[];
    total: number;
    currentPage: number;
    pageSize: number;
    loading: boolean;
    onTableChange: (
      pagination: PaginationConfig,
      filter: Record<keyof ProductLightExtendedDTO, string[]>,
      sorter: SorterResult<ProductLightExtendedDTO>
    ) => void;
    onDelete?: (id: string) => void;
    sortBy?: { [key: string]: number; };
    filters?: { [key: string]: string[]; };
    readonly?: boolean;
    router: RouterState;
    handleSearch: (searchPattern: string) => void;
    searchTerm: string;
    setSearchTerm: (value: string) => void;
  };

const NonAdminBlacklistDataIndexes = ['enabled', 'stateId', 'countUnresolvedProblem', 'collection', 'updatedAt', 'promoted', 'haveObjectProductModelBundle'];
const NonArchitectBlacklistDataIndexes = ['lastDownloaded'];
const isMobile = window.innerWidth <= 600;

class ProductsTable extends React.Component<IProps> {

  private columns: Array<ColumnProps<ProductLightExtendedDTO>> = [
    {
      title: '',
      dataIndex: 'thumbnail.media.url',
      key: 'thumbnail',
      render: (value: string, record) => (
        <Thumbnail
          src={getCloudImageUrl(value, 54) || thumbnailPlaceholder}
          alt={`Image ${record.name}`}
          size={Size.SMALL}
        />
      ),
      width: '5%'
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.name' }),
      dataIndex: 'name',
      width: '18%',
      sorter: true,
      render: (value, record) => {
        return this.props.accountType !== AccountType.Architect ?
          <Link to={`/products/${record.id}?tab=info`}>{getProductName(record)}</Link>
          : <div className={style.productName} onClick={() => this.props.showProductModal(record.id, record.objectProductId)}>{getProductName(record)}</div>
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'productpage.panel.brand' }),
      dataIndex: 'brand',
      sorter: true,
      render: (value: string, record) => (<Link to={`/brands/${record.brand ? record.brand.id : record.brandWebsiteUrl}?tab=info`}>{record.brand ? record.brand.name : ""}</Link>)
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.collection' }),
      dataIndex: 'collection',
      render: value => <span>{value || '-'}</span>,
      sorter: true
    },
    // Hide type column for now
    // {
    //   title: 'Type',
    //   dataIndex: 'objectProductType',
    //   key: 'objectProductTypes',
    //   sorter: false,
    //   filters: [
    //     { text: <FormattedMessage id="global.furniture" />, value: ObjectProductType.Furniture },
    //     { text: <FormattedMessage id="global.lighting" />, value: ObjectProductType.Lighting },
    //     { text: <FormattedMessage id="global.accessories" />, value: ObjectProductType.Accessories }
    //   ],
    //   filterMultiple: true,
    //   render: value => <FormattedMessage id={`products.${value}`} />
    // },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.status' }),
      dataIndex: 'stateId',
      key: 'stateIds',
      render: (value: string) => <Tag type={TagType.ACTIVE}>{this.getProductStateTitle(value)}</Tag>
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.problems' }),
      dataIndex: 'countUnresolvedProblem',
      sorter: true
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.update' }),
      dataIndex: 'updatedAt',
      render: value => <FormattedDate value={value} />,
      sorter: true,
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.downloaded' }),
      dataIndex: 'lastDownloaded',
      render: value => <FormattedDate value={value} />,
      sorter: true,
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.enabled' }),
      width: '10%',
      render: (value: boolean, record: ProductLightExtendedDTO) => {
        return (
          <div className={style.enableCell}>
            {/* <Tag type={record.enabled ? TagType.ACTIVE : TagType.INACTIVE}>
              <FormattedMessage id={`products.${record.enabled ? 'active' : 'inactive'}`} />
            </Tag> */}
            <div className={style.richRight}>
              {this.props.children}
              <Switch
                onChange={() => this.updateEnabled(!record.enabled, record)}
                checked={record.enabled}
                integration={Integration.GREEN}
                className={style.enabled}
                disabled={this.props.readonly}
              >

              </Switch>
            </div>
          </div>
        );
      },
      dataIndex: 'enabled',
      filters: [
        { text: this.props.intl.formatMessage({ id: 'products.active' }), value: 'true' },
        { text: this.props.intl.formatMessage({ id: 'products.inactive' }), value: 'false' }
      ],
      filterMultiple: false

    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.promoted' }),
      width: '9%',
      render: (value: boolean, record: ProductLightExtendedDTO) => {
        return (
          <div className={style.enableCell}>
            <div className={style.richRight}>
              {this.props.children}
              <Switch
                onChange={() => this.updatePromoted(!record.promoted, record)}
                checked={record.promoted}
                integration={Integration.GREEN}
                className={style.enabled}
                disabled={this.props.readonly}
              >
              </Switch>
            </div>
          </div>
        );
      },
      dataIndex: 'promoted',
      filters: [
        { text: this.props.intl.formatMessage({ id: 'products.promoted' }), value: 'true' },
        { text: this.props.intl.formatMessage({ id: 'products.notPromoted' }), value: 'false' }
      ],
      filterMultiple: false
    },
    {
      title: '',
      width: '8%',
      render: (value: boolean, record: ProductLightExtendedDTO) =>
        record.deletedAt ? (
          <span>
            <FormattedMessage id="products.deleted" />
          </span>
        ) : (
          <div className={style.actions}>
            {value && this.props.router.location.pathname === Routes.Downloads && this.props.accountType === AccountType.Architect ? (
              <Icon type="download" aria-hidden onClick={() => {
                this.props.setDisplayViewer(true);
                this.props.fetchProduct(record.id);
                if (record.objectProductId) {
                  this.props.fetchObjectProduct(record.objectProductId);
                  this.props.fetchObjectProductVariations(record.objectProductId);
                }
              }}/>
            ) : (
              record.haveObjectProductModelBundle && (
                <Button
                  theme={Theme.GHOST}
                  aria-label={`Vue 3D de ${record.name}`}
                  uppercase
                  onClick={() => {
                    this.props.setDisplayViewer(true);
                    this.props.fetchProduct(record.id);
                    if (record.objectProductId) {
                      this.props.fetchObjectProduct(record.objectProductId);
                      this.props.fetchObjectProductVariations(record.objectProductId);
                    }
                  }}
                >
                  <FormattedMessage id="global.3dpreview" />
                </Button>
              )
            )}
            {this.props.onDelete && !this.props.readonly && this.canDelete() && (
              <Popconfirm
                title={this.props.intl.formatMessage({ id: 'global.confirmdelete' })}
                onConfirm={() => {
                  this.props.onDelete && this.props.onDelete(record.id)
                  setUserLog(this.props.connectedUser!.account.id, record.id, 'product deleted')
                }}
                okText={this.props.intl.formatMessage({ id: 'global.yes' })}
                cancelText={this.props.intl.formatMessage({ id: 'global.no' })}
                placement="left"
              >
                <Button
                  theme={Theme.GHOST}
                  shape="circle"
                  icon="delete"
                  title={this.props.intl.formatMessage({ id: 'global.delete' })}
                  aria-label={`Supprimer ${record.name}`}
                />
              </Popconfirm>
            )
            }
          </div >
        ),
      dataIndex: 'haveObjectProductModelBundle',
      filters: [
        { text: this.props.intl.formatMessage({ id: 'products.with3d' }), value: 'true' },
        { text: this.props.intl.formatMessage({ id: 'products.without3d' }), value: 'false' }
      ],
      filterMultiple: false
    }
  ];

  private mobileColumns: Array<ColumnProps<ProductLightExtendedDTO>> = [
    {
      title: '',
      dataIndex: 'thumbnail.media.url',
      key: 'thumbnail',
      render: (value: string, record) => (
        <Thumbnail
          src={getCloudImageUrl(value, 54) || thumbnailPlaceholder}
          alt={`Image ${record.name}`}
          size={Size.SMALL}
        />
      ),
      width: '5%'
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.name' }),
      dataIndex: 'name',
      sorter: true,
      render: (value, record) => (
        <Link to={`/products/${record.id}?tab=info`}>{getProductName(record)}</Link>
      )
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.status' }),
      dataIndex: 'enabled',
      render: value => (
        <Tag type={value ? TagType.ACTIVE : TagType.INACTIVE}>
          <FormattedMessage id={`products.${value ? 'active' : 'inactive'}`} />
        </Tag>
      ),
      filters: [
        { text: this.props.intl.formatMessage({ id: 'products.active' }), value: 'true' },
        { text: this.props.intl.formatMessage({ id: 'products.inactive' }), value: 'false' }
      ],
      filterMultiple: false
    },
    {
      title: '',
      render: (value: boolean, record: ProductLightExtendedDTO) => (
        <div className={style.actions}>
          {this.props.onDelete && !this.props.readonly && this.canDelete() && (
            <Popconfirm
              title={this.props.intl.formatMessage({ id: 'global.confirmdelete' })}
              onConfirm={() => this.props.onDelete && this.props.onDelete(record.id)}
              okText={this.props.intl.formatMessage({ id: 'global.yes' })}
              cancelText={this.props.intl.formatMessage({ id: 'global.no' })}
              placement="left"
            >
              <Button
                theme={Theme.GHOST}
                shape="circle"
                icon="delete"
                title={this.props.intl.formatMessage({ id: 'global.delete' })}
                aria-label={`Supprimer ${record.name}`}
              />
            </Popconfirm>
          )}
        </div>
      ),
      dataIndex: 'haveObjectProductModelBundle',
      filters: [
        { text: this.props.intl.formatMessage({ id: 'products.with3d' }), value: 'true' },
        { text: this.props.intl.formatMessage({ id: 'products.without3d' }), value: 'false' }
      ],
      filterMultiple: false
    }
  ];

  public componentDidMount() {
    this.props.fetchProductStates();
  }

  public componentDidUpdate(prevProps) {
    if (this.props.products !== prevProps.products) {
      this.setState({ products: this.props.products });
    }

    if (this.props.total !== prevProps.total) {
      this.setState({ total: this.props.total });
    }
  }

  public render() {
    const columns = [...(isMobile ? this.mobileColumns : this.columns)];
    const stateColumn = columns.find(c => c.dataIndex === 'stateId');
    if (stateColumn && this.props.productStates && this.props.productStates.length > 0) {
      stateColumn.filters = this.orderStates(this.props.productStates).map(state => ({
        text: state.title,
        value: state.id,
        key: state.id
      }));
    }

    // Init sort and filters
    if (this.props.sortBy || this.props.filters) {
      const sortBy = this.props.sortBy;
      const filters = this.props.filters;
      columns.forEach(column => {
        if (sortBy) {
          const orderValue = column.key
            ? sortBy[column.key]
            : (column.dataIndex && sortBy[column.dataIndex]) || 0;
          if (orderValue) {
            column.defaultSortOrder = orderValue > 0 ? 'ascend' : 'descend';
          }
        }

        if (filters) {
          column.filteredValue = column.key
            ? filters[column.key]
            : (column.dataIndex && filters[column.dataIndex]) || undefined;
        }
      });
    }

    const pagination: PaginationConfig = {
      total: this.props.total,
      pageSize: this.props.pageSize,
      current: this.props.currentPage,
      showTotal: total =>
        `${total} ${this.props.intl.formatMessage({ id: 'producttable.results' })}`
    };
    return (
      <div className={style.tableStyle}>
        { location.pathname === Routes.Downloads && (
          <Searchbar
            className={styles.search}
            placeholder={"Search"}
            value={this.props.searchTerm}
            onChange={(e) => {this.props.setSearchTerm(e.target.value)}}
            onSearch={this.props.handleSearch}
          />
        )}
        <Table
          columns={
            this.props.accountType === AccountType.AddsomeUser
              ? columns.filter(
                col =>
                  !NonArchitectBlacklistDataIndexes.find(dataIndex => dataIndex === col.dataIndex)
              )
              : columns.filter(
                col =>
                  !NonAdminBlacklistDataIndexes.find(dataIndex => dataIndex === col.dataIndex)
              )
          }
          dataSource={this.props.products}
          key={Math.random().toString(36).substr(2, 9)}
          pagination={this.props.total > this.props.pageSize ? pagination : false}
          loading={this.props.loading}
          onChange={this.props.onTableChange}
          rowClassName={this.getRowClassName}

        />
      </div>
    );
  }

  private getRowClassName = (record: ProductLightExtendedDTO) => {
    return record.deletedAt ? style.deleted : '';
  };

  private canDelete = () => {
    return this.props.accountType === AccountType.AddsomeUser && (this.props.connectedUser as AddsomeUserDTO).perform_deletions
  }

  private getProductStateTitle = (stateId: string) => {
    const productState = this.props.productStates.find(state => state.id === stateId);
    return (productState && productState.title) || '';
  };
  private updateEnabled = (enabled: boolean, product: ProductLightExtendedDTO) => {
    this.props.patchProduct(product.id, { enabled });
  };
  private updatePromoted = (promoted: boolean, product: ProductLightExtendedDTO) => {
    this.props.patchProduct(product.id, { promoted });
  };

  private orderStates = (productStates: ProductStateDTO[]) => {
    const orderedProductStates: ProductStateDTO[] = new Array(productStates.length);
    productStates.map((state) => {
      if (state.id === '200')
        orderedProductStates[0] = state
      if (state.id === '300')
        orderedProductStates[1] = state
      if (state.id === '350')
        orderedProductStates[2] = state
      if (state.id === '000')
        orderedProductStates[3] = state
      if (state.id === '050')
        orderedProductStates[4] = state
      if (state.id === '100')
        orderedProductStates[5] = state
      if (state.id === '400')
        orderedProductStates[6] = state
      if (state.id === '500')
        orderedProductStates[7] = state
    })
    return orderedProductStates;
  };
}

const mapStateToProps = (state: IRootState) => ({
  productStates: state.productStatesState.productStates,
  accountType: state.authState.type,
  connectedUser: state.userState.user,
  router: state.router,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  push: (location: string) => dispatch(push(location)),
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewer.setDisplayViewer(displayViewer)),
  fetchProduct: (productId: string) => dispatch(product.thunks.fetchValue(productId)),
  fetchObjectProduct: (objectProductId: string) =>
    dispatch(objectProduct.thunks.fetchValue(objectProductId)),
  fetchObjectProductVariations: (objectProductId: string) =>
    dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId)),
  fetchProductStates: () => dispatch(productStates.fetchProductStates()),
  patchProduct: (id: string, payload: ProductUpdateDTO) =>
    dispatch(productActions.thunks.patchValue(id, payload)),
  showProductModal: async (productId: string, objectProductId?: string | null) => {
    dispatch(product.thunks.fetchValue(productId))
    dispatch(productViewer.setDisplayViewer(true))
    // Only fecth again if the product changed
    if (!!objectProductId) {
      await dispatch(objectProduct.thunks.fetchValue(objectProductId))
      await dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId))
    }
    dispatch(productViewer.setProductId(productId))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProductsTable));
