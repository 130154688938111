"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_ARCHITECT_CLIENTS"] = "SET_ARCHITECT_CLIENTS";
  ActionsTypes["SET_ARCHITECT_CLIENT"] = "SET_ARCHITECT_CLIENT";
  ActionsTypes["UNSET_ARCHITECT_CLIENT"] = "UNSET_ARCHITECT_CLIENT";
  ActionsTypes["SET_ARCHITECT_CLIENT_LOADING"] = "SET_ARCHITECT_CLIENT_LOADING";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));