"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _toConsumableArray = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/toConsumableArray");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  projects: [],
  project: null,
  loading: false,
  total: 0
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_BRAND_USER_PROJECTS, function (state, _ref) {
  var projects = _ref.projects,
      total = _ref.total;
  return Object.assign(Object.assign({}, state), {
    projects: projects,
    total: total
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.ADD_BRAND_USER_PROJECTS, function (state, _ref2) {
  var projects = _ref2.projects,
      total = _ref2.total;
  return Object.assign(Object.assign({}, state), {
    projects: [].concat(_toConsumableArray(state.projects), _toConsumableArray(projects)),
    total: total
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_BRAND_USER_PROJECT_LOADING, function (state, _ref3) {
  var loading = _ref3.loading;
  return Object.assign(Object.assign({}, state), {
    loading: loading
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_BRAND_USER_PROJECT, function (state, _ref4) {
  var project = _ref4.project;
  return Object.assign(Object.assign({}, state), {
    project: project
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UNSET_BRAND_USER_PROJECT, function (state) {
  return Object.assign(Object.assign({}, state), {
    project: null
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.REMOVE_BRAND_USER_PROJECT, function (state, _ref5) {
  var id = _ref5.id;
  return Object.assign(Object.assign({}, state), {
    total: state.total - 1,
    projects: state.projects.filter(function (project) {
      return project.id !== id;
    })
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);