"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  accountId: null,
  token: typeof window !== 'undefined' ? localStorage.getItem('token') : null,
  loading: false,
  type: null,
  typeId: null,
  hasLoggedIn: false,
  enabled: true,
  shouldShowArchitectNotEnabledDownloadPopup: false,
  shouldShowArchitectFirstConnectionPopup: false,
  shouldShowArchitectNotLinkedToBrandDownloadPopup: false,
  notLinkedBrand: null
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.RESET_AUTH, function () {
  return exports.initialState;
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_TOKEN, function (state, _ref) {
  var token = _ref.token;
  return Object.assign(Object.assign({}, state), {
    token: token
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.RESET_TOKEN, function (state) {
  return Object.assign(Object.assign({}, state), {
    token: null
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_AUTH_LOADING, function (state, _ref2) {
  var loading = _ref2.loading;
  return Object.assign(Object.assign({}, state), {
    loading: loading
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_AUTH, function (state, _ref3) {
  var auth = _ref3.auth;
  return Object.assign(Object.assign({}, state), {
    accountId: auth.accountId,
    type: auth.type,
    typeId: auth.typeId,
    token: auth.token
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_ERROR, function (state, _ref4) {
  var error = _ref4.error;
  return Object.assign(Object.assign({}, state), {
    error: error
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UNSET_ERROR, function (state) {
  return Object.assign(Object.assign({}, state), {
    error: undefined
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.HAS_LOGGED_IN, function (state) {
  return Object.assign(Object.assign({}, state), {
    hasLoggedIn: true
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SHOULD_SHOW_ARCHITECT_FIRST_CONNECTION_POPUP, function (state, _ref5) {
  var shouldShowArchitectFirstConnectionPopup = _ref5.shouldShowArchitectFirstConnectionPopup;
  return Object.assign(Object.assign({}, state), {
    shouldShowArchitectFirstConnectionPopup: shouldShowArchitectFirstConnectionPopup
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SHOULD_SHOW_ARCHITECT_NOT_ENABLED_DOWNLOAD_POPUP, function (state, _ref6) {
  var shouldShowArchitectNotEnabledDownloadPopup = _ref6.shouldShowArchitectNotEnabledDownloadPopup;
  return Object.assign(Object.assign({}, state), {
    shouldShowArchitectNotEnabledDownloadPopup: shouldShowArchitectNotEnabledDownloadPopup
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SHOULD_SHOW_ARCHITECT_NOT_LINKED_TO_BRAND_DOWNLOAD_POPUP, function (state, _ref7) {
  var shouldShowArchitectNotLinkedToBrandDownloadPopup = _ref7.shouldShowArchitectNotLinkedToBrandDownloadPopup,
      brand = _ref7.brand;
  return Object.assign(Object.assign({}, state), {
    shouldShowArchitectNotLinkedToBrandDownloadPopup: shouldShowArchitectNotLinkedToBrandDownloadPopup,
    notLinkedBrand: shouldShowArchitectNotLinkedToBrandDownloadPopup != null ? brand : undefined
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);