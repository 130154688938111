import { AddressDTO } from '@addsome/dtos'
import axios from 'axios'
import settings from '../settings'

interface IAddressContext {
  id: string
  'text_fr-FR'?: string
  text: string
  wikidata?: string
  language: string
  short_code?: string
}

interface IAddress {
  address?: string
  id: string
  type: string
  place_type: string[]
  relevance: number
  properties: IAddressContext
  'text_fr-FR'?: string
  'place_name_fr-FR'?: string
  text: string
  place_name: string
  bbox: [number, number, number, number]
  center: [number, number]
  geometry: {
    type: string
    coordinates: [number, number]
  }
  context: IAddressContext[]
}

interface IAddressResponse {
  type: string
  query: string[]
  features: IAddress[]
  attribution: string
}

export interface ICountry {
  label: string
  code: string
}

let localLanguage = localStorage.getItem('lang') || 'fr'
let appLanguage = localLanguage + '-' + localLanguage.toUpperCase()

const axiosIntance = axios.create()

const MAPBOX_API = 'https://api.mapbox.com/geocoding/v5/mapbox.places'

export async function getAdressesSuggestion(address: string) {
  if (!address) return []
  const response = await axiosIntance.get<IAddressResponse>(`${MAPBOX_API}/${address}.json`, {
    params: {
      limit: 5,
      language: appLanguage,
      access_token: settings.mapBox.token,
      types: ['address'],
      proximity: [48.85661, 2.351499]
    }
  })

  const { data } = response

  const addresses: AddressDTO[] = data.features.map(feature => {
    return {
      // some street don't have a number
      street: feature.address ? `${feature.address} ${feature.text}` : feature.text,
      city: feature.context[1] ? feature.context[1].text : '',
      region: feature.context[2] ? feature.context[2].text : '',
      zipcode: feature.context[0].text,
      country: feature.context[3] ? feature.context[3].text : ''
    }
  })

  return addresses
}

export async function searchCountry(query: string): Promise<ICountry[]> {
  if (!query) return []
  const { data } = await axiosIntance.get<IAddressResponse>(`${MAPBOX_API}/${query}.json`, {
    params: {
      limit: 5,
      language: appLanguage,
      access_token: settings.mapBox.token,
      types: ['country'],
      autocomplete: true
    }
  })

  return data.features.map(country => ({
    label: country.text,
    code: country.properties.short_code || ''
  }))
}

export async function getCountryFromCode(code: string): Promise<ICountry> {
  const { data } = await axiosIntance.get<IAddressResponse>(
    `${MAPBOX_API}/${code.toLowerCase()}.json`,
    {
      params: {
        limit: 5,
        access_token: settings.mapBox.token,
        types: ['country'],
        language: appLanguage
      }
    }
  )

  const matchingFeature = data.features.find((feature) => {
    const shortCode = feature.properties.short_code;
    return shortCode && shortCode.toLowerCase() === code.toLowerCase();
  });

  return matchingFeature
    ? {
      label: matchingFeature.text,
      code: matchingFeature.properties.short_code || ''
    }
    : { label: '', code: '' };
}
