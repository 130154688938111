"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_ALBUMS"] = "SET_ALBUMS";
  ActionsTypes["SET_ALBUMS_LOADING"] = "SET_ALBUMS_LOADING";
  ActionsTypes["ADD_ALBUMS"] = "ADD_ALBUMS";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));