import { drive } from '@addsome/redux-store'
import { Button, Heading, InputField, Modal, Theme } from '@addsome/ui-kit'
import Form from 'antd/lib/form'
import { push } from 'connected-react-router'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import AppBreadcrumb from '../../../components/AppBreadcrumb/AppBreadcrumb'
import DriveDropzone from '../../../components/Drive/DriveDropzone'
import DriveHeader from '../../../components/Drive/DriveHeader'
import FilesTable from '../../../components/Drive/FilesTable'
import FoldersList from '../../../components/Drive/FoldersList'
import { IRootState } from '../../../redux'
import { LIGHT_GREY } from '../../../utils/colors'
import styles from './DrivePage.module.scss'

const modalStyle: React.CSSProperties = {
  padding: '2.5rem',
  background: LIGHT_GREY
}

interface IMatchParams {
  slug: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IMatchParams>

const DrivePage: FC<IProps> = ({
  fetchFolder,
  folders,
  deleteFileOrFolder,
  createFolder,
  addQueueFiles,
  push,
  pathname,
  match,
  files,
  queuedFiles
}) => {
  const [displayFolderModal, setDisplayFolderModal] = useState(false)
  const [name, setName] = useState('')
  const [pattern, setPattern] = useState('')
  const [loading, setLoading] = useState(true)
  const [brandSlug, setBrandSlug] = useState<string | null>(null)
  const intl = useIntl()
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setBrandSlug(match.params.slug)
  }, [match.params.slug])

  const parsedPath = useMemo(() => {
    const pathMatch = pathname.match(/^\/drive\/(?:[^\/])+\/(.+)/)
    if (pathMatch) return pathMatch[1]
    else return null
  }, [pathname])

  useEffect(() => {
    async function asyncFetchFolder() {
      setLoading(true)
      if (brandSlug && parsedPath) await fetchFolder(brandSlug, parsedPath)
      else if (brandSlug) await fetchFolder(brandSlug)
      setLoading(false)
    }

    asyncFetchFolder()
  }, [brandSlug, fetchFolder, parsedPath, pathname])

  const handleCreateFolder = useCallback(
    (name: string) => {
      if (brandSlug && parsedPath) createFolder(brandSlug, name, parsedPath)
      else if (brandSlug) createFolder(brandSlug, name)
    },
    [brandSlug, parsedPath, createFolder]
  )

  const handleUploadFiles = useCallback(
    (files: FileList | File[]) => {
      if (parsedPath) {
        addQueueFiles(Array.from(files), parsedPath)
      } else if (brandSlug) addQueueFiles(Array.from(files))
    },
    [parsedPath, brandSlug, addQueueFiles]
  )

  const getDisplayName = useCallback(
    (name: string) => {
      const displayName = parsedPath ? name.replace(parsedPath, '') : name
      return displayName[0] === '/' ? displayName.substring(1) : displayName
    },
    [parsedPath]
  )

  const filteredFilesList = useMemo(() => {
    return [
      ...files
        .filter(f => f.name.includes(pattern) && f.name !== parsedPath)
        .map(f => ({
          ...f,
          displayName: getDisplayName(f.name)
        })),
      ...queuedFiles.filter(f => {
        if (f.displayName) {
          const path = f.name.replace(f.displayName, '')
          const folder = parsedPath ? parsedPath + '/' : ''
          return path === folder
        }
      })
    ]
  }, [files, queuedFiles, pattern, parsedPath, getDisplayName])

  const foldersList = useMemo(() => {
    return folders.map(f => ({
      name: getDisplayName(f),
      path: f
    }))
  }, [folders, getDisplayName])

  const handleImport = useCallback(() => {
    if (fileInputRef && fileInputRef.current) fileInputRef.current.click()
  }, [])

  const currentFolderName = useMemo(() => {
    if (!parsedPath) return ''
    const foldersArray = parsedPath.split('/')
    return foldersArray[foldersArray.length - 1]
  }, [parsedPath])

  const routes = useMemo(() => {
    if (!parsedPath || !brandSlug) return
    let path = `/drive/${brandSlug}`
    const routes = [
      { breadcrumbName: 'Drive', path },
      ...parsedPath.split('/').map(s => {
        path = `${path}/${s}`
        return {
          breadcrumbName: s,
          path
        }
      })
    ]
    return routes
  }, [parsedPath, brandSlug])

  if (!brandSlug) return null

  return (
    <>
      <DriveHeader
        onSearch={pattern => setPattern(pattern)}
        onAddFolder={() => setDisplayFolderModal(true)}
        onImportFile={handleImport}
        displayFileImport={!!parsedPath}
      />
      <div className={styles.container}>
        <input
          ref={fileInputRef}
          type="file"
          multiple
          onChange={e => {
            if (e.target.files) handleUploadFiles(e.target.files)
          }}
          style={{ visibility: 'hidden' }}
        />
        {!parsedPath ? (
          <div className={styles.titleContainer}>
            <Heading as="h1" level={1} strong className={styles.title}>
              {intl.formatMessage({ id: 'drivepage.title' })}
            </Heading>
            <span className={styles.subtitle}>
              {intl.formatMessage({ id: 'drivepage.subtitle' })}
            </span>
          </div>
        ) : (
          <div className={styles.titleContainer}>
            <AppBreadcrumb routes={routes} />
            <Heading as="h1" level={1} strong className={styles.title}>
              {currentFolderName}
            </Heading>
          </div>
        )}
        {foldersList.length > 0 && !loading && (
          <>
            <Heading as="h2" level={3} className={styles.foldersTitle} strong>
              {intl.formatMessage({ id: 'drivepage.folders' })}
            </Heading>
            <FoldersList
              folders={foldersList}
              onDeleteFolder={path => deleteFileOrFolder(brandSlug, path)}
              onClickFolder={path => push(`/drive/${brandSlug}/${path}`)}
            />
          </>
        )}
        {filteredFilesList.length > 0 && !loading && (
          <>
            <Heading as="h2" level={3} className={styles.filesTitle} strong>
              {intl.formatMessage({ id: 'drivepage.filestable.files' })}
            </Heading>
            <FilesTable
              files={filteredFilesList}
              onDelete={name => deleteFileOrFolder(brandSlug, name)}
            />
          </>
        )}
        {parsedPath && filteredFilesList.length === 0 && foldersList.length === 0 && !loading && (
          <DriveDropzone onDrop={files => addQueueFiles(files, parsedPath || undefined)} />
        )}
        <Modal
          visible={displayFolderModal}
          onCancel={() => {
            setName('')
            setDisplayFolderModal(false)
          }}
          footer={null}
          centered
          bodyStyle={modalStyle}
        >
          <form
            onSubmit={e => {
              e.preventDefault()
              handleCreateFolder(name)
              setDisplayFolderModal(false)
            }}
          >
            <Form.Item label={intl.formatHTMLMessage({ id: 'drivepage.foldermodal.foldername' })}>
              <InputField onChange={e => setName(e.currentTarget.value)} />
            </Form.Item>
            <Button theme={Theme.PRIMARY} htmlType="submit" disabled={!name} block>
              {intl.formatHTMLMessage({ id: 'drivepage.foldermodal.addfolder' })}
            </Button>
          </form>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  folders: state.driveState.folders,
  files: state.driveState.files,
  brandId: state.driveState.brandId,
  pathname: state.router.location.pathname,
  queuedFiles: state.driveState.queuedFiles
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, {}, AnyAction>,
  { match }: RouteComponentProps<IMatchParams>
) => ({
  fetchFolder: (brandIdOrSlug: string, path?: string) =>
    dispatch(drive.fetchFolder(brandIdOrSlug, path)),
  createFolder: (brandIdOrSlug: string, folderName: string, path?: string) =>
    dispatch(drive.createFolder(brandIdOrSlug, folderName, path)),
  deleteFileOrFolder: (brandIdOrSlug: string, path: string) =>
    dispatch(drive.deleteFileOrFolder(brandIdOrSlug, path)),
  addQueueFiles: (files: File[], path?: string) =>
    dispatch(drive.addQueueFiles(files, path, match.params.slug)),
  push: (path: string, state?: any) => dispatch(push(path, state))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrivePage)
