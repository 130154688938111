import { BrandUserCreationDTO, BrandUserDTO, AccountType, BrandUserRole } from '@addsome/dtos'
import { brandUser as brandUserActions, IPaginationRequest } from '@addsome/redux-store'
import { PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import { parse, stringify } from 'querystring'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import ListTop from '../../ListTop/ListTop'
import BrandUsersTable from './BrandUsersTable'
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl'
import BrandUserInformationStep from '../../Brands/BrandUserCreation/BrandUserInformationStep'
import Routes from '../../../utils/routes'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps

interface IState {
  currentPage: number
  request: IPaginationRequest
  prevSearch: string
}

const pageSize = 10

class BrandUsersTabPane extends React.Component<IProps, IState> {
  public state: IState = {
    currentPage: 0,
    request: {
      skip: 0,
      take: pageSize
    },
    prevSearch: ''
  }

  public componentDidMount() {
    this.updateRequest()
  }

  public componentDidUpdate() {
    const { prevSearch } = this.state
    const { urlSearch } = this.props
    // Update data when user use previous
    if (prevSearch !== urlSearch) {
      this.updateRequest()
    }
  }

  public render() {
    const { queryParams, updateQuery } = this.props

    const handleCreateBrandUser = async (newBrandUser) => {
      const brandUser = await this.props.createBrandUser(newBrandUser)
      this.props.redirect(`/users?tab=brandusers`)
    }
    return (
      <div>
        <ListTop
          searchLabel={this.props.intl.formatMessage({ id: 'brandusertable.search' })}
          onSearch={searchPattern => {
            const newRequest = {
              ...this.state.request,
              skip: 0,
              filters: {
                query: [searchPattern]
              }
            }
            this.setState({ request: newRequest })
            this.props.fetchBrandUsers(newRequest)

            const newParams = { ...queryParams, search: searchPattern, page: 1 }
            this.setState({ prevSearch: '?' + stringify(newParams) })
            updateQuery(stringify(newParams))
          }}
          creation={
            this.props.accountType === AccountType.AddsomeUser
              ? {
                active: true,
                initialValue: {
                  firstName: '',
                  lastName: '',
                  password: '',
                  email: '',
                  role: BrandUserRole.Normal,
                  brandIds: []
                },
                steps: [
                  {
                    title: <FormattedMessage id="productspage.infos" />,
                    component: BrandUserInformationStep,
                  }
                ],
                title: <FormattedMessage id="userspage.branduser.add" />,
                onCreate: payload => handleCreateBrandUser(payload)
              }
              : undefined
          }
          searchInitValue={queryParams.search}
        />
        <BrandUsersTable
          brandUsers={this.props.brandUsers}
          total={this.props.total}
          pageSize={pageSize}
          currentPage={this.state.currentPage}
          loading={this.props.loading}
          onTableChange={this.handleTableChange}
          onDelete={this.deleteBrandUser}
          sortBy={JSON.parse(queryParams.order || '{"createdAt": -1}')}
        />
      </div>
    )
  }

  private updateRequest = () => {
    const { queryParams } = this.props
    const currentPage = Math.max(1, parseInt(queryParams.page, 10)) || 1
    const newRequest = {
      ...this.state.request,
      skip: (currentPage - 1) * pageSize,
      order: JSON.parse(queryParams.order || '{}'),
      filters: { query: [queryParams.search] }
    }
    this.setState({ currentPage, request: newRequest, prevSearch: this.props.urlSearch })
    this.props.fetchBrandUsers(newRequest)
  }

  private handleTableChange = (
    pagination: PaginationConfig,
    filter: Record<keyof BrandUserDTO, string[]>,
    sorter: SorterResult<BrandUserDTO>
  ) => {
    const newRequest: IPaginationRequest = {
      ...this.state.request,
      skip: ((pagination.current || 1) - 1) * pageSize,
      order: sorter.column ? { [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1 } : {createdAt: -1}
    }
    this.setState({
      request: newRequest
    })
    this.props.fetchBrandUsers(newRequest)
    if (pagination.current) this.setState({ currentPage: pagination.current })

    const newParams = {
      ...this.props.queryParams,
      page: pagination.current || this.state.currentPage,
      order: JSON.stringify(newRequest.order)
    }
    this.setState({ prevSearch: '?' + stringify(newParams) })
    this.props.updateQuery(stringify(newParams))
  }

  private deleteBrandUser = (id: string) => this.props.deleteBrandUser(id)
}

const mapStateToProps = (state: IRootState) => ({
  brandUsers: state.brandUserState.values,
  loading: state.brandUserState.loading,
  total: state.brandUserState.total,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string },
  urlSearch: state.router.location.search,
  accountType: state.authState.type,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchBrandUsers: (request?: IPaginationRequest) =>
    dispatch(brandUserActions.thunks.fetchValues(request)),
  deleteBrandUser: (id: string) => dispatch(brandUserActions.thunks.deleteValue(id)),
  createBrandUser: (brandUser: BrandUserCreationDTO) =>
    dispatch(brandUserActions.thunks.createValue(brandUser)),
  updateQuery: (params: string) => dispatch(push({ search: params })),
  redirect: (location: string) => dispatch(push(location))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BrandUsersTabPane))
