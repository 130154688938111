import { ObjectProductVariationDTO } from '@addsome/dtos';
import { AutoComplete, Button, InputField, Option, Popconfirm, Select, Theme } from '@addsome/ui-kit';
import React, { useEffect, useState } from 'react';
import styles from './ProductVariations.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import presetVariations from './presetVariations';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Tooltip } from 'antd';

type IProps = WrappedComponentProps & {
  variationPresetNameAndGroupId: number;
  selectedColor: string;
  addMaterial: boolean;
  materialName: string;
  materialNameError: boolean;
  material: { materialName: string; id: string; } | null;
  variation: ObjectProductVariationDTO;
  materialsForProduct: string[];
  changeName: (name: string) => void;
  changePresetVariation: (presetVariationId: number) => void;
  changeColorHexa: (colorCode: string) => void;
  setAddEditMaterialError: () => void;
};


class EditRowForMaterial extends React.Component<IProps> {
  state = {
    displayTooltip: false
  };
  getPresetVariation = (variationId) => {
    const found = presetVariations.find((elem) => elem.id === variationId.toString());
    if (found)
      return found.name;
    return "";
  };

  public render() {

    return (
      <div draggable={true}
        onDragStart={(event) => { event.preventDefault(); event.stopPropagation(); }}
        className={`${styles.materialEditSection} ${styles.alignTable}`} style={{ padding: "0", alignItems: "center !important" }}
      >

        <div className={`${styles.alignTable} ${styles.materialEditSectionWidth} ${!this.props.addMaterial ? styles.positionForMobile : ""}`} style={{ padding: "0", gap: "1vw", alignItems: "center" }}>
          <Tooltip defaultVisible={true} visible={this.state.displayTooltip} title="Please add at least one material before attaching it to the variation">

            <span className={`${styles.inputStyle}`}
              onMouseEnter={() => {
                this.props.materialsForProduct.length < 1 && this.setState({ displayTooltip: true });

              }}
              onMouseLeave={() => {
                this.props.materialsForProduct.length < 1 && this.setState({ displayTooltip: false });
              }}
            >
              <InputField
              >
                <Select
                  disabled={this.props.materialsForProduct.length < 1}
                  onChange={value => this.props.changeName(value)}
                  defaultValue={this.props.material ? this.props.material.materialName : this.props.materialsForProduct[0]}
                  placeholder={this.props.intl.formatMessage({ id: 'productpage.variations.materialName' })}
                >
                  {this.props.materialsForProduct.map((material, index) => (
                    <Option key={index} value={material}>
                      {material}
                    </Option>
                  ))}
                </Select>
              </InputField>
            </span>
          </Tooltip>

          <span className={`${styles.inputStyle} ${styles.presetInputSelectWidth} `}>
            <div className={styles.marginBottom}>
            </div>

            <AutoComplete
              onSelect={(value: SelectValue) => {
                this.props.changePresetVariation(Number(value));
              }}
              onBlur={() => { { } }}
              fields={presetVariations.map(c => ({ key: c.id, label: c.name }))}
              filterOption={(inputValue, option) => {
                return (option.props.children as string)
                  .toLocaleLowerCase()
                  .includes(inputValue.toLocaleLowerCase());
              }}
              defaultValue={this.getPresetVariation(this.props.variationPresetNameAndGroupId)}
            />
          </span>
          <span className={`${styles.inputStyle} ${styles.smallerWidth}`}>
            <InputField error={""}>
              <input type="color" id="head" name="head"
                value={this.props.selectedColor} onChange={(e) => {

                  this.props.changeColorHexa(e.target.value);
                }}
                onMouseLeave={() => document.getElementById('variationThumbnail')!.style.cssText = `
                                                                                                    transform: scale(1);
                                                                                                    margin-left: 0;
                                                                                                                        `
                }
                onSelect={() => {
                  document.getElementById('variationThumbnail')!.style.cssText = `
                                                                                  transform: scale(2.8);
                                                                                  margin-left: 6em;
                                                                                  z-index: 100;
                                                                                  position: relative;
                                                                                `;
                }}
              />
            </InputField>
          </span>
        </div>

      </div >
    );
  }
}



export default injectIntl(EditRowForMaterial);
