import { Redirect, RouteComponentProps } from 'react-router-dom'
import React, { FC, useEffect, useState } from 'react'
import { getArchitectByAccountId } from '../../services/architectAccount';
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel';
import { getPacks, getSubscription, setPayment } from '../../services/subscription';
import { FormattedMessage, useIntl } from 'react-intl';
import { Apps, ArchitectSubscriptionDTO, ArchitectSubscriptionType } from '@addsome/dtos/dist';
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { architect as architectActions, auth } from '@addsome/redux-store'
import { connect } from 'react-redux'
import ConnectionLayout from '../../components/ConnectionLayout/ConnectionLayout';
import styles from './PaymentComplete.module.scss';
import { sendNewAccountEmail, sendAccountValidatedEmail } from '../../services/architectAccount'
import { push } from 'connected-react-router'
import { hasActiveSubscription, payInvoice } from '../../services/payment'
import { Loading } from '@addsome/ui-kit';
import { removeCookie } from 'typescript-cookie'
import * as crypto from 'crypto-js';

interface IMatchParams {
  accountId: string,
  email: string,
  subscriptionType: string,
}

type IProps = RouteComponentProps<IMatchParams> &
  ReturnType<typeof mapDispatchToProps>

const PaymentComplete: FC<IProps> = ({ match, updateSubscriptionOnArchitectAccount, redirect, updateRemainingDownloadsOnArchitectAccount, login }) => {
  const userAccountId = match.params.accountId
  const userEmail = match.params.email
  const subscriptionType = match.params.subscriptionType
  const intl = useIntl()

  const title = intl.formatMessage({ id: 'register.end.title' });
  const subtitle = '';
  const [activeSubscription, setActiveSubscription] = useState(null)
  const [architectValidated, setArchitectValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  // Retrieve encrypted data from localStorage
  const storedEncryptedEmail = localStorage.getItem('email');
  const storedEncryptedPassword = localStorage.getItem('password');
  // Decrypt data
  const decryptedEmailBytes = crypto.AES.decrypt(storedEncryptedEmail, process.env.REACT_APP_CRYPTO_KEY);
  const decryptedEmail = decryptedEmailBytes.toString(crypto.enc.Utf8);
  const decryptedPasswordBytes = crypto.AES.decrypt(storedEncryptedPassword, process.env.REACT_APP_CRYPTO_KEY);
  const decryptedPassword = decryptedPasswordBytes.toString(crypto.enc.Utf8);
  const [email, setEmail] = useState(decryptedEmail);
  const [password, setPassword] = useState(decryptedPassword);

  const getSubscriptionObject = async () => {
    const subscription = await getSubscription(subscriptionType as ArchitectSubscriptionType)
    return subscription
  }

  useEffect(() => {
    Mixpanel.alias(userEmail);
    Mixpanel.track(MixpanelEvents.UserRegisteredWithPayment);

    if (subscriptionType.includes('Pack')) {
      updateUserWithPack();
    } else {
      // We try 3 times because a bug that could not be reproduced so this might be the solution
      const checkActiveSubscription = (attempts = 0) => {
        hasActiveSubscription(userEmail).then((activeS) => {
          if (!activeS && attempts < 2) {
            setTimeout(() => {
              checkActiveSubscription(attempts + 1);
            }, 1000);
          } else if (!activeS && attempts >= 2) {
            redirect('/');
          } else {
            setActiveSubscription(activeS);
          }
        });
      };
      checkActiveSubscription();
    }
  }, []);

  const updateUserWithPack = async () => {
    getArchitectByAccountId(userAccountId).then(async (architect) => {
      setArchitectValidated(architect.account.enabled)
      await getPacks().then(async (p) => {
        const pack = p.find(p => p.downloads == subscriptionType.replace('Pack', ''))
        if (!architect.account.emailValidated) {
          if (architect.account.enabled) {
            sendAccountValidatedEmail(architect.id, `?downloads=${pack.downloads}&price=${pack.price}`)
          } else {
            //Put just an id for subscription because it is not used
            sendNewAccountEmail(architect.id, architect.id)
          }
          setPayment({ accountId: architect.account.id, subscriptionType: subscriptionType, paymentStatus: 'payment complete' })
          setIsLoading(false);
          const validPaymentIntent = await payInvoice(userEmail);
          if (validPaymentIntent) {
            await updateRemainingDownloadsOnArchitectAccount(architect.id, pack.downloads).then(async () => {
              removeCookie('registerValidated')
              localStorage.removeItem('email');
              localStorage.removeItem('password');
              if (email && password) {
                await login(email, password);
                redirect('/')
              }
            });
          }
        } else {
          redirect('/')
        }
      })
    })
  }

  useEffect(() => {
    if (activeSubscription) {
      getArchitectByAccountId(userAccountId).then(async (architect) => {
        setArchitectValidated(architect.account.enabled)
        if (!architect.account.emailValidated) {
          const subscription = await getSubscriptionObject()
          await updateSubscriptionOnArchitectAccount(architect.id, subscription)
          if (architect.account.enabled) {
            sendAccountValidatedEmail(architect.id)
          } else {
            sendNewAccountEmail(architect.id, subscription.slug)
          }
          setPayment({ accountId: architect.account.id, subscriptionType: subscriptionType, paymentStatus: 'payment complete' })
          setIsLoading(false);
          removeCookie('registerValidated')
          localStorage.removeItem('email');
          localStorage.removeItem('password');
          if (email && password) {
            await login(email, password);
            redirect('/')
          }
        } else {
          redirect('/')
        }
      })
    }
  }, [activeSubscription])

  return (
    <>
      {!isLoading ?
        <ConnectionLayout
          title={title}
          subtitle={subtitle}
          displayImage={false}
          className={styles.stepZeroContainer}
        >
          <div className={styles.end}>
            <FormattedMessage
              id={architectValidated ? "register.end.textSubscriptionValidation" : "register.end.text"}
              values={{
                p: (...chunks: string[]) => <p>{chunks}</p>
              }}
            />
          </div>
        </ConnectionLayout> : <Loading></Loading>
      }
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  updateSubscriptionOnArchitectAccount: (id: string, subscription: ArchitectSubscriptionDTO) => {
    return dispatch(
      architectActions.thunks.patchValue(id,
        {
          account: {
            emailValidated: true
          },
          subscription: subscription,
          subscriptionStartDate: new Date()
        }
      )
    )
  },
  redirect: (location: string) => dispatch(push(location)),
  enableArchitectEmail: (id: string) => {
    dispatch(
      architectActions.thunks.patchValue(id, {
        account: {
          emailValidated: true
        }
      })
    )
  },
  updateRemainingDownloadsOnArchitectAccount: (id: string, remainingDownloads: number) => {
    return dispatch(
      architectActions.thunks.patchValue(id,
        {
          account: {
            emailValidated: true
          },
          subscriptionRemainingDownloads: remainingDownloads <= 0 ? 0 : remainingDownloads,
        }
      )
    )
  },
  login: (email: string, password: string, type?: string) =>
    dispatch(auth.login(email, password, Apps.Backoffice, undefined, type)).then(r => {
      Mixpanel.identify(email)
      Mixpanel.track(MixpanelEvents.UserLoggedIn)
      Mixpanel.track(MixpanelEvents.UserConnected)
    }),
})

export default connect(
  null,
  mapDispatchToProps
)(PaymentComplete)