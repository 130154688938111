"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setBrandMetrics = exports.setObjectProductMetrics = exports.setObjectProductTypesMetrics = exports.setLoading = exports.setMetrics = void 0;

var model_1 = require("./model");

var setMetrics = function setMetrics(metrics) {
  return {
    type: model_1.ActionsTypes.SET_METRICS,
    metrics: metrics
  };
};

exports.setMetrics = setMetrics;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_METRICS_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;

var setObjectProductTypesMetrics = function setObjectProductTypesMetrics(objectProductTypesMetrics) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_TYPES_METRICS,
    objectProductTypesMetrics: objectProductTypesMetrics
  };
};

exports.setObjectProductTypesMetrics = setObjectProductTypesMetrics;

var setObjectProductMetrics = function setObjectProductMetrics(objectProductMetrics) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_METRICS,
    objectProductMetrics: objectProductMetrics
  };
};

exports.setObjectProductMetrics = setObjectProductMetrics;

var setBrandMetrics = function setBrandMetrics(brandMetrics) {
  return {
    type: model_1.ActionsTypes.SET_BRAND_METRICS,
    brandMetrics: brandMetrics
  };
};

exports.setBrandMetrics = setBrandMetrics;