"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$accountTypeU;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountTypeURLs = exports.ActionsTypes = void 0;

var dtos_1 = require("@addsome/dtos");

var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_USER_LOADING"] = "SET_AUTH_LOADING";
  ActionsTypes["SET_USER"] = "SET_USER";
  ActionsTypes["RESET_USER"] = "RESET_USER";
  ActionsTypes["SET_DISPLAY_REGISTER_USER"] = "SET_DISPLAY_REGISTER_USER";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));

exports.accountTypeURLs = (_exports$accountTypeU = {}, _defineProperty(_exports$accountTypeU, dtos_1.AccountType.AddsomeUser, '/addsome-users'), _defineProperty(_exports$accountTypeU, dtos_1.AccountType.Architect, '/architects'), _defineProperty(_exports$accountTypeU, dtos_1.AccountType.BrandUser, '/brand-users'), _exports$accountTypeU);