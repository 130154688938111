"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createReducer = void 0;

var createReducer = function createReducer(initialState, handlers) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;

    try {
      return action.type && handlers[action.type] ? handlers[action.type](state, action) : state;
    } catch (e) {
      throw e;
    }
  };
};

exports.createReducer = createReducer;