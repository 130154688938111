"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.setObjectProductDimensions = void 0;

var model_1 = require("./model");

var setObjectProductDimensions = function setObjectProductDimensions(objectProductDimensions) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_DIMENSIONS,
    objectProductDimensions: objectProductDimensions
  };
};

exports.setObjectProductDimensions = setObjectProductDimensions;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_OBJECT_PRODUCT_DIMENSIONS_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;