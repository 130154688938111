import { AddsomeUserDTO, ArchitectDTO, BrandUserWithBrandsDTO } from '@addsome/dtos'
import { setUser as setUserAction } from '@addsome/redux-store/dist/store/user'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import defaultAvatarImg from '../assets/images/avatar_default.png'
import { IRootState } from '../redux'

export default function useLoggedUser() {
  const { user, accountType } = useSelector((state: IRootState) => ({
    user: state.userState.user,
    accountType: state.authState.type
  }))

  const dispatch = useDispatch()

  const setUser = useCallback(
    (user: AddsomeUserDTO | ArchitectDTO | BrandUserWithBrandsDTO) => {
      dispatch(setUserAction(user))
    },
    [dispatch]
  )

  return {
    user,
    setUser,
    accountType,
    profilePicture:
      (user && user.account && user.account.profilePicture && user.account.profilePicture.url) ||
      defaultAvatarImg
  }
}
