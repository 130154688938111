import { Button, Modal, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import React from 'react'
import DTOForm from '../DTOForm/DTOForm'
import styles from './CreationModalWrapper.module.scss'

interface IProps {
  display: boolean
  onUpdateDisplay: (display: boolean) => void
  title: string
  children?: React.ReactElement<typeof DTOForm>
  hideAddButton?: boolean
}

interface IState {
  displayModal: boolean
}

class CreationModalWrapper extends React.Component<IProps, IState> {
  public state: IState = {
    displayModal: this.props.display || false
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.display !== this.props.display) {
      this.setState({ displayModal: this.props.display })
    }
  }

  public render() {
    return (
      <div className={styles.container} style={this.props.hideAddButton ? { margin: 0 } : {}}>
        {!this.props.hideAddButton &&
          <Button theme={Theme.PRIMARY} shape="circle" size="large" onClick={this.toggleDisplayModal}>
            <Icon type="plus" aria-label="Ajouter" />
          </Button>
        }
        <Modal
          title={this.props.title}
          visible={this.state.displayModal}
          onCancel={this.toggleDisplayModal}
          footer={null}
          width={600}
        >
          {this.props.children}
        </Modal>
      </div>
    )
  }

  private toggleDisplayModal = () =>
    this.setState({ displayModal: !this.state.displayModal }, () =>
      this.props.onUpdateDisplay(this.state.displayModal)
    )
}

export default CreationModalWrapper
