"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var models_1 = require("./models");

var utils_1 = require("../utils");

exports.initialState = {
  collections: []
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, models_1.ActionsTypes.SET_COLLECTIONS, function (state, _ref) {
  var collections = _ref.collections;
  return Object.assign(Object.assign({}, state), {
    collections: collections
  });
}), _defineProperty(_actionHandlers, models_1.ActionsTypes.UPDATE_COLLECTION, function (state, _ref2) {
  var collection = _ref2.collection;
  return Object.assign(Object.assign({}, state), {
    collections: state.collections.map(function (c) {
      if (c.id === collection.id) {
        return collection;
      }

      return c;
    })
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);