import React from "react";
import {LiveChatWidget} from "@livechat/widget-react";
import {IRootState} from "../../redux";
import {connect} from "react-redux";

const LiveChatComponent = ({ user }) => {

  const getName = () => {
    if (user) {
      return user.account.firstName;
    }
    return null;
  }

  const getEmail = () => {
    if (user) {
      return user.account.email;
    }
    return null;
  }
  return (
    <>
      <LiveChatWidget license="16157166" customerName={getName()} customerEmail={getEmail()}/>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user,
})

export default connect(mapStateToProps, undefined)(LiveChatComponent)