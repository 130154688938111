import {
  AccountType,
  ArchitectDTO,
  AddsomeUserDTO,
  ProductDTO,
  ProductLightExtendedDTO,
} from '@addsome/dtos'
import {
  Button,
  Popconfirm,
  Table,
  Theme,
  Switch,
  Thumbnail, Size, Loading,
} from '@addsome/ui-kit'
import {
  architect as architectActions, architectProductHistory,
  IPaginationRequest,
} from '@addsome/redux-store'
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import React from 'react'
import {
  FormattedDate,
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import style from './ArchitectsTable.module.scss'
import moment from 'moment'
import { sendAccountValidatedWithoutSubscriptionEmail, sendAccountValidatedEmail } from '../../../services/architectAccount'
import { Modal } from '@addsome/ui-kit'
import {
  fetchArchitectProductDownloads
} from '@addsome/redux-store/dist/store/architect-product-history'
import { getCloudImageUrl } from '../../../utils/cloudImage'
import thumbnailPlaceholder from '../../../assets/images/donnees-2D.svg'
import getProductName from '../../../utils/getProductName'
type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  WrappedComponentProps & {
    architects: ArchitectDTO[]
    total: number
    currentPage: number
    pageSize: number
    loading: boolean
    onTableChange: (
      pagination: PaginationConfig,
      filter: Record<keyof ArchitectDTO, string[]>,
      sorter: SorterResult<ArchitectDTO>
    ) => void
    onDelete: (id: string) => void
    sortBy?: { [key: string]: number }
    filters?: { [key: string]: string[] }
  }
const isMobile = window.innerWidth <= 600

const sendValidatedEmail = (architect, value) => {
  if (value && architect.subscription) {
    sendAccountValidatedEmail(architect.id)
  } else if (value && !architect.subscription) {
    sendAccountValidatedWithoutSubscriptionEmail(architect.id)
  }
}

class ArchitectsTable extends React.Component<IProps> {
  state = {
    isModalVisible: false,
    selectedArchitect: null as ArchitectDTO | null,
    downloadedProducts: [],
    loadingDownloadedProducts: false,
  };

  showModal = (architect: ArchitectDTO) => {
    this.setState({ isModalVisible: true, selectedArchitect: architect, loadingDownloadedProducts: true });
    this.props.fetchProductsDownloadHistory(architect.id, undefined).then((response) => {
      this.setState({ isModalVisible: true, selectedArchitect: architect, downloadedProducts: response.data, loadingDownloadedProducts: false });
    });
  };

  hideModal = () => {
    this.setState({ isModalVisible: false, selectedArchitect: null });
  };

  private productColumns: Array<ColumnProps<ProductLightExtendedDTO>> = [
    {
      title: '',
      dataIndex: 'thumbnail.media.url',
      key: 'thumbnail',
      render: (value: string, record) => (
        <Thumbnail
          src={getCloudImageUrl(value, 54) || thumbnailPlaceholder}
          alt={`Image ${record.name}`}
          size={Size.SMALL}
        />
      ),
      width: '5%'
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.name' }),
      dataIndex: 'name',
      width: '18%',
      render: (value, record) => {
        return <Link to={`/products/${record.id}?tab=info`}>{getProductName(record)}</Link>
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'productpage.panel.brand' }),
      dataIndex: 'brand',
      render: (value: string, record) => (<Link to={`/brands/${record.brand ? record.brand.id : record.brandWebsiteUrl}?tab=info`}>{record.brand ? record.brand.name : ""}</Link>)
    },
    {
      title: this.props.intl.formatMessage({ id: 'producttable.downloaded' }),
      dataIndex: 'lastDownloaded',
      render: value => <FormattedDate value={value} />,
    },
  ];

  private columns: Array<ColumnProps<ArchitectDTO>> = [
    {
      title: <FormattedMessage id="architecttable.name" />,
      dataIndex: 'account.firstName',
      key: 'firstName',
      render: (value, record) => (
        <Link to={`/architects/${record.id}/edit`}>
          {value} {record.account.lastName}
        </Link>
      ),
      sorter: true
    },
    {
      title: 'Mail',
      dataIndex: 'account.email',
      key: 'email'
    },
    {
      title: 'Subscription type',
      dataIndex: 'subscription.slug',
      key: 'subscription'
    },
    {
      title: 'Downloads',
      dataIndex: 'countDownloadedProducts',
      key: 'countDownloadedProducts',
      sorter: true,
      render: (value, record) => (
        <a onClick={() => this.showModal(record)}>
          {value} /&nbsp;<span>{record.subscriptionRemainingDownloads + (record.subscription ? record.subscription.quota : 0)}</span>
        </a>
      ),
    },
    {
      title: <FormattedMessage id="architecttable.update" />,
      dataIndex: 'account.createdAt',
      key: 'createdAt',
      sorter: true,
      render: (value: Date) => moment(value).format('DD/MM/YYYY')
    },
    {
      title: 'Email',
      dataIndex: 'account.emailValidated',
      key: 'emailValidated',
      render: (_, record) => (
        <Switch
          checked={record.account.emailValidated}
          onChange={() => this.props.toggleArchitectEmail(record.id, record.account.emailValidated)}
        />
      )
    },
    {
      title: <FormattedMessage id="architecttable.account" />,
      dataIndex: 'account.enabled',
      key: 'account.enabled',
      render: (value, record) => (
        <Switch
          checked={record.account.enabled}
          onChange={() => { this.props.toggleArchitectAccount(record.id, value); sendValidatedEmail(record, value); }}
        />
      )
    },
    {
      title: '',
      key: 'enabled',
      render: (v, record) =>
        this.canDelete() && (
          !record.deletedAt ? (
            <Popconfirm
              title={this.props.intl.formatMessage({ id: 'global.confirmdelete' })}
              onConfirm={() => this.props.onDelete(record.id)}
              okText={this.props.intl.formatMessage({ id: 'global.yes' })}
              cancelText={this.props.intl.formatMessage({ id: 'global.no' })}
              placement="left"
            >
              <Button
                theme={Theme.GHOST}
                shape="circle"
                icon="delete"
                title={this.props.intl.formatMessage({ id: 'global.delete' })}
                aria-label={`Supprimer ${record.account.lastName}`}
              />
            </Popconfirm>
          ) : (
            <FormattedMessage id='userspage.deleted' />
          )
        ),
      width: '5%',
      dataIndex: 'account.enabled',
      filters: [
        { text: this.props.intl.formatMessage({ id: 'user.enable' }), value: 'true' },
        { text: 'Pending', value: 'false' }
      ],
      filterMultiple: false
    },
  ]

  private mobileColumns: Array<ColumnProps<ArchitectDTO>> = [
    {
      title: <FormattedMessage id="architecttable.name" />,
      dataIndex: 'account.firstName',
      key: 'firstName',
      render: (value, record) => (
        <Link to={`/architects/${record.id}/edit`}>
          {value} {record.account.lastName}
        </Link>
      ),
      sorter: true
    },
    {
      title: 'Mail',
      dataIndex: 'account.email',
      key: 'email'
    },
    {
      title: <FormattedMessage id="architecttable.account" />,
      dataIndex: 'account.enabled',
      key: 'account.enabled',
      render: (value, record) => (
        <Switch
          checked={record.account.enabled}
          onChange={() => this.props.toggleArchitectAccount(record.id, value)}
        />
      )
    },
    {
      title: '',
      key: 'enabled',
      render: (v, record) =>
        this.canDelete() && (
          !record.deletedAt ? (
            <Popconfirm
              title={this.props.intl.formatMessage({ id: 'global.confirmdelete' })}
              onConfirm={() => this.props.onDelete(record.id)}
              okText={this.props.intl.formatMessage({ id: 'global.yes' })}
              cancelText={this.props.intl.formatMessage({ id: 'global.no' })}
              placement="left"
            >
              <Button
                theme={Theme.GHOST}
                shape="circle"
                icon="delete"
                title={this.props.intl.formatMessage({ id: 'global.delete' })}
                aria-label={`Supprimer ${record.account.lastName}`}
              />
            </Popconfirm>
          ) : (
            <FormattedMessage id='userspage.deleted' />
          )
        ),
      width: '5%',
      dataIndex: 'account.enabled',
      filters: [
        { text: this.props.intl.formatMessage({ id: 'user.enable' }), value: 'true' },
        { text: 'Pending', value: 'false' }
      ],
      filterMultiple: false
    }
  ]

  public render() {
    const columns = [...(isMobile ? this.mobileColumns : this.columns)]
    const productColumns = this.productColumns;

    const pagination: PaginationConfig = {
      total: this.props.total,
      pageSize: this.props.pageSize,
      current: this.props.currentPage,
      showTotal: total =>
        `${total} ${this.props.intl.formatMessage({ id: 'architecttable.results' })}`
    }

    // Init sort and filters
    if (this.props.sortBy || this.props.filters) {
      const sortBy = this.props.sortBy
      const filters = this.props.filters

      columns.forEach(column => {
        if (sortBy) {
          const orderValue = column.key
            ? sortBy[column.key]
            : (column.dataIndex && sortBy[column.dataIndex]) || 0

          if (orderValue) {
            column.defaultSortOrder = orderValue > 0 ? 'ascend' : 'descend'
          }
        }

        if (filters) {
          column.filteredValue = column.key
            ? filters[column.key]
            : (column.dataIndex && filters[column.dataIndex]) || undefined
          if (column.key === 'account.enabled') column.filteredValue = filters['enabled']
        }
      })
    }

    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.architects}
          rowKey="id"
          pagination={pagination}
          loading={this.props.loading}
          onChange={this.props.onTableChange}
          rowClassName={this.getRowClassName}
        />
        <Modal
          visible={this.state.isModalVisible}
          onCancel={this.hideModal}
          footer={<> </>}
        >
          {this.state.loadingDownloadedProducts &&
            <Loading></Loading>
          }
          {this.state.selectedArchitect && !this.state.loadingDownloadedProducts && (
            <Table
              columns={productColumns}
              dataSource={this.state.downloadedProducts}
              rowKey="id"
            >
            </Table>
          )}
        </Modal>
      </div>
    )
  }

  private getRowClassName = (record: ArchitectDTO) => {
    return record.deletedAt ? style.deleted : ''
  }
  private canDelete = () => {
    return this.props.accountType === AccountType.AddsomeUser && (this.props.connectedUser as AddsomeUserDTO).perform_deletions
  }
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  connectedUser: state.userState.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  push: (location: string) => dispatch(push(location)),
  toggleArchitectAccount: (id: string, isEnabled: boolean) => {
    dispatch(
      architectActions.thunks.patchValue(id, {
        account: {
          enabled: !isEnabled
        }
      })
    )
  },
  toggleArchitectEmail: (id: string, isEnabled: boolean) => {
    dispatch(
      architectActions.thunks.patchValue(id, {
        account: {
          emailValidated: !isEnabled
        }
      })
    )
  },
  fetchProductsDownloadHistory: (architectId: string, request: IPaginationRequest | undefined) =>
    dispatch(architectProductHistory.fetchArchitectProductDownloads(architectId, request))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ArchitectsTable))
