"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setThumbnailByPictureId = exports.setDisplayActions = exports.unsetSelectedPictureId = exports.setSelectedPictureId = exports.setVariationIndex = exports.unsetProductId = exports.setProductId = exports.setDisplayViewer = void 0;

var model_1 = require("./model");

var setDisplayViewer = function setDisplayViewer(displayViewer) {
  return {
    type: model_1.ActionsTypes.SET_VIEWER_DISPLAY,
    displayViewer: displayViewer
  };
};

exports.setDisplayViewer = setDisplayViewer;

var setProductId = function setProductId(productId) {
  return {
    type: model_1.ActionsTypes.SET_VIEWER_PRODUCT_ID,
    productId: productId
  };
};

exports.setProductId = setProductId;

var unsetProductId = function unsetProductId() {
  return {
    type: model_1.ActionsTypes.SET_VIEWER_PRODUCT_ID
  };
};

exports.unsetProductId = unsetProductId;

var setVariationIndex = function setVariationIndex(variationIndex) {
  return {
    type: model_1.ActionsTypes.SET_VARIATION_INDEX,
    variationIndex: variationIndex
  };
};

exports.setVariationIndex = setVariationIndex;

var setSelectedPictureId = function setSelectedPictureId(pictureId) {
  return {
    type: model_1.ActionsTypes.SET_SELECTED_PICTURE_ID,
    selectedPictureId: pictureId
  };
};

exports.setSelectedPictureId = setSelectedPictureId;

var unsetSelectedPictureId = function unsetSelectedPictureId() {
  return {
    type: model_1.ActionsTypes.UNSET_SELECTED_PICTURE_ID
  };
};

exports.unsetSelectedPictureId = unsetSelectedPictureId;

var setDisplayActions = function setDisplayActions(displayActions) {
  return {
    type: model_1.ActionsTypes.SET_DISPLAY_ACTIONS,
    displayActions: displayActions
  };
};

exports.setDisplayActions = setDisplayActions;

var setThumbnailByPictureId = function setThumbnailByPictureId(pictureId) {
  return {
    type: model_1.ActionsTypes.SET_AS_THUMBNAIL_PICTURE_BY_ID,
    pictureId: pictureId
  };
};

exports.setThumbnailByPictureId = setThumbnailByPictureId;