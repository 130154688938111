"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var BrandInvitationStatus;

(function (BrandInvitationStatus) {
  BrandInvitationStatus["Accepted"] = "accepted";
  BrandInvitationStatus["Pending"] = "pending";
  BrandInvitationStatus["Requested"] = "requested";
  BrandInvitationStatus["Declined"] = "declined";
})(BrandInvitationStatus = exports.BrandInvitationStatus || (exports.BrandInvitationStatus = {}));