import styles from '../StartComponent/StartComponent.module.scss'
import React from 'react'
import { Button, Theme } from '@addsome/ui-kit'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'antd'

const StatusComponent = ({image, message}) => {
  
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  
  return (
    <div className={styles.container}>
      <h3 className={styles.content}>
        <p>
          {message}
        </p>
        <Button className={styles.uploadButton} onClick={() => handleClick()} theme={Theme.BLACK}>
          <FormattedMessage id="community.end.gobacktogallery" /> <Icon type="arrow-up" />
        </Button>
      </h3>
      {image && (
        <div className={styles.imageContainer}>
          <img src={image} alt="First Image" />
        </div>
      )}
    </div>
  );
}

export default StatusComponent