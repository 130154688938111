import { BrandDriveFileWithStatus, UploadStatus } from '@addsome/redux-store/dist/store/drive'
import { Button, Popconfirm, Size, Table, Theme, Thumbnail } from '@addsome/ui-kit'
import Progress from 'antd/lib/progress'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { default as donnees2DImg } from '../../../assets/images/donnees-2D.svg'
import donnees3DImg from '../../../assets/images/donnees-3D.svg'
import pdffile from '../../../assets/images/pdffile.svg'
import zipfile from '../../../assets/images/zipfile.svg'
import { FileType, getFileTypeByName, getFormattedFileType } from '../../../services/filesTypes'
import { PRIMARY } from '../../../utils/colors'
import { getUploadStatus } from '../../../utils/utils'
import styles from './FilesTable.module.scss'

export type CustomBrandDriveFile = BrandDriveFileWithStatus & {
  displayName?: string
}

interface IProps {
  files: CustomBrandDriveFile[]
  onDelete?: (name: string) => void
}

const FilesTable: FC<IProps> = ({ files, onDelete }) => {
  const intl = useIntl()
  const isMobile = window.innerWidth <= 600

  const columns = useMemo(() => {
    const cols: Array<ColumnProps<CustomBrandDriveFile>> = [
      {
        title: '',
        dataIndex: 'url',
        render: (value: string, record) => {
          let img = ''
          const type = record.contentType && getFileTypeByName(record.name)
          if (type && [FileType.model3D, FileType.model3DSettings].includes(type)) {
            img = donnees3DImg
          } else if (type && [FileType.image].includes(type)) {
            img = value
          } else if (type && [FileType.archive].includes(type)) {
            img = zipfile
          } else if (type && [FileType.document].includes(type)) {
            img = pdffile
          } else img = donnees2DImg
          return (
            <Thumbnail
              src={img || donnees2DImg}
              alt={record.displayName}
              size={Size.SMALL}
              className={img !== value ? styles.svgThumbnail : ''}
            />
          )
        },
        width: '5%'
      },
      {
        title: intl.formatMessage({ id: 'drivepage.filestable.name' }),
        dataIndex: 'name',
        render: (value: string, record) => <span>{record.displayName || '-'}</span>,
        width: '30%'
      },
      {
        title: intl.formatMessage({ id: 'drivepage.filestable.status' }),
        dataIndex: 'status',
        render: (value: UploadStatus) => <span>{getUploadStatus(value, intl)}</span>
      },
      {
        title: intl.formatMessage({ id: 'drivepage.filestable.uploaddate' }),
        dataIndex: 'createdAt',
        render: (value: Date) => moment(value).format('DD/MM/YYYY'),
        sorter: (a, b) => {
          if (b.createdAt && a.createdAt)
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          return 0
        }
      },
      {
        title: intl.formatMessage({ id: 'drivepage.filestable.size' }),
        dataIndex: 'size',
        render: (value: number) => `${(value / Math.pow(1024, 2)).toFixed(2)} MB`,
        sorter: (a, b) => {
          if (b.size && a.size) return b.size - a.size
          return 0
        }
      },
      {
        title: intl.formatMessage({ id: 'drivepage.filestable.type' }),
        dataIndex: 'name',
        render: value => (
          <span>
            {value ? (
              getFormattedFileType(getFileTypeByName(value), intl)
            ) : (
              <FormattedMessage id="filetype.unused" />
            )}
          </span>
        ),
        filters: Object.keys(FileType).map(key => {
          return {
            text: getFormattedFileType(key as FileType, intl),
            value: key
          }
        }),
        filterMultiple: true,
        onFilter: (value: FileType, record) =>
          record.contentType ? getFileTypeByName(record.name) === value : false
      },
      {
        title: '',
        render: (value, record) => (
          <div className={styles.actions}>
            {onDelete && record.status === UploadStatus.Done ? (
              <Popconfirm
                title={intl.formatMessage({ id: 'global.confirmdelete' })}
                onConfirm={() => onDelete && onDelete(record.name)}
                okText={intl.formatMessage({ id: 'global.yes' })}
                cancelText={intl.formatMessage({ id: 'global.no' })}
                placement="left"
              >
                <Button
                  theme={Theme.GHOST}
                  shape="circle"
                  icon="delete"
                  title={intl.formatMessage({ id: 'global.delete' })}
                  aria-label={`${intl.formatMessage({ id: 'global.delete' })} ${
                    record.displayName
                  }`}
                />
              </Popconfirm>
            ) : (
              <Progress
                type="circle"
                percent={record.progress}
                showInfo={false}
                width={39}
                strokeWidth={8}
                status="active"
                strokeColor={PRIMARY}
              />
            )}
          </div>
        )
      }
    ]

    const mobileCols: Array<ColumnProps<CustomBrandDriveFile>> = [
      {
        title: '',
        dataIndex: 'url',
        render: (value: string, record) => {
          let img = ''
          const type = record.contentType && getFileTypeByName(record.name)
          if (type && [FileType.model3D, FileType.model3DSettings].includes(type)) {
            img = donnees3DImg
          } else if (type && [FileType.image].includes(type)) {
            img = value
          } else if (type && [FileType.archive].includes(type)) {
            img = zipfile
          } else if (type && [FileType.document].includes(type)) {
            img = pdffile
          } else img = donnees2DImg
          return (
            <Thumbnail
              src={img || donnees2DImg}
              alt={record.displayName}
              size={Size.SMALL}
              className={img !== value ? styles.svgThumbnail : ''}
            />
          )
        },
        width: '5%'
      },
      {
        title: intl.formatMessage({ id: 'drivepage.filestable.name' }),
        dataIndex: 'name',
        render: (value: string, record) => <span>{record.displayName || '-'}</span>,
        width: '30%'
      },
      {
        title: intl.formatMessage({ id: 'drivepage.filestable.status' }),
        dataIndex: 'status',
        render: (value: UploadStatus) => <span>{getUploadStatus(value, intl)}</span>
      },
      {
        title: '',
        render: (value, record) => (
          <div className={styles.actions}>
            {onDelete && record.status === UploadStatus.Done ? (
              <Popconfirm
                title={intl.formatMessage({ id: 'global.confirmdelete' })}
                onConfirm={() => onDelete && onDelete(record.name)}
                okText={intl.formatMessage({ id: 'global.yes' })}
                cancelText={intl.formatMessage({ id: 'global.no' })}
                placement="left"
              >
                <Button
                  theme={Theme.GHOST}
                  shape="circle"
                  icon="delete"
                  title={intl.formatMessage({ id: 'global.delete' })}
                  aria-label={`${intl.formatMessage({ id: 'global.delete' })} ${
                    record.displayName
                  }`}
                />
              </Popconfirm>
            ) : (
              <Progress
                type="circle"
                percent={record.progress}
                showInfo={false}
                width={39}
                strokeWidth={8}
                status="active"
                strokeColor={PRIMARY}
              />
            )}
          </div>
        )
      }
    ]
    return isMobile ? mobileCols : cols
  }, [intl, onDelete, isMobile])

  return <Table columns={columns} dataSource={files} rowKey="name" pagination={false} />
}

export default FilesTable
