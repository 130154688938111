import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  PinterestShareButton,
  PinterestIcon,
} from 'react-share';

function SocialShare({ productTitle, productDescription, productImage, productURL }) {
  return (
    <div>
      <FacebookShareButton url={productURL} quote={productTitle} hashtag="addsome" style={{paddingRight: '5px'}}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      
      <FacebookMessengerShareButton appId="237073772638078" url={productURL} style={{paddingRight: '5px'}}>
        <FacebookMessengerIcon size={32} round />
      </FacebookMessengerShareButton>
      
      <PinterestShareButton url={productURL} media={productImage} description={productTitle} style={{ paddingRight: '5px' }}>
        <PinterestIcon size={32} round />
      </PinterestShareButton>
    </div>
  );
}

export default SocialShare;