import { MediaDTO } from '@addsome/dtos'
import { Button, InputField, Theme } from '@addsome/ui-kit'
import { Form } from 'antd'
import React, { useState } from 'react'
import { FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl'
import styles from './ImportFile.module.scss'

export enum TypeImport {
  information = 'information',
  cao = 'cao',
  picture = 'picture'
}

type IProps = WrappedComponentProps & {
  type: TypeImport
  fileName: string
  media: MediaDTO
  onImport: (name: string) => void
  allFilesNames?: string[]
  allFilesExtentions?: string[]
  allFilesTypes?: string[]
  allFilesSize?: number
}

const ImportFile: React.FC<IProps> = ({ type, fileName, onImport, media, intl,
  allFilesExtentions, allFilesNames, allFilesSize, allFilesTypes }) => {
    
  const [name, setName] = useState(fileName)

  const extension = (media.url && media.url.split('.').pop()) || ''

  return (
    <div className={styles.importFile}>
      <Form noValidate onSubmit={() => onImport(name || fileName)}>
        <Form.Item className={styles.filename}>
          <label>
            <FormattedMessage id={`import.upload.${allFilesNames ? 'filesnames': 'filename'}`} /> :
            <div className={styles.description}>
              {  allFilesNames ? 
                allFilesNames.map((fileName, index) => 
                  <div className={styles.description} key={index}>{fileName}</div>
                )
                : 
                <InputField
                  type="text"
                  name="filename"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder={intl.formatMessage({ id: 'brandspage.addName'})}
                /> }
            </div>
          </label>
        </Form.Item>
        <div className={styles.description}>
          <div>
            <FormattedMessage id={`import.${allFilesTypes? 'types' : 'type'}`} /> : { allFilesTypes ? 
              allFilesTypes.map((fileType, index) => 
                <span key={index}>
                  <FormattedMessage id={`import.${fileType}`} key={index} /> <span> </span>
                </span>
              )
              :
              <FormattedMessage id={`import.${type}`} />
            }
          </div>
          <div>
            <FormattedMessage id={`import.${allFilesExtentions ? 'extensions': 'extension'}`} /> : {allFilesExtentions ? 
              allFilesExtentions.map((fileExtension, index)=>
                <span key={index}>
                  {fileExtension.toUpperCase()} <span> </span>
                </span>  
              )
              :
              extension.toUpperCase()
            } 
          </div>
          <div>
            <FormattedMessage id="import.size" /> :{' '}
            <FormattedNumber value={(allFilesSize ? allFilesSize : media.size) / (1024 * 1024)} maximumFractionDigits={2} /> Mo
          </div>
        </div>
        <Button
          htmlType="submit"
          theme={Theme.PRIMARY}
          block
          uppercase
          size="large"
          className={styles.validate}
        >
          <FormattedMessage id="import.upload.validate" />
        </Button>
      </Form>
    </div>
  )
}

export default injectIntl(ImportFile)
