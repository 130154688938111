"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.unsetQuote = exports.setQuote = void 0;

var model_1 = require("./model");

var setQuote = function setQuote(quote) {
  return {
    type: model_1.ActionsTypes.SET_QUOTE,
    quote: quote
  };
};

exports.setQuote = setQuote;

var unsetQuote = function unsetQuote() {
  return {
    type: model_1.ActionsTypes.UNSET_QUOTE
  };
};

exports.unsetQuote = unsetQuote;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_QUOTE_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;