"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

function IsHexaColor(validationOptions) {
  return function (object, propertyName) {
    class_validator_1.registerDecorator({
      name: 'isHexaColor',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate: function validate(value, args) {
          return /^#[0-9A-F]{6}$/i.test(value);
        },
        defaultMessage: function defaultMessage() {
          return '$value should be a valid hexa color';
        }
      }
    });
  };
}

exports.IsHexaColor = IsHexaColor;