import { ArchitectSubscriptionDTO, ArchitectSubscriptionType, ArchitectSubscriptionPaymentCreationDTO, ArchitectPackDTO } from '@addsome/dtos';
import { apiRequest } from '@addsome/redux-store';

const SUBSCRIPTION_PATH = '/architect-subscription';
const SUBSCRIPTION_PAYMENT_PATH = '/architect-subscription-payment';
const PACK_PATH = 'architect-pack'

export function getSubscriptions(): Promise<ArchitectSubscriptionDTO[]> {
  return apiRequest<ArchitectSubscriptionDTO[]>('GET', `${SUBSCRIPTION_PATH}`);
}

export function getSubscription(slug: ArchitectSubscriptionType): Promise<ArchitectSubscriptionDTO> {
  return apiRequest<ArchitectSubscriptionDTO>('GET', `${SUBSCRIPTION_PATH}/${slug}`);
}

export function setPayment(dto: ArchitectSubscriptionPaymentCreationDTO): Promise<void> {
  return apiRequest<void>('POST', `${SUBSCRIPTION_PAYMENT_PATH}`, dto);
}

export function getPacks(): Promise<ArchitectPackDTO[]> {
  return apiRequest<ArchitectPackDTO[]>('GET', `${PACK_PATH}`);
}

