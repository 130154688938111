import { ObjectProductDTO, ObjectProductDimensionDTO, ObjectProductDimensionUpdateDTO, ObjectProductDimensionCreationDTO, ProductRelationshipsExtendedDTO, ProductUpdateDTO, ObjectProductCreationDTO } from '@addsome/dtos'
import React from 'react'
import { IRootState } from '../../../redux';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { convertObjectProductForUpdate } from '../../../services/convertions'
import ObjectProductForm from '../ObjectProductForm/ObjectProductForm'
import ProductVisuals from '../ProductVisuals'
import styles from './ProductDimensions.module.scss'
import { objectProductDimensions } from '@addsome/redux-store/dist';
import { setUserLog } from '../../../services/userLog';

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    product: ProductRelationshipsExtendedDTO
    objectProduct: ObjectProductDTO
    canEdit?: boolean
    onSubmit: (product: ProductUpdateDTO) => void
  }

interface IState {
  allDimensions: ObjectProductDimensionDTO[];
}

class ProductDimensions extends React.Component<IProps, IState> {

  public state: IState = {
    allDimensions: []
  }

  private async addDimension() {
    if (this.props.product && this.props.product.objectProduct) {
      await this.props.createObjectProductDimension({ objectProductId: this.props.product.objectProduct.id });
      setUserLog(this.props.connectedUser!.account.id, this.props.product.id, 'dimension created')
      this.fetchDimensions(this.props.product.objectProduct.id)
    }
  }

  private async updateDimension(dimensionId, objectProductDimension) {
    if (this.props.product && this.props.product.objectProduct) {
      await this.props.updateObjectProductDimension(dimensionId, objectProductDimension);
      this.fetchDimensions(this.props.product.objectProduct.id)
    }
  }

  private async removeDimension(objectProductDimensionId) {
    if (this.props.product && this.props.product.objectProduct && objectProductDimensionId) {
      await this.props.deleteObjectProductDimension(objectProductDimensionId);
      setUserLog(this.props.connectedUser!.account.id, this.props.product.id, 'dimension deleted')
      this.fetchDimensions(this.props.product.objectProduct.id)
    }
  }

  private async fetchDimensions(id) {
    const dimensions = await this.props.fetchObjectProductDimensions(id);
    this.setState({ allDimensions: dimensions });
  }

  public async componentDidMount() {
    if (this.props.product && this.props.product.objectProduct) {
      this.fetchDimensions(this.props.product.objectProduct.id)
    }
  }

  public render() {
    const { loading } = this.props
    return (
      <div className={styles.productDimensions}>
        <ProductVisuals product={this.props.product} />
        <ObjectProductForm
          objectProductDimensions={this.state.allDimensions}
          loading={loading}
          canEdit={this.props.canEdit}
          product={this.props.product}
          user={this.props.connectedUser}
          objectProduct={convertObjectProductForUpdate(this.props.objectProduct)}
          onSubmit={objProd => this.props.onSubmit({ objectProduct: { ...objProd, type: undefined } })}
          addDimension={() => this.addDimension()}
          removeDimension={(opdId) => this.removeDimension(opdId)}
          updateDimension={(opdId, opd) => this.updateDimension(opdId, opd)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  objectProductDimensions: state.objectProductDimensionsState.objectProductDimensions,
  loading: state.objectProductDimensionsState.loading,
  connectedUser: state.userState.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchObjectProductDimensions: (objectProductId: string) =>
    dispatch(objectProductDimensions.fetchObjectProductDimensions(objectProductId)),
  createObjectProductDimension: (objectProductDimension: ObjectProductDimensionCreationDTO) =>
    dispatch(objectProductDimensions.createObjectProductDimension(objectProductDimension)),
  deleteObjectProductDimension: (objectProductDimensionId: string) =>
    dispatch(objectProductDimensions.deleteObjectProductDimension(objectProductDimensionId)),
  updateObjectProductDimension: (objectProductDimensionId: string, objectProductDimension: ObjectProductDimensionUpdateDTO) =>
    dispatch(objectProductDimensions.updateObjectProductDimension(objectProductDimensionId, objectProductDimension))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDimensions);
