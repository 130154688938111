import { ObjectProductDTO, AddsomeUserDTO, ArchitectDTO, BrandUserWithBrandsDTO, ProductRelationshipsExtendedDTO, ProductUpdateDTO } from '@addsome/dtos'
import React from 'react'
import {
  convertObjectProductForUpdate,
  convertProductForUpdate
} from '../../../services/convertions'
import ProductTagsForm from '../ProductTagsForm/ProductTagsForm'
import ProductVisuals from '../ProductVisuals'
import styles from './ProductTags.module.scss'
import { setUserLog } from '../../../services/userLog';

interface IProps {
  product: ProductRelationshipsExtendedDTO
  objectProduct: ObjectProductDTO
  onSubmit: (product: ProductUpdateDTO) => void,
  user?: AddsomeUserDTO | ArchitectDTO | BrandUserWithBrandsDTO | null,
  canEdit?: boolean,
}

const ProductTags: React.FC<IProps> = ({ product, objectProduct, onSubmit, user, canEdit }) => (
  <div className={styles.productTags}>
    <ProductVisuals product={product} />
    <ProductTagsForm
      product={convertProductForUpdate(product)}
      objectProduct={convertObjectProductForUpdate(objectProduct)}
      canEdit={canEdit}
      onSubmit={productUpdate => {
        onSubmit(productUpdate)
        setUserLog(user!.account.id, product.id, 'product specification updated')
      }}
    />
  </div>
)

export default ProductTags
