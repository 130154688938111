"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_METRICS_LOADING"] = "SET_METRICS_LOADING";
  ActionsTypes["FETCH_METRICS"] = "FETCH_METRICS";
  ActionsTypes["SET_METRICS"] = "SET_METRICS";
  ActionsTypes["FETCH_OBJECT_PRODUCT_TYPES_METRICS"] = "FETCH_OBJECT_PRODUCT_TYPES_METRICS";
  ActionsTypes["SET_OBJECT_PRODUCT_TYPES_METRICS"] = "SET_OBJECT_PRODUCT_TYPES_METRICS";
  ActionsTypes["FETCH_OBJECT_PRODUCT_METRICS"] = "FETCH_OBJECT_PRODUCT_METRICS";
  ActionsTypes["SET_OBJECT_PRODUCT_METRICS"] = "SET_OBJECT_PRODUCT_METRICS";
  ActionsTypes["FETCH_BRAND_METRICS"] = "FETCH_BRAND_METRICS";
  ActionsTypes["SET_BRAND_METRICS"] = "SET_BRAND_METRICS";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));