import { AccountType, BrandLightDTO, BrandUserWithBrandsDTO } from '@addsome/dtos'
import { Menu } from 'antd'
import classnames from 'classnames'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import usePush from '../../hooks/usePush'
import useLoggedUser from '../../hooks/useUser'
import Routes from '../../utils/routes'
import styles from './NavBar.module.scss'

interface IProps {
  darkTheme: boolean
}

const NavBar: React.FC<IProps> = ({ darkTheme }) => {
  const { accountType, user } = useLoggedUser()
  const { router } = usePush()
  const intl = useIntl()
  const [menuItems, setMenuItems] = useState<JSX.Element[]>([])

  const selectedKeys = useMemo(() => [`/${router.location.pathname.split('/')[1]}`], [
    router.location.pathname
  ])

  useEffect(() => {
    let menuItemsTmp: JSX.Element[] = []

    menuItemsTmp.push(
      <Menu.Item key={Routes.Index} className={styles.item}>
        <Link to={Routes.Index}>
          {accountType === AccountType.BrandUser
            ? intl.formatMessage({ id: 'navBar.index.brandUser' })
            : intl.formatMessage({ id: 'navBar.index.other' })}
        </Link>
      </Menu.Item>
    )

    switch (accountType) {
    case AccountType.Architect:
      menuItemsTmp = menuItemsTmp.concat([
        <Menu.Item key={Routes.Catalog} className={styles.item}>
          <Link to={Routes.Catalog}>{intl.formatMessage({ id: 'navBar.catalog' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Brands} className={styles.item}>
          <Link to={Routes.Brands}>{intl.formatMessage({ id: 'navBar.brands' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.MySpace} className={styles.item}>
          <Link to={Routes.MySpace}>{intl.formatMessage({ id: 'navBar.mySpace' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Community} className={styles.item}>
          <Link to={Routes.Community}>{intl.formatMessage({ id: 'navBar.community' })}</Link>
        </Menu.Item>
      ])
      break

    case AccountType.AddsomeUser:
      menuItemsTmp = menuItemsTmp.concat([
        <Menu.Item key={Routes.Brands} className={styles.item}>
          <Link to={Routes.Brands}>{intl.formatMessage({ id: 'navBar.brands' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Catalog} className={styles.item}>
          <Link to={Routes.Catalog}>{intl.formatMessage({ id: 'navBar.catalog' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Products} className={styles.item}>
          <Link to={Routes.Products}>{intl.formatMessage({ id: 'navBar.products' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Users} className={styles.item}>
          <Link to={Routes.Users}>{intl.formatMessage({ id: 'navBar.users' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Community} className={styles.item}>
          <Link to={Routes.Community}>{intl.formatMessage({ id: 'navBar.community' })}</Link>
        </Menu.Item>
      ])
      break

    case AccountType.BrandUser:
      const firstBrand: BrandLightDTO | null =
          (user as BrandUserWithBrandsDTO).brands &&
            (user as BrandUserWithBrandsDTO).brands.length > 0
            ? (user as BrandUserWithBrandsDTO).brands[0]
            : null

      menuItemsTmp = menuItemsTmp.concat([
        <Menu.Item key={Routes.Clients} className={styles.item}>
          <Link to={Routes.Clients}>{intl.formatMessage({ id: 'navBar.clients' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Catalog} className={styles.item}>
          <Link
            to={firstBrand ? `${Routes.Brands}/${firstBrand.id}?tab=products` : Routes.Brands}
          >
            {intl.formatMessage({ id: 'navBar.catalog' })}
          </Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Drive} className={styles.item}>
          <Link to={firstBrand ? `${Routes.Drive}/${firstBrand.slug}` : Routes.Drive}>
            {intl.formatMessage({ id: 'navBar.drive' })}
          </Link>
        </Menu.Item>
      ])

      break

    default:
      menuItemsTmp = menuItemsTmp.concat([
        <Menu.Item key={Routes.Catalog} className={styles.item}>
          <Link to={Routes.Catalog}>{intl.formatMessage({ id: 'navBar.catalog' })}</Link>
        </Menu.Item>,
        <Menu.Item key={Routes.Community} className={styles.item}>
          <Link to={Routes.Community}>{intl.formatMessage({ id: 'navBar.community' })}</Link>
        </Menu.Item>
      ])
      break
    }

    setMenuItems(menuItemsTmp)
  }, [accountType, intl, user])

  return (
    <nav
      className={classnames(styles.navbar, {
        [styles.darkTheme]: darkTheme
      })}
    >
      <Menu className={styles.menu} theme="light" mode="horizontal" selectedKeys={selectedKeys}>
        {menuItems}
      </Menu>
    </nav>
  )
}

export default memo(NavBar)
