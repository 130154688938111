import { Heading, Size, Card, CategoryColor } from '@addsome/ui-kit'
import { ProductLightExtendedDTO } from '@addsome/dtos'
import React, { memo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import bg from '../../../assets/images/home-other-bg.png';
import CatalogSection from '../../../components/CatalogSection/CatalogSection'
import MetricCardGeneric from '../../../components/MetricCardGeneric/MetricCardGeneric'
import PageCardsGeneric from '../../../components/PageCards/PageCardsGeneric'
import PageCards from '../../../components/PageCards/PageCards'
import PageHeader from '../../../components/PageHeaders/PageHeader'
import Section from '../../../components/Section/Section'
import { replacePageTitle } from '../../../services/title'
import productToCardData from '../../../utils/productToCardData'
import Routes from '../../../utils/routes'
import styles from './HomePageAll.module.scss'
import useMetricsArchitect from '../HomePageArchitect/useMetricsArchitect'
import logo from '../../../assets/images/new-logo-white.png'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { abstractRouter, IRootState } from '../../../redux'
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl'
import {
  objectProductCategories as objectProductCategory,
  roomTag,
  metrics as metricsActions,
  auth,
  product as productActions,
  productViewer,
  objectProduct,
  objectProductVariations,
} from '@addsome/redux-store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import Footer from '../../../components/Footer/Footer'
import PublicHeader from '../../../components/PublicHeader/PublicHeader'
import ProductViewerModal from '../../../components/Player/ProductViewModal/ProductViewerModal'
import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel';
import RegisterViewModal from '../../RegisterPage/RegisterViewModal'

type IProps = WrappedComponentProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const HomePageAll: React.FC<IProps> = ({ objectProductCategories, roomTags, metrics, brandMetrics, fetchMetrics, showProductModal }) => {
  const {
    architectMetricsFull,
    brands,
    recentProducts,
    mostDownloadedProducts,
    selectionProducts,
    loadings,
    objectCategories,
    roomsCategories
  } = useMetricsArchitect()
  const [globalMetrics, setGlobalMetrics] = useState<any[]>([]);
  const dispatch: ThunkDispatch<IRootState, {}, AnyAction> = useDispatch();

  const intl = useIntl()
  replacePageTitle(intl.formatMessage({ id: 'homepage.seo.title' }))

  useEffect(() => {
    fetchMetrics()
    dispatch(objectProductCategory.thunks.fetchValues());
    dispatch(roomTag.thunks.fetchValues());
    dispatch(auth.logout(abstractRouter));

    Mixpanel.track(MixpanelEvents.PublicHomePageViewed)
  }, [])
  const findCategory = (filter) => {
    let subcategory;

    let category = objectProductCategories.find(opc => {
      return opc.childs.find(c => c.name == filter)
    });

    if (category) {
      subcategory = category.childs.find(c => c.name === filter)
    }

    return subcategory ? subcategory.id : ''
  }

  const findRoom = (filter) => {
    let room = roomTags.find(rt => rt.name === filter)

    return room ? room.id : ''
  }

  useEffect(() => {
    console.log(metrics)
    if (!metrics) return;

    const metricsArray: any[] = [
      ...([
        {
          entityName: <FormattedMessage id="homepage.metrics.brands.title" />,
          nbEntities:
            metrics.brandDefaultTypeCount.furniture +
            metrics.brandDefaultTypeCount.lighting +
            metrics.brandDefaultTypeCount.accessories +
            metrics.brandDefaultTypeCount.unset,
          diagramDatas: [
            {
              label: intl.formatMessage({ id: 'global.furniture' }),
              value: metrics.brandDefaultTypeCount.furniture,
              color: CategoryColor.LIGHT_GREY
            },
            {
              label: intl.formatMessage({ id: 'global.lighting' }),
              value: metrics.brandDefaultTypeCount.lighting,
              color: CategoryColor.CYAN
            },
            {
              label: intl.formatMessage({ id: 'global.accessories' }),
              value: metrics.brandDefaultTypeCount.accessories,
              color: CategoryColor.DARK_BLUE
            }
          ],
          recents: brands.slice(0, 8).map(brand => ({
            img: (brand.logo && brand.logo.url) || '',
            label: brand.name,
            link: `${Routes.Catalog}?brandIds=${brand.id}`
          })),
          bigButton: {
            label: intl.formatMessage({ id: 'homepage.metrics.brands.labelviewallentities' }),
            link: `${Routes.Catalog}`
          }
        }
      ]),
      {
        entityName: <FormattedMessage id="homepage.metrics.products.title" />,
        nbEntities: metrics.objectProductCount.furniture +
          metrics.objectProductCount.lighting +
          metrics.objectProductCount.accessories,
        diagramDatas: [
          {
            label: intl.formatMessage({ id: 'global.furniture' }),
            value: metrics.objectProductCount.furniture,
            color: CategoryColor.LIGHT_GREY
          },
          {
            label: intl.formatMessage({ id: 'global.lighting' }),
            value: metrics.objectProductCount.lighting,
            color: CategoryColor.CYAN
          },
          {
            label: intl.formatMessage({ id: 'global.accessories' }),
            value: metrics.objectProductCount.accessories,
            color: CategoryColor.DARK_BLUE
          }
        ],
        recents: recentProducts.slice(0, 8).map(product => ({
          img: (product.thumbnail && product.thumbnail.media && product.thumbnail.media.url) || '',
          label: product.name,
          link: `/products/${product.id}`,
          id: product.id,
        })),
        bigButton: {
          label: intl.formatMessage({ id: 'homepage.metrics.products.labelviewallentities' }),
          link: `/catalog`
        },
        onThumbnailClick: (id) => onRecentProductsClick(id)
      },
      {
        entityName: <FormattedMessage id="homepage.metrics.files.title" />,
        nbEntities: metrics.objectProductWithBundleCount.furniture +
          metrics.objectProductWithBundleCount.lighting +
          metrics.objectProductWithBundleCount.accessories,
        diagramDatas: [
          {
            label: intl.formatMessage({ id: 'global.furniture' }),
            value: metrics.objectProductWithBundleCount.furniture,
            color: CategoryColor.LIGHT_GREY
          },
          {
            label: intl.formatMessage({ id: 'global.lighting' }),
            value: metrics.objectProductWithBundleCount.lighting,
            color: CategoryColor.CYAN
          },
          {
            label: intl.formatMessage({ id: 'global.accessories' }),
            value: metrics.objectProductWithBundleCount.accessories,
            color: CategoryColor.DARK_BLUE
          }
        ],
        recents: mostDownloadedProducts.slice(0, 8).map(product => ({
          img: (product.thumbnail && product.thumbnail.media && product.thumbnail.media.url) || '',
          label: product.name,
          link: `/products/${product.id}`,
          id: product.id
        })),
        bigButton: {
          label: intl.formatMessage({ id: 'homepage.metrics.files.labelviewallentities' }),
          link: '/catalog?haveObjectProductModelBundle=true'
        },
        onThumbnailClick: (id) => onMostDownloadedClick(id)
      }
    ];
    setGlobalMetrics(metricsArray);
  }, [metrics, brandMetrics, brands, mostDownloadedProducts, recentProducts, intl]);

  const onMostDownloadedClick = (id) => {
    const selectedProduct = mostDownloadedProducts.find(x => x.id === id)
    if (selectedProduct) {
      showProductModal(selectedProduct, selectedProduct.id, selectedProduct.objectProductId)
    }
  }

  const onSelectionClick = (id) => {
    const selectionProduct = selectionProducts.find(x => x.id === id)
    if (selectionProduct) {
      showProductModal(selectionProduct, selectionProduct.id, selectionProduct.objectProductId)
    }
  }

  const onRecentProductsClick = (id) => {
    const recentProduct = recentProducts.find(x => x.id === id)
    if (recentProduct) {
      showProductModal(recentProduct, recentProduct.id, recentProduct.objectProductId)
    }
  }

  return (
    <>
      <PublicHeader darkTheme={false} />
      <PageHeader
        title={
          <>
            <Heading level={1} strong centerText>
              {intl.formatMessage({ id: "architect.type.design" })}
            </Heading>
            <span>
              {intl.formatMessage({ id: 'global.welcome' })}
            </span>
          </>
        }
        size={Size.LARGE}
        hasCards
        backgroundSrc={bg}
      />

      <div className={styles.allContainer}>
        <PageCards metricsArray={globalMetrics} />

        <div className={styles.contentContainer}>

          <div className={styles.sectionCategory}>
            {objectCategories.map((oc, idx) => (
              <Link key={idx} to={`/catalog?objectProductCategoryIds=${findCategory(oc.filter)}`}>
                <Card
                  className={styles.card}
                  title={intl.formatMessage({ id: `catalog.filters.${oc.label}` })}
                  footer={intl.formatMessage({ id: `catalog.filters.${oc.label}Description` })}
                  inner
                  image={oc.img}
                  href
                />
              </Link>
            ))}
          </div>

          <div className={styles.sectionCategory}>
            {roomsCategories.map((rc, idx) => (
              <Link key={idx} to={`/catalog?roomTagIds=${findRoom(rc.filter)}`}>
                <Card
                  className={styles.card}
                  title={intl.formatMessage({ id: `catalog.filters.${rc.label}` })}
                  footer={intl.formatMessage({ id: `catalog.filters.${rc.label}Description` })}
                  inner
                  image={rc.img}
                  href
                />
              </Link>
            ))}
          </div>

          <Section
            loading={loadings.selectionProductsLoading}
            title={intl.formatMessage({ id: 'homepage.section.titleaddsomeselection' })}
            buttonLabel={intl.formatMessage({ id: 'global.displayall' })}
            buttonRoute={Routes.Catalog}
            cardsData={selectionProducts.map(product => productToCardData(product))}
            onProductClick={onSelectionClick}
            green
            oneLine
          />

          <Section
            loading={loadings.mostDownloadedProductsLoading}
            title={intl.formatMessage({ id: 'homepage.section.title.mostDownloaded' })}
            buttonLabel={intl.formatMessage({ id: 'global.displayall' })}
            buttonRoute={Routes.Catalog}
            cardsData={mostDownloadedProducts.map(product => productToCardData(product))}
            mobileHidden={true}
            onProductClick={onMostDownloadedClick}
          />

          <Section
            loading={loadings.brandsLoading}
            noPaddingTop
            title={intl.formatMessage({ id: 'homepage.section.titlelatestbrands' })}
            buttonLabel={intl.formatMessage({ id: 'global.displayall' })}
            buttonRoute={Routes.Brands}
            brandsData={brands}
          />
        </div>
        <CatalogSection />
        <ProductViewerModal />
        <RegisterViewModal />
      </div>
      <Footer></Footer>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  objectProductCategories: state.objectProductCategoriesState.values,
  roomTags: state.roomTagState.values,
  metrics: state.metricsState.metrics,
  brandMetrics: state.metricsState.brandMetrics,
  brands: state.brandState.values,
  products: state.productState.values
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchMetrics: () => dispatch(metricsActions.fetchMetrics()),
  showProductModal: async (product: ProductLightExtendedDTO, productId: string, objectProductId?: string | null) => {
    dispatch(productActions.thunks.fetchValue(productId))
    dispatch(productViewer.setProductId(productId))
    // Only fecth again if the product changed
    if (!!objectProductId) {
      await dispatch(objectProduct.thunks.fetchValue(objectProductId))
      await dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId))
    }
    dispatch(productViewer.setDisplayViewer(true))
    if (product.stateId !== '500' && product.thumbnail) {
      dispatch(productViewer.setSelectedPictureId(product.thumbnail.id))
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HomePageAll))
