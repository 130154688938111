import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { updatePageTitle } from '../../services/title'
import LoginForm from './LoginForm/LoginForm'
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux';
import ConnectionLayout from '../../components/ConnectionLayout/ConnectionLayout'
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel'
import { push } from 'connected-react-router';
import RegisterViewModal from '../RegisterPage/RegisterViewModal'
import { Helmet } from "react-helmet";

const LoginPage: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch();
  const authToken = useSelector((state: IRootState) => state.authState.token)

  useEffect(() => {
    if (authToken) {
      dispatch(push('/'));
    }
  }, [dispatch])

  useEffect(() => {
    // Following the logic from https://help.mixpanel.com/hc/en-us/articles/360001355506-Tracking-First-Time-Users
    Mixpanel.track(MixpanelEvents.LoginPageViewed, { 'First Time': 'TRUE' })
    Mixpanel.register({ 'First Time': 'FALSE' })
  }, [])
  
  return (
    <ConnectionLayout
      title={
        <>
          {intl.formatMessage({ id: 'login.title' })}
          <br />
          Addsome Drive
        </>
      }
      subtitle={intl.formatMessage({ id: 'login.presentation' })}
    >
      <Helmet>
        <title>{intl.formatMessage({ id: 'login.seo.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'login.seo.description' })} />
      </Helmet>
      <LoginForm />
      <RegisterViewModal />
    </ConnectionLayout>
  )
}

export default LoginPage
