import {
  AccountType,
  ObjectProductDimensionDTO,
  ObjectProductVariationDTO,
  ProductMaterialTagDTO,
  ProductRelationshipsExtendedDTO,
  ArchitectWithBrandDTO,
  BrandLightDTO,
  ErrorsCode,
  ProductFileType,
  ProductProblemCreationDTO,
  ArchitectSubscriptionType, ArchitectDTO
} from '@addsome/dtos';
import { Container, Heading, Option, Select, Button, Size } from '@addsome/ui-kit';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import logoPlaceholder from '../../../assets/images/donnees-2D.svg';
import logo3DPlaceholder from '../../../assets/images/donnees-3D.svg';
import textureLogo from '../../../assets/images/texture_icon.svg'
import Price from '../../Price';
import styles from './ProductInformationPanel.module.scss';
import Routes from '../../../utils/routes';
import getProductName from '../../../utils/getProductName';
import { Icon } from 'antd';
import useLoggedUser from '../../../hooks/useUser'
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from '../../../redux';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import {
  setShouldShowArchitectNotEnabledDownloadPopup,
  setShouldShowArchitectNotLinkedToBrandDownloadPopup
} from '@addsome/redux-store/dist/store/auth';
import {
  axiosInstance,
  architect as architectActions,
  productProblem, collections,
  productMaterialTag,
  user
} from '@addsome/redux-store';
import { downloadBlob } from '../../../utils/download';
import slugify from 'slugify';
import { hasDownloadedProduct, createDownloadedProduct } from '../../../services/productDownload'
import { auth, productViewer } from '@addsome/redux-store'
import { hasActiveSubscription, setTrialSubscriptionNoRemainingDownloads, setNoSubscriptionWithRemainingDownloads } from '../../../services/payment'
import { Modal, Theme } from '@addsome/ui-kit'
import { push, RouterState } from 'connected-react-router'
import CreationModalWrapper from '../../CreationModalWrapper/CreationModalWrapper';
import ProductProblemCreationForm from '../ProductProblems/ProductProblemCreationForm';
import { reportProblemEmail } from '../../../services/architectAccount'
import heartEmptyBlack from "../../../assets/images/heartEmptyBlack.png";
import heartFullBlack from "../../../assets/images/heartFullBlack.png";
import clipboardCopy from "../../../assets/images/clipboard-copy.png";
import { CollectionDtoWithProducts } from '@addsome/dtos/dist/dtos/collection';
import { removeProductFromAllCollections } from "@addsome/redux-store/dist/store/collections";
import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel';
import RegisterViewModal from '../../../pages/RegisterPage/RegisterViewModal'
import SocialShare from '../../SocialShare/SocialShare'

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  WrappedComponentProps & {
    product: ProductRelationshipsExtendedDTO;
    countryName: string;
    variationIndex: number | undefined;
    productMaterialTags: ProductMaterialTagDTO[];
    objectProductVariations: ObjectProductVariationDTO[];
    objectProductDimensions: ObjectProductDimensionDTO[];
    title: React.ReactNode;
    isInCatalog: boolean | undefined;
    isShowing3d: boolean | undefined;
    isDownloading: boolean | undefined;
    useNewPlayer: boolean | undefined;
    handleVariationChange: React.ReactNode;
    convertForFront: (dimension: number) => void;
    convertDepthForFront: (dimension: number) => void;
    accountType: AccountType,
    router: RouterState,
    createNewCollection: any,
    addProductsToCollection: any,
    removeProductFromAllCollections: any,
    collections: CollectionDtoWithProducts[];
    fetchCollectionsList: any;
    onDownload3dVisualization: (format) => void;
  };

const InformationPanelDesktopView: React.FC<IProps> = ({
  product,
  countryName,
  productMaterialTags,
  title,
  variationIndex,
  handleVariationChange,
  objectProductVariations,
  objectProductDimensions,
  convertForFront,
  convertDepthForFront,
  isInCatalog,
  isShowing3d,
  isDownloading,
  useNewPlayer,
  accountType,
  productProblemTypes,
  showNotEnabledModal,
  showNotLinkedModal,
  patchArchitectDownloads,
  getAuth,
  pushRouter,
  setDisplayViewer,
  createProductProblem,
  intl,
  router,
  createNewCollection,
  addProductsToCollection,
  removeProductFromAllCollections,
  collections,
  fetchCollectionsList,
  fetchProductMaterialTags,
  setDisplayRegisterUser,
  onDownload3dVisualization
}) => {

  const [showFiles, setShowFiles] = useState(router.location.pathname === Routes.Downloads ? true : false);
  const { user } = useLoggedUser()
  const [totalDownloads, setTotalDownloads] = useState(0)
  const [showQuotaPopup, setShowQuotaPopup] = useState(false)
  const [showExceededQuotaPopup, setShowExceededQuotaPopup] = useState(false)
  const [hasReachedLastQuota, setHasReachedLastQuota] = useState(false)
  const [displayCreationProblemModal, setDisplayCreationProblemModal] = useState(false)
  const [displayLoginModal, setDisplayLoginModal] = useState(false);
  const [activeDownloadId, setActiveDownloadId] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const shortenedDescription = product.description ? product.description.substring(0, 180) + "..." : '';
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const textToCopy = `${window.location.origin}/catalog/${product.slug}?productIds=${product.id}`;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess(true);

      // Hide the success message after 2 seconds
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);

    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    if (accountType === AccountType.Architect && user.subscription) {
      hasActiveSubscription(user.account.email).then((activeSubscription) => {
        if (!activeSubscription && user.subscription!.slug != ArchitectSubscriptionType.Standard10) {
          setNoSubscriptionWithRemainingDownloads(user.id).then((res) => {
            getAuth()
          })
        }
      });
    }

    if (user && user.downloads) {
      setTotalDownloads(user.downloads)
    }
  }, [user])

  useEffect(() => {
    if (!isDownloading) {
      setActiveDownloadId(null);
    }
  }, [isDownloading]);

  useEffect(() => {
    fetchProductMaterialTags()
  }, [])

  const handleCreateProblem = async (problem) => {
    setDisplayCreationProblemModal(false)
    await createProductProblem(problem)
    const type = productProblemTypes.find(p => p.id == problem.typeId)
    reportProblemEmail(product, user.account.email, type.name, problem.comment)
  }

  const handleAddProductToLatestCollection = async (productId: string) => {
    const collection = collections[0];
    // If there is no collection, create one
    if (collection == undefined) {
      createNewCollection({ name: "Default", products: [{ id: productId }] },
        intl.formatMessage({ id: 'architect.collection.add.success' }),
        intl.formatMessage({ id: 'architect.collection.add.error' })).then(() => { fetchCollectionsList(); })
      return true;
    }
    const collectionProductIds = collection ? collection.products.map(product => ({ id: product.id })) : [];
    const payload = { products: [...collectionProductIds, { id: productId }] };
    return addProductsToCollection(collection.id,
      payload,
      intl.formatMessage({ id: 'architect.collection.add.product.success' }, { collectionName: collection.name }),
      intl.formatMessage({ id: 'architect.collection.add.product.error' }, { collectionName: collection.name }))
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  const handleRemoveProductFromCollections = async (productId: string) => {
    removeProductFromAllCollections(
      productId,
      intl.formatMessage({ id: 'architect.collection.remove.product.all.collections.success' }),
      intl.formatMessage({ id: 'architect.collection.remove.product.all.collections.error' })
    );
  }

  const handleShow3DFiles = () => {
    if (user) {
      setShowFiles(true);
    } else {
      setDisplayLoginModal(true);
      Mixpanel.track(MixpanelEvents.PublicAccess3DFiles)
    }
  }


  const isProductInCollectionAnyCollection = (productId: string) => {
    if (collections && collections.length > 0) {
      // Loop through each collection
      for (const collection of collections) {
        if (collection && collection.products) {
          // Check if the product is present in the current collection
          const found = collection.products.some((product) => product.id === productId);
          if (found) {
            return true; // Product found in at least one collection
          }
        }
      }
    }
    return false; // Product not found in any collection
  };


  const handleDownloadFile = async (fileType, file, format = 'glb', existingFile = true) => {
    if (!user) {
      return;
    }

    if (accountType === AccountType.Architect) {
      const architect = user as ArchitectWithBrandDTO;

      if (!architect.account.enabled) {
        showNotEnabledModal();
        return;
      }
    }

    let dataFileId;
    switch (format) {
    case 'fbx':
      dataFileId = `${file.id}-fbx`;
      break;
    case 'obj':
      dataFileId = `${file.id}-obj`;
      break;
    case 'dae':
      dataFileId = `${file.id}-dae`;
      break;
    default:
      dataFileId = file.id;
      break;
    }

    setActiveDownloadId(dataFileId);

    const downloadAvailableResponse = await axiosInstance.get(
      `/product-file/download-available/${product.id}?ts=${Date.now()}`,
      {
        params: {
          fileType
        }
      }
    );

    if (!downloadAvailableResponse.data.downloadAvailable) {
      setShowQuotaPopup(true);
      setHasReachedLastQuota(true);
      setActiveDownloadId(null);

      return;
    }

    if (downloadAvailableResponse.data.maxQuotaExceeded) {
      setShowExceededQuotaPopup(true);
      setShowQuotaPopup(true);
      setActiveDownloadId(null);

      return;
    }

    if (existingFile) {
      // We add a timestamp to prevent caching on the browser side
      const blob: { data: BlobPart; } = await axiosInstance.get(
        `/product-file/${file.id}?ts=${Date.now()}`,
        {
          params: {
            fileType
          },
          responseType: 'blob'
        }
      );

      const hasExt = (file.name || product.name).toLowerCase().includes(file.extension.toLowerCase());
      const filename = `${slugify(product.brand.name, { lower: true })}_${slugify(
        file.name || product.name,
        { lower: true }
      )}${hasExt ? '' : '.' + file.extension}`;

      downloadBlob(blob.data, filename);
      setActiveDownloadId(null);

      Mixpanel.trackProductDownloaded({
        brandId: product.brand.id,
        brandName: product.brand.name,
        productId: product.id,
        productName: product.name,
        mediaId: file.media.id
      });
    } else {
      onDownload3dVisualization(format);
    }

    if (accountType == AccountType.Architect) {
      const hasDownloaded = await hasDownloadedProduct(user.id, product.id);

      if (!hasDownloaded) {
        const downloads = totalDownloads + 1;
        setTotalDownloads(downloads);
        createDownloadedProduct(user.id, product.id).then(() => {
          getAuth();
        })
      }
    }
  }

  const getGlbFileName = () => {
    const file = product.sourceFiles.find(p => p.extension === 'glb')

    return file ? file.name.replace('.glb', '') : ''
  }

  const handleDownloadFileWithTexture = (file, index, type: string) => {
    handleVariationChange(index);
    handleDownloadFile(ProductFileType.SourceFile, file, type, false);
  }

  return (
    <div className={styles.productInformationPanel}>
      {title && (
        <Heading level={3} as="h2" className={styles.title}>
          {title}
        </Heading>
      )}
      <Container className={styles.data}>
        {showFiles ?
          <div>
            <dl style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={styles.headerGroup}>
                <div>
                  <img
                    src={
                      (product.brand && product.brand.logo && product.brand.logo.url) || logoPlaceholder
                    }
                    alt={`Logo ${product.brand ? product.brand.name : ''}`}
                  />
                </div>
                <div className={styles.header}>
                  <div className={styles.title}>
                    <h1 className={styles.name}>{getProductName(product)}</h1>
                  </div>
                  <div>
                    {product.brand && product.brand.madeIn && countryName && (
                      <div>
                        <FormattedMessage id="productpage.panel.country" /> {countryName}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {accountType == AccountType.Architect && user.subscription && user.subscription.slug !== ArchitectSubscriptionType.Unlimited &&
                <div className={styles.remainingDownloads}>
                  <span>{user.downloads}/{user.subscription ? user.subscription.quota + user.subscriptionRemainingDownloads : user.subscriptionRemainingDownloads}&nbsp;</span>
                </div>
              }
            </dl>
            <div className={styles.productFiles}>
              <div className={styles.backWrapper}>
                <Icon type="info-circle" className={styles.backToInfo} />
                <h5>{intl.formatMessage({ id: 'productspage.countDownloads' })}</h5>
              </div>
              {useNewPlayer && objectProductVariations && (
                <div>
                  <div className={styles.variationDiv}>
                    <h3>
                      <FormattedMessage id="productpage.panel.variations" />
                    </h3>
                    <div style={{ cursor: "pointer" }} onClick={() => setShowFiles(false)} >
                      <Icon type="arrow-left" style={{ marginRight: '5px' }} />
                      <FormattedMessage id="productpage.panel.back" />
                    </div>
                  </div>

                  <dd className={styles.selectContainer}>
                    {objectProductVariations && objectProductVariations.length > 0 ? (
                      <Select
                        value={variationIndex}
                        onChange={handleVariationChange}
                        disabled={objectProductVariations && objectProductVariations.length < 2}
                        showArrow={objectProductVariations && objectProductVariations.length > 1}
                      >
                        {objectProductVariations.map((variation, index) => (
                          <Option key={variation.id} value={index}>
                            {variation.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <FormattedMessage id="productpage.panel.noVariation" />
                    )}
                  </dd>
                </div>
              )}
              {useNewPlayer && product.sourceFiles.length > 0 && objectProductVariations.length > 0 &&
                <>
                  {variationIndex !== undefined && objectProductVariations[variationIndex] &&
                    <div data-fileid={objectProductVariations[variationIndex].id} className={styles.fileCard} key={objectProductVariations[variationIndex].id}>
                      <img src={textureLogo} />
                      <div className={styles.fileName}>{`${getGlbFileName()} ${objectProductVariations[variationIndex].name.replace(/[^a-zA-Z0-9]/g, ' ')}.glb`}</div>
                      <div className={styles.fileExtension}>{'glb'.toUpperCase()}</div>
                      <div className={styles.fileSize}></div>
                      <span className={styles.fileIcon}
                        onClick={() => handleDownloadFileWithTexture(objectProductVariations[variationIndex], variationIndex, 'glb')}
                      >
                        <Icon type="download" />
                      </span>
                      <span data-progressbarcontent
                        className={styles.progressBarContent}
                        style={{ opacity: activeDownloadId === objectProductVariations[variationIndex].id ? '1' : '0' }}
                      >
                        <Icon className={styles.loading} type="loading" />
                      </span>
                    </div>
                  }
                  {variationIndex !== undefined && objectProductVariations[variationIndex] &&
                    <div data-fileid={`${objectProductVariations[variationIndex].id}-fbx`} className={styles.fileCard} key={`${objectProductVariations[variationIndex].id}-fbx`}>
                      <img src={textureLogo} />
                      <div className={styles.fileName}>{`${getGlbFileName()} ${objectProductVariations[variationIndex].name.replace(/[^a-zA-Z0-9]/g, ' ')}.fbx`}</div>
                      <div className={styles.fileExtension}>{'fbx'.toUpperCase()}</div>
                      <div className={styles.fileSize}></div>
                      <div className={styles.fileIcon} style={isDownloading ? { cursor: 'not-allowed' } : {}}>
                        <span className={styles.fileIcon} style={isDownloading ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}} onClick={() => handleDownloadFileWithTexture(objectProductVariations[variationIndex], variationIndex, 'fbx')}>
                          <Icon type="download" />
                        </span>
                      </div>
                      <span data-progressbarcontent
                        className={styles.progressBarContent}
                        style={{ opacity: activeDownloadId === `${objectProductVariations[variationIndex].id}-fbx` ? '1' : '0' }}
                      >
                        <Icon className={styles.loading} type="loading" />
                      </span>
                    </div>
                  }
                  {variationIndex !== undefined && objectProductVariations[variationIndex] &&
                    <div data-fileid={`${objectProductVariations[variationIndex].id}-obj`} className={styles.fileCard} key={`${objectProductVariations[variationIndex].id}-obj`}>
                      <img src={textureLogo} />
                      <div className={styles.fileName}>{`${getGlbFileName()} ${objectProductVariations[variationIndex].name.replace(/[^a-zA-Z0-9]/g, ' ')}.obj`}</div>
                      <div className={styles.fileExtension}>{'obj'.toUpperCase()}</div>
                      <div className={styles.fileSize}></div>
                      <div className={styles.fileIcon} style={isDownloading ? { cursor: 'not-allowed' } : {}}>
                        <span className={styles.fileIcon} style={isDownloading ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}} onClick={() => handleDownloadFileWithTexture(objectProductVariations[variationIndex], variationIndex, 'obj')}>
                          <Icon type="download" />
                        </span>
                      </div>
                      <span data-progressbarcontent
                        className={styles.progressBarContent}
                        style={{ opacity: activeDownloadId === `${objectProductVariations[variationIndex].id}-obj` ? '1' : '0' }}
                      >
                        <Icon className={styles.loading} type="loading" />
                      </span>
                    </div>
                  }
                  {variationIndex !== undefined && objectProductVariations[variationIndex] &&
                    <div data-fileid={`${objectProductVariations[variationIndex].id}-dae`} className={styles.fileCard} key={`${objectProductVariations[variationIndex].id}-dae`}>
                      <img src={textureLogo} />
                      <div className={styles.fileName}>{`${getGlbFileName()} ${objectProductVariations[variationIndex].name.replace(/[^a-zA-Z0-9]/g, ' ')}.dae`}</div>
                      <div className={styles.fileExtension}>{'dae'.toUpperCase()}</div>
                      <div className={styles.fileSize}></div>
                      <div className={styles.fileIcon} style={isDownloading ? { cursor: 'not-allowed' } : {}}>
                        <span className={styles.fileIcon} style={isDownloading ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}} onClick={() => handleDownloadFileWithTexture(objectProductVariations[variationIndex], variationIndex, 'dae')}>
                          <Icon type="download" />
                        </span>
                      </div>
                      <span data-progressbarcontent
                        className={styles.progressBarContent}
                        style={{ opacity: activeDownloadId === `${objectProductVariations[variationIndex].id}-dae` ? '1' : '0' }}
                      >
                        <Icon className={styles.loading} type="loading" />
                      </span>
                    </div>
                  }
                </>
              }
              {product.documents.length > 0 &&
                <>
                  <Heading level={3} as="h2">
                    <FormattedMessage id="productpage.files.docs" />
                  </Heading>
                  {product.documents.map(f =>
                    <div data-fileid={f.id} className={styles.fileCard} key={f.id}>
                      <img src={logoPlaceholder} alt="Logo" />
                      <div className={styles.fileName}>{f.name}</div>
                      <div className={styles.fileExtension}>{f.extension.toUpperCase()}</div>
                      <div className={styles.fileSize}>{(f.media.size / Math.pow(1024, 2)).toFixed(2)} MB</div>
                      <span className={styles.fileIcon}
                        onClick={() => handleDownloadFile(ProductFileType.Document, f)}>
                        <Icon type="download" />
                      </span>
                      <span data-progressbarcontent
                        className={styles.progressBarContent}
                        style={{ opacity: activeDownloadId === f.id ? '1' : '0' }}
                      >
                        <Icon className={styles.loading} type="loading" />
                      </span>
                    </div>
                  )}
                </>
              }
              {product.sourceFiles.length > 0 &&
                <>
                  <Heading level={3} as="h2">
                    <FormattedMessage id="productpage.files.cao" />
                  </Heading>
                  {product.sourceFiles.map(f =>
                    <div data-fileid={f.id} className={styles.fileCard} key={f.id}>
                      <img src={logo3DPlaceholder} alt="Logo 3D" />
                      <div className={styles.fileName}>{f.name}</div>
                      <div className={styles.fileExtension}>{f.extension.toUpperCase()}</div>
                      <div className={styles.fileSize}>{(f.media.size / Math.pow(1024, 2)).toFixed(2)} MB</div>
                      <span className={styles.fileIcon}
                        onClick={() => handleDownloadFile(ProductFileType.SourceFile, f)}
                      >
                        <Icon type="download" />
                      </span>
                      <span data-progressbarcontent
                        className={styles.progressBarContent}
                        style={{ opacity: activeDownloadId === f.id ? '1' : '0' }}
                      >
                        <Icon className={styles.loading} type="loading" />
                      </span>
                    </div>
                  )}
                </>
              }
              <Modal
                visible={showQuotaPopup}
                footer={null}
                centered
                onCancel={() => setShowQuotaPopup(false)}
              >
                <Heading level={3} fill centerText>
                  <FormattedMessage
                    id="architectnotlinkedpopup.title"
                    values={{
                      name: (
                        <span className={styles.name}>
                          {user!.account.firstName} {user!.account.lastName}
                        </span>
                      )
                    }}
                  />
                </Heading>
                <div className={styles.alignCenter}>
                  {showExceededQuotaPopup ?
                    <FormattedMessage id="productpage.files.modal.exceeded.text"
                      values={{
                        p: (...chunks: string[]) => <p>{chunks}</p>
                      }}
                    /> :
                    <FormattedMessage id="productpage.files.modal.text" />
                  }
                </div>
                {!showExceededQuotaPopup &&
                  < Button
                    theme={Theme.PRIMARY}
                    onClick={() => { setShowQuotaPopup(false); setDisplayViewer(false); pushRouter(`${Routes.Architects}/${user!.id}/edit/subscription`) }}
                    block>
                    <FormattedMessage
                      id={
                        hasReachedLastQuota
                          ? 'productpage.files.modal.buttonQuotaMaxReached'
                          : 'productpage.files.modal.buttonQuotaReached'
                      }
                    />
                  </Button>
                }
              </Modal>
            </div>
          </div> :
          <dl>
            <div>
              <img
                src={
                  (product.brand && product.brand.logo && product.brand.logo.url) || logoPlaceholder
                }
                alt={`Logo ${product.brand ? product.brand.name : ''}`}
              />
            </div>
            <div className={styles.header}>
              <div className={styles.title}>
                <h1 className={styles.name}>{getProductName(product)}</h1>
                {user && (
                  <span>
                    {isProductInCollectionAnyCollection(product.id) ?
                      <img src={heartFullBlack} className={styles.heart} onClick={(e) => handleRemoveProductFromCollections(product.id)} alt="Heart full" /> :
                      <img src={heartEmptyBlack} className={styles.heart} onClick={(e) => handleAddProductToLatestCollection(product.id)} alt="Heart empty" />
                    }
                  </span>
                )}
              </div>
              <div>
                {product.brand && product.brand.madeIn && countryName && (
                  <div>
                    <FormattedMessage id="productpage.panel.country" /> {countryName}
                  </div>
                )}
              </div>
            </div>
            <>
              {product.suggestedPrice && (
                <>
                  <dt>
                    <FormattedMessage id="productpage.panel.price" />
                  </dt>
                  <dd>
                    <Price amount={product.suggestedPrice} />
                  </dd>
                </>
              )}

              {objectProductVariations && (
                <>
                  <dt>
                    <FormattedMessage id="productpage.panel.variations" />
                  </dt>
                  <dd className={styles.selectContainer}>
                    {objectProductVariations && objectProductVariations.length > 0 ? (
                      <Select
                        value={variationIndex}
                        onChange={handleVariationChange}
                        disabled={objectProductVariations && objectProductVariations.length < 2}
                        showArrow={objectProductVariations && objectProductVariations.length > 1}
                      >
                        {objectProductVariations.map((variation, index) => (
                          <Option key={variation.id} value={index}>
                            {variation.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <FormattedMessage id="productpage.panel.noVariation" />
                    )}
                  </dd>
                </>
              )}

              {product.collection && (
                <>
                  <dt>
                    <FormattedMessage id="productpage.panel.collection" />
                  </dt>
                  <div>{product.collection}</div>
                </>
              )}

              {product.designers.length > 0 && (
                <>
                  <dt>
                    <FormattedMessage id="productpage.panel.designedBy" />
                  </dt>
                  <dd> {product.designers.map(designer => designer.name).join(', ')}</dd>
                </>
              )}

              {product.designedDate && (
                <>
                  <dt>
                    <FormattedMessage id="productpage.panel.designedDate" />
                  </dt>
                  <div>{new Date(product.designedDate).getFullYear()}</div>
                </>
              )}

              {product.materialTagIds && product.materialTagIds.length > 0 && (
                <>
                  <dt>
                    <FormattedMessage id="productpage.panel.material" />
                  </dt>
                  <dd>
                    {product.materialTagIds
                      .map(id => {
                        const materialTag = productMaterialTags.find(tag => tag.id === id);
                        if (materialTag) return materialTag.name;
                      })
                      .join(', ')}
                  </dd>
                </>
              )}

              {objectProductDimensions.length > 0 && objectProductDimensions.map((dimension, idx) => (
                (dimension.width || dimension.depth || dimension.height || dimension.weight) && (
                  <>
                    {idx == 0 &&
                      <dt>
                        <FormattedMessage id="productpage.panel.dimensions" />
                      </dt>
                    }
                    <dd>
                      <ul className={styles.dimensions}>
                        {dimension.width > 0 && (
                          <li>
                            <FormattedMessage id="productpage.panel.width" /> :{' '}
                            <FormattedNumber value={convertForFront(dimension.width)} /> cm
                          </li>
                        )}
                        {dimension.height > 0 && (
                          <li>
                            <FormattedMessage id="productpage.panel.height" /> :{' '}
                            <FormattedNumber value={convertForFront(dimension.height)} /> cm
                          </li>
                        )}
                        {dimension.depth > 0 && (
                          <li>
                            <FormattedMessage id="productpage.panel.depth" /> :{' '}
                            <FormattedNumber value={convertForFront(dimension.depth)} /> cm
                          </li>
                        )}
                        {dimension.weight > 0 && (
                          <li>
                            <FormattedMessage id="productpage.panel.weight" /> :{' '}
                            <FormattedNumber value={convertDepthForFront(dimension.weight)} /> kg
                          </li>
                        )}
                      </ul>
                      {product.brand.isApproximatedDimensions &&
                        <p style={{ fontStyle: "italic" }}>*<FormattedMessage id="productpage.panel.dimensions.disclaimer" /></p>
                      }
                    </dd>
                  </>
                )))
              }
              {product.description && (
                <>
                  <dt>
                    <FormattedMessage id="productpage.panel.description" />
                  </dt>
                  <dd className={styles.desc}>
                    {isExpanded ? product.description : shortenedDescription}
                    <button style={{ background: 'none', border: 'none', cursor: 'pointer' }} onClick={toggleDescription}>
                      <span style={{ fontWeight: 'bold' }}>{isExpanded ? <FormattedMessage id="productpage.panel.readLess" /> : <FormattedMessage id="productpage.panel.readMore" />}</span>
                    </button>
                  </dd>
                </>
              )}
              {product.brandWebsiteUrl && accountType === AccountType.AddsomeUser && (
                <>
                  <dt>
                    <FormattedMessage id="productpage.panel.brandUrl" />
                  </dt>
                  <dd>
                    <a href={product.brandWebsiteUrl} target="_blank">
                      {product.brandWebsiteUrl}
                    </a>
                  </dd>
                </>
              )}
              <>
                <dt><FormattedMessage id="productpage.panel.copyTitle" /></dt>
                <dd style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 0 }}>
                  <SocialShare
                    productURL={textToCopy}
                    productTitle={product.name}
                    productDescription={product.description}
                    productImage={product && product.thumbnail && product.thumbnail.media && product.thumbnail.media.url}
                  />
                  <button onClick={handleCopyClick} style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}>
                    <img src={clipboardCopy} alt="Copy to clipboard" style={{ width: '32px', height: '32px', border: 'none', verticalAlign: "unset" }} />
                  </button>
                  {copySuccess && <FormattedMessage id="productpage.panel.copyText" />}
                </dd>
                <br></br>
              </>
            </>
          </dl>
        }

        {isShowing3d && !showFiles && (
          <div className={styles.productButtonContainer}>
            <Button className={styles.productButton} onClick={() => handleShow3DFiles()}>
              <FormattedMessage id="productpage.panel.seeProduct" />
            </Button>
          </div>
        )}
      </Container>
      {
        user && (
          <div className={styles.reportText} onClick={() => setDisplayCreationProblemModal(true)}>
            <FormattedMessage id="productpage.problems.reportProblem" />
          </div>
        )
      }
      {
        user &&
        (<CreationModalWrapper
          display={displayCreationProblemModal}
          onUpdateDisplay={(display) => setDisplayCreationProblemModal(display)}
          title={intl.formatMessage({ id: 'productspage.problems.addAProblem' })}
          hideAddButton
        >
          <ProductProblemCreationForm
            productId={product.id}
            accountId={user.account.id}
            onSubmit={handleCreateProblem}
          />
        </CreationModalWrapper>)
      }
      <Modal
        className={styles.loginRegisterModal}
        visible={displayLoginModal}
        onCancel={() => setDisplayLoginModal(false)}
        okText={<FormattedMessage id="public.catalog.loginmodal.register" />}
        cancelText={<FormattedMessage id="public.catalog.loginmodal.login" />}
        title={<FormattedMessage id="public.catalog.loginmodal.title" />}
        bodyStyle={{ display: 'none' }}
        footer={
          <>
            <Button block uppercase size={Size.LARGE} onClick={() => {
              setDisplayLoginModal(false)
              setDisplayViewer(false)
              setDisplayRegisterUser(true)
            }}>
              <FormattedMessage id="public.catalog.loginmodal.register" />
            </Button>
            <Button block uppercase size={Size.LARGE} onClick={() => window.location.href = Routes.Login + `?productId=${product.id}`} theme={Theme.PRIMARY}>
              <FormattedMessage id="public.catalog.loginmodal.login" />
            </Button>
          </>
        }
      />
      <RegisterViewModal />
    </div >
  );
};

const mapStateToProps = (state: IRootState) => ({
  productProblemTypes: state.productProblemTypeState.values,
  collections: state.collectionsState.collections,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewer.setDisplayViewer(displayViewer)),
  createProductProblem: (problem: ProductProblemCreationDTO) =>
    dispatch(productProblem.thunks.createValue(problem)),
  pushRouter: (location: string) => dispatch(push(location)),
  showNotEnabledModal: () => dispatch(setShouldShowArchitectNotEnabledDownloadPopup(true)),
  showNotLinkedModal: (brand: BrandLightDTO) =>
    dispatch(setShouldShowArchitectNotLinkedToBrandDownloadPopup(true, brand)),
  patchArchitectDownloads: (id: string, downloads, succes?: string, error?: string) => {
    dispatch(
      architectActions.patchArchitectDownloads(id, {
        downloads: downloads
      }, succes, error)
    );
  },
  getAuth: () => dispatch(auth.getAuth()),
  createNewCollection: (payload: any, success: string, error: string) => dispatch(collections.createCollection(payload, success, error)),
  addProductsToCollection: (id: string, payload: any, success: string, error: string) => dispatch(collections.updateCollection(id, payload, success, error)),
  removeProductFromAllCollections: (productId: string, success: string, error: string) => dispatch(collections.removeProductFromAllCollections(productId, success, error)),
  fetchCollectionsList: () =>
    dispatch(collections.fetchCollections()),
  fetchProductMaterialTags: () => dispatch(productMaterialTag.thunks.fetchValues()),
  setDisplayRegisterUser: (displayRegister: boolean) =>
    dispatch(user.setRegisterUser(displayRegister)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps)(injectIntl(InformationPanelDesktopView));
