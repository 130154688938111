import { AccountType } from '@addsome/dtos/dist'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../redux'
import HomePageArchitect from './HomePageArchitect/HomePageArchitect'
import HomePageBrandUser from './HomePageBrandUser/HomePageBrandUser'
import HomePageAll from './HomePageAll/HomePageAll'
import HomePageOther from './HomePageOther'
import Layout from '../../templates/Layout/Layout'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { auth } from '@addsome/redux-store'
import { Loading } from "@addsome/ui-kit";

const HomePage: React.FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = ({ accountType, getAuth }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!accountType) {
      const fetchAuth = async () => {
        await getAuth();
        setLoading(false);
      };
      fetchAuth();
    } else {
      setLoading(false);
    }
  }, [accountType, getAuth]);
  
  if (loading) {
    return <Loading></Loading>;
  }
  else {
    switch (accountType) {
    case AccountType.BrandUser:
      return <HomePageBrandUser />
    case AccountType.AddsomeUser:
      return <Layout><HomePageOther accountType={accountType} /></Layout>
    case AccountType.Architect:
      return <Layout><HomePageArchitect /></Layout>
    default:
      return <HomePageAll />
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  getAuth: () => dispatch(auth.getAuth()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
