import { ArchitectDTO } from '@addsome/dtos/dist'
import React from 'react'
import classnames from 'classnames'
import getProfilePicture from '../../utils/getProfilePicture'
import styles from './ProfilePageLayout.module.scss'
import { FormattedMessage } from 'react-intl'
import { Switch, Integration } from '@addsome/ui-kit'
import useArchitect from './useArchitect'

type IProps = {
  architect: ArchitectDTO
  subHeader?: React.ReactElement
  showEmail?: boolean
  canToggleUse?: boolean
}

export const Columns: React.FC<{
  leftSectionTitle: string
  leftSectionContent: React.ReactElement
  rightSectionTitle: string
  rightSectionContent: React.ReactElement
}> = ({ leftSectionTitle, leftSectionContent, rightSectionTitle, rightSectionContent }) => (
  <div className={styles.content}>
    <div className={styles.photo}>
      <h3>{leftSectionTitle}</h3>

      <div>{leftSectionContent}</div>
    </div>

    <div className={styles.description}>
      <h3>{rightSectionTitle}</h3>

      <div className={styles.descriptionBlock}>{rightSectionContent}</div>
    </div>
  </div>
)

const ProfilePageLayout: React.FC<IProps> = ({
  architect,
  subHeader,
  showEmail,
  canToggleUse,
  children
}) => {
  const profilePicture = getProfilePicture(architect.account)
  const { patchArchitect, loading } = useArchitect(architect.id)

  const onToggleUser = () => {
    patchArchitect({
      account: {
        enabled: !architect.account.enabled
      }
    })
  }

  return (
    architect && (
      <div className={styles.container}>
        <div className={styles.header}>
          <img className={styles.profilePicture} src={profilePicture} />

          <div className={styles.titleContainer}>
            <h1>
              {architect.account.firstName} {architect.account.lastName}
            </h1>

            {showEmail && <span className={styles.email}>{architect.account.email}</span>}
          </div>

          {canToggleUse && (
            <Switch
              className={classnames(styles.switch, { [styles.isLoading]: loading })}
              checked={architect.account.enabled}
              onChange={onToggleUser}
              integration={Integration.DARK}
            >
              <FormattedMessage id={'user.' + (architect.account.enabled ? 'disable' : 'enable')} />
            </Switch>
          )}
        </div>

        {subHeader}

        {children}
      </div>
    )
  )
}

export default ProfilePageLayout
