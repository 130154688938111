"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var queryDecorator_1 = require("../queryDecorator");

var MediaDTO = function MediaDTO() {
  _classCallCheck(this, MediaDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], MediaDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], MediaDTO.prototype, "url", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], MediaDTO.prototype, "name", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], MediaDTO.prototype, "mimetype", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], MediaDTO.prototype, "size", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], MediaDTO.prototype, "width", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], MediaDTO.prototype, "height", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], MediaDTO.prototype, "createDate", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], MediaDTO.prototype, "updateDate", void 0);

exports.MediaDTO = MediaDTO;
var MediaAllowedExtension;

(function (MediaAllowedExtension) {
  MediaAllowedExtension["jpeg"] = "jpeg";
  MediaAllowedExtension["png"] = "png";
  MediaAllowedExtension["jpg"] = "jpg";
})(MediaAllowedExtension = exports.MediaAllowedExtension || (exports.MediaAllowedExtension = {}));

var MediaOrderEnum;

(function (MediaOrderEnum) {
  MediaOrderEnum["createDate"] = "createDate";
  MediaOrderEnum["size"] = "size";
  MediaOrderEnum["mimetype"] = "mimetype";
})(MediaOrderEnum = exports.MediaOrderEnum || (exports.MediaOrderEnum = {}));

var MediaCreationDTO = function MediaCreationDTO() {
  _classCallCheck(this, MediaCreationDTO);
};

__decorate([class_validator_1.IsEnum(MediaAllowedExtension), __metadata("design:type", String)], MediaCreationDTO.prototype, "type", void 0);

exports.MediaCreationDTO = MediaCreationDTO;

var MediaQueryDto = function MediaQueryDto() {
  _classCallCheck(this, MediaQueryDto);
};

__decorate([class_validator_1.IsOptional(), queryDecorator_1.TransformToArray(), __metadata("design:type", Array)], MediaQueryDto.prototype, "ids", void 0);

exports.MediaQueryDto = MediaQueryDto;