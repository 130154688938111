"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.removeScene = exports.updateScene = exports.unsetScene = exports.setScene = exports.addScenes = exports.setScenes = void 0;

var model_1 = require("./model");

var setScenes = function setScenes(scenes, total) {
  return {
    type: model_1.ActionsTypes.SET_SCENES,
    scenes: scenes,
    total: total
  };
};

exports.setScenes = setScenes;

var addScenes = function addScenes(scenes, total) {
  return {
    type: model_1.ActionsTypes.ADD_SCENES,
    scenes: scenes,
    total: total
  };
};

exports.addScenes = addScenes;

var setScene = function setScene(scene) {
  return {
    type: model_1.ActionsTypes.SET_SCENE,
    scene: scene
  };
};

exports.setScene = setScene;

var unsetScene = function unsetScene() {
  return {
    type: model_1.ActionsTypes.UNSET_SCENE
  };
};

exports.unsetScene = unsetScene;

var updateScene = function updateScene(scene) {
  return {
    type: model_1.ActionsTypes.UPDATE_SCENE,
    scene: scene
  };
};

exports.updateScene = updateScene;

var removeScene = function removeScene(id) {
  return {
    type: model_1.ActionsTypes.REMOVE_SCENE,
    id: id
  };
};

exports.removeScene = removeScene;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_SCENE_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;