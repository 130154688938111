"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var ObjectProductDimensionDTO = function ObjectProductDimensionDTO() {
  _classCallCheck(this, ObjectProductDimensionDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductDimensionDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionDTO.prototype, "width", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionDTO.prototype, "height", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionDTO.prototype, "depth", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionDTO.prototype, "seatHeight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionDTO.prototype, "diameter", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionDTO.prototype, "weight", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], ObjectProductDimensionDTO.prototype, "createdAt", void 0);

exports.ObjectProductDimensionDTO = ObjectProductDimensionDTO;

var ObjectProductDimensionCreationDTO = function ObjectProductDimensionCreationDTO() {
  _classCallCheck(this, ObjectProductDimensionCreationDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductDimensionCreationDTO.prototype, "objectProductId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionCreationDTO.prototype, "width", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionCreationDTO.prototype, "height", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionCreationDTO.prototype, "depth", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionCreationDTO.prototype, "seatHeight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionCreationDTO.prototype, "diameter", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionCreationDTO.prototype, "weight", void 0);

exports.ObjectProductDimensionCreationDTO = ObjectProductDimensionCreationDTO;

var ObjectProductDimensionUpdateDTO = function ObjectProductDimensionUpdateDTO() {
  _classCallCheck(this, ObjectProductDimensionUpdateDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionUpdateDTO.prototype, "width", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionUpdateDTO.prototype, "height", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionUpdateDTO.prototype, "depth", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionUpdateDTO.prototype, "seatHeight", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionUpdateDTO.prototype, "diameter", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsInt(), __metadata("design:type", Number)], ObjectProductDimensionUpdateDTO.prototype, "weight", void 0);

exports.ObjectProductDimensionUpdateDTO = ObjectProductDimensionUpdateDTO;