"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ProductType;

(function (ProductType) {
  ProductType["Object"] = "object";
  ProductType["Surface"] = "surface";
})(ProductType = exports.ProductType || (exports.ProductType = {}));