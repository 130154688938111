import { Heading } from '@addsome/ui-kit'
import React, { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'
import creation from '../../../assets/images/creation.svg'
import styles from './DriveDropzone.module.scss'

interface IProps {
  onDrop: (files: File[]) => void
}

const DriveDropzone: FC<IProps> = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ noClick: true, onDrop })
  const intl = useIntl()

  return (
    <div {...getRootProps()} className={styles.container}>
      <input {...getInputProps()} />
      <div className={`${styles.circle} ${isDragActive ? styles.active : ''}`}>
        <img src={creation} />
        <Heading as="h2" level={3} strong className={styles.title}>
          {intl.formatHTMLMessage({ id: 'drivepage.dropzone.title' })}
        </Heading>
        <span className={styles.subtitle}>
          {intl.formatHTMLMessage({ id: 'drivepage.dropzone.subtitle' })}
        </span>
      </div>
    </div>
  )
}

export default DriveDropzone
