"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_USER_PROJECTS"] = "SET_USER_PROJECTS";
  ActionsTypes["SET_USER_PROJECT"] = "SET_USER_PROJECT";
  ActionsTypes["UNSET_USER_PROJECT"] = "UNSET_USER_PROJECT";
  ActionsTypes["REMOVE_USER_PROJECT"] = "REMOVE_USER_PROJECT";
  ActionsTypes["SET_USER_PROJECT_LOADING"] = "SET_USER_PROJECT_LOADING";
  ActionsTypes["ADD_USER_PROJECTS"] = "ADD_USER_PROJECTS";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));