import { ArchitectCreationDTO, ArchitectUpdateDTO } from '@addsome/dtos';
import { ArchitectType } from '@addsome/dtos/dist/enum/architectType'
import { InputField, InvisibleButton, Option, Select, TextArea } from '@addsome/ui-kit';
import { Form, Icon } from 'antd';
import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { findAccountWithSameEmail } from '../../../services/architectAccount'
import { injectIntl, WrappedComponentProps } from 'react-intl';

type IProps = WrappedComponentProps & {
  formik: FormikProps<ArchitectUpdateDTO | ArchitectCreationDTO>;
  architect: ArchitectUpdateDTO | ArchitectCreationDTO;
};

const ArchitectInformationFieldsGroup: React.FC<IProps> = ({ formik, architect, intl }) => {
  const [isEmailAlreadyUsed, setEmailAlreadyUsed] = useState(false)

  const getList = () => {
    let architectTypeList: string[] = [];
    Object.keys(ArchitectType).forEach(((architectType) => {
      architectTypeList.push(architectType);
    }
    ));
    return architectTypeList;
  };

  const checkEmailValidation = (e, isOnSubmit = false, values = {}) => {
    if (!e) {
      setEmailAlreadyUsed(false)
      return;
    }

    findAccountWithSameEmail(e).then((emailExists) => {
      if (emailExists) {
        setEmailAlreadyUsed(true)
      } else {
        setEmailAlreadyUsed(false)
      }
    })
  }

  const [architectsTypes] = useState(getList());
  const [showPassword, setShowPassword] = useState(false)
  const addressErrors = (formik.errors.address as any) || {};

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'register.firstname' })}
        required
        colon={false}
      >
        <InputField
          required
          name="firstName"
          defaultValue={architect.firstName}
          error={formik.errors.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'register.lastname' })}
        required
        colon={false}>
        <InputField
          required
          name="lastName"
          defaultValue={architect.lastName}
          error={formik.errors.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.email' })} required colon={false}>
        <InputField
          name="email"
          defaultValue={architect.email}
          error={isEmailAlreadyUsed ? intl.formatMessage({ id: 'register.emailAlreadyUsed' }) : (formik.touched.email && formik.errors.email ? formik.errors.email : '')}
          required
          onChange={formik.handleChange}
          onBlur={(value) => { checkEmailValidation(value.target.value); formik.handleBlur(value); }}
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.password' })} required colon={false}>
        <InputField
          required
          name="password"
          defaultValue={architect.password}
          type={showPassword ? 'text' : 'password'}
          error={formik.errors.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          addonAfter={
            <InvisibleButton
              onClick={() => setShowPassword(!showPassword)}
              aria-label="Afficher / Masquer le mot de passe"
              type="button"
            >
              <Icon type={showPassword ? 'eye' : 'eye-invisible'} />
            </InvisibleButton>
          }
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.architect.description' })}>
        <InputField error={formik.errors.description}>
          <TextArea
            name="description"
            defaultValue={architect.description || ''}
            onChange={formik.handleChange}
          />
        </InputField>
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.address.phone' })}>
        <InputField
          error={formik.errors.phone}
          name="phone"
          defaultValue={architect.phone}
          placeholder="+33"
          maxLength={16}
          onChange={event => formik.setFieldValue('phone', event.target.value || undefined)}
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.address.street' })}>
        <InputField
          error={addressErrors.street}
          name="address.street"
          defaultValue={architect.address && architect.address.street}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.address.zipcode' })}>
        <InputField
          error={addressErrors.zipcode}
          name="address.zipcode"
          defaultValue={architect.address && architect.address.zipcode}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'register.address.city' })}>
        <InputField
          error={addressErrors.city}
          name="address.city"
          defaultValue={architect.address && architect.address.city}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <Form.Item required label={intl.formatMessage({ id: 'register.architect.siret' })}>
        <InputField
          error={formik.errors.siret}
          name="siret"
          required
          defaultValue={architect.siret || ''}
          maxLength={14}
          onChange={event => formik.setFieldValue('siret', event.target.value || undefined)}
          onBlur={formik.handleBlur}
        />
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'register.architect.type' })}>
        <InputField>
          <Select
            defaultValue={architect.type}
            onChange={value => formik.setFieldValue('type', value)}
          >
            {architectsTypes.length > 0 && architectsTypes.map(((architectType, index) => (
              <Option key={index} value={ArchitectType[architectType]}>
                {architectType}
              </Option>
            )))}
          </Select>
        </InputField>
      </Form.Item>
    </>
  );
};

export default injectIntl(ArchitectInformationFieldsGroup);
