import { ArchitectExtendedDTO } from '@addsome/dtos/dist'
import { Size, Theme, Thumbnail, SkeletonThumbnail } from '@addsome/ui-kit'
import classnames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import defaultPlaceholder from '../../assets/images/logo.png'
import Routes from '../../utils/routes'
import { IItems } from '../PageCards/PageCardsGeneric'
import UserThumbnail from '../UserThumbnail/UserThumbnail'
import styles from './ThumbnailList.module.scss'

interface IProps {
  items: IItems[]
  placeholder?: string
  thumbnailSize?: Size
  className?: string,
  onThumbnailClick?: (id) => void
}

export const UserThumbnailList: React.FC<{
  users: ArchitectExtendedDTO[]
  className?: string
  thumbnailSize?: Size,
}> = ({ users, thumbnailSize, className }) => (
  <div className={classnames(styles.container, className)}>
    {users.map(user => (
      <Link to={`${Routes.Architects}/${user.id}`} key={user.id}>
        <UserThumbnail user={user} thumbnailSize={thumbnailSize} theme={Theme.WHITE} />
      </Link>
    ))}
  </div>
)

const ThumbnailList: React.FC<IProps> = ({
  items,
  placeholder,
  thumbnailSize = Size.MEDIUM,
  className,
  onThumbnailClick
}) => (
  <div className={classnames(styles.container, className)}>
    {(items || []).map((item, index) => (
      <>
        {onThumbnailClick ?
          <div onClick={() => onThumbnailClick(item.id)}>
            <Thumbnail
              src={item.img || placeholder || defaultPlaceholder}
              alt={item.label}
              size={thumbnailSize}
            />
          </div>
          :
          <Link to={item.link} key={index} title={item.label}>
            <Thumbnail
              src={item.img || placeholder || defaultPlaceholder}
              alt={item.label}
              size={thumbnailSize}
            />
          </Link>
        }
      </>
    ))}
  </div>
)

export const SkeletonThumbnailList: React.FC<{
  className?: string
  thumbnailSize?: Size
  nbItems: number
}> = ({ thumbnailSize = Size.MEDIUM, className, nbItems }) => (
  <div className={classnames(styles.container, className)}>
    {[...Array(nbItems)].map((e, i) => (
      <SkeletonThumbnail key={i} size={thumbnailSize} />
    ))}
  </div>
)

export default ThumbnailList
