import { BrandUserUpdateDTO } from '@addsome/dtos/dist'
import { brandUser as brandUserActions } from '@addsome/redux-store'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { updatePageTitle } from '../../services/title'
import getProfilePicture from '../../utils/getProfilePicture'

export default function useBrandUser(id: string) {
  const { brandUser, loading } = useSelector((state: IRootState) => ({
    brandUser: state.brandUserState.value,
    loading: state.brandUserState.loading
  }))

  const dispatch: ThunkDispatch<IRootState, {}, AnyAction> = useDispatch()

  const fetchBrandUser = useCallback(() => dispatch(brandUserActions.thunks.fetchValue(id)), [
    dispatch,
    id
  ])

  const patchBrandUser = useCallback(
    (payload: BrandUserUpdateDTO) => dispatch(brandUserActions.thunks.patchValue(id, payload)),
    [dispatch, id]
  )

  const unsetBrandUser = useCallback(() => dispatch(brandUserActions.thunks.unsetValue()), [
    dispatch
  ])

  useEffect(() => {
    if (id) {
      fetchBrandUser()
    }

    return () => {
      unsetBrandUser()
    }
  }, [id, fetchBrandUser, unsetBrandUser])

  useEffect(() => {
    if (brandUser) {
      updatePageTitle(`${brandUser.account.firstName} ${brandUser.account.lastName}`)
    }
  }, [brandUser])

  return {
    brandUser,
    fetchBrandUser,
    patchBrandUser,
    loading,
    profilePicture: getProfilePicture((brandUser && brandUser.account) || null)
  }
}
