import { ObjectProductUpdateDTO, ProductUpdateDTO } from '@addsome/dtos'
import { Button, Container, Theme } from '@addsome/ui-kit'
import { Form } from 'antd'
import { Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import ProductTagsFieldsGroup from '../ProductTagsFieldsGroup/ProductTagsFieldsGroup'
import styles from './ProductTagsForm.module.scss'
import { FormattedMessage } from 'react-intl'
import { createHashHistory } from 'history'

interface IProps {
  product: ProductUpdateDTO
  objectProduct: ObjectProductUpdateDTO
  canEdit?: boolean
  onSubmit: (product: ProductUpdateDTO) => Promise<void> | void
}

export default class ProductTagsForm extends React.Component<IProps> {
  private validationSchema = yup.object().shape({
    objectProduct: yup.object().shape({
      type: yup.string().required(),
      categoryIds: yup.array(yup.string()).nullable()
    }),
    styleTagIds: yup.array(yup.string()).nullable(),
    roomTagIds: yup.array(yup.string()).nullable(),
    materialTagIds: yup.array(yup.string()).nullable()
  })

  public render() {
    const { product, objectProduct } = this.props
    return (
      <Formik
        initialValues={{
          ...product,
          objectProduct: { ...objectProduct }
        }}
        validationSchema={this.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!values) return
          const wait = this.props.onSubmit(this.restrictUpdateData(values)) || Promise.resolve()
          // If we get a promise, wait for it
          wait.finally(() => setSubmitting(false))
        }}
      >
        {formik => (
          <Form
            layout="horizontal"
            onSubmit={formik.handleSubmit}
            className={styles.productTagsForm}
            noValidate
          >
            <Container>
              <ProductTagsFieldsGroup product={formik.values} formik={formik} />
            </Container>
            <Button
              theme={Theme.PRIMARY}
              htmlType="submit"
              block
              size="large"
              uppercase
              disabled={Object.values(formik.errors).length > 0}
            >
              <FormattedMessage id='productpage.panel.saveChanges' />
            </Button>
          </Form>
        )}
      </Formik>
    )
  }

  private restrictUpdateData = (product: ProductUpdateDTO): ProductUpdateDTO => ({
    ...(product.objectProduct
      ? {
        objectProduct: {
          type: product.objectProduct.type,
          categoryIds: product.objectProduct.categoryIds
        }
      }
      : {}),
    styleTagIds: product.styleTagIds,
    roomTagIds: product.roomTagIds,
    materialTagIds: product.materialTagIds
  })
}
