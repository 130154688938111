import { Icon } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Unity, { UnityContent } from 'react-unity-webgl';
import { IRootState } from '../../../redux';
import settings from '../../../settings';
import styles from './ProductViewer.module.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ProductRender3d from './ProductRender3D';
import { ProductRelationshipsExtendedDTO } from '@addsome/dtos/dist';
import { product } from '@addsome/redux-store/dist';
import { ObjectProductVariationDTO } from '@addsome/dtos';
import moment from 'moment';

type IProps = WrappedComponentProps &
  ReturnType<typeof mapStateToProps> & {
    productId: string;
    isOnView: boolean;
    variationIndex: number;
    nrOfVariationChanges: number;
    product?: ProductRelationshipsExtendedDTO;
    objectProductVariations: ObjectProductVariationDTO[];
    download3dWithTexturesFormat: string | null,
    onResetAuth: () => void,
    onFinishDownloading: () => void
  };

interface IMessageParameter {
  [key: string]: any;
}

const API_PLAYER_NAME = 'WebPlayerAPI';

class ProductViewer extends Component<IProps> {
  public state = {
    isLoading: true,
    glbFile: null
  };

  private unityContent = new UnityContent(
    `${window.location.origin}/AddsomeWebPlayer/Build/AddsomeWebPlayer.json`,
    `${window.location.origin}/AddsomeWebPlayer/Build/UnityLoader.js`
  );

  public sendMessage = (
    gameObjectName: string,
    methodName: string,
    parameter?: IMessageParameter
  ) => this.unityContent.send(gameObjectName, methodName, JSON.stringify(parameter));

  public componentDidMount() {
    if (this.props.product && this.props.product.haveObjectProductModelBundle) {
      this.addEventListener('WebPlayerAPI_OnWebPlayerReadyForInit', this.setApiInfos);
      this.addEventListener('WebPlayerAPI_OnWebPlayerReady', this.initScene);
      this.addEventListener('WebPlayerAPI_OnViewerProductSet', this.onViewerProductSet);
    }
  }

  public componentWillUnmount() {
    this.unityContent.remove();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!this.hasGLB() || this.hasGLB === undefined) {
      const { productId, variationIndex } = this.props;
      if (prevProps.productId !== productId) {
        this.setState({ loading: true });
        this.sendMessage(API_PLAYER_NAME, 'UseViewerCamera');
        this.sendMessage(API_PLAYER_NAME, 'SetViewerProduct', {
          productId,
          variationIndex
        });
      }
      if (prevProps.variationIndex !== variationIndex && prevProps.productId === productId) {
        this.sendMessage(API_PLAYER_NAME, 'SetViewerVariationIndex', {
          variationIndex
        });
      }
    }
  }

  hasGLB = () => {

    if (this.props.product) {
      let file = this.props.product.sourceFiles.find(file => (file.extension === "glb"));
      if (file) {
        const creationDate = file.media.createDate;
        const updateDate = file.media.updateDate;
        if (moment(creationDate).year() < 2022 && moment(updateDate).year() < 2022)
          return false;
      }
      return file;
    }
    else return false;
  };
  hasObj = () => {
    if (this.props.product) {
      let file = this.props.product.sourceFiles.find(file => (file.extension === "obj"));
      if (file) {
        return file
      }
      return false;

    }
  }

  public render() {
    return (
      <div className={styles.container}>
        {this.state.isLoading && !this.hasGLB() && (
          <div className={styles.loading}>
            <Icon type="loading" style={{ fontSize: '3rem', color: '#000000' }} />
            <p>{this.props.intl.formatMessage({ id: 'viewer.loading' })}</p>
          </div>
        )}
        {this.hasGLB() ?
          <ProductRender3d
            isOnView={this.props.isOnView}
            glbFile={this.hasGLB()}
            objFile={this.hasObj()}
            objectProductVariations={this.props.objectProductVariations}
            variationIndex={this.props.variationIndex}
            nrOfVariationChanges={this.props.nrOfVariationChanges}
            productId={this.props.productId}
            download3dWithTexturesFormat={this.props.download3dWithTexturesFormat}
            onResetAuth={() => this.props.onResetAuth()}
            onFinishDownloading={this.props.onFinishDownloading}
          /> :
          <Unity unityContent={this.unityContent} />
        }

      </div>
    );
  }

  private onViewerProductSet = () => {
    this.setState({ isLoading: false });
  };

  private addEventListener = (eventName: string, eventCallback: (e?: any) => void) =>
    this.unityContent.on(eventName, eventCallback);

  private setApiInfos = () =>
    this.sendMessage(API_PLAYER_NAME, 'Init', {
      url: settings.api.baseUrl,
      token: this.props.token
    });

  private initScene = () => {
    const { productId, variationIndex } = this.props;
    if (productId) {
      this.sendMessage(API_PLAYER_NAME, 'UseViewerCamera');
      setTimeout(
        () =>
          this.sendMessage(API_PLAYER_NAME, 'SetViewerProduct', {
            productId,
            variationIndex
          }),
        500
      );
    }
  };
}

const mapStateToProps = (state: IRootState) => ({
  token: state.authState.token
});

export default connect(mapStateToProps)(injectIntl(ProductViewer));
