import { ArchitectUpdateDTO } from '@addsome/dtos/dist'
import { architect as architectActions } from '@addsome/redux-store'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { updatePageTitle } from '../../services/title'
import getProfilePicture from '../../utils/getProfilePicture'

export default function useArchitect(id: string) {
  const { architect, loading } = useSelector((state: IRootState) => ({
    architect: state.architectState.value,
    loading: state.architectState.loading
  }))

  const dispatch: ThunkDispatch<IRootState, {}, AnyAction> = useDispatch()

  const fetchArchitect = useCallback(() => dispatch(architectActions.thunks.fetchValue(id)), [
    dispatch,
    id
  ])

  const patchArchitect = useCallback(
    (payload: ArchitectUpdateDTO) => dispatch(architectActions.thunks.patchValue(id, payload)),
    [dispatch, id]
  )

  const unsetArchitect = useCallback(() => dispatch(architectActions.thunks.unsetValue()), [
    dispatch
  ])

  useEffect(() => {
    if (id) {
      fetchArchitect()
    }

    return () => {
      unsetArchitect()
    }
  }, [id, fetchArchitect, unsetArchitect])

  useEffect(() => {
    if (architect) {
      updatePageTitle(`${architect.account.firstName} ${architect.account.lastName}`)
    }
  }, [architect])

  return {
    architect,
    fetchArchitect,
    patchArchitect,
    loading,
    profilePicture: getProfilePicture((architect && architect.account) || null)
  }
}
