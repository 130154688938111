"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) {
    if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNotifications = exports.DRIVE_NOTIFICATION_KEY = void 0;

var notification_1 = require("../notification");

__exportStar(require("./reducer"), exports);

__exportStar(require("./actions"), exports);

__exportStar(require("./model"), exports);

__exportStar(require("./thunks"), exports);

exports.DRIVE_NOTIFICATION_KEY = 'DRIVE';

function createNotifications(dispatch, message) {
  dispatch((0, notification_1.addLabels)(exports.DRIVE_NOTIFICATION_KEY, {
    fetchValue: {
      error: message.errorRetrievingTheFolder
    },
    create: {
      success: message.addedFolder,
      error: message.folderAlreadyExisting
    },
    delete: {
      success: message.deletedItem,
      error: message.errorDeletingItem
    },
    fetchValues: {
      success: message.addedFile,
      error: message.fileAlreadyExisting
    }
  }));
}

exports.createNotifications = createNotifications;