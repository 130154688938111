import { ArchitectDTO, BrandUserDTO } from '@addsome/dtos/dist'
import React from 'react'
import classnames from 'classnames'
import getProfilePicture from '../../utils/getProfilePicture'
import styles from './ProfilePageLayout.module.scss'
import { FormattedMessage } from 'react-intl'
import { Switch } from '@addsome/ui-kit'
import useBrandUser from './useBrandUser'

type IProps = {
  brandUser: ArchitectDTO | BrandUserDTO
  subHeader?: React.ReactElement
  showEmail?: boolean
  canToggleUse?: boolean
}

export const Columns: React.FC<{
  leftSectionTitle: string
  leftSectionContent: React.ReactElement
  rightSectionTitle: string
  rightSectionContent: React.ReactElement
}> = ({ leftSectionTitle, leftSectionContent, rightSectionTitle, rightSectionContent }) => (
  <div className={styles.content}>
    <div className={styles.photo}>
      <h3>{leftSectionTitle}</h3>

      <div>{leftSectionContent}</div>
    </div>

    <div className={styles.description}>
      <h3>{rightSectionTitle}</h3>

      <div className={styles.descriptionBlock}>{rightSectionContent}</div>
    </div>
  </div>
)

const BrandUserProfilePageLayout: React.FC<IProps> = ({
  brandUser,
  subHeader,
  showEmail,
  canToggleUse,
  children
}) => {
  const profilePicture = getProfilePicture(brandUser.account)
  const { patchBrandUser, loading } = useBrandUser(brandUser.id)

  const onToggleUser = () => {
    patchBrandUser({
      account: {
        enabled: !brandUser.account.enabled
      }
    })
  }

  return (
    brandUser && (
      <div className={styles.container}>
        <div className={styles.header}>
          <img className={styles.profilePicture} src={profilePicture} />

          <div className={styles.titleContainer}>
            <h1>
              {brandUser.account.firstName} {brandUser.account.lastName}
            </h1>

            {showEmail && <span className={styles.email}>{brandUser.account.email}</span>}
          </div>

          {canToggleUse && (
            <Switch
              className={classnames(styles.switch, { [styles.isLoading]: loading })}
              checked={brandUser.account.enabled}
              onChange={onToggleUser}
            >
              <FormattedMessage id={'user.' + (brandUser.account.enabled ? 'disable' : 'enable')} />
            </Switch>
          )}
        </div>

        {subHeader}

        {children}
      </div>
    )
  )
}

export default BrandUserProfilePageLayout
