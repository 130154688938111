import { push as pushAction } from 'connected-react-router'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../redux'

export default function usePush() {
  const dispatch = useDispatch()

  const router = useSelector((state: IRootState) => state.router)

  const push = useCallback(
    (to: string) => {
      dispatch(pushAction(to))
    },
    [dispatch]
  )

  const updateUrl = useCallback((search: string) => dispatch(pushAction({ search })), [dispatch])

  return { push, updateUrl, router }
}
