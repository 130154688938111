"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var queryDecorator_1 = require("../queryDecorator");

var class_transformer_1 = require("class-transformer");

var vector3_1 = require("./vector3");

var vector2_1 = require("./vector2");

var ObjectProductVariationMaterialDTO = function ObjectProductVariationMaterialDTO() {
  _classCallCheck(this, ObjectProductVariationMaterialDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "variationId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "shaderName", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "materialName", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "presetName", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "presetGroup", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "presetVariation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialDTO.prototype, "_CoordinatesOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_CoordinatesRotation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialDTO.prototype, "_DetailCoordinatesOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_DetailNormalMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DetailNormalMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialDTO.prototype, "_DetailTilingCoef", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseAddColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseAddValue", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseMultValue", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseTemperatureOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseTintOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_EmissionColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_EmissionSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_EmissionStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_FresnelStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_InvertDiffuse", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_MaskColor0", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_MaskColor1", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_MaskColor2", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_MaskColor3", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_Metalness", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_NormalMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_NormalMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_Opacity", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_Smoothness", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_SmoothnessContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_SmoothnessMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_SmoothnessMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialDTO.prototype, "_SpecularColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_SpecularContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_SpecularMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_SpecularSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_SpecularStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector2_1.Vector2;
}), __metadata("design:type", vector2_1.Vector2)], ObjectProductVariationMaterialDTO.prototype, "_Tiling", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_TilingCoef", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_TriPlanarBlend", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialDTO.prototype, "_TriPlanarTiling", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductVariationMaterialDTO.prototype, "_UseRGBMasks", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductVariationMaterialDTO.prototype, "_UseTriPlanar", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialDTO.prototype, "_DiffuseHueOffset", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], ObjectProductVariationMaterialDTO.prototype, "createdAt", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], ObjectProductVariationMaterialDTO.prototype, "updatedAt", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsDate(), __metadata("design:type", Date)], ObjectProductVariationMaterialDTO.prototype, "deletedAt", void 0);

exports.ObjectProductVariationMaterialDTO = ObjectProductVariationMaterialDTO;

var ObjectProductVariationMaterialCreationDTO = function ObjectProductVariationMaterialCreationDTO() {
  _classCallCheck(this, ObjectProductVariationMaterialCreationDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "variationId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "shaderName", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "materialName", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "presetName", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "presetGroup", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "presetVariation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialCreationDTO.prototype, "_CoordinatesOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_CoordinatesRotation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialCreationDTO.prototype, "_DetailCoordinatesOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_DetailNormalMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DetailNormalMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialCreationDTO.prototype, "_DetailTilingCoef", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseAddColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseAddValue", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseMultValue", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseTemperatureOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseTintOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_EmissionColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_EmissionSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_EmissionStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_FresnelStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_InvertDiffuse", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_MaskColor0", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_MaskColor1", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_MaskColor2", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_MaskColor3", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_Metalness", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_NormalMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_NormalMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_Opacity", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_Smoothness", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_SmoothnessContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_SmoothnessMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_SmoothnessMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialCreationDTO.prototype, "_SpecularColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_SpecularContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_SpecularMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_SpecularSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_SpecularStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector2_1.Vector2;
}), __metadata("design:type", vector2_1.Vector2)], ObjectProductVariationMaterialCreationDTO.prototype, "_Tiling", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_TilingCoef", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_TriPlanarBlend", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialCreationDTO.prototype, "_TriPlanarTiling", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductVariationMaterialCreationDTO.prototype, "_UseRGBMasks", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductVariationMaterialCreationDTO.prototype, "_UseTriPlanar", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialCreationDTO.prototype, "_DiffuseHueOffset", void 0);

exports.ObjectProductVariationMaterialCreationDTO = ObjectProductVariationMaterialCreationDTO;

var ObjectProductVariationMaterialUpdateDTO = function ObjectProductVariationMaterialUpdateDTO() {
  _classCallCheck(this, ObjectProductVariationMaterialUpdateDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "variationId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "shaderName", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "materialName", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "presetName", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "presetGroup", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "presetVariation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialUpdateDTO.prototype, "_CoordinatesOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_CoordinatesRotation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DetailCoordinatesOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DetailNormalMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DetailNormalMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DetailTilingCoef", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseAddColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseAddValue", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseMultValue", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseTemperatureOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseTintOffset", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_EmissionColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_EmissionSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_EmissionStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_FresnelStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_InvertDiffuse", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_MaskColor0", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_MaskColor1", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_MaskColor2", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_MaskColor3", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_Metalness", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_NormalMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_NormalMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_Opacity", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_Smoothness", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_SmoothnessContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_SmoothnessMap", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_SmoothnessMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductVariationMaterialUpdateDTO.prototype, "_SpecularColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_SpecularContrast", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_SpecularMapStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_SpecularSaturation", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_SpecularStrength", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector2_1.Vector2;
}), __metadata("design:type", vector2_1.Vector2)], ObjectProductVariationMaterialUpdateDTO.prototype, "_Tiling", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_TilingCoef", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_TriPlanarBlend", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return vector3_1.Vector3;
}), __metadata("design:type", vector3_1.Vector3)], ObjectProductVariationMaterialUpdateDTO.prototype, "_TriPlanarTiling", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductVariationMaterialUpdateDTO.prototype, "_UseRGBMasks", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ObjectProductVariationMaterialUpdateDTO.prototype, "_UseTriPlanar", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductVariationMaterialUpdateDTO.prototype, "_DiffuseHueOffset", void 0);

exports.ObjectProductVariationMaterialUpdateDTO = ObjectProductVariationMaterialUpdateDTO;

var ObjectProductVariationMaterialFilterDTO = function ObjectProductVariationMaterialFilterDTO() {
  _classCallCheck(this, ObjectProductVariationMaterialFilterDTO);
};

__decorate([queryDecorator_1.TransformToArray(), class_validator_1.IsOptional(), class_validator_1.IsUUID(undefined, {
  each: true
}), __metadata("design:type", Array)], ObjectProductVariationMaterialFilterDTO.prototype, "variationIds", void 0);

exports.ObjectProductVariationMaterialFilterDTO = ObjectProductVariationMaterialFilterDTO;