"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var address_1 = require("./address");

var media_1 = require("./media");

var ContactDTO = function ContactDTO() {
  _classCallCheck(this, ContactDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ContactDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ContactDTO.prototype, "role", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsPhoneNumber('ZZ'), __metadata("design:type", String)], ContactDTO.prototype, "phone", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsMobilePhone('ZZ'), __metadata("design:type", String)], ContactDTO.prototype, "mobilePhone", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsEmail(), __metadata("design:type", String)], ContactDTO.prototype, "email", void 0);

__decorate([class_validator_1.IsOptional(), class_transformer_1.Type(function () {
  return address_1.AddressDTO;
}), class_validator_1.ValidateNested(), __metadata("design:type", address_1.AddressDTO)], ContactDTO.prototype, "address", void 0);

__decorate([class_validator_1.IsOptional(), class_transformer_1.Type(function () {
  return media_1.MediaDTO;
}), class_validator_1.ValidateNested(), __metadata("design:type", media_1.MediaDTO)], ContactDTO.prototype, "picture", void 0);

exports.ContactDTO = ContactDTO;

var ContactUpdateDTO = function ContactUpdateDTO() {
  _classCallCheck(this, ContactUpdateDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ContactUpdateDTO.prototype, "role", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsPhoneNumber('ZZ'), __metadata("design:type", String)], ContactUpdateDTO.prototype, "phone", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsMobilePhone('ZZ'), __metadata("design:type", String)], ContactUpdateDTO.prototype, "mobilePhone", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsEmail(), __metadata("design:type", String)], ContactUpdateDTO.prototype, "email", void 0);

__decorate([class_validator_1.IsOptional(), class_transformer_1.Type(function () {
  return address_1.AddressDTO;
}), class_validator_1.ValidateNested(), __metadata("design:type", address_1.AddressDTO)], ContactUpdateDTO.prototype, "address", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsUUID(), __metadata("design:type", String)], ContactUpdateDTO.prototype, "pictureId", void 0);

exports.ContactUpdateDTO = ContactUpdateDTO;

var ContactCreationDTO = function ContactCreationDTO() {
  _classCallCheck(this, ContactCreationDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ContactCreationDTO.prototype, "role", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsPhoneNumber('ZZ'), __metadata("design:type", String)], ContactCreationDTO.prototype, "phone", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsMobilePhone('ZZ'), __metadata("design:type", String)], ContactCreationDTO.prototype, "mobilePhone", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsEmail(), __metadata("design:type", String)], ContactCreationDTO.prototype, "email", void 0);

__decorate([class_validator_1.IsOptional(), class_transformer_1.Type(function () {
  return address_1.AddressDTO;
}), class_validator_1.ValidateNested(), __metadata("design:type", address_1.AddressDTO)], ContactCreationDTO.prototype, "address", void 0);

exports.ContactCreationDTO = ContactCreationDTO;