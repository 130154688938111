import { ArchitectDTO, BrandUserWithBrandsDTO, ArchitectExtendedBrandDTO } from '@addsome/dtos'
import { IPaginationRequest, brands as brandsActions } from '@addsome/redux-store'
import { PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import { parse, stringify } from 'querystring'
import React, { useState, useEffect } from 'react'
import { FormattedNumber, FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import ListTop from '../ListTop/ListTop'
import ClientsTable from './ClientsTable/ClientsTable'
import styles from './Clients.module.scss'
import getProfilePicture from '../../utils/getProfilePicture'
import usePush from '../../hooks/usePush'
import Routes from '../../utils/routes'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const pageSize = 10

const Clients: React.FC<IProps> = ({
  queryParams,
  urlSearch,
  updateQuery,
  fetchBrandArchitects,
  fetchBrandArchitectsMetrics,
  user,
  historyAction
}) => {
  const intl = useIntl()
  const [hasFetchedArchitects, setHasFetchedArchitects] = useState(false)
  const [clients, setClients] = useState<ArchitectExtendedBrandDTO[]>([])
  const [totalClients, setTotalClients] = useState(0)
  const [latestClients, setLatestClients] = useState<ArchitectExtendedBrandDTO[] | null>(null)
  const [currentPage, setCurrentPage] = useState(Math.max(1, parseInt(queryParams.page, 10)) || 1)
  const [request, setRequest] = useState<IPaginationRequest>({
    skip: (currentPage - 1) * pageSize,
    take: pageSize,
    filters: { query: [queryParams.search] }
  })
  const { push } = usePush()

  const brandId = user.brands[0].id

  useEffect(() => {
    if (hasFetchedArchitects) return
    const fetchData = async () => {
      const { data } = await fetchBrandArchitectsMetrics(brandId)
      setLatestClients(data)
      const res = await fetchBrandArchitects(brandId, request)
      setClients(res.data)
      setTotalClients(res.total)
      setHasFetchedArchitects(true)
    }
    fetchData()
  }, [brandId, fetchBrandArchitects, fetchBrandArchitectsMetrics, request, hasFetchedArchitects])

  useEffect(() => {
    if (historyAction === 'PUSH') return
    const currentPage = Math.max(1, parseInt(queryParams.page, 10)) || 1
    const newRequest = {
      ...request,
      skip: (currentPage - 1) * pageSize,
      order: JSON.parse(queryParams.order || '{}'),
      filters: { query: [queryParams.search] }
    }
    setCurrentPage(currentPage)
    setRequest(newRequest)

    const fetchData = async () => {
      const { data, total } = await fetchBrandArchitects(brandId, newRequest)
      setClients(data)
      setTotalClients(total)
      setHasFetchedArchitects(true)
    }
    fetchData()
    // We disable this rule to prevent infinite loop when we update currentPage and request
    // We want this useEffect to be trigger only when user is using back action on his browser
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearch, historyAction])

  const handleTableChange = (
    pagination: PaginationConfig,
    filter: Record<keyof ArchitectDTO, string[]>,
    sorter: SorterResult<ArchitectDTO>
  ) => {
    const newRequest: IPaginationRequest = {
      ...request,
      skip: ((pagination.current || 1) - 1) * pageSize,
      order: sorter.column ? { [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1 } : {}
    }
    setRequest(newRequest)

    const fetchData = async () => {
      const { data, total } = await fetchBrandArchitects(brandId, newRequest)
      setClients(data)
      setTotalClients(total)
    }
    fetchData()

    if (pagination.current) {
      setCurrentPage(pagination.current)
    }
    const newParams = {
      ...queryParams,
      page: pagination.current || currentPage,
      order: JSON.stringify(newRequest.order)
    }
    updateQuery(stringify(newParams))
  }

  return (
    <div className={styles.container}>
      <div className={styles.topPanel}>
        {latestClients && (
          <>
            <div className={styles.topPanelNbClients}>
              <FormattedNumber value={totalClients} />
            </div>
            <div className={styles.topPanelNbClientsText}>
              <FormattedMessage id="clienttabpane.clients" values={{ totalClients }} />
            </div>
            <div className={styles.topPanelAddedRecently}>
              <FormattedMessage id="clienttabpane.recentlyAdded" />
            </div>
            <ul className={styles.topPanelClientList}>
              {latestClients.map(client => (
                <li key={client.id} className={styles.topPanelClientListItem}>
                  <div
                    className={styles.topPanelClientListItemImage}
                    onClick={() => push(`${Routes.Architects}/${client.id}`)}
                  >
                    <img src={getProfilePicture(client.account)} />
                  </div>
                  <span className={styles.topPanelClientListItemLabel}>
                    {client.account.firstName} {client.account.lastName}
                  </span>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      <ListTop
        searchLabel={intl.formatMessage({ id: 'clienttabpane.searchPlaceholder' })}
        onSearch={searchPattern => {
          const newRequest = {
            ...request,
            skip: 0,
            filters: {
              query: [searchPattern]
            }
          }
          setRequest(newRequest)
          setCurrentPage(1)
          fetchBrandArchitects(brandId, newRequest).then(({ data, total }) => {
            setClients(data)
            setTotalClients(total)
          })

          const newParams = { ...queryParams, search: searchPattern, page: 1 }
          updateQuery(stringify(newParams))
        }}
        searchInitValue={queryParams.search}
      />
      <ClientsTable
        clients={clients}
        brandId={brandId}
        total={totalClients}
        pageSize={pageSize}
        currentPage={currentPage}
        loading={!hasFetchedArchitects}
        onTableChange={handleTableChange}
        sortBy={JSON.parse(queryParams.order || '{}')}
      />
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user as BrandUserWithBrandsDTO,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string },
  urlSearch: state.router.location.search,
  historyAction: state.router.action
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchBrandArchitects: (brandId: string, request: IPaginationRequest = {}) => {
    return dispatch(brandsActions.fetchBrandArchitects(brandId, request))
  },
  fetchBrandArchitectsMetrics: (brandId: string) => {
    return dispatch(
      brandsActions.fetchBrandArchitects(brandId, {
        take: 10,
        order: { createdAt: 'DESC' }
      })
    )
  },
  updateQuery: (params: string) => dispatch(push({ search: params }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Clients)
