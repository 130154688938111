"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var media_1 = require("./media");

var class_transformer_1 = require("class-transformer");

var FileDTO = function FileDTO() {
  _classCallCheck(this, FileDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], FileDTO.prototype, "id", void 0);

__decorate([class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return media_1.MediaDTO;
}), __metadata("design:type", media_1.MediaDTO)], FileDTO.prototype, "media", void 0);

__decorate([class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], FileDTO.prototype, "enabled", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], FileDTO.prototype, "name", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], FileDTO.prototype, "extension", void 0);

exports.FileDTO = FileDTO;
exports.FileOrderEnum = Object.assign({}, media_1.MediaOrderEnum);

var FileCreationDTO = function FileCreationDTO() {
  _classCallCheck(this, FileCreationDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], FileCreationDTO.prototype, "mediaId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], FileCreationDTO.prototype, "enabled", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], FileCreationDTO.prototype, "name", void 0);

exports.FileCreationDTO = FileCreationDTO;

var FileUpdateDTO = function FileUpdateDTO() {
  _classCallCheck(this, FileUpdateDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], FileUpdateDTO.prototype, "enabled", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], FileUpdateDTO.prototype, "name", void 0);

exports.FileUpdateDTO = FileUpdateDTO;
var ProductFileType;

(function (ProductFileType) {
  ProductFileType["SourceFile"] = "source-file";
  ProductFileType["Document"] = "document";
})(ProductFileType = exports.ProductFileType || (exports.ProductFileType = {}));