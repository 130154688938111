import { BrandExtendedDTO, BrandUpdateDTO, MediaDTO } from '@addsome/dtos';
import { brand as brandActions } from '@addsome/redux-store';
import { Container, Heading, Theme, Button } from '@addsome/ui-kit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import logoPlaceholder from '../../../assets/images/donnees-2D.svg';
import { IRootState } from '../../../redux';
import { convertBrandForUpdate } from '../../../services/convertions';
import { mediaUploadUrl } from '../../../services/media';
import UploadMedia, { UploadType } from '../../UploadMedia/UploadMedia';
import BrandInformationForm from '../BrandInformationForm/BrandInformationForm';
import BrandInformationPanel from '../BrandInformationPanel/BrandInformationPanel';
import styles from './BrandInformation.module.scss';
import { push } from 'connected-react-router';
import Routes from '../../../utils/routes';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    brand: BrandExtendedDTO;
    readonly?: boolean;
  } & WrappedComponentProps;

class BrandInformation extends React.Component<IProps> {
  public render() {
    const { brand, token, intl, push } = this.props;
    return (
      <div className={styles.brandInformation}>
        {!this.props.readonly && (
          <div>
            <Container className={styles.images}>
              <div>
                <Heading level={4} as="h3">
                  Logo
                </Heading>
                <div className={styles.logo}>
                  <img
                    src={brand.logo && brand.logo.url ? brand.logo.url : logoPlaceholder}
                    alt={`Logo ${brand.name}`}
                  />
                  <div className={styles.description}>
                    <UploadMedia
                      action={mediaUploadUrl}
                      afterUpload={this.setLogo}
                      type={UploadType.GHOST}
                      token={token}
                    >
                      <FormattedMessage id="brandpage.information.media.upload" />
                    </UploadMedia>
                  </div>
                </div>
              </div>
              <div>
                <Heading level={4} as="h3">
                  {this.props.intl.formatMessage({ id: 'brandspage.coverImage' })}
                </Heading>
                <div className={styles.cover}>
                  {brand.cover && brand.cover.url && (
                    <img src={brand.cover.url} alt={`Couverture ${brand.name}`} />
                  )}
                  <UploadMedia
                    action={mediaUploadUrl}
                    afterUpload={this.setCover}
                    type={UploadType.GHOST}
                    token={token}
                  >
                    <FormattedMessage id="brandpage.information.media.upload" />
                  </UploadMedia>

                  <Button onClick={this.handleGoToFiles} theme={Theme.GHOST} uppercase block>
                    <FormattedMessage id="brandpage.information.files" />
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        )}
        <div>
          {this.props.readonly ? (
            <div>
              <BrandInformationPanel brand={brand} />
              {brand.productsCount !== 0 && (
                <Button
                  onClick={() => push(`${Routes.Catalog}?brandIds=${brand.id}`)}
                  className={styles.action}
                  theme={Theme.PRIMARY}
                >
                  {intl.formatMessage({ id: 'brandpage.button.seeProducts' })}
                </Button>
              )}
            </div>
          ) : (
            <BrandInformationForm
              brand={convertBrandForUpdate(brand)}
              onSubmit={this.handleSubmit}
            />
          )}
        </div>
      </div>
    );
  }

  private handleGoToFiles = () => this.props.push(`/drive/${this.props.brand.slug}`);

  private handleSubmit = (data: BrandUpdateDTO) => {
    const { brand } = this.props;
    if (!brand) return;
    this.props.patchBrand(brand.id, {
      phone: data.phone,
      name: data.name,
      description: data.description,
      websiteUrl: data.websiteUrl,
      alternativeNames: data.alternativeNames,
      madeIn: data.madeIn,
      defaultType: data.defaultType,
      countries: data.countries
    });
  };

  private setLogo = (logo: MediaDTO) => {
    const { brand } = this.props;
    if (!brand) return;
    this.props.patchBrand(brand.id, { logo: logo.id });
  };

  private setCover = (cover: MediaDTO) => {
    const { brand } = this.props;
    if (!brand) return;
    this.props.patchBrand(brand.id, { cover: cover.id });
  };
}

const mapStateToProps = (state: IRootState) => ({
  token: state.authState.token
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  patchBrand: (id: string, payload: BrandUpdateDTO) =>
    dispatch(brandActions.thunks.patchValue(id, payload)),
  push: (to: string) => dispatch(push(to))
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BrandInformation)
);
