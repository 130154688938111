import {
  AccountType,
  BrandLightDTO,
  BrandUserWithBrandsDTO,
  ProductCreationDTO,
  ProductLightExtendedDTO,
  ProductType
} from '@addsome/dtos';
import {
  brand as brandActions,
  IPaginationRequest,
  metrics as metricsActions,
  product as productActions,
  productStates as productStatesActions
} from '@addsome/redux-store';
import { CategoryColor, Diagram, Heading, Integration, Size } from '@addsome/ui-kit';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import { push } from 'connected-react-router';
import { parse, stringify } from 'querystring';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import bg from '../../../assets/images/home-bg.png';
import ListTop from '../../../components/ListTop/ListTop';
import PageHeader from '../../../components/PageHeaders/PageHeader';
import {
  DimensionsStep,
  InformationStep,
  PicturesStep
} from '../../../components/Products/ProductCreation';
import ProductsTable from '../../../components/Products/ProductsTable/ProductsTable';
import { IRootState } from '../../../redux';
import { updatePageTitle } from '../../../services/title';
import Routes from '../../../utils/routes';
import styles from './ProductsPage.module.scss';

const NB_RECENT = 8;

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  WrappedComponentProps;

const ProductsPage: React.FC<IProps> = ({
  fetchProducts,
  products,
  loading,
  total,
  createProduct,
  deleteProduct,
  intl,
  queryParams,
  updateQuery,
  urlSearch,
  productStates,
  fetchProductStates,
  accountType,
  user,
  redirect,
  fetchBrands,
  fetchMultipleBrandsMetrics,
  fetchMetrics,
  metrics,
  brandMetrics,
  push
}) => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(Math.max(1, parseInt(queryParams.page, 10)) || 1);
  const [request, setRequest] = useState<IPaginationRequest>({
    skip: (currentPage - 1) * pageSize,
    take: pageSize,
    order: queryParams.order ? JSON.parse(queryParams.order) : { createdAt: -1 },
    filters: {
      name: [queryParams.search],
      ...JSON.parse(queryParams.filters || '{}')
    }
  });
  const [prevSearch, setPrevSearch] = useState(urlSearch);
  const [userBrands, setUserBrands] = useState<BrandLightDTO[]>([]);

  useEffect(() => {
    if (accountType === AccountType.Architect) {
      push(Routes.Catalog);
    }
  }, [push, accountType]);

  useEffect(() => {
    if (accountType) {
      if (accountType !== AccountType.BrandUser) {
        fetchBrands({
          order: { createdAt: 'DESC' },
          filters: { enabled: ['true'] },
          take: NB_RECENT
        });
      }
    }
  }, [accountType, fetchBrands]);

  useEffect(() => {
    if (user) {
      if (accountType === AccountType.BrandUser) {
        const brandUser = user as BrandUserWithBrandsDTO;
        if (brandUser.brands && brandUser.brands.length > 0) {
          setUserBrands(brandUser.brands);
          fetchMultipleBrandsMetrics(brandUser.brands.map(brand => brand.id));
        }
      } else {
        fetchMetrics();
      }
    }
  }, [user, accountType, fetchMultipleBrandsMetrics, fetchMetrics]);

  useEffect(() => {
    if (user) {
      // Filter request for brandUsers
      if (accountType === AccountType.BrandUser && request.filters) {
        request.filters.brandIds = (user as BrandUserWithBrandsDTO).brands.map(brand => brand.id);
      }
      fetchProducts(request);
    }
    fetchProductStates();
    updatePageTitle(intl.formatMessage({ id: 'productspage.title' }));
  }, [user, accountType, fetchProductStates, intl]);

  // Update data when user use previous
  useEffect(() => {
    if (prevSearch !== urlSearch) {
      const newCurrentPage = Math.max(1, parseInt(queryParams.page, 10)) || 1;
      const newRequest: IPaginationRequest = {
        skip: (newCurrentPage - 1) * pageSize,
        take: pageSize,
        order: queryParams.order ? JSON.parse(queryParams.order) : { createdAt: -1 },
        filters: {
          brandIds: (request.filters && request.filters.brandIds) || [],
          name: [queryParams.search],
          ...JSON.parse(queryParams.filters || '{}')
        }
      };
      setCurrentPage(newCurrentPage);
      setRequest(newRequest);
      fetchProducts(newRequest);
      setPrevSearch(urlSearch);
    }
  }, [fetchProducts, intl, prevSearch, queryParams.filters, queryParams.order, queryParams.page, queryParams.search, request.filters, urlSearch]);

  const memoizedHandleTableChange = useCallback(
    (
      pagination: PaginationConfig,
      filter: Record<keyof ProductLightExtendedDTO, string[]>,
      sorter: SorterResult<ProductLightExtendedDTO>
    ) => {
      const newRequest: IPaginationRequest = {
        take: pageSize,
        skip: ((pagination.current || 1) - 1) * pageSize,
        order: sorter.column
          ? { [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1 }
          : { createdAt: -1 },
        filters: { ...request.filters, ...filter }
      };
      setRequest(newRequest);

      fetchProducts(newRequest);
      if (pagination.current) setCurrentPage(pagination.current);

      const newParams = {
        ...queryParams,
        page: pagination.current || currentPage,
        order: JSON.stringify(newRequest.order),
        filters: JSON.stringify(filter)
      };
      setPrevSearch('?' + stringify(newParams));
      updateQuery(stringify(newParams));
    },
    [currentPage, fetchProducts, queryParams, request.filters, updateQuery]
  );

  const memoizedHandleSearch = useCallback(
    (searchPattern: string) => {
      const newRequest: IPaginationRequest = {
        ...request,
        skip: 0,
        take: pageSize,
        filters: { ...request.filters, name: [searchPattern] }
      };
      setRequest(newRequest);
      setCurrentPage(1);

      fetchProducts(newRequest);

      const newParams = { ...queryParams, search: searchPattern, page: 1 };
      setPrevSearch('?' + stringify(newParams));
      updateQuery(stringify(newParams));
    },
    [fetchProducts, queryParams, request, updateQuery]
  );

  const handleCreateProduct = useCallback(
    async (payload: ProductCreationDTO) => {
      const product = await createProduct(payload, request);
      redirect(`/products/${product.id}`);
    },
    [createProduct, redirect, request]
  );

  const readonly = accountType === AccountType.Architect;
  const isBrandUser = accountType === AccountType.BrandUser;

  return (
    <>
      <PageHeader
        title={
          <Heading level={1} strong centerText>
            <FormattedMessage id="productspage.title" />
          </Heading>
        }
        backgroundSrc={
          accountType === AccountType.BrandUser &&
            userBrands.length === 1 &&
            userBrands[0].cover &&
            userBrands[0].cover.url
            ? userBrands[0].cover.url
            : bg
        }
        size={Size.MEDIUM}
      >
        {(metrics || (isBrandUser && brandMetrics)) && (
          <div className={styles.metrics}>
            <Diagram
              integration={Integration.DARK}
              label={intl.formatNumber(
                isBrandUser
                  ? brandMetrics.furniture.objectProductWithBundleCount +
                  brandMetrics.lighting.objectProductWithBundleCount +
                  brandMetrics.accessories.objectProductWithBundleCount
                  : metrics.objectProductWithBundleCount.furniture +
                  metrics.objectProductWithBundleCount.lighting +
                  metrics.objectProductWithBundleCount.accessories
              )}
              subLabel={intl.formatMessage({ id: 'brandpage.withBundle' })}
              datas={[
                {
                  label: intl.formatMessage({ id: 'global.furniture' }),
                  value: isBrandUser
                    ? brandMetrics.furniture.objectProductWithBundleCount
                    : metrics.objectProductWithBundleCount.furniture,
                  color: CategoryColor.LIGHT_GREY
                },
                {
                  label: intl.formatMessage({ id: 'global.lighting' }),
                  value: isBrandUser
                    ? brandMetrics.lighting.objectProductWithBundleCount
                    : metrics.objectProductWithBundleCount.lighting,
                  color: CategoryColor.CYAN
                },
                {
                  label: intl.formatMessage({ id: 'global.materials' }),
                  value: isBrandUser
                    ? brandMetrics.accessories.objectProductWithBundleCount
                    : metrics.objectProductWithBundleCount.accessories,
                  color: CategoryColor.BLUE
                },
                {
                  label: intl.formatMessage({ id: 'global.accessories' }),
                  value: 0,
                  color: CategoryColor.DARK_BLUE
                }
              ]}
            />
            <Diagram
              integration={Integration.DARK}
              label={intl.formatNumber(
                isBrandUser
                  ? brandMetrics.furniture.objectProductCount +
                  brandMetrics.lighting.objectProductCount +
                  brandMetrics.accessories.objectProductCount
                  : metrics.objectProductCount.furniture +
                  metrics.objectProductCount.lighting +
                  metrics.objectProductCount.accessories
              )}
              subLabel={intl.formatMessage({ id: 'global.products' })}
              datas={[
                {
                  label: intl.formatMessage({ id: 'global.furniture' }),
                  value: isBrandUser
                    ? brandMetrics.furniture.objectProductCount
                    : metrics.objectProductCount.furniture,
                  color: CategoryColor.LIGHT_GREY
                },
                {
                  label: intl.formatMessage({ id: 'global.lighting' }),
                  value: isBrandUser
                    ? brandMetrics.lighting.objectProductCount
                    : metrics.objectProductCount.lighting,
                  color: CategoryColor.CYAN
                },
                {
                  label: intl.formatMessage({ id: 'global.materials' }),
                  value: isBrandUser
                    ? brandMetrics.accessories.objectProductCount
                    : metrics.objectProductCount.accessories,
                  color: CategoryColor.BLUE
                },
                {
                  label: intl.formatMessage({ id: 'global.accessories' }),
                  value: 0,
                  color: CategoryColor.DARK_BLUE
                }
              ]}
            />
          </div>
        )}
      </PageHeader>
      <div className={styles.container}>
        <ListTop
          searchLabel={intl.formatMessage({ id: 'producttable.search' })}
          onSearch={memoizedHandleSearch}
          searchInitValue={queryParams.search}
          creation={
            accountType === AccountType.AddsomeUser
              ? {
                active: !readonly,
                initialValue: {
                  name: '',
                  designerIds: [],
                  type: ProductType.Object,
                  origin: '',
                  stateId: (productStates.length > 0 && productStates[0].id) || ''
                },
                steps: [
                  {
                    title: <FormattedMessage id="addproduct.information" />,
                    component: InformationStep
                  },
                  {
                    title: <FormattedMessage id="addproduct.dimensions" />,
                    component: DimensionsStep
                  },
                  {
                    title: <FormattedMessage id="addproduct.pictures" />,
                    component: PicturesStep
                  }
                ],
                title: <FormattedMessage id="productspage.add" />,
                onCreate: payload => handleCreateProduct(payload)
              }
              : undefined
          }
        />
        <ProductsTable
          products={products}
          loading={loading}
          total={total}
          currentPage={currentPage}
          pageSize={pageSize}
          onTableChange={memoizedHandleTableChange}
          onDelete={id => deleteProduct(id, request)}
          sortBy={JSON.parse(queryParams.order || '{}')}
          filters={JSON.parse(queryParams.filters || '{}')}
          readonly={readonly}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  products: state.productState.values,
  total: state.productState.total,
  loading: state.productState.loading,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string; },
  urlSearch: state.router.location.search,
  productStates: state.productStatesState.productStates,
  user: state.userState.user,
  accountType: state.authState.type,
  metrics: state.metricsState.metrics,
  brandMetrics: state.metricsState.brandMetrics
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createProduct: (payload: ProductCreationDTO, request: IPaginationRequest) =>
    dispatch(productActions.thunks.createValue(payload, request)),
  fetchProducts: (request: IPaginationRequest = {}) =>
    dispatch(productActions.thunks.fetchValues(request)),
  deleteProduct: (id: string, request: IPaginationRequest) =>
    dispatch(productActions.thunks.deleteValue(id, request)),
  updateQuery: (params: string) => dispatch(push({ search: params })),
  fetchProductStates: () => dispatch(productStatesActions.fetchProductStates()),
  redirect: (location: string) => dispatch(push(location)),
  fetchBrands: (request: IPaginationRequest = {}) =>
    dispatch(brandActions.thunks.fetchValues(request)),
  fetchMetrics: () => dispatch(metricsActions.fetchMetrics()),
  fetchMultipleBrandsMetrics: (ids: string[]) =>
    dispatch(metricsActions.fetchMultipleBrandsMetrics(ids)),
  push: (to: string) => dispatch(push(to))
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductsPage)
);
