import { ProductProblemCreationDTO } from '@addsome/dtos'
import { productProblemType } from '@addsome/redux-store'
import { Button, InputField, Option, Select, Size, TextArea, Theme } from '@addsome/ui-kit'
import { Form } from 'antd'
import { Formik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import * as yup from 'yup'
import { IRootState } from '../../../../redux'
import { WrappedComponentProps, injectIntl } from 'react-intl'

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & WrappedComponentProps & {
    productId: string,
    accountId: string,
    onSubmit: (productProblem: ProductProblemCreationDTO) => void | Promise<void>
  }

const ProductProblemCreationForm: React.FC<IProps> = ({
  fetchProductProblemTypes,
  productProblemTypes,
  productId,
  accountId,
  onSubmit,
  intl
}) => {
  useEffect(() => {
    fetchProductProblemTypes()
  }, [fetchProductProblemTypes])

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        typeId: yup.string().required()
      }),
    []
  )

  return (
    <Formik
      initialValues={{
        productId,
        accountId,
        typeId: '',
        comment: ''
      }}
      validationSchema={validationSchema}
      onSubmit={async (values: ProductProblemCreationDTO, { setSubmitting, resetForm }) => {
        if (!values) return
        await (onSubmit(values) || Promise.resolve())
        setSubmitting(false)
        resetForm({ productId, accountId, typeId: '', comment: '' })
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit} noValidate layout="horizontal">
          <Form.Item label={intl.formatMessage({ id: "producttable.problemType.type" })} required colon={false}>
            <InputField error={formik.errors.productId}>
              <Select
                onChange={value => formik.setFieldValue('typeId', value)}
                value={formik.values.typeId}
              >
                {productProblemTypes.map(type => (
                  <Option key={type.id} value={type.id}>
                    {intl.locale == 'fr' ? type.nameFr : type.name}
                  </Option>
                ))}
              </Select>
            </InputField>
          </Form.Item>

          <Form.Item label={intl.formatMessage({ id: 'producttable.comment' })} colon={false}>
            <InputField error={formik.errors.comment}>
              <TextArea
                rows={7}
                onChange={formik.handleChange}
                name="comment"
                value={formik.values.comment}
              />
            </InputField>
          </Form.Item>

          <Button
            htmlType="submit"
            theme={Theme.PRIMARY}
            size={Size.LARGE}
            uppercase
            block
            disabled={Object.values(formik.errors).length > 0}
          >
            {intl.formatMessage({ id: 'drivepage.foldermodal.addfolder' })}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state: IRootState) => ({
  productProblemTypes: state.productProblemTypeState.values
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProductProblemTypes: () => dispatch(productProblemType.thunks.fetchValues())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProductProblemCreationForm))
