import { ProductPictureType } from '@addsome/dtos/dist'
import {
  collections,
  IPaginationRequest,
  objectProduct,
  objectProductVariations,
  product,
  product as productActions,
  productViewer
} from '@addsome/redux-store'
import {
  FilterType,
  ICardData,
  InfiniteGridWithSearch, PopOverMenu, PopOverMenuItem,
  Searchbar
} from '@addsome/ui-kit'
import FiltersContainerCatalog from './FiltersContainerCatalog'
import classNames from 'classnames'
import { parse } from 'querystring'
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import SkeletonCatalogCardsList from '../../components/SkeletonCardsList/SkeletonCatalogCardsList/SkeletonCatalogCardsList'
import { IRootState } from '../../redux'
import { getResizedPreview } from '../../utils/cloudImage'
import formatPrice from '../../utils/formatPrice'
import { ScrollDirectionContext } from '../../utils/ScrollDirectionContext'
import styles from './Catalog.module.scss'
import CollectionMenu from "../../components/CollectionMenu/CollectionMenu";
import { CollectionDtoWithProducts } from '@addsome/dtos/dist/dtos/collection'
import Routes from '../../utils/routes'
const FILTERS = [
  FilterType.BRAND,
  FilterType.COLOR,
  FilterType.EMPTY,
  FilterType.MATERIAL,
  FilterType.OBJECT,
  FilterType.ORIGIN,
  FilterType.PRICE,
  FilterType.ROOM,
  FilterType.STYLE,
  FilterType.HAVE3D,
  FilterType.DIMENSIONS
]

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const Catalog: React.FC<IProps> = ({
  fetchProducts,
  products,
  loader,
  totalProducts,
  showProductModal,
  router,
  fetchCollectionsList,
  createNewCollection,
  addProductsToCollection,
  removeSelectedProductFromCollection,
  collectionsState
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchValueBuffer, setSearchValueBuffer] = useState('')
  const scrollDirection = useContext(ScrollDirectionContext)
  const [initialFetch, setInitialFetch] = useState(true)
  const [initialLoader, setInitialLoader] = useState(true)
  const [filtersForAPI, setfiltersForAPI] = useState(undefined)
  const intl = useIntl()

  const [collections, setCollections] = useState<CollectionDtoWithProducts[] | null>(null);
  const [collectionsLoading, setCollectionsLoading] = useState(true);

  const callFetchCollections = () => {
    fetchCollectionsList().then((res: any) => {
      setCollections(res);
      setCollectionsLoading(false);
    });
  }

  useEffect(() => {
    setCollections(collectionsState);
  }, [collectionsState]);

  useEffect(() => {
    if (collections === null) {
      callFetchCollections();
    }
  }, [collections]);


  //open product popup
  useEffect(() => {
    if (products[0] && filtersForAPI && filtersForAPI.productIds && filtersForAPI.productIds.length === 1) {
      showProductModal(filtersForAPI.productIds[0], products[0].objectProductId)
    }
  }, [filtersForAPI, products])

  const fetchItems = useCallback(
    (
      filters: { [key: string]: string[] },
      renew: boolean,
      skip: number,
      // TODO: Clean this default filter
      order: { [key: string]: 'ASC' | 'DESC' } = { promoted: 'DESC', createdAt: 'DESC' }
    ) => {
      if (searchValue) {
        filters.query = [searchValue]
      }
      return fetchProducts({ skip, filters, order }, !renew)
    },
    [fetchProducts, searchValue]
  )

  useEffect(() => {
    if (router.location.pathname.includes(`${Routes.Catalog}/`) && !router.location.query.productIds) {
      window.location.href = `${Routes.Catalog}`
    }

    if (router.location.search) {
      const querystring = parse(router.location.search.substring(1))

      if (querystring.query) {
        const value = querystring.query.toString()
        setSearchValue(value)
        setSearchValueBuffer(value)
      }
    }
    // updateUrl(stringify(filtersForAPI, { arrayFormat: 'comma' }));
    if (router.location.search == '') {
      setfiltersForAPI({})
    }
  }, [router.location.search])

  const cardsData = useMemo<ICardData[]>(() => {
    if (initialFetch) {
      setInitialFetch(false)
      return []
    } else {
      setInitialLoader(false)
      return products.map(
        (p): ICardData => {
          const image =
            p.thumbnail && p.thumbnail.media.url
              ? getResizedPreview(385, 256, p.thumbnail.media.url)
              : undefined

          return {
            id: p.id,
            title: p.brand ? p.brand.name : '',
            image,
            desc: p.name,
            secondName: p.details,
            slug: p.slug,
            side: formatPrice(p.suggestedPrice),
            objectProductId: p.objectProductId,
            imgFit: (p.thumbnail && p.thumbnail.type !== ProductPictureType.Ambiance) || undefined,
            imgBgColor: (p.thumbnail && p.thumbnail.hexaColor) || undefined
          }
        }
      )
    }
  }, [products])

  const onFiltersChange = (f) => {
    setfiltersForAPI(f)
  }

  const handleAddToFavorites = (productId: string, collectionId: string | null) => {
    // If no collection id we create a default collection
    if (collectionId == null) {
      createNewCollection({ name: "Default", products: [{ id: productId }] },
        intl.formatMessage({ id: 'architect.collection.add.success' }),
        intl.formatMessage({ id: 'architect.collection.add.error' })).then(() => { fetchCollectionsList(); })
      return true;
    }
    const collection = collections.find(collection => collection.id === collectionId);
    const collectionProductIds = collection ? collection.products.map(product => ({ id: product.id })) : [];
    const payload = { products: [...collectionProductIds, { id: productId }] };

    return addProductsToCollection(collectionId,
      payload,
      intl.formatMessage({ id: 'architect.collection.add.product.success' }, { collectionName: collection.name }),
      intl.formatMessage({ id: 'architect.collection.add.product.error' }, { collectionName: collection.name }))
      .then(() => {
        callFetchCollections();
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const handleRemoveFromFavorites = (productId: string, collectionId: string) => {
    removeSelectedProductFromCollection(
      collectionId,
      productId,
      intl.formatMessage({ id: 'architect.collections.products.delete.success' }),
      intl.formatMessage({ id: 'architect.collections.products.delete.error' }))
      .then(() => {
        callFetchCollections();
      })
  }

  const handleCreateNewCollection = (collectionName: string, productId: string) => {
    createNewCollection({ name: collectionName, products: [{ id: productId }] },
      intl.formatMessage({ id: 'architect.collection.add.success' }),
      intl.formatMessage({ id: 'architect.collection.add.error' })).then(() => { callFetchCollections(); });
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.filtersContainer, {
          [styles.scrollToTop]: scrollDirection === 'up' || scrollDirection == null,
          [styles.scrollToBottom]: scrollDirection === 'down'
        })}
      >
        <div className={styles.searchBarContainer}>
          <Searchbar
            value={searchValueBuffer}
            onChange={e => setSearchValueBuffer(e.target.value)}
            onBlur={e => setSearchValue(e.target.value)}
            onSearch={v => setSearchValue(v)}
            placeholder={intl.formatMessage({ id: 'catalog.filters.search' })}
          />
        </div>
        {(products.length != 0 || router.location.search != '') &&
          <FiltersContainerCatalog
            onFiltersChange={(f) => onFiltersChange(f)}
            searchValue={searchValue}
            reinitLabel={intl.formatMessage({ id: 'catalog.filters.reinit' })}
            buttonsContainerClassName={styles.filtersBar}
            tagsContainerClassName={styles.filtersBar}
          />
        }
      </div>
      <InfiniteGridWithSearch
        filters={filtersForAPI}
        fetchItems={fetchItems}
        cardsData={cardsData}
        totalItems={totalProducts}
        className={styles.grid}
        searchValue={searchValue}
        loading={loader || initialLoader}
        inner
        loadingMessage={intl.formatMessage({ id: 'catalog.search.message' })}
        onCardClick={(cardData: ICardData) =>
          showProductModal(cardData.id, cardData.objectProductId)
        }
        collectionMenu={(cardData: ICardData) => {
          return <CollectionMenu
            collections={collections}
            createNewCollection={handleCreateNewCollection}
            productId={cardData.id}
            addProductToCollection={(productId, collectionId) => handleAddToFavorites(productId, collectionId)}
            handleRemoveFromFavorites={(productId, collectionId) => handleRemoveFromFavorites(productId, collectionId)}
          />
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  products: state.productState.values,
  totalProducts: state.productState.total,
  user: state.userState.user,
  router: state.router,
  loader: state.productState.loading,
  collectionsState: state.collectionsState.collections,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProducts: (request: IPaginationRequest = {}, concatenate: boolean) =>
    dispatch(productActions.thunks.fetchValues(request, concatenate, undefined, undefined, true)),
  showProductModal: async (productId: string, objectProductId?: string | null) => {
    dispatch(product.thunks.fetchValue(productId))
    dispatch(productViewer.setDisplayViewer(true))

    // Only fecth again if the product changed
    if (!!objectProductId) {
      await dispatch(objectProduct.thunks.fetchValue(objectProductId))
      await dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId))
    }
    dispatch(productViewer.setProductId(productId))
  },
  fetchCollectionsList: () =>
    dispatch(collections.fetchCollections()),
  createNewCollection: (payload: any, success: string, error: string) => dispatch(collections.createCollection(payload, success, error)),
  addProductsToCollection: (id: string, payload: any, success: string, error: string) => dispatch(collections.updateCollection(id, payload, success, error)),
  removeSelectedProductFromCollection: (id: string, productId: string, success: string, error: string) => dispatch(collections.removeProductFromCollection(id, productId, success, error)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(Catalog))
