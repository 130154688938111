import { MixpanelProductDownloadedDTO, MixpanelProductViewedDTO } from '@addsome/dtos'
import mixpanel from 'mixpanel-browser'

export enum MixpanelEvents {
  LoginPageViewed = 'Login Page Viewed',
  RegisterPageViewed = 'Register Page Viewed',
  UserLoggedIn = 'User Logged In',
  ProductViewed = 'productViewed',
  ProductDownloaded = 'productDownloaded',
  UserRegistered = 'User Registered',
  UserRegisteredWithPayment = 'User registered with complete payment',
  UserConnected = 'User Connected',
  UserLoggedOut = 'User Logged Out',
  BrandUserArchitectPageViewed = 'BrandUser Architect Page Viewed',
  AddsomeUserPageViewed = 'AddsomeUser Page Viewed',
  PaymentPageViewed = 'Payment Page Viewed',
  RegisterStep1 = 'Register step 1',
  RegisterStep2 = 'Register step 2',
  RegisterStep3 = 'Register step 3',
  PublicHomePageViewed = 'Public Home Page Viewed',
  PublicCatalogViewed = 'Public Catalog Viewed',
  PublicAccess3DFiles = 'Public access 3D files',
  UserClickSelectPlan = 'User Select Plan',
  UserClickSelectPack = 'User Select Pack'
}

const shouldSendEvent =
  process.env.REACT_APP_MIXPANEL_TOKEN &&
  process.env.REACT_APP_ENV &&
  ['PREPROD', 'PROD'].includes(process.env.REACT_APP_ENV)

const shouldLog =
  process.env.REACT_APP_ENV && ['PREPROD', 'DEV'].includes(process.env.REACT_APP_ENV)

if (shouldSendEvent) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '')
}

const Mixpanel = {
  identify: (userMail: string) => {
    if (shouldSendEvent) mixpanel.identify(userMail)
  },
  // TODO: If we have many anonymous interactions and want to link them to the user account
  // We need to follow this documentation https://help.mixpanel.com/hc/en-us/articles/115004497803
  // However for now signup does not return the user-id, and we don't really need it anyway
  alias: (userMail: string) => {
    if (shouldLog) console.log(`Mixpanel aliasing - ${userMail}`)
    if (shouldSendEvent) mixpanel.alias(userMail)
  },
  track: (name: MixpanelEvents, props?: mixpanel.Dict) => {
    if (shouldLog) console.log(`Mixpanel tracking - ${name}`)
    if (shouldSendEvent) mixpanel.track(name, props)
  },
  register: (props: mixpanel.Dict, days?: number | undefined) => {
    if (shouldLog) console.log(`Mixpanel registering - ${JSON.stringify(props)}`)
    if (shouldSendEvent) mixpanel.register(props, days)
  },
  trackProductViewed: (props: MixpanelProductViewedDTO) => {
    if (shouldLog) console.log(`Mixpanel tracking ProductView - ${props.productName}`)
    if (shouldSendEvent) mixpanel.track(MixpanelEvents.ProductViewed, props)
  },
  trackProductDownloaded: (props: MixpanelProductDownloadedDTO) => {
    if (shouldLog) console.log(`Mixpanel tracking ProductDownload - ${props.productName}`)
    if (shouldSendEvent) mixpanel.track(MixpanelEvents.ProductDownloaded, props)
  },
  people: {
    set: (props: mixpanel.Dict | string) => {
      if (shouldLog) console.log(`Mixpanel setting people - ${JSON.stringify(props)}`)
      if (shouldSendEvent) mixpanel.people.set(props)
    }
  },
  reset: () => {
    if (shouldLog) console.log(`Mixpanel resetting`)
    if (shouldSendEvent) mixpanel.reset()
  }
}

export default Mixpanel
