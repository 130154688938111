"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.removeRender = exports.updateRender = exports.unsetRender = exports.setRender = exports.addRenders = exports.setRenders = void 0;

var model_1 = require("./model");

var setRenders = function setRenders(renders, total) {
  return {
    type: model_1.ActionsTypes.SET_RENDERS,
    renders: renders,
    total: total
  };
};

exports.setRenders = setRenders;

var addRenders = function addRenders(renders, total) {
  return {
    type: model_1.ActionsTypes.ADD_RENDERS,
    renders: renders,
    total: total
  };
};

exports.addRenders = addRenders;

var setRender = function setRender(render) {
  return {
    type: model_1.ActionsTypes.SET_RENDER,
    render: render
  };
};

exports.setRender = setRender;

var unsetRender = function unsetRender() {
  return {
    type: model_1.ActionsTypes.UNSET_RENDER
  };
};

exports.unsetRender = unsetRender;

var updateRender = function updateRender(render) {
  return {
    type: model_1.ActionsTypes.UPDATE_RENDER,
    render: render
  };
};

exports.updateRender = updateRender;

var removeRender = function removeRender(id) {
  return {
    type: model_1.ActionsTypes.REMOVE_RENDER,
    id: id
  };
};

exports.removeRender = removeRender;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_RENDER_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;