import { BrandPictureDTO, MediaDTO, FileDTO } from '@addsome/dtos';
import { InvisibleButton, Popconfirm } from '@addsome/ui-kit';
import { Icon } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import style from './Media.module.scss';
import { getCloudImageUrl } from '../../utils/cloudImage';
import { FormattedMessage } from 'react-intl';
import { ThumbnailChecked, ThumbnailUnchecked } from '../SVG/Icons';
import { number } from 'yup';
interface IProps {
  key: string,
  src: string;
  enabled: boolean;
  name: string;
  media: MediaDTO;
  onChange: (id: string, enabled: boolean) => void;
  onDelete: (id: string) => void;
  readonly?: boolean;
  onClick?: () => void;
  picture: FileDTO;
  setImageAsThumbnailById?: (imageId: string) => void;
  thumbnailPictureId?: string;
}
let checkedThumbnailKey: string;
export default class Media extends React.Component<IProps> {
  public render() {

    return (
      <div className={classNames(style.media, { [style.disabled]: !this.props.enabled })}>
        <div className={style.actions}>
          {this.props.media.url && (
            <div className={style.flex}>
              <>
                {this.props.thumbnailPictureId === this.props.picture.id ?

                  <div onClick={(event) => {
                    event.stopPropagation();
                    this.props.setImageAsThumbnailById && this.props.setImageAsThumbnailById(this.props.picture.id);
                  }}>
                    <ThumbnailChecked />
                  </div>
                  :
                  <div onClick={(event) => { event.stopPropagation(); this.props.setImageAsThumbnailById && this.props.setImageAsThumbnailById(this.props.picture.id); }} >
                    <ThumbnailUnchecked />
                  </div>
                }</>

              <a
                aria-label={`Télécharger média ${this.props.name}`}
                href={this.props.media.url}
                download
                target="_blank"
              >
                <Icon type="download" aria-hidden />
              </a>

            </div>
          )}
          {!this.props.readonly && (
            <Popconfirm
              title={<FormattedMessage id="global.confirmdelete" />}
              onConfirm={this.delete}
              okText={<FormattedMessage id="global.yes" />}
              cancelText={<FormattedMessage id="global.no" />}
            >
              <InvisibleButton aria-label={`Supprimer média ${this.props.name}`}>
                <Icon type="delete" aria-hidden />
              </InvisibleButton>
            </Popconfirm>
          )}
        </div>
        <InvisibleButton onClick={this.props.onClick}>
          <img src={getCloudImageUrl(this.props.src, 200)} alt={this.props.name} />
        </InvisibleButton>
        <div className={style.controls}>
          <div className={style.resolution}>
            {this.props.media.width} x {this.props.media.height}
          </div>

          {/* Not used for now
          {!this.props.readonly && (
            <Switch aria-label="Actif" checked={this.props.enabled} onChange={this.handleChange} />
          )} */}
        </div>
      </div>
    )
  }

  private handleChange = (enabled: boolean) => {
    this.props.onChange(this.props.media.id, enabled)
  }

  private delete = () => {
    this.props.onDelete(this.props.media.id)
  }
}
