"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.addAlbums = exports.setAlbums = void 0;

var model_1 = require("./model");

var setAlbums = function setAlbums(albums, total) {
  return {
    type: model_1.ActionsTypes.SET_ALBUMS,
    albums: albums,
    total: total
  };
};

exports.setAlbums = setAlbums;

var addAlbums = function addAlbums(albums, total) {
  return {
    type: model_1.ActionsTypes.ADD_ALBUMS,
    albums: albums,
    total: total
  };
};

exports.addAlbums = addAlbums;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_ALBUMS_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;