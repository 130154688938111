import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { IRootState } from '../../../redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { communityScene } from '@addsome/redux-store'
import { Table, Switch, Modal, Popconfirm, Button, Theme } from '@addsome/ui-kit'
import { SceneDTO } from '@addsome/dtos/dist'
import { ColumnProps, PaginationConfig } from 'antd/lib/table'
import styles from '../CommunityPage.module.scss'
import { RecordVoiceOverSharp, TheatersOutlined } from '@mui/icons-material'

type IProps = WrappedComponentProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const CommunityPageAdmin: React.FC<IProps> = ({ scenes, loading, intl, fetchScenes, toggleEnableScene, togglePromotedScene, removeScene }) => {

  const [showProductsModal, setShowProductsModal] = useState(false)
  const [showMediasModal, setShowMediasModal] = useState(false)
  const [selectedScene, setSelectedScene] = useState(null)
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const pagination: PaginationConfig = {
    total: total,
    pageSize: pageSize,
    current: currentPage,
    showTotal: total =>
      `${total} ${intl.formatMessage({ id: 'architecttable.results' })}`
  }

  const columns: Array<ColumnProps<SceneDTO>> = [
    {
      title: <FormattedMessage id="addproduct.pictures" />,
      key: 'picture',
      render: (value, record) => (
        <div
          onClick={() => {
            setSelectedScene(record)
            setShowMediasModal(true)
          }}
          className={styles.thumbnail}
        >
          {record.medias.length > 0 &&
            <img src={record.medias[0].url} loading='lazy' />
          }
        </div>
      )
    },
    {
      title: <FormattedMessage id="architecttable.name" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <FormattedMessage id="global.products" />,
      dataIndex: 'products',
      key: 'products',
      align: 'center',
      render: (value, record) => (
        <div className={styles.productsColumn} onClick={() => {
          setShowProductsModal(true)
          setSelectedScene(record)
        }}>
          {record.products!.length}
        </div>
      )
    },
    {
      title: <FormattedMessage id="register.architect.type.architect" />,
      dataIndex: 'architect.account.lastName',
      key: 'architectName',
      render: (value, record) => (
        <a href={`/architects/${record.architect.id}/edit`}>
          {record.architect.account.firstName} {record.architect.account.lastName}
        </a>
      )
    },
    {
      title: <FormattedMessage id="community.vizualizationsCount" />,
      dataIndex: 'vizualizationsCount',
      key: 'vizualizationsCount',
      align: 'center'
    },
    {
      title: <FormattedMessage id="global.promoted" />,
      dataIndex: 'promoted',
      key: 'promoted',
      align: 'center',
      render: (value, record) => (
        <Switch
          defaultChecked={record.promoted}
          onChange={() => togglePromotedScene(record.id, value)}
        />
      )
    },
    {
      title: <FormattedMessage id="user.enable" />,
      dataIndex: 'enabled',
      key: 'enabled',
      align: 'center',
      render: (value, record) => (<Switch
        defaultChecked={record.enabled}
        onChange={() => toggleEnableScene(record.id, value)}
      />
      )
    },
    {
      title: '',
      key: 'deleted',
      render: (v, record) => (
        <Popconfirm
          title={<FormattedMessage id="global.confirmdelete" />}
          onConfirm={() => removeScene(record.id).then((m) => fetchScenes())}
          okText={<FormattedMessage id="global.yes" />}
          cancelText={<FormattedMessage id="global.no" />}
          placement="left"
        >
          <Button
            theme={Theme.GHOST}
            shape="circle"
            icon="delete"
            title={intl.formatMessage({ id: "global.delete" })}
          />
        </Popconfirm>
      ),
      width: '5%'
    },
  ]

  useEffect(() => {
    fetchScenes().then(
      (s) => setTotal(s.length)
    )
  }, [])


  return (
    <div className={styles.adminTableContainer}>
      <Table
        columns={columns}
        dataSource={scenes}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={(r) => setCurrentPage(r.current)}
      />
      <Modal
        visible={showProductsModal}
        title={selectedScene && selectedScene.name}
        onCancel={() => setShowProductsModal(false)}
        footer={<> </>}
      >
        <div className={styles.adminProductsWrapper}>
          {selectedScene && selectedScene.products.length &&
            selectedScene.products.map((p) =>
              <a href={`/productsEdit/${p.id}`} target='_blank' key={p.id} className={styles.adminProductCard}>
                <img src={p.thumbnail.media.url} />
                <h3>{p.brand.name}</h3>
                <span>{p.name}</span>
              </a>)
          }
        </div>
      </Modal>
      <Modal
        visible={showMediasModal}
        title={selectedScene && selectedScene.name}
        onCancel={() => setShowMediasModal(false)}
        footer={<> </>}
      >
        <div className={styles.adminMediasWrapper}>
          {selectedScene && selectedScene.medias.length &&
            selectedScene.medias.map((m) =>
              <div key={m.id} className={styles.adminMediaCard}>
                <img src={m.url} />
              </div>)
          }
        </div>
      </Modal>
    </div>
  )

}

const mapStateToProps = (state: IRootState) => ({
  scenes: state.communitySceneState.allScenes,
  loading: state.communitySceneState.loading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchScenes: () => dispatch(communityScene.fetchAllScenes()),
  removeScene: (id: string) => dispatch(communityScene.removeScene(id)),
  toggleEnableScene: (id: string, enabled: boolean) => {
    dispatch(
      communityScene.patchScene(id, {
        enabled: !enabled
      })
    );
  },
  togglePromotedScene: (id: string, promoted: boolean) => {
    dispatch(
      communityScene.patchScene(id, {
        promoted: !promoted
      })
    );
  },
})


export default connect(mapStateToProps,
  mapDispatchToProps)(injectIntl(CommunityPageAdmin))