"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  init: false,
  displayViewer: false,
  variationIndex: 0,
  displayActions: false,
  thumbnailPictureId: null
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_VIEWER_DISPLAY, function (state, _ref) {
  var displayViewer = _ref.displayViewer;
  return Object.assign(Object.assign({}, state), {
    displayViewer: displayViewer,
    displayActions: !displayViewer ? false : state.displayActions,
    init: true
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_VIEWER_PRODUCT_ID, function (state, _ref2) {
  var productId = _ref2.productId;
  return Object.assign(Object.assign({}, state), {
    productId: productId
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UNSET_VIEWER_PRODUCT_ID, function (state) {
  return Object.assign(Object.assign({}, state), {
    productId: undefined
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_VARIATION_INDEX, function (state, _ref3) {
  var variationIndex = _ref3.variationIndex;
  return Object.assign(Object.assign({}, state), {
    variationIndex: variationIndex
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_SELECTED_PICTURE_ID, function (state, _ref4) {
  var selectedPictureId = _ref4.selectedPictureId;
  return Object.assign(Object.assign({}, state), {
    selectedPictureId: selectedPictureId
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UNSET_SELECTED_PICTURE_ID, function (state) {
  return Object.assign(Object.assign({}, state), {
    selectedPictureId: undefined
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_DISPLAY_ACTIONS, function (state, _ref5) {
  var displayActions = _ref5.displayActions;
  return Object.assign(Object.assign({}, state), {
    displayActions: displayActions
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_AS_THUMBNAIL_PICTURE_BY_ID, function (state, _ref6) {
  var pictureId = _ref6.pictureId;
  return Object.assign(Object.assign({}, state), {
    thumbnailPictureId: pictureId
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);