import { AutoComplete, InputField, Integration, Option, Select, Size, Switch } from '@addsome/ui-kit'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './PageHeader.module.scss'
import { AccountType, ProductStateDTO } from '@addsome/dtos/dist'
import { setProductStates } from '@addsome/redux-store/dist/store/product-states'
import { Icon } from 'antd'
import { IRootState } from '../../redux'
import { connect } from 'react-redux'

interface IRichParams {
  info?: string | React.ReactNode
  icon?: string
  enabled?: boolean
  promoted?: boolean
  onChangeEnabled?: (value: boolean) => void
  onChangePromoted?: (value: boolean) => void
  namespace?: string
  readonly?: boolean
  onChangeStateId?: (value: string) => void
  stateId?: string
}

type IProps = ReturnType<typeof mapStateToProps> & {
  title: React.ReactNode
  backgroundSrc?: string
  richParams?: IRichParams
  children?: React.ReactNode
  hasCards?: boolean
  size?: Size
  productStates?: ProductStateDTO[]
  isLoading?: boolean
  changeLoader?: () => void
}


class PageHeader extends React.Component<IProps> {
  public render() {
    const { backgroundSrc, size = Size.SMALL, richParams, hasCards } = this.props;
    var orderedProductStates: ProductStateDTO[] = []
    if (this.props.productStates) {
      orderedProductStates = new Array(this.props.productStates.length)
      this.props.productStates.map((state) => {
        if (state.id === '200')
          orderedProductStates[0] = state
        if (state.id === '300')
          orderedProductStates[1] = state
        if (state.id === '350')
          orderedProductStates[2] = state
        if (state.id === '000')
          orderedProductStates[3] = state
        if (state.id === '050')
          orderedProductStates[4] = state
        if (state.id === '100')
          orderedProductStates[5] = state
        if (state.id === '400')
          orderedProductStates[6] = state
        if (state.id === '500')
          orderedProductStates[7] = state
      })
    }
    return (
      <header
        style={{
          backgroundImage: `url(${backgroundSrc})`,
          backgroundPosition: 'center'
        }}
        className={classNames(styles.pageHeader, {
          [styles.megaBig]: size === Size.LARGE,
          [styles.big]: size === Size.MEDIUM || size === Size.LARGE,
          [styles.dark]: backgroundSrc,
          [styles.rich]: richParams,
          [styles.hasCards]: hasCards
        })}
      >
        {!richParams && (
          <>
            {this.props.title}
            {this.props.children}
          </>
        )}

        {richParams && (
          <div className={styles.richContent}>
            <div className={styles.richLeft}>
              {richParams.icon && <img src={richParams.icon} className={styles.icon} alt="Logo" />}
              <div className={styles.richTitle}>
                {this.props.title}
                {richParams.info && <div className={styles.info}>{richParams.info}</div>}
                {richParams.enabled !== undefined && size === Size.MEDIUM && !richParams.readonly && (
                  <Switch
                    onChange={richParams.onChangeEnabled}
                    checked={richParams.enabled}
                    integration={Integration.DARK}
                    className={styles.enabled}
                    disabled={richParams.readonly}
                  >
                    <FormattedMessage
                      id={
                        (richParams.namespace || 'global') +
                        '.' +
                        (richParams.enabled ? 'active' : 'inactive')
                      }
                    />
                  </Switch>
                )}
              </div>
            </div>
            <div className={styles.richRight}>
              {
                this.props.accountType != AccountType.Architect && this.props.productStates && richParams && (
                  <div className={styles.inputFieldDropdownProductState}>
                    <InputField error={""}>
                      <Select
                        value={richParams.stateId}
                        onChange={
                          value => {
                            richParams.onChangeStateId && richParams.onChangeStateId(value.toString())
                            this.props.changeLoader && this.props.changeLoader()
                          }
                        }
                      >
                        {
                          orderedProductStates.map((productState) => (
                            <Option key={productState.id} value={productState.id}>
                              {productState.title}
                            </Option>
                          ))
                        }
                      </Select>
                      {
                        this.props.isLoading && (
                          <div className={styles.loading}>
                            <Icon type="loading" style={{ fontSize: '2em', color: '#FFFFFF' }} />
                          </div>
                        )
                      }
                    </InputField>
                  </div>
                )
              }

              {richParams.enabled !== undefined && size !== Size.MEDIUM && !richParams.readonly && (
                <div className={styles.switchBox}>
                  <Switch
                    onChange={richParams.onChangeEnabled}
                    checked={richParams.enabled}
                    integration={Integration.DARK}
                    className={styles.enabled}
                    disabled={richParams.readonly}
                  >
                    <FormattedMessage
                      id={
                        (richParams.namespace || 'global') +
                        '.' +
                        (richParams.enabled ? 'active' : 'inactive')
                      }
                    />
                  </Switch>
                  <Switch
                    onChange={richParams.onChangePromoted}
                    checked={richParams.promoted}
                    integration={Integration.DARK}
                    className={styles.enabled}
                    disabled={richParams.readonly}
                  >
                    <FormattedMessage id='global.promoted' />
                  </Switch>
                </div>
              )}
            </div>
          </div>
        )}
      </header>
    )
  }
}
const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type
})

export default connect(mapStateToProps)(PageHeader)
