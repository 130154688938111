import {
  BrandDTO,
  BrandUpdateDTO,
  FileCreationDTO,
  FileDTO,
  ObjectProductDTO,
  ObjectProductUpdateDTO,
  ProductDocumentCreationDTO,
  ProductDocumentDTO,
  ProductRelationshipsExtendedDTO,
  ProductSourceFileCreationDTO,
  ProductSourceFileDTO,
  ProductUpdateDTO
} from '@addsome/dtos'
import get from 'lodash.get'

export const convertProductForUpdate = (
  product: ProductRelationshipsExtendedDTO
): ProductUpdateDTO => ({
  ...product,
  objectProduct: undefined,
  brandId: get(product, 'brand.id'),
  thumbnail: get(product, 'thumbnail.id', null),
  pictures: (product.pictures || []).map(convertPictureForCreation),
  previews: (product.previews || []).map(convertPictureForCreation),
  sourceFiles: (product.sourceFiles || []).map(convertSourceFileForCreation),
  documents: product.documents.map(convertDocumentFileForCreation),
  designerIds: product.designers.map(designer => designer.id)
})

export const convertObjectProductForUpdate = (
  objectProduct: ObjectProductDTO
): ObjectProductUpdateDTO => ({
  ...objectProduct,
  categoryIds: objectProduct.categoryIds || undefined
})

export const convertBrandForUpdate = (brand: BrandDTO): BrandUpdateDTO => ({
  ...brand,
  pictures: brand.pictures.map(convertPictureForCreation),
  madeIn: brand.madeIn || undefined,
  logo: get(brand, 'logo.id', null),
  cover: get(brand, 'cover.id', null)
})

export const convertPictureForCreation = (picture: FileDTO): FileCreationDTO => ({
  enabled: picture.enabled,
  mediaId: picture.media.id
})

export const convertSourceFileForCreation = (
  source: ProductSourceFileDTO
): ProductSourceFileCreationDTO => ({
  name: source.name,
  mediaId: source.media.id
})

export const convertDocumentFileForCreation = (
  doc: ProductDocumentDTO
): ProductDocumentCreationDTO => ({
  name: doc.name,
  mediaId: doc.media.id,
  enabled: doc.enabled
})
