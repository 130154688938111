import { auth, brandInvitation } from '@addsome/redux-store';
import { Button, InputField, Size, Theme, InvisibleButton } from '@addsome/ui-kit';
import Form from 'antd/lib/form';
import Icon from 'antd/lib/icon';
import { push } from 'connected-react-router';
import { parse } from 'querystring';
import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from '../../../redux';
import styles from './LoginForm.module.scss';
import Routes from '../../../utils/routes';
import { Apps, BrandDTO } from '@addsome/dtos';
import {
  setGuestInviteToken,
  fetchInvitationBrand
} from '@addsome/redux-store/dist/store/brand-invitation';
import jwt_decode from 'jwt-decode';
import googlePng from '../../../assets/images/google.svg';
import facebookLogo from '../../../assets/images/facebookLogo.jpeg';
import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps;

interface IState {
  emailValue: string;
  brandInvitationToken: string | null;
  passwordValue: string;
  loginErrorMessageId: string;
  incomplete: boolean;
  showPassword: boolean;
  displayInfoArchitectModal: boolean;
  isEmailValidated: boolean;
  googleButtonWrapper: any,
  loginWithGoogle: string,
  loginWithFacebook: string,
  productId: string,
  redirect: string,
}

class LoginForm extends React.PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      emailValue: (props.queryParams.email as string) || '',
      brandInvitationToken: (props.queryParams.brandInvitationToken as string) || null,
      passwordValue: '',
      loginErrorMessageId: '',
      incomplete: false,
      showPassword: false,
      displayInfoArchitectModal: false,
      isEmailValidated: Boolean(props.queryParams.email),
      googleButtonWrapper: undefined,
      loginWithGoogle: (props.queryParams.loginWithGoogle as string) || '',
      loginWithFacebook: (props.queryParams.loginWithFacebook as string) || '',
      productId: (props.queryParams.productId as string) || '',
      redirect: (props.queryParams.redirect as string) || '',
    };
  }

  public async componentDidMount() {
    // If we have  brandInvitationToken in the url we set our store so that the brand is added to the architect brand after login
    if (this.state.brandInvitationToken != null && this.state.brandInvitationToken !== '') {
      await this.props.fetchBrandFromToken(this.state.brandInvitationToken);
      this.props.setGuestInviteToken(this.state.brandInvitationToken);
    }


    /* global google */
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: '892022811072-g49j13o9gith6frtue6289b905ps2d7d.apps.googleusercontent.com',
      callback: (response: any) => this.handleGoogleLogin(response),
    });

    this.setState({ googleButtonWrapper: await this.createFakeGoogleWrapper() });
    if (this.state.loginWithGoogle != '') {
      const decodedJsonString = decodeURIComponent(this.state.loginWithGoogle);
      this.handleGoogleLogin(JSON.parse(decodedJsonString));
    }

    if (this.state.loginWithFacebook != '') {
      const decodedJsonString = decodeURIComponent(this.state.loginWithFacebook);
      this.handleFacebookLogin(JSON.parse(decodedJsonString));
    }

    window.FB.init({
      appId: '237073772638078',
      cookie: true,
      xfbml: true,
      version: 'v17.0'
    });

    window.FB.Event.subscribe('auth.statusChange', response => {
      if (response.authResponse) {
        this.checkLoginState();
      }
    });
  }

  checkLoginState() {
    window.FB.getLoginStatus(function (response) {
      this.statusChangeCallback(response);
    }.bind(this));
  }

  statusChangeCallback(response) {
    if (response.status === 'connected') {
      this.fetchUserInfo();
    }
  }

  fetchUserInfo() {
    const handleFacebookLogin = this.handleFacebookLogin.bind(this);
    window.FB.api('/me', { fields: 'first_name,last_name,email' }, function (response) {
      if (response.email) {
        handleFacebookLogin(response);
      }
    });
  }

  handleClick() {
    window.FB.login(this.checkLoginState(), { scope: 'email' });
  }

  public async handleFacebookLogin(response: any) {
    try {
      const canLoginResponse = await this.props.canLogin(response.email);

      if (!canLoginResponse.canLogin) {
        this.props.routerPush(Routes.Register, {
          email: response.email,
          lastName: response.last_name,
          firstName: response.first_name
        });
      } else {
        const isAccountDisabled = await this.props.login(
          response.email,
          'facebook',
          'google'
        );
        if (isAccountDisabled !== undefined && isAccountDisabled) {
          this.setState({ displayInfoArchitectModal: true });
        } else {
          const { queryParams, routerPush, guestInviteToken, resetBrand } = this.props;

          if (guestInviteToken) {
            resetBrand();
            routerPush(`${Routes.Guests}/${guestInviteToken}`);
          } else {
            if (queryParams.from) {
              const redirection = {
                from: queryParams.from as string,
                searchFrom: queryParams.searchFrom as string
              };
              routerPush(redirection.from + (redirection.searchFrom || ''));
            } else {
              this.state.productId ? routerPush(Routes.Catalog + `?productIds=${this.state.productId}`) : routerPush(Routes.Index);;
            }
          }
        }
      }
    } catch (e) {
      this.setState({
        loginErrorMessageId: `login.error.${e.data ? e.data.message : 'generic'}`
      });
    }
  }

  public async createFakeGoogleWrapper() {
    const googleLoginWrapper = document.createElement("div");
    // Or you can simple hide it in CSS rule for custom-google-button
    googleLoginWrapper.style.display = "none";
    googleLoginWrapper.classList.add("custom-google-button");

    // Add the wrapper to body
    document.body.appendChild(googleLoginWrapper);

    // Use GSI javascript api to render the button inside our wrapper
    // You can ignore the properties because this button will not appear
    //@ts-ignore
    google.accounts.id.renderButton(googleLoginWrapper, {
      theme: "outline",
    });
    const googleLoginWrapperButton =
      googleLoginWrapper.querySelector("div[role=button]");
    return {
      click: () => {
        googleLoginWrapperButton && googleLoginWrapperButton.click();
      },
    };
  };
  public async handleGoogleLogin(response: any) {
    const userObj = jwt_decode(response.credential);
    try {
      const canLoginResponse = await this.props.canLogin(userObj.email);

      if (!canLoginResponse.canLogin) {
        this.props.routerPush(Routes.Register, {
          email: userObj.email,
          lastName: userObj.family_name,
          firstName: userObj.given_name
        });
      } else {
        const isAccountDisabled = await this.props.login(
          userObj.email,
          response.credential,
          'google'
        );

        if (isAccountDisabled !== undefined && isAccountDisabled) {
          this.setState({ displayInfoArchitectModal: true });
        } else {
          const { queryParams, routerPush, guestInviteToken, resetBrand } = this.props;

          if (guestInviteToken) {
            resetBrand();
            routerPush(`${Routes.Guests}/${guestInviteToken}`);
          } else {
            if (queryParams.from) {
              const redirection = {
                from: queryParams.from as string,
                searchFrom: queryParams.searchFrom as string
              };
              routerPush(redirection.from + (redirection.searchFrom || ''));
            } else {
              this.state.productId ? routerPush(Routes.Catalog + `?productIds=${this.state.productId}`) : routerPush(Routes.Index);;
            }
          }
        }
      }
    } catch (e) {
      this.setState({
        loginErrorMessageId: `login.error.${e.data ? e.data.message : 'generic'}`
      });
    }
  }


  public render() {
    const { loading, intl } = this.props;
    const {
      emailValue,
      passwordValue,
      loginErrorMessageId,
      incomplete,
      isEmailValidated
    } = this.state;

    return (
      <>
        <Form onSubmit={this.handleSubmit} className={styles.form}>
          {this.state.displayInfoArchitectModal && (
            <div className={styles.error}>
              <FormattedMessage id="login.error.Account_Not_Validated" />
            </div>
          )}
          {(loginErrorMessageId || incomplete) && (
            <div className={styles.error}>
              <FormattedMessage id={incomplete ? 'login.incomplete' : loginErrorMessageId} />
            </div>
          )}
          {isEmailValidated && (
            <div className={styles.validation}>
              <FormattedMessage id="login.isEmailValidated" />
            </div>
          )}
          <Form.Item label={intl.formatMessage({ id: 'login.email' })} colon={false}>
            <InputField
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={intl.formatMessage({ id: 'login.email.placeholder' })}
              name="email"
              type="text"
              value={emailValue}
              onChange={this.handleEmail}
            />
          </Form.Item>

          <Form.Item label={intl.formatMessage({ id: 'login.password' })} colon={false}>
            <InputField
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={intl.formatMessage({ id: 'login.password.placeholder' })}
              name="password"
              type={this.state.showPassword ? 'text' : 'password'}
              value={passwordValue}
              onChange={this.handlePassword}
              addonAfter={
                <InvisibleButton
                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                  aria-label="Afficher / Masquer le mot de passe"
                  type="button"
                >
                  <Icon type={this.state.showPassword ? 'eye' : 'eye-invisible'} />
                </InvisibleButton>
              }
            />
          </Form.Item>
          <Link to="/forgotten-password">
            <FormattedMessage id="login.forgotPassword" />
          </Link>

          <Button
            htmlType="submit"
            theme={Theme.PRIMARY}
            className={styles.submit}
            loading={loading}
            uppercase
            size={Size.LARGE}
          >
            <FormattedMessage id="login.submit" />
          </Button>

          <Button
            theme={Theme.GHOST}
            className={styles.submit}
            loading={loading}
            uppercase
            size={Size.LARGE}
            onClick={() => { this.state.googleButtonWrapper && this.state.googleButtonWrapper.click() }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img style={{ width: '15px', height: '15px', marginRight: '8px' }} src={googlePng} />
            <span style={{ flex: 1, textAlign: 'center' }}>Sign in with Google</span>
          </Button>

          <Button
            theme={Theme.GHOST}
            className={styles.submit}
            loading={loading}
            uppercase
            size={Size.LARGE}
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => this.handleClick()}
          >
            <img style={{ width: '17px', height: '17px', marginRight: '8px' }} src={facebookLogo} />
            <span style={{ flex: 1, textAlign: 'center' }}>Sign in with Facebook</span>
          </Button>

          {/* commented for ticket #73 (https://gitlab.com/addsome-io/addsome-monorepo/-/issues/73) */}
          {/* {this.props.queryParams && this.props.queryParams.unlock_register && this.props.queryParams.unlock_register == 'true' && */}
          <div className={styles.noAccount}>
            <FormattedMessage id="login.noAccount" />{' '}
            <Link to={Routes.Register}>
              <FormattedMessage id="login.register" />
            </Link>
          </div>
          {/*}*/}
        </Form>
      </>
    );
  }

  private handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    this.setState({
      loginErrorMessageId: '',
      incomplete: !(this.state.emailValue && this.state.passwordValue)
    });

    if (this.state.emailValue && this.state.passwordValue) {
      try {
        const isAccountDisabled = await this.props.login(this.state.emailValue, this.state.passwordValue);
        if (isAccountDisabled && isAccountDisabled !== undefined) {
          this.setState({ displayInfoArchitectModal: true });
        }
        else {
          const { queryParams, routerPush, guestInviteToken, resetBrand } = this.props;
          if (guestInviteToken) {
            // If we have a guest invite token we redirect to the proper page
            resetBrand();
            routerPush(`${Routes.Guests}/${guestInviteToken}`);
          } else {
            if (queryParams.from) {
              const redirection = {
                from: queryParams.from as string,
                searchFrom: queryParams.searchFrom as string
              };
              routerPush(redirection.from + (redirection.searchFrom || ''));
            } else {
              if (this.state.productId) {
                routerPush(Routes.Catalog + `?productIds=${this.state.productId}`);
              } else  if (this.state.redirect && this.state.redirect == 'community') {
                routerPush(Routes.Community)
              } else {
                routerPush(Routes.Index);
              }
            }
          }
        }
      } catch (e) {
        this.setState({
          loginErrorMessageId: `login.error.${e.data ? e.data.message : 'generic'}`
        });
      }
    }
  };

  private handleEmail: React.ChangeEventHandler<HTMLInputElement> = event =>
    this.setState({ emailValue: event.currentTarget.value });

  private handlePassword: React.ChangeEventHandler<HTMLInputElement> = event =>
    this.setState({ passwordValue: event.currentTarget.value });
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.authState.loading,
  guestInviteToken: state.brandInvitationState.guestInviteToken,
  queryParams: parse(state.router.location.search.replace(/^\?/, ''))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  login: (email: string, password: string, type?: string) =>
    dispatch(auth.login(email, password, Apps.Backoffice, undefined, type)).then(r => {
      Mixpanel.identify(email)
      Mixpanel.track(MixpanelEvents.UserLoggedIn)
      Mixpanel.track(MixpanelEvents.UserConnected)
    })
  ,
  routerPush: (location: string, params?: any) => dispatch(push(location, params)),
  resetBrand: () => dispatch(brandInvitation.setInvitationBrand((null as unknown) as BrandDTO)),
  setGuestInviteToken: (token: string) => dispatch(setGuestInviteToken(token)),
  fetchBrandFromToken: (token: string) => dispatch(fetchInvitationBrand(token)),
  canLogin: (email: string) => dispatch(auth.canLoginWithGoogle(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LoginForm));
