"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SurfaceProductType;

(function (SurfaceProductType) {
  SurfaceProductType["Wall"] = "wall";
  SurfaceProductType["Ground"] = "ground";
})(SurfaceProductType = exports.SurfaceProductType || (exports.SurfaceProductType = {}));