import io from 'socket.io-client'
import { SocketEvents, ProductAccountMetricWsEvent } from '@addsome/dtos'
import settings from '../settings'

class AppSocketManager {
  private static instance: AppSocketManager

  public socket: SocketIOClient.Socket | null = null
  public token: string | null = null

  public constructor() {
    if (AppSocketManager.instance) {
      return AppSocketManager.instance
    }

    AppSocketManager.instance = this
  }

  public login(token: string) {
    if (token !== this.token || !this.socket) {
      this.token = token

      // check middleware if one day we need something didn't need authorization (io.use)
      this.socket =
        (settings.api.baseUrl &&
          io(settings.api.baseUrl, {
            query: {
              token: `Bearer ${token}`
            }
          })) ||
        null
    }
  }

  public emiProductAccountMetricEvent(params: ProductAccountMetricWsEvent) {
    this.socket && this.socket.emit(SocketEvents.ProductAccountMetric, params)
  }

  public logout() {
    this.socket && this.socket.close()
    this.socket = null
    this.token = null
  }
}

const AppSocket = new AppSocketManager()

export default AppSocket
