"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.setProductStates = void 0;

var model_1 = require("./model");

var setProductStates = function setProductStates(productStates) {
  return {
    type: model_1.ActionsTypes.SET_PRODUCT_STATES,
    productStates: productStates
  };
};

exports.setProductStates = setProductStates;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_PRODUCT_STATES_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;