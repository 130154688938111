import {
  AccountType,
  BrandUserDTO,
  BrandUserRole,
  MediaDTO,
  ProductDocumentDTO,
  ProductRelationshipsExtendedDTO,
  ProductSourceFileDTO,
  ProductUpdateDTO,
  BrandLightDTO,
  ProductFileType,
  ArchitectWithBrandDTO,
  ErrorsCode,
  ArchitectUpdateDTO,
  ArchitectSubscriptionType,
  ArchitectDTO,
  ArchitectSubscriptionDTO
} from '@addsome/dtos';
import {
  product as productActions,
  architect as architectActions,
  axiosInstance,
  IPaginationRequest,
  architectBrands,
  user
} from '@addsome/redux-store';
import Progress from 'antd/lib/progress'
import { setUser } from '@addsome/redux-store/dist/store/user';
import { Heading, Modal, Button, Theme, Loading } from '@addsome/ui-kit';
import { Icon, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { default as donnees2DImg } from '../../../assets/images/donnees-2D.svg';
import donnees3DImg from '../../../assets/images/donnees-3D.svg';
import { IRootState } from '../../../redux';
import {
  convertDocumentFileForCreation,
  convertPictureForCreation,
  convertSourceFileForCreation
} from '../../../services/convertions';
import { FileType, getFileType, getFileTypeByName } from '../../../services/filesTypes';
import { deleteMedia, deleteMultipleMedia, mediaUploadUrl } from '../../../services/media';
import Mixpanel from '../../../utils/mixpanel';
import EditableTable, { IColumnPropsEditable } from '../../EditableTable/EditableTable';
import UploadMedia, { UploadType } from '../../UploadMedia/UploadMedia';
import ImportFile, { TypeImport } from '../ImportFile';
import styles from './ProductFiles.module.scss';
import {
  setShouldShowArchitectNotEnabledDownloadPopup,
  setShouldShowArchitectNotLinkedToBrandDownloadPopup
} from '@addsome/redux-store/dist/store/auth';
import { downloadBlob, downloadUrl } from '../../../utils/download';
import slugify from 'slugify';
import { setUserLog } from '../../../services/userLog';
import { Loader } from '@react-three/drei';
import { push } from 'connected-react-router'
import { hasActiveSubscription, setNoSubscriptionWithRemainingDownloads } from '../../../services/payment'
import { getAuth } from '@addsome/redux-store/dist/store/auth'
import { auth } from '@addsome/redux-store'
import { hasDownloadedProduct, createDownloadedProduct } from '../../../services/productDownload'
import Routes from '../../../utils/routes';

type IProps = ReturnType<typeof mapStateToProps> &
  WrappedComponentProps &
  ReturnType<typeof mapDispatchToProps> & {
    product: ProductRelationshipsExtendedDTO;
    readonly?: boolean;
  };

interface IState {
  newFile: { media: MediaDTO; name: string; type: TypeImport; } | null;
  showQuotaPopup: boolean;
  hasReachedLastQuota: boolean,
  key: string;
  totalDownloads: number;
  updatedSubscriptionData: Date | null;
  selectedRowKeys: React.Key[];
}

class ProductFiles extends React.Component<IProps, IState> {
  public state: IState = {
    newFile: null,
    showQuotaPopup: false,
    hasReachedLastQuota: false,
    key: 'initial',
    totalDownloads: 0,
    updatedSubscriptionData: null,
    selectedRowKeys: []
  };

  componentDidMount() {
    const {
      user
    } = this.props;

    if (this.props.accountType === AccountType.Architect && user.subscription) {
      hasActiveSubscription(user.account.email).then((activeSubscription) => {
        if (!activeSubscription && user.subscription!.slug != ArchitectSubscriptionType.Standard10) {
          setNoSubscriptionWithRemainingDownloads(user.id).then((res) => {
            getAuth()
          })
        }
      });
    }

    if (user.downloads)
      this.setState({ totalDownloads: user.downloads });

  }
  public render() {
    const { product, readonly, token, connectedUser } = this.props;
    const { newFile, showQuotaPopup, hasReachedLastQuota, selectedRowKeys } = this.state;
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      this.setState({ selectedRowKeys: newSelectedRowKeys });
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const handleDeleteSelectedFiles = async () => {
      const isCAO = true;
      const { selectedRowKeys } = this.state;
      const { product } = this.props;
      const { documents, sourceFiles } = this.props.product;
      const mediaIds: string[] = [];
      for (const fileId of selectedRowKeys) {
        const foundProductSourceFile = product.sourceFiles.find(file => file.id === fileId);
        const mediaId = foundProductSourceFile.media.id || null;
        if (mediaId) {
          mediaIds.push(mediaId);
        }
      }
      if (mediaIds.length > 0) {
        deleteMultipleMedia(mediaIds as string[]).then(() => {
          this.props.patchProduct(this.props.product.id, {
            documents: (isCAO ? documents : documents.filter(pdf => !selectedRowKeys.includes(pdf.id))).map(
              convertDocumentFileForCreation
            ),
            sourceFiles: (!isCAO ? sourceFiles : sourceFiles.filter(sf => !selectedRowKeys.includes(sf.id))).map(
              convertSourceFileForCreation
            )
          });
        }).catch((err) => {
          //TODO create a notif
        });
      }

      // After all files are deleted, reset the selectedRowKeys
      this.setState({ selectedRowKeys: [] });
    };


    const canDelete =
      this.props.accountType === AccountType.AddsomeUser ||
      (this.props.accountType === AccountType.BrandUser &&
        (this.props.connectedUser as BrandUserDTO).role === BrandUserRole.Admin);

    const canEdit =
      this.props.accountType === AccountType.AddsomeUser ||
      (this.props.accountType === AccountType.BrandUser &&
        (this.props.connectedUser as BrandUserDTO).role === BrandUserRole.Admin);

    let allFilesNames;
    if (localStorage.allFilesNames)
      allFilesNames = JSON.parse(localStorage.allFilesNames);

    let allFilesExtentions;
    if (localStorage.allFilesExtentions)
      allFilesExtentions = JSON.parse(localStorage.allFilesExtentions);

    let allFilesTypes;
    if (localStorage.allFilesTypes)
      allFilesTypes = JSON.parse(localStorage.allFilesTypes);

    let allFilesSize;
    if (localStorage.allFilesSize)
      allFilesSize = JSON.parse(localStorage.allFilesSize);

    return (
      <div className={styles.productFiles}>
        <Modal
          visible={showQuotaPopup}
          footer={null}
          centered
          onCancel={() => this.setState({ showQuotaPopup: false })}
        >
          <Heading level={3} fill centerText>
            <FormattedMessage
              id="architectnotlinkedpopup.title"
              values={{
                name: (
                  <span className={styles.name}>
                    {connectedUser!.account.firstName} {connectedUser!.account.lastName}
                  </span>
                )
              }}
            />
          </Heading>
          <p>
            <FormattedMessage id="productpage.files.modal.text" />
          </p>
          <Button
            theme={Theme.PRIMARY}
            onClick={() => this.props.pushRouter(`${Routes.Architects}/${connectedUser!.id}/edit/subscription`)}
            block>
            <FormattedMessage
              id={
                hasReachedLastQuota
                  ? 'productpage.files.modal.buttonQuotaMaxReached'
                  : 'productpage.files.modal.buttonQuotaReached'
              }
            />
          </Button>
        </Modal>
        <>
          <div className={styles.add}>
            <UploadMedia
              type={UploadType.CIRCLE}
              action={mediaUploadUrl}
              afterUpload={this.importFile}
              token={token}
              key={this.state.key}
              description={this.props.intl.formatMessage({ id: 'productpage.errorCreating' })}
            >
              <Icon type="plus" aria-label="Ajouter" />
            </UploadMedia>
          </div>
          <Modal
            visible={newFile !== null}
            title={<FormattedMessage id={`productpage.files.${allFilesNames ? 'imports' : 'import'}`} />}
            footer={null}
            onCancel={() => this.setState({ newFile: null, key: 'cancel' + newFile!.name })}
          >
            {newFile && (
              <ImportFile
                media={newFile.media}
                fileName={newFile.name}
                type={newFile.type}
                onImport={this.addFile}
                allFilesNames={allFilesNames}
                allFilesExtentions={allFilesExtentions}
                allFilesTypes={allFilesTypes}
                allFilesSize={allFilesSize}
              />
            )}
          </Modal>
        </>
        <div className={styles.section}>
          <Heading level={3} as="h2">
            <FormattedMessage id="productpage.files.docs" />
          </Heading>
          <EditableTable
            columns={this.getColumns<ProductDocumentDTO>(ProductFileType.Document)}
            dataSource={product.documents}
            rowKey="id"
            readonly={readonly}
            onEdit={canEdit ? this.editPDF : undefined}
            showHeader={false}
            onDelete={canDelete ? file => this.deleteFile(file.id, false, file.media.id) : undefined}
            intl={this.props.intl}
          />
        </div>

        <div className={styles.section}>
          <Heading level={3} as="h2">
            <FormattedMessage id="productpage.files.cao" />
            <div style={{ marginTop: 10 }}>
              <Popconfirm
                title={<FormattedMessage id="global.confirmdelete" />}
                onConfirm={handleDeleteSelectedFiles}
                okText={<FormattedMessage id="global.yes" />}
                cancelText={<FormattedMessage id="global.no" />}
                placement="left"
              >
                <Button>Delete selected files</Button>
              </Popconfirm>
            </div>

          </Heading>
          <EditableTable
            columns={this.getColumns<ProductSourceFileDTO>(ProductFileType.SourceFile)}
            dataSource={product.sourceFiles}
            rowSelection={rowSelection}
            rowKey="id"
            readonly={readonly}
            onEdit={canEdit ? this.editSourceFile : undefined}
            showHeader={false}
            onDelete={canDelete ? file => this.deleteFile(file.id, true, file.media.id) : undefined}
            intl={this.props.intl}
          />
        </div>
      </div>
    );
  }

  private importFile = (media: MediaDTO, name: string) => {
    const type = getFileTypeByName(media.url);
    const isDocument = [FileType.document, FileType.text, FileType.archive].includes(type);
    const isPicture = [FileType.image, FileType.vectorial].includes(type);

    this.setState({
      newFile: {
        media,
        name,
        type: isDocument ? TypeImport.information : isPicture ? TypeImport.picture : TypeImport.cao
      }
    });
  };

  private addFile = (name: string) => {
    const { newFile } = this.state;
    let documents = JSON.parse(localStorage.documents);
    let sourceFiles = JSON.parse(localStorage.sourceFiles);
    let pictures = JSON.parse(localStorage.importPictures);
    const connectedUser = this.props.connectedUser;

    if (newFile) {
      this.props.patchProduct(this.props.product.id, {
        sourceFiles: [
          ...(this.props.product.sourceFiles || []).map(convertSourceFileForCreation),
          // ...(file.type === TypeImport.cao
          //   ? 
          //   [
          //       {
          //         name: file.name,
          //         mediaId: file.mediaId
          //       }
          //     ]
          //   : [])
          ...sourceFiles
        ],
        documents: [
          ...(this.props.product.documents || []).map(convertDocumentFileForCreation),
          // ...(file.type === TypeImport.information
          //   ? [
          //       {
          //         name: file.name,
          //         mediaId: file.mediaId,
          //         enabled: true
          //       }
          //     ]
          //   : [])
          ...documents
        ],
        pictures: [
          ...(this.props.product.pictures || []).map(convertPictureForCreation),
          // ...(file.type === TypeImport.picture
          //   ? [
          //       {
          //         name: file.name,
          //         mediaId: file.mediaId,
          //         enabled: true
          //       }
          //     ]
          //   : [])
          ...pictures
        ]
      });

      setUserLog(connectedUser!.account.id, this.props.product.id, 'media uploaded', newFile.media.id);

      this.setState({
        newFile: null,
        key: 'uploaded' + newFile.media.id
      });
    }
  };

  private getColumns<T extends ProductSourceFileDTO | ProductDocumentDTO>(
    productFileType: ProductFileType
  ): Array<IColumnPropsEditable<T>> {
    return [
      {
        title: '',
        dataIndex: 'media',
        editable: false,
        render: (_, file) => (
          <img
            src={this.getTypeIcon(getFileType(file.extension))}
            aria-hidden
            className={styles.fileIcon}
            alt="File icon"
          />
        ),
        width: '5%'
      },
      {
        title: 'Modèle',
        dataIndex: 'name',
        editable: true,
        width: '50%',
        render: value => <span>{value}</span>
      },
      {
        title: 'Type',
        dataIndex: 'extension',
        editable: false,
        width: '15%',
        render: (value: string) => value.toUpperCase()
      },
      {
        title: 'Taille',
        dataIndex: 'media.size',
        editable: false,
        width: '15%',
        render: (value: number) => `${(value / Math.pow(1024, 2)).toFixed(2)} MB`
      },
      {
        title: 'Téléchargement',
        dataIndex: 'id',
        editable: false,
        width: '5%',
        // data attributes are used to show the progress bar on download without having to rerender the component
        render: (_, file) => {
          if (this.props.user.type === "architect" && !this.props.user.subscription)
            return;
          return (
            <div data-fileId={file.id} className={styles.downloadCart}>
              <span
                className={styles.download}
                onClick={() => this.handleDownloadFile(file, productFileType)}
              >
                <Icon type="download" />
              </span>
              <span data-progressBarContent className={styles.progressBarContent}>
                <Icon className={styles.loading} type="loading" />
              </span>
            </div>
          );
        }
      }
    ];
  };

  // Show a popup if the user is an architect and his account wasn't validated
  // otherwise download the file
  private handleDownloadFile = async (
    file: ProductSourceFileDTO | ProductDocumentDTO,
    fileType: ProductFileType
  ) => {
    const {
      connectedUser,
      product,
      accountType,
      showNotEnabledModal,
      showNotLinkedModal
    } = this.props;

    if (connectedUser && accountType === AccountType.Architect) {
      const architect = connectedUser as ArchitectWithBrandDTO;

      if (!architect.account.enabled) {
        showNotEnabledModal();
        return;
      }

      if (!architect.brands || !architect.brands.some(brand => brand.id === product.brand.id)) {
        showNotLinkedModal(product.brand);
        return;
      }
    }

    try {

      if (connectedUser && accountType === AccountType.Architect) {
        const progressBarContent = document.querySelector(
          `[data-fileId="${file.id}"] [data-progressBarContent]`
        ) as HTMLElement;
        progressBarContent.style.opacity = '1';
        // We add a timestamp to prevent caching on the browser side
        const blob: { data: BlobPart; } = await axiosInstance.get(
          `/product-file/${file.id}?ts=${Date.now()}`,
          {
            params: {
              fileType
            },
            responseType: 'blob'
          }
        );

        progressBarContent.style.opacity = '0';

        Mixpanel.trackProductDownloaded({
          brandId: this.props.product.brand.id,
          brandName: this.props.product.brand.name,
          productId: this.props.product.id,
          productName: this.props.product.name,
          mediaId: file.media.id
        });

        const hasExt = (file.name || product.name).toLowerCase().includes(file.extension.toLowerCase());
        const filename = `${slugify(product.brand.name, { lower: true })}_${slugify(
          file.name || product.name,
          { lower: true }
        )}${hasExt ? '' : '.' + file.extension}`;
        downloadBlob(blob.data, filename);
      } else if (connectedUser && accountType === AccountType.AddsomeUser) {
        if (file.media.url) {
          downloadUrl(file.media.url, file.name || '')
        }
      }

      if (accountType == AccountType.Architect) {
        const hasDownloaded = await hasDownloadedProduct(this.props.user.id, this.props.product.id)
        if (!hasDownloaded) {
          const downloads = this.state.totalDownloads + 1;
          this.setState({ totalDownloads: downloads });
          createDownloadedProduct(this.props.user.id, this.props.product.id).then(() => {
            this.props.getAuth()
          });

        }
      }

    } catch (e) {
      try {
        const errorMessage = JSON.parse(await e.data.text()).message;

        if (
          errorMessage === ErrorsCode.Quota_Reached ||
          errorMessage === ErrorsCode.Quota_Max_Reached ||
          errorMessage === ErrorsCode.Quota_Max_Exceeded
        ) {
          const progressBarContent = document.querySelector(
            `[data-fileId="${file.id}"] [data-progressBarContent]`
          ) as HTMLElement;
          progressBarContent.style.opacity = '0';
          this.setState({
            showQuotaPopup: true,
            hasReachedLastQuota: errorMessage === ErrorsCode.Quota_Max_Reached
          });
        }
        // Errors without blob as a response (API down)
      } catch (_) { }
    }
  };

  private getTypeIcon = (type: FileType) => {
    if ([FileType.model3D, FileType.model3DSettings].includes(type)) {
      return donnees3DImg;
    } else if ([FileType.image, FileType.vectorial].includes(type)) {
      return donnees2DImg;
    }
    return donnees2DImg;
  };

  private editSourceFile = (file: ProductSourceFileDTO) => {
    this.props.patchProduct(this.props.product.id, {
      sourceFiles: this.props.product.sourceFiles.map(sf =>
        convertSourceFileForCreation(sf.id === file.id ? file : sf)
      )
    });
  };

  private editPDF = (file: ProductDocumentDTO) => {
    this.props.patchProduct(this.props.product.id, {
      documents: this.props.product.documents.map(pdf =>
        convertDocumentFileForCreation(pdf.id === file.id ? file : pdf)
      )
    });
  };

  private deleteFile = (fileId: string, isCAO: boolean, mediaId: string) => {
    const { documents, sourceFiles } = this.props.product;
    const connectedUser = this.props.connectedUser;
    const document = documents.find(x => x.id == fileId) || sourceFiles.find(x => x.id == fileId);
    setUserLog(connectedUser!.account.id, this.props.product.id, `media deleted: ${document!.media.name}`, mediaId);
    deleteMedia(mediaId);
    this.props.patchProduct(this.props.product.id, {
      documents: (isCAO ? documents : documents.filter(pdf => pdf.id !== fileId)).map(
        convertDocumentFileForCreation
      ),
      sourceFiles: (!isCAO ? sourceFiles : sourceFiles.filter(sf => sf.id !== fileId)).map(
        convertSourceFileForCreation
      )
    });
  };
}

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user as ArchitectDTO,
  token: state.authState.token,
  accountType: state.authState.type,
  connectedUser: state.userState.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  patchProduct: (id: string, payload: ProductUpdateDTO) =>
    dispatch(productActions.thunks.patchValue(id, payload)),
  showNotEnabledModal: () => dispatch(setShouldShowArchitectNotEnabledDownloadPopup(true)),
  showNotLinkedModal: (brand: BrandLightDTO) =>
    dispatch(setShouldShowArchitectNotLinkedToBrandDownloadPopup(true, brand)),
  patchArchitectDownloads: (id: string, downloads, startDate) => {
    dispatch(
      architectActions.thunks.patchValue(id, {
        downloads: downloads,
        subscriptionStartDate: startDate
      })
    );
  },
  getAuth: () => dispatch(auth.getAuth()),
  patchArchitect: async (id: string, user: ArchitectDTO, payload: ArchitectUpdateDTO) => {
    const architect = await dispatch(architectActions.thunks.patchValue(id, payload));
    // Only update the subscription of the current user, we don't want to delete brands for instance
    // because there are too many Architect DTOs (e.g: ArchitectWithBrandDTO)
    dispatch(
      setUser({
        ...user,
        subscription: architect.subscription
      })
    );
  }
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductFiles)
);
