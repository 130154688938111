import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styles from './CheckoutForm.module.scss';
import { Modal, Button, Theme } from '@addsome/ui-kit'
import { PaymentIntentResult, SetupIntentResult } from '@stripe/stripe-js';
import { ArchitectCreationDTO, AccountDTO, ArchitectDTO } from '@addsome/dtos'
import { setPayment } from '../../services/subscription';
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel';
import secureBadge from '../../assets/images/stripe-badge.png'


interface IProps {
  user: ArchitectCreationDTO | ArchitectDTO,
  account: AccountDTO | null,
  onStripeCancel: () => void,
  upgrade?: string
  register?: boolean
}

const CheckoutForm: React.FC<IProps> = ({ user, account, onStripeCancel, upgrade, register }) => {

  const intl = useIntl()

  const stripe = useStripe();
  const elements = useElements();


  const [message, setMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isVisible, setisVisible] = useState(true);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntentResult | null>(null);
  const [setupIntent, setSetupIntent] = useState<SetupIntentResult | null>(null);

  useEffect(() => {
    stripePaymentIntent()
  }, [stripe, elements])

  useEffect(() => {
    if (account) {
      Mixpanel.alias(account.email);
    }
    Mixpanel.track(MixpanelEvents.PaymentPageViewed);
  }, [])

  const stripePaymentIntent = async () => {
    if (stripe && elements) {
      //if the subscription starts with Trial period then we have a Setup Intent(seti) not a Payment Intent(pi)
      if (elements._commonOptions.clientSecret.clientSecret.includes('seti')) {
        setSetupIntent(await stripe.retrieveSetupIntent(elements._commonOptions.clientSecret.clientSecret))
      } else {
        setPaymentIntent(await stripe.retrievePaymentIntent(elements._commonOptions.clientSecret.clientSecret))
      }
    }
  }

  const onPaymentCancel = () => {
    setisVisible(false);
    setPayment({ accountId: account!.id, subscriptionType: user.subscriptionType, paymentStatus: 'cancel payment' })
    onStripeCancel();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const return_url = upgrade ? 'upgrade-payment-complete' : 'payment-complete'
    const subscriptionType = upgrade ? upgrade : user.subscriptionType

    const { error } = setupIntent !== null ? await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${return_url}/${account!.id}/${account!.email}/${subscriptionType}`,
      },
    }) : await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${return_url}/${account!.id}/${account!.email}/${subscriptionType}`,
      },
    })

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      setPayment({ accountId: account!.id, subscriptionType: subscriptionType, paymentStatus: `payment error: ${error.message}` })
    } else {
      setMessage(intl.formatMessage({ id: 'stripe.unexpectedError' }));
      setPayment({ accountId: account!.id, subscriptionType: subscriptionType, paymentStatus: 'payment error' })
    }

    setIsProcessing(false);
  };
  return (
    <Modal
      visible={isVisible}
      footer={null}
      centered
      maskClosable={false}
      onCancel={onPaymentCancel}
    >
      <form id="payment-form" onSubmit={handleSubmit} className={styles.paymentForm}>
        <PaymentElement id="payment-element" />
        <div className={styles.payButton}>
          <Button
            disabled={isProcessing || !stripe || !elements}
            htmlType="submit"
            theme={Theme.PRIMARY}
            uppercase
          >
            {setupIntent !== null ? intl.formatMessage({ id: 'stripe.startFreeTrial' }) :
              <>
                {isProcessing || !paymentIntent || !paymentIntent.paymentIntent ?
                  intl.formatMessage({ id: 'stripe.processing' })
                  : intl.formatMessage({ id: 'stripe.payNow' }) + ` ${paymentIntent.paymentIntent.amount / 100} ${paymentIntent.paymentIntent.currency}`}
              </>
            }
          </Button>
          {setupIntent !== null && register ?
            <>
              <span className={styles.skipLink} onClick={onPaymentCancel}>{intl.formatMessage({ id: 'stripe.skip' })}</span>
              <h6>{intl.formatMessage({ id: 'stripe.skipDescription' })}</h6>
            </>
            : <></>
          }
        </div>
        {message && <div id="payment-message" className={styles.paymentError}>{message}</div>}
        <div className={styles.secureBadge}>
          <img src={secureBadge}></img>
        </div>
      </form>
    </Modal>
  );
};

export default CheckoutForm;