import { BrandCreationDTO } from '@addsome/dtos'
import { Form } from 'antd'
import { Formik } from 'formik'
import React, { useMemo } from 'react'
import * as yup from 'yup'
import { IStepFormProps } from '../../MultiStepModal/MultiStepModal'
import MultiStepNavigation from '../../MultiStepModal/MultiStepNavigation/MultiStepNavigation'
import BrandInformationFieldsGroup from '../BrandInformationFieldsGroup'
import styles from './BrandCreation.module.scss'

type IProps = IStepFormProps<BrandCreationDTO>

const BrandInformationStep: React.FC<IProps> = ({ onPrevious, onNext, value, first, last }) => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required()
      }),
    []
  )

  return (
    <Formik
      initialValues={value}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!values) return
        setSubmitting(false)
        onNext(values)
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit} noValidate layout="horizontal">
          <div className={styles.formBody}>
            <BrandInformationFieldsGroup brand={value} formik={formik} />
          </div>
          <MultiStepNavigation
            showPrevious={!first}
            finish={last}
            nextDisabled={Object.values(formik.errors).length > 0}
            onPrevious={onPrevious}
            onNext="submit"
          />
        </Form>
      )}
    </Formik>
  )
}

export default BrandInformationStep
