"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoading = exports.setArchitectProjectSceneTemplates = void 0;

var model_1 = require("./model");

var setArchitectProjectSceneTemplates = function setArchitectProjectSceneTemplates(templates, total) {
  return {
    type: model_1.ActionsTypes.SET_ARCHITECT_PROJECT_SCENE_TEMPLATES,
    templates: templates,
    total: total
  };
};

exports.setArchitectProjectSceneTemplates = setArchitectProjectSceneTemplates;

var setLoading = function setLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_ARCHITECT_PROJECT_SCENE_TEMPLATE_LOADING,
    loading: loading
  };
};

exports.setLoading = setLoading;