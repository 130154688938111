"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCollectionAction = exports.setCollectionsAction = void 0;

var models_1 = require("./models");

var setCollectionsAction = function setCollectionsAction(collections) {
  return {
    type: models_1.ActionsTypes.SET_COLLECTIONS,
    collections: collections
  };
};

exports.setCollectionsAction = setCollectionsAction;

var updateCollectionAction = function updateCollectionAction(collection) {
  return {
    type: models_1.ActionsTypes.UPDATE_COLLECTION,
    collection: collection
  };
};

exports.updateCollectionAction = updateCollectionAction;