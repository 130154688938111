const presetVariations =
  [
    {
      "id": "0",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_oak",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_oak",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_oak_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_oak_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.5,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "1",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_walnut",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_walnut",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_walnut_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_walnut_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.5,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "2",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_dark",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_dark",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_dark_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_dark_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.5,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "3",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_light",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_light",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_light_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_light_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.5,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "4",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_color",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_color",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_color_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.5,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "5",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "metal_brass_matte",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "brass / metal / metal_brass_matte",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.55",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "6",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "metal_brass_satin",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "brass / metal / metal_brass_satin",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 1,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 1,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "7",
      "presetGroup": "fabric",
      "presetName": "fabric_velvet",
      "presetVariation": "fabric_velvet",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_color / fabric / fabric_velvet",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFEDD5",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "fabric_velvet_diff",
      "_DiffuseMapStrength": "1.3",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "0.8",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0,
      "_NormalMap": "fabric_velvet_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.4,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "17",
      "presetGroup": "plastic",
      "presetName": "clean",
      "presetVariation": "black_glossy",
      "shaderName": "Standard (Roughness setup)",
      "name": "clean / plastic / black_glossy",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.9,
      "_SmoothnessContrast": "",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0.1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "",
      "_TilingCoef": "",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "",
      "_UseRGBMasks": "",
      "_UseTriPlanar": ""
    },
    {
      "id": "18",
      "presetGroup": "plastic",
      "presetName": "clean",
      "presetVariation": "white_matte",
      "shaderName": "Standard (Roughness setup)",
      "name": "clean / plastic / white_matte",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.4,
      "_SmoothnessContrast": "",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "",
      "_TilingCoef": "",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "",
      "_UseRGBMasks": "",
      "_UseTriPlanar": ""
    },
    {
      "id": "19",
      "presetGroup": "leather",
      "presetName": "leather_grain",
      "presetVariation": "leather_grain",
      "shaderName": "Addsome/LEGACY/legacy_leather",
      "name": "leather_grain / leather / leather_grain_white",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "0.5",
      "_DetailTilingCoef": "{\"x\":0.5,\"y\":0.5,\"z\":0.5}",
      "_DiffuseAddColor": "#000000",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#7A6B53",
      "_DiffuseContrast": "",
      "_DiffuseMap": "leather_grain_diff",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "1",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "leather_grain_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": "leather_grain_rough",
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.84,\"y\":0.84}",
      "_TilingCoef": "2",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":2,\"y\":2,\"z\":2}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "20",
      "presetGroup": "leather",
      "presetName": "leather_fine",
      "presetVariation": "leather_fine",
      "shaderName": "Addsome/LEGACY/legacy_leather",
      "name": "leather_fine / leather / leather_fine",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "0.15",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "#000000",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#8F816C",
      "_DiffuseContrast": "",
      "_DiffuseMap": "leather_fine_diff",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "1",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "leather_fine_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": "leather_fine_rough",
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.84,\"y\":0.84}",
      "_TilingCoef": "2",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":2,\"y\":2,\"z\":2}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "21",
      "presetGroup": "transparent",
      "presetName": "glass",
      "presetVariation": "transparent_glass_colored",
      "shaderName": "Addsome/LEGACY/legacy_glass",
      "name": "glass / transparent / transparent_glass_colored",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#000000",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "1",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.5,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 0.08,
      "_Opacity": 0.3,
      "_Smoothness": 1,
      "_SmoothnessContrast": "",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#000000",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "",
      "_TilingCoef": "",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "22",
      "presetGroup": "emissive",
      "presetName": "homogeneous",
      "presetVariation": "emissive",
      "shaderName": "Addsome/LEGACY/legacy_emissive",
      "name": "homogeneous / emissive / emissive",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "#FFFFFF",
      "_EmissionSaturation": "",
      "_EmissionStrength": 2,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": null,
      "_SmoothnessContrast": "",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0.08,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "",
      "_TilingCoef": "",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "23",
      "presetGroup": "fabric",
      "presetName": "fabric_color",
      "presetVariation": "fabric_color",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_color / fabric / fabric_color",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFEDD5",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "fabric_color_diff",
      "_DiffuseMapStrength": "1.3",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "0.8",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0,
      "_NormalMap": "fabric_color_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "fabric_color_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "24",
      "presetGroup": "fabric",
      "presetName": "fabric_greydots",
      "presetVariation": "fabric_greydots",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_greydots / fabric / fabric_greydots",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFFFFF",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "fabric_greydots_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "1",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0,
      "_NormalMap": "fabric_greydots_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "fabric_greydots_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "25",
      "presetGroup": "fabric",
      "presetName": "fabric_lines",
      "presetVariation": "fabric_lines",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_lines / fabric / fabric_lines",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#C4C4C4",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "fabric_lines_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "1",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0,
      "_NormalMap": "fabric_lines_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "fabric_lines_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "26",
      "presetGroup": "marble",
      "presetName": "marble_black",
      "presetVariation": "marble_black",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "marble / marble_black / marble_black",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "marble_black_diff",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "marble_black_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.1,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "marble_black_rough",
      "_SmoothnessMapStrength": 0.6,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "27",
      "presetGroup": "marble",
      "presetName": "marble_grey",
      "presetVariation": "marble_grey",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "marble / marble_grey / marble_grey",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "marble_grey_diff",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "marble_grey_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.1,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "marble_grey_rough",
      "_SmoothnessMapStrength": 0.6,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "28",
      "presetGroup": "marble",
      "presetName": "marble_grey02",
      "presetVariation": "marble_grey02",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "marble / marble_grey02 / marble_grey02",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "marble_grey02_diff",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "marble_grey02_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.1,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "marble_grey02_rough",
      "_SmoothnessMapStrength": 0.6,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "29",
      "presetGroup": "marble",
      "presetName": "marble_multi",
      "presetVariation": "marble_multi",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "marble / marble_multi / marble_multi",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "marble_multi_diff",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "marble_multi_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.1,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "marble_multi_rough",
      "_SmoothnessMapStrength": 0.6,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "30",
      "presetGroup": "marble",
      "presetName": "marble_white",
      "presetVariation": "marble_white",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "marble / marble_white / marble_white",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "marble_white_diff",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": null,
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.1,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "marble_white_rough",
      "_SmoothnessMapStrength": 0.5,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "31",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_oak_glossy",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_oak_glossy",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_oak_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_oak_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.7,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "32",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_walnut_glossy",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_walnut_glossy",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_walnut_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_walnut_nrml",
      "_NormalMapStrength": 0.05,
      "_Opacity": null,
      "_Smoothness": 0.7,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "33",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_dark_glossy",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_dark_glossy",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_dark_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_dark_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.7,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "34",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_light_glossy",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_light_glossy",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_light_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_light_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.7,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "35",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_color_glossy",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_color_glossy",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_color_nrml",
      "_NormalMapStrength": 0.08,
      "_Opacity": null,
      "_Smoothness": 0.7,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "36",
      "presetGroup": "transparent",
      "presetName": "glass",
      "presetVariation": "transparent_glass_painted",
      "shaderName": "Addsome/LEGACY/legacy_glass",
      "name": "glass / transparent / transparent_glass_painted",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#000000",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "1",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.2,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 0.08,
      "_Opacity": 0.7,
      "_Smoothness": 0.9,
      "_SmoothnessContrast": "",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#000000",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "",
      "_TilingCoef": "",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "37",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "mirror",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "mirror",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#828282",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 1,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 1,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "38",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "gold_satin",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "brass / gold / gold_satin",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFD700",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 1,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 1,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "39",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "gold_matte",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "brass / gold / gold_matte",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFD700",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "40",
      "presetGroup": "metal",
      "presetName": "metal",
      "presetVariation": "silver_satin",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "metal / silver / silver_satin",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#C0C0C0",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 1,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.9,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "41",
      "presetGroup": "metal",
      "presetName": "metal",
      "presetVariation": "silver_matte",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "metal / silver / silver_matte",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#C0C0C0",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "42",
      "presetGroup": "metal",
      "presetName": "metal",
      "presetVariation": "chrome_satin",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "metal / chrome / chrome_satin",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#C7C4B9",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 1,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.9,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "43",
      "presetGroup": "stone",
      "presetName": "stone",
      "presetVariation": "concrete",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "stone / stone / concrete",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "concrete_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": 'concrete_nrml',
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": 'concrete_rough',
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "44",
      "presetGroup": "metal",
      "presetName": "metal",
      "presetVariation": "bronze_matte",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "metal / bronze / bronze_matte",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#CC6633",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "45",
      "presetGroup": "metal",
      "presetName": "metal",
      "presetVariation": "chrome_matte",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "metal / chrome / chrome_matte",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#C7C4B9",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "metal_normal.png",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.75",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "46",
      "presetGroup": "fabric",
      "presetName": "fabric_velvet_soft",
      "presetVariation": "fabric_velvet_soft",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_color / fabric / fabric_velvet_soft",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFEDD5",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "valvet_soft_diff",
      "_DiffuseMapStrength": "1.3",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "0.8",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0.3,
      "_NormalMap": "valvet_soft_nrml",
      "_NormalMapStrength": 0.6,
      "_Opacity": null,
      "_Smoothness": 0.3,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "valvet_soft_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "47",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "metal_brass_matte_chainlink",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "brass / metal / metal_brass_matte_chainlink",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "chainlink_diff",
      "_DetailNormalMapStrength": 1,
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "chainlink_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": 2,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "chainlink_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.55",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "48",
      "presetGroup": "stone",
      "presetName": "stone_basic",
      "presetVariation": "stone_basic",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "stone / stone / stone_basic",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "stone_basic_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": 'stone_basic_nrml',
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "49",
      "presetGroup": "wood",
      "presetName": "wood_wicker",
      "presetVariation": "wood_wicker",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_wicker",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wicker_1_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wicker_1_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.5,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": null,
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "50",
      "presetGroup": "wood",
      "presetName": "wood_wicker_second",
      "presetVariation": "wood_wicker_second",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_wicker_second",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wiker_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wiker_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.01,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": "wiker_rough",
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "51",
      "presetGroup": "stone",
      "presetName": "ceramic",
      "presetVariation": "ceramic",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "stone / stone / ceramic",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "ceramic_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": 'ceramic_nrml',
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": 'ceramic_rough',
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "52",
      "presetGroup": "stone",
      "presetName": "terracotta",
      "presetVariation": "terracotta",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "stone / stone / terracotta",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "terracott_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": 'terracott_nrml',
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": '',
      "_SmoothnessMapStrength": null,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "53",
      "presetGroup": "fabric",
      "presetName": "fabric_sheer",
      "presetVariation": "fabric_sheer",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "fabric_color / fabric / fabric_sheer",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "fabric_natural",
      "_DetailNormalMapStrength": 1,
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "fabric_natural_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": 2,
      "_Smoothness": 0,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.55",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "54",
      "presetGroup": "fabric",
      "presetName": "fabric_wool",
      "presetVariation": "fabric_wool",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_color / fabric / fabric_wool",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFEDD5",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "fabric_wools_diff",
      "_DiffuseMapStrength": "1.3",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "0.8",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0,
      "_NormalMap": "fabric_wools_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "fabric_wools_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "55",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "metal_scratch",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "brass / metal / metal_scratch",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "metal_scratch_diff",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "metal_scratch_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 0,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.55",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "56",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "metal_brass_matte_romb",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "brass / metal / metal_brass_matte_romb",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "plaster_metal_mesh_diff",
      "_DetailNormalMapStrength": 1,
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "plaster_metal_mesh_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": 2,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.55",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "57",
      "presetGroup": "metal",
      "presetName": "brass",
      "presetVariation": "metal_brass_matte_quare",
      "shaderName": "Addsome/LEGACY/legacy_metal",
      "name": "brass / metal / metal_brass_matte_quare",
      "_CoordinatesOffset": "",
      "_CoordinatesRotation": "",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "mesh square_diff",
      "_DetailNormalMapStrength": 1,
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "",
      "_DiffuseContrast": "",
      "_DiffuseMap": "",
      "_DiffuseMapStrength": "",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0.9,
      "_NormalMap": "mesh_square_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": 2,
      "_Smoothness": 0.6,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "#FFE9A6",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "0.55",
      "_Tiling": "{\"x\":1,\"y\":1}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1,\"y\":1,\"z\":1}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "58",
      "presetGroup": "fabric",
      "presetName": "fabric_sheep",
      "presetVariation": "fabric_sheep",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_color / fabric / fabric_sheep",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFEDD5",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "fabric_alcantara_diff",
      "_DiffuseMapStrength": "1.3",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "0.8",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0,
      "_NormalMap": "fabric_alcantara_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "fabric_alcantara_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "59",
      "presetGroup": "fabric",
      "presetName": "fabric_knited",
      "presetVariation": "fabric_knited",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_color / fabric / fabric_knited",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFEDD5",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "fabric_knited_diff",
      "_DiffuseMapStrength": "1.3",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "0.8",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0,
      "_NormalMap": "fabric_knited_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "fabric_knited_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "60",
      "presetGroup": "fabric",
      "presetName": "fabric_carou",
      "presetVariation": "fabric_carou",
      "shaderName": "Addsome/LEGACY/legacy_fabric",
      "name": "fabric_color / fabric / fabric_carou",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "{\"x\":1,\"y\":1,\"z\":1}",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "",
      "_DiffuseColor": "#FFEDD5",
      "_DiffuseContrast": "0.7",
      "_DiffuseMap": "fabric_carou_diff",
      "_DiffuseMapStrength": "1.3",
      "_DiffuseMultValue": "",
      "_DiffuseSaturation": "0.8",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "0",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": null,
      "_FresnelStrength": "",
      "_InvertDiffuse": "",
      "_MaskColor0": "#000000",
      "_MaskColor1": "#000000",
      "_MaskColor2": "#000000",
      "_MaskColor3": "#000000",
      "_Metalness": 0,
      "_NormalMap": "fabric_alcantara_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": "1",
      "_SmoothnessMap": "fabric_carou_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":1.26,\"y\":1.26}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":3,\"y\":3,\"z\":3}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "t"
    },
    {
      "id": "61",
      "presetGroup": "stone",
      "presetName": "concrete_super",
      "presetVariation": "concrete_super",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "stone / stone / concrete_super",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "concrete_super_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": 'concrete_super_nrml',
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": 'concrete_super_rough',
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    },
    {
      "id": "62",
      "presetGroup": "wood",
      "presetName": "ash",
      "presetVariation": "wood_light_color",
      "shaderName": "Addsome/LEGACY/legacy_wood",
      "name": "ash / wood / wood_light_color",
      "_CoordinatesOffset": "{\"x\":0,\"y\":0,\"z\":0}",
      "_CoordinatesRotation": "0",
      "_DetailCoordinatesOffset": "",
      "_DetailNormalMap": "",
      "_DetailNormalMapStrength": "",
      "_DetailTilingCoef": "",
      "_DiffuseAddColor": "",
      "_DiffuseAddValue": "0",
      "_DiffuseColor": "#808080",
      "_DiffuseContrast": "2.2",
      "_DiffuseMap": "wood_light_color_diff",
      "_DiffuseMapStrength": "1",
      "_DiffuseMultValue": "0",
      "_DiffuseSaturation": "0.52",
      "_DiffuseTemperatureOffset": "",
      "_DiffuseTintOffset": "-0.002",
      "_EmissionColor": "",
      "_EmissionSaturation": "",
      "_EmissionStrength": 0,
      "_FresnelStrength": "",
      "_InvertDiffuse": "0",
      "_MaskColor0": "",
      "_MaskColor1": "",
      "_MaskColor2": "",
      "_MaskColor3": "",
      "_Metalness": 0,
      "_NormalMap": "wood_light_color_nrml",
      "_NormalMapStrength": 1,
      "_Opacity": null,
      "_Smoothness": 0.5,
      "_SmoothnessContrast": null,
      "_SmoothnessMap": "wood_light_color_rough",
      "_SmoothnessMapStrength": 1,
      "_SpecularColor": "",
      "_SpecularContrast": "",
      "_SpecularMapStrength": "",
      "_SpecularSaturation": "",
      "_SpecularStrength": "",
      "_Tiling": "{\"x\":0.79,\"y\":0.79}",
      "_TilingCoef": "1",
      "_TriPlanarBlend": "",
      "_TriPlanarTiling": "{\"x\":1.875,\"y\":1.875,\"z\":1.875}",
      "_UseRGBMasks": "f",
      "_UseTriPlanar": "f"
    }
  ];

export default presetVariations;