"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _toConsumableArray = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/toConsumableArray");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  renders: [],
  render: null,
  loading: false,
  total: 0
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_RENDERS, function (state, _ref) {
  var renders = _ref.renders,
      total = _ref.total;
  return Object.assign(Object.assign({}, state), {
    renders: renders,
    total: total
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.ADD_RENDERS, function (state, _ref2) {
  var renders = _ref2.renders,
      total = _ref2.total;
  return Object.assign(Object.assign({}, state), {
    renders: [].concat(_toConsumableArray(state.renders), _toConsumableArray(renders)),
    total: total
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_RENDER_LOADING, function (state, _ref3) {
  var loading = _ref3.loading;
  return Object.assign(Object.assign({}, state), {
    loading: loading
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UPDATE_RENDER, function (state, _ref4) {
  var render = _ref4.render;
  return Object.assign(Object.assign({}, state), {
    render: render
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_RENDER, function (state, _ref5) {
  var render = _ref5.render;
  return Object.assign(Object.assign({}, state), {
    render: render
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UNSET_RENDER, function (state) {
  return Object.assign(Object.assign({}, state), {
    render: null
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.REMOVE_RENDER, function (state, _ref6) {
  var id = _ref6.id;
  return Object.assign(Object.assign({}, state), {
    renders: state.renders.filter(function (render) {
      return render.id !== id;
    })
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);