import React, { FC } from 'react'
import styles from './FoldersList.module.scss'
import FolderItem from '../FolderItem'

interface IFolder {
  name: string
  path: string
}

interface IProps {
  folders: IFolder[]
  onDeleteFolder: (path: string) => void
  onClickFolder: (path: string) => void
}

const FoldersList: FC<IProps> = ({ folders, onDeleteFolder, onClickFolder }) => (
  <div className={styles.container}>
    {folders.map(f => (
      <FolderItem
        key={f.path}
        label={f.name}
        path={f.path}
        onDelete={onDeleteFolder}
        onClick={onClickFolder}
      />
    ))}
  </div>
)

export default FoldersList
