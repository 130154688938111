import {
  ObjectProductCategoryDTO,
  ObjectProductType,
  ProductCreationDTO,
  ProductMaterialTagDTO,
  ProductUpdateDTO,
  RoomTagDTO,
  StyleTagDTO
} from '@addsome/dtos'
import { InputField, Option, Select } from '@addsome/ui-kit'
import { Form } from 'antd'
import { FormikProps } from 'formik'
import get from 'lodash.get'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../redux'
import useSearch from '../../../hooks/searchHook'
import TagsAutoComplete from '../../TagsAutoComplete'
import styles from './ProductTagsFieldsGroup.module.scss'
import { injectIntl, WrappedComponentProps } from 'react-intl'


type IProps = ReturnType<typeof mapStateToProps> &
  WrappedComponentProps & {
    product: ProductUpdateDTO | ProductCreationDTO
    formik: FormikProps<ProductUpdateDTO | ProductCreationDTO>
  }

const ProductTagsFieldsGroup: React.FC<IProps> = ({
  product,
  formik,
  objectProductCategories,
  styleTags,
  roomTags,
  productMaterialTags,
  intl
}) => {
  const [suggestedStyleTags, searchStyleTag] = useSearch<StyleTagDTO>()
  const [suggestedRoomTags, searchRoomTag] = useSearch<RoomTagDTO>()
  const [suggestedMaterialTags, searchMaterialTag] = useSearch<ProductMaterialTagDTO>()
  const [suggestedCategories, searchCategory] = useSearch<ObjectProductCategoryDTO>()
  const [categoriesChildren, setCategoriesChildren] = useState<ObjectProductCategoryDTO[]>([])
  

  useEffect(() => {
    const categoriesChilds = objectProductCategories.reduce(
      (children: ObjectProductCategoryDTO[], category) => [...children, ...category.childs],
      []
    )
    setCategoriesChildren(categoriesChilds)
  }, [objectProductCategories])

  return (
    <div className={styles.productTagsFieldsGroup}>
      {product.objectProduct && (
        <>
          <Form.Item
            label={intl.formatMessage({ id: 'tags.producttype' })}
            required={true}
            colon={false}
          >
            <InputField error={get(formik.errors, 'objectProduct.type')}>
              <Select
                defaultValue={product.objectProduct && product.objectProduct.type}
                onChange={value => formik.setFieldValue('objectProduct.type', value)}
              >
                <Option value={ObjectProductType.Furniture}>{intl.formatMessage({ id: 'global.furniture'})}</Option>
                <Option value={ObjectProductType.Lighting}>{intl.formatMessage({ id: 'global.lighting'})}</Option>
                <Option value={ObjectProductType.Accessories}>{intl.formatMessage({ id: 'global.accessories'})}</Option>
              </Select>
            </InputField>
          </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'tags.category' })} colon={false}>
        <TagsAutoComplete
          ids={product.objectProduct.categoryIds || []}
          source={categoriesChildren}
          getLabel={tag => tag.name}
          onSearch={v => searchCategory(v.toString(), categoriesChildren)}
          fields={suggestedCategories}
          onChange={value => formik.setFieldValue('objectProduct.categoryIds', value)}
        />
      </Form.Item>
      </>
      )}

      <Form.Item label={intl.formatMessage({ id: 'tags.rooms' })} colon={false}>
        <TagsAutoComplete
          ids={product.roomTagIds || []}
          source={roomTags}
          getLabel={tag => tag.name}
          onSearch={v => searchRoomTag(v.toString(), roomTags)}
          fields={suggestedRoomTags}
          onChange={value => formik.setFieldValue('roomTagIds', value)}
        />
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'tags.styles' })} colon={false}>
        <TagsAutoComplete
          ids={product.styleTagIds || []}
          source={styleTags}
          getLabel={tag => tag.name}
          onSearch={v => searchStyleTag(v.toString(), styleTags)}
          fields={suggestedStyleTags}
          onChange={value => formik.setFieldValue('styleTagIds', value)}
        />
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'tags.materials' })} colon={false}>
        <TagsAutoComplete
          ids={product.materialTagIds || []}
          source={productMaterialTags}
          getLabel={tag => tag.name}
          onSearch={v => searchMaterialTag(v.toString(), productMaterialTags)}
          fields={suggestedMaterialTags}
          onChange={value => formik.setFieldValue('materialTagIds', value)}
        />
      </Form.Item>

      
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  objectProductCategories: state.objectProductCategoriesState.values,
  styleTags: state.styleTagState.values,
  roomTags: state.roomTagState.values,
  productMaterialTags: state.productMaterialTagState.values
})

export default connect(mapStateToProps)(injectIntl(ProductTagsFieldsGroup))
