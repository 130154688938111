import { UploadStatus } from '@addsome/redux-store/dist/store/drive'
import { IntlShape } from 'react-intl'

export const capitalize = (value: string) =>
  value ? value.charAt(0).toUpperCase() + value.slice(1) : ''

export interface IMonth {
  label: string
  month: number
  year: number
}

export const getMonthsBetween = (startDate: Date, endDate: Date) => {
  const arr: IMonth[] = []
  for (const dt = new Date(startDate); dt <= endDate; dt.setMonth(dt.getMonth() + 1)) {
    arr.push({
      label: capitalize(dt.toLocaleDateString(undefined, { month: 'short' })),
      month: dt.getMonth() + 1,
      year: dt.getFullYear()
    })
  }
  return arr
}

export const getUploadStatus = (status: UploadStatus, intl: IntlShape) =>
  intl.formatMessage({
    id: status !== UploadStatus.Done ? 'uploadstatus.waiting' : 'uploadstatus.done'
  })

export const timeConvert = (time: number) => {
  const hours = time / 60
  const rhours = Math.floor(hours)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)
  return { hours: rhours, minutes: rminutes }
}
