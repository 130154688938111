"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNotifications = exports.thunks = exports.reducer = void 0;

var crud_1 = require("./crud");

var notification_1 = require("./notification");

var KEY = 'BRAND_USER';
exports.reducer = (0, crud_1.default)(KEY);
exports.thunks = new crud_1.ThunkHandler({
  url: '/brand-users',
  key: KEY
});

function createNotifications(dispatch, message) {
  dispatch((0, notification_1.addLabels)(KEY, {
    fetchValues: {
      error: message.errorRetrievingBrandUsers
    },
    fetchValue: {
      error: message.errorRetrievingABrandUser
    },
    create: {
      success: message.addBrandUser,
      error: message.errorCreatingBrandUser
    },
    patch: {
      success: message.userUpdated,
      error: message.errorRegisteringBrandUser
    },
    delete: {
      success: message.userDeleted,
      error: message.errorDeletingBrandUser
    }
  }));
}

exports.createNotifications = createNotifications;