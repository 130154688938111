import { ColorTagDTO } from '@addsome/dtos';
import { Button, ColorButton, InputField, Tag, TagsList, Theme } from '@addsome/ui-kit';
import React, { useRef, useState } from 'react';
import styles from './ProductVariations.module.scss';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Form } from 'antd';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { motion } from 'framer-motion';


type IProps = WrappedComponentProps & {
  changeVariationName: (colorCode: string) => void;
  addEditVariation: (tagsArray?: string[]) => void;
  closeAddVariation: () => void;
  setAddEditVariationError: () => void;
  addColorLabel: (label: string, id: string) => void;
  onCloseColorLabel: (index: number) => void;
  displayAddVariation: boolean;
  variationName: string;
  variationNameError: boolean;
  addVariation: boolean;
  colorTagsRequest: ColorTagDTO[];
  colorTagsLabels: string[];
  colorTagsIds: string[];

};

const AddVariation: React.FC<IProps> = ({
  intl,
  changeVariationName,
  variationName,
  displayAddVariation,
  variationNameError,
  addEditVariation,
  setAddEditVariationError,
  closeAddVariation,
  addColorLabel,
  onCloseColorLabel,
  colorTagsRequest,
  colorTagsLabels,
  colorTagsIds,
  addVariation = false
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef<any>(null);
  useOnClickOutside(popupRef, () => setIsOpen(false));

  const variants = {
    open: { opacity: 1, y: 0, display: 'block', zIndex: 10 },
    closed: {
      opacity: 0,
      y: -40,
      zIndex: -1,
      transitionEnd: { display: 'none' }
    }
  };
  return (
    <div className={`${styles.alignTableVariation} ${styles.addVariationNew}`}>

      <div className={`${styles.alignTableVariation} `}>

        <span className={`${styles.inputStyleAddVariation} ${styles.smallerWidth} ${addVariation && styles.addInputWidth}`}>
          <InputField
            name="Variation name"
            placeholder={intl.formatMessage({ id: 'productpage.variations.variationName' })}
            type="text"
            required
            onChange={(e) => { changeVariationName(e.target.value); }}
            defaultValue={variationName}
            error={variationNameError && variationName === "" ? intl.formatMessage({ id: 'productpage.variations.errorEmptyField' }) : ""}
          />
        </span>
        <span className={`${styles.inputStyleAddVariation} ${addVariation && styles.addInputWidth} `}>
          <div ref={popupRef} >
            <div onClick={e => {
              e.stopPropagation();
              setIsOpen(isOpen => !isOpen);
            }} >
              <InputField placeholder={intl.formatMessage({ id: 'productsvariations.selectColors' })} className={styles.pointerCursor}>
              </InputField>
            </div>
            <div className={styles.tagListContainer}>
              {colorTagsLabels.map((value, index) => (
                <li key={index} className={styles.liStyle}>
                  <Tag closable onClose={function (index: number): void {
                    onCloseColorLabel(index);
                  }}>
                    {value}
                  </Tag>
                </li>
              ))}
            </div>
            {isOpen &&
              <motion.div
                animate={isOpen ? 'open' : 'closed'}
                variants={variants}
              >
                <div className={styles.container}>
                  {colorTagsRequest.map((color, index) =>
                    <ColorButton
                      className={styles.colorButton}
                      key={color.id}
                      color={{
                        id: color.id,
                        name: color.name,
                        hexa: color.hexaColor || '',
                        index
                      }}
                      showName
                      onClick={() => addColorLabel(color.name, color.id)}
                      newName={true}
                    >
                    </ColorButton>
                  )}
                </div>
              </motion.div>
            }

          </div>
        </span>
      </div>
      {displayAddVariation &&
        (<div className={`${styles.flexRight} ${styles.addVariationButton}  ${variationName ? styles.closerButtons : ""}`}>
          <Button
            theme={Theme.PRIMARY}
            shape="circle"
            aria-label="Éditer"
            icon="check"
            className={`${styles.rightSpace}`}
            onClick={() => {
              if (variationName !== "")
                addEditVariation(colorTagsIds);
              else setAddEditVariationError();
            }
            }
          />
          <Button
            theme={Theme.GHOST}
            shape="circle"
            aria-label="Éditer"
            icon="close"
            onClick={() => {
              closeAddVariation();
            }
            }
          />
        </div>)
      }
    </div>
  );
};

export default (injectIntl(AddVariation));
