import { ArchitectDTO } from '@addsome/dtos'
import { Table, Tag, TagType, Thumbnail, Size } from '@addsome/ui-kit'
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import styles from './ClientsTable.module.scss'
import getProfilePicture from '../../../utils/getProfilePicture'
import { IPaginationRequest, brands as brandsActions } from '@addsome/redux-store'
import Routes from '../../../utils/routes'

type IProps = ReturnType<typeof mapDispatchToProps> & {
  clients: ArchitectDTO[]
  total: number
  brandId: string
  currentPage: number
  pageSize: number
  loading: boolean
  onTableChange: (
    pagination: PaginationConfig,
    filter: Record<keyof ArchitectDTO, string[]>,
    sorter: SorterResult<ArchitectDTO>
  ) => void
  sortBy?: { [key: string]: number }
}

const ClientsTable: React.FC<IProps> = ({
  total,
  pageSize,
  currentPage,
  sortBy,
  clients,
  loading,
  onTableChange,
  brandId,
  fetchBrandArchitects
}) => {
  const intl = useIntl()
  const [columns, setColumns] = useState<ColumnProps<ArchitectDTO>[]>([
    {
      key: 'avatar',
      className: styles.avatar,
      render: (_, record) => <Thumbnail src={getProfilePicture(record.account)} size={Size.SMALL} />
    },
    {
      key: 'lastName',
      dataIndex: 'account.lastName',
      title: intl.formatMessage({ id: 'clienttable.col.lastName' }),
      render: (lastName, record) => (
        <Link to={`${Routes.Architects}/${record.id}`}>
          {record.account.firstName} {lastName}
        </Link>
      ),
      sorter: true
    },
    {
      key: 'city',
      dataIndex: 'address.city',
      title: intl.formatMessage({ id: 'clienttable.col.city' })
      // TODO: Missing from API
      // sorter: true
    },
    {
      key: 'country',
      dataIndex: 'address.country',
      title: intl.formatMessage({ id: 'clienttable.col.country' })
      // TODO: Missing from API
      // sorter: true
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: intl.formatMessage({ id: 'clienttable.col.type' }),
      render: type => intl.formatMessage({ id: `profileEdit.form.type.${type}` })
      // TODO: Missing from API
      // sorter: true
    }
    // TODO: Removed temporarily
    // {
    //   key: 'status',
    //   dataIndex: 'id',
    //   title: intl.formatMessage({ id: 'clienttable.col.status' }),
    //   // Will change once the brand architects data are pulled
    //   render: () => null
    // }
  ])

  useEffect(() => {
    const fetchData = async () => {
      const brandsArchitects = await fetchBrandArchitects(brandId)
      const brandsArchitectsIds: string[] = brandsArchitects.data.map(architect => architect.id)

      setColumns(columns => {
        return columns.map(column => {
          if (column.key !== 'status') return column
          return {
            ...column,
            render: (id: string) => {
              const status = brandsArchitectsIds.includes(id) ? TagType.ACTIVE : TagType.INACTIVE
              return (
                <Tag type={status}>
                  <FormattedMessage id={`clienttable.col.status.${status}`} />
                </Tag>
              )
            }
          }
        })
      })
    }
    fetchData()
  }, [brandId, fetchBrandArchitects])

  useEffect(() => {
    if (!sortBy) return

    setColumns(columns => {
      return columns.map(column => {
        const orderValue = column.key
          ? sortBy[column.key]
          : (column.dataIndex && sortBy[column.dataIndex]) || 0

        return !orderValue
          ? column
          : {
            ...column,
            defaultSortOrder: orderValue > 0 ? 'ascend' : 'descend'
          }
      })
    })
  }, [sortBy])

  const pagination: PaginationConfig = {
    total: total,
    pageSize: pageSize,
    current: currentPage
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={clients}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={onTableChange}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchBrandArchitects: (brandId: string, request: IPaginationRequest = {}) => {
    return dispatch(brandsActions.fetchBrandArchitects(brandId, request))
  },
  push: (location: string) => dispatch(push(location))
})

export default connect(
  null,
  mapDispatchToProps
)(ClientsTable)
