import AntLayout from 'antd/lib/layout'
import classnames from 'classnames'
import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import logoWhite from '../../assets/images/logo-white.png'
import logo from '../../assets/images/logo.png'
import { IRootState } from '../../redux'
import Routes from '../../utils/routes'
import { ScrollDirectionContext } from '../../utils/ScrollDirectionContext'
import ConnectedUser from '../ConnectedUser/ConnectedUser'
import NavBar from '../NavBar/NavBar'
import styles from './Header.module.scss'

type IProps = ReturnType<typeof mapStateToProps> & {
  darkTheme: boolean
}

const Header: React.FC<IProps> = ({ darkTheme, user, router }) => {
  const scrollDirection = useContext(ScrollDirectionContext)

  return (
    <AntLayout.Header
      className={classnames(styles.header, {
        [styles.darkTheme]: darkTheme,
        // [styles.white]: router.location.pathname === Routes.Catalog,
        [styles.sticky]:
          router.location.pathname.includes(Routes.Catalog) &&
          (scrollDirection === 'up' || scrollDirection == null)
      })}
    >
      <div className={styles.content}>
        <Link to="/" className={styles.logo}>
          <img alt="Addsome" src={darkTheme ? logo : logoWhite} />
        </Link>
        <NavBar darkTheme={darkTheme} />
        <ConnectedUser />
      </div>
    </AntLayout.Header>
  )
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  user: state.userState.user,
  router: state.router
})

export default connect(mapStateToProps)(Header)
