import React, { useEffect, useState } from 'react'
import styles from '../ProductsComponent/ProductsComponent.module.scss'
import { Button, InputField, Theme, Loading } from '@addsome/ui-kit'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../../redux'
import { AnyAction } from 'redux'
import {
  architectProductHistory,
  IPaginationRequest,
} from '@addsome/redux-store'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import useLoggedUser from '../../../../hooks/useUser'
import { Icon } from 'antd'

const ProductsComponent = ({setSceneName, sceneName, selectedProducts, toggleProductSelection, thumbnail, setStep, fetchProductsDownloadHistory, handleCreateScene}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useLoggedUser();
  const intl = useIntl();
  const [showAddName, setShowAddName] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [showMinimumProductsMessage, setShowMinimumProductsMessage] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const handleCreateSceneAction = async () => {
    if (isProcessing) return;
    
    setIsProcessing(true);
    try {
      handleCreateScene();
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchProductsDownloadHistory(user.id, {take:300}).then(res => {
      setProducts(res.data)
      setLoading(false)
    })
  }, []);
  
  const handleNextClick = () => {
    if (selectedProducts.length > 0) {
      setIsReadOnly(true);
      setShowAddName(true);
    } else {
      setShowMinimumProductsMessage(true);
      setTimeout(() => setShowMinimumProductsMessage(false), 3000);
    }
  };
  
  const handleNextClickForName = () => {
    if (sceneName == '') {
      setShowNameError(true);
      setTimeout(() => setShowNameError(false), 3000);
    } else {
      setShowUpload(true);
      setReadonly(true)
    }
  }
  
  const onProductClick = (productId) => {
    if (!isReadOnly) {
      toggleProductSelection(productId);
    }
  };
  
  const getButton = () => {
    if (showAddName && !showUpload) {
      return (
        <Button
          className={styles.uploadButton}
          onClick={() => handleNextClickForName()}
          theme={Theme.BLACK}
        >
          {intl.formatMessage({ id: 'community.products.next' })}
          <Icon type="arrow-right" />
        </Button>
      )
    }
    if (showUpload) {
      return (
        <Button
          className={styles.uploadButton}
          onClick={() => handleCreateSceneAction()}
          disabled={isProcessing}
          theme={Theme.BLACK}
        >
          {intl.formatMessage({ id: 'community.products.completeUpload' })}
          <Icon type="arrow-right" />
        </Button>
      )
    }
    return (
      <Button
        className={styles.uploadButton}
        onClick={() => {handleNextClick()}}
        theme={Theme.BLACK}
      >
        {intl.formatMessage({ id: 'community.products.next' })}
        <Icon type="arrow-right" />
      </Button>
    )
  }
  
  const getOnBackButton = () => {
    if (showAddName && !showUpload) {
      setShowAddName(false);
      setIsReadOnly(false);
    } else if (showUpload) {
      setShowUpload(false);
      setReadonly(false);
    } else {
      setStep(2);
    }
  }
  
  if (loading) {
    return <Loading/>
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <img src={thumbnail && thumbnail.url} className={styles.thumbnail}/>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.productsPart}>
          <Icon type="arrow-left" style={{cursor: "pointer"}} onClick={() => getOnBackButton()}/>
          <div className={styles.textSection}>
            {showAddName ? (
              <div>
                <InputField
                  className={styles.input}
                  placeholder={intl.formatMessage({ id: 'community.products.name.placeholder' })}
                  value={sceneName}
                  readOnly={readonly}
                  onChange={(e) => setSceneName(e.target.value)}
                />
                {showAddName && !showUpload &&
                  <p><FormattedMessage id="community.products.name.choose" /></p>
                }
                {showNameError && (
                  <span className={styles.showMinimumProductsMessage}>
                    {intl.formatMessage({ id: 'community.name.select.error' })}
                  </span>
                )}
              </div>
            ) : (
              <div>
                <h2><FormattedMessage id="community.products.suggest" /></h2>
                <span><FormattedMessage id="community.products.suggest.description" /></span>
                {showMinimumProductsMessage && (
                  <span className={styles.showMinimumProductsMessage}>
                    {intl.formatMessage({ id: 'community.products.select.error' })}
                  </span>
                )}
              </div>
            )}
           <>{getButton()}</>
          </div>
          <div className={`${styles.productsWrapper} ${isReadOnly ? styles.readOnly : ''}`}>
            {products.map((p) => (
              <div
                key={p.id}
                className={`${styles.productCard} ${selectedProducts.includes(p.id) ? styles.selected : ''}`}
                onClick={() => onProductClick(p.id)}
              >
                <div className={styles.productThumbnail}>
                  <img src={p.thumbnail.media.url} />
                  {selectedProducts.includes(p.id) && (
                    <div className={styles.overlay}>
                    </div>
                  )}
                </div>
                <div className={styles.productDescription}>
                  <h4>{p.brand.name}</h4>
                  <div>{p.name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProductsDownloadHistory: (architectId: string, request: IPaginationRequest|undefined) =>
    dispatch(architectProductHistory.fetchArchitectProductDownloads(architectId, request)),
})

export default injectIntl(
  connect(
    undefined,
    mapDispatchToProps
  )(ProductsComponent)
)
