import { useRef, useState, useCallback, useEffect, WheelEvent } from 'react'
import throttle from 'lodash.throttle'

export const useHorizontalScroll = <T extends HTMLElement>() => {
  const scrollContainerRef = useRef<T>(null)

  const handleWheel = (e: WheelEvent<T>) =>
    scrollContainerRef.current && scrollContainerRef.current.scrollBy(e.deltaY, 0)

  return { scrollContainerRef, handleWheel }
}

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>()

  const lastScrollYRef = useRef(window.scrollY)
  const handleScroll = useCallback(
    throttle(() => {
      setScrollDirection(lastScrollYRef.current > window.scrollY ? 'up' : 'down')
      lastScrollYRef.current = window.scrollY
    }, 120),
    []
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return scrollDirection
}
