import {
  MediaDTO,
  ProductRelationshipsExtendedDTO,
  ProductUpdateDTO,
  ObjectProductModelBundleCreationWithoutObjectProductDTO,
  ObjectProductModelBundleUpdateDTO,
  Platform
} from '@addsome/dtos'
import {
  product as productActions,
  objectProductModelBundle
} from '@addsome/redux-store'
import { Heading, Modal, Button, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import {
  convertDocumentFileForCreation,
  convertPictureForCreation,
  convertSourceFileForCreation
} from '../../../services/convertions'
import { FileType, getFileType, getFileTypeByName } from '../../../services/filesTypes'
import { mediaUploadUrl } from '../../../services/media'
import UploadMedia, { UploadType } from '../../UploadMedia/UploadMedia'
import ImportFile, { TypeImport } from '../ImportFile'
import styles from './ProductPreview.module.scss'
import ProductPreviewFile from './ProductPreviewFile';

type IProps = ReturnType<typeof mapStateToProps> &
  WrappedComponentProps &
  ReturnType<typeof mapDispatchToProps> & {
    product: ProductRelationshipsExtendedDTO
    readonly?: boolean
  }

interface IState {
  newFile: { media: MediaDTO; name: string; type: TypeImport } | null
  showQuotaPopup: boolean
  hasReachedLastQuota: boolean
}

class ProductPreview extends React.Component<IProps, IState> {
  public state: IState = {
    newFile: null,
    showQuotaPopup: false,
    hasReachedLastQuota: false
  }

  public render() {
    const { product, readonly, token, connectedUser } = this.props
    const { newFile, showQuotaPopup, hasReachedLastQuota } = this.state

    return (
      <div className={styles.productFiles}>
        <>
          <div className={styles.add}>
            <UploadMedia
              type={UploadType.CIRCLE}
              action={mediaUploadUrl}
              afterUpload={this.importFile}
              token={token}
            >
              <Icon type="plus" aria-label="Ajouter" />
            </UploadMedia>
          </div>
          <Modal
            visible={newFile !== null}
            title={<FormattedMessage id="productpage.files.import" />}
            footer={null}
            onCancel={() => this.setState({ newFile: null })}
          >
            {newFile && (
              <ImportFile
                media={newFile.media}
                fileName={newFile.name}
                type={newFile.type}
                onImport={this.addFile}
              />
            )}
          </Modal>
        </>
        <div className={styles.section}>
          <Heading level={3} as="h2">
            <FormattedMessage id="productpage.preview.fileSelected" />
          </Heading>
          {product.objectProductModelBundle && (
            <>
              <ProductPreviewFile product={product}></ProductPreviewFile>
            </>
          )}
        </div>
      </div>
    )
  }

  private importFile = (media: MediaDTO, name: string) => {
    const type = getFileTypeByName(media.url)
    const isDocument = [FileType.document, FileType.text, FileType.archive].includes(type)
    const isPicture = [FileType.image, FileType.vectorial].includes(type)

    this.setState({
      newFile: {
        media,
        name,
        type: isDocument ? TypeImport.information : isPicture ? TypeImport.picture : TypeImport.cao
      }
    })
  }

  private addFile = (name: string) => {
    const { newFile } = this.state
    if (newFile) {
      this.props.patchProduct(this.props.product.id, {
        sourceFiles: [
          ...(this.props.product.sourceFiles || []).map(convertSourceFileForCreation),
          ...(newFile.type === TypeImport.cao
            ? [
              {
                name,
                mediaId: newFile.media.id
              }
            ]
            : [])
        ],
        documents: [
          ...(this.props.product.documents || []).map(convertDocumentFileForCreation),
          ...(newFile.type === TypeImport.information
            ? [
              {
                name,
                mediaId: newFile.media.id,
                enabled: true
              }
            ]
            : [])
        ],
        pictures: [
          ...(this.props.product.pictures || []).map(convertPictureForCreation),
          ...(newFile.type === TypeImport.picture
            ? [
              {
                name,
                mediaId: newFile.media.id,
                enabled: true
              }
            ]
            : [])
        ]
      })

      this.setState({
        newFile: null
      })

      if (this.props.product.objectProduct == undefined) {
        return;
      }

      if (this.props.product.objectProductModelBundle === null) {
        this.props.createObjectProductModelBundle(this.props.product.objectProduct.id, { platform: Platform.Webgl, unityVersion: '2019.1.8f1', fileId: newFile.media.id },
          this.props.intl.formatMessage({ id: 'global.succes' }), this.props.intl.formatMessage({ id: 'global.error' }));
      } else {
        this.props.updateObjectProductModelBundle(this.props.product.objectProduct.id, { fileId: newFile.media.id }, Platform.Webgl,
          this.props.intl.formatMessage({ id: 'global.succes' }), this.props.intl.formatMessage({ id: 'global.error' }));
      }
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  token: state.authState.token,
  accountType: state.authState.type,
  connectedUser: state.userState.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  patchProduct: (id: string, payload: ProductUpdateDTO) =>
    dispatch(productActions.thunks.patchValue(id, payload)),
  createObjectProductModelBundle: (id: string, payload: ObjectProductModelBundleCreationWithoutObjectProductDTO,
    succes?: string, error?: string) =>
    dispatch(objectProductModelBundle.createObjectProductModelBundle(id, payload, succes, error)),
  updateObjectProductModelBundle: (id: string, payload: ObjectProductModelBundleUpdateDTO, platform: string,
    succes?: string, error?: string) =>
    dispatch(objectProductModelBundle.updateObjectProductModelBundle(id, payload, platform, succes, error))
})

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductPreview)
)
