import React, { useEffect, useState } from 'react'
import styles from './CommunityPage.module.scss'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { IRootState } from '../../redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { communityScene } from '@addsome/redux-store'
import { MediaDTO } from '@addsome/dtos/dist'
import CreateScene from './CreateScene/CreateScene'
import { SceneDTO } from '@addsome/dtos'
import ScenePage from './ScenePage'
import Routes from '../../utils/routes'
import { Mms } from '@mui/icons-material'
import { Loader } from '@react-three/drei'
import { Loading } from '@addsome/ui-kit'
import RegisterViewModal from '../RegisterPage/RegisterViewModal'
import { Link } from 'react-router-dom'

type IProps = {
  slug?: string
}
  & WrappedComponentProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const CommunityPage: React.FC<IProps> = ({
  slug,
  scenes,
  loading,
  fetchScenes,
  accountType,
  fetchMostPopularScenes,
  mostPopularScenes,
  fetchPromotedScenes,
  promotedScenes
}) => {

  const [communityGallery, setCommunityGallery] = useState([]);

  useEffect(() => {
    if (slug) {
      window.scrollTo(0, 0)
    }
  }, [slug])

  useEffect(() => {
    fetchScenes().then(s => {
      let gallery: MediaDTO[] = []
      if (s && s.length > 0) {
        for (const scene of s) {
          if (scene.medias && scene.medias.length) {
            const mapMedias = scene.medias.map(m => {
              return { sceneId: scene.id, sceneSlug: scene.slug, ...m }
            })
            gallery = gallery.concat(mapMedias[0])
          }
        }
        setCommunityGallery(gallery.slice(0, 6))
      }
    })
    fetchMostPopularScenes()
    fetchPromotedScenes()
  }, [])

  return (
    <>
      {loading ?
        <div className={styles.loaderContainer}>
          <Loading />
        </div>
        :
        <div className={styles.container}>
          {slug ?
            <ScenePage slug={slug} />
            : <></>
          }
          <div className={styles.firstSection}>
            <h1>
              <FormattedMessage id='community.gallery' />
            </h1>
            <span><FormattedMessage id='community.description' /></span>
            <div className={styles.communityGallery}>
              {communityGallery.length > 0 &&
                communityGallery.map(m =>
                  <Link key={m.id} to={`${Routes.Community}/${m.sceneSlug}`}>
                    <img src={m.url} />
                  </Link>
                )
              }
            </div>
          </div>
          <div className={styles.secondSection}>
            <h2>
              <FormattedMessage id='homepage.section.titleaddsomeselection' />
            </h2>
            <span><FormattedMessage id='community.discover' /></span>
            <div className={styles.addsomeGallery}>
              {promotedScenes && promotedScenes.length > 0 &&
                promotedScenes.map(m =>
                  <Link key={m.id} to={`${Routes.Community}/${m.slug}`}>
                    <img src={m.medias[0].url} key={m.id} />
                  </Link>)
              }
            </div>
          </div>
          {/* <div className={styles.thirdSection}>
            <h2>
              <FormattedMessage id='community.mostPopular' />
            </h2>
            <span><FormattedMessage id='community.popularDescription' /></span>
            <div className={styles.popularGallery}>
              {mostPopularScenes.length > 0 &&
                mostPopularScenes.map(m =>
                  <a key={m.id} href={`${Routes.Community}/${m.slug}`}>
                    <img src={m.medias[0].url} key={m.id} />
                  </a>
                )
              }
            </div>
          </div> */}
          <CreateScene defaultGallery={communityGallery} />
          <RegisterViewModal />
        </div >
      }
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  scenes: state.communitySceneState.scenes,
  mostPopularScenes: state.communitySceneState.mostPopularScenes,
  promotedScenes: state.communitySceneState.promotedScenes,
  loading: state.communitySceneState.loading,
  accountType: state.authState.type,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchScenes: () => dispatch(communityScene.fetchScenes()),
  fetchMostPopularScenes: () => dispatch(communityScene.fetchMostPopularScenes()),
  fetchPromotedScenes: () => dispatch(communityScene.fetchPromotedScenes())
})


export default connect(mapStateToProps,
  mapDispatchToProps)(injectIntl(CommunityPage))