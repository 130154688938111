import { AccountType, ProductUpdateDTO, BrandUserDTO, BrandUserRole, AddsomeUserDTO } from '@addsome/dtos'
import {
  colorTag,
  objectProduct as objectProductActions,
  objectProductCategories,
  objectProductVariations,
  product as productActions,
  productMaterialTag,
  productStates as productStatesActions,
  roomTag,
  styleTag
} from '@addsome/redux-store';
import { Heading, Size, Tabs, Tag, TagType } from '@addsome/ui-kit';
import { push } from 'connected-react-router';
import get from 'lodash.get';
import { parse, stringify } from 'querystring';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import PageHeader from '../../../components/PageHeaders/PageHeader';
import ProductDimensions from '../../../components/Products/ProductDimensions';
import ProductFiles from '../../../components/Products/ProductFiles/ProductFiles';
import ProductInformation from '../../../components/Products/ProductInformation/ProductInformation';
import ProductMedia from '../../../components/Products/ProductMedia/ProductMedia';
import ProductProblems from '../../../components/Products/ProductProblems/ProductProblems';
import ProductTags from '../../../components/Products/ProductTags';
import { IRootState } from '../../../redux';
import { updatePageTitle } from '../../../services/title';
import Mixpanel from '../../../utils/mixpanel';
import styles from './ProductPage.module.scss';
import AppSocket from '../../../utils/socket';
import getProductName from '../../../utils/getProductName';
import ProductVariations from '../../../components/Products/ProductVariations/ProductVariations';
import ProductPreview from '../../../components/Products/ProductPreview/ProductPreview';
import Routes from "../../../utils/routes";

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IMatchParams> &
  WrappedComponentProps

interface IState {
  activeKey: string;
  isLoading: boolean;
}

class ProductPage extends React.Component<IProps, IState> {
  state: IState = {
    activeKey: (this.props.queryParams.tab as string) || 'info',
    isLoading: false
  };


  public async componentDidMount() {
    this.props.fetchProductStates();
    this.props.fetchObjectProductCategories();
    this.props.fetchStyleTags();
    this.props.fetchRoomTags();
    this.props.fetchProductMaterialTags();
    this.props.getColorTags();

    await this.props.fetchProduct(this.props.match.params.id);
    updatePageTitle((this.props.product || ({} as any)).name || 'Product');
    if (this.props.product && this.props.product.objectProduct) {
      this.props.fetchObjectProduct(this.props.product.objectProduct.id);
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { product } = this.props;
    if (prevProps.product !== product && product) {
      AppSocket.emiProductAccountMetricEvent({
        productId: product.id
      });

      Mixpanel.trackProductViewed({
        brandId: product.brand.id,
        brandName: product.brand.name,
        productId: product.id,
        productName: product.name
      });
      if (product.objectProduct) {
        this.props.fetchObjectProductVariations(product.objectProduct.id);
      }
    }
    if (prevProps.queryParams !== this.props.queryParams) {
      if (this.props.queryParams.hasOwnProperty('tab'))
        this.setState({ activeKey: this.props.queryParams.tab as string });
    }
  }
  public componentWillUnmount() {
    this.props.unsetProduct();
    this.props.unsetObjectProduct();
  }

  public render() {
    const { product, objectProduct } = this.props;
    const readOnly = this.props.accountType !== AccountType.AddsomeUser ||
      !(this.props.accountType == AccountType.AddsomeUser && (this.props.connectedUser as AddsomeUserDTO).perform_deletions);
    const canEdit =
      (this.props.accountType === AccountType.AddsomeUser &&
        (this.props.connectedUser as AddsomeUserDTO).data_entry) ||
      (this.props.accountType === AccountType.BrandUser &&
        (this.props.connectedUser as BrandUserDTO).role === BrandUserRole.Admin);

    const canDelete = this.props.accountType === AccountType.AddsomeUser &&
      (this.props.connectedUser as AddsomeUserDTO).perform_deletions

    const headerProps = {
      title: product && (
        <Heading level={1} strong>
          {getProductName(product)}
        </Heading>
      ),
      ...(product
        ? {
          richParams: {
            info: product.brand && (
              <Link to={`/brands/${product.brand.id}`}>{product.brand.name}</Link>
            ),
            ...(!readOnly ? { enabled: product.enabled } : {}),
            icon: get(product, 'brand.logo.url', ''),
            namespace: 'products',
            readonly: readOnly && !canEdit,
            enabled: product.enabled,
            promoted: product.promoted,
            onChangeEnabled: this.updateEnabled,
            onChangeStateId: this.updateStateId,
            onChangePromoted: this.updatePromoted,
            stateId: product.stateId
          }
        }
        : {})
    }
    return (
      <div className={styles.product}>
        <PageHeader
          {...headerProps}
          productStates={this.props.productStates}
          isLoading={this.state.isLoading}
          changeLoader={() => this.setState({ isLoading: true })}
        >
          <div className={styles.status}>
            {!readOnly && product && (
              <Tag type={TagType.ACTIVE} size={Size.SMALL}>
                {this.getProductStateTitle(product.stateId) || product.stateId}
              </Tag>
            )}
          </div>
        </PageHeader>
        {product && objectProduct && (
          // query param can be either string or string[]
          <Tabs activeKey={this.state.activeKey} onChange={this.changeTab}>
            <Tabs.TabPane
              tab={this.props.intl.formatMessage({ id: 'productspage.infos' })}
              key="info"
            >
              <ProductInformation
                product={product}
                objectProduct={objectProduct}
                onSubmit={this.handleSubmit}
                readonly={readOnly}
                canEdit={canEdit}
                user={this.props.connectedUser}
              />
            </Tabs.TabPane>
            {this.props.accountType === AccountType.AddsomeUser && (
              <Tabs.TabPane
                tab={this.props.intl.formatMessage({ id: 'productspage.specifications' })}
                key="tags"
              >
                <ProductTags
                  product={product}
                  canEdit={canEdit}
                  objectProduct={objectProduct}
                  onSubmit={this.handleSubmit}
                  user={this.props.connectedUser}
                />
              </Tabs.TabPane>
            )}
            {this.props.accountType === AccountType.AddsomeUser && (
              <Tabs.TabPane
                tab={this.props.intl.formatMessage({ id: 'productspage.dimensions' })}
                key="dimensions"
              >
                <ProductDimensions
                  product={product}
                  objectProduct={objectProduct}
                  canEdit={canEdit}
                  onSubmit={this.handleSubmit}
                />
              </Tabs.TabPane>
            )}
            {this.props.accountType === AccountType.AddsomeUser && !canEdit && (
              <Tabs.TabPane
                tab={this.props.intl.formatMessage({ id: 'productspage.variations' })}
                key="variations"
              >
                <ProductVariations product={product} readonly={readOnly} />
              </Tabs.TabPane>
            )}
            {!canEdit && (
              <Tabs.TabPane
                tab={this.props.intl.formatMessage({ id: 'productspage.images' })}
                key="images"
              >
                <ProductMedia product={product} readonly={readOnly} />
              </Tabs.TabPane>
            )}
            {!canEdit && (
              <Tabs.TabPane
                tab={this.props.intl.formatMessage({ id: 'productspage.preview' })}
                key="preview"
              >
                <ProductPreview product={product} readonly={readOnly} />
              </Tabs.TabPane>
            )}
            {!canEdit && (
              <Tabs.TabPane
                tab={this.props.intl.formatMessage({ id: 'productspage.downloads' })}
                key="files"
              >
                <ProductFiles product={product} readonly={readOnly} />
              </Tabs.TabPane>
            )}
            {this.props.accountType === AccountType.AddsomeUser && !canEdit && (
              <Tabs.TabPane
                tab={this.props.intl.formatMessage({ id: 'productspage.problems' })}
                key="problems"
              >
                <ProductProblems productId={product.id} readonly={readOnly} />
              </Tabs.TabPane>
            )}

          </Tabs>
        )}
      </div>
    );
  }

  private handleSubmit = async (data: ProductUpdateDTO) => {
    const { product } = this.props;
    if (!product) return;
    // prevent updating data from other tabs
    delete data.sourceFiles;
    delete data.documents;
    delete data.pictures;
    await this.props.patchProduct(product.id, data);
    await this.props.fetchProduct(product.id);
  };

  private changeTab = (tab: string) => {
    const params = { ...this.props.queryParams, tab };
    this.props.updateQuery(stringify(params));
    this.setState({ activeKey: tab });

    const { product } = this.props;
    if ((tab === "variations" || tab === "info") && product && product.objectProduct)
      this.props.fetchObjectProductVariations(product.objectProduct.id);

  };

  private updateEnabled = (enabled: boolean) => {
    if (this.props.product) {
      this.props.patchProduct(this.props.product.id, { enabled });
    }
  };

  private updatePromoted = (promoted: boolean) => {
    if (this.props.product) {
      this.props.patchProduct(this.props.product.id, { promoted });
    }
  };

  private updateStateId = (stateId: string) => {
    if (this.props.product) {
      this.props.patchProduct(this.props.product.id, { stateId }).then((onSuccess) => {
        this.setState({
          isLoading: false
        });
      });
    }
  };

  private getProductStateTitle = (stateId: string) => {
    const productState = this.props.productStates.find(state => state.id === stateId);
    return (productState && productState.title) || '';
  };
}

const mapStateToProps = (state: IRootState) => ({
  product: state.productState.value,
  accountType: state.authState.type,
  connectedUser: state.userState.user,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')),
  productStates: state.productStatesState.productStates,
  objectProduct: state.objectProductState.value,
  router: state.router
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProduct: (id: string) => dispatch(productActions.thunks.fetchValue(id)),
  patchProduct: (id: string, payload: ProductUpdateDTO) =>
    dispatch(productActions.thunks.patchValue(id, payload)),
  updateQuery: (params: string) => dispatch(push({ search: params })),
  unsetProduct: () => dispatch(productActions.thunks.unsetValue()),
  fetchObjectProductVariations: (objectProductId: string) =>
    dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId)),
  unsetObjectProduct: () => dispatch(objectProductActions.thunks.unsetValue()),
  fetchProductStates: () => dispatch(productStatesActions.fetchProductStates()),
  fetchObjectProduct: (id: string) => dispatch(objectProductActions.thunks.fetchValue(id)),
  fetchObjectProductCategories: () => dispatch(objectProductCategories.thunks.fetchValues()),
  fetchStyleTags: () => dispatch(styleTag.thunks.fetchValues()),
  fetchRoomTags: () => dispatch(roomTag.thunks.fetchValues()),
  fetchProductMaterialTags: () => dispatch(productMaterialTag.thunks.fetchValues()),
  getColorTags: () =>
    dispatch(colorTag.thunks.fetchValues()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProductPage))
