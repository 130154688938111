"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_SELECTED_PRODUCTS"] = "SET_SELECTED_PRODUCTS";
  ActionsTypes["SET_PRODUCTS_SETTINGS"] = "SET_PRODUCTS_SETTINGS";
  ActionsTypes["ADD_PRODUCT_SETTING"] = "ADD_PRODUCT_SETTING";
  ActionsTypes["SET_RENDER_SETTINGS"] = "SET_RENDER_SETTINGS";
  ActionsTypes["UNSET_RENDER_SETTINGS"] = "UNSET_RENDER_SETTINGS";
  ActionsTypes["REMOVE_RENDER_SETTINGS"] = "REMOVE_RENDER_SETTINGS";
  ActionsTypes["RESET"] = "RESET";
  ActionsTypes["SET_PACKSHOTS"] = "SET_PACKSHOTS";
  ActionsTypes["SET_PACKSHOT_LOADING"] = "SET_PACKSHOT_LOADING";
  ActionsTypes["ADD_PACKSHOTS"] = "ADD_PACKSHOTS";
  ActionsTypes["SET_PRODUCTS_SETTINGS_VARIATION_IDS"] = "SET_PRODUCTS_SETTINGS_VARIATION_IDS";
  ActionsTypes["SET_PRODUCTS_SETTINGS_CAMERA_IDS"] = "SET_PRODUCTS_SETTINGS_CAMERA_IDS";
  ActionsTypes["SET_CUSTOM_CAMERA"] = "SET_CUSTOM_CAMERA";
  ActionsTypes["SET_IS_ADD"] = "SET_IS_ADD";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));