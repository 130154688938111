import { AccountType } from '@addsome/dtos/dist'
import React, { memo, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { RouteComponentProps } from 'react-router-dom'
import ProfilePageLayout, { Columns } from '../../templates/ProfilePageLayout/ProfilePageLayout'
import useArchitect from '../../templates/ProfilePageLayout/useArchitect'
import usePush from '../../hooks/usePush'
import useLoggedUser from '../../hooks/useUser'
import Routes from '../../utils/routes'
import styles from './ProfilePage.module.scss'
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel'

interface IMatchParams {
  id: string
}

type IProps = RouteComponentProps<IMatchParams>

const ProfilePage: React.FC<IProps> = ({ match }) => {
  const intl = useIntl()
  const { architect, profilePicture } = useArchitect(match.params.id)
  const { user, accountType } = useLoggedUser()
  const { push } = usePush()

  useEffect(() => {
    if ((user && match.params.id === user.id) || accountType === AccountType.AddsomeUser) {
      document.location.replace(`${Routes.Architects}/${match.params.id}/edit`)
    }
  }, [user, accountType, match.params.id, push])

  useEffect(() => {
    if (user && architect && accountType === AccountType.AddsomeUser) {
      Mixpanel.track(MixpanelEvents.BrandUserArchitectPageViewed, {
        brandUserId: user.id,
        architectId: architect.id
      })
    }
  }, [accountType, architect, user])

  return (
    architect && (
      <ProfilePageLayout architect={architect}>
        <Columns
          leftSectionTitle={intl.formatMessage({ id: 'profile.title.photo' })}
          rightSectionTitle={intl.formatMessage({ id: 'profile.title.description' })}
          leftSectionContent={
            <>
              <img className={styles.profilePicture} src={profilePicture} />

              <h4 className={styles.name}>
                {architect.account.firstName} {architect.account.lastName}
              </h4>

              <h5 className={styles.country}>
                {intl.formatMessage({ id: 'architect.type.' + architect.type })}
              </h5>

              <h5 className={styles.country}>
                <a href={`mailto:${architect.account.email}`}>{architect.account.email}</a>
              </h5>
              <h5 className={styles.country}>{architect.account.phone}</h5>

              {architect.address.city && (
                <h5 className={styles.country}>{architect.address.city}</h5>
              )}
              {architect.address.country && (
                <h5 className={styles.country}>{architect.address.country}</h5>
              )}
            </>
          }
          rightSectionContent={<>{architect.description}</>}
        />
      </ProfilePageLayout>
    )
  )
}

export default memo(ProfilePage)
