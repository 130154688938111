import { ProductCreationDTO, ProductLightExtendedDTO, ProductType } from '@addsome/dtos'
import {
  IPaginationRequest,
  product as productActions,
  productStates as productStatesActions
} from '@addsome/redux-store'
import { PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import { parse, stringify } from 'querystring'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import ListTop from '../../ListTop/ListTop'
import { DimensionsStep, InformationStep, PicturesStep } from '../../Products/ProductCreation'
import ProductsTable from '../../Products/ProductsTable/ProductsTable'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps & { productsPageSize: number; readonly?: boolean }

const BrandProducts: React.FC<IProps> = ({
  fetchProducts,
  brand,
  productsPageSize,
  products,
  productLoading,
  productsTotal,
  intl,
  createProduct,
  queryParams,
  updateQuery,
  urlSearch,
  productStates,
  fetchProductStates,
  readonly,
  deleteProduct,
  historyAction
}) => {
  const [productsCurrentPage, setProductsCurrentPage] = useState(
    Math.max(1, parseInt(queryParams.page, 10)) || 1
  )
  const [request, setRequest] = useState<IPaginationRequest>({
    skip: (productsCurrentPage - 1) * productsPageSize,
    take: productsPageSize,
    order: queryParams.order ? JSON.parse(queryParams.order) : { createdAt: -1 },
    filters: {
      brandIds: [(brand || ({} as any)).id || ''],
      name: [queryParams.search],
      ...JSON.parse(queryParams.filters || '{}')
    }
  })
  const [prevSearch, setPrevSearch] = useState(urlSearch)
  const [hasFetchedData, setHasFetchedData] = useState(false)

  useEffect(() => {
    if (hasFetchedData) return
    setHasFetchedData(true)
    fetchProducts(request)
    fetchProductStates()
  }, [fetchProductStates, fetchProducts, hasFetchedData, intl, request])

  // Update data when user use previous
  useEffect(() => {
    if (historyAction === 'PUSH' || prevSearch === urlSearch) return

    const newRequest: IPaginationRequest = {
      skip: (productsCurrentPage - 1) * productsPageSize,
      take: productsPageSize,
      order: queryParams.order ? JSON.parse(queryParams.order) : { createdAt: -1 },
      filters: {
        brandIds: [(brand || ({} as any)).id || ''],
        name: [queryParams.search],
        ...JSON.parse(queryParams.filters || '{}')
      }
    }
    setRequest(newRequest)
    fetchProducts(newRequest)
    setPrevSearch(urlSearch)
  }, [brand, fetchProducts, productsCurrentPage, historyAction, intl, prevSearch, productsPageSize, queryParams.filters, queryParams.order, queryParams.page, queryParams.search, urlSearch])

  const memoizedHandleTableChange = useCallback(
    (
      pagination: PaginationConfig,
      filter: Record<keyof ProductLightExtendedDTO, string[]>,
      sorter: SorterResult<ProductLightExtendedDTO>
    ) => {
      const newRequest: IPaginationRequest = {
        take: productsPageSize,
        skip: ((pagination.current || 1) - 1) * productsPageSize,
        order: sorter.column
          ? { [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1 }
          : { createdAt: -1 },
        filters: { ...request.filters, ...filter }
      }
      setRequest(newRequest)
      fetchProducts(newRequest)
      if (pagination.current) setProductsCurrentPage(pagination.current)

      const newParams = {
        ...queryParams,
        page: pagination.current || productsCurrentPage,
        order: JSON.stringify(newRequest.order),
        filters: JSON.stringify(filter)
      }
      setPrevSearch('?' + stringify(newParams))
      updateQuery(stringify(newParams))
    },
    [fetchProducts, productsCurrentPage, productsPageSize, queryParams, request.filters, updateQuery]
  )

  const memoizedHandleSearch = useCallback(
    (searchPattern: string) => {
      const newRequest: IPaginationRequest = {
        ...request,
        skip: 0,
        take: productsPageSize,
        filters: {
          ...request.filters,
          brandName: [(brand || ({} as any)).name || ''],
          name: [searchPattern]
        }
      }
      setRequest(newRequest)
      setProductsCurrentPage(1)
      fetchProducts(newRequest)

      const newParams = { ...queryParams, search: searchPattern, page: 1 }
      setPrevSearch('?' + stringify(newParams))
      updateQuery(stringify(newParams))
    },

    [brand, fetchProducts, productsPageSize, queryParams, request, updateQuery] 
  )

  return (
    <>
      <ListTop
        searchLabel={intl.formatMessage({ id: 'brandpage.product.search' })}
        onSearch={memoizedHandleSearch}
        searchInitValue={queryParams.search}
        creation={{
          active: !readonly,
          initialValue: {
            name: '',
            designerIds: [],
            type: ProductType.Object,
            origin: '',
            brandId: brand && brand.id,
            stateId: (productStates.length > 0 && productStates[0].id) || ''
          },
          steps: [
            {
              title: <FormattedMessage id="addproduct.information" />,
              component: InformationStep,
              props: { brandId: brand && brand.id }
            },
            { title: <FormattedMessage id="addproduct.dimensions" />, component: DimensionsStep },
            { title: <FormattedMessage id="addproduct.pictures" />, component: PicturesStep }
          ],
          title: <FormattedMessage id="brandpage.product.add" />,
          onCreate: payload => createProduct(payload, request)
        }}
      />
      <ProductsTable
        products={products}
        loading={productLoading}
        total={productsTotal}
        currentPage={productsCurrentPage}
        pageSize={productsPageSize}
        onTableChange={memoizedHandleTableChange}
        onDelete={(id: string) => deleteProduct(id, request)}
        sortBy={JSON.parse(queryParams.order || '{}')}
        filters={JSON.parse(queryParams.filters || '{}')}
        readonly={readonly}
      />
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  historyAction: state.router.action,
  brand: state.brandState.value,
  products: state.productState.values,
  productLoading: state.productState.loading,
  productsTotal: state.productState.total,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string },
  urlSearch: state.router.location.search,
  productStates: state.productStatesState.productStates
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProducts: (request: IPaginationRequest = {}) =>
    dispatch(productActions.thunks.fetchValues(request)),
  createProduct: (payload: ProductCreationDTO, request: IPaginationRequest) =>
    dispatch(productActions.thunks.createValue(payload, request)),
  updateQuery: (params: string) => dispatch(push({ search: params })),
  fetchProductStates: () => dispatch(productStatesActions.fetchProductStates()),
  deleteProduct: (id: string, request: IPaginationRequest) =>
    dispatch(productActions.thunks.deleteValue(id, request))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BrandProducts))
