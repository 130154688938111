import {
  ArchitectCreationDTO,
  AccountDTO,
  Apps,
  standardToLanguageEnum,
  ArchitectSubscriptionType
} from '@addsome/dtos';
import { ArchitectType } from '@addsome/dtos/dist/enum/architectType';
import { architect as architectActions, auth, user } from '@addsome/redux-store';
import { push } from 'connected-react-router';
import React, { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from '../../redux';
import { IRegisterStepItem, RegisterStep, IErrors } from './register-steps';
import RegisterSubscriptionForm from './RegisterSubscriptionForm';
import RegisterInitForm from './RegisterInitForm';
import ConnectionLayout from '../../components/ConnectionLayout/ConnectionLayout';
import styles from './RegisterPage.module.scss';
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { configPayment, createPaymentIntent } from '../../services/payment';
import { getPacks, getSubscription } from '../../services/subscription';
import { parse } from 'querystring'
import { sendAccountValidatedWithoutSubscriptionEmail, sendNewUserEmail } from '../../services/architectAccount'
import bg from '../../assets/images/register-login-bg.jpg'
import { Icon } from 'antd'
import jwt_decode from "jwt-decode";
import RegisterInitFormModal from './RegisterInitFormModal/RegisterInitFormModal';
import RegisterSubscriptionFormModal from './RegisterSubscriptionFormModal/RegisterSubscriptionFormModal'
import Routes from '../../utils/routes';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie'
import * as crypto from 'crypto-js';
import { Helmet } from "react-helmet";


type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps;

const RegisterPage: React.FC<IProps> = ({
  guestInviteToken,
  createArchitect,
  intl,
  queryParams,
  location,
  canLogin,
  updateEmailValidatedOnArchitectAccount,
  displayRegisterUser,
  setDisplayRegisterUser,
  pushRouter,
  login,
  redirect,
  updateArchitect
}) => {

  const dispatch = useDispatch();
  const authToken = useSelector((state: IRootState) => state.authState.token)
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState<any>(null);
  useEffect(() => {
    Mixpanel.track(MixpanelEvents.RegisterPageViewed);
    if (authToken) {
      dispatch(push('/'));
    }
    // if (!(queryParams && queryParams.unlock_register && queryParams.unlock_register == 'true')) {
    //   dispatch(push('/login'));
    // }
  }, [dispatch]);

  useEffect(() => {
    /* global google */
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: '892022811072-g49j13o9gith6frtue6289b905ps2d7d.apps.googleusercontent.com',
      callback: (response: any) => handleGoogleSignUp(response),
    });
    setGoogleButtonWrapper(createFakeGoogleWrapper());
  }, []);

  const handleGoogleSignUp = (response: any) => {
    const userObj = jwt_decode(response.credential);
    const jsonString = JSON.stringify(response);
    const encodedJsonString = encodeURIComponent(jsonString);
    canLogin(userObj.email).then((res: { canLogin: boolean }) => {
      if (res.canLogin) {
        setDisplayRegisterUser(false);
        dispatch(push(`/login?loginWithGoogle=${encodedJsonString}`));
      } else {
        setUser(prevUser => ({
          ...prevUser,
          email: userObj.email,
          firstName: userObj.given_name,
          lastName: userObj.family_name
        }));
      }
    });
  }

  function checkLoginState() {
    window.FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    }.bind(this));
  }

  function statusChangeCallback(response) {
    if (response.status === 'connected') {
      fetchUserInfo();
    }
  }

  function fetchUserInfo() {
    window.FB.api('/me', { fields: 'first_name,last_name,email' }, function (response) {
      if (response.email) {
        handleFacebookLogin(response);
      }
    });
  }

  function handleClick() {
    window.FB.login(checkLoginState(), { scope: 'email' });
  }

  const handleFacebookLogin = (response: any) => {
    const jsonString = JSON.stringify(response);
    const encodedJsonString = encodeURIComponent(jsonString);
    canLogin(response.email).then((res: { canLogin: boolean }) => {
      if (res.canLogin) {
        setDisplayRegisterUser(false);
        dispatch(push(`/login?loginWithFacebook=${encodedJsonString}`));
      } else {
        setUser(prevUser => ({
          ...prevUser,
          email: response.email,
          firstName: response.first_name,
          lastName: response.last_name
        }));
      }
    });
  }

  const createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement("div");
    // Or you can simple hide it in CSS rule for custom-google-button
    googleLoginWrapper.style.display = "none";
    googleLoginWrapper.classList.add("custom-google-button");

    // Add the wrapper to body
    document.body.appendChild(googleLoginWrapper);

    //@ts-ignore
    google.accounts.id.renderButton(googleLoginWrapper, {
      theme: "outline",
    });
    const googleLoginWrapperButton =
      googleLoginWrapper.querySelector("div[role= button]");
    return {
      click: () => {
        googleLoginWrapperButton && googleLoginWrapperButton.click();
      },
    };
  };

  const [account, setAccount] = useState<AccountDTO | null>(null)
  const [user, setUser] = useState<ArchitectCreationDTO>({
    companyName: '',
    vatCode: '',
    firstName: location && location.state && location.state.firstName ? location.state.firstName : '',
    lastName: location && location.state && location.state.lastName ? location.state.lastName : '',
    password: '',
    email: location && location.state && location.state.email ? location.state.email : '',
    address: {
      street: '',
      city: '',
      zipcode: '',
      country: 'FR',
    },
    siret: '',
    type: ArchitectType.Architect,
    description: '',
    enabled: true,
  });

  const [stepIndex, setStepIndex] = useState<number>(-1);

  const [stepsList, setStepsList] = useState<IRegisterStepItem[]>([
    {
      step: RegisterStep.SUBSCRIPTION,
      completed: false,
      component: RegisterSubscriptionForm
    },
    // {
    //   step: RegisterStep.ARCHITECT,
    //   completed: false,
    //   component: RegisterArchitectForm
    // }
  ]);


  const stepsListModal = [
    {
      step: RegisterStep.SUBSCRIPTION,
      completed: false,
      component: RegisterSubscriptionFormModal
    },
    // {
    //   step: RegisterStep.ARCHITECT,
    //   completed: false,
    //   component: RegisterArchitectFormModal
    // }
  ];
  const [errors, setErrors] = useState<IErrors>({});
  const [isRegistered, setIsRegistered] = useState(false);
  const [displayPaymentForm, setDisplayPaymentForm] = useState(false);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [newArchitect, setNewArchitect] = useState(null)
  const [newTrialUser, setNewTrialUser] = useState(false)
  const euCuntries = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU',
    'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'XI']

  // const checkVatValidity = async (values) => {
  // if (!values.vadCode || values.vatCode == '' || (values.address && values.address.country && !euCuntries.includes(values.address.country))) {
  //   setUser({ ...values, enabled: true })
  // } else if (values.address && values.address.country && euCuntries.includes(values.address.country)) {
  //   let valid = await validateVatCode(values.address.country, values.vatCode.slice(2))
  //   if (valid) {
  //     setUser({ ...values, enabled: valid })
  //   }
  // }
  // }

  const updateForm = useCallback(
    async (architect: ArchitectCreationDTO) => {
      setUser(architect);
      //Save the user in the session storage so we can login on payment success
      const encryptedEmail = crypto.AES.encrypt(user.email, process.env.REACT_APP_CRYPTO_KEY).toString();
      const encryptedPassword = crypto.AES.encrypt(user.password, process.env.REACT_APP_CRYPTO_KEY).toString();

      // Store encrypted data in localStorage
      localStorage.setItem('email', encryptedEmail);
      localStorage.setItem('password', encryptedPassword);
      if (stepIndex + 1 < stepsList.length) {
        if (stepIndex >= 0) {
          setStepsList(
            stepsList.map((stepItem, index) => ({
              ...stepItem,
              completed: index === stepIndex ? true : stepItem.completed
            }))
          );
        }
        setStepIndex(stepIndex + 1);
        setErrors({});
      } else {
        if (intl.locale != null && standardToLanguageEnum[intl.locale] != null) {
          architect.language = standardToLanguageEnum[intl.locale];
        }
        // await checkVatValidity(architect)
        //TODO check why street doesnt have the deafult value
        if (!architect.address.street) {
          architect.address.street = "";
        }
        if (architect.subscriptionType && architect.subscriptionType.includes('Pack')) {
          getPacks().then(async (p) => {
            const pack = p.find(p => p.downloads == architect.subscriptionType.replace('Pack', ''))
            await createPaymentIntentForSubscription(pack.price, architect.email, architect.subscriptionType, architect);
          })
        }
        else if (architect.subscriptionType != ArchitectSubscriptionType.Standard10) {
          const subscription = await getSubscription(architect.subscriptionType)
          await createPaymentIntentForSubscription(subscription.price, architect.email, architect.subscriptionType, architect)
        }
        else {
          const subscription = await getSubscription(ArchitectSubscriptionType.Standard10)
          const currentDate = new Date();
          const oneMonthLater = new Date();
          oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
          setUser({
            ...architect,
            enabled: true,
            subscriptionStartDate: new Date(),
            subscription: subscription,
            subscriptionNextPaymentDate: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
          })
          setNewTrialUser(true)
        }
      }
    },
    [stepIndex, stepsList, createArchitect, guestInviteToken, intl.locale]
  );

  const addTrialToArchitect = async () => {
    const subscription = await getSubscription(ArchitectSubscriptionType.Standard10)
    const currentDate = new Date();
    const oneMonthLater = new Date();
    oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
    const payload = {
      subscriptionStartDate: new Date(),
      subscription: subscription,
      subscriptionNextPaymentDate: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
    }

    await updateArchitect(newArchitect.id, payload);

    setIsRegistered(true);
  }
  const onBack = () => setStepIndex(stepIndex => stepIndex - 1);

  const FormComponent = stepsList[Math.max(stepIndex, 0)] && stepsList[Math.max(stepIndex, 0)].component;
  const FormComponentModal = stepsListModal[Math.max(stepIndex, 0)] && stepsListModal[Math.max(stepIndex, 0)].component;

  useEffect(() => {
    configPayment().then(async (result) => {
      const { publishableKey } = await result;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    if (clientSecret || newTrialUser) {
      createArchitect(user)
        .then(async (arch) => {
          // After creation we link the previous anonymous data from mixpanel
          Mixpanel.alias(user.email);
          Mixpanel.track(MixpanelEvents.UserRegistered);
          setAccount(arch.account)
          setNewArchitect(arch)
          setCookie('registerValidated', user.enabled)
          sendNewUserEmail(arch.id)
          if (newTrialUser) {
            setIsRegistered(true)
          }
        })
        .catch(e => {
          if (e.request && e.request.status === 409) {
            setErrors(errors => ({
              ...errors,
              isEmailAlreadyUsed: true
            }));
          }
        });
    }
  }, [clientSecret, newTrialUser])

  useEffect(() => {
    if (isRegistered) {
      setDisplayPaymentForm(false)
    }
    if (isRegistered && user.enabled && newArchitect) {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      updateEmailValidatedOnArchitectAccount(newArchitect.id).then(async () => {
        removeCookie('registerValidated')
        await login(user.email, user.password);
        setDisplayRegisterUser(false)
        redirect('/');
      });

      sendAccountValidatedWithoutSubscriptionEmail(newArchitect.id)
    }
  }, [isRegistered])

  useEffect(() => {
    if (displayRegisterUser) {
      const alreadyRegistered = getCookie('registerValidated')
      if (alreadyRegistered == 'true') {
        setIsRegistered(true)
        user.enabled = true
        setStepIndex(1)
      } else if (alreadyRegistered == 'false') {
        setIsRegistered(true)
        setStepIndex(1)
      } else {
        setStepIndex(-1)
      }
    }

    if (displayRegisterUser && !isRegistered) {
      setUser({
        companyName: '',
        vatCode: '',
        firstName: location && location.state && location.state.firstName ? location.state.firstName : '',
        lastName: location && location.state && location.state.lastName ? location.state.lastName : '',
        password: '',
        email: location && location.state && location.state.email ? location.state.email : '',
        address: {
          street: '',
          city: '',
          zipcode: '',
          country: 'FR',
        },
        siret: '',
        type: ArchitectType.Architect,
        description: '',
        enabled: true,
      })
    }
    if (!displayRegisterUser && isRegistered) {
      pushRouter(Routes.Catalog)
    }
  }, [displayRegisterUser])

  const createPaymentIntentForSubscription = async (amount, email, subscriptionType, architect) => {
    createPaymentIntent(amount, email, subscriptionType, architect).then(async (result) => {
      if (result.error) {
        setErrors(errors => ({
          ...errors,
          stripe: result.error.message.replace(/au_abn|eu_vat|ca_bn|cl_tin|is_vat|in_gst|jp_cn|mx_rfc|nz_gst|no_vat|ru_inn|sa_vat|sg_gst|za_vat|kr_brn|ch_vat|tr_tin|ae_trn|gb_vat/g, intl.formatMessage({ id: 'register.vatCode' }))
        }));
        return;
      }
      var { clientSecret } = await result;
      setClientSecret(clientSecret);
      setDisplayPaymentForm(true)
    });
  }
  // const createPaymentIntentForTrialSubscription = async (amount, email, architect) => {
  //   createPaymentIntentTrial(amount, email, architect).then(async (result) => {
  //     if (result.error) {
  //       setErrors(errors => ({
  //         ...errors,
  //         stripe: result.error.message
  //       }));
  //       return;
  //     }
  //     var { clientSecret } = await result;
  //     setClientSecret(clientSecret);
  //     setDisplayPaymentForm(true)
  //   });
  // }
  const goBackModal = (index) => {
    if (index < stepIndex && !isRegistered) {
      setStepIndex(index)
    }
  }


  const isShowingIntro = stepIndex < 0;
  const isShowingPlan = stepIndex == 0;
  let title;
  let subtitle;
  if (isShowingIntro) {
    title = intl.formatMessage({ id: 'register.title' });
    subtitle = intl.formatMessage({ id: 'register.subtitle' });
  } else if (isRegistered) {
    title = intl.formatMessage({ id: 'register.end.title' });
  } else if (isShowingPlan) {
    title = intl.formatMessage({ id: 'register.plan.title' });
    subtitle = intl.formatMessage({ id: 'register.subtitle' });
  }
  else {
    title = intl.formatMessage({ id: 'register.checkout.title' });
    subtitle = intl.formatMessage({ id: 'register.checkout.subtitle' });
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'register.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'register.seo.description' })} />
      </Helmet>
      {!isRegistered && !displayRegisterUser &&
        <div className={styles.registerHeader}>
          <div className={styles.radioRegisterStep}>
            <div className={styles.wrapper}>
              <div className={styles.option}>
                <input className={styles.input} type="radio" name="registerStep" value='-1' checked={stepIndex === -1} />
                <div className={styles.btn}>
                  <span className={styles.span}>1</span>
                </div>
              </div>
              <div className={styles.option}>
                <input className={styles.input} type="radio" name="registerStep" value='0' checked={stepIndex === 0} />
                <div className={styles.btn}>
                  <span className={styles.span}>2</span>
                </div>
              </div>
              {/*<div className={styles.option} >*/}
              {/*  <input className={styles.input} type="radio" name="registerStep" value='1' checked={stepIndex === 1} />*/}
              {/*  <div className={styles.btn}>*/}
              {/*    <span className={styles.span}>3</span>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
          {stepIndex !== -1 &&
            <div className={styles.backSection} onClick={onBack}>
              <Icon type="left" />
            Back
            </div>
          }
        </div>
      }
      {displayRegisterUser ?
        <div className={stepIndex === 0 && !isRegistered ? `${styles.registerModal} ${styles.verticalLine}` : styles.registerModal}>
          {displayPaymentForm &&
            <Elements stripe={stripePromise} options={{ clientSecret }} >
              <CheckoutForm user={user} account={account} onStripeCancel={() => addTrialToArchitect()} register />
            </Elements>
          }
          <div className={styles.radioRegisterStepModal}>
            <div className={styles.wrapper}>
              <div className={styles.option} onClick={() => goBackModal(-1)}>
                <input className={styles.input} type="radio" name="registerStep" value='-1' checked={stepIndex === -1} />
                <div className={styles.btn}>
                  <span className={styles.span}>1</span>
                </div>
              </div>
              <div className={styles.option} onClick={() => goBackModal(0)}>
                <input className={styles.input} type="radio" name="registerStep" value='0' checked={stepIndex === 0} />
                <div className={styles.btn}>
                  <span className={styles.span}>2</span>
                </div>
              </div>
              {/*<div className={styles.option} onClick={() => goBackModal(1)}>*/}
              {/*  <input className={styles.input} type="radio" name="registerStep" value='1' checked={stepIndex === 1} />*/}
              {/*  <div className={styles.btn}>*/}
              {/*    <span className={styles.span}>3</span>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
          {!isShowingPlan && (
            <div className={styles.imageContainer}>
              <img src={bg} />
            </div>
          )}
          <div className={styles.registerModalStep}>
            {stepIndex != 1 && stepIndex != 0 &&
              <>
                <h1>{title}</h1>
                <span>{subtitle}</span>
              </>
            }
            <div className={styles.registerModalForm}>
              {isRegistered && !user.enabled && (
                <div className={styles.end}>
                  <FormattedMessage
                    id="register.end.text"
                    values={{
                      p: (...chunks: string[]) => <p>{chunks}</p>
                    }}
                  />
                </div>
              )}
              {isRegistered && user.enabled && (
                <div className={styles.end}>
                  <FormattedMessage
                    id="register.end.textValidation"
                    values={{
                      p: (...chunks: string[]) => <p>{chunks}</p>
                    }}
                  />
                </div>
              )}
              {isShowingIntro && !isRegistered && <RegisterInitFormModal initialValues={user} onSubmit={updateForm} googleButtonWrapper={googleButtonWrapper} handleFacebook={handleClick} />}
              {!isShowingIntro && !isRegistered && (
                <FormComponentModal
                  initialValues={{ ...user, cgu: false, policy: false, subscriptionType: user.subscriptionType }}
                  errors={errors}
                  isRegistered={isRegistered}
                  onBack={onBack}
                  onSubmit={updateForm}
                />
              )}
            </div>
          </div>
        </div>
        :
        <ConnectionLayout
          title={title}
          subtitle={subtitle}
          className={classnames({
            [styles.container]: !isRegistered,
            [styles.stepZeroContainer]: stepIndex === 0
          })}
          displayImage={stepIndex !== 0}
        >
          {displayPaymentForm &&
            <Elements stripe={stripePromise} options={{ clientSecret }} >
              <CheckoutForm user={user} account={account} onStripeCancel={() => addTrialToArchitect()} register />
            </Elements>
          }
          {isShowingIntro && <RegisterInitForm initialValues={user} onSubmit={updateForm} googleButtonWrapper={googleButtonWrapper} handleFacebook={handleClick} />}
          {isRegistered && !user.enabled && (
            <div className={styles.end}>
              <FormattedMessage
                id="register.end.text"
                values={{
                  p: (...chunks: string[]) => <p>{chunks}</p>
                }}
              />
            </div>
          )}
          {isRegistered && user.enabled && (
            <div className={styles.end}>
              <FormattedMessage
                id="register.end.textValidation"
                values={{
                  p: (...chunks: string[]) => <p>{chunks}</p>
                }}
              />
            </div>
          )}

          {!isShowingIntro && !isRegistered && (
            <FormComponent
              initialValues={{ ...user, cgu: false, policy: false, subscriptionType: user.subscriptionType }}
              errors={errors}
              isRegistered={isRegistered}
              onBack={onBack}
              onSubmit={updateForm}
            />
          )}
        </ConnectionLayout>
      }
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  guestInviteToken: state.brandInvitationState.guestInviteToken,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string },
  location: state.router.location,
  displayRegisterUser: state.userState.displayRegisterUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createArchitect: (architect: ArchitectCreationDTO) =>
    dispatch(architectActions.thunks.createValue(architect)),
  updateEmailValidatedOnArchitectAccount: (id: string) => {
    return dispatch(
      architectActions.thunks.patchValue(id,
        {
          account: {
            emailValidated: true
          }
        }
      )
    )
  },
  updateArchitect: (id: string, payload: any) => { return dispatch(architectActions.thunks.patchValue(id, payload)) },
  pushRouter: (path: string) => dispatch(push(path)),
  canLogin: (email: string) => dispatch(auth.canLoginWithGoogle(email)),
  login: (email: string, password: string, type?: string) =>
    dispatch(auth.login(email, password, Apps.Backoffice, undefined, type)).then(r => {
      Mixpanel.identify(email)
      Mixpanel.track(MixpanelEvents.UserLoggedIn)
      Mixpanel.track(MixpanelEvents.UserConnected)
    }),
  redirect: (location: string) => dispatch(push(location)),
  setDisplayRegisterUser: (displayRegister: boolean) =>
    dispatch(user.setRegisterUser(displayRegister))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RegisterPage));
