"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_OBJECT_PRODUCT_VARIATIONS"] = "SET_OBJECT_PRODUCT_VARIATIONS";
  ActionsTypes["SET_OBJECT_PRODUCT_VARIATIONS_LOADING"] = "SET_OBJECT_PRODUCT_VARIATIONS_LOADING";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));