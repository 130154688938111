import { AccountType, BrandUserDTO, AddsomeUserDTO } from '@addsome/dtos'
import { Button, Popconfirm, Table, Theme, Switch } from '@addsome/ui-kit'
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { brandUser as brandUserActions } from '@addsome/redux-store'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import style from './BrandUsersTable.module.scss'

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  WrappedComponentProps & {
    brandUsers: BrandUserDTO[]
    total: number
    currentPage: number
    pageSize: number
    loading: boolean
    onTableChange: (
      pagination: PaginationConfig,
      filter: Record<keyof BrandUserDTO, string[]>,
      sorter: SorterResult<BrandUserDTO>
    ) => void
    onDelete: (id: string) => void
    sortBy?: { [key: string]: number }
  }
const isMobile = window.innerWidth <= 600

class BrandUsersTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<BrandUserDTO>> = [
    {
      title: <FormattedMessage id="brandusertable.name" />,
      dataIndex: 'account.firstName',
      key: 'firstName',
      render: (value, record) => (
        <Link to={`/brand-users/${record.id}/edit`}>
          {value} {record.account.lastName}
        </Link>
      ),
      sorter: true
    },
    {
      title: <FormattedMessage id="brandusertable.brand" />,
      render: (v, record) =>
        record.brands.length > 0 && record.brands.map(b =>
          <div key={b.id}>{b.name}</div>
        )
    },
    {
      title: 'Mail',
      dataIndex: 'account.email',
      key: 'email',
      sorter: true
    },
    {
      title: 'Email',
      dataIndex: 'account.emailValidated',
      key: 'emailValidated',
      render: (_, record) => (
        <Switch
          checked={record.account.emailValidated}
          onChange={() => this.props.toggleBrandUserEmail(record.id, record.account.emailValidated)}
        />
      )
    },
    {
      title: <FormattedMessage id="architecttable.account" />,
      dataIndex: 'account.enabled',
      key: 'account.enabled',
      render: (value, record) => (
        <Switch
          checked={record.account.enabled}
          onChange={() => this.props.toggleBrandUserAccount(record.id, value)}
        />
      )
    },
    {
      title: '',
      render: (v, record) =>
        this.canDelete() && (
          !record.deletedAt ? (
            <Popconfirm
              title={<FormattedMessage id="global.confirmdelete" />}
              onConfirm={() => this.props.onDelete(record.id)}
              okText={<FormattedMessage id="global.yes" />}
              cancelText={<FormattedMessage id="global.no" />}
              placement="left"
            >
              <Button
                theme={Theme.GHOST}
                shape="circle"
                icon="delete"
                title={this.props.intl.formatMessage({ id: 'global.delete' })}
                aria-label={`Supprimer ${record.account.lastName}`}
              />
            </Popconfirm>
          ) : (
            <FormattedMessage id='userspage.deleted' />
          )
        ),
      width: '5%'
    }
  ]
  private mobileColumns: Array<ColumnProps<AddsomeUserDTO>> = [
    {
      title: <FormattedMessage id="brandusertable.name" />,
      dataIndex: 'account.firstName',
      key: 'firstName',
      render: (value, record) => (
        <Link to={`/brand-users/${record.id}/edit`}>
          {value} {record.account.lastName}
        </Link>
      ),
      sorter: true
    },
    {
      title: <FormattedMessage id="brandusertable.brand" />,
      render: (v, record) =>
        record.brands.length > 0 && record.brands.map(b =>
          <div key={b.id}>{b.name}</div>
        )
    },
    {
      title: 'Mail',
      dataIndex: 'account.email',
      key: 'email',
      sorter: true,
      width: 'auto'
    },
    // {
    //   title: <FormattedMessage id="architecttable.account" />,
    //   dataIndex: 'account.enabled',
    //   key: 'account.enabled',
    //   render: (value, record) => (
    //     <Switch
    //       checked={record.account.enabled}
    //       onChange={() => this.props.toggleBrandUserAccount(record.id, value)}
    //     />
    //   )
    // },
    {
      title: '',
      render: (v, record) =>
        this.canDelete() && (
          !record.deletedAt ? (
            <Popconfirm
              title={<FormattedMessage id="global.confirmdelete" />}
              onConfirm={() => this.props.onDelete(record.id)}
              okText={<FormattedMessage id="global.yes" />}
              cancelText={<FormattedMessage id="global.no" />}
              placement="left"
            >
              <Button
                theme={Theme.GHOST}
                shape="circle"
                icon="delete"
                title={this.props.intl.formatMessage({ id: 'global.delete' })}
                aria-label={`Supprimer ${record.account.lastName}`}
              />
            </Popconfirm>
          ) : (
            <FormattedMessage id='userspage.deleted' />
          )
        ),
      width: '5%'
    }
  ]

  public render() {
    const columns = [...(isMobile ? this.mobileColumns : this.columns)]
    const pagination: PaginationConfig = {
      total: this.props.total,
      pageSize: this.props.pageSize,
      current: this.props.currentPage,
      showTotal: total =>
        `${total} ${this.props.intl.formatMessage({ id: 'brandusertable.results' })}`
    }

    // Init sort
    if (this.props.sortBy) {
      const sortBy = this.props.sortBy
      columns.forEach(column => {
        const orderValue = column.key
          ? sortBy[column.key]
          : (column.dataIndex && sortBy[column.dataIndex]) || 0
        if (orderValue) {
          column.defaultSortOrder = orderValue > 0 ? 'ascend' : 'descend'
        }
      })
    }

    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.brandUsers}
          rowKey="id"
          pagination={pagination}
          loading={this.props.loading}
          onChange={this.props.onTableChange}
          rowClassName={this.getRowClassName}
        />
      </div>
    )
  }

  private getRowClassName = (record: BrandUserDTO) => {
    return record.deletedAt ? style.deleted : ''
  }

  private canDelete = () => {
    return this.props.accountType === AccountType.AddsomeUser && (this.props.connectedUser as AddsomeUserDTO).perform_deletions
  }
}
const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  connectedUser: state.userState.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  push: (location: string) => dispatch(push(location)),
  toggleBrandUserAccount: (id: string, isEnabled: boolean) => {
    dispatch(
      brandUserActions.thunks.patchValue(id, {
        account: {
          enabled: !isEnabled
        }
      })
    )
  },
  toggleBrandUserEmail: (id: string, isEnabled: boolean) => {
    dispatch(
      brandUserActions.thunks.patchValue(id, {
        account: {
          emailValidated: !isEnabled
        }
      })
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BrandUsersTable))
