"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Rendering;

(function (Rendering) {
  Rendering["Hdrp"] = "hdrp";
  Rendering["Lwrp"] = "lwrp";
  Rendering["Legacy"] = "legacy";
})(Rendering = exports.Rendering || (exports.Rendering = {}));