import React, { useMemo } from "react";
import { Form } from 'antd';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useIntl } from 'react-intl'
import { IStepFormProps } from '../../MultiStepModal/MultiStepModal'
import { AddsomeUserCreationDTO, AddsomeUserUpdateDTO } from '@addsome/dtos';
import MultiStepNavigation from '../../MultiStepModal/MultiStepNavigation/MultiStepNavigation';
import AdminInformationFieldGroup from "../AdminInformationFieldGroup/AdminInformationFieldGroup";
import styles from './AdminInformationStep.module.scss';

type IProps = IStepFormProps<AddsomeUserCreationDTO>

const AdminInformationStep: React.FC<IProps> = ({ onPrevious, onNext, value, first, last }) => {
  const intl = useIntl()

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().trim().required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        lastName: yup.string().trim().required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        email: yup.string().email(intl.formatMessage({ id: 'validation.email.notValid' })).trim().required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        password: yup.string().required().min(5)
      }),
    [intl]
  )

  return (
    <Formik
      initialValues={value}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!values) return
        setSubmitting(false)
        onNext(values)
      }}
    >
      {
        formik => (
          <Form onSubmit={formik.handleSubmit} noValidate layout="horizontal">
            <div className={styles.formBody}>
              <AdminInformationFieldGroup addsomeUser={value} formik={formik} />
            </div>
            <MultiStepNavigation
              showPrevious={!first}
              finish={last}
              nextDisabled={Object.values(formik.errors).length > 0}
              onPrevious={onPrevious}
              onNext="submit"
            />
          </Form>
        )
      }
    </Formik>
  );
};

export default AdminInformationStep;