import { ObjectProductCreationDTO, ObjectProductType, ProductCreationDTO } from '@addsome/dtos'
import { Form } from 'antd'
import { Formik } from 'formik'
import React, { useMemo } from 'react'
import * as yup from 'yup'
import {
  convertObjectProductForAPI,
  convertObjectProductForFront
} from '../../../services/frontObjectProduct'
import { IStepFormProps } from '../../MultiStepModal/MultiStepModal'
import MultiStepNavigation from '../../MultiStepModal/MultiStepNavigation/MultiStepNavigation'
import ObjectProductFieldsGroup from '../ObjectProductFieldsGroup.tsx/ObjectProductFieldsGroup'
import styles from './ProductCreation.module.scss'

const DimensionsStep: React.FC<IStepFormProps<ProductCreationDTO>> = ({
  onPrevious,
  onNext,
  value,
  first,
  last
}) => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        width: yup
          .number()
          .positive()
          .typeError('')
          .nullable(),
        height: yup
          .number()
          .positive()
          .typeError('')
          .nullable(),
        depth: yup
          .number()
          .positive()
          .typeError('')
          .nullable(),
        seatHeight: yup
          .number()
          .positive()
          .typeError('')
          .nullable(),
        diameter: yup
          .number()
          .positive()
          .typeError('')
          .nullable(),
        weight: yup
          .number()
          .positive()
          .typeError('')
          .nullable()
      }),
    []
  )

  const initialValue: ObjectProductCreationDTO = useMemo(
    () =>
      value.objectProduct
        ? convertObjectProductForFront(value.objectProduct)
        : {
          type: ObjectProductType.Lighting,
          data3D: { anchor: null }
        },
    [value]
  )

  return (
    <Formik
      initialValues={{ ...initialValue }}
      validationSchema={validationSchema}
      onSubmit={(values: ObjectProductCreationDTO, { setSubmitting }) => {
        if (!values) return
        setSubmitting(false)
        onNext({ ...value, objectProduct: convertObjectProductForAPI(values) })
      }}
    >
      {formik => (
        <Form layout="horizontal" onSubmit={formik.handleSubmit} noValidate>
          <div className={styles.formBody}>
            <ObjectProductFieldsGroup objectProduct={formik.values} formik={formik} />
          </div>

          <MultiStepNavigation
            showPrevious={!first}
            finish={last}
            nextDisabled={Object.values(formik.errors).length > 0}
            onPrevious={onPrevious}
            onNext="submit"
          />
        </Form>
      )}
    </Formik>
  )
}

export default DimensionsStep
