import { BrandSuggestionDTO } from '@addsome/dtos';
import {
  brandsSuggests,
  brandTag,
  colorTag,
  objectProductCategories,
  productMaterialTag,
  productPrices,
  roomTag,
  styleTag,
  tagsSelectors
} from '@addsome/redux-store';
import {
  BrandFilter,
  ColorFilter,
  COUNTRY_CODE_TO_INTL_ID,
  FilterType,
  IFilter,
  IFilterButton,
  MaterialsFilter,
  ObjectFilter,
  OriginFilter,
  Have3dFilter,
  PriceFilter,
  DimensionsFilter,
  Range,
  RoomFilter,
  StyleFilter,
  translateCountries,
  Dimensions
} from '@addsome/ui-kit';
import { push } from 'connected-react-router';
import { parse, stringify } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { isArray } from 'util';
import {
  BrandIcon,
  ColorIcon,
  DollarsIcon,
  MaterialIcon,
  ObjectIcon,
  OriginIcon,
  RoomIcon,
  StyleIcon,
  Have3DIcon,
  SizeIcon
} from '../../components/SVG/Icons';
import { IRootState } from '../../redux';
import images from '../../assets/materials';

export default function useFilters(filtersWanted: FilterType[], searchQuery?: string) {
  const [filterButtons, setFilterButtons] = useState<IFilterButton[]>([]);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [visibleButtonsIds, setVisibleButtonsIds] = useState<number[]>([]);
  const [filtersForAPI, setFiltersForAPI] = useState<{ [key: string]: string[]; }>();
  const [promotedBrands, setPromotedBrands] = useState<BrandSuggestionDTO[]>([]);
  const intl = useIntl();

  const [dataFetchingStarted, setDataFetchingStarted] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [firstUrlParsingDone, setFirstUrlParsingDone] = useState(false);
  const [closeFilter, setCloseFilter] = useState(false)

  const dispatch: ThunkDispatch<IRootState, {}, AnyAction> = useDispatch();

  // initial actions dispatching for essential data fetching
  useEffect(() => {
    dispatch(objectProductCategories.thunks.fetchValues());
    dispatch(productMaterialTag.thunks.fetchValues());
    dispatch(styleTag.thunks.fetchValues());
    dispatch(colorTag.thunks.fetchValues());
    dispatch(roomTag.thunks.fetchValues());
    dispatch(brandTag.fetchBrandLexique(intl.formatMessage({ id: 'promotedBrands.errorRetrievingPackshots' })));
    dispatch(productPrices.fetchPrices(intl.formatMessage({ id: 'price.errorRetrievingPrice' })));

    dispatch(
      brandsSuggests.fetchBrandsSuggests({
        filters: { promoted: ['true'], haveProducts: ['true'] },
        take: 20
      })
    ).then(b => {
      setPromotedBrands(b.sort(() => Math.random() - 0.5));
    });
  }, [dispatch, intl]);

  const { filterData, loading, router } = useSelector((state: IRootState) => ({
    filterData: {
      brandLexique: state.brandTagState.brandLexique,
      objectProductCategories: state.objectProductCategoriesState.values,
      styleTags: state.styleTagState.values,
      roomTags: state.roomTagState.values,
      prices: state.productPricesState && state.productPricesState.prices,
      productMaterialTags: state.productMaterialTagState.values,
      colorTags: state.colorTagState.values
    },
    loading: {
      objectProductCategories: state.objectProductCategoriesState.loading,
      roomTag: state.roomTagState.loading,
      styleTag: state.styleTagState.loading,
      productMaterialTag: state.productMaterialTagState.loading,
      colorTag: state.colorTagState.loading,
      brandLexique: state.brandTagState.brandLexiqueLoading,
      promotedBrands: state.brandTagState.promotedBrandsLoading
    },
    router: state.router
  }));

  const updateUrl = useCallback((search: string) => dispatch(push({ search })), [dispatch]);

  // this hook watches for loading state changes, and when data has finished fetching, set a boolean
  useEffect(() => {
    const loadings = Object.values(loading);
    if (loadings.includes(true)) {
      setDataFetchingStarted(true);
    }
    if (dataFetchingStarted && !loadings.includes(true)) {
      setDataFetched(true);
    }
  }, [dataFetchingStarted, loading]);

  const {
    getBrandSuggestionFromId,
    getObjectProductCategoryFromId,
    getStyleTagFromId,
    getRoomTagFromId,
    getMaterialTagFromId,
    getColorTagFromId
  } = useSelector((state: IRootState) => ({
    getBrandSuggestionFromId: (brandId: string) =>
      tagsSelectors.getBrandSuggestionFromId(brandId, state),
    getObjectProductCategoryFromId: (objectProductCategoryId: string) =>
      tagsSelectors.getObjectProductCategoryFromId(objectProductCategoryId, state),
    getStyleTagFromId: (styleTagId: string) => tagsSelectors.getStyleTagFromId(styleTagId, state),
    getRoomTagFromId: (roomTagId: string) => tagsSelectors.getRoomTagFromId(roomTagId, state),
    getMaterialTagFromId: (materialTagId: string) =>
      tagsSelectors.getMaterialTagFromId(materialTagId, state),
    getColorTagFromId: (colorTagId: string) => tagsSelectors.getColorTagFromId(colorTagId, state)
  }));

  // when data has finished fetching, parse URL filters and sets filters into this hook. only done once.
  useEffect(() => {
    if (!dataFetched || firstUrlParsingDone) return;

    if (router.location.search) {
      const querystringParsedParams = parse(router.location.search.substring(1), {
        arrayFormat: 'comma'
      });

      // turns url query params into objects like { filterId: [value(s)] }
      const _queryFilters: { [key: string]: string[]; } = Object.keys(
        querystringParsedParams
      ).reduce((acc, key) => {
        return {
          ...acc,
          [key]: isArray(querystringParsedParams[key])
            ? querystringParsedParams[key]
            : [querystringParsedParams[key]]
        };
      }, {});

      if (!_queryFilters) return;

      const newFilters: IFilter[] = [];
      let minPrice: number | undefined;
      let maxPrice: number | undefined;
      let widthMin: number | undefined;
      let widthMax: number | undefined;
      let heightMin: number | undefined;
      let heightMax: number | undefined;
      let depthMin: number | undefined;
      let depthMax: number | undefined;
      Object.keys(_queryFilters).map(key => {
        _queryFilters[key].map(queryFilter => {
          switch (key) {
          case 'brandIds':
            const brand = getBrandSuggestionFromId(queryFilter);
            if (brand) {
              newFilters.push({ type: FilterType.BRAND, label: brand.name, value: brand.id });
            }
            return;
          case 'priceMin':
            minPrice = parseInt(queryFilter, 10);
            if (maxPrice !== undefined && minPrice !== undefined) {
              newFilters.push({
                type: FilterType.PRICE,
                label: `${minPrice}-${maxPrice}€`,
                value: [minPrice, maxPrice]
              });
            }
            return;
          case 'priceMax':
            maxPrice = parseInt(queryFilter, 10);
            if (maxPrice !== undefined && minPrice !== undefined) {
              newFilters.push({
                type: FilterType.PRICE,
                label: `${minPrice}-${maxPrice}€`,
                value: [minPrice, maxPrice]
              });
            }
            return;
          case 'widthMin':
            widthMin = parseInt(queryFilter, 10);
            if (
              widthMax !== undefined &&
                widthMin !== undefined &&
                heightMin !== undefined &&
                heightMax !== undefined &&
                depthMin !== undefined &&
                depthMax !== undefined
            ) {
              newFilters.push({
                type: FilterType.DIMENSIONS,
                label: `${intl.formatMessage({ id: 'catalog.dimensionsFilter.width' })}: ${widthMin}-${widthMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.height' })}: ${heightMin}-${heightMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.depth' })}: ${depthMin}-${depthMax}cm `,
                value: [widthMin, widthMax, heightMin, heightMax, depthMin, depthMax]
              });
            }
            return;
          case 'widthMax':
            widthMax = parseInt(queryFilter, 10);
            if (
              widthMax !== undefined &&
                widthMin !== undefined &&
                heightMin !== undefined &&
                heightMax !== undefined &&
                depthMin !== undefined &&
                depthMax !== undefined
            ) {
              newFilters.push({
                type: FilterType.DIMENSIONS,
                label: `${intl.formatMessage({ id: 'catalog.dimensionsFilter.width' })}: ${widthMin}-${widthMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.height' })}: ${heightMin}-${heightMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.depth' })}: ${depthMin}-${depthMax}cm `,
                value: [widthMin, widthMax, heightMin, heightMax, depthMin, depthMax]
              });
            }
          case 'heightMin':
            heightMin = parseInt(queryFilter, 10);
            if (
              widthMax !== undefined &&
                widthMin !== undefined &&
                heightMin !== undefined &&
                heightMax !== undefined &&
                depthMin !== undefined &&
                depthMax !== undefined
            ) {
              newFilters.push({
                type: FilterType.DIMENSIONS,
                label: `${intl.formatMessage({ id: 'catalog.dimensionsFilter.width' })}: ${widthMin}-${widthMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.height' })}: ${heightMin}-${heightMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.depth' })}: ${depthMin}-${depthMax}cm `,
                value: [widthMin, widthMax, heightMin, heightMax, depthMin, depthMax]
              });
            }
            return;
          case 'heightMax':
            heightMax = parseInt(queryFilter, 10);
            if (
              widthMax !== undefined &&
                widthMin !== undefined &&
                heightMin !== undefined &&
                heightMax !== undefined &&
                depthMin !== undefined &&
                depthMax !== undefined
            ) {
              newFilters.push({
                type: FilterType.DIMENSIONS,
                label: `${intl.formatMessage({ id: 'catalog.dimensionsFilter.width' })}: ${widthMin}-${widthMax}cm ${intl.formatMessage({ id: 'catalog.dimensionsFilter.height' })}: ${heightMin}-${heightMax}cm ${intl.formatMessage({ id: 'catalog.dimensionsFilter.depth' })}: ${depthMin}-${depthMax}cm `,
                value: [widthMin, widthMax, heightMin, heightMax, depthMin, depthMax]
              });
            }
            return;
          case 'depthMin':
            depthMin = parseInt(queryFilter, 10);
            if (
              widthMax !== undefined &&
                widthMin !== undefined &&
                heightMin !== undefined &&
                heightMax !== undefined &&
                depthMin !== undefined &&
                depthMax !== undefined
            ) {
              newFilters.push({
                type: FilterType.DIMENSIONS,
                label: `${intl.formatMessage({ id: 'catalog.dimensionsFilter.width' })}: ${widthMin}-${widthMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.height' })}: ${heightMin}-${heightMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.depth' })}: ${depthMin}-${depthMax}cm `,
                value: [widthMin, widthMax, heightMin, heightMax, depthMin, depthMax]
              });
            }
            return;
          case 'depthMax':
            depthMax = parseInt(queryFilter, 10);
            if (
              widthMax !== undefined &&
                widthMin !== undefined &&
                heightMin !== undefined &&
                heightMax !== undefined &&
                depthMin !== undefined &&
                depthMax !== undefined
            ) {
              newFilters.push({
                type: FilterType.DIMENSIONS,
                label: `${intl.formatMessage({ id: 'catalog.dimensionsFilter.width' })}: ${widthMin}-${widthMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.height' })}: ${heightMin}-${heightMax}cm | ${intl.formatMessage({ id: 'catalog.dimensionsFilter.depth' })}: ${depthMin}-${depthMax}cm `,
                value: [widthMin, widthMax, heightMin, heightMax, depthMin, depthMax]
              });
            }
            return;
          case 'objectProductCategoryIds':
            const objectProductCategory = getObjectProductCategoryFromId(queryFilter);
            if (objectProductCategory) {
              newFilters.push({
                type: FilterType.OBJECT,
                label: objectProductCategory.name,
                value: objectProductCategory.id
              });
            }
            return;
          case 'objectProductParentCategoryIds':
            const objectProductParentCategory = getObjectProductCategoryFromId(queryFilter);
            if (objectProductParentCategory) {
              newFilters.push({
                type: FilterType.OBJECT_PARENT,
                label: objectProductParentCategory.name,
                value: objectProductParentCategory.id
              });
            }
            return;
          case 'styleTagIds':
            const styleTag = getStyleTagFromId(queryFilter);
            if (styleTag) {
              newFilters.push({
                type: FilterType.STYLE,
                label: styleTag.name,
                value: styleTag.id
              });
            }
            return;
          case 'materialTagIds':
            const materialTag = getMaterialTagFromId(queryFilter);
            if (materialTag) {
              newFilters.push({
                type: FilterType.MATERIAL,
                label: materialTag.name,
                value: materialTag.id
              });
            }
            return;
          case 'colorTagIds':
            const colorTag = getColorTagFromId(queryFilter);
            if (colorTag) {
              newFilters.push({
                type: FilterType.COLOR,
                label: colorTag.name,
                value: colorTag.id
              });
            }
            return;
          case 'roomTagIds':
            const roomTag = getRoomTagFromId(queryFilter);
            if (roomTag) {
              newFilters.push({
                type: FilterType.ROOM,
                label: roomTag.name,
                value: roomTag.id
              });
            }
            return;
          case 'madeIn':
            newFilters.push({
              type: FilterType.ORIGIN,
              label: intl.formatMessage({ id: COUNTRY_CODE_TO_INTL_ID[queryFilter] }),
              value: queryFilter
            });
            return;

          case 'haveObjectProductModelBundle':
            const have3d = queryFilter === 'true';
            newFilters.push({
              type: FilterType.HAVE3D,
              label: intl.formatMessage({
                id: have3d ? 'products.with3d' : 'products.without3d'
              }),
              value: have3d
            });
            return;
          case 'promoted':
            newFilters.push({
              type: FilterType.PROMOTED,
              label: intl.formatMessage({ id: 'products.promoted' }),
              value: queryFilter
            });
            return;
          case 'onlyVisible':
            newFilters.push({
              type: FilterType.ONLY_VISIBLE,
              label: intl.formatMessage({ id: 'catalog.filters.onlyVisible' }),
              value: queryFilter
            });
            return;
          case 'enabled':
            newFilters.push({
              type: FilterType.ENABLED,
              label: intl.formatMessage({ id: 'catalog.filters.enabled' }),
              value: queryFilter
            });
            return;
          case 'haveThumbnail':
            newFilters.push({
              type: FilterType.HAVE_THUMBNAIL,
              label: intl.formatMessage({ id: 'catalog.filters.haveThumbnail' }),
              value: queryFilter
            });
            return;
          case 'productIds':
            newFilters.push({
              type: FilterType.PRODUCT_ID,
              label: intl.formatMessage({ id: 'catalog.filters.product' }),
              value: queryFilter
            });
            return;
          default:
            return;
          }
        });
      });
      setFilters(newFilters);
    }
    setFirstUrlParsingDone(true);
  }, [
    router.location.search,
    dataFetched,
    firstUrlParsingDone,
    getBrandSuggestionFromId,
    getObjectProductCategoryFromId,
    getStyleTagFromId,
    getMaterialTagFromId,
    getColorTagFromId,
    getRoomTagFromId,
    intl
  ]);

  const addFilter = useCallback(
    (v: IFilter) => {
      if (filters.some(f => f.type === v.type && f.value === v.value)) return;

      setFilters([
        // We filter to only have one filter with the same value
        // (and only one price filter)
        ...filters.filter(filter => {
          if (v.type === FilterType.PRICE || v.type === FilterType.HAVE3D || v.type === FilterType.DIMENSIONS) {
            return filter.type !== v.type;
          }
          return filter.value !== v.value;
        }),
        v
      ]);
    },
    [filters]
  );

  const handleValidate = useCallback(
    (filterType: FilterType) => (label: string, value: [number, number] | Dimensions | string, type?:number) => {
      addFilter({ type: type ? type: filterType, label, value });
      setCloseFilter(true)
      setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== filterType));
    },
    [addFilter, visibleButtonsIds]
  );
  
  useEffect(() => {
    if (closeFilter) {
      setCloseFilter(false)
    }
  }, [closeFilter])

  // this generates filter buttons array according to the wanted filters array parameter.
  // also handles loading.
  useEffect(() => {
    const _filterButtons: any[] = [];;

    if (filtersWanted.includes(FilterType.PRICE)) {
      _filterButtons.push({
        id: FilterType.PRICE,
        type: FilterType.PRICE,
        icon: DollarsIcon,
        label: intl.formatMessage({ id: 'catalog.filters.budget' }),
        width: window.innerWidth > 756 ? 'auto' : '80%',
        height: window.innerWidth > 756 ? 'auto' : '22%',
        content: (
          <PriceFilter
            onValidate={handleValidate(FilterType.PRICE)}
            min={Math.floor(filterData.prices && filterData.prices.priceMin)}
            max={Math.round(filterData.prices && filterData.prices.priceMax)}
            applyLabel={intl.formatMessage({ id: 'catalog.filters.budget.apply' })}
            clearLabel={intl.formatMessage({ id: 'catalog.filters.budget.clear' })}
          />
        )
      });
    }

    if (filtersWanted.includes(FilterType.OBJECT)) {
      _filterButtons.push({
        id: FilterType.OBJECT,
        type: FilterType.OBJECT,
        icon: ObjectIcon,
        label: intl.formatMessage({ id: 'catalog.filters.object' }),
        width: window.innerWidth > 756 ? '50%' :'80%',
        height: '60%',
        content: (
          <ObjectFilter
            onValidate={handleValidate(FilterType.OBJECT)}
            objectProductCategories={filterData.objectProductCategories}
            language={intl.locale}
          />
        )
      });
    }

    if (filtersWanted.includes(FilterType.BRAND)) {
      _filterButtons.push({
        id: FilterType.BRAND,
        type: FilterType.BRAND,
        icon: BrandIcon,
        label: intl.formatMessage({ id: 'catalog.filters.brand' }),
        width: '80%',
        height: '60%',
        content: (
          <BrandFilter
            onValidate={handleValidate(FilterType.BRAND)}
            brandLexique={filterData.brandLexique || {}}
            loading={loading.brandLexique}
            promotedBrands={promotedBrands}
          />
        )
      });
    }

    if (filtersWanted.includes(FilterType.STYLE)) {
      _filterButtons.push({
        id: FilterType.STYLE,
        type: FilterType.STYLE,
        icon: StyleIcon,
        label: intl.formatMessage({ id: 'catalog.filters.style' }),
        width: window.innerWidth > 756 ? '60%': '90%',
        height: 'auto',
        content: (
          <StyleFilter
            onValidate={handleValidate(FilterType.STYLE)}
            loading={loading.styleTag}
            styleTags={filterData.styleTags}
            language={intl.locale}
          />
        )
      });
    }

    if (filtersWanted.includes(FilterType.MATERIAL)) {
      _filterButtons.push({
        id: FilterType.MATERIAL,
        type: FilterType.MATERIAL,
        icon: MaterialIcon,
        label: intl.formatMessage({ id: 'catalog.filters.material' }),
        width: 'auto',
        height: 'auto',
        content: (
          <MaterialsFilter
            onValidate={handleValidate(FilterType.MATERIAL)}
            loading={loading.productMaterialTag}
            productMaterialTags={filterData.productMaterialTags}
            images={images}
            language={intl.locale}
          />
        )
      });
    }

    if (filtersWanted.includes(FilterType.ROOM)) {
      _filterButtons.push({
        id: FilterType.ROOM,
        type: FilterType.ROOM,
        icon: RoomIcon,
        label: intl.formatMessage({ id: 'catalog.filters.room' }),
        width: window.innerWidth > 756 ? '60%': '90%',
        height: 'auto',
        content: (
          <RoomFilter
            onValidate={handleValidate(FilterType.ROOM)}
            loading={loading.roomTag}
            roomTags={filterData.roomTags}
            language={intl.locale}
          />
        )
      });
    }

    if (filtersWanted.includes(FilterType.COLOR)) {
      _filterButtons.push({
        id: FilterType.COLOR,
        type: FilterType.COLOR,
        icon: ColorIcon,
        label: intl.formatMessage({ id: 'catalog.filters.color' }),
        width: window.innerWidth > 756 ? '30%' : '90%',
        height: 'auto',
        content: (
          <ColorFilter
            onValidate={handleValidate(FilterType.COLOR)}
            loading={loading.colorTag}
            colorTags={filterData.colorTags}
            language={intl.locale}
          />
        )
      });
    }

    if (filtersWanted.includes(FilterType.ORIGIN)) {
      _filterButtons.push({
        id: FilterType.ORIGIN,
        type: FilterType.ORIGIN,
        icon: OriginIcon,
        label: intl.formatMessage({ id: 'catalog.filters.origin' }),
        height: 'auto',
        content: (
          <OriginFilter
            countries={translateCountries(intl.formatMessage)}
            onValidate={handleValidate(FilterType.ORIGIN)}
          />
        )
      });
    }

    if (filtersWanted.includes(FilterType.HAVE3D)) {
      const have3d = filters.find(filter => filter.type === FilterType.HAVE3D);

      _filterButtons.push({
        id: FilterType.HAVE3D,
        type: FilterType.HAVE3D,
        icon: Have3DIcon,
        label: intl.formatMessage({ id: 'catalog.filters.3d' }),
        width: 'auto',
        height: 'auto',
        content: (
          <Have3dFilter
            messages={{
              with3d: intl.formatMessage({ id: 'products.with3d' }),
              without3d: intl.formatMessage({ id: 'products.without3d' })
            }}
            have3d={have3d ? !!have3d.value : null}
            onValidate={handleValidate(FilterType.HAVE3D)}
          />
        )
      });
    }
    if (filtersWanted.includes(FilterType.DIMENSIONS)) {
      _filterButtons.push({
        id: FilterType.DIMENSIONS,
        type: FilterType.DIMENSIONS,
        icon: SizeIcon,
        label: intl.formatMessage({ id: 'catalog.filters.size' }),
        width: window.innerWidth > 756 ? 'auto': '80%',
        height: 'auto',
        content: (
          <DimensionsFilter
            onValidate={handleValidate(FilterType.DIMENSIONS)}
            min={1}
            max={500}
            applyLabel={intl.formatMessage({ id: 'catalog.filters.budget.apply' })}
            clearLabel={intl.formatMessage({ id: 'catalog.filters.budget.clear' })}
            widthLabel={intl.formatMessage({ id: 'catalog.dimensionsFilter.width' })}
            heightLabel={intl.formatMessage({ id: 'catalog.dimensionsFilter.height' })}
            depthLabel={intl.formatMessage({ id: 'catalog.dimensionsFilter.depth' })}
          />
        )
      });
    }

    setFilterButtons(_filterButtons);
  }, [
    handleValidate,
    promotedBrands,
    filters,
    filtersWanted,
    intl,
    filterData.prices,
    filterData.objectProductCategories,
    filterData.brandLexique,
    filterData.styleTags,
    filterData.productMaterialTags,
    filterData.roomTags,
    filterData.colorTags,
    loading.brandLexique,
    loading.styleTag,
    loading.productMaterialTag,
    loading.roomTag,
    loading.colorTag
  ]);

  // watches for filters changes, and translates them into an API readable object. also updates URL.
  useEffect(() => {
    if (!firstUrlParsingDone) return;
    let matchedColors: any = [];
    let matchedBrands: any = [];
    let matchedCategories: any = [];
    let matchedStyles: any = [];
    let matchedMaterials: any = [];
    let matchedRooms: any = [];
    let wordsToRemove: string[] = [];
    let translatedFilters = translateFiltersForAPI(filters);

    const searchInCategory = (category, query) => {
      if ((category.name.toLowerCase() === query.toLowerCase()) || category.slug.toLowerCase() === query.toLowerCase() ||
        category.name.toLowerCase() === query.toLowerCase() + 's' || category.name.toLowerCase() === query.toLowerCase() + 'es') { // We check also the prural form
        matchedCategories.push(category); // Push the matched category into the array
        return true;
      }
      if (category.childs) {
        for (const childCategory of category.childs) {
          if (searchInCategory(childCategory, query)) {
            return true;
          }
        }
      }
      return false;
    };

    if (searchQuery) {
      // TOTO maybe a more elegant way to do this
      if (searchQuery.length > 1) {
        // First search for multiple words
        // Brands
        for (const key in filterData.brandLexique) {
          filterData.brandLexique[key].forEach((brand) => {
            if (brand.name.length > 1 && searchQuery.toLowerCase().includes(brand.name.toLowerCase())) {
              matchedBrands.push(brand);
              const brandNameWords = brand.name.split(' ');
              brandNameWords.forEach(word => {
                wordsToRemove.push(word);
                wordsToRemove.push(word.toLowerCase());
              });
            }
          });
        }

        // Types of objects
        const arrayOfObject = [
          'table arts',
          'decorative objects',
          'hooks and coat racks',
          'small storage',
          'trash cans',
          'floor lamps',
          'portable lamps',
          'desk lamps',
          'table lamps',
          'reading light',
          'ceiling light',
          'pendant light',
          'writing desks',
          'low tables',
          'high tables',
          'chaise chairs',
          'tealight holders, candle holders and candle',
          'chest with drawers',
          'entrance furniture',
          'TV furniture',
          'bedroom furniture',
          'box springs'
        ];
        arrayOfObject.forEach((object: string) => {
          if (object.length > 1 && searchQuery.toLowerCase().includes(object.toLowerCase())) {
            filterData.objectProductCategories.forEach((category) => {
              if (searchInCategory(category, object.toLowerCase())) {
                const objectCategNameWords = object.split(' ');
                objectCategNameWords.forEach(word => {
                  wordsToRemove.push(word);
                  wordsToRemove.push(word.toLowerCase());
                });
              }
            });
          }
        });
      }

      const searchWords = searchQuery ? searchQuery.split(' ') : [];
      searchWords.forEach((word) => {
        let matched = false;

        filterData.colorTags.some((colorTag) => {
          if ((colorTag.name.toLowerCase() === word.toLowerCase()) ||
          colorTag.slug.toLowerCase() === word.toLowerCase()) {
            matchedColors.push(colorTag);
            wordsToRemove.push(word);
            matched = true;
            return true; // Exit the loop once a match is found
          }
          return false;
        });

        if (!matched) {
          for (const key in filterData.brandLexique) {
            if (matched) break; // If a match was found in colors, no need to check brands
            filterData.brandLexique[key].forEach((brand) => {
              if (brand.name.toLowerCase() === word.toLowerCase()) {
                matchedBrands.push(brand);
                wordsToRemove.push(word);
                matched = true;
                return; // Exit the loop once a match is found
              }
            });
          }
        }

        if (!matched) {
          filterData.objectProductCategories.forEach((category) => {
            if (searchInCategory(category, word.toLowerCase())) {
              wordsToRemove.push(word);
              matched = true;
            }
          });
        }

        // Check against styleTags
        if (!matched) {
          filterData.styleTags.some((styleTag) => {
            if ((styleTag.name.toLowerCase() === word.toLowerCase()) || (styleTag.slug.toLowerCase() === word.toLowerCase())) {
              matchedStyles.push(styleTag);
              wordsToRemove.push(word);
              matched = true;
            }
          });
        }

        // Check against productMaterialTags
        if (!matched) {
          filterData.productMaterialTags.some((materialTag) => {
            if ((materialTag.name.toLowerCase() === word.toLowerCase()) || (materialTag.slug.toLowerCase() === word.toLowerCase())) {
              matchedMaterials.push(materialTag);
              wordsToRemove.push(word);
              matched = true;
            }
          });
        }

        // Check against roomTags
        if (!matched) {
          filterData.roomTags.some((roomTag) => {
            if ((roomTag.name.toLowerCase() === word.toLowerCase()) || (roomTag.slug.toLowerCase() === word.toLowerCase())) {
              matchedRooms.push(roomTag);
              wordsToRemove.push(word);
              matched = true;
            }
          });
        }
      });

      const filteredWords = searchWords.filter((word) => !wordsToRemove.includes(word));
      const newSearchQuery = filteredWords.join(' ');
      matchedColors.forEach((colorTag) => {
        addFilter({ type: FilterType.COLOR, label: colorTag.name, value: colorTag.id });
        setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== FilterType.COLOR));
      });

      matchedBrands.forEach((brand) => {
        addFilter({ type: FilterType.BRAND, label: brand.name, value: brand.id });
        setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== FilterType.BRAND));
      });

      matchedCategories.forEach((category) => {
        addFilter({ type: FilterType.OBJECT, label: category.name, value: category.id });
        setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== FilterType.OBJECT));
      });

      matchedStyles.forEach((styleTag) => {
        addFilter({ type: FilterType.STYLE, label: styleTag.name, value: styleTag.id });
        setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== FilterType.STYLE));
      });

      matchedMaterials.forEach((materialTag) => {
        addFilter({ type: FilterType.MATERIAL, label: materialTag.name, value: materialTag.id });
        setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== FilterType.MATERIAL));
      });

      matchedRooms.forEach((roomTag) => {
        addFilter({ type: FilterType.ROOM, label: roomTag.name, value: roomTag.id });
        setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== FilterType.ROOM));
      });

      if (matchedColors.length > 0 || matchedBrands.length > 0 || matchedCategories.length > 0 ||
          matchedStyles.length > 0 || matchedMaterials.length > 0 || matchedRooms.length > 0) {
        translatedFilters = { ...translatedFilters, query: [newSearchQuery || ' '] };
      } else {
        // If no matches, keep the original searchQuery
        translatedFilters = { ...translatedFilters, query: [searchQuery] };
      }
    } else if (translatedFilters.query) {
      delete translatedFilters.query;
    }
    setFiltersForAPI(translatedFilters);

    updateUrl(stringify(translatedFilters, { arrayFormat: 'comma' }));
  }, [filters, searchQuery, firstUrlParsingDone, updateUrl]);


  const onResetFilters = useCallback(() => setFilters([]), []);
  const onCloseFilter = useCallback(
    (filter: IFilter) => setFilters(filters.filter(f => f !== filter)),
    [filters]
  );
  const onUpdatePopoverVisible = useCallback(
    (id: number, visible: boolean) =>
      setVisibleButtonsIds(
        visible ? [...visibleButtonsIds, id] : visibleButtonsIds.filter(bId => id !== bId)
      ),
    [visibleButtonsIds]
  );

  return {
    // filtersForAPI will be set to empty array if no filters were in the url. until then, it is undefined, and should not be used.
    loading: !firstUrlParsingDone || !dataFetched || filtersForAPI === undefined,
    filterButtons,
    filters,
    visibleButtonsIds,
    filtersForAPI,
    onResetFilters,
    onCloseFilter,
    onUpdatePopoverVisible,
    closeFilter
  };
}

export const translateFiltersForAPI = (catalogFilters: IFilter[]) => {
  let objectFilters: { [key: string]: string[]; } = {};
  catalogFilters.forEach(filter => {
    switch (filter.type) {
    case FilterType.PRICE:
      const value = filter.value as Range;
      objectFilters = {
        ...objectFilters,
        priceMin: [value[0].toString()],
        priceMax: [value[1].toString()]
      };
      return;
    case FilterType.DIMENSIONS:
      const aux = filter.value as Dimensions;
      objectFilters = {
        ...objectFilters,
        widthMin: [aux[0].toString()],
        widthMax: [aux[1].toString()],
        heightMin: [aux[2].toString()],
        heightMax: [aux[3].toString()],
        depthMin: [aux[4].toString()],
        depthMax: [aux[5].toString()]
      };
      return;
    case FilterType.BRAND:
      objectFilters = {
        ...objectFilters,
        brandIds:
            'brandIds' in objectFilters
              ? [...objectFilters.brandIds, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.STYLE:
      objectFilters = {
        ...objectFilters,
        styleTagIds:
            'styleTagIds' in objectFilters
              ? [...objectFilters.styleTagIds, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.ROOM:
      objectFilters = {
        ...objectFilters,
        roomTagIds:
            'roomTagIds' in objectFilters
              ? [...objectFilters.roomTagIds, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.COLOR:
      objectFilters = {
        ...objectFilters,
        colorTagIds:
            'colorTagIds' in objectFilters
              ? [...objectFilters.colorTagIds, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.MATERIAL:
      objectFilters = {
        ...objectFilters,
        materialTagIds:
            'materialTagIds' in objectFilters
              ? [...objectFilters.materialTagIds, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.ORIGIN:
      objectFilters = {
        ...objectFilters,
        madeIn:
            'madeIn' in objectFilters
              ? [...objectFilters.madeIn, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.OBJECT:
      objectFilters = {
        ...objectFilters,
        objectProductCategoryIds:
            'objectProductCategoryIds' in objectFilters
              ? [...objectFilters.objectProductCategoryIds, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.OBJECT_PARENT:
      objectFilters = {
        ...objectFilters,
        objectProductCategoryParentIds:
          'objectProductCategoryParentIds' in objectFilters
            ? [...objectFilters.objectProductCategoryParentIds, filter.value as string]
            : [filter.value as string]
      };
      return;
    case FilterType.HAVE3D:
      objectFilters = {
        ...objectFilters,
        haveObjectProductModelBundle:
            'haveObjectProductModelBundle' in objectFilters
              ? [...objectFilters.haveObjectProductModelBundle, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.PROMOTED:
      objectFilters = {
        ...objectFilters,
        haveObjectProductModelBundle:
            'promoted' in objectFilters
              ? [...objectFilters.haveObjectProductModelBundle, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.ONLY_VISIBLE:
      objectFilters = {
        ...objectFilters,
        onlyVisible:
            'onlyVisible' in objectFilters
              ? [...objectFilters.onlyVisible, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.ENABLED:
      objectFilters = {
        ...objectFilters,
        enabled:
            'enabled' in objectFilters
              ? [...objectFilters.enabled, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.HAVE_THUMBNAIL:
      objectFilters = {
        ...objectFilters,
        haveThumbnail:
            'haveThumbnail' in objectFilters
              ? [...objectFilters.haveThumbnail, filter.value as string]
              : [filter.value as string]
      };
      return;
    case FilterType.PRODUCT_ID:
      objectFilters = {
        ...objectFilters,
        productIds:
          'productIds ' in objectFilters
            ? [...objectFilters.haveThumbnail, filter.value as string]
            : [filter.value as string]
      };
      return;
      // TODO: find a way to add orders for filters
      // case FilterType.MOST_DOWNLOADED:
      // { download: 'DESC' }

      // TODO: find a way to add orders for filters
      // case FilterType.NEW:
      // { createdAt: 'DESC' }
    default:
      return;
    }
  });
  return objectFilters;
};
