import { ConnectedRouter } from 'connected-react-router'
import 'moment/locale/fr'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import 'what-input'
import { setLocale } from 'yup'
import BrandPage from './pages/Brands/BrandPage/BrandPage'
import BrandsPage from './pages/Brands/BrandsPage/BrandsPage'
import Catalog from './pages/Catalog/Catalog'
import CatalogRouter from './pages/Catalog/CatalogRouter'
import DrivePage from './pages/Drive/DrivePage'
import ForgottenPassword from './pages/ForgottenPassword/ForgottenPassword'
import GuestsPage from './pages/GuestsPage/GuestsPage'
import HomePage from './pages/HomePage/HomePage'
import LoginPage from './pages/LoginPage/LoginPage'
import PaymentComplete from './pages/Stripe/PaymentComplete'
import MySpace from './pages/MySpace/MySpace'
import AddsomeUserProfileEditPage from './pages/AddsomeUserProfileEditPage/AddsomeUserProfileEditPage'
import ProductsPage from './pages/Products/ProductsPage/ProductsPage'
import ProfileEditPage from './pages/ProfileEditPage/ProfileEditPage'
import BrandUserProfileEditPage from './pages/BrandUserProfileEditPage/BrandUserProfileEditPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import RegisterPage from './pages/RegisterPage'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import UsersPage from './pages/Users/UsersPage/UsersPage'
import ClientsPage from './pages/ClientsPage/ClientsPage'
import store, { browserHistory } from './redux'
import './styles/global.scss'
import Layout from './templates/Layout/Layout'
import PrivateRoute from './templates/PrivateRoute'
import messages_en from './translations/en.json'
import messages_fr from './translations/fr.json'
import { localeEn, localeFr } from './translations/validationLocale'
import { ILocales, IMessages } from './utils/interfaces'
import Routes from './utils/routes'
import DownloadedProductsPage from './pages/Products/DownloadedProductsPage/DownloadedProductsPage'
import UpgradePaymentComplete from './pages/UpgradeSubscription/UpgradePaymentComplete'
import RouterProductPage from './pages/Products/ProductPage/RouterProductPage'
import ProductPage from './pages/Products/ProductPage/ProductPage'
import RecentlyViewedProducts from "./pages/RecentlyViewedProducts/RecentlyViewedProducts";
import CollectionProducts from './pages/CollectionProducts/CollectionProducts'
import AccountDetails from './pages/AccountDetails/AccountDetails'
import LiveChatComponent from "./components/LiveChat/LiveChatComponent";
import CommunityPage from './pages/Community/CommunityPage'
import ScenePage from './pages/Community/ScenePage'
import NotFound from './pages/NotFoundPage/NotFound'
import CommunityRouter from './pages/Community/CommunityRouter'

const messages: IMessages = {
  fr: messages_fr,
  en: messages_en
}

let language = localStorage.getItem('lang') || 'fr'

const browserLanguage = navigator.language.slice(0, 2)

if (localStorage.getItem('lang') == null) {
  localStorage.setItem('lang', browserLanguage)
}

if (localStorage.getItem('lang')! in messages) {
  language = localStorage.getItem('lang')!
} else {
  language = 'en'
}

const validationLocales: ILocales = {
  fr: localeFr,
  en: localeEn
}

// Yup
if (validationLocales[language] !== undefined) {
  setLocale(validationLocales[language])
} else {
  setLocale(validationLocales['en'])
}

const App: React.FC = () => (
  <Provider store={store}>
    <IntlProvider locale={language} messages={messages[language]}>
      <ConnectedRouter history={browserHistory}>
        <LiveChatComponent>
        </LiveChatComponent>
        <Switch>
          <Route path={Routes.Login} exact component={LoginPage} />
          <Route path={Routes.Register} exact component={RegisterPage} />
          {/* TODO: Add invite with Brands + Architects */}
          {/* <Route path={`${Routes.Invite}/:token`} exact component={InvitePage} /> */}
          <Route path={`${Routes.Guests}/:token`} component={GuestsPage} />
          <Route path={`${Routes.PaymentComplete}/:accountId/:email/:subscriptionType`} component={PaymentComplete} />
          <Route path={Routes.ForgottenPassword} exact component={ForgottenPassword} />
          <Route path={Routes.ResetPassword} exact component={ResetPassword} />
          <Route path={`${Routes.Products}/:id`} component={RouterProductPage} />
          <Route path={Routes.Catalog} exact component={CatalogRouter} />
          <Route path={`${Routes.Catalog}/:slug`} exact component={CatalogRouter} />
          <Route path={Routes.Index} exact component={HomePage} />
          <Route path={Routes.Community} exact component={CommunityRouter} />
          <Route path={`${Routes.Community}/:slug`} component={CommunityRouter} />
          <PrivateRoute path={Routes.Index}>
            <Layout>
              <Switch>
                <Route path={Routes.Index} exact component={HomePage} />
                <Route path={Routes.MySpace} exact component={MySpace} />
                <Route path={Routes.Brands} exact component={BrandsPage} />
                <Route path={`${Routes.Brands}/:id`} component={BrandPage} />
                <Route path={Routes.Products} exact component={ProductsPage} />
                <Route path={`${Routes.ProductsEdit}/:id`} component={ProductPage} />
                <Route path={Routes.Downloads} exact component={DownloadedProductsPage} />
                <Route path={Routes.Users} component={UsersPage} />
                <Route path={`${Routes.Architects}/:id`} exact component={ProfilePage} />
                <Route path={`${Routes.Architects}/:id/edit`} component={AccountDetails} />
                <Route path={`${Routes.BrandUsers}/:id/edit`} component={BrandUserProfileEditPage} />
                <Route path={`${Routes.UpgradePaymentComplete}/:accountId/:email/:subscriptionType`} component={UpgradePaymentComplete} />
                <Route path={`${Routes.AddsomeUsers}/:id/edit`} exact component={AddsomeUserProfileEditPage} />
                <Route path={`${Routes.AddsomeUsers}/:id/edit/password`} exact component={AddsomeUserProfileEditPage} />
                <Route path={`${Routes.Drive}/:slug`} component={DrivePage} />
                <Route path={Routes.Clients} component={ClientsPage} />
                <Route path={Routes.RecentlyViewedProducts} component={RecentlyViewedProducts} />
                <Route path={`${Routes.Collections}/:id`} component={CollectionProducts} />
                <Route path='*' component={NotFound} status={404} />
              </Switch>
            </Layout>
          </PrivateRoute>
        </Switch>
      </ConnectedRouter>
    </IntlProvider>
  </Provider>
)

export default App
