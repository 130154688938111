"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setBrandLexiqueLoading = exports.setPromotedBrandsLoading = exports.setBrandLexique = exports.setPromotedBrands = void 0;

var model_1 = require("./model");

var setPromotedBrands = function setPromotedBrands(promotedBrands) {
  return {
    type: model_1.ActionsTypes.SET_PROMOTED_BRANDS,
    promotedBrands: promotedBrands
  };
};

exports.setPromotedBrands = setPromotedBrands;

var setBrandLexique = function setBrandLexique(brandLexique) {
  return {
    type: model_1.ActionsTypes.SET_BRAND_LEXIQUE,
    brandLexique: brandLexique
  };
};

exports.setBrandLexique = setBrandLexique;

var setPromotedBrandsLoading = function setPromotedBrandsLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_PROMOTED_BRAND_LOADING,
    loading: loading
  };
};

exports.setPromotedBrandsLoading = setPromotedBrandsLoading;

var setBrandLexiqueLoading = function setBrandLexiqueLoading(loading) {
  return {
    type: model_1.ActionsTypes.SET_BRAND_LEXIQUE_LOADING,
    loading: loading
  };
};

exports.setBrandLexiqueLoading = setBrandLexiqueLoading;