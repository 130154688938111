import { ObjectProductVariationDTO } from '@addsome/dtos';
import { Button, Heading, InputField, Option, Popconfirm, Select, Theme } from '@addsome/ui-kit';

import React from 'react';
import styles from './ProductVariations.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { Form } from 'antd';

type IProps = WrappedComponentProps & {
  deleteLabel: (labelId: number) => void;
  addLabel: () => void;
  changeMaterialLabelName: (labelName: string) => void;
  setAddLabelError: () => void;
  addLabelError: boolean,
  materials: string[];
  materialLabelName: string;
};


class AddMaterialsForProduct extends React.Component<IProps> {

  public render() {
    return (
      <>
        {window.innerWidth <= 1000 &&
          <div className={`${styles.wrapperLabels}`}>
            {this.props.materials && this.props.materials.length > 0 &&
              this.props.materials.map((lable, i) =>
                <span key={i} className={`${styles.labelStyle}`}>
                  <p className={styles.rightSpaceText}>
                    {lable}
                  </p>
                  <p onClick={() => {
                    this.props.deleteLabel(i);
                  }} style={{ cursor: "pointer" }}>x</p>
                </span>
              )


            }
          </div>

        }
        <div className={`${styles.alignMaterialsInputAndText} `}>
          <Heading level={3} as="h2">
            <p className={styles.titleFormat}>
              <FormattedMessage id='global.materials' />
            </p>
          </Heading>
          <span className={styles.spaceAddButton}>

            <InputField
              id="materialNameInputId"
              name="Material name"
              placeholder={this.props.intl.formatMessage({ id: 'productpage.variations.materialName' })}
              type="text"
              required
              onChange={(e) => { this.props.changeMaterialLabelName(e.target.value); }}
              defaultValue={this.props.materialLabelName}
              onPressEnter={() => {
                if (this.props.materialLabelName !== "")
                  this.props.addLabel();
                else this.props.setAddLabelError();
              }}
              value={this.props.materialLabelName}
              error={this.props.addLabelError && this.props.materialLabelName === "" ? this.props.intl.formatMessage({ id: 'productpage.variations.errorEmptyField' }) : ""}
            />
          </span>


          <Button
            theme={Theme.PRIMARY}
            shape="circle"
            aria-label="Éditer"
            icon="plus"
            title={this.props.intl.formatMessage({ id: 'productsvariations.addMaterial' })}
            className={`${styles.rightSpace}`}
            onClick={() => {
              if (this.props.materialLabelName !== "") {
                this.props.addLabel();
                this.props.changeMaterialLabelName("");
              }
              else this.props.setAddLabelError();
            }
            }
          />
          {window.innerWidth > 1000 &&
            <div className={`${styles.wrapperLabels}`}>
              {this.props.materials && this.props.materials.length > 0 &&
                this.props.materials.map((lable, i) =>
                  <span key={i} className={`${styles.labelStyle}`}>
                    <p className={styles.rightSpaceText}>
                      {lable}
                    </p>
                    <p onClick={() => {
                      this.props.deleteLabel(i);
                    }} style={{ cursor: "pointer" }}>x</p>
                  </span>
                )
              }
            </div>
          }
        </div>
      </>
    );
  }
}



export default injectIntl(AddMaterialsForProduct);
