import { IRootState } from '../../../redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { AccountType, ProductRelationshipsExtendedDTO } from '@addsome/dtos/dist';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router'
import Routes from '../../../utils/routes';
import { RouteComponentProps } from 'react-router-dom';
import {
  product as productActions,
  productViewer,
  objectProduct,
  objectProductVariations,
  product,
  auth
} from '@addsome/redux-store'
import { Loading } from '@addsome/ui-kit';
interface IMatchParams {
  id: string;
}

type IProps = RouteComponentProps<IMatchParams>
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & WrappedComponentProps;

const RouterProductPage: React.FC<IProps> = ({ accountType, redirect, fetchProduct, showProductModal, match, getAuth }) => {

  useEffect(() => {
    if (!accountType) {
      getAuth();
    }
  }, [accountType, getAuth]);

  useEffect(() => {
    if (!accountType || accountType == AccountType.Architect) {
      fetchProduct(match.params.id).then((product) => {
        history.go(-1)
        showProductModal(product, product.id, product.objectProduct!.id)
      })
    } else {
      document.location.replace(`${Routes.ProductsEdit}/${match.params.id}`)
    }
  }, [accountType])

  return (
    <Loading></Loading>
  )
}
const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  redirect: (location: string) => dispatch(push(location)),
  getAuth: () => dispatch(auth.getAuth()),
  fetchProduct: (id: string) => dispatch(productActions.thunks.fetchValue(id)),
  showProductModal: async (product: ProductRelationshipsExtendedDTO, productId: string, objectProductId?: string | null) => {
    dispatch(productActions.thunks.fetchValue(productId))
    dispatch(productViewer.setProductId(productId))
    // Only fecth again if the product changed
    if (!!objectProductId) {
      await dispatch(objectProduct.thunks.fetchValue(objectProductId))
      await dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId))
    }
    dispatch(productViewer.setDisplayViewer(true))
    if (!product.haveObjectProductModelBundle && product.pictures.length) {
      dispatch(productViewer.setSelectedPictureId(product.pictures[0].id))
    }
  }
})

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RouterProductPage)
)