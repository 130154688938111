import {
  ArchitectWithBrandDTO,
} from '@addsome/dtos'
import {
  collections,
} from '@addsome/redux-store'
import {Modal, Size} from '@addsome/ui-kit'
import React, { useEffect, useState } from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import PageHeader from '../../components/PageHeaders/PageHeader'
import Section from '../../components/Section/Section'
import { IRootState } from '../../redux'
import Routes from '../../utils/routes'
import PageHeaderArchitectContent from "../../components/PageHeaderArchitectContent/PageHeaderArchitectContent";
import {CollectionDtoWithProducts} from "@addsome/dtos/dist/dtos/collection";
import collectionsToCardData from "../../utils/collectionsToCardData";
import styles from './MySpace.module.scss'
import {Icon} from "antd";

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

// We need to take enough data to fit on wider screens
const TAKE = 22

const MySpace: React.FC<IProps> = ({
  fetchCollectionsList,
  removeCollection
}) => {
  const [collectionsLoading, setCollectionsLoading] = useState(true)
  const intl = useIntl()
  const [collections, setCollections] = useState<CollectionDtoWithProducts[]>([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [collectionIdToDelete, setCollectionIdToDelete] = useState(null);

  useEffect(() => {
    if (collections.length === 0) {
      fetchCollectionsList().then((res: any) => {
        if (res.length > 0) {
          setCollections(res);
        }
        setCollectionsLoading(false);
      });
    }

  }, [collections]);

  const handleOpenDeleteModal = (e) => {
    e.preventDefault();
    setIsDeleteModalVisible(true);
    setCollectionIdToDelete(e.currentTarget.getAttribute('data-id'));
  }

  const handleDelete = () => {
    removeCollection(
      collectionIdToDelete,
      intl.formatMessage({ id: 'architect.collection.remove.collection.success' }),
      intl.formatMessage({ id: 'architect.collection.remove.collection.error' })).then(() => {
      fetchCollectionsList().then((res: any) => {
        setCollections(res);
      });
    })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsDeleteModalVisible(false);
      });
  }

  return (
    <>
      <PageHeaderArchitectContent/>

      { collections.length == 0 && !collectionsLoading &&
        <h2 className={styles.noCollections}>No Collections Available</h2>
      }

      <Section
        noPaddingTop
        title={""}
        buttonLabel={intl.formatMessage({ id: 'global.displayall' })}
        buttonRoute={Routes.Products}
        cardsData={collections.map(collection => collectionsToCardData(collection))}
        productTopIcons={<Icon type={"delete"} style={{color:"white"}} onClick={(e) => handleOpenDeleteModal(e)}/>}
        enrichTopIcons={true}
        isCollection={true}
        loading={false}
        href={true}
      />
      <Modal
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        onOk={handleDelete}
        okText={<FormattedMessage id="global.yes" />}
        cancelText={<FormattedMessage id="global.no" />}
        title={<FormattedMessage id="architect.collections.delete.modal.header" />}
        bodyStyle={{ display: 'none' }}
      />

    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user as ArchitectWithBrandDTO,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchCollectionsList: () =>
    dispatch(collections.fetchCollections()),
  removeCollection: (collectionId, success, error) => dispatch(collections.removeCollection(collectionId, success, error)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MySpace)
