"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var queryDecorator_1 = require("../queryDecorator");

var ObjectProductCountDTO = function ObjectProductCountDTO() {
  _classCallCheck(this, ObjectProductCountDTO);
};

exports.ObjectProductCountDTO = ObjectProductCountDTO;

var ObjectProductWithBundleCountDTO = function ObjectProductWithBundleCountDTO() {
  _classCallCheck(this, ObjectProductWithBundleCountDTO);
};

exports.ObjectProductWithBundleCountDTO = ObjectProductWithBundleCountDTO;

var BrandDefaultTypeCountDTO = function BrandDefaultTypeCountDTO() {
  _classCallCheck(this, BrandDefaultTypeCountDTO);
};

exports.BrandDefaultTypeCountDTO = BrandDefaultTypeCountDTO;

var MetricsDTO = function MetricsDTO() {
  _classCallCheck(this, MetricsDTO);
};

exports.MetricsDTO = MetricsDTO;
var ObjectProductMetricsGroupBy;

(function (ObjectProductMetricsGroupBy) {
  ObjectProductMetricsGroupBy["Day"] = "day";
  ObjectProductMetricsGroupBy["Week"] = "week";
  ObjectProductMetricsGroupBy["Month"] = "month";
  ObjectProductMetricsGroupBy["Year"] = "year";
})(ObjectProductMetricsGroupBy = exports.ObjectProductMetricsGroupBy || (exports.ObjectProductMetricsGroupBy = {}));

var ObjectProductMetricsDTO = function ObjectProductMetricsDTO() {
  _classCallCheck(this, ObjectProductMetricsDTO);
};

exports.ObjectProductMetricsDTO = ObjectProductMetricsDTO;

var BrandsMetricsDTO = function BrandsMetricsDTO() {
  _classCallCheck(this, BrandsMetricsDTO);
};

exports.BrandsMetricsDTO = BrandsMetricsDTO;

var BrandMetricsDTO = function BrandMetricsDTO() {
  _classCallCheck(this, BrandMetricsDTO);
};

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], BrandMetricsDTO.prototype, "objectProductCount", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], BrandMetricsDTO.prototype, "objectProductWithBundleCount", void 0);

exports.BrandMetricsDTO = BrandMetricsDTO;

var ObjectProductTypesMetricsDTO = function ObjectProductTypesMetricsDTO() {
  _classCallCheck(this, ObjectProductTypesMetricsDTO);
};

exports.ObjectProductTypesMetricsDTO = ObjectProductTypesMetricsDTO;

var ObjectProductTypeMetricsDTO = function ObjectProductTypeMetricsDTO() {
  _classCallCheck(this, ObjectProductTypeMetricsDTO);
};

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductTypeMetricsDTO.prototype, "brandDefaultTypeCount", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductTypeMetricsDTO.prototype, "objectProductCount", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ObjectProductTypeMetricsDTO.prototype, "objectProductWithBundleCount", void 0);

exports.ObjectProductTypeMetricsDTO = ObjectProductTypeMetricsDTO;

var ObjectProductMetricsFilterDTO = function ObjectProductMetricsFilterDTO() {
  _classCallCheck(this, ObjectProductMetricsFilterDTO);
};

__decorate([class_validator_1.IsDate(), class_transformer_1.Type(function () {
  return Date;
}), __metadata("design:type", Date)], ObjectProductMetricsFilterDTO.prototype, "startDate", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsDate(), class_transformer_1.Type(function () {
  return Date;
}), __metadata("design:type", Date)], ObjectProductMetricsFilterDTO.prototype, "endDate", void 0);

__decorate([class_validator_1.IsEnum(ObjectProductMetricsGroupBy), __metadata("design:type", String)], ObjectProductMetricsFilterDTO.prototype, "groupBy", void 0);

__decorate([class_validator_1.IsOptional(), queryDecorator_1.TransformToArray(), class_validator_1.IsArray(), class_validator_1.IsUUID(undefined, {
  each: true
}), __metadata("design:type", Array)], ObjectProductMetricsFilterDTO.prototype, "brandIds", void 0);

exports.ObjectProductMetricsFilterDTO = ObjectProductMetricsFilterDTO;

var BrandsMetricsFilterDTO = function BrandsMetricsFilterDTO() {
  _classCallCheck(this, BrandsMetricsFilterDTO);
};

__decorate([class_validator_1.IsOptional(), queryDecorator_1.TransformToArray(), class_validator_1.IsArray(), class_validator_1.IsUUID(undefined, {
  each: true
}), __metadata("design:type", Array)], BrandsMetricsFilterDTO.prototype, "brandIds", void 0);

exports.BrandsMetricsFilterDTO = BrandsMetricsFilterDTO;

var ArchitectMetricDTO = function ArchitectMetricDTO() {
  _classCallCheck(this, ArchitectMetricDTO);
};

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ArchitectMetricDTO.prototype, "brandCount", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", ObjectProductCountDTO)], ArchitectMetricDTO.prototype, "objectProductCount", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", ObjectProductWithBundleCountDTO)], ArchitectMetricDTO.prototype, "objectProductWithBundleCount", void 0);

exports.ArchitectMetricDTO = ArchitectMetricDTO;