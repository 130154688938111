import AntLayout from 'antd/lib/layout'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import DriveUploadPopup from '../../components/Drive/DriveUploadPopup'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import InviteNotificationHandler from '../../components/InviteNotificationHandler/InviteNotificationHandler'
import ArchitectFirstConnectionPopup from '../../components/ArchitectFirstConnectionPopup/ArchitectFirstConnectionPopup'
import ArchitectNotEnabledPopup from '../../components/ArchitectNotEnabledPopup/ArchitectNotEnabledPopup'
import ArchitectNotLinkedPopup from '../../components/ArchitectNotLinkedPopup/ArchitectNotLinkedPopup'
import NotificationHandler from '../../components/NotificationHandler/NotificationHandler'
import ProductViewerModal from '../../components/Player/ProductViewModal/ProductViewerModal'
import { useScrollDirection } from '../../hooks/scroll'
import { IRootState } from '../../redux'
import Routes from '../../utils/routes'
import { ScrollDirectionContext } from '../../utils/ScrollDirectionContext'
import styles from './Layout.module.scss'

type IProps = ReturnType<typeof mapStateToProps> & {
  children: React.ReactNode,
  accountType: any;
}

enum HeaderTheme {
  Default,
  Dark
}

const Layout: React.FC<IProps> = ({ children, router, accountType }) => {
  const [headerTheme, setHeaderTheme] = useState(HeaderTheme.Default)
  const scrollDirection = useScrollDirection()

  useEffect(() => {
    // if (router.location.pathname.startsWith(Routes.Catalog)) {
    //   setHeaderTheme(HeaderTheme.Dark)
    // } else {
    setHeaderTheme(HeaderTheme.Default)
    // }
  }, [router.location.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [router.location.pathname])

  return (
    <ScrollDirectionContext.Provider value={scrollDirection}>
      <AntLayout
        className={classnames(styles.layout, {
          [styles.whiteBackground]: headerTheme === HeaderTheme.Dark
        })}
      >
        <Header darkTheme={headerTheme === HeaderTheme.Dark} />
        <main>{children}</main>
        <Footer />
        <NotificationHandler />
        <ProductViewerModal />
        <DriveUploadPopup />
        <InviteNotificationHandler />
        <ArchitectFirstConnectionPopup />
        <ArchitectNotEnabledPopup />
        <ArchitectNotLinkedPopup />
      </AntLayout>
    </ScrollDirectionContext.Provider>
  )
}

const mapStateToProps = (state: IRootState) => ({
  router: state.router,
  accountType: state.authState.type
})

export default connect(mapStateToProps)(Layout)
