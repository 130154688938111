import { ArchitectExtendedDTO } from '@addsome/dtos/dist'
import { Size, Theme, Thumbnail } from '@addsome/ui-kit'
import classnames from 'classnames'
import React, { memo } from 'react'
import { getResizedPreview } from '../../utils/cloudImage'
import styles from './UserThumbnail.module.scss'

interface IProps {
  user: ArchitectExtendedDTO
  thumbnailSize?: Size
  theme?: Theme.DEFAULT | Theme.WHITE
  onClick?: () => void
}

const UserThumbnail: React.FC<IProps> = ({
  user,
  thumbnailSize = Size.LARGE,
  theme = Theme.DEFAULT,
  onClick
}) => {
  return (
    <div className={styles.container} onClick={() => onClick && onClick()}>
      {user.account.profilePicture && user.account.profilePicture.url ? (
        <Thumbnail
          size={thumbnailSize}
          src={getResizedPreview(93, 93, user.account.profilePicture.url)}
        />
      ) : (
        <div
          className={classnames(styles.noThumbnail, {
            [styles.white]: theme === Theme.WHITE,
            [styles.small]: thumbnailSize === Size.SMALL,
            [styles.large]: thumbnailSize === Size.LARGE
          })}
        >
          <span>
            {user.account.firstName && user.account.lastName
              ? (user.account.firstName[0] + user.account.lastName[0]).toUpperCase()
              : '??'}
          </span>
        </div>
      )}
    </div>
  )
}

export default memo(UserThumbnail)
