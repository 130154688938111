
import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { communityScene, productViewer, product, objectProduct, objectProductVariations } from '@addsome/redux-store'
import Routes from '../../utils/routes'
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { IRootState } from '../../redux';
import { AnyAction } from 'redux';
import { connect } from 'react-redux'
import styles from './CommunityPage.module.scss'
import { Form, Icon } from 'antd'
import { Loading } from '@addsome/ui-kit';
import SocialShare from '../../components/SocialShare/SocialShare'


type IProps = {
  slug: string
} &
  WrappedComponentProps &
  ReturnType<typeof mapDispatchToProps>

const ScenePage: React.FC<IProps> = ({ slug, increseVizualization, showProductModal }) => {

  const [scene, setScene] = useState(null)
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0)
  const [currentProductIndex, setCurrentProductIndex] = useState(0)
  const dispatch: ThunkDispatch<IRootState, {}, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(communityScene.fetchScene(slug))
      .then(s => {
        setScene(s)
      })

    increseVizualization(slug)
  }, [slug])

  return (
    <div className={styles.sceneContainer}>
      {scene ?
        <>
          <div className={styles.picturesPart}>
            {scene.medias.length &&
              <>
                <img src={scene.medias[currentPictureIndex].url} className={styles.currentPicture} />
                {scene.medias.length > 1 &&
                  <>
                    < Icon className={styles.leftIcon} type='left'
                      onClick={() => currentPictureIndex > 0 ? setCurrentPictureIndex(currentPictureIndex - 1)
                        : setCurrentPictureIndex(scene.medias.length - 1)
                      }
                    />
                    <Icon className={styles.rightIcon} type='right'
                      onClick={() => (currentPictureIndex + 1 < scene.medias.length) ? setCurrentPictureIndex(currentPictureIndex + 1)
                        : setCurrentPictureIndex(0)
                      }
                    />
                  </>
                }
              </>
            }
          </div>
          <div className={styles.productsPart}>
            <div className={styles.productHeader}>
              <div>
                <h3>{scene.name}</h3>
                <span><FormattedMessage id='productpage.panel.designedBy' />&nbsp;{scene.architect.account.lastName}</span>
              </div>
              <div>
                <SocialShare
                  productImage={scene.medias[0].url}
                  productURL={`${window.location.origin}${Routes.Community}/${scene.slug}`}
                  productDescription={`${scene.name} by ${scene.architect.account.lastName}`}
                  productTitle={scene.name}
                />
              </div>
            </div>
            <div className={styles.productsWrapper}>
              {scene.products.length &&
                scene.products.map((p) =>
                  <div key={p.id} className={styles.productCard}>
                    <div className={styles.productThumbnail}
                      onClick={() => showProductModal(p.id, p.objectProductId)}
                    >
                      <img src={p.thumbnail.media.url} />
                    </div>
                    <div className={styles.productDescription}>
                      <h4>{p.brand.name}</h4>
                      <div>{p.name}</div>
                    </div>
                  </div>)
              }
              {/* <div className={styles.iconWrapper}>
                <Icon
                  type='left'
                  onClick={() => currentProductIndex > 0 && setCurrentProductIndex(currentProductIndex - 1)}
                />
                <Icon
                  type='right'
                  onClick={() => (currentProductIndex + 1) < Math.ceil(scene.products.length / 6) && setCurrentProductIndex(currentProductIndex + 1)}
                />
              </div> */}
            </div>
          </div>
        </>
        : <Loading />
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  increseVizualization: (slug) => dispatch(communityScene.increaseVizualizations(slug)),
  showProductModal: async (productId: string, objectProductId?: string | null) => {
    dispatch(product.thunks.fetchValue(productId))
    dispatch(productViewer.setDisplayViewer(true))
    // Only fecth again if the product changed
    if (!!objectProductId) {
      await dispatch(objectProduct.thunks.fetchValue(objectProductId))
      await dispatch(objectProductVariations.fetchObjectProductVariations(objectProductId))
    }
    dispatch(productViewer.setProductId(productId))
  }
})


export default injectIntl(connect(null,
  mapDispatchToProps)(ScenePage))