import beton from './beton.png'
import bois from './bois.png'
import cuir from './cuir.png'
import marbre from './marbre.png'
import metal from './metal.png'
import plastique from './plastique.png'
import tissu from './tissu.png'
import velours from './velours.png'
import verre from './verre.png'
import ceramic from './ceramic.png'

const images = {
  beton,
  bois,
  cuir,
  marbre,
  metal,
  plastique,
  tissu,
  velours,
  verre,
  ceramic
}

export default images