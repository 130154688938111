"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var country_data_1 = require("country-data");

function IsCountryCode(validationOptions) {
  return function (object, propertyName) {
    class_validator_1.registerDecorator({
      name: 'isCountryCode',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate: function validate(value, args) {
          var alpha2Values;

          if (typeof value === 'string') {
            alpha2Values = [value];
          } else if (Array.isArray(value)) {
            alpha2Values = value;
          } else {
            return false;
          }

          var validCountries = alpha2Values.filter(function (alpha2) {
            var countries = country_data_1.lookup.countries({
              alpha2: alpha2
            });
            return countries.length > 0;
          });
          return validCountries.length === alpha2Values.length;
        },
        defaultMessage: function defaultMessage() {
          return '($value) should be a valid country code (ISO 3166-1 alpha 2)';
        }
      }
    });
  };
}

exports.IsCountryCode = IsCountryCode;