import {
  ArchitectMetricDTO,
  BrandUserWithBrandsDTO,
  ProductLightExtendedDTO,
  ProductSuggestionDTO
} from '@addsome/dtos/dist'
import {
  brand as brandActions,
  IPaginationRequest,
  metrics as metricsActions,
  product as productActions,
  productSuggests as productSuggestsActions
} from '@addsome/redux-store'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IGenericMetrics } from '../../../components/PageCards/PageCardsGeneric'
import { IRootState } from '../../../redux'
import { getResizedPreview } from '../../../utils/cloudImage'
import useLoggedUser from '../../../hooks/useUser'
import Routes from '../../../utils/routes'
import { multiply3Dmetrics } from '../../../utils/multiply3Dmetrics'
import chairBg from '../../../assets/images/chairs.png'
import sofaBg from '../../../assets/images/sofas.png'
import tableBg from '../../../assets/images/tables.png'
import lightBg from '../../../assets/images/lighting.png'
import diningBg from '../../../assets/images/diningRoom.png'
import officeBg from '../../../assets/images/officeRoom.png'
import kitchenBg from '../../../assets/images/kitchenRoom.png'
import bathroomBg from '../../../assets/images/bathroom.png'


export default function useMetricsArchitect() {
  const [architectMetrics, setArchitectMetrics] = useState<ArchitectMetricDTO | null>(null)
  const [object3DProducts, setObject3DProducts] = useState<ProductSuggestionDTO[]>([])

  const [recentProducts, setRecentProducts] = useState<ProductLightExtendedDTO[]>([])
  const [mostDownloadedProducts, setMostDownloadedProducts] = useState<ProductLightExtendedDTO[]>(
    []
  )
  const [selectionProducts, setSelectionProducts] = useState<ProductLightExtendedDTO[]>([])

  const [recentProductsLoading, setRecentProductsLoading] = useState(true)
  const [mostDownloadedProductsLoading, setMostDownloadedProductsLoading] = useState(true)
  const [selectionProductsLoading, setSelectionProductsLoading] = useState(true)
  const [object3DProductsLoading, setObject3DProductsLoading] = useState(true)

  const intl = useIntl()
  const dispatch: ThunkDispatch<IRootState, {}, AnyAction> = useDispatch()

  const { metrics, brands, ...loadings } = useSelector((state: IRootState) => ({
    metrics: state.metricsState.metrics,
    metricsLoading: state.metricsState.loading,
    brands: state.brandState.values,
    brandsLoading: state.brandState.loading
  }))

  const objectCategories = [
    {
      label: 'chairs',
      filter: 'Chairs',
      img: chairBg
    },
    {
      label: 'couches',
      filter: 'Couch',
      img: sofaBg
    },
    {
      label: 'tables',
      filter: 'Tables',
      img: tableBg
    },
    {
      label: 'lighting',
      filter: 'Pendant lights',
      img: lightBg
    }]
  const roomsCategories = [
    {
      label: 'dining',
      img: diningBg,
      filter: 'Dining room'
    },
    {
      label: 'office',
      img: officeBg,
      filter: 'Office'
    },
    {
      label: 'kitchen',
      img: kitchenBg,
      filter: 'Kitchen'
    },
    {
      label: 'bathroom',
      img: bathroomBg,
      filter: 'Bathroom'
    }
  ]

  const actions = useMemo(
    () => ({
      fetchMetrics: () => dispatch(metricsActions.fetchMetrics()),
      fetchBrands: (request: IPaginationRequest = {}) =>
        dispatch(brandActions.thunks.fetchValues(request)),
      fetchProducts: (request: IPaginationRequest = {}) =>
        dispatch(productActions.thunks.fetchValues(request, false, true)),
      fetchProductSuggests: (request: IPaginationRequest = {}) =>
        dispatch(productSuggestsActions.fetchProducts(request))
    }),
    [dispatch]
  )

  // dispatch actions to gather data
  useEffect(() => {
    actions.fetchMetrics()

    // fetch brands, but we will get result from state.
    actions.fetchBrands({
      order: { createdAt: 'DESC' },
      filters: { enabled: ['true'] },
      take: 22
    })

    actions
      .fetchProducts({
        order: { createdAt: 'DESC' },
        take: 12,
        filters: {
          haveObjectProductModelBundle: ['true'],
          enabled: ['true']
        }
      })
      .then(res => {
        setRecentProducts(res)
        setRecentProductsLoading(false)
      })

    actions
      .fetchProductSuggests({
        order: { createdAt: 'DESC' },
        take: 12,
        filters: {
          haveObjectProductModelBundle: ['true'],
          enabled: ['true'],
          stateIds: ['500']
        }
      })
      .then(res => {
        setObject3DProducts(res)
        setObject3DProductsLoading(false)
      })

    actions
      .fetchProducts({
        order: { createdAt: 'DESC' },
        filters: { promoted: ['true'] },
        take: 20
      })
      .then(products => {
        setSelectionProducts(products)
        setSelectionProductsLoading(false)
      })

    actions
      .fetchProducts({
        order: { download: 'DESC' },
        take: 20
      })
      .then(products => {
        setMostDownloadedProducts(products)
        setMostDownloadedProductsLoading(false)
      })
  }, [actions, intl])

  // generate architectMetrics full using data from the store
  const architectMetricsFull: IGenericMetrics[] = useMemo(() => {
    if (!metrics)
      return [
        {
          entityName: intl.formatMessage({ id: 'homepage.metrics.brands.title' }),
          labelAdded: intl.formatMessage(
            { id: 'homepage.metrics.brands.labeladded' },
            { items: 2 }
          ),
          labelViewMyEntities: intl.formatMessage({
            id: 'homepage.metrics.brands.labelviewmyentities'
          }),
          labelViewAllEntities: intl.formatMessage({
            id: 'homepage.metrics.brands.labelviewallentities'
          }),
          items: [],
          linkEntities: `${Routes.Brands}?tab=allBrands`,
          linkMyEntities: `${Routes.Brands}?tab=myBrands`,
          loading: true
        },
        {
          entityName: intl.formatMessage({ id: 'homepage.metrics.products.title' }),
          labelAdded: intl.formatMessage(
            { id: 'homepage.metrics.products.labeladded' },
            { items: 2 }
          ),
          labelViewMyEntities: intl.formatMessage({
            id: 'homepage.metrics.products.labelviewmyentities'
          }),
          labelViewAllEntities: intl.formatMessage({
            id: 'homepage.metrics.products.labelviewallentities'
          }),
          items: [],
          linkEntities: Routes.Catalog,
          linkMyEntities: `${Routes.Catalog}`,
          loading: true
        },
        {
          entityName: intl.formatMessage({ id: 'homepage.metrics.files.title' }),
          labelAdded: intl.formatMessage({ id: 'homepage.metrics.files.labeladded' }, { items: 2 }),
          labelViewMyEntities: intl.formatMessage({
            id: 'homepage.metrics.files.labelviewmyentities'
          }),
          labelViewAllEntities: intl.formatMessage({
            id: 'homepage.metrics.files.labelviewallentities'
          }),
          items: [],
          linkEntities: Routes.Catalog + '?haveObjectProductModelBundle=true',
          linkMyEntities: `${Routes.Catalog}?haveObjectProductModelBundle=true`,
          loading: true
        }
      ]

    const nbAddedBrands = metrics.brandDefaultTypeCount.count
    const nbAddedProducts =
      metrics.objectProductCount.furniture +
      metrics.objectProductCount.lighting +
      metrics.objectProductCount.accessories
    const nbAddedFiles =
      metrics.objectProductWithBundleCount.furniture +
      metrics.objectProductWithBundleCount.lighting +
      metrics.objectProductWithBundleCount.accessories

    return [
      // METRICS BRANDS
      {
        loading: loadings.metricsLoading || loadings.brandsLoading,
        entityName: intl.formatMessage({ id: 'homepage.metrics.brands.title' }),
        labelAdded: intl.formatMessage(
          { id: 'homepage.metrics.brands.labeladded' },
          {
            items: nbAddedBrands
          }
        ),
        labelViewMyEntities: intl.formatMessage({
          id: 'homepage.metrics.brands.labelviewmyentities'
        }),
        labelViewAllEntities: intl.formatMessage({
          id: 'homepage.metrics.brands.labelviewallentities'
        }),
        nbEntities:
          metrics.brandDefaultTypeCount.furniture +
          metrics.brandDefaultTypeCount.lighting +
          metrics.brandDefaultTypeCount.accessories +
          metrics.brandDefaultTypeCount.unset,
        nbAddedEntities: nbAddedBrands,
        items: brands.slice(0, 6).map(brand => ({
          img: (brand.logo && brand.logo.url && getResizedPreview(90, 90, brand.logo.url)) || '',
          label: brand.name,
          link: `${Routes.Brands}/${brand.id}`
        })),
        linkEntities: `${Routes.Brands}?tab=allBrands`,
        linkMyEntities: `${Routes.Brands}?tab=myBrands`
      },

      // METRICS PRODUCTS
      {
        loading: loadings.metricsLoading || object3DProductsLoading,
        entityName: intl.formatMessage({ id: 'homepage.metrics.products.title' }),
        labelAdded: intl.formatMessage(
          { id: 'homepage.metrics.products.labeladded' },
          { items: nbAddedProducts }
        ),
        labelViewMyEntities: intl.formatMessage({
          id: 'homepage.metrics.products.labelviewmyentities'
        }),
        labelViewAllEntities: intl.formatMessage({
          id: 'homepage.metrics.products.labelviewallentities'
        }),
        nbEntities:
          metrics.objectProductCount.furniture +
          metrics.objectProductCount.lighting +
          metrics.objectProductCount.accessories,
        nbAddedEntities: nbAddedProducts,
        items: recentProducts.map(product => ({
          img:
            (product.thumbnail &&
              product.thumbnail.media &&
              product.thumbnail.media.url &&
              getResizedPreview(90, 90, product.thumbnail.media.url)) ||
            '',
          label: product.name,
          link: `${Routes.Products}/${product.id}`
        })),
        linkEntities: Routes.Catalog,
        linkMyEntities: `${Routes.Catalog}`
      },

      // METRICS FILES
      {
        loading: loadings.metricsLoading || object3DProductsLoading,
        entityName: intl.formatMessage({ id: 'homepage.metrics.files.title' }),
        labelAdded: intl.formatMessage(
          { id: 'homepage.metrics.files.labeladded' },
          { items: nbAddedFiles }
        ),
        labelViewMyEntities: intl.formatMessage({
          id: 'homepage.metrics.files.labelviewmyentities'
        }),
        labelViewAllEntities: intl.formatMessage({
          id: 'homepage.metrics.files.labelviewallentities'
        }),
        nbEntities:
          (metrics.objectProductWithBundleCount.furniture +
            metrics.objectProductWithBundleCount.lighting +
            metrics.objectProductWithBundleCount.accessories),
        nbAddedEntities: nbAddedFiles,
        items: object3DProducts.map(product => ({
          img:
            (product.thumbnail &&
              product.thumbnail.media &&
              product.thumbnail.media.url &&
              getResizedPreview(90, 90, product.thumbnail.media.url)) ||
            '',
          label: product.name,
          link: `${Routes.Products}/${product.id}`
        })),
        linkEntities: Routes.Catalog + '?haveObjectProductModelBundle=true',
        linkMyEntities: `${Routes.Catalog}?haveObjectProductModelBundle=true`
      }
    ]
  }, [
    architectMetrics,
    brands,
    intl,
    loadings,
    metrics,
    object3DProducts,
    recentProducts,
    object3DProductsLoading
  ])

  return {
    architectMetricsFull,
    brands,
    mostDownloadedProducts,
    selectionProducts,
    recentProducts,
    objectCategories,
    roomsCategories,
    loadings: {
      ...loadings,
      mostDownloadedProductsLoading,
      selectionProductsLoading,
      recentProductsLoading
    }
  }
}
