export default {
  api: {
    baseUrl: process.env.REACT_APP_API_URL || 'http://localhost:3001'
  },
  mapBox: {
    token: process.env.REACT_APP_MAPBOX_KEY || ''
  },
  media: {
    cloudimageToken: process.env.REACT_APP_CLOUDIMAGE_TOKEN || ''
  }
}
