import { Button, Heading, Theme } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import Routes from '../../utils/routes'
import styles from './CatalogSection.module.scss'

type IProps = ReturnType<typeof mapDispatchToProps>

const CatalogSection: React.FC<IProps> = ({ push }) => {
  const intl = useIntl()

  return (
    <div className={styles.consultCatalogSection}>
      <Heading level={5} as="h2" centerText className={styles.smallTitle}>
        {intl.formatMessage({ id: 'homepage.needinspiration' })}
      </Heading>

      <Heading level={1} as="h2" centerText className={styles.consultCatalogTitle}>
        {intl.formatMessage({ id: 'homepage.consultcatalog' })}
      </Heading>

      <Button theme={Theme.WHITEGHOST} uppercase size="large" onClick={() => push(Routes.Catalog)}>
        {intl.formatMessage({ id: 'homepage.catalog' })}
      </Button>
    </div>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  push: (path: string) => dispatch(push(path))
})

export default connect(
  null,
  mapDispatchToProps
)(CatalogSection)
