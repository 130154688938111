import {
  ArchitectExtendedBrandDTO,
  BrandsMetricsDTO,
  BrandUserWithBrandsDTO,
  ProductLightExtendedDTO,
  ProductSuggestionDTO
} from '@addsome/dtos'
import {
  brand as brandActions,
  brands as brandsActions,
  IPaginationRequest,
  metrics as metricsActions,
  product as productActions,
  productSuggests as productSuggestsActions
} from '@addsome/redux-store'
import { Button, Heading, Size, Theme } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import bg from '../../../assets/images/home-bg.png'
import MetricCardGeneric from '../../../components/MetricCardGeneric/MetricCardGeneric'
import MetricsBars from '../../../components/MetricsBars/MetricsBars'
import MostActiveUsersSection from '../../../components/MostActiveUsersSection/MostActiveUsersSection'
import PageCardsGeneric, { IGenericMetrics } from '../../../components/PageCards/PageCardsGeneric'
import PageHeader from '../../../components/PageHeaders/PageHeader'
import Section, { SectionTemplate } from '../../../components/Section/Section'
import { IRootState } from '../../../redux'
import { updatePageTitle } from '../../../services/title'
import { getResizedPreview } from '../../../utils/cloudImage'
import productToCardData, { Sides } from '../../../utils/productToCardData'
import Routes from '../../../utils/routes'
import styles from './HomePageBrandUser.module.scss'
import { multiply3Dmetrics } from '../../../utils/multiply3Dmetrics'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const HomePageBrandUser: React.FC<IProps> = ({
  fetchMetrics,
  metrics,
  user,
  fetchBrands,
  fetchProducts,
  brands,
  products,
  fetchProductSuggests,
  fetchBrandsArchitects,
  fetchMultipleBrandsMetrics,
  push
}) => {
  const [brandsMetrics, setBrandsMetrics] = useState<BrandsMetricsDTO>()

  const [object3DProducts, setObject3DProducts] = useState<ProductSuggestionDTO[]>([])
  const [recentProducts, setRecentProducts] = useState<ProductLightExtendedDTO[]>([])

  const [mostViewedProducts, setMostViewedProducts] = useState<ProductLightExtendedDTO[]>([])
  const [mostViewedProductsLoading, setMostViewedProductsLoading] = useState(true)

  const [architects, setArchitects] = useState<ArchitectExtendedBrandDTO[]>([])
  const [architectsTotal, setArchitectsTotal] = useState(0)
  const [architectsLoading, setArchitectsLoading] = useState(true)

  const [mostDownloadedProducts, setMostDownloadedProducts] = useState<ProductLightExtendedDTO[]>(
    []
  )
  const [mostDownloadedProductsLoading, setMostDownloadedProductsLoading] = useState(true)

  const intl = useIntl()

  const [brandUserMetricsFull, setBrandUserMetricsFull] = useState<IGenericMetrics[]>([
    {
      loading: true,
      items: [],
      entityName: intl.formatMessage({ id: 'homepage.metrics.products.title' }),
      labelAdded: intl.formatMessage({ id: 'homepage.metrics.products.labeladded' }, { items: 5 }),
      labelViewMyEntities: intl.formatMessage({
        id: 'homepage.metrics.products.labelviewmyentities'
      }),
      linkMyEntities: Routes.Products
    },
    {
      loading: true,
      items: [],
      entityName: intl.formatMessage({ id: 'homepage.metrics.files.title' }),
      labelAdded: intl.formatMessage({ id: 'homepage.metrics.products.labeladded' }, { items: 5 }),
      labelViewMyEntities: intl.formatMessage({
        id: 'homepage.metrics.files.labelviewmyentities'
      }),
      linkMyEntities: `${Routes.Products}?filters={"haveObjectProductModelBundle":["true"]}`
    },
    {
      loading: true,
      items: [],
      entityName: intl.formatMessage({ id: 'homepage.metrics.clients.title' }),
      labelAdded: intl.formatMessage({ id: 'homepage.metrics.clients.labeladded' }, { items: 5 }),
      labelViewMyEntities: intl.formatMessage({
        id: 'homepage.metrics.clients.labelviewmyentities'
      }),
      linkMyEntities: Routes.Clients
    }
  ] as IGenericMetrics[])

  const background =
    user.brands.length !== 0 && user.brands[0].cover && user.brands[0].cover.url
      ? user.brands[0].cover.url
      : bg

  useEffect(() => {
    if (!user) return

    fetchBrands({
      order: { createdAt: 'DESC' },
      filters: { enabled: ['true'] },
      take: 16
    })

    fetchMultipleBrandsMetrics(user.brands.map(b => b.id)).then(setBrandsMetrics)

    fetchProducts({
      order: { createdAt: 'DESC' },
      take: 6
    }).then(setRecentProducts)

    fetchProducts({
      order: { view: 'DESC' },
      take: 6
    }).then(res => {
      setMostViewedProducts(res)
      setMostViewedProductsLoading(false)
    })

    if (user.brands.length > 0) {
      fetchBrandsArchitects(user.brands[0].id, { take: 50, order: { download: 'DESC' } }).then(
        ({ data, total }) => {
          setArchitects(data)
          setArchitectsTotal(total)
          setArchitectsLoading(false)
        }
      )
    }

    fetchProducts({
      order: { download: 'DESC' },
      take: 6
    }).then(res => {
      setMostDownloadedProducts(res)
      setMostDownloadedProductsLoading(false)
    })

    fetchProductSuggests({
      order: { createdAt: 'DESC' },
      take: 6,
      filters: {
        haveObjectProductModelBundle: ['true'],
        enabled: ['true'],
        stateIds: ['500']
      }
    }).then(setObject3DProducts)
  }, [
    fetchBrands,
    fetchBrandsArchitects,
    fetchMultipleBrandsMetrics,
    fetchProductSuggests,
    fetchProducts,
    user
  ])

  // TODO custom hook for clarity ?
  useEffect(() => {
    if (!metrics || !brandsMetrics) return

    const values = { items: 5 }
    const metricsArray: IGenericMetrics[] = [
      {
        entityName: intl.formatMessage({ id: 'homepage.metrics.products.title' }),
        labelAdded: intl.formatMessage({ id: 'homepage.metrics.products.labeladded' }, values),
        labelViewMyEntities: intl.formatMessage({
          id: 'homepage.metrics.products.labelviewmyentities'
        }),
        nbAddedEntities:
          brandsMetrics.accessories.objectProductCount +
          brandsMetrics.furniture.objectProductCount +
          brandsMetrics.lighting.objectProductCount,
        items: recentProducts.map(product => ({
          img: (product.thumbnail && product.thumbnail.media && product.thumbnail.media.url) || '',
          label: product.name,
          link: `${Routes.Brands}/${product.id}`
        })),
        linkMyEntities: Routes.Products
      },
      {
        entityName: intl.formatMessage({ id: 'homepage.metrics.files.title' }),
        labelAdded: intl.formatMessage({ id: 'homepage.metrics.products.labeladded' }, values),
        labelViewMyEntities: intl.formatMessage({
          id: 'homepage.metrics.files.labelviewmyentities'
        }),
        nbAddedEntities:
          (brandsMetrics.accessories.objectProductWithBundleCount +
            brandsMetrics.furniture.objectProductWithBundleCount +
            brandsMetrics.lighting.objectProductWithBundleCount),
        items: recentProducts.map(product => ({
          img:
            (product.thumbnail &&
              product.thumbnail.media &&
              product.thumbnail.media.url &&
              getResizedPreview(90, 90, product.thumbnail.media.url)) ||
            '',
          label: product.name,
          link: `${Routes.Products}/${product.id}`
        })),
        linkMyEntities: `${Routes.Products}?filters={"haveObjectProductModelBundle":["true"]}`
      },
      {
        itemsAsUser: true,
        entityName: intl.formatMessage({ id: 'homepage.metrics.clients.title' }),
        labelAdded: intl.formatMessage({ id: 'homepage.metrics.clients.labeladded' }, values),
        labelViewMyEntities: intl.formatMessage({
          id: 'homepage.metrics.clients.labelviewmyentities'
        }),
        nbAddedEntities: architectsTotal,
        items: architects,
        linkMyEntities: Routes.Clients
      }
    ]
    setBrandUserMetricsFull(metricsArray)
  }, [
    metrics,
    brandsMetrics,
    brands,
    products,
    object3DProducts,
    architects,
    architectsTotal,
    intl,
    recentProducts
  ])

  useEffect(() => {
    if (user) {
      fetchMetrics()
    }
  }, [fetchMetrics, user])

  updatePageTitle()

  return (
    <>
      <PageHeader
        title={
          <>
            <Heading level={1} as="h1" centerText className={styles.smallTitle}>
              {intl.formatMessage({ id: 'homepage.title' })}
            </Heading>
          </>
        }
        size={Size.LARGE}
        hasCards
        backgroundSrc={background}
      />

      <div className={styles.container}>
        <PageCardsGeneric>
          {brandUserMetricsFull.map(metric => (
            <MetricCardGeneric
              key={metric.entityName}
              itemsAsUser={metric.itemsAsUser}
              cardTitle={metric.entityName}
              metrics={[{ value: metric.nbAddedEntities, label: metric.labelAdded || '' }]}
              items={metric.items}
              bigButton={{ label: metric.labelViewMyEntities!, link: metric.linkMyEntities! }}
              loading={metric.loading}
            />
          ))}
        </PageCardsGeneric>

        <div className={styles.contentContainer}>
          <Section
            title={intl.formatMessage({ id: 'homepage.section.title.mostViewed' })}
            buttonLabel={intl.formatMessage({ id: 'global.displayall' })}
            buttonRoute={Routes.Products}
            loading={mostViewedProductsLoading}
            cardsData={mostViewedProducts.map(product =>
              productToCardData(product, {
                side: Sides.view
              })
            )}
          />

          <MostActiveUsersSection users={architects} loading={architectsLoading} />

          <Section
            title={intl.formatMessage({ id: 'homepage.section.title.mostDownloaded' })}
            buttonLabel={intl.formatMessage({ id: 'global.displayall' })}
            buttonRoute={Routes.Products}
            loading={mostDownloadedProductsLoading}
            cardsData={mostDownloadedProducts.map(product =>
              productToCardData(product, {
                side: Sides.download
              })
            )}
          />

          <SectionTemplate
            title={intl.formatMessage({ id: 'homepage.section.title.addedProducts' })}
            sectionTitleRightComponent={
              <Button theme={Theme.GHOST} uppercase onClick={() => push(Routes.Products)}>
                {intl.formatMessage({ id: 'global.displayall' })}
              </Button>
            }
          >
            <MetricsBars
              noHeading
              brandIds={
                user && user.brands && user.brands.length > 0
                  ? user.brands.map(brand => brand.id)
                  : undefined
              }
            />
          </SectionTemplate>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  user: state.userState.user as BrandUserWithBrandsDTO,
  metrics: state.metricsState.metrics,
  brands: state.brandState.values,
  products: state.productState.values,
  productsSuggests: state.productSuggestsState.products
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  push: (path: string) => dispatch(push(path)),
  fetchMetrics: () => dispatch(metricsActions.fetchMetrics()),
  fetchMultipleBrandsMetrics: (ids: string[]) =>
    dispatch(metricsActions.fetchMultipleBrandsMetrics(ids)),
  fetchBrands: (request: IPaginationRequest = {}) =>
    dispatch(brandActions.thunks.fetchValues(request)),
  fetchProducts: (request: IPaginationRequest = {}) =>
    dispatch(productActions.thunks.fetchValues(request, false, true)),
  fetchProductSuggests: (request: IPaginationRequest = {}, errorRetrievingProducts?: string) =>
    dispatch(productSuggestsActions.fetchProducts(request)),
  fetchBrandsArchitects: (brandId: string, request: IPaginationRequest = {}) =>
    dispatch(brandsActions.fetchBrandArchitects(brandId, request))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePageBrandUser)
