import { ICardData } from '@addsome/ui-kit/dist'
import { getResizedPreview } from './cloudImage'
import {CollectionDtoWithProducts} from "@addsome/dtos/dist/dtos/collection";

const collectionsToCardData = (collection: CollectionDtoWithProducts): ICardData => {
  const image = collection.products.length > 0 &&
      collection.products[0].thumbnail &&
      collection.products[0].thumbnail.media &&
      collection.products[0].thumbnail.media.url &&
      getResizedPreview(385, 256, collection.products[0].thumbnail.media.url);

  return {
    id: collection.id,
    title: collection.name ? collection.name : '',
    desc: collection.products.length + " Saved Products",
    image: image !== false ? image : undefined,
  }
}

export default collectionsToCardData
