import { Heading, Size, Tabs } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import { parse, stringify } from 'querystring'
import React, { useEffect } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import bg from '../../../assets/images/home-bg.png'
import PageHeader from '../../../components/PageHeaders/PageHeader'
import ArchitectsTabPane from '../../../components/Users/Architects/ArchitectsTabPane'
import BrandUsersTabPane from '../../../components/Users/BrandUsers/BrandUsersTabPane'
import AddsomeUsersTabPane from '../../../components/Users/Admins/AdminsTabPane'
import { IRootState } from '../../../redux'
import { updatePageTitle } from '../../../services/title'
import { AccountType, AddsomeUserDTO } from '@addsome/dtos/dist'
import usePush from '../../../hooks/usePush'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps

const UsersPage: React.FC<IProps> = ({ intl, accountType, queryParams, connectedUser, updateQuery }) => {
  const { push } = usePush()
  useEffect(() => {
    if (accountType !== AccountType.AddsomeUser) {
      // @TODO: Redirect to 404 but we don't have a 404
      push('/')
    }
  }, [accountType, push])

  updatePageTitle(intl.formatMessage({ id: 'userspage.title' }))
  return (
    <>
      <PageHeader
        title={
          <Heading level={1} strong centerText>
            <FormattedMessage id="userspage.title" />
          </Heading>
        }
        backgroundSrc={bg}
        size={Size.MEDIUM}
      />
      <Tabs
        defaultActiveKey={(queryParams.tab as string) || 'architects'}
        onChange={(tab: string) => {
          const params = { ...queryParams, tab }
          updateQuery(stringify(params))
        }}
      >
        <Tabs.TabPane tab="Architects" key="architects">
          <ArchitectsTabPane />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Brand Users" key="brandusers">
          <BrandUsersTabPane />
        </Tabs.TabPane>
        {(connectedUser as AddsomeUserDTO).superadmin &&
          <Tabs.TabPane tab="Admins" key="admins">
            <AddsomeUsersTabPane />
          </Tabs.TabPane>
        }
      </Tabs>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')),
  connectedUser: state.userState.user
})
const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  updateQuery: (params: string) => dispatch(push({ search: params }))
})
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UsersPage)
)
