"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = exports.UploadStatus = void 0;
var UploadStatus;

(function (UploadStatus) {
  UploadStatus[UploadStatus["Waiting"] = 0] = "Waiting";
  UploadStatus[UploadStatus["Uploading"] = 1] = "Uploading";
  UploadStatus[UploadStatus["Done"] = 2] = "Done";
})(UploadStatus = exports.UploadStatus || (exports.UploadStatus = {}));

var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["FETCH_DRIVE"] = "FETCH_DRIVE";
  ActionsTypes["SET_DRIVE"] = "SET_DRIVE";
  ActionsTypes["SET_DRIVE_LOADING"] = "SET_DRIVE_LOADING";
  ActionsTypes["SET_BRAND_ID"] = "SET_BRAND_ID";
  ActionsTypes["ADD_FOLDER"] = "ADD_FOLDER";
  ActionsTypes["REMOVE_FILE_OR_FOLDER"] = "REMOVE_FILE_OR_FOLDER";
  ActionsTypes["ADD_FILE"] = "ADD_FILE";
  ActionsTypes["ADD_QUEUE_FILE"] = "ADD_QUEUE_FILE";
  ActionsTypes["REMOVE_WAITING_FILE"] = "REMOVE_WAITING_FILE";
  ActionsTypes["UPDATE_FILE_PROGRESS"] = "UPDATE_FILE_PROGRESS";
  ActionsTypes["EMPTY_QUEUE"] = "EMPTY_QUEUE";
  ActionsTypes["UPDATE_QUEUE_FILE_STATUS"] = "UPDATE_QUEUE_FILE_STATUS";
  ActionsTypes["SET_UPLOAD_SPEED"] = "SET_UPLOAD_SPEED";
  ActionsTypes["SET_BYTES_UPLOADED"] = "SET_BYTES_UPLOADED";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));