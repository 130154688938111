"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var validationColorHexa_1 = require("../validationColorHexa");

var ColorTagDTO = function ColorTagDTO() {
  _classCallCheck(this, ColorTagDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ColorTagDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ColorTagDTO.prototype, "slug", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ColorTagDTO.prototype, "name", void 0);

__decorate([class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ColorTagDTO.prototype, "enabled", void 0);

__decorate([validationColorHexa_1.IsHexaColor(), class_validator_1.IsOptional(), class_validator_1.IsString(), __metadata("design:type", String)], ColorTagDTO.prototype, "hexaColor", void 0);

__decorate([class_validator_1.IsArray(), class_validator_1.ValidateNested({
  each: true
}), class_transformer_1.Type(function () {
  return ColorTagDTO;
}), __metadata("design:type", Array)], ColorTagDTO.prototype, "childs", void 0);

exports.ColorTagDTO = ColorTagDTO;

var ColorTagCreationDTO = function ColorTagCreationDTO() {
  _classCallCheck(this, ColorTagCreationDTO);
};

__decorate([class_validator_1.IsString(), class_validator_1.IsNotEmpty(), __metadata("design:type", String)], ColorTagCreationDTO.prototype, "name", void 0);

__decorate([validationColorHexa_1.IsHexaColor(), class_validator_1.IsString(), __metadata("design:type", String)], ColorTagCreationDTO.prototype, "hexaColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsUUID(), __metadata("design:type", String)], ColorTagCreationDTO.prototype, "parentId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ColorTagCreationDTO.prototype, "enabled", void 0);

exports.ColorTagCreationDTO = ColorTagCreationDTO;

var ColorTagUpdateDTO = function ColorTagUpdateDTO() {
  _classCallCheck(this, ColorTagUpdateDTO);
};

__decorate([class_validator_1.IsOptional(), class_validator_1.IsString(), class_validator_1.IsNotEmpty(), __metadata("design:type", String)], ColorTagUpdateDTO.prototype, "name", void 0);

__decorate([class_validator_1.IsOptional(), validationColorHexa_1.IsHexaColor(), class_validator_1.IsString(), __metadata("design:type", String)], ColorTagUpdateDTO.prototype, "hexaColor", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsUUID(), __metadata("design:type", String)], ColorTagUpdateDTO.prototype, "parentId", void 0);

__decorate([class_validator_1.IsOptional(), class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], ColorTagUpdateDTO.prototype, "enabled", void 0);

exports.ColorTagUpdateDTO = ColorTagUpdateDTO;