import { ArchitectExtendedDTO } from '@addsome/dtos/dist'
import React from 'react'
import styles from './PageCards.module.scss'

export interface IItems {
  img?: string
  label: string
  link: string,
  id?: string
}

export interface IGenericMetrics {
  entityName: string
  nbEntities?: number
  nbAddedEntities?: number
  items: IItems[] | ArchitectExtendedDTO[]
  linkEntities?: string
  linkMyEntities?: string
  labelAdded?: string
  labelViewMyEntities?: string
  labelViewAllEntities?: string
  itemsAsUser?: boolean
  loading?: boolean
}

const PageCardsGeneric: React.FC<{ children: any }> = ({ children }) => {
  return <div className={styles.container}>{children}</div>
}

export default PageCardsGeneric
