import React, { useMemo, useState, useEffect, useRef } from 'react'
import { AccountType, ArchitectSubscriptionType, ArchitectUpdateDTO, Language } from '@addsome/dtos'
import { ArchitectType } from '@addsome/dtos/dist/enum/architectType'
import styles from './AccountDetails.module.scss'
import { useIntl, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { Icon } from 'antd';
import { Route, RouteComponentProps } from 'react-router-dom'
import Routes from '../../utils/routes'
import usePush from '../../hooks/usePush'
import { Formik, FormikErrors } from 'formik'
import { Form } from 'antd'
import { InputField, Button, Popconfirm, Loading, Modal, Theme } from '@addsome/ui-kit'
import useArchitect from '../../templates/ProfilePageLayout/useArchitect'
import * as yup from 'yup'
import { Validator } from 'class-validator';
import { Select, Option } from '@addsome/ui-kit'
import countryList from 'react-select-country-list'
import { ThunkDispatch } from 'redux-thunk';
import { IRootState, abstractRouter } from '../../redux'
import { AnyAction } from 'redux';
import { getAuth } from '@addsome/redux-store/dist/store/auth'
import { architect as architectActions } from '@addsome/redux-store';
import { connect, useDispatch } from 'react-redux';
import { parse } from 'querystring'
import SubscriptionCards from '../SubscriptionCards/SubscriptionCards'
import { cancelMonthlySubscription, hasActiveSubscription, setNoSubscriptionWithRemainingDownloads, hasCanceledSubscriptions, getInvoices, setTrialSubscriptionNoRemainingDownloads, setTrialSubscriptionWithRemainingDownloads } from '../../services/payment'
import { sendCancelSubscriptionEmail } from '../../services/architectAccount'
import notification from 'antd/lib/notification'
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel'
import * as Sentry from '@sentry/browser'
import { auth } from '@addsome/redux-store'
import { checkPassword } from '../../services/architectAccount'
import useLoggedUser from '../../hooks/useUser'
import UploadMedia, { UploadType } from '../../components/UploadMedia/UploadMedia'
import { mediaUploadUrl } from '../../services/media'

interface IMatchParams {
  id: string
}

type IProps = RouteComponentProps<IMatchParams> &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  WrappedComponentProps

const AccountDetails: React.FC<IProps> = ({ match, patchArchitectUpdate, logout, queryParams, accountType, token }) => {
  const intl = useIntl()
  const { push } = usePush()
  const { architect, patchArchitect, fetchArchitect, profilePicture, loading } = useArchitect(match.params.id)
  const { setUser } = useLoggedUser()
  const countryOptions = useMemo(() => countryList().getData(), [])
  const dispatch = useDispatch()
  const [hasSubscription, setHasSubscription] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [allInvoices, setAllInvoices] = useState([])
  const [canceledSubscriptions, setCanceledSubscriptions] = useState([])
  const [minimumPeriodOverdue, setMinimumPeriodOverdue] = useState(false)
  const [unsubscribeLoading, setUnsubscribedLoading] = useState(false)
  const [formValidated, setFormValidated] = useState(false)
  const formikRef = useRef<Formik>(null)
  const formikChangePasswordRef = useRef<Formik>(null)
  const [logoutVisible, setLogoutVisible] = useState(false)
  const [changePasswordVisible, setChangePasswordVisible] = useState(false)
  const [wrongPassword, setWrongPassword] = useState(false)
  const [loadPassword, setLoadPassword] = useState(false)
  const [thumbnailLoading, setThumbnailLoading] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const euCountries = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU',
    'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'XI']

  useEffect(() => {
    if (window.location.pathname == `${Routes.Architects}/${match.params.id}/edit` && formikRef && formikRef.current) {
      formikRef.current.validateForm(formikRef.current.state.values)
    }
  }, [formikRef.current])

  // useEffect(() => {
  //   if (queryParams && queryParams.upgrade) {
  //     setShowUpgradeModal(true)
  //   }
  // }, [])

  useEffect(() => {
    if (!architect) return;

    if (architect.subscription) {
      hasActiveSubscription(architect.account.email).then((activeSubscription) => {
        if (activeSubscription) {
          setHasSubscription(true)
          setSubscription(activeSubscription)
        } else if (architect.subscription.slug != ArchitectSubscriptionType.Standard10) {
          setHasSubscription(false)
          setSubscription(null)
          setNoSubscriptionWithRemainingDownloads(architect.id).then((res) => {
            dispatch(getAuth())
          })
        } else {
          setHasSubscription(false)
        }
      });
    }

    hasCanceledSubscriptions(architect.account.email).then((canceledSubscriptions) => {
      if (canceledSubscriptions.length) {
        setCanceledSubscriptions(canceledSubscriptions)
      }
    });

    getInvoices(architect.account.email).then((invoices) => {
      if (invoices) {
        setAllInvoices(invoices)
      }
    })
  }, [architect])

  useEffect(() => {
    if (!subscription) return;

    if (hasSubscription) {
      const createdDate = new Date(subscription.created * 1000)
      const overdueDate = new Date(createdDate)
      overdueDate.setMonth(createdDate.getMonth() + architect!.subscription!.minimumPeriod)

      if (new Date().getTime() > overdueDate.getTime()) {
        setMinimumPeriodOverdue(true)
      }
    }
  }, [subscription, architect])

  const handleClickOnLogout = () => {
    setLogoutVisible(true)
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        lastName: yup
          .string()
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        firstName: yup
          .string()
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        email: yup
          .string()
          .email(intl.formatMessage({ id: 'validation.email.notValid' }))
          .trim()
          .required(intl.formatMessage({ id: 'validation.*.mandatory' })),
        type: yup.mixed().oneOf(Object.values(ArchitectType)),
        phone: yup.string(),
        street: yup.string().trim(),
        zipcode: yup
          .string()
          .matches(/^[0-9]+$/, intl.formatMessage({ id: 'validation.zipcode.invalid' }))
          .max(10, intl.formatMessage({ id: 'validation.zipcode.invalid' })),
        city: yup.string().matches(/^[aA-zZÀ-ÿ\s\-]+$/, intl.formatMessage({ id: 'register.address.city.error' })),
        country: yup.string().required(),
        vatCode: yup.string().matches(/^[a-zA-Z0-9]+$/, intl.formatMessage({ id: 'register.noEmptySpacesOrSpecialChars' })),
        companyName: yup.string(),
        siret: yup
          .string()
          .matches(/^[0-9]*$/, intl.formatMessage({ id: 'register.architect.siret.error' }))
      }),
    [intl]
  )

  const validationPasswordSchema = useMemo(() =>
    yup.object().shape({
      actualPassword: yup.string().required().min(5),
      newPassword: yup.string().required().min(5),
      confirmation: yup.string().required().min(5),
    }), [intl])

  const submitArchitect = (values) => {
    patchArchitectUpdate(architect.id, {
      account: {
        lastName: values.lastName,
        firstName: values.firstName,
        email: values.email,
        phone: values.phone || undefined
      },
      type: values.type,
      address: {
        street: values.street,
        zipcode: values.zipcode,
        city: values.city,
        country: values.country
      },
      companyName: values.companyName,
      siret: values.siret,
      vatCode: values.vatCode
    }, intl.formatMessage({ id: 'userspage.architect.updatedArchitect' }), intl.formatMessage({ id: 'userspage.architect.errorUpdatedArchitect' }))
      .then((a) => {
        fetchArchitect()
        dispatch(getAuth())
        if (queryParams && queryParams.upgrade) {
          push(`${Routes.Architects}/${match.params.id}/edit/subscription?subscription=${queryParams.upgrade}`)
        }
      })
      .catch((e) => console.log("Error", e))
  }


  return (
    <div className={styles.container}>
      <Modal
        visible={logoutVisible}
        onCancel={() => setLogoutVisible(false)}
        onOk={logout}
        okText={<FormattedMessage id="global.yes" />}
        cancelText={<FormattedMessage id="global.no" />}
        title={<FormattedMessage id="logout.confirm" />}
        bodyStyle={{ display: 'none' }}
      />
      {/*<Modal*/}
      {/*  visible={showUpgradeModal}*/}
      {/*  onCancel={() => setShowUpgradeModal(false)}*/}
      {/*  onOk={logout}*/}
      {/*  footer={<></>}*/}
      {/*>*/}
      {/*  <div>{intl.formatMessage({ id: 'profile.plan.missingInfoError' })}</div>*/}
      {/*</Modal>*/}
      <Modal
        visible={changePasswordVisible}
        onCancel={() => setChangePasswordVisible(false)}
        title={<FormattedMessage id="profileEdit.form.button.changePassword" />}
        okText={<FormattedMessage id="profileEdit.form.button.changePassword" />}
        cancelText={<FormattedMessage id="edittable.toCancel" />}
        onOk={() => {
          if (formikChangePasswordRef.current && Object.keys(formikChangePasswordRef.current.state.errors).length) {
            return;
          }

          setLoadPassword(true);
          formikChangePasswordRef.current && formikChangePasswordRef.current.executeSubmit();
        }}
        className={styles.changePasswordModal}
      >
        {!loadPassword ?
          <Formik
            initialValues={{
              actualPassword: '',
              newPassword: '',
              confirmation: ''
            }}
            ref={formikChangePasswordRef}
            validate={({ newPassword, confirmation }) => {
              const errors: FormikErrors<any> = {}

              if (newPassword !== confirmation) {
                errors.confirmation = intl.formatMessage({
                  id: 'validation.password.mismatch'
                })
              }

              return errors
            }}
            validationSchema={validationPasswordSchema}
            onSubmit={async (values) => {
              const isCorrect = await checkPassword(architect.account.email, values.actualPassword)
              if (isCorrect) {
                await patchArchitectUpdate(architect.id, { account: { password: values.newPassword } }, intl.formatMessage({ id: 'userspage.architect.updatedArchitectPassword' }), intl.formatMessage({ id: 'userspage.architect.errorUpdatedArchitectPassword' }))
                setChangePasswordVisible(false)
              } else {
                setWrongPassword(true)
              }
              setLoadPassword(false)
            }}
            validateOnChange={false}
          >
            {formik => (
              <>
                <Form onSubmit={formik.handleSubmit} className={styles.formPasswordChange}>
                  <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.actualPassword' })} />
                  <InputField
                    name="actualPassword"
                    type="password"
                    required
                    onChange={(e) => { formik.handleChange(e); formik.setFieldTouched('actualPassword', true) }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.actualPassword ? formik.errors.actualPassword as string : undefined}
                  />

                  <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.newPassword' })} />
                  <InputField
                    name="newPassword"
                    type="password"
                    required
                    onChange={(e) => { formik.handleChange(e); formik.setFieldTouched('newPassword', true) }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newPassword ? formik.errors.newPassword as string : undefined}
                  />

                  <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.confirmation' })} />
                  <InputField
                    name="confirmation"
                    type="password"
                    required
                    onChange={(e) => { formik.handleChange(e); formik.setFieldTouched('confirmation', true) }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirmation ? formik.errors.confirmation as string : undefined}
                  />
                </Form>
                {wrongPassword &&
                  <div className={styles.wrongPassword}>{intl.formatMessage({ id: 'validation.password.correct' })}</div>
                }
              </>
            )}
          </Formik> : <div><Loading /></div>
        }
      </Modal>
      <div className={styles.leftPartWrapper}>
        <div className={styles.leftPartList}>
          <span>{intl.formatMessage({ id: 'global.myAcount' })}</span>
          <div className={styles.accountList}>
            <h4
              className={window.location.pathname == `${Routes.Architects}/${match.params.id}/edit` ? styles.selected : ''}
              onClick={() => push(`${Routes.Architects}/${match.params.id}/edit`)}
            >
              <Icon type="user" />{intl.formatMessage({ id: 'profileEdit.navBar.profile' })}
            </h4>
            <h4
              className={window.location.pathname == `${Routes.Architects}/${match.params.id}/edit/subscription` ? styles.selected : ''}
              onClick={() => push(`${Routes.Architects}/${match.params.id}/edit/subscription`)}
            >
              <Icon type="profile" />{intl.formatMessage({ id: 'profileEdit.navBar.subscription' })}
            </h4>
            {/* <h4
              className={window.location.pathname == `${Routes.Architects}/${match.params.id}/edit/billing` ? styles.selected : ''}
              onClick={() => push(`${Routes.Architects}/${match.params.id}/edit/billing`)}
            >
              <Icon type="credit-card" />{intl.formatMessage({ id: 'profileEdit.navBar.billing' })}
            </h4> */}
            <h4
              className={window.location.pathname == `${Routes.Architects}/${match.params.id}/edit/purchases` ? styles.selected : ''}
              onClick={() => push(`${Routes.Architects}/${match.params.id}/edit/purchases`)}
            >
              <Icon type="clock-circle" />{intl.formatMessage({ id: 'profileEdit.navBar.purchaseHistory' })}
            </h4>
          </div>
        </div>
        <div onClick={handleClickOnLogout} className={styles.leftPartLogout}>
          <h4><Icon type="arrow-left" />{intl.formatMessage({ id: 'global.signOut' })}</h4>
        </div>
      </div>
      <div className={styles.rightPartWrapper}>
        <Route
          path={`${Routes.Architects}/:id/edit`}
          exact
          component={() => (
            <>
              <div className={styles.profileFirstSection}>
                <div className={styles.profileFirsSubsection}>
                  <div>
                    <h2>{intl.formatMessage({ id: 'profileEdit.navBar.profile' })}</h2>
                    <span>{intl.formatMessage({ id: 'global.userDetails' })}</span>
                  </div>
                  <div className={styles.thumbnailSection}>
                    {thumbnailLoading ?
                      <Icon className={styles.thumbnailImg} type="loading" />
                      : <img className={styles.thumbnailImg} src={profilePicture} />
                    }
                    <UploadMedia
                      type={UploadType.TEXT}
                      action={mediaUploadUrl}
                      disabled={thumbnailLoading}
                      afterUpload={media => {
                        setThumbnailLoading(true)
                        patchArchitect({
                          account: { profilePictureId: media.id }
                        }).then(u => {
                          // if current user is the currently modified user,
                          // set its new profile picture
                          if (match.params.id === architect!.id) {
                            setUser(u)
                          }
                          setThumbnailLoading(false)
                        })
                      }
                      }
                      token={token}
                      className={styles.uploadButton}
                    >
                      <div className={styles.editLink}>
                        <FormattedMessage id='architect.header.content.edit.profile.picture.button' />
                      </div>
                    </UploadMedia>
                  </div>
                </div>
                {architect ?
                  <Formik
                    ref={formikRef}
                    initialValues={{
                      lastName: architect.account.lastName || '',
                      firstName: architect.account.firstName || '',
                      email: architect.account.email || '',
                      type: architect.type,
                      phone: architect.account.phone || '',
                      street: architect.address.street || '',
                      zipcode: architect.address.zipcode || '',
                      city: architect.address.city || '',
                      country: architect.address.country || '',
                      companyName: architect.companyName || '',
                      siret: architect.siret || '',
                      vatCode: architect.vatCode || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => submitArchitect(values)}
                  >
                    {formik => (
                      <Form onSubmit={formik.handleSubmit} className={styles.form} noValidate>
                        <div className={styles.formWrapper}>
                          <div className={styles.formContainer}>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.firstName' })} />
                              <InputField
                                name="firstName"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.firstName' })}
                                defaultValue={formik.initialValues.firstName}
                                className={formik.errors.firstName ? styles.inputError : ''}
                                required
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.lastName' })} />
                              <InputField
                                name="lastName"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.lastName' })}
                                defaultValue={formik.initialValues.lastName}
                                className={formik.errors.lastName ? styles.inputError : ''}
                                required
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.email' })} />
                              <InputField
                                name="email"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.email' })}
                                defaultValue={formik.initialValues.email}
                                required
                                disabled
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.type' })} />
                              <select
                                value={formik.values.type}
                                onChange={e => formik.setFieldValue('type', e.target.value)}
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.type' })}
                              >
                                {Object.values(ArchitectType).map(type => (
                                  <option key={type} value={type}>
                                    {intl.formatMessage({ id: `profileEdit.form.type.${type}` })}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.phone' })} />
                              <InputField
                                name="phone"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.phone' })}
                                defaultValue={formik.initialValues.phone}
                                className={formik.errors.phone ? styles.inputError : ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.street' })} />
                              <InputField
                                name="street"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.street' })}
                                defaultValue={formik.initialValues.street}
                                className={formik.errors.street ? styles.inputError : ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.changeBtn}>
                              <span onClick={() => setChangePasswordVisible(true)} >
                                {intl.formatMessage({ id: 'profileEdit.form.button.changePassword' })}
                              </span>
                            </div>
                          </div>
                          <div className={styles.formContainer}>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.city' })} />
                              <InputField
                                name="city"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.city' })}
                                defaultValue={formik.initialValues.city}
                                className={formik.errors.city ? styles.inputError : ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.country' })} />
                              <select
                                value={formik.values.country}
                                onChange={e => formik.setFieldValue('country', e.target.value)}
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.country' })}
                              >
                                {countryOptions.map(country => (
                                  <option key={country.value} value={country.value}>
                                    {country.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.zipcode' })} />
                              <InputField
                                name="zipcode"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.zipcode' })}
                                defaultValue={formik.initialValues.zipcode}
                                className={formik.errors.zipcode ? styles.inputError : ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.companyName' })} />
                              <InputField
                                name="companyName"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.companyName' })}
                                defaultValue={formik.initialValues.companyName}
                                className={formik.errors.companyName ? styles.inputError : ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.siret' })} />
                              <InputField
                                name="siret"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.siret' })}
                                defaultValue={formik.initialValues.siret}
                                className={formik.errors.siret ? styles.inputError : ''}
                                onChange={(e) => { formik.handleChange(e); console.log(formik) }}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.formGroup}>
                              <Form.Item label={intl.formatMessage({ id: 'profileEdit.form.vatCode' })} />
                              <InputField
                                name="vatCode"
                                placeholder={intl.formatMessage({ id: 'profileEdit.form.vatCode' })}
                                defaultValue={formik.initialValues.vatCode}
                                className={formik.errors.vatCode ? styles.inputError : ''}
                                required={euCountries.includes(formik.values.country)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            <div className={styles.saveBtn}>
                              <Button
                                htmlType="submit"
                                size="small"
                                uppercase
                                disabled={Object.values(formik.errors).length > 0}
                              >
                                {intl.formatMessage({ id: 'global.save' })}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  : <div><Loading /></div>
                }
              </div>
              <div className={styles.profileSecondSection}>
                <h2>{intl.formatMessage({ id: 'profileEdit.navBar.preferences' })}</h2>
                <div className={styles.preferences}>
                  {architect && architect.siret
                    && ((architect.vatCode && euCountries.includes(architect.address.country)) || !euCountries.includes(architect.address.country))
                    && architect.address.country && architect.address.zipcode &&
                    <div className={styles.group}>
                      <h4>{intl.formatMessage({ id: 'profile.languagePreference' })}</h4>
                      <select
                        defaultValue={architect!.account.language}
                        onChange={e => {
                          patchArchitectUpdate(architect.id, { account: { language: e.target.value } })
                            .then(a => {
                              localStorage.setItem('lang', e.target.value == Language.French ? 'fr' : 'en')
                              window.location.reload()
                            });
                        }}
                      >
                        <option key={Language.English} value={Language.English}>
                          {Language.English}
                        </option>
                        <option key={Language.French} value={Language.French}>
                          {Language.French}
                        </option>
                      </select>
                    </div>
                  }
                  <div className={styles.group}>
                    <h4>{intl.formatMessage({ id: 'profile.cookiePreferences' })}</h4>
                    <span onClick={() => window.tarteaucitron.userInterface.openPanel()} >
                      {intl.formatMessage({ id: 'profile.openManager' })}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        />
        <Route
          path={`${Routes.Architects}/:id/edit/subscription`}
          exact
          render={() => (
            <>
              {architect &&
                <>
                  <div className={styles.subscriptionFirstSection}>
                    <h2>{intl.formatMessage({ id: 'profileEdit.navBar.subscription' })}</h2>
                    <span>{intl.formatMessage({ id: 'global.subscriptionDetails' })}</span>
                    <div className={styles.subscriptionDetails}>
                      {architect.subscription &&
                        <div className={styles.group}>
                          <h4>{intl.formatMessage({ id: 'profile.subscription.type' })}</h4>
                          {architect.subscription && architect.subscription.slug == ArchitectSubscriptionType.Unlimited &&
                            <span>Unlimited</span>
                          }
                          {architect.subscription && architect.subscription.slug == ArchitectSubscriptionType.Standard10 &&
                            <span>Free</span>
                          }
                        </div>
                      }
                      
                      <div className={styles.group}>
                        <h4>{intl.formatMessage({ id: 'profile.subscription.monthlyDownloads' })}</h4>
                        {architect.subscription && architect.subscription.slug != ArchitectSubscriptionType.Unlimited &&
                          <span>
                            {architect.downloads}/{architect.subscription ? architect.subscription.quota + architect.subscriptionRemainingDownloads : architect.subscriptionRemainingDownloads}
                          </span>
                        }
                        {architect.subscription && architect.subscription.slug == ArchitectSubscriptionType.Unlimited &&
                          <span>Unlimited</span>
                        }
                      </div>
                      
                      {hasSubscription &&
                        <div className={styles.group}>
                          <h4>{intl.formatMessage({ id: 'profile.subscription.nextPaymentDate' })}</h4>
                          <span>
                            {subscription && new Date(subscription.current_period_end * 1000 ).toLocaleDateString()}
                          </span>
                        </div>
                      }
                      {(minimumPeriodOverdue || (accountType == AccountType.AddsomeUser && hasSubscription)) &&
                        <Popconfirm
                          title={intl.formatMessage({ id: 'stripe.unsubscribeConfirmation' })}
                          onConfirm={() => {
                            setUnsubscribedLoading(true);
                            cancelMonthlySubscription(architect.account.email).then((subscr) => {
                              setUnsubscribedLoading(false);
                              if (architect.subscription.slug != ArchitectSubscriptionType.Standard10) {
                                setNoSubscriptionWithRemainingDownloads(architect.id)
                                setSubscription(null)
                              }
                              sendCancelSubscriptionEmail(architect.id)
                              notification.success({
                                message: intl.formatMessage({ id: 'stripe.unsubscribedSuccessfully' }),
                                className: styles.notificationContainer
                              })
                              setHasSubscription(false)
                              dispatch(getAuth())
                            })
                          }}
                          okText={intl.formatMessage({ id: 'global.yes' })}
                          cancelText={intl.formatMessage({ id: 'global.no' })}
                          placement="right"
                        >
                          <Button
                            size="small"
                            uppercase
                            loading={unsubscribeLoading}
                            className={styles.unsubscribeButton}
                          >
                            <FormattedMessage id="stripe.unsubscribe" />
                          </Button>
                        </Popconfirm>
                      }
                    </div>
                  </div>
                  <div className={styles.cardsSection}>
                    <SubscriptionCards queryParams={queryParams} currentSubscription={architect.subscription} />
                  </div>
                </>
              }
            </>
          )}
        />
        <Route
          path={`${Routes.Architects}/:id/edit/billing`}
          exact
          component={() => (<h1>Billing</h1>)}
        />
        <Route
          path={`${Routes.Architects}/:id/edit/purchases`}
          exact
          component={() => (
            <>
              <div className={styles.purchaseFirstSection}>
                <h2>{intl.formatMessage({ id: 'profileEdit.navBar.purchaseHistory' })}</h2>
                {allInvoices.length > 0 &&
                  <div className={styles.detailsBlock}>
                    <span className={styles.paymentsBlock}>
                      <div>
                        <h5 className={styles.paymentReason}>{intl.formatMessage({ id: 'profileEdit.form.description' })}</h5>
                        <h5 className={styles.paymentStatus}>{intl.formatMessage({ id: 'producttable.status' })}</h5>
                        <h5 className={styles.paymentDate}>{intl.formatMessage({ id: 'profile.subscription.date' })}</h5>
                        <h5 className={styles.paymentAmount}>{intl.formatMessage({ id: 'profile.subscription.amount' })}</h5>
                        <h5 className={styles.paymentInvoiceDownload}>{intl.formatMessage({ id: 'global.downloads' })}</h5>
                      </div>
                      {allInvoices.map((i) =>
                        <div key={i.id}>
                          <div className={styles.paymentReason}>{i.billing_reason.charAt(0).toUpperCase() + i.billing_reason.slice(1).replace('_', ' ')}</div>
                          <div className={styles.paymentStatus}>{i.status.charAt(0).toUpperCase() + i.status.slice(1)}</div>
                          <div className={styles.paymentDate}>{new Date(i.created * 1000).toLocaleDateString()}</div>
                          <div className={styles.paymentAmount}>{(i.amount_due / 100).toFixed(2)} {i.currency}</div>
                          <div className={styles.paymentInvoiceDownload}>
                            <a href={i.invoice_pdf} >
                              {intl.formatMessage({ id: 'profile.subscription.downloadInvoice' })}&nbsp;&nbsp;<Icon type="download"></Icon>
                            </a>
                          </div>
                        </div>)
                      }
                    </span>
                  </div>
                }
              </div>
            </>
          )}
        />
      </div>
    </div>
  )
}
const mapStateToProps = (state: IRootState) => ({
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string },
  accountType: state.authState.type,
  token: state.authState.token,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  patchArchitectUpdate: (id: string, payload: ArchitectUpdateDTO, succes?: string, error?: string) => dispatch(
    architectActions.patchArchitectUpdate(id, payload, succes, error)
  ),
  logout: () => {
    Sentry.setUser(null)
    Mixpanel.track(MixpanelEvents.UserLoggedOut)
    Mixpanel.reset()
    dispatch(auth.logout(abstractRouter))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(injectIntl(AccountDetails));