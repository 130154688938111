"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utils_1 = require("../utils");

var actions_1 = require("./actions");

var model_1 = require("./model");

function createCrudReducer(key) {
  var _actionHandlers;

  var initialState = model_1.defaultInitialState;
  var actionsTypes = (0, actions_1.generateActionsTypes)(key);
  var actions = (0, actions_1.generateActions)(actionsTypes);
  var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, actionsTypes.SET_VALUES, function (state, _ref) {
    var values = _ref.values,
        total = _ref.total;
    return Object.assign(Object.assign({}, state), {
      values: values,
      total: total
    });
  }), _defineProperty(_actionHandlers, actionsTypes.SET_LOADING, function (state, _ref2) {
    var loading = _ref2.loading;
    return Object.assign(Object.assign({}, state), {
      loading: loading
    });
  }), _defineProperty(_actionHandlers, actionsTypes.UPDATE_VALUE, function (state, _ref3) {
    var value = _ref3.value;
    return Object.assign(Object.assign({}, state), {
      values: state.values.map(function (item) {
        if (item.id === value.id) return value;
        return item;
      }),
      value: value
    });
  }), _defineProperty(_actionHandlers, actionsTypes.SET_VALUE, function (state, _ref4) {
    var value = _ref4.value;
    return Object.assign(Object.assign({}, state), {
      value: value
    });
  }), _defineProperty(_actionHandlers, actionsTypes.UNSET_VALUE, function (state) {
    return Object.assign(Object.assign({}, state), {
      value: null
    });
  }), _defineProperty(_actionHandlers, actionsTypes.REMOVE_VALUE, function (state, _ref5) {
    var id = _ref5.id;
    return Object.assign(Object.assign({}, state), {
      values: state.values.filter(function (value) {
        return value.id !== id;
      })
    });
  }), _defineProperty(_actionHandlers, actionsTypes.ADD_VALUES, function (state, _ref6) {
    var values = _ref6.values,
        total = _ref6.total;
    return Object.assign(Object.assign({}, state), {
      values: state.values.concat(values),
      total: total
    });
  }), _actionHandlers);
  return (0, utils_1.createReducer)(initialState, actionHandlers);
}

exports.default = createCrudReducer;