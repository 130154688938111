import { Card, ICardData, Theme } from '@addsome/ui-kit'
import classnames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import Routes from '../../utils/routes'
import styles from './ProductsGrid.module.scss'
import { Icon } from "antd";

interface IProps {
  cardsData: ICardData[]
  className?: string
  cardTheme?: Theme.WHITE | Theme.DEFAULT
  isCollection?: boolean
  topIcons?: React.ReactNode
  enrichTopIcons?: boolean,
  onProductClick?: (id) => void
  href?: boolean
}

const ProductsGrid: React.FC<IProps> = ({
  cardsData,
  className,
  cardTheme = Theme.DEFAULT,
  isCollection,
  topIcons,
  enrichTopIcons,
  onProductClick,
  href = true
}) => {
  return (
    <div className={classnames(styles.container, className)}>
      {cardsData.map((cardData: ICardData) => (
        <React.Fragment key={cardData.id}>
          {isCollection ? (
            <Link to={`${Routes.Collections}/${cardData.id}`}>
              <Card className={styles.card} inner {...cardData} theme={cardTheme} topIcons={topIcons} enrichTopIcons={enrichTopIcons} href={href} />
            </Link>
          ) : <>
            {
              onProductClick ?
                <div onClick={() => onProductClick(cardData.id)}>
                  <Card className={styles.card
                  } inner {...cardData} theme={cardTheme} topIcons={topIcons} enrichTopIcons={enrichTopIcons} href={href}/>
                </div>
                :
                <Link to={`${Routes.Products}/${cardData.id}`}>
                  <Card className={styles.card} inner {...cardData} theme={cardTheme} topIcons={topIcons} enrichTopIcons={enrichTopIcons} href={href} />
                </Link>
            }
          </>
          }
        </React.Fragment>
      ))}
    </div>
  )
}

export default ProductsGrid
