import { Container, Diagram, Heading, IDiagramData, Button, Theme } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { IItems } from '../PageCards/PageCardsGeneric'
import ThumbnailList, { SkeletonThumbnailList } from '../ThumbnailList/ThumbnailList'
import styles from './MetricCard.module.scss'

type IProps = WrappedComponentProps &
  ReturnType<typeof mapDispatchToProps> & {
    entityName: string
    nbEntities: number
    recents: IItems[]
    diagramDatas: IDiagramData[]
    link?: string
    placeholder?: string
    bigButton?: {
      link: string
      label: string
    }
    onThumbnailClick?: (id) => void
  }

const MetricCard: React.FC<IProps> = ({
  entityName,
  nbEntities,
  recents,
  diagramDatas,
  link,
  intl,
  placeholder,
  bigButton,
  routerPush,
  onThumbnailClick
}) => {
  const header = (
    <Heading level={3} strong as="h2">
      {entityName}
    </Heading>
  )
  return (
    <Container className={styles.metricCard}>
      <div className={styles.container}>
        {link ? <Link to={link}>{header}</Link> : header}
        <Diagram
          label={intl.formatNumber(nbEntities)}
          subLabel={entityName}
          datas={diagramDatas}
          {...(link ? { onClick: () => routerPush(link) } : {})}
        />
        <>
          <Heading level={4} as="h3">
            {intl.formatMessage({ id: 'homepage.metrics.recentlyadded' })}
          </Heading>
          {recents.length > 0 ? (
            <ThumbnailList onThumbnailClick={onThumbnailClick} items={recents} placeholder={placeholder} />
          ) : <SkeletonThumbnailList nbItems={8} />}
        </>
        {bigButton && (
          <Button
            theme={Theme.BLACK}
            disabled={false}
            uppercase
            size="default"
            onClick={() => routerPush(bigButton.link)}
            block
          >
            {bigButton.label}
          </Button>
        )}
      </div>
    </Container>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  routerPush: (location: string) => dispatch(push(location))
})

export default connect(
  undefined,
  mapDispatchToProps
)(injectIntl(MetricCard))
