import { ArchitectExtendedDTO } from '@addsome/dtos/dist'
import { Button, Container, Heading, Size, Theme, Skeleton } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React, { useCallback } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { getResizedPreview } from '../../utils/cloudImage'
import { IItems } from '../PageCards/PageCardsGeneric'
import ThumbnailList, {
  SkeletonThumbnailList,
  UserThumbnailList
} from '../ThumbnailList/ThumbnailList'
import styles from './MetricCardGeneric.module.scss'

type IProps = WrappedComponentProps &
  ReturnType<typeof mapDispatchToProps> & {
    cardTitle: string
    metrics: Array<{ value?: number; label: string }>
    items: IItems[] | ArchitectExtendedDTO[]
    bigButton?: {
      link: string
      label: string
    }
    smallLink?: {
      link: string
      label: string
    }
    itemsAsUser?: boolean
    loading?: boolean
  }

const MetricCardGeneric: React.FC<IProps> = ({
  cardTitle,
  metrics,
  items,
  bigButton,
  smallLink,
  routerPush,
  itemsAsUser = false,
  loading = false
}) => {
  const handlePushMyEntities = useCallback(() => routerPush(bigButton ? bigButton.link : ''), [
    bigButton,
    routerPush
  ])

  const thumbnails = itemsAsUser ? (
    <UserThumbnailList
      className={styles.thumbnailList}
      // TODO remove slice ?
      users={(items.slice(0, 6) as ArchitectExtendedDTO[]).map(a => ({
        ...a,
        account: {
          ...a.account,
          profilePicture: a.account.profilePicture && {
            ...a.account.profilePicture,
            url:
              a.account.profilePicture.url &&
              getResizedPreview(90, 90, a.account.profilePicture.url)
          }
        }
      }))}
      thumbnailSize={Size.LARGE}
    />
  ) : (
    <ThumbnailList
      className={styles.thumbnailList}
      // TODO remove slice ?
      items={(items.slice(0, 6) as IItems[]).map(i => ({
        ...i,
        img: i.img && getResizedPreview(90, 90, i.img)
      }))}
      thumbnailSize={Size.LARGE}
    />
  )

  return (
    <Container className={styles.metricCard}>
      <div className={styles.container}>
        <Heading level={3} strong as="h2">
          {cardTitle}
        </Heading>

        <div className={styles.entityContainer}>
          {metrics.map(m => (
            <div key={m.label}>
              {m.value === undefined ? (
                <div className={styles.skeleton}>
                  <Skeleton height={10} width={20} />
                </div>
              ) : (
                m.value
              )}
              <span>{m.label}</span>
            </div>
          ))}
        </div>

        {loading ? <SkeletonThumbnailList nbItems={6} thumbnailSize={Size.LARGE} /> : thumbnails}

        {bigButton && (
          <Button
            theme={Theme.GHOST}
            disabled={false}
            uppercase
            size="default"
            onClick={handlePushMyEntities}
            block
          >
            {bigButton.label}
          </Button>
        )}

        {smallLink && <Link to={smallLink.link}>{smallLink.label}</Link>}
      </div>
    </Container>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  routerPush: (location: string) => dispatch(push(location))
})

export default connect(
  undefined,
  mapDispatchToProps
)(injectIntl(MetricCardGeneric))
