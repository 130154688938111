"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var MaterialMapping = function MaterialMapping() {
  _classCallCheck(this, MaterialMapping);
};

exports.MaterialMapping = MaterialMapping;