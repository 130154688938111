"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = exports.BrandInvitationNotificationState = void 0;
var BrandInvitationNotificationState;

(function (BrandInvitationNotificationState) {
  BrandInvitationNotificationState["ALREADY_ACCEPTED"] = "alreadyAccepted";
  BrandInvitationNotificationState["ACCEPTED"] = "accepted";
  BrandInvitationNotificationState["ERROR"] = "error";
})(BrandInvitationNotificationState = exports.BrandInvitationNotificationState || (exports.BrandInvitationNotificationState = {}));

var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_BRAND_INVITATION"] = "SET_BRAND_INVITATION";
  ActionsTypes["SET_DISPLAY_BRAND_INVITATION_NOTIFICATION"] = "SET_DISPLAY_BRAND_INVITATION_NOTIFICATION";
  ActionsTypes["SET_BRAND_INVITATION_NOTIFICATION_STATE"] = "SET_BRAND_INVITATION_NOTIFICATION_STATE";
  ActionsTypes["SET_GUESTS_INVITATION_TOKEN"] = "SET_GUESTS_INVITATION_TOKEN";
  ActionsTypes["UNSET_GUESTS_INVITATION_TOKEN"] = "UNSET_GUESTS_INVITATION_TOKEN";
  ActionsTypes["SET_INVITATION_BRAND"] = "SET_INVITATION_BRAND";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));