"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultInitialState = void 0;
exports.defaultInitialState = {
  values: [],
  value: null,
  loading: false,
  total: 0
};