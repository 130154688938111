enum Routes {
  Index = '/',
  Brands = '/brands',
  AddsomeUsers = '/addsome-users',
  BrandUsers = '/brand-users',
  Products = '/products',
  ProductsEdit = '/productsEdit',
  Catalog = '/catalog',
  Users = '/users',
  MySpace = '/my-space',
  Architects = '/architects',
  Drive = '/drive',
  Login = '/login',
  Register = '/register',
  ForgottenPassword = '/forgotten-password',
  ResetPassword = '/reset-password',
  Invite = '/invite',
  Guests = '/guests',
  Clients = '/clients',
  Downloads = '/downloads',
  PaymentComplete = '/payment-complete',
  UpgradePaymentComplete = '/upgrade-payment-complete',
  RecentlyViewedProducts = '/recently-viewed-products',
  Collections = '/collections',
  Community = '/community',
}

export default Routes
