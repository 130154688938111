import { Icon } from 'antd'
import React from 'react'
import styles from './UploadBigSquare.module.scss'
import {WrappedComponentProps, injectIntl, FormattedMessage} from 'react-intl'

type IProps = WrappedComponentProps & {
  loading: boolean
  children?: React.ReactNode
  title?: string
}

const UploadBigSquare = (props: IProps) => (
  <div className={`${styles.uploadBigSquare} big-square-upload`}>
    {props.title && <span className={styles.title}>{props.title}</span>}
    <Icon type={props.loading ? 'loading' : 'plus'} className={styles.icon} />
    {!props.loading && (props.children || <span><FormattedMessage id='drivepage.foldermodal.addfolder'/></span>)}
  </div>
)

export default injectIntl(UploadBigSquare)
