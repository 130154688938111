import { SkeletonUserCard } from '@addsome/ui-kit'
import classnames from 'classnames'
import React from 'react'
import styles from './SkeletonUserCardsList.module.scss'

export const SkeletonUserCardsList: React.FC<{
  className?: string
  nbItems: number
}> = ({ className, nbItems }) => (
  <div className={classnames(styles.container, className)}>
    {[...Array(nbItems)].map((_, i) => (
      <SkeletonUserCard className={styles.card} key={i} />
    ))}
  </div>
)

export default SkeletonUserCardsList
