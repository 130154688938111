import React, { useMemo } from 'react'
import { Button, Modal, Size, Theme } from '@addsome/ui-kit'
import styles from './StartComponent.module.scss'
import { Icon } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import Routes from '../../../../utils/routes'
import useLoggedUser from '../../../../hooks/useUser'
import { user } from '@addsome/redux-store'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../../redux'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'

const StartComponent = ({ firstGalleryMedia, setStep, setDisplayRegisterUser }) => {

  const { user } = useLoggedUser();
  const getFirstImageFromGallery = useMemo(() => {
    if (firstGalleryMedia) {
      return firstGalleryMedia.url;
    }
    return null;
  }, [firstGalleryMedia]);

  const [displayLoginModal, setDisplayLoginModal] = React.useState(false);

  const getButtonAction = () => {
    if (user) {
      setStep(2);
    } else {
      setDisplayLoginModal(true);
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2><FormattedMessage id="community.start.title" /></h2>
          <p><FormattedMessage id="community.start.description" /></p>
          <Button className={styles.uploadButton} onClick={() => { getButtonAction() }} theme={Theme.BLACK}>
            <FormattedMessage id="community.start.uploadButton" /> <Icon type="arrow-right" />
          </Button>
        </div>
        {getFirstImageFromGallery && (
          <div className={styles.imageContainer}>
            <img src={getFirstImageFromGallery} alt="First Image" />
          </div>
        )}
      </div>
      <Modal
        className={styles.loginRegisterModal}
        visible={displayLoginModal}
        onCancel={() => setDisplayLoginModal(false)}
        okText={<FormattedMessage id="public.catalog.loginmodal.register" />}
        cancelText={<FormattedMessage id="public.catalog.loginmodal.login" />}
        title={<FormattedMessage id="public.scene.modal.title" />}
        bodyStyle={{ display: 'none' }}
        footer={
          <>
            <Button block uppercase size={Size.LARGE} onClick={() => {
              setDisplayLoginModal(false);
              setDisplayRegisterUser(true);
            }}>
              <FormattedMessage id="public.catalog.loginmodal.register" />
            </Button>
            <Button block uppercase size={Size.LARGE} onClick={() => window.location.href = `${Routes.Login}?redirect=community`} theme={Theme.PRIMARY}>
              <FormattedMessage id="public.catalog.loginmodal.login" />
            </Button>
          </>
        }
      />
    </>
  );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setDisplayRegisterUser: (displayRegister: boolean) =>
    dispatch(user.setRegisterUser(displayRegister)),
})


export default injectIntl(
  connect(
    undefined,
    mapDispatchToProps
  )(StartComponent)
)