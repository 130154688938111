import {
  ObjectProductDimensionDTO,
  ObjectProductDTO,
  ObjectProductVariationDTO,
  ProductRelationshipsExtendedDTO
} from '@addsome/dtos';
import { Container, Heading, Option, Select, Button } from '@addsome/ui-kit';
import { SelectValue } from 'antd/lib/select';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import logoPlaceholder from '../../../assets/images/donnees-2D.svg';
import { IRootState } from '../../../redux';
import { convertObjectProductForFront } from '../../../services/frontObjectProduct';
import { getCountryFromCode } from '../../../services/mapbox';
import Price from '../../Price';
import styles from './ProductInformationPanel.module.scss';
import Routes from '../../../utils/routes';
import getProductName from '../../../utils/getProductName';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InformationPanelDesktopView from './InformationPanelDesktopView';
import InformationPanelMobileView from './InformationPanelMobileView';
import { RouterState } from 'connected-react-router'

interface IState {
  countryName: string;
}

type IProps = ReturnType<typeof mapStateToProps> & {
  product: ProductRelationshipsExtendedDTO;
  objectProduct?: ObjectProductDTO;
  objectProductVariations?: ObjectProductVariationDTO[];
  onUpdateVariation?: (variationIndex: number) => void;
  title?: React.ReactNode;
  variationIndex?: number;
  isInCatalog?: boolean;
  isShowing3d?: boolean;
  isDownloading: boolean
  useNewPlayer: boolean
  router: RouterState;
  onDownload3dVisualization: (format) => void;
};

const ProductInformationPanel: React.FC<IProps> = ({
  product,
  objectProduct,
  title,
  objectProductVariations,
  productMaterialTags,
  objectProductDimensions,
  variationIndex,
  onUpdateVariation,
  isInCatalog,
  isShowing3d,
  isDownloading,
  useNewPlayer,
  accountType,
  router,
  onDownload3dVisualization
}) => {
  const [countryName, setCountryName] = useState('');
  useEffect(() => {
    if (product.brand && product.brand.madeIn) {
      getCountryFromCode(product.brand.madeIn).then(country => {
        setCountryName(country.label);
      });
    }
  }, [product.brand]);

  const convertForFront = (n) => {
    return n / 10;
  };

  const convertDepthForFront = (n) => {
    return n / 1000;
  };

  const handleVariationChange = (value: SelectValue) => {
    if (onUpdateVariation) onUpdateVariation(value as number);
  };
  if (objectProductVariations)
    return (
      <>
        {/* {window.innerWidth > 800 ? */}

        <InformationPanelDesktopView
          product={product}
          countryName={countryName}
          title={title}
          productMaterialTags={productMaterialTags}
          objectProductVariations={objectProductVariations}
          variationIndex={variationIndex}
          handleVariationChange={handleVariationChange}
          objectProductDimensions={objectProductDimensions}
          convertForFront={convertForFront}
          convertDepthForFront={convertDepthForFront}
          isInCatalog={isInCatalog}
          isShowing3d={isShowing3d}
          isDownloading={isDownloading}
          useNewPlayer={useNewPlayer}
          accountType={accountType}
          router={router}
          onDownload3dVisualization={onDownload3dVisualization}
        />

        {/* :
          <InformationPanelMobileView
            product={product}
            countryName={countryName}
            title={title}
            productMaterialTags={productMaterialTags}
            objectProductVariations={objectProductVariations}
            variationIndex={variationIndex}
            handleVariationChange={handleVariationChange}
            objectProductDimensions={objectProductDimensions}
            convertForFront={convertForFront}
            convertDepthForFront={convertDepthForFront}
            isInCatalog={isInCatalog}
            isShowing3d={isShowing3d}
            accountType={accountType}
          />
        } */}
      </>
    );
};

const mapStateToProps = (state: IRootState) => ({
  productMaterialTags: state.productMaterialTagState.values,
  objectProductDimensions: state.objectProductDimensionsState.objectProductDimensions,
  accountType: state.authState.type,
  router: state.router
});

export default connect(mapStateToProps)(ProductInformationPanel);
