import {
  BrandLightDTO,
  DesignerLightDTO,
  ObjectProductType,
  ProductCreationDTO,
  ProductUpdateDTO
} from '@addsome/dtos'
import {
  AutoComplete,
  AutoCompleteField,
  InputField,
  InputNumber,
  InputURL,
  Option,
  Select,
  TagsList,
  TextArea,
  YearSelect
} from '@addsome/ui-kit'
import { Form } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { FormikProps } from 'formik'
import get from 'lodash.get'
import React, { useCallback, useEffect, useState } from 'react'
import searchSuggested from '../../../services/searchSuggested'
import styles from './ProductInformationFieldsGroup.module.scss'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'

type IProps = WrappedComponentProps & {
  product: ProductUpdateDTO | ProductCreationDTO
  formik: FormikProps<ProductUpdateDTO | ProductCreationDTO>
  brandName?: string
  brandId?: string
  productDesigners?: DesignerLightDTO[]
  setType?: boolean
}

const ProductInformationFieldsGroup: React.FC<IProps> = ({
  product,
  formik,
  brandName,
  brandId,
  productDesigners,
  setType,
  intl
}) => {
  const [selectedDesigners, setSelectedDesigners] = useState([...(productDesigners || [])])
  const [suggestedBrands, setSuggestedBrands] = useState<BrandLightDTO[]>([])
  const [suggestedDesigners, setSuggestedDesigners] = useState<DesignerLightDTO[]>([])

  useEffect(() => {
    setSelectedDesigners([...(productDesigners || [])])
  }, [productDesigners])

  const searchBrands = async (value: SelectValue) => {
    const brands = await searchSuggested<BrandLightDTO>('brands', value.toString())
    setSuggestedBrands(brands)
  }

  const searchDesigners = useCallback(
    async (value: SelectValue) => {
      const designers = await searchSuggested<DesignerLightDTO>('designers', value.toString())
      setSuggestedDesigners(
        designers.filter(
          designer => !selectedDesigners.find(selected => selected.id === designer.id)
        )
      )
    },
    [selectedDesigners]
  )

  const addDesigner = useCallback(
    (designerId: string) => {
      const newDesigner = suggestedDesigners.find(designer => designer.id === designerId)
      if (newDesigner) {
        setSelectedDesigners([...selectedDesigners, newDesigner])
      }
    },
    [suggestedDesigners, selectedDesigners]
  )

  const removeDesigner = useCallback(
    (designerId: string) => {
      setSelectedDesigners(selectedDesigners.filter(d => d.id !== designerId))
    },
    [selectedDesigners]
  )

  return (
    <div className={styles.productInformationFieldsGroup}>
      <Form.Item
        label={<FormattedMessage id="productpage.panel.brand" />}
        required={true}
        colon={false}
      >
        <AutoCompleteField
          name="brandId"
          error={formik.errors.brandId}
          onSearch={searchBrands}
          fields={suggestedBrands.map(b => ({
            label: b.name,
            key: b.id
          }))}
          defaultLabel={brandName || ''}
          defaultValue={brandId || product.brandId || ''}
          disabled={!!brandId}
          onChangeValue={(v: string) => {
            formik.setFieldValue('brandId', v)
          }}
          onSetInvalid={(invalid: boolean) => {
            formik.setFieldError('brandId', invalid ? 'Veuillez sélectionner une marque' : '')
          }}
        />
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="productpage.panel.name" />}
        required={true}
        colon={false}
      >
        <InputField
          name="name"
          type="text"
          error={formik.errors.name}
          required={true}
          defaultValue={product.name || ''}
          onChange={formik.handleChange}
        />
      </Form.Item>

      <Form.Item label={<FormattedMessage id="productpage.panel.secondName" />} colon={false}>
        <InputField
          name="details"
          type="text"
          error={formik.errors.details}
          defaultValue={product.details || ''}
          onChange={formik.handleChange}
        />
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="productpage.panel.collectionName" />}
        required={false}
        colon={false}
      >
        <InputField
          name="collection"
          type="text"
          error={formik.errors.collection}
          defaultValue={product.collection || ''}
          onChange={formik.handleChange}
        />
      </Form.Item>

      {setType && (
        <Form.Item
          label={<FormattedMessage id="productpage.panel.type" />}
          required={true}
          colon={false}
        >
          <InputField error={get(formik.errors, 'objectProduct.type')}>
            <Select
              defaultValue={product.objectProduct && product.objectProduct.type}
              onChange={value => formik.setFieldValue('objectProduct.type', value)}
            >
              <Option value={ObjectProductType.Furniture}>{intl.formatMessage({ id: 'global.furniture'})}</Option>
              <Option value={ObjectProductType.Lighting}>{intl.formatMessage({ id: 'global.lighting'})}</Option>
              <Option value={ObjectProductType.Accessories}>{intl.formatMessage({ id: 'global.accessories'})}</Option>
            </Select>
          </InputField>
        </Form.Item>
      )}

      <Form.Item
        label={<FormattedMessage id="productPage.panel.year" />}
        required={false}
        colon={false}
      >
        <InputField error={formik.errors.designedDate}>
          <YearSelect
            defaultValue={product.designedDate ? new Date(product.designedDate) : undefined}
            onChange={(date: any) => formik.setFieldValue('designedDate', date)}
          />
        </InputField>
      </Form.Item>

      <Form.Item label={<FormattedMessage id="productpage.panel.designedBy" />}>
        <TagsList
          values={selectedDesigners.map(d => d.name)}
          onClose={index => {
            const closedDesigner = selectedDesigners[index]
            formik.setFieldValue(
              'designerIds',
              (formik.values.designerIds || []).filter(id => id !== closedDesigner.id)
            )
            removeDesigner(closedDesigner.id)
          }}
        />
        <AutoComplete
          onSearch={searchDesigners}
          fields={suggestedDesigners.map(d => ({
            label: d.name,
            key: d.id
          }))}
          placeholder={intl.formatMessage({ id: 'productpage.panel.addDesigner'})}
          onSelect={(v: SelectValue) => {
            const designerId = v.toString()
            formik.setFieldValue('designerIds', [...(formik.values.designerIds || []), designerId])
            addDesigner(designerId)
          }}
        />
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="productpage.panel.brandUrl" />}
        required={false}
        colon={false}
      >
        <InputField error={formik.errors.brandWebsiteUrl}>
          <InputURL
            name="brandWebsiteUrl"
            defaultValue={product.brandWebsiteUrl || ''}
            onChange={formik.handleChange}
          />
        </InputField>
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="productpage.panel.price" />}
        required={false}
        colon={false}
      >
        <InputField error={formik.errors.suggestedPrice}>
          <InputNumber
            name="suggestedPrice"
            defaultValue={product.suggestedPrice || undefined}
            onChange={value => formik.setFieldValue(`suggestedPrice`, value)}
            precision={2}
            formatter={value => (!isNaN(parseFloat(`${value}`)) ? `${value} €` : `${value}`)}
          />
        </InputField>
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="productpage.panel.description" />}
        required={false}
        colon={false}
      >
        <InputField error={formik.errors.collection}>
          <TextArea
            name="description"
            defaultValue={product.description || ''}
            onChange={formik.handleChange}
          />
        </InputField>
      </Form.Item>
    </div>
  )
}

export default injectIntl(ProductInformationFieldsGroup)
