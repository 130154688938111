"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _toConsumableArray = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/toConsumableArray");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  brandId: null,
  files: [],
  queuedFiles: [],
  folders: [],
  loading: true,
  uploadSpeed: 0,
  bytesUploaded: 0
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_DRIVE, function (state, _ref) {
  var drive = _ref.drive;
  return Object.assign(Object.assign({}, state), {
    files: drive.files.map(function (f) {
      return Object.assign(Object.assign({}, f), {
        status: model_1.UploadStatus.Done
      });
    }),
    folders: drive.folders
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_DRIVE_LOADING, function (state, _ref2) {
  var loading = _ref2.loading;
  return Object.assign(Object.assign({}, state), {
    loading: loading
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_BRAND_ID, function (state, _ref3) {
  var brandId = _ref3.brandId;
  return Object.assign(Object.assign({}, state), {
    brandId: brandId
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.ADD_FOLDER, function (state, _ref4) {
  var path = _ref4.path;
  return Object.assign(Object.assign({}, state), {
    folders: [].concat(_toConsumableArray(state.folders), [path])
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.REMOVE_FILE_OR_FOLDER, function (state, _ref5) {
  var path = _ref5.path;
  return Object.assign(Object.assign({}, state), {
    folders: state.folders.filter(function (f) {
      return f !== path;
    }),
    files: state.files.filter(function (f) {
      return f.name !== path;
    })
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.ADD_QUEUE_FILE, function (state, _ref6) {
  var file = _ref6.file;
  return Object.assign(Object.assign({}, state), {
    queuedFiles: [].concat(_toConsumableArray(state.queuedFiles), [file])
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.ADD_FILE, function (state, _ref7) {
  var file = _ref7.file;
  return Object.assign(Object.assign({}, state), {
    files: [file].concat(_toConsumableArray(state.files))
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.REMOVE_WAITING_FILE, function (state, _ref8) {
  var path = _ref8.path;
  return Object.assign(Object.assign({}, state), {
    queuedFiles: state.queuedFiles.filter(function (f) {
      return f.name !== path;
    })
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UPDATE_FILE_PROGRESS, function (state, _ref9) {
  var path = _ref9.path,
      progress = _ref9.progress;
  var file = state.queuedFiles.find(function (f) {
    return f.name === path && f.status === model_1.UploadStatus.Uploading;
  });
  var newFile = Object.assign(Object.assign({}, file), {
    progress: progress
  });
  return Object.assign(Object.assign({}, state), {
    queuedFiles: state.queuedFiles.map(function (f) {
      return f === file ? newFile : f;
    })
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UPDATE_QUEUE_FILE_STATUS, function (state, _ref10) {
  var path = _ref10.path,
      status = _ref10.status;
  var file = state.queuedFiles.find(function (f) {
    return f.name === path;
  });
  var newFile = Object.assign(Object.assign({}, file), {
    status: status
  });
  return Object.assign(Object.assign({}, state), {
    queuedFiles: state.queuedFiles.map(function (f) {
      return f === file ? newFile : f;
    })
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.EMPTY_QUEUE, function (state) {
  return Object.assign(Object.assign({}, state), {
    queuedFiles: [],
    files: state.files.filter(function (f) {
      return f.status === model_1.UploadStatus.Done;
    })
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_UPLOAD_SPEED, function (state, _ref11) {
  var speed = _ref11.speed;
  return Object.assign(Object.assign({}, state), {
    uploadSpeed: speed
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_BYTES_UPLOADED, function (state, _ref12) {
  var bytes = _ref12.bytes;
  return Object.assign(Object.assign({}, state), {
    bytesUploaded: bytes
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);