"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_PROMOTED_BRANDS"] = "SET_PROMOTED_BRANDS";
  ActionsTypes["SET_BRAND_LEXIQUE"] = "SET_BRAND_LEXIQUE";
  ActionsTypes["SET_PROMOTED_BRAND_LOADING"] = "SET_PROMOTED_BRAND_LOADING";
  ActionsTypes["SET_BRAND_LEXIQUE_LOADING"] = "SET_BRAND_LEXIQUE_LOADING";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));