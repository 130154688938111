import React from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import ConnectionLayout from '../../components/ConnectionLayout/ConnectionLayout'
import { updatePageTitle } from '../../services/title'
import styles from './ForgottenPassword.module.scss'
import RetrievePasswordForm from './RetrievePasswordForm/RetrievePasswordForm'

const ForgottenPassword: React.FC<WrappedComponentProps> = ({ intl }) => {
  updatePageTitle(intl.formatMessage({ id: 'forgottenpassword.title' }))
  return (
    <ConnectionLayout
      title={<FormattedMessage id="forgottenpassword.title" />}
      subtitle={<FormattedMessage id="forgottenpassword.presentation" />}
    >
      <div className={styles.forgotten}>
        <RetrievePasswordForm />
        <div>
          <Link to="/login">
            <FormattedMessage id="forgottenpassword.back" />
          </Link>
        </div>
      </div>
    </ConnectionLayout>
  )
}

export default injectIntl(ForgottenPassword)
