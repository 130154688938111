import {
  productViewer,
  objectProductVariations as objectProductVariationsActions,
  product as productActions,
  objectProductDimensions,
  auth
} from '@addsome/redux-store/dist';
import { ThunkDispatch } from '@addsome/redux-store/node_modules/redux-thunk';
import { Modal } from '@addsome/ui-kit';
import { Icon } from 'antd';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IRootState } from '../../../redux';
import { LIGHT_GREY } from '../../../utils/colors';
import ProductGallery from '../../Products/ProductGallery/ProductGallery';
import ProductInformationPanel from '../../Products/ProductInformationPanel/ProductInformationPanel';
import styles from './ProductViewerModal.module.scss';
import Routes from '../../../utils/routes';
import { ObjectProductVariationDTO } from '@addsome/dtos/dist';
import Mixpanel from '../../../utils/mixpanel';
import AppSocket from "../../../utils/socket";
import moment from 'moment';

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const modalStyle: React.CSSProperties = {
  padding: window.innerWidth > 800 ? '2.5rem' : "20px 0px",
  background: LIGHT_GREY
};
let nrOfVariationChanges = 0;

const ProductViewerModal: React.FC<IProps> = ({
  displayViewer,
  loading,
  product,
  objectProduct,
  setDisplayViewer,
  objectProductVariations,
  selectedPictureId,
  setSelectedPictureId,
  fetchObjectProductDimensions,
  pathname,
  unsetProduct,
  getAuth
}) => {
  const [variationIndex, setVariationIndex] = useState(0);
  const [isShowing3d, setIsShowing3d] = useState(true);
  const [show3d, setShow3d] = useState(false);
  const [isInCatalog, setIsInCatalog] = useState(false);
  const [sortedVariations, setSortedVariations] = useState([]);
  const [download3dWithTexturesFormat, setDownload3dWithTexturesFormat] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [useNewPlayer, setUseNewPlayer] = useState(true);

  useEffect(() => {
    if (product) {
      AppSocket.emiProductAccountMetricEvent({
        productId: product.id
      });

      Mixpanel.trackProductViewed({
        brandId: product.brand.id,
        brandName: product.brand.name,
        productId: product.id,
        productName: product.name
      });
    }
  }, [product]);
  const sortVariations = (list) => {

    list.sort((a, b) => {

      if (a.listIndex === null) {
        return 1;
      }

      if (b.listIndex === null) {
        return -1;
      }

      if (a.listIndex === b.listIndex) {
        return 0;
      }
      return a.listIndex < b.listIndex ? -1 : 1;
    });

    return (list);
  };

  const onDownload3dVisualization = (format) => {
    setIsDownloading(true);
    setDownload3dWithTexturesFormat(format);
  }

  const onFinishDownloading = () => {
    setIsDownloading(false);
    setDownload3dWithTexturesFormat(null);
  }

  useEffect(() => {
    if (displayViewer) {
      document.body.classList.add('fullscreen');

      if (product) {
        Mixpanel.trackProductViewed({
          brandId: product.brand.id,
          brandName: product.brand.name,
          productId: product.id,
          productName: product.name
        });
      }
    }
    if (product) {
      setIsShowing3d(product.haveObjectProductModelBundle);
      setShow3d(product.haveObjectProductModelBundle);
    }
    if (product && product.objectProduct && displayViewer) {
      fetchObjectProductDimensions(product.objectProduct.id);
    }
    return () => document.body.classList.remove('fullscreen');
  }, [displayViewer, product, fetchObjectProductDimensions]);

  useEffect(() => {
    const sortedVariations = sortVariations(objectProductVariations);
    setSortedVariations(sortedVariations);
  }, [objectProductVariations, displayViewer]);

  useEffect(() => {
    setIsInCatalog(pathname === Routes.Catalog);
  }, [pathname]);

  useEffect(() => {
    if (!product) {
      return;
    }

    let file = product.sourceFiles.find(file => (file.extension === 'glb'));

    if (!file) {
      setUseNewPlayer(false);

      return;
    }

    const creationDate = file.media.createDate;
    const updateDate = file.media.updateDate;

    if (moment(creationDate).year() < 2022 && moment(updateDate).year() < 2022) {
      setUseNewPlayer(false);

      return;
    }

    setUseNewPlayer(true);
  }, [product]);

  const onUpdateVariation = (index: number) => {
    setVariationIndex(index);
    nrOfVariationChanges++;
    if (product && product.haveObjectProductModelBundle) {
      setIsShowing3d(true);
    }
  };

  return (
    <Modal
      visible={displayViewer}
      footer={null}
      width="95%"
      centered
      onCancel={() => {
        setDisplayViewer(false);
        setIsShowing3d(false);
        setShow3d(false);
        nrOfVariationChanges = 0;
      }}
      afterClose={() => {
        if (pathname === Routes.Catalog) {
          unsetProduct();
        }
        setVariationIndex(0);
      }}
      bodyStyle={modalStyle}
      className={classNames(styles.modal, { [styles.isInCatalog]: isInCatalog })}
      closeIcon={isInCatalog ? <Icon type="close" /> : null}
    >
      {product && (
        <div className={classNames(styles.body, { [styles.hidden]: loading })}>
          <ProductGallery
            product={product}
            variationIndex={variationIndex}
            nrOfVariationChanges={nrOfVariationChanges}
            objectProductVariations={objectProductVariations}
            selectedPictureId={selectedPictureId}
            onSelectPicture={picture => {
              setSelectedPictureId(picture.id);
              setIsShowing3d(false);
            }}
            isInCatalog={isInCatalog}
            isShowing3d={isShowing3d}
            setIsShowing3d={setIsShowing3d}
            displayViewer={displayViewer}
            download3dWithTexturesFormat={download3dWithTexturesFormat}
            onResetAuth={() => getAuth()}
            onFinishDownloading={onFinishDownloading}
          />
          <ProductInformationPanel
            product={product}
            variationIndex={variationIndex}
            objectProductVariations={sortedVariations}
            onUpdateVariation={onUpdateVariation}
            isInCatalog={isInCatalog}
            isShowing3d={show3d}
            onDownload3dVisualization={onDownload3dVisualization}
            isDownloading={isDownloading}
            useNewPlayer={useNewPlayer}
            {...(objectProduct && { objectProduct })}
          />
        </div>
      )}
      {(loading || !product) && (
        <div className={styles.loading}>
          <Icon type="loading" />
        </div>
      )}
    </Modal>
  );
};
const mapStateToProps = (state: IRootState) => ({
  displayViewer: state.productViewerState.displayViewer,
  product: state.productState.value,
  selectedPictureId: state.productViewerState.selectedPictureId,
  loading: state.productState.loading,
  objectProduct: state.objectProductState.value,
  objectProductVariations: state.objectProductVariationsState.objectProductVariations,
  pathname: state.router.location.pathname
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewer.setDisplayViewer(displayViewer)),
  setSelectedPictureId: (pictureId: string) =>
    dispatch(productViewer.setSelectedPictureId(pictureId)),
  setObjectProductVariations: (objectProductVariations: ObjectProductVariationDTO[]) =>
    dispatch(objectProductVariationsActions.setObjectProductVariations(objectProductVariations)),
  unsetProduct: () => dispatch(productActions.thunks.unsetValue()),
  fetchObjectProductDimensions: (objectProductId: string) =>
    dispatch(objectProductDimensions.fetchObjectProductDimensions(objectProductId)),
  getAuth: () => dispatch(auth.getAuth()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductViewerModal);
