import { DesignerDTO, ProductCreationDTO } from '@addsome/dtos'
import { brand as brandActions, designer as designerActions } from '@addsome/redux-store/dist'
import { Form } from 'antd'
import { Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import * as yup from 'yup'
import { IRootState } from '../../../redux'
import { IStepFormProps } from '../../MultiStepModal/MultiStepModal'
import MultiStepNavigation from '../../MultiStepModal/MultiStepNavigation/MultiStepNavigation'
import ProductInformationFieldsGroup from '../ProductInformationFieldsGroup/ProductInformationFieldsGroup'
import styles from './ProductCreation.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IStepFormProps<ProductCreationDTO> & {
    brandId?: string
  }

const InformationStep: React.FC<IProps> = ({
  onPrevious,
  onNext,
  value,
  first,
  last,
  brand,
  brandId,
  fetchBrand,
  designer,
  fetchDesigner
}) => {
  // Fetch product's brand name
  useEffect(() => {
    const productBrandId = brandId || value.brandId
    if (productBrandId && (!brand || productBrandId !== brand.id)) {
      fetchBrand(productBrandId)
    }
  }, [value, brandId, brand, fetchBrand])

  // Fetch designers
  const [productDesigners, setProductDesigners] = useState<DesignerDTO[]>([])
  useEffect(() => {
    if (value.designerIds && value.designerIds.length > 0) {
      value.designerIds.reduce((promise: Promise<any>, designerId) => {
        return promise.then(() => fetchDesigner(designerId))
      }, Promise.resolve())
    }
  }, [fetchDesigner, value])
  useEffect(() => {
    if (value.designerIds) {
      if (designer && value.designerIds.find(designerId => designerId === designer.id)) {
        if (!productDesigners.find(d => designer.id === d.id)) {
          setProductDesigners([...productDesigners, designer])
        }
      }
    }
  }, [designer, productDesigners, value.designerIds])

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(),
        brandId: yup.string().required(),
        collection: yup.string(),
        designedDate: yup.date(),
        brandWebsiteUrl: yup.string().url(),
        description: yup.string(),
        objectProduct: yup.object().shape({
          type: yup.string().required()
        }),
        suggestedPrice: yup
          .number()
          .min(0)
          .typeError('')
      }),
    []
  )

  return (
    <Formik
      initialValues={{ ...value }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!values) return
        setSubmitting(false)
        onNext(values)
      }}
    >
      {formik => (
        <Form layout="horizontal" onSubmit={formik.handleSubmit} noValidate>
          <div className={styles.formBody}>
            <ProductInformationFieldsGroup
              product={value}
              formik={formik}
              brandId={brandId}
              brandName={(brand && brand.id === brandId && brand.name) || ''}
              productDesigners={productDesigners}
              setType
            />
          </div>

          <MultiStepNavigation
            showPrevious={!first}
            finish={last}
            nextDisabled={Object.values(formik.errors).length > 0}
            onPrevious={onPrevious}
            onNext="submit"
          />
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state: IRootState) => ({
  brand: state.brandState.value,
  designer: state.designerState.value
})
const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchBrand: (id: string) => dispatch(brandActions.thunks.fetchValue(id)),
  fetchDesigner: (id: string) => dispatch(designerActions.thunks.fetchValue(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationStep)
