import { AccountType } from '@addsome/dtos/dist'
import { auth } from '@addsome/redux-store'
import { Modal } from '@addsome/ui-kit'
import * as Sentry from '@sentry/browser'
import Icon from 'antd/lib/icon'
import { motion } from 'framer-motion'
import React, { useCallback, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { abstractRouter, IRootState } from '../../redux'
import { WHITE } from '../../utils/colors'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import usePush from '../../hooks/usePush'
import useLoggedUser from '../../hooks/useUser'
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel'
import Routes from '../../utils/routes'
import styles from './ConnectedUser.module.scss'
import { ArchitectSubscriptionType } from '@addsome/dtos'

type IProps = ReturnType<typeof mapDispatchToProps>

const ConnectedUser: React.FC<IProps> = ({ logout }) => {
  const [logoutVisible, setLogoutVisible] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const popupRef = useRef<any>(null)
  const { accountType, user, profilePicture } = useLoggedUser()
  const { push } = usePush()
  const intl = useIntl()

  useOnClickOutside(popupRef, () => setIsOpen(false))

  const handleClickOnLogout = useCallback(() => {
    setLogoutVisible(true)
  }, [])

  const variants = {
    open: { opacity: 1, y: 0, display: 'block' },
    closed: {
      opacity: 0,
      y: -40,
      transitionEnd: { display: 'none' }
    }
  }

  if (!user) {
    return <></>;
  }

  return (
    <>
      <div className={styles.mainContainer} ref={popupRef}>
        {((accountType == AccountType.Architect && !user.subscription) || (accountType == AccountType.Architect && user.subscription.slug != ArchitectSubscriptionType.Unlimited)) &&
          <div className={styles.remainingDownloads}>{user.downloads}/{user.subscription ? user.subscription.quota + user.subscriptionRemainingDownloads : user.subscriptionRemainingDownloads}&nbsp;<Icon type="vertical-align-bottom" /></div>
        }
        <div
          className={styles.container}
          onClick={e => {
            e.stopPropagation()
            setIsOpen(isOpen => !isOpen)
          }}
        >
          <div className={styles.avatar}>
            <img src={profilePicture} alt="Menu Utilisateur" />
          </div>

          <Icon type="caret-down" style={{ fontSize: 12, color: WHITE, marginLeft: '5px' }} />
        </div>

        {user && (
          <motion.div
            className={styles.userPopup}
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
          >
            <div className={styles.upperBlock}>
              <Icon type="close" onClick={() => setIsOpen(false)} />
              <div className={styles.upperBlockDetailsContainer}>
                <div className={styles.userInfos}>
                  <h3 className={styles.userName}>
                    {user.account.firstName} {user.account.lastName}
                  </h3>
                  <span className={styles.userEmail}>{user.account.email}</span>
                </div>
                {((accountType == AccountType.Architect && !user.subscription) || (accountType == AccountType.Architect && user.subscription.slug != ArchitectSubscriptionType.Unlimited)) &&
                  <div className={styles.userCredits}>
                    <h3>{user.downloads}/{user.subscription ? user.subscription.quota + user.subscriptionRemainingDownloads : user.subscriptionRemainingDownloads}&nbsp;</h3>
                    <span>{intl.formatMessage({ id: 'global.credits' })}</span>
                  </div>
                }
              </div>
            </div>
            <div className={styles.actions}>
              {accountType == AccountType.Architect &&
                <div className={styles.action}
                  onClick={() => {
                    setIsOpen(false)
                    push(`${Routes.Architects}/${user.id}/edit`)
                  }}>
                  <Icon type="setting" />
                  <h3>
                    {intl.formatMessage({ id: 'global.accountDetails' })}
                  </h3>
                </div>
              }
              {/* <div className={styles.action}>
                <Icon type="heart" />
                <h3>
                  {intl.formatMessage({ id: 'global.collections' })}
                </h3>
              </div> */}
              {accountType == AccountType.Architect &&
                <div className={styles.action} onClick={() => {
                  setIsOpen(false)
                  push(`/downloads`)
                }}>
                  <Icon type="download" />
                  <h3>
                    {intl.formatMessage({ id: 'global.downloads' })}
                  </h3>
                </div>
              }
              <div className={styles.action} onClick={handleClickOnLogout}>
                <Icon type="logout" />
                <h3>
                  {intl.formatMessage({ id: 'global.logout' })}
                </h3>
              </div>
              {/* <div className={styles.langs}>
                <div
                  className={classNames(styles.flag, {
                    [styles.selected]: localStorage.getItem('lang') === 'fr'
                  })}
                  onClick={() => {
                    localStorage.setItem('lang', 'fr')
                    location.reload()
                  }}
                >
                  <img src={fr} />
                  FR
                </div>
                <div
                  className={classNames(styles.flag, {
                    [styles.selected]: localStorage.getItem('lang') === 'en'
                  })}
                  onClick={() => {
                    localStorage.setItem('lang', 'en')
                    location.reload()
                  }}
                >
                  <img src={gb} />
                  EN
                </div>
              </div> */}
            </div>
          </motion.div>
        )}
      </div>

      <Modal
        visible={logoutVisible}
        onCancel={() => setLogoutVisible(false)}
        onOk={logout}
        okText={<FormattedMessage id="global.yes" />}
        cancelText={<FormattedMessage id="global.no" />}
        title={<FormattedMessage id="logout.confirm" />}
        bodyStyle={{ display: 'none' }}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  logout: () => {
    Sentry.setUser(null)
    Mixpanel.track(MixpanelEvents.UserLoggedOut)
    Mixpanel.reset()
    dispatch(auth.logout(abstractRouter))
  }
})

export default connect(
  null,
  mapDispatchToProps
)(ConnectedUser)
