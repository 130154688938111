"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var product_1 = require("./product");

var ProjectSceneVersionQuoteLineDTO = function ProjectSceneVersionQuoteLineDTO() {
  _classCallCheck(this, ProjectSceneVersionQuoteLineDTO);
};

__decorate([class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return product_1.ProductQuoteDTO;
}), __metadata("design:type", product_1.ProductQuoteDTO)], ProjectSceneVersionQuoteLineDTO.prototype, "product", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ProjectSceneVersionQuoteLineDTO.prototype, "unitPrice", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ProjectSceneVersionQuoteLineDTO.prototype, "quantity", void 0);

exports.ProjectSceneVersionQuoteLineDTO = ProjectSceneVersionQuoteLineDTO;

var ProjectSceneVersionQuoteDTO = function ProjectSceneVersionQuoteDTO() {
  _classCallCheck(this, ProjectSceneVersionQuoteDTO);
};

__decorate([class_validator_1.IsArray(), class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return ProjectSceneVersionQuoteLineDTO;
}), __metadata("design:type", Array)], ProjectSceneVersionQuoteDTO.prototype, "lines", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ProjectSceneVersionQuoteDTO.prototype, "vatPrc", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ProjectSceneVersionQuoteDTO.prototype, "amountExcludingVAT", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ProjectSceneVersionQuoteDTO.prototype, "vatAmount", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], ProjectSceneVersionQuoteDTO.prototype, "total", void 0);

exports.ProjectSceneVersionQuoteDTO = ProjectSceneVersionQuoteDTO;