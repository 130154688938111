import { Row } from '@addsome/ui-kit'
import { Form } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import React from 'react'
import { EditableContext } from './EditableTable'

const EditableRow = ({ form, ...props }: { form: WrappedFormUtils; [key: string]: any }) => (
  <EditableContext.Provider value={{ form }}>
    <Row {...props} />
  </EditableContext.Provider>
)

// Will inject 'form' in the component props
export default Form.create()(EditableRow)
