import { ObjectProductDTO, AddsomeUserDTO, ArchitectDTO, BrandUserWithBrandsDTO, ProductRelationshipsExtendedDTO, ProductUpdateDTO } from '@addsome/dtos'
import { Button, Theme } from '@addsome/ui-kit'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { convertProductForUpdate } from '../../../services/convertions'
import ProductInformationForm from '../ProductInformationForm/ProductInformationForm'
import ProductInformationPanel from '../ProductInformationPanel/ProductInformationPanel'
import ProductVisuals from '../ProductVisuals'
import styles from './ProductInformation.module.scss'
import { setUserLog } from '../../../services/userLog';

interface IProps {
  product: ProductRelationshipsExtendedDTO
  objectProduct: ObjectProductDTO
  onSubmit: (product: ProductUpdateDTO) => void
  user?: AddsomeUserDTO | ArchitectDTO | BrandUserWithBrandsDTO | null,
  readonly?: boolean
  canEdit?: boolean
}

const ProductInformation: FC<IProps> = ({
  product,
  readonly,
  objectProduct,
  canEdit,
  onSubmit,
  user
}) => {
  const [showForm, setShowForm] = useState(false)
  const intl = useIntl()

  return (
    <div className={styles.productInformation}>
      <div>
        <ProductVisuals product={product} />
      </div>
      <div>
        <ProductInformationForm
          product={convertProductForUpdate(product)}
          brandName={product.brand ? product.brand.name : ''}
          productDesigners={product.designers}
          canEdit={canEdit}
          onSubmit={productUpdate => {
            onSubmit(productUpdate)
            setShowForm(false)
            setUserLog(user!.account.id, product.id, 'product information updated')
          }}
        />
        {/* <>
            <ProductInformationPanel product={product} {...(objectProduct && { objectProduct })} />
            {canEdit && (
              <Button
                className={styles.showFormButton}
                theme={Theme.PRIMARY}
                block
                size="large"
                uppercase
                onClick={() => setShowForm(true)}
              >
                {intl.formatMessage({ id: 'productspage.infos.edit' })}
              </Button>
            )}
          </> */}
      </div>
    </div>
  )
}

export default ProductInformation
