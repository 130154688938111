import React, { FC } from 'react'
import { FormattedNumber } from 'react-intl'

interface IProps {
  amount: number
  currency?: string
}

const Price: FC<IProps> = ({ amount, currency = 'EUR' }) => (
  <FormattedNumber value={amount} style="currency" currency={currency} minimumFractionDigits={2} />
)

export default Price
