import { DesignerLightDTO, ProductUpdateDTO } from '@addsome/dtos'
import { Button, Container, Theme } from '@addsome/ui-kit'
import { Form } from 'antd'
import { Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import ProductInformationFieldsGroup from '../ProductInformationFieldsGroup/ProductInformationFieldsGroup'
import styles from './ProductInformationForm.module.scss'
import { FormattedMessage } from 'react-intl'

interface IProps {
  product: ProductUpdateDTO
  brandName?: string
  productDesigners?: DesignerLightDTO[]
  onSubmit: (product: ProductUpdateDTO) => Promise<void> | void,
  canEdit?: boolean
}

export default class ProductInformationForm extends React.Component<IProps> {
  private validationSchema = yup.object().shape({
    name: yup.string().required(),
    brandId: yup.string().required(),
    collection: yup.string().nullable(),
    designedDate: yup.date().nullable(),
    brandWebsiteUrl: yup
      .string()
      .url()
      .nullable(),
    description: yup.string().nullable(),
    suggestedPrice: yup
      .number()
      .min(0)
      .typeError('')
  })

  public render() {
    const { product } = this.props
    return (
      <Formik
        initialValues={{ ...product }}
        validationSchema={this.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!values) return
          const wait = this.props.onSubmit(this.restrictUpdatedData(values)) || Promise.resolve()
          // If we get a promise, wait for it
          wait.finally(() => setSubmitting(false))
        }}
      >
        {formik => (
          <Form
            layout="horizontal"
            onSubmit={formik.handleSubmit}
            className={styles.productInformationForm}
            noValidate
          >
            <Container>
              <ProductInformationFieldsGroup
                product={{ ...product }}
                formik={formik}
                brandName={this.props.brandName}
                productDesigners={this.props.productDesigners}
              />
            </Container>
            <Button
              theme={Theme.PRIMARY}
              htmlType="submit"
              block
              size="large"
              uppercase
              disabled={Object.values(formik.errors).length > 0}
            >
              <FormattedMessage id='productpage.panel.saveChanges' />
            </Button>
          </Form>
        )}
      </Formik>
    )
  }

  private restrictUpdatedData = (product: ProductUpdateDTO): ProductUpdateDTO => ({
    brandId: product.brandId,
    name: product.name,
    details: product.details,
    collection: product.collection,
    designedDate: product.designedDate || null,
    designerIds: product.designerIds,
    brandWebsiteUrl: product.brandWebsiteUrl,
    suggestedPrice: product.suggestedPrice,
    description: product.description
  })
}
