"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNotifications = exports.thunks = exports.reducer = void 0;

var crud_1 = require("./crud");

var notification_1 = require("./notification");

var KEY = 'OBJECT_PRODUCT_CATEGORIES';
exports.reducer = (0, crud_1.default)(KEY);
exports.thunks = new crud_1.ThunkHandler({
  url: '/object-product-categories',
  key: KEY,
  noPagination: true
});

function createNotifications(dispatch, message) {
  dispatch((0, notification_1.addLabels)(KEY, {
    fetchValues: {
      error: message.errorRetrievingCategories
    },
    fetchValue: {
      error: message.errorRetrievingCategory
    },
    create: {
      success: message.addedProductCategory,
      error: message.errorCreatingCategory
    },
    patch: {
      success: message.updatedProductCategory,
      error: message.errorRegisteringCategory
    },
    delete: {
      success: message.removedProductCategory,
      error: message.errorDeletingCategory
    }
  }));
}

exports.createNotifications = createNotifications;