import { ArchitectCreationDTO } from '@addsome/dtos'
import { Form } from 'antd'
import { Formik } from 'formik'
import React, { useMemo } from 'react'
import * as yup from 'yup'
import { IStepFormProps } from '../../MultiStepModal/MultiStepModal'
import MultiStepNavigation from '../../MultiStepModal/MultiStepNavigation/MultiStepNavigation'
import ArchitectInformationFieldsGroup from '../ArchitectsInformationFieldsGroup/ArchitectInformationFieldsGroup'
import styles from './ArchitectCreation.module.scss'
import { Validator } from 'class-validator';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type IProps = WrappedComponentProps & IStepFormProps<ArchitectCreationDTO>

const ArchitectInformationStep: React.FC<IProps> = ({ onPrevious, onNext, value, first, last, intl }) => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup
          .string()
          .required()
          .email(),
        password: yup
          .string()
          .required()
          .min(5),
        phone: yup
          .mixed()
          .test('len', intl.formatMessage({ id: 'register.address.phone.error' }), val => {
            const validator = new Validator();
            return val == null || validator.isPhoneNumber(val, 'ZZ');
          }),
        address: yup.object().shape({
          street: yup.string(),
          zipcode: yup.lazy(value =>
            value
              ? yup
                .string()
                .matches(/^[0-9]+$/, intl.formatMessage({ id: 'register.address.zipcode.error' }))
                .max(10, intl.formatMessage({ id: 'register.address.zipcode.error' }))
              : yup.string()
          ),
          city: yup.string()
        }),
        siret: yup
          .string()
          .matches(/^[0-9]*$/, intl.formatMessage({ id: 'register.architect.siret.error' }))
          .required(intl.formatMessage({ id: 'register.architect.siret.required' })),
        type: yup.string()
      }),
    []
  )

  return (
    <Formik
      initialValues={value}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!values) return
        setSubmitting(false)
        onNext(values)
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit} noValidate layout="horizontal">
          <div className={styles.formBody}>
            <ArchitectInformationFieldsGroup architect={value} formik={formik} />
          </div>
          <MultiStepNavigation
            showPrevious={!first}
            finish={last}
            nextDisabled={Object.values(formik.errors).length > 0}
            onPrevious={onPrevious}
            onNext="submit"
          />
        </Form>
      )}
    </Formik>
  )
}

export default injectIntl(ArchitectInformationStep)
