"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNotifications = exports.thunks = exports.reducer = void 0;

var crud_1 = require("./crud");

var notification_1 = require("./notification");

var KEY = 'PRODUCT';
exports.reducer = (0, crud_1.default)(KEY);
exports.thunks = new crud_1.ThunkHandler({
  url: '/products',
  key: KEY,
  public: true
});

function createNotifications(dispatch, message) {
  dispatch((0, notification_1.addLabels)(KEY, {
    fetchValues: {
      error: message.errorRetrievingProducts
    },
    fetchValue: {
      error: message.errorRetrievingAProduct
    },
    create: {
      success: message.productAdded,
      error: message.errorCreatingProduct
    },
    patch: {
      success: message.updatedProduct,
      error: message.errorRegisteringProduct
    },
    delete: {
      success: message.productRemoved,
      error: message.errorDeletingProduct
    }
  }));
}

exports.createNotifications = createNotifications;