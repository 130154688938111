import {
  setGuestInviteToken,
  acceptGuestBrandInvitation,
  unsetGuestInviteToken,
  fetchInvitationBrand,
  setBrandInvitationNotificationState,
  BrandInvitationNotificationState,
  setDisplayBrandInviteNotification
} from '@addsome/redux-store/dist/store/brand-invitation'
import { push } from 'connected-react-router'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { IRootState } from '../../redux'
import Routes from '../../utils/routes'
import { getAuth } from '@addsome/redux-store/dist/store/auth'

interface IMatchParams {
  token: string
}

type IProps = RouteComponentProps<IMatchParams>

const GuestsPage: FC<IProps> = ({ match }) => {
  const dispatch = useDispatch()

  const accountId = useSelector((state: IRootState) => state.authState.accountId)
  const authToken = useSelector((state: IRootState) => state.authState.token)
  const brand = useSelector((state: IRootState) => state.brandInvitationState.brand)
  const [hasLoadedBrad, setHasLoadedBrad] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchInvitationBrand(match.params.token))
      setHasLoadedBrad(true)
    }

    fetchData()
  }, [dispatch, match.params.token])

  useEffect(() => {
    if (match.params.token) {
      if (!authToken) {
        // User is not connected
        // Redirect to login page
        dispatch(setGuestInviteToken(match.params.token))
        dispatch(push(Routes.Login))
      } else {
        dispatch(getAuth())
      }
    }
  }, [authToken, dispatch, match.params.token])

  useEffect(() => {
    if (!accountId || !hasLoadedBrad) return

    if (!brand) {
      dispatch(setBrandInvitationNotificationState(BrandInvitationNotificationState.ERROR))
      dispatch(setDisplayBrandInviteNotification(true))
      dispatch(push(Routes.Index))
    } else {
      dispatch(acceptGuestBrandInvitation(match.params.token))
      dispatch(unsetGuestInviteToken())
      dispatch(push(`${Routes.Brands}/${brand.id}`))
    }
  }, [accountId, brand, dispatch, hasLoadedBrad, match.params.token])

  return null
}

export default GuestsPage
