"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_BRAND_USER_PROJECTS"] = "SET_BRAND_USER_PROJECTS";
  ActionsTypes["SET_BRAND_USER_PROJECT"] = "SET_BRAND_USER_PROJECT";
  ActionsTypes["UNSET_BRAND_USER_PROJECT"] = "UNSET_BRAND_USER_PROJECT";
  ActionsTypes["REMOVE_BRAND_USER_PROJECT"] = "REMOVE_BRAND_USER_PROJECT";
  ActionsTypes["SET_BRAND_USER_PROJECT_LOADING"] = "SET_BRAND_USER_PROJECT_LOADING";
  ActionsTypes["ADD_BRAND_USER_PROJECTS"] = "ADD_BRAND_USER_PROJECTS";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));