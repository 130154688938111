"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _toConsumableArray = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/toConsumableArray");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

var uuidv4_1 = require("uuidv4");

exports.initialState = {
  selectedProducts: [],
  productsSettings: [],
  renderSettings: [],
  customCamera: null,
  packshots: [],
  loading: false,
  isAdd: false,
  total: 0
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_IS_ADD, function (state, _ref) {
  var isAdd = _ref.isAdd;
  return Object.assign(Object.assign({}, state), {
    isAdd: isAdd
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_SELECTED_PRODUCTS, function (state, _ref2) {
  var selectedProducts = _ref2.selectedProducts;
  return Object.assign(Object.assign({}, state), {
    selectedProducts: selectedProducts
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_PRODUCTS_SETTINGS, function (state, _ref3) {
  var productsSettings = _ref3.productsSettings;
  return Object.assign(Object.assign({}, state), {
    productsSettings: productsSettings
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_RENDER_SETTINGS, function (state, _ref4) {
  var renderSettings = _ref4.renderSettings;
  return Object.assign(Object.assign({}, state), {
    renderSettings: [].concat(_toConsumableArray(state.renderSettings), [Object.assign(Object.assign({}, renderSettings), {
      id: (0, uuidv4_1.default)()
    })])
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.REMOVE_RENDER_SETTINGS, function (state, _ref5) {
  var id = _ref5.id;
  return Object.assign(Object.assign({}, state), {
    renderSettings: state.renderSettings.filter(function (s) {
      return s.id !== id;
    })
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UNSET_RENDER_SETTINGS, function (state) {
  return Object.assign(Object.assign({}, state), {
    renderSettings: []
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.RESET, function (state) {
  return Object.assign(Object.assign({}, state), {
    selectedProducts: [],
    productsSettings: [],
    renderSettings: null
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_PACKSHOTS, function (state, _ref6) {
  var packshots = _ref6.packshots,
      total = _ref6.total;
  return Object.assign(Object.assign({}, state), {
    packshots: packshots,
    total: total
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.ADD_PACKSHOTS, function (state, _ref7) {
  var packshots = _ref7.packshots,
      total = _ref7.total;
  return Object.assign(Object.assign({}, state), {
    packshots: [].concat(_toConsumableArray(state.packshots), _toConsumableArray(packshots)),
    total: total
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_PACKSHOT_LOADING, function (state, _ref8) {
  var loading = _ref8.loading;
  return Object.assign(Object.assign({}, state), {
    loading: loading
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_PRODUCTS_SETTINGS_VARIATION_IDS, function (state, _ref9) {
  var productId = _ref9.productId,
      variationIds = _ref9.variationIds;
  return Object.assign(Object.assign({}, state), {
    productsSettings: state.productsSettings.map(function (productSetting) {
      if (productSetting.productId === productId) {
        return Object.assign(Object.assign({}, productSetting), {
          variationIds: variationIds
        });
      }

      return productSetting;
    })
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_PRODUCTS_SETTINGS_CAMERA_IDS, function (state, _ref10) {
  var productId = _ref10.productId,
      cameraIds = _ref10.cameraIds;
  return Object.assign(Object.assign({}, state), {
    productsSettings: state.productsSettings.map(function (productSetting) {
      if (productSetting.productId === productId) {
        return Object.assign(Object.assign({}, productSetting), {
          cameraIds: cameraIds
        });
      }

      return productSetting;
    })
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_CUSTOM_CAMERA, function (state, _ref11) {
  var camera = _ref11.camera;
  return Object.assign(Object.assign({}, state), {
    customCamera: camera
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);