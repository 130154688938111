import { AddsomeUserUpdateDTO } from '@addsome/dtos/dist'
import { addsomeUser as addsomeUserActions } from '@addsome/redux-store'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { updatePageTitle } from '../../services/title'
import getProfilePicture from '../../utils/getProfilePicture'

export default function useAddsomeUser(id: string) {
  const { addsomeUser, loading } = useSelector((state: IRootState) => ({
    addsomeUser: state.addsomeUserState.value,
    loading: state.addsomeUserState.loading
  }))

  const dispatch: ThunkDispatch<IRootState, {}, AnyAction> = useDispatch()

  const fetchAddsomeUser = useCallback(() => dispatch(addsomeUserActions.thunks.fetchValue(id)), [
    dispatch,
    id
  ])

  const patchAddsomeUser = useCallback(
    (payload: AddsomeUserUpdateDTO) => dispatch(addsomeUserActions.thunks.patchValue(id, payload)),
    [dispatch, id]
  )

  const unsetAddsomeUser = useCallback(() => dispatch(addsomeUserActions.thunks.unsetValue()), [
    dispatch
  ])

  useEffect(() => {
    if (id) {
      fetchAddsomeUser()
    }

    return () => {
      unsetAddsomeUser()
    }
  }, [id, fetchAddsomeUser, unsetAddsomeUser])

  useEffect(() => {
    if (addsomeUser) {
      updatePageTitle(`${addsomeUser.account.firstName} ${addsomeUser.account.lastName}`)
    }
  }, [addsomeUser])

  return {
    addsomeUser,
    fetchAddsomeUser,
    patchAddsomeUser,
    loading,
    profilePicture: getProfilePicture((addsomeUser && addsomeUser.account) || null)
  }
}
