"use strict";

var _defineProperty = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/defineProperty");

var _actionHandlers;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = void 0;

var utils_1 = require("../utils");

var model_1 = require("./model");

exports.initialState = {
  invitation: null,
  displayNotification: false,
  notificationState: model_1.BrandInvitationNotificationState.ACCEPTED,
  guestInviteToken: null,
  brand: null
};
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_BRAND_INVITATION, function (state, _ref) {
  var invitation = _ref.invitation;
  return Object.assign(Object.assign({}, state), {
    invitation: invitation
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_DISPLAY_BRAND_INVITATION_NOTIFICATION, function (state, _ref2) {
  var display = _ref2.display;
  return Object.assign(Object.assign({}, state), {
    displayNotification: display
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_BRAND_INVITATION_NOTIFICATION_STATE, function (state, _ref3) {
  var notificationState = _ref3.notificationState;
  return Object.assign(Object.assign({}, state), {
    notificationState: notificationState
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_GUESTS_INVITATION_TOKEN, function (state, _ref4) {
  var token = _ref4.token;
  return Object.assign(Object.assign({}, state), {
    guestInviteToken: token
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.UNSET_GUESTS_INVITATION_TOKEN, function (state) {
  return Object.assign(Object.assign({}, state), {
    guestInviteToken: null
  });
}), _defineProperty(_actionHandlers, model_1.ActionsTypes.SET_INVITATION_BRAND, function (state, _ref5) {
  var brand = _ref5.brand;
  return Object.assign(Object.assign({}, state), {
    brand: brand
  });
}), _actionHandlers);
exports.reducer = (0, utils_1.createReducer)(exports.initialState, actionHandlers);