import { AccountType, BrandLightDTO, BrandUserWithBrandsDTO } from '@addsome/dtos'
import { Heading, Size, Tabs } from '@addsome/ui-kit'
import classNames from 'classnames'
import { parse } from 'querystring'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import bg from '../../../assets/images/brands-bg.png'
import BrandsList from '../../../components/Brands/BrandsList/BrandsList'
import PageHeader from '../../../components/PageHeaders/PageHeader'
import { IRootState } from '../../../redux'
import { updatePageTitle } from '../../../services/title'
import usePush from '../../../hooks/usePush'
import styles from './BrandsPage.module.scss'

enum Tab {
  MyBrands = 'myBrands',
  AllBrands = 'allBrands'
}

type IProps = WrappedComponentProps & ReturnType<typeof mapStateToProps>

const BrandsPage: React.FC<IProps> = ({ accountType, intl, user, accountId }) => {
  const pageSize = 10
  const [userBrands, setUserBrands] = useState<BrandLightDTO[]>([])
  const [activeKey, setActiveKey] = useState<Tab>('')
  const { updateUrl, router } = usePush()

  useEffect(() => {
    if (router.location.search) {
      const qs = parse(router.location.search.substring(1))
      if (qs.tab && Object.values(Tab).includes(qs.tab.toString() as Tab)) {
        setActiveKey(qs.tab as Tab)
      }
    }
  }, [router.location.search])

  useEffect(() => {
    updatePageTitle(intl.formatMessage({ id: 'brandspage.title' }))
  }, [intl])

  useEffect(() => {
    if (user) {
      if (accountType === AccountType.BrandUser) {
        const brandUser = user as BrandUserWithBrandsDTO
        if (brandUser.brands && brandUser.brands.length > 0) {
          setUserBrands(brandUser.brands)
        }
      }
    }
  }, [user, accountType])

  useEffect(() => {
    if (accountType === AccountType.Architect && !router.location.search) {
      updateUrl(`tab=${Tab.MyBrands}`)
    }
  }, [])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      setActiveKey(activeKey as Tab)
      updateUrl(`tab=${activeKey}`)
    },
    [setActiveKey, updateUrl]
  )

  return (
    <>
      <PageHeader
        title={
          <>
            <Heading level={1} strong centerText>
              <FormattedMessage id="brandspage.title" />
            </Heading>
            <span>
              <FormattedMessage id="global.welcome" />
            </span>
          </>
        }
        backgroundSrc={
          accountType === AccountType.BrandUser &&
            userBrands.length === 1 &&
            userBrands[0].cover &&
            userBrands[0].cover.url
            ? userBrands[0].cover.url
            : bg
        }
        size={Size.MEDIUM}
      />
      <div
        className={classNames(styles.brandsPage, {
          [styles.noTab]: accountType !== AccountType.Architect
        })}
      >
        {accountType !== AccountType.Architect ? (
          <>
            {((accountType && accountType !== AccountType.BrandUser) || accountId) && (
              <BrandsList
                pageSize={pageSize}
                filters={accountType === AccountType.BrandUser ? { accountIds: [accountId] } : {}}
              />
            )}
          </>
        ) : (
          <>
            {/* We need to force destroy of inactive tab to rerender the brandslist */}
            <Tabs activeKey={activeKey} onChange={handleTabChange} destroyInactiveTabPane={true}>
              <Tabs.TabPane
                tab={<FormattedMessage id="brandspage.tabs.mybrands" />}
                key={Tab.MyBrands}
              >
                <BrandsList
                  pageSize={pageSize}
                  readonly
                  filters={user ? { architectIds: [user.id], enabled: ['true'] } : undefined}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<FormattedMessage id="brandspage.tabs.allbrands" />}
                key={Tab.AllBrands}
              >
                <BrandsList pageSize={pageSize} readonly filters={{ enabled: ['true'] }} />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  accountType: state.authState.type,
  user: state.userState.user,
  accountId: state.authState.accountId
})

export default injectIntl(connect(mapStateToProps)(BrandsPage))
