import { ArchitectCreationDTO, ArchitectDTO, AccountType, ArchitectSubscriptionType } from '@addsome/dtos'
import { architect as architectActions, IPaginationRequest } from '@addsome/redux-store'
import { PaginationConfig, SorterResult } from 'antd/lib/table'
import { push } from 'connected-react-router'
import { parse, stringify } from 'querystring'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import ListTop from '../../ListTop/ListTop'
import ArchitectsTable from './ArchitectsTable'
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl'
import ArchitectInformationStep from '../../Architects/ArchitectCreation/ArchitectInformationStep'
import { ArchitectType } from '@addsome/dtos/dist/enum/architectType'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps

interface IState {
  currentPage: number
  request: IPaginationRequest
  prevSearch: string
}

const pageSize = 10

class ArchitectsTabPane extends React.Component<IProps, IState> {
  public state: IState = {
    currentPage: 0,
    request: {
      skip: 0,
      take: pageSize,
      filters: {
        name: [this.props.queryParams.search],
        ...JSON.parse(this.props.queryParams.filters || '{}')
      }
    },
    prevSearch: ''
  }

  public componentDidMount() {
    let { queryParams } = this.props
    // Default ordering
    if (Object.keys(queryParams).length === 0) {
      const newParams = {
        ...this.props.queryParams,
        order: JSON.stringify({ createdAt: -1 })
      }

      this.props.updateQuery(stringify(newParams))
    } else {
      this.updateRequest()
    }
  }

  public componentDidUpdate() {
    const { prevSearch } = this.state
    const { urlSearch } = this.props

    // Update data when user use previous
    if (prevSearch !== urlSearch) {
      this.updateRequest()
    }
  }

  public render() {
    const { queryParams, updateQuery } = this.props

    const handleCreateArchitect = async (newArchitect) => {
      const architect = await this.props.createArchitect(newArchitect)
      this.props.redirect(`/users?tab=architects`)
    }

    return (
      <div>
        <ListTop
          searchLabel={this.props.intl.formatMessage({ id: 'architecttable.search' })}
          onSearch={searchPattern => {
            const newRequest = {
              ...this.state.request,
              skip: 0,
              filters: {
                ...this.state.request.filters,
                query: [searchPattern]
              }
            }
            this.setState({ request: newRequest })
            this.props.fetchArchitects(newRequest)

            const newParams = { ...queryParams, search: searchPattern, page: 1 }
            this.setState({ prevSearch: '?' + stringify(newParams) })
            updateQuery(stringify(newParams))
          }}
          searchInitValue={queryParams.search}
          creation={
            this.props.accountType === AccountType.AddsomeUser
              ? {
                active: true,
                initialValue: {
                  companyName: '',
                  firstName: '',
                  lastName: '',
                  password: '',
                  email: '',
                  address: {
                    street: '',

                    city: '',
                    zipcode: '',
                    country: '',
                    region: ''
                  },
                  siret: '',
                  type: ArchitectType.Architect,
                  description: '',
                },
                steps: [
                  {
                    title: <FormattedMessage id="productspage.infos" />,
                    component: ArchitectInformationStep
                  }
                ],
                title: <FormattedMessage id="userspage.architect.add" />,
                onCreate: payload => handleCreateArchitect(payload)
              }
              : undefined
          }
        />
        <ArchitectsTable
          architects={this.props.architects}
          total={this.props.total}
          pageSize={pageSize}
          currentPage={this.state.currentPage}
          loading={this.props.loading}
          onTableChange={this.handleTableChange}
          onDelete={this.deleteArchitect}
          sortBy={JSON.parse(queryParams.order || '{"createdAt": -1}')}
          filters={JSON.parse(queryParams.filters || '{"createdAt": -1}')}
        />
      </div>
    )
  }

  private updateRequest = () => {
    const { queryParams } = this.props
    const currentPage = Math.max(1, parseInt(queryParams.page, 10)) || 1
    const newRequest = {
      ...this.state.request,
      skip: (currentPage - 1) * pageSize,
      order: JSON.parse(queryParams.order || '{}'),
      filters: {
        ...JSON.parse(this.props.queryParams.filters || '{}'),
        query: [queryParams.search]
      }
    }

    this.setState({ currentPage, request: newRequest, prevSearch: this.props.urlSearch })
    this.props.fetchArchitects(newRequest)
  }

  private handleTableChange = (
    pagination: PaginationConfig,
    filter: Record<keyof ArchitectDTO, string[]>,
    sorter: SorterResult<ArchitectDTO>
  ) => {
    const newRequest: IPaginationRequest = {
      ...this.state.request,
      skip: ((pagination.current || 1) - 1) * pageSize,
      order: sorter.column ? { [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1 } : {},
      filters: { ...this.state.request.filters, ...filter }
    }
    const newParams = {
      ...this.props.queryParams,
      page: pagination.current || this.state.currentPage,
      order: JSON.stringify(newRequest.order),
      filters: JSON.stringify(filter)
    }

    this.props.updateQuery(stringify(newParams))
  }

  private deleteArchitect = (id: string) => this.props.deleteArchitect(id)
}

const mapStateToProps = (state: IRootState) => ({
  architects: state.architectState.values,
  loading: state.architectState.loading,
  total: state.architectState.total,
  queryParams: parse(state.router.location.search.replace(/^\?/, '')) as { [key: string]: string },
  urlSearch: state.router.location.search,
  accountType: state.authState.type,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchArchitects: (request?: IPaginationRequest) =>
    dispatch(architectActions.thunks.fetchValues(request)),
  deleteArchitect: (id: string) => dispatch(architectActions.thunks.deleteValue(id)),
  createArchitect: (architect: ArchitectCreationDTO) =>
    dispatch(architectActions.thunks.createValue(architect)),
  updateQuery: (params: string) => dispatch(push({ search: params })),
  redirect: (location: string) => dispatch(push(location))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ArchitectsTabPane))
