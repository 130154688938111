"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SocketEvents;

(function (SocketEvents) {
  SocketEvents["ProductMediaMetric"] = "productMediaMetric";
  SocketEvents["ProductAccountMetric"] = "productAccountMetric";
})(SocketEvents = exports.SocketEvents || (exports.SocketEvents = {}));