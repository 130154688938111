"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionsTypes = void 0;
var ActionsTypes;

(function (ActionsTypes) {
  ActionsTypes["SET_VIEWER_DISPLAY"] = "SET_VIEWER_DISPLAY";
  ActionsTypes["SET_VIEWER_PRODUCT_ID"] = "SET_VIEWER_PRODUCT_ID";
  ActionsTypes["UNSET_VIEWER_PRODUCT_ID"] = "UNSET_VIEWER_PRODUCT_ID";
  ActionsTypes["SET_VARIATION_INDEX"] = "SET_VARIATION_INDEX";
  ActionsTypes["SET_SELECTED_PICTURE_ID"] = "SET_SELECTED_PICTURE_ID";
  ActionsTypes["UNSET_SELECTED_PICTURE_ID"] = "UNSET_SELECTED_PICTURE_ID";
  ActionsTypes["SET_DISPLAY_ACTIONS"] = "SET_DISPLAY_ACTIONS";
  ActionsTypes["SET_AS_THUMBNAIL_PICTURE_BY_ID"] = "SET_AS_THUMBNAIL_PICTURE_BY_ID";
})(ActionsTypes = exports.ActionsTypes || (exports.ActionsTypes = {}));