"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ProductPictureType;

(function (ProductPictureType) {
  ProductPictureType["Packshot"] = "packshot";
  ProductPictureType["Ambiance"] = "ambiance";
  ProductPictureType["CloseUp"] = "close-up";
  ProductPictureType["Measures"] = "measures";
  ProductPictureType["Undefinied"] = "undefined";
})(ProductPictureType = exports.ProductPictureType || (exports.ProductPictureType = {}));