import React from 'react'
import { AccountType, ArchitectUpdateDTO, Language } from '@addsome/dtos'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import logo from '../../assets/images/logo-white.png'
import styles from './Footer.module.scss'
import classNames from "classnames";
import fr from "../../assets/images/fr.png";
import gb from "../../assets/images/gb.png";
import cookie from '../../assets/images/cookies.png'
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from '../../redux'
import { AnyAction } from 'redux';
import { architect as architectActions } from '@addsome/redux-store';
import { connect } from 'react-redux';
import useLoggedUser from '../../hooks/useUser'
import { debug } from 'console'
import Icon from 'antd/lib/icon'
import tikTok from '../../assets/images/tik-tok.png'


type IProps = WrappedComponentProps &
  ReturnType<typeof mapDispatchToProps> & {
    isLogin?: boolean
  }

const Footer: React.FC<IProps> = ({ isLogin, patchArchitectUpdate }) => {
  const currentLanguage = localStorage.getItem('lang');
  const landingPageUrl = 'https://addsome.io';
  const timestamp = new Date().getTime();
  const { accountType, user } = useLoggedUser()

  const getFileName = (type: string): string => {
    switch (type) {
    case "terms": {
      return currentLanguage === 'fr' ? 'Conditions_Generales_de_Services.pdf' : "General_Terms_and_Conditions.pdf";
    }
    case "privacy": {
      return currentLanguage === 'fr' ? 'Charte_de_Confidentialite.pdf' : "Privacy_Policy.pdf";
    }
    case "legal": {
      return currentLanguage === 'fr' ? 'Mentions_Legales.pdf' : "Legal_Mentions.pdf";
    }
    default:
      return "";
    }
  };


  return (
    <footer className={classnames(styles.container, { [styles.isLogin]: isLogin })}>
      <div className={styles.copyright}>
        <img src={logo} alt="Logo" />
        <span>
          <FormattedMessage
            id="footer.copyright"
            values={{
              year: new Date().getFullYear()
            }}
          />
        </span>
      </div>
      <div>
        <div className={styles.langs}>
          <div
            className={classNames(styles.flag, {
              [styles.selected]: currentLanguage === 'fr'
            })}
            onClick={() => {
              if (user && accountType === AccountType.Architect) {
                patchArchitectUpdate(user.id, { account: { language: Language.French } })
              }
              localStorage.setItem('lang', 'fr')
              window.location.reload()
            }}
          >
            <img src={fr} alt="French Flag" />
            FR
          </div>
          <span style={{ color: "white" }}> | </span>
          <div
            className={classNames(styles.flag, {
              [styles.selected]: currentLanguage === 'en'
            })}
            onClick={() => {
              if (user && accountType === AccountType.Architect) {
                patchArchitectUpdate(user.id, { account: { language: Language.English } })
              }
              localStorage.setItem('lang', 'en')
              window.location.reload()
            }}
          >
            <img src={gb} alt="British Flag" />
            EN
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.links}>
          <div className={styles.socialMedia}>
            <a href='https://www.facebook.com/profile.php?id=100095112354583' aria-label="Facebook" target='_blank'>
              <Icon type="facebook" className={styles.socialIcon} />
            </a>
            <a href='https://www.tiktok.com/@addsome_design' aria-label="TikTok" target='_blank'>
              <img src={tikTok} alt='Tik Tok' className={styles.tiktokIcon} />
            </a>
            <a href='https://www.instagram.com/addsome_edesign/' aria-label="Instagram" target='_blank'>
              <Icon type="instagram" className={styles.socialIcon} />
            </a>
          </div>
          <div className={styles.terms}>
            <a href={`${landingPageUrl}/documents/${getFileName('terms')}?t=${timestamp}`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="footer.termsOfUse" />
            </a>
            <a href={`${landingPageUrl}/documents/${getFileName('legal')}?t=${timestamp}`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="footer.legalNotice" />
            </a>
            <a href={`${landingPageUrl}/documents/${getFileName('privacy')}?t=${timestamp}`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="footer.privacy" />
            </a>
            <div onClick={() => window.tarteaucitron.userInterface.openPanel()}>
              <FormattedMessage id="footer.manageCookies" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobileCopyright}>
        <span>
          <FormattedMessage
            id="footer.copyright"
            values={{
              year: new Date().getFullYear()
            }}
          />
        </span>
        <img onClick={() => window.tarteaucitron.userInterface.openPanel()} src={cookie} alt="Cookie" />
      </div>
    </footer>
  );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  patchArchitectUpdate: (id: string, payload: ArchitectUpdateDTO, succes?: string, error?: string) => dispatch(
    architectActions.patchArchitectUpdate(id, payload, succes, error)
  ),
});

export default connect(
  undefined,
  mapDispatchToProps)(injectIntl(Footer));
