import { MediaDTO } from '@addsome/dtos';
import { Size, Theme } from '@addsome/ui-kit';
import notification from 'antd/lib/notification';
import Upload, { UploadChangeParam } from 'antd/lib/upload';
import classnames from 'classnames';
import React from 'react';
import UploadBigSquare from './UploadBigSquare/UploadBigSquare';
import UploadButton from './UploadButton/UploadButton';
import styles from './UploadMedia.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { type } from 'os';

export enum UploadType {
  BUTTON = 'button',
  SQUARE = 'square',
  GHOST = 'ghost',
  CIRCLE = 'circle',
  TEXT = 'text',
}

export type IUploadMediaProps = WrappedComponentProps & {
  action: string;
  afterUpload: (response: MediaDTO, originalName: string) => void;
  type?: UploadType;
  children?: React.ReactNode;
  accept?: string;
  token: string;
  className?: string;
  disabled?: boolean;
  description?: string;
  title?: string;
};

interface IState {
  loading: boolean;
}

class UploadMedia extends React.Component<IUploadMediaProps, IState> {
  public state: IState = {
    loading: false
  };

  public render() {
    const {title, children} =  this.props;
    const Component = this.props.type === UploadType.SQUARE ? UploadBigSquare : UploadButton;
    const componentProps =
      this.props.type === UploadType.SQUARE
        ? { title, children}
        : {
          theme:
            this.props.type === UploadType.GHOST
              ? Theme.GHOST
              : this.props.type === UploadType.CIRCLE
                ? Theme.PRIMARY
                : Theme.DEFAULT,
          circle: this.props.type === UploadType.CIRCLE,
          size: Size.MEDIUM,
          disabled: this.props.disabled
        };
    return (
      <Upload
        name="file"
        action={this.props.action}
        onChange={this.handleChange}
        showUploadList={false}
        accept={this.props.accept || '*'}
        className={classnames(styles.uploadMedia, this.props.className)}
        headers={{
          authorization: `Bearer ${this.props.token}`
        }}
        multiple={true}
        defaultFileList={[]}
      >
        {this.props.type === UploadType.TEXT ?
          <div>{this.props.children}</div> :
          <Component loading={this.state.loading} {...componentProps}>
            {this.props.children}
          </Component>
        }
      </Upload>
    );
  }

  private handleChange = (info: UploadChangeParam) => {
    this.setState({
      loading: info.file.status === 'uploading'
    });

    let mediaResponseList: any[] = [];
    for (const imageInfo of info.fileList) {
      if (imageInfo.response && imageInfo.response.id !== undefined)
        mediaResponseList.push({ enabled: true, mediaId: imageInfo.response.id });
    }
    localStorage.setItem("pictures", JSON.stringify(mediaResponseList));

    let allFilesNames: any[] = []
    let allFilesTypes: any[] = []
    let allFilesExtentions: any[] = []
    let allFilesSize = 0

    let documents: any[] = [];
    for (const fileInfo of info.fileList) {
      if (fileInfo.response && fileInfo.response.id !== undefined) {
        if (fileInfo.type.includes("pdf") || fileInfo.type.includes("zip") || fileInfo.type.includes("rar")) {
          documents.push({ name: fileInfo.name, mediaId: fileInfo.response.id, enable: true })
          allFilesNames.push(fileInfo.name)
          allFilesExtentions.push(fileInfo.response.url.split('.').pop())
          allFilesSize = allFilesSize + fileInfo.size
        }
      }
    }
    localStorage.setItem("documents", JSON.stringify(documents))

    let pictures: any[] = [];
    for (const fileInfo of info.fileList) {
      if (fileInfo.response && fileInfo.response.id !== undefined) {
        if (fileInfo.type.includes("image")) {
          pictures.push({ name: fileInfo.name, mediaId: fileInfo.response.id, enabled: true })
          allFilesNames.push(fileInfo.name)
          allFilesExtentions.push(fileInfo.response.url.split('.').pop())
          allFilesSize = allFilesSize + fileInfo.size
        }
      }
    }
    localStorage.setItem("importPictures", JSON.stringify(pictures))

    let sourceFiles: any[] = [];
    for (const fileInfo of info.fileList) {
      if (fileInfo.response && fileInfo.response.id !== undefined) {
        if (!fileInfo.type.includes("pdf") && !fileInfo.type.includes("image")
          && !fileInfo.type.includes("zip") && !fileInfo.type.includes("rar")) {
          sourceFiles.push({ name: fileInfo.name, mediaId: fileInfo.response.id })
          allFilesNames.push(fileInfo.name)
          allFilesExtentions.push(fileInfo.response.url.split('.').pop())
          allFilesSize = allFilesSize + fileInfo.size
        }
      }
    }
    localStorage.setItem("sourceFiles", JSON.stringify(sourceFiles))

    if (documents.length)
      allFilesTypes.push('information')
    if (sourceFiles.length)
      allFilesTypes.push('cao')
    if (pictures.length)
      allFilesTypes.push('picture')

    localStorage.setItem('allFilesNames', JSON.stringify(allFilesNames))
    localStorage.setItem('allFilesExtentions', JSON.stringify(allFilesExtentions))
    localStorage.setItem('allFilesTypes', JSON.stringify(allFilesTypes))
    localStorage.setItem('allFilesSize', JSON.stringify(allFilesSize))

    if (info.file.status === 'done') {
      this.props.afterUpload(info.file.response, info.file.name);
    } else if (info.file.status === 'error') {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'global.error' }),
        description: this.props.description ? this.props.description : this.props.intl.formatMessage({ id: 'import.upload.media' }),
        className: styles.notificationContainer
      });
    }
  };
}

export default injectIntl(UploadMedia);
