import { AutoCompleteField } from '@addsome/ui-kit'
import { SelectValue } from 'antd/lib/select'
import React, { useEffect, useState } from 'react'
import { getCountryFromCode, ICountry, searchCountry } from '../../services/mapbox'

interface IProps {
  onChange: (code: string) => void
  defaultValue?: string
  name: string
  error?: string
  onSetInvalid?: (invalid: boolean) => void
}

const CountryAutoCompleteField: React.FC<IProps> = ({
  onChange,
  defaultValue,
  name,
  error,
  onSetInvalid
}) => {
  const [countries, setCountries] = useState<ICountry[]>([])
  const [defaultCountry, setDefaultCountry] = useState<ICountry | null>(null)

  const fillCountries = async (query: SelectValue) => {
    const results = await searchCountry(query as string)
    setCountries(results)
  }

  useEffect(() => {
    if (defaultValue) {
      getCountryFromCode(defaultValue).then(setDefaultCountry)
    }
  }, [defaultValue])

  return (
    <>
      {(!defaultValue || (defaultValue && defaultCountry)) && (
        <AutoCompleteField
          name={name}
          error={error}
          onSearch={fillCountries}
          fields={countries.map(c => ({
            label: c.label,
            key: c.code.toUpperCase()
          }))}
          defaultLabel={(defaultCountry && defaultCountry.label) || ''}
          defaultValue={defaultValue || ''}
          onChangeValue={onChange}
          onSetInvalid={onSetInvalid}
        />
      )}
    </>
  )
}

export default CountryAutoCompleteField
