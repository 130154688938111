import { ArchitectCreationDTO, ArchitectSubscriptionType } from '@addsome/dtos'

export class ArchitectCreationForm extends ArchitectCreationDTO {
  public cgu: boolean = false
  public policy: boolean = false
  public subscriptionType: ArchitectSubscriptionType = ArchitectSubscriptionType.Pro100
}

export enum RegisterStep {
  SUBSCRIPTION = 'subscription',
  ARCHITECT = 'architect',
  FORMULA = 'formula'
}

export interface IRegisterStepProps {
  initialValues: ArchitectCreationForm
  errors?: IErrors
  isRegistered?: boolean
  onBack: () => void
  onSubmit: (values: ArchitectCreationForm) => void
}

export interface IErrors {
  isEmailAlreadyUsed?: boolean,
  stripe?: string
}

export interface IRegisterStepItem {
  step: RegisterStep
  completed: boolean
  component: React.FC<IRegisterStepProps> | React.ComponentClass<IRegisterStepProps>
}
