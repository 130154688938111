"use strict";

var _classCallCheck = require("/builds/addsome-io/addsome-monorepo/common/temp/node_modules/.registry.npmjs.org/@babel/runtime/7.5.5/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var media_1 = require("./media");

var ObjectProductFBXProcessingDTO = function ObjectProductFBXProcessingDTO() {
  _classCallCheck(this, ObjectProductFBXProcessingDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductFBXProcessingDTO.prototype, "state", void 0);

__decorate([class_validator_1.ValidateNested(), class_transformer_1.Type(function () {
  return media_1.MediaDTO;
}), __metadata("design:type", media_1.MediaDTO)], ObjectProductFBXProcessingDTO.prototype, "file", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], ObjectProductFBXProcessingDTO.prototype, "createdAt", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], ObjectProductFBXProcessingDTO.prototype, "updatedAt", void 0);

exports.ObjectProductFBXProcessingDTO = ObjectProductFBXProcessingDTO;

var ObjectProductFBXProcessingCreationDTO = function ObjectProductFBXProcessingCreationDTO() {
  _classCallCheck(this, ObjectProductFBXProcessingCreationDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ObjectProductFBXProcessingCreationDTO.prototype, "stateId", void 0);

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ObjectProductFBXProcessingCreationDTO.prototype, "fileId", void 0);

exports.ObjectProductFBXProcessingCreationDTO = ObjectProductFBXProcessingCreationDTO;