import React, { FC } from 'react'
import styles from './DriveHeader.module.scss'
import { Searchbar, Button, Theme, Integration } from '@addsome/ui-kit'
import Icon from 'antd/lib/icon'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'
import { useIntl } from 'react-intl'
import file from '../../../assets/images/importfile.svg'
import folder from '../../../assets/images/newfolder.svg'

interface IProps {
  onSearch: (pattern: string) => void
  onAddFolder: () => void
  onImportFile: () => void
  displayFileImport: boolean
}

const DriveHeader: FC<IProps> = ({ onSearch, onAddFolder, onImportFile, displayFileImport }) => {
  const intl = useIntl()

  return (
    <div className={styles.container}>
      <div className={styles.searchbarContainer}>
        <Searchbar
          onSearch={onSearch}
          integration={Integration.DARK}
          placeholder={intl.formatMessage({ id: 'drivepage.searchfile' })}
        />
        <Dropdown
          overlay={
            <Menu
              onClick={e => {
                if (e.key === '0') {
                  onAddFolder()
                }
                if (e.key === '1') {
                  onImportFile()
                }
              }}
              className={styles.menu}
            >
              <Menu.Item key="0" className={styles.item}>
                <img src={folder} />
                <span className={styles.label}>
                  {intl.formatMessage({ id: 'drivepage.addfolder' })}
                </span>
              </Menu.Item>
              {displayFileImport && (
                <Menu.Item key="1" className={styles.item}>
                  <img src={file} />
                  <span className={styles.label}>
                    {intl.formatMessage({ id: 'drivepage.fileimport' })}
                  </span>
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <Button theme={Theme.PRIMARY} shape="circle" size="large" className={styles.button}>
            <Icon type="plus" />
          </Button>
        </Dropdown>
      </div>
    </div>
  )
}

export default DriveHeader
